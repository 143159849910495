var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialogSize",
      attrs: {
        title: _vm.title,
        top: "15vh",
        width: "45%",
        "append-to-body": true,
        visible: _vm.showBusinessSourceDialog,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { overflow: "auto" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务来源", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入来源名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属客户", prop: "clientName" } },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "bottom", trigger: "manual" },
                              model: {
                                value: _vm.visible,
                                callback: function ($$v) {
                                  _vm.visible = $$v
                                },
                                expression: "visible",
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "customer-table",
                                  attrs: {
                                    data: _vm.clientList,
                                    "max-height": 350,
                                    border: "",
                                  },
                                  on: {
                                    "current-change": _vm.handleCurrentChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      property: "clientName",
                                      label: "客户名称",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      property: "isAgreementClient",
                                      label: "协议客户",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ row }) {
                                          return [
                                            row.isAgreementClient == 1
                                              ? _c("span", [
                                                  _vm._v("协议客户"),
                                                  row.beContract
                                                    ? _c("span", [
                                                        _vm._v("（包干）"),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _c("span"),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "70",
                                      property: "clientLevel",
                                      label: "等级",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.hasMore
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "load-more-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCustomerData(
                                            false,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 加载更多数据 ")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  ref: "divRef",
                                  attrs: { slot: "reference" },
                                  on: { click: _vm.showPopover },
                                  slot: "reference",
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      placeholder: "请输入客户名称搜索",
                                      clearable: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.getCustomerData(true)
                                      },
                                      clear: _vm.handleClear,
                                    },
                                    model: {
                                      value: _vm.form.clientName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "clientName", $$v)
                                      },
                                      expression: "form.clientName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "启用状态" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#409eff" },
                            model: {
                              value: _vm.form.isActive,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isActive", $$v)
                              },
                              expression: "form.isActive",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "red",
                                "margin-left": "10px",
                              },
                            },
                            [
                              _vm._v("(已经选择"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.form.isActive ? "启用" : "禁用")
                                ),
                              ]),
                              _vm._v("本业务来源)"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isHeadquarters
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "共享状态" } },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#409eff" },
                                model: {
                                  value: _vm.form.isShare,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isShare", $$v)
                                  },
                                  expression: "form.isShare",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "red",
                                    "margin-left": "10px",
                                  },
                                },
                                [
                                  _vm._v("(已经选择"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.isShare ? "共享" : "不共享"
                                      )
                                    ),
                                  ]),
                                  _vm._v("到分店)"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "过滤现结提车",
                            prop: "isSelfPayingTakeCarMustPaid",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#409eff" },
                            model: {
                              value: _vm.form.isSelfPayingTakeCarMustPaid,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "isSelfPayingTakeCarMustPaid",
                                  $$v
                                )
                              },
                              expression: "form.isSelfPayingTakeCarMustPaid",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "red",
                                "margin-left": "10px",
                              },
                            },
                            [
                              _vm._v("(自费车单"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.form.isSelfPayingTakeCarMustPaid
                                      ? "不必"
                                      : "必须"
                                  )
                                ),
                              ]),
                              _vm._v("结账后才能提车)"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.verifyBeforeSave },
              },
              [_vm._v("确定")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }