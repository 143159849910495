import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=209e3dbc&scoped=true"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"
import style0 from "./Main.vue?vue&type=style&index=0&id=209e3dbc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209e3dbc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\grow_task\\code\\multi_workspace_code\\geyue-back-end-management-web\\branch_prod-features_change\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('209e3dbc')) {
      api.createRecord('209e3dbc', component.options)
    } else {
      api.reload('209e3dbc', component.options)
    }
    module.hot.accept("./Main.vue?vue&type=template&id=209e3dbc&scoped=true", function () {
      api.rerender('209e3dbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/home/Main.vue"
export default component.exports