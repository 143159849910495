import Vue from 'vue';
import { Table } from 'element-ui';
import scrollbarWidth from 'element-ui/lib/utils/scrollbar-width';

const ElTable = {
  extends: Table,
  mounted: function () {
    this.$nextTick().then(() => {
      // console.log('混入对象的钩子被调用');
      let gutter = scrollbarWidth();
      // console.log('gutter:', gutter);
      // console.log('this:', this);
      let cols = this.$refs?.tableHeader?.$el?.querySelectorAll(
        'colgroup > col[name=gutter]'
      );
      // console.log('cols:', cols);
      for (let i = 0, j = cols.length; i < j; i++) {
        const col = cols[i];
        col.setAttribute('width', gutter);
      }
    });
  },
};

// 重新注册el-table组件
Vue.component('el-table', ElTable);
