<template>
  <el-dialog
    title="温馨提示"
    :visible="visibility"
    width="50%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <p class="tip-word" v-if="inconsistentQuantityList.length">
      当前接车单存在商品报价数量与出库数量不一致情况（标红商品），请确认。
    </p>
    <div>
      <el-table
        v-if="inconsistentQuantityList.length"
        :data="inconsistentQuantityList"
        style="width: 100%"
        class="service-table"
        border
        default-expand-all
        row-key="id"
        :tree-props="{
          children: 'billGoods',
          hasChildren: 'hasChildren',
        }"
        :key="1"
      >
        <el-table-column prop="title" width="80" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="报价项目" width="180">
          <template #default="{ row }">
            <div :class="{ red: row.type === 'goods' }">{{ row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="报价项目编码" width="180">
          <template #default="{ row }">
            <div :class="{ red: row.type === 'goods' }">{{ row.code }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="报价数量" min-width="120">
          <template #default="{ row }">
            <div :class="{ red: row.type === 'goods' }">{{ row.num }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="出库数量" min-width="120">
          <template #default="{ row }">
            <div :class="{ red: row.type === 'goods' }">
              {{ row.pickedNum }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <p class="tip-word" v-if="inconsistentPricesList.length">
      当前接车单存在商品报价小于成本价（标红商品），请确认。
    </p>
    <el-table
      v-if="inconsistentPricesList.length"
      :data="inconsistentPricesList"
      style="width: 100%; margin-bottom: 40px"
      class="service-table"
      border
      default-expand-all
      row-key="id"
      :tree-props="{ children: 'billGoods', hasChildren: 'hasChildren' }"
      :key="2"
    >
      <el-table-column prop="title" width="80" label="序号"> </el-table-column>
      <el-table-column prop="name" label="报价项目" width="180">
        <template #default="{ row }">
          <div :class="{ red: row.type === 'goods' }">{{ row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="itemCode" label="报价项目编码" width="180">
        <template #default="{ row }">
          <div :class="{ red: row.type === 'goods' }">{{ row.code }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="报价价格" min-width="120">
        <template #default="{ row }">
          <div :class="{ red: row.type === 'goods' }">
            {{ row.total | toThousands }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="成本价格" min-width="120">
        <template #default="{ row }">
          <div
            v-if="row.type === 'goods'"
            :class="{ red: row.type === 'goods' }"
          >
            {{ row.totalCostPrice | toThousands }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <template slot="footer">
      <el-button type="primary" @click="handleClose">知道了</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'goodsExceptionDialog',
  props: {
    visibility: { type: Boolean, default: false },
    // 数量不一致
    inconsistentQuantityList: { type: Array, default: () => [] },
    // 价格不一致
    inconsistentPricesList: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.tip-word {
  margin: 24px 0px 15px;
  font-size: 14px;
  font-weight: 400;
  color: $orange;
}
.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}
// 不显示收起图标
.service-table.el-table ::v-deep .el-table__expand-icon {
  display: none;
}
.service-table.el-table ::v-deep .el-table__indent {
  padding: 0 !important;
}
.service-table.el-table ::v-deep .el-table__placeholder {
  width: 0 !important;
}
</style>
