<template>
  <el-drawer
    title="注意事项"
    :visible="remarksDrawerVisibility"
    size="50%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="content">
      <p class="pre-wrap">{{ clientRemark }}</p>
    </div>
  </el-drawer>
</template>
<script>
export default {
  props: {
    remarksDrawerVisibility: { type: Boolean, default: false },
    clientRemark: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:remarksDrawerVisibility', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 96%;
  margin: 0px 2%;
  height: 98%;
  border: 1px solid #999;
  border-radius: 4px;
  overflow: scroll;
  p {
    color: #333;
    margin: 20px 10px;
  }
}
</style>
