<template>
  <el-select
    ref="selEle"
    v-model="companyIds"
    multiple
    clearable
    filterable
    collapse-tags
    collapse-tags-tooltip
    :max-collapse-tags="maxCollapseTags"
    :placeholder="placeholder"
    popper-class="custom-header"
    @clear="handlerSelClear"
    @change="handleSelChange"
    style="width: 240px"
  >
    <template #header>
      <el-checkbox
        v-model="checkAll"
        :indeterminate="indeterminate"
        @change="handleCheckAll"
      >
        全选
      </el-checkbox>
    </template>
    <el-option
      v-for="item in companyList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </el-select>
</template>
<!-- 版本不支持，无法自定义header，需要升级到Vue3的 element-ui版本  -->
<script>
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
    operateCompanyUrl: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '搜索车店名称/编号',
    },
    maxCollapseTags: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    value: {
      handler: function (val) {
        console.log('value:', val);
        if (val.length === 0) {
          this.checkAll = false;
          this.indeterminate = false;
        } else if (val.length === this.companyIds.length) {
          this.checkAll = true;
          this.indeterminate = false;
        } else {
          this.indeterminate = true;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      companyList: [],
      companyIds: [],
      checkAll: false,
      indeterminate: false,
    };
  },
  computed: {
    ...mapState({
      companyTree: (state) => state.user.companyTree,
    }),
  },
  created() {
    console.log('maxCollapseTags:', this.maxCollapseTags);
    this.init();
  },
  methods: {
    init() {
      // 找出门店列表
      const _companyTree = this.companyTree;
      const _companyList = [];
      const _companyIds = [];
      this.findCompanyListByCompanyTree(
        _companyTree,
        _companyIds,
        _companyList
      );
      // 去重，排序
      _companyList.sort((a, b) => {
        return a.id - b.id;
      });
      this.companyList = _companyList;
    },
    findCompanyListByCompanyTree(_companyTree, _companyIds, _companyList) {
      for (let i = 0; i < _companyTree.length; i++) {
        const element = _companyTree[i];
        if (element && element.isCompany === true) {
          if (_companyIds.includes(element.id)) {
            continue;
          }
          _companyIds.push(element.id);
          _companyList.push(element);
        }
        if (element.nextGroup && element.nextGroup.length > 0) {
          this.findCompanyListByCompanyTree(
            element.nextGroup,
            _companyIds,
            _companyList
          );
        }
      }
    },
    handlerSelClear() {
      this.checkAll = false;
      this.$emit('input', []);
    },
    handleSelChange(value) {
      this.$emit('input', value || []);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-header {
  .el-checkbox {
    display: flex;
    height: unset;
  }
}
</style>
