// import process from 'process';

// process.on('warning', (e) => console.warn(e.stack));

// 解决IE不兼容问题 SCRIPT445: 对象不支持此操作
import 'core-js/stable';
// import 'regenerator-runtime/runtime';

import lodash from 'lodash';

import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';
import axios from './service/axios';
import http from './service/http';

import filters from '@/utils/filters';
import directives from '@/utils/directives';

import checkVersion from '@/utils/checkVersion';

// 修改js原型
import '@/utils/extend';

// 将axios挂载到Vue原型上
Vue.prototype.$axios = axios;
Vue.prototype.$http = http;
// 将lodash挂载到Vue原型上
Vue.prototype.$lodash = lodash;

// 引入Element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 覆盖ElementUI默认样式
import '@/styles/reset-element.scss';
// 修改ElementUI主题颜色变量
import '@/styles/element-variables.scss';

// 修改ElementUI组件默认属性
// 抽屉
ElementUI.Drawer.props.modal.default = false; // 抽屉 遮罩层
ElementUI.Drawer.props.wrapperClosable.default = false; // 抽屉 是否点击遮罩层关闭
ElementUI.Drawer.props.closeOnPressEscape.default = false; // 抽屉 是否按esc关闭

Vue.use(ElementUI);

import './element-ui';

// 引入阿里图标库
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont2/iconfont.css';
// 全局样式
import '@/styles/global.scss';

// 图片预览插件
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);
Viewer.setDefaults({
  zIndex: 9999,
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source',
  },
});

// 全局组件
import BaseContainer from './components/BaseContainer.vue';
import NumberInput from '@/components/numberInput.vue';
Vue.component('BaseContainer', BaseContainer);
Vue.component('NumberInput', NumberInput);

// 全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 全局自定义指令
Object.keys(directives).forEach((key) => {
  Vue.directive(key, directives[key]);
});

Vue.config.productionTip = false;

// window.vuex = store;
new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    Vue.prototype.$bus = this;
    // 在Vue根实例的created生命周期钩子中启动轮询检测版本更新
    checkVersion();
  },
}).$mount('#app');
