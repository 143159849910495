<template>
  <div>
    <el-dialog
      :visible="visibility"
      top="5vh"
      width="60%"
      title="查看营业额目标"
      :close-on-click-modal="false"
      :before-close="handleClose"
      append-to-body
    >
      <div class="container">
        <p class="info">月份：{{ year }}年{{ month }}月</p>
        <el-table :data="tableData" border>
          <el-table-column
            label="门店"
            prop="companyName"
            min-width="120"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            label="总目标"
            min-width="180"
            align="center"
            header-align="center"
          >
            <el-table-column
              label="责任人"
              prop="totalResponsibleName"
              width="80"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              label="目标"
              min-width="100"
              align="center"
              header-align="center"
            >
              <template #default="{ row }">{{
                row.totalTarget | toThousands(0)
              }}</template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="自费"
            min-width="180"
            align="center"
            header-align="center"
          >
            <el-table-column
              label="责任人"
              prop="selfPayResponsibleName"
              width="80"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              label="目标"
              min-width="100"
              align="center"
              header-align="center"
            >
              <template #default="{ row }">{{
                row.selfPayTarget | toThousands(0)
              }}</template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="包干"
            min-width="180"
            align="center"
            header-align="center"
          >
            <el-table-column
              label="责任人"
              prop="contractForResponsibleName"
              width="80"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              label="目标"
              min-width="100"
              align="center"
              header-align="center"
            >
              <template #default="{ row }">{{
                row.contractForTarget | toThousands(0)
              }}</template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="理赔"
            min-width="180"
            align="center"
            header-align="center"
          >
            <el-table-column
              label="责任人"
              prop="claimSettlementResponsibleName"
              width="80"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              label="目标"
              min-width="100"
              align="center"
              header-align="center"
            >
              <template #default="{ row }">{{
                row.claimSettlementTarget | toThousands(0)
              }}</template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="索赔"
            min-width="180"
            align="center"
            header-align="center"
          >
            <el-table-column
              label="责任人"
              prop="claimForCompensationResponsibleName"
              width="80"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              label="目标"
              min-width="100"
              align="center"
              header-align="center"
            >
              <template #default="{ row }">{{
                row.claimForCompensationTarget | toThousands(0)
              }}</template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="操作"
            min-width="120"
            align="center"
            header-align="center"
          >
            <template #default="{ row }">
              <template v-if="row.companyName === '合计'">
                <el-button
                  v-auth="540"
                  type="text"
                  size="small"
                  @click="handleSetHeadResponsible(row)"
                  >设置总责任人</el-button
                >
              </template>

              <template v-else-if="nowMonth == month">
                <el-button
                  v-auth="539"
                  type="text"
                  size="small"
                  @click="handleSetTurnover(row)"
                  >{{
                    row.totalTarget === undefined ? '设置目标' : '修改当月目标'
                  }}</el-button
                >
              </template>

              <template v-else>
                <el-button
                  v-auth="538"
                  type="text"
                  size="small"
                  @click="handleSetTurnover(row)"
                  >设置目标</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <template slot="footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button
          v-if="nowMonth == month"
          size="small"
          type="primary"
          @click="nextMonth"
          >查看下月目标</el-button
        >
      </template>
      <setTurnoverTargetDialog
        v-if="setTurnoverTargetDialogVisibility"
        :visibility.sync="setTurnoverTargetDialogVisibility"
        :companyId="editRow.companyId"
        :companyName="editRow.companyName"
        :year="year"
        :month="month"
        :isEdit="true"
        @change="getData"
      ></setTurnoverTargetDialog>
    </el-dialog>
    <el-dialog
      :visible.sync="setHeadResponsibleVisibility"
      width="400px"
      title="设置总责任人"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-table :data="businessTypeList" border>
        <el-table-column
          label="业务类型"
          prop="businessType"
          width="100"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column label="责任人">
          <template #default="{ row }">
            <el-select
              v-model="row.principal"
              value-key="userId"
              size="small"
              filterable
              clearable
              remote
              placeholder="请选择责任人"
              :remote-method="searchEmployee"
              :loading="searchEmployeeLoading"
              @focus="searchEmployee()"
            >
              <el-option
                v-for="item in employeeList"
                :key="item.userId"
                :label="item.realName + ' / ' + item.phone"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <template slot="footer">
        <el-button size="small" @click="setHeadResponsibleVisibility = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="handleSaveHeadResponsible"
          >确 定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// 接口
import {
  getAllControlCompanyTarget,
  setHeadResponsible,
  getHeadResponsible,
} from '@/api/commandCenter';
import { getUserList } from '@/api/employee';
// 组件
import setTurnoverTargetDialog from './setTurnoverTargetDialog.vue';

export default {
  name: 'viewTurnoverTargetListDialog',
  components: {
    setTurnoverTargetDialog,
  },
  props: {
    visibility: { type: Boolean, default: false },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      nowMonth: new Date().getMonth() + 1,

      tableData: [],

      // 设置营业额目标弹窗
      setTurnoverTargetDialogVisibility: false,

      // 编辑行
      editRow: {},

      searchEmployeeLoading: false,
      employeeList: [],

      setHeadResponsibleVisibility: false,
      businessTypeList: [
        {
          businessType: '自费',
          principal: '',
        },
        {
          businessType: '包干',
          principal: '',
        },
        {
          businessType: '理赔',
          principal: '',
        },
        {
          businessType: '索赔',
          principal: '',
        },
        {
          businessType: '合计',
          principal: '',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {},
  mounted() {
    this.getData();
    this.searchEmployee();
  },
  methods: {
    /**
     * 获取数据
     */
    async getData(reset) {
      await getAllControlCompanyTarget({
        companyId: this.companyId,
        statisticsPeriod: new Date(this.year, this.month - 1).Format('yyyy/MM'),
      }).then((res) => {
        if (!reset) {
          this.tableData = res;
        } else if (![null, undefined].includes(res.at(-1).totalTarget)) {
          this.tableData = res;
        } else {
          // this.$message.success('已带入上月设置目标');
        }
      });
    },

    /**
     * 查看下月目标
     */
    nextMonth() {
      this.month = new Date(this.year, this.month).getMonth() + 1;
      this.getData(true);
    },

    /**
     * 设置目标
     */
    handleSetTurnover(row) {
      this.editRow = row;
      this.setTurnoverTargetDialogVisibility = true;
    },

    /**
     * 设置总责任人
     */
    async handleSetHeadResponsible(row) {
      let res = await getHeadResponsible({
        companyId: this.companyId,
        statisticsPeriod: new Date(this.year, this.month - 1).Format('yyyy/MM'),
      });
      if (res?.selfPayResponsibleId) {
        this.businessTypeList[0].principal = {
          userId: res.selfPayResponsibleId,
          realName: res.selfPayResponsibleName,
          phone: res.selfPayResponsiblePhone,
        };
      }
      if (res?.contractForResponsibleId) {
        this.businessTypeList[1].principal = {
          userId: res.contractForResponsibleId,
          realName: res.contractForResponsibleName,
          phone: res.contractForResponsiblePhone,
        };
      }
      if (res?.claimSettlementResponsibleId) {
        this.businessTypeList[2].principal = {
          userId: res.claimSettlementResponsibleId,
          realName: res.claimSettlementResponsibleName,
          phone: res.claimSettlementResponsiblePhone,
        };
      }
      if (res?.claimForCompensationResponsibleId) {
        this.businessTypeList[3].principal = {
          userId: res.claimForCompensationResponsibleId,
          realName: res.claimForCompensationResponsibleName,
          phone: res.claimForCompensationResponsiblePhone,
        };
      }
      if (res?.totalResponsibleId) {
        this.businessTypeList[4].principal = {
          userId: res.totalResponsibleId,
          realName: res.totalResponsibleName,
          phone: res.totalResponsiblePhone,
        };
      }
      this.setHeadResponsibleVisibility = true;
    },

    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },

    /**
     * 远程搜索门店列表
     */
    searchEmployee(keyWord) {
      this.searchEmployeeLoading = true;
      getUserList(keyWord)
        .then((res) => {
          this.employeeList = res.records || [];
        })
        .finally(() => {
          this.searchEmployeeLoading = false;
        });
    },
    /**
     * 保存总责任人
     */
    async handleSaveHeadResponsible() {
      // 遍历表格校验必填并提示对应的业务类型
      for (let i = 0; i < this.businessTypeList.length; i++) {
        if (!this.businessTypeList[i].principal?.userId) {
          this.$message.error(
            `请选择${this.businessTypeList[i].businessType}责任人`
          );
          return;
        }
      }

      await setHeadResponsible({
        companyId: this.companyId,
        statisticsPeriod: new Date(this.year, this.month - 1).Format('yyyy/MM'),

        selfPayResponsibleId: this.businessTypeList[0].principal.userId,
        contractForResponsibleId: this.businessTypeList[1].principal.userId,
        claimSettlementResponsibleId: this.businessTypeList[2].principal.userId,
        claimForCompensationResponsibleId:
          this.businessTypeList[3].principal.userId,
        totalResponsibleId: this.businessTypeList[4].principal.userId,
      });
      this.setHeadResponsibleVisibility = false;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 62vh;
  overflow: auto;
}
.info {
  margin: 15px 0;
}
</style>
