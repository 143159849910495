import {
  admin1PagePrefix,
  admin2PagePrefix,
} from '@/config/compatibilityOlderSystems';

export const MENUS = [
  {
    title: '首页',
    path: '/wel',
    icon: 'grow-icon-home',
  },
  {
    title: '车单记录',
    icon: 'grow-icon-car',
    children: [
      {
        title: '接车单',
        permission: 178,
        children: [
          {
            title: '车单列表',
            permission: 35,
            path: '/carBill/carBillList',
          },
          {
            title: '定损单',
            permission: 457,
            path: '/carBill/carLossDeterminationBillList',
          },
          {
            title: '车单反向记录',
            permission: 439,
            path: '/carBill/reverseProcessRecordList',
          },
          {
            title: '疑似返修记录',
            permission: 668,
            path: '/carBill/repairDiscoverRecordList',
          },
          {
            title: '预约信息',
            permission: 1007,
            path: '/carBill/miniProgramBookingRecordList',
          },
          {
            title: '预约注册',
            permission: 1012,
            path: '/carBill/miniProgramBookingRegisterList',
          },
          {
            title: '现场排号',
            permission: 1013,
            path: '/carBill/miniProgramBookingNumberTakeList',
          },
          // {
          //   title: '报价审核记录',
          // },
        ],
      },
      {
        title: '在场车辆',
        permission: 446,
        children: [
          {
            title: '今日在场统计',
            permission: 521,
            path: '/carBill/strandedCarStatistics',
          },
          {
            title: '今日在场明细',
            permission: 447,
            path: '/carBill/presentCar',
            params: {
              activeName: 'first',
            },
          },

          {
            title: '历史滞留快照',
            permission: 448,
            path: '/carBill/strandedCar',
            params: {
              activeName: 'second',
            },
          },
        ],
      },
      {
        title: '车间工单',
        permission: 493,
        children: [
          // {
          //   title: '检车单',
          // },
          {
            title: '施工单',
            permission: 94,
            path: '/carBill/constructionBill',
            params: {
              activeName: 'second',
            },
          },
        ],
      },
      // {
      //   title: '预约单',
      //   permission: 172,
      //   children: [
      //     {
      //       title: '预约总览',
      //     },
      //     {
      //       title: '预约管理',
      //     },
      //     {
      //       title: '预约设置',
      //     },
      //   ]
      // },
      {
        title: '车单评价记录',
        permission: 600,
        path: '/carBill/vehicleEvaluationRecord',
      },
    ],
  },
  {
    title: '财务管理',
    icon: 'grow-icon-caiwu',
    permission: 55,
    children: [
      {
        title: '营业总表',
        path: '/financialManagement/showBusinessDetailsList',
        permission: 149,
      },
      {
        title: '营业对账',
        permission: 497,
        children: [
          {
            title: '车单结账明细',
            path: '/financialManagement/invoiciDetailsList',
            permission: 159,
            params: {
              activeName: 'first',
            },
          },
          {
            title: '车单项目明细',
            path: '/financialManagement/projectDetailsList',
            permission: 213,
            params: {
              activeName: 'second',
            },
          },
          {
            title: '车单实收明细',
            path: '/financialManagement/subsidiaryDetailsList',
            permission: 411,
            params: {
              activeName: 'third',
            },
          },
          {
            title: '项目毛利明细',
            path: '/financialManagement/itemGrossProfitList',
            permission: 509,
            params: {
              activeName: 'third',
            },
          },
          {
            title: '客户对账',
            path: '/financialManagement/customerReconciliation',
            permission: 527,
            params: {
              activeName: 'third',
            },
          },
          {
            title: '对账批次',
            path: '/financialManagement/reconciliationLot',
            permission: 529,
            onlyHeadquarters: true,
            params: {
              activeName: 'third',
            },
          },
        ],
      },
      {
        title: '应收款',
        permission: 499,
        children: [
          {
            title: '应收明细',
            path: '/financialManagement/receivableDetailsSecond',
            permission: 161,
            params: {
              activeName: 'second',
            },
          },
          {
            title: '应收汇总',
            path: '/financialManagement/receivableSummaryFirst',
            permission: 495,
            params: {
              activeName: 'first',
            },
          },
          {
            title: '销账收款记录',
            path: '/financialManagement/salesCollectionThird',
            permission: 216,
            params: {
              activeName: 'third',
            },
          },
          {
            title: '包干分摊批次',
            path: '/financialManagement/parcelDryFourch',
            permission: 444,
            params: {
              activeName: 'fourth',
            },
            onlyHeadquarters: true,
          },
          {
            title: '回款认领流水',
            path: '/financialManagement/transaction/bank',
            permission: 1000,
          },
          {
            title: '回款认领记录',
            path: '/financialManagement/transaction/claim',
            permission: 1001,
          },
          {
            title: '回款认领提单',
            path: '/financialManagement/transaction/submitwork',
            permission: 1002,
          },
          {
            title: '回款认领提单确认',
            path: '/financialManagement/transaction/submitwork/complete',
            permission: 1003,
          },
          {
            title: '回款认领核销明细',
            path: '/financialManagement/transaction/submitwork/detail',
            permission: 1005,
          },
          {
            title: '回款认领明细',
            path: '/financialManagement/transaction/claim/detail',
            permission: 1004,
          },
        ],
      },
      {
        title: '应付款',
        permission: 499,
        children: [
          {
            title: '应付明细',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/payment/list///`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
          {
            title: '应付汇总',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/payment/list///`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
        ],
      },
      {
        title: '调拨应收应付',
        outside: true,
        path: `${admin2PagePrefix}#/financialManagement/allocate?keepRoute=true`,
      },
      {
        title: '绩效管理',
        children: [
          {
            title: '绩效汇总',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/performance-list`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
          {
            title: '绩效明细',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/performance-detail///`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
        ],
      },
    ],
  },
  {
    title: '库存管理',
    icon: 'grow-icon-kucun',
    permission: 69,
    children: [
      {
        title: '库存概况',
        outside: true,
        path: `${admin2PagePrefix}#/stock/inventoryAnalyze?keepRoute=true`,
      },
      {
        title: '库存查询',
        children: [
          // {
          //   title: '库存总览',
          //   outside: true,
          //   path: `${admin2PagePrefix}#/stock/query/inventorySummary?keepRoute=true`,
          // },
          {
            title: '库存总览',
            path: '/stock/query/inventorySummary',
            permission: 131,
          },
          {
            title: '批次余量',
            outside: true,
            path: `${admin2PagePrefix}#/stock/query/inventoryDetailMargin?keepRoute=true`,
          },
          {
            title: '出入库记录',
            outside: true,
            path: `${admin2PagePrefix}#/stock/query/inventoryRecord?keepRoute=true`,
          },
          {
            title: '收发流水',
            outside: true,
            path: `${admin2PagePrefix}#/stock/query/receiveRecord?keepRoute=true`,
          },
        ],
      },
      {
        title: '采购入库',
        outside: true,
        path: `${admin2PagePrefix}#/stock/purchaseList?keepRoute=true`,
      },
      {
        title: '领料出库',
        children: [
          {
            title: '维修领料',
            outside: true,
            path: `${admin2PagePrefix}#/stock/outOfStock/repairPicking?keepRoute=true`,
          },
          {
            title: '其他出库',
            outside: true,
            path: `${admin2PagePrefix}#/stock/outOfStock/othersPicking?keepRoute=true`,
          },
        ],
      },
      {
        title: '采购退货',
        outside: true,
        path: `${admin2PagePrefix}#/stock/purchaseRefunds?keepRoute=true`,
      },
      {
        title: '顾客退货',
        outside: true,
        path: `${admin2PagePrefix}#/stock/customerRefunds?keepRoute=true`,
      },
      // {
      //   title: '移库',
      //   outside: true,
      //   path: `${admin2PagePrefix}#/stock/moveStock?keepRoute=true`,
      // },
      {
        title: '库存盘点',
        outside: true,
        path: `${admin2PagePrefix}#/stock/stocktaking?keepRoute=true`,
      },
      {
        title: '库存调拨',
        outside: true,
        path: `${admin2PagePrefix}#/stock/allocate?keepRoute=true`,
      },
      {
        title: '库存报表',
        children: [
          {
            title: '采购报表',
            outside: true,
            path: `${admin2PagePrefix}#/stock/report/purchaseReports?keepRoute=true`,
          },
          {
            title: '调拨报表',
            outside: true,
            path: `${admin2PagePrefix}#/stock/report/allocateReport?keepRoute=true`,
          },
          {
            title: '进销存报表',
            outside: true,
            path: `${admin2PagePrefix}#/stock/report/invoicingReport?keepRoute=true`,
          },
          {
            title: '采购单入库监控',
            path: '/stock/report/purchase/reportByGoods',
            permission: 1010,
          },
        ],
      },
      {
        title: '库存设置',
        children: [
          {
            title: '供应商管理',
            outside: true,
            path: `${admin2PagePrefix}#/stock/setting/supplierManagement?keepRoute=true`,
          },
          {
            title: '仓库管理',
            outside: true,
            path: `${admin2PagePrefix}#/stock/setting/storageManagement?keepRoute=true`,
          },
          {
            title: '系统管理',
            outside: true,
            path: `${admin2PagePrefix}#/stock/setting/basicSettings?keepRoute=true`,
          },
          {
            title: '品名管理',
            path: '/stock/setting/goodsAliasManage',
            permission: 582,
          },
          {
            title: '商品管理',
            // outside: true,
            // path: `${admin2PagePrefix}#/stock/setting/goodsManage?keepRoute=true`,
            path: '/stock/setting/goodsManage',
            permission: 18,
          },
          {
            title: '商品分类管理',
            outside: true,
            path: `${admin2PagePrefix}#/stock/setting/assortman?keepRoute=true`,
          },
          {
            title: '商品管控管理',
            path: '/stock/setting/goodsControlManage',
            permission: 632,
          },
        ],
      },
      {
        title: '库存预警',
        path: '/stock/query/inventoryWarning',
        permission: 624,
      },
      {
        title: '库存补货',
        path: '/stock/query/inventoryReplenishment',
        permission: 629,
      },
      {
        title: '采购价管控',
        path: '/stock/setting/purchase/price/control/list',
        permission: 1009,
      },
    ],
  },
  {
    title: '客户管理',
    icon: 'grow-icon-kehu',
    permission: 186,
    children: [
      {
        title: '客户档案',
        path: '/customer/customerFiles',
        permission: 107,
      },
      {
        title: '车辆档案',
        path: '/customer/carList',
        permission: 52,
      },
      // 1.0隐藏
      // {
      //   title: '会员卡管理',
      //   outside: true,
      //   path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/memberCard-management/memberCard`,
      //   params: [{ key: '$$companyId$$', value: 'companyId' }],
      // },
      {
        title: '车辆盘点表',
        path: '/customer/carInventory',
        permission: 525,
      },
      {
        title: '车型管理',
        path: '/customer/carModelList',
        permission: 586,
      },
      {
        title: '车管反馈',
        path: '/customer/VMfeedback',
        permission: 586,
      },
      {
        title: '车辆年审申请',
        path: '/customer/annualReview',
        permission: 1008,
      },
      {
        title: '协议',
        onlyHeadquarters: true,
        children: [
          {
            title: '客户协议',
            path: '/customer/agreementList',
            permission: 474,
            onlyHeadquarters: true,
          },
          {
            title: '客户协议价',
            path: '/customer/agreementPriceList',
            permission: 725,
            onlyHeadquarters: true,
          },
          {
            title: '常用报价模板',
            path: '/customer/usualQuote',
            permission: 580,
            onlyHeadquarters: true,
          },
        ],
      },
      {
        title: '厂家批量活动管理',
        path: '/customer/carFactoryCompensationActivity',
        permission: 1016,
      },
    ],
  },
  {
    title: '数据看板',
    icon: 'grow-icon-zhihui',
    permission: 286,
    children: [
      {
        title: '指挥中心',
        // title: '营业目标管控',
        path: '/dataBoard/turnoverTargetBoard',
        permission: 535,
        // onlyHeadquarters: true,
      },
      {
        title: '毛利总榜',
        path: '/dataBoard/grossMarginRankList',
        permission: 567,
      },
      {
        title: '毛利明细',
        path: '/dataBoard/marginDetail',
        permission: 568,
      },
      {
        title: '定损业绩看板',
        path: '/dataBoard/lossAssessmentOverview',
        permission: 602,
      },
      {
        title: '定损执行报表',
        path: '/dataBoard/lossAssessmentExecutionReport',
        permission: 603,
      },
      {
        title: '定损明细',
        path: '/dataBoard/lossAssessmentDetailList',
        permission: 605,
      },
      {
        title: '故障分诊明细',
        path: '/dataBoard/faultDiagnosisDetail',
        permission: 609,
      },
    ],
  },
  // {
  //   title: '其他事务',
  //   icon:'grow-icon_ok_shiwu',
  //   children: [
  //     {
  //       title: '车务代办',
  //     },
  //     {
  //       title: '网约车考证',
  //     },
  //   ],
  // },

  // bug764隐藏
  // {
  //   title: '总部业务',
  //   // children: [{ title: '开票管理' }], //blank
  // },
  {
    title: '系统管理',
    icon: 'grow-icon_ok_xitong',
    permission: 38,
    children: [
      {
        title: '设置向导',
        outside: true,
        path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/setting-overview`,
        params: [{ key: '$$companyId$$', value: 'companyId' }],
      },
      {
        title: '技术中心',
        children: [
          {
            title: '素材管理',
            path: '/technologyCenter/materialList',
            permission: 549,
            // onlyHeadquarters: true,
          },
        ],
      },
      {
        title: '问诊模型',
        permission: 552,
        children: [
          {
            title: '问诊模型库',
            path: '/technologyCenter/consultationLibrary',
            permission: 553,
          },
          {
            title: '故障现象库',
            path: '/technologyCenter/faultPhenomenon',
            permission: 554,
          },
          {
            title: '故障原因库',
            path: '/technologyCenter/failureCause',
            permission: 555,
          },
          {
            title: '疑难杂症库',
            path: '/technologyCenter/complicatedDisease',
            permission: 556,
          },
        ],
      },
      {
        title: '门店相关设置',
        children: [
          {
            // title: '门店管理',
            // outside: true,
            // path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/carShop-manage/company-list`,
            // params: [{ key: '$$companyId$$', value: 'companyId' }],
            title: '门店管理',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/carShop-manage/company-list`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
          {
            title: '管辖组设置',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/carShop-manage/company-group`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
            onlyHeadquarters: true,
          },
          {
            title: '开票主体',
            path: '/system/storeManagement',
            onlyHeadquarters: true,
          },
          {
            title: '角色权限',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/staff-manage/role`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
          {
            title: '员工管理',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/staff-manage/staff-list///////`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
          {
            title: '提成设置',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/commission/commission-goods`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
          {
            title: '返修追溯通知配置',
            path: '/system/systemManagement/gyBillRepairNotificationSetting/GyBillRepairNotificationSetting',
            permission: 639,
          },
        ],
      },
      {
        title: '车单相关设置',
        children: [
          {
            title: '工单设置',
            // outside: true,
            // path: `${admin2PagePrefix}#/basicSetting/workOrderSetting?keepRoute=true`,
            // onlyHeadquarters: true,
            path: '/system/singleSet',
            permission: 347,
          },
          {
            title: '打印设置',
            outside: true,
            path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/setting-print`,
            params: [{ key: '$$companyId$$', value: 'companyId' }],
          },
          // {
          //   title: '车单标签管理',
          //   outside: true,
          //   path: '',
          //   params: '',
          // },
          {
            title: '业务来源管理',
            // outside: true,
            // path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/bill-order/business-source-manage`,
            // params: [{ key: '$$companyId$$', value: 'companyId' }],
            path: '/system/businessSource',
            permission: 343,
          },
          {
            title: '保险公司管理',
            path: '/system/insuranceCompany',
            permission: 479,
            onlyHeadquarters: true,
          },
          {
            title: '定损提醒设置',
            path: '/system/lossWarningSet',
            permission: 516,
            onlyHeadquarters: true,
          },
          {
            title: '三包厂家管理',
            path: '/system/pakeManufacturer',
            permission: 486,
            onlyHeadquarters: true,
          },
          {
            title: '质保规则管理',
            path: '/system/warrantyRule',
            permission: 532,
            onlyHeadquarters: true,
          },
          {
            title: '结账方式管理',
            outside: true,
            path: `${admin2PagePrefix}#/basicSetting/checkoutMethods?keepRoute=true`,
          },
          {
            title: '服务管理',
            permission: 24,
            path: '/system/systemManagement/carOrderSettings/serviceManagement',
          },
          {
            title: '服务分类管理',
            outside: true,
            path: `${admin2PagePrefix}#/basicSetting/serviceClassificationManagement?keepRoute=true`,
          },
          // {
          //   title: '工单相关',
          //   outside: true,
          //   path: `${admin1PagePrefix}/container.html?companyId=35361#/bill-order/business-source-manage`,
          //   params: [{ key: '$$companyId$$', value: 'companyId' }],
          // },
          // { title: '检车项设置' }, //blank
        ],
      },
      // {
      //   title: '基础数据',
      //   children: [
      //     // 1.0隐藏
      //     // { title: '标签管理' }, //blank
      //   ],
      // },
      {
        title: '批量打印',
        permission: 501,
        path: '/carBill/batchPrint',
      },
      {
        title: '数据导入',
        permission: 419,
        // path: `${admin2PagePrefix}#/basicSetting/initialDataImport?keepRoute=true`,
        children: [
          {
            title: '车辆信息导入',
            path: '/system/carDataImport',
            permission: 420,
          },
          {
            title: '客户指令导入',
            path: '/system/clientCommandImport',
            permission: 547,
          },
          {
            title: '质保规则导入',
            path: '/system/warrantyRulesImport',
            permission: 542,
            onlyHeadquarters: true,
          },
          {
            title: '问诊模型导入',
            path: '/system/consultationModelImport',
            permission: 565,
            onlyHeadquarters: true,
          },
          {
            title: '数据导入记录',
            path: '/system/dataImportResultView',
            permission: 1011,
            onlyHeadquarters: true,
          },
        ],
      },
    ],
  },
  // {
  //   title: '连锁管理',
  //   children: [
  //     {
  //       title: '连锁管控',
  //       outside: true,
  //       path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/chain-manage-container/chain-manage`,
  //       params: [{ key: '$$companyId$$', value: 'companyId' }],
  //     },
  //   ],
  // },
];

// export const sidebarMenus = [
//   {
//     title: '首页',
//     icon: 'grow-a-icon_pitchon_home',
//     path: '/wel',
//   },
//   // 产品 去掉此菜单 2208091011
//   // {
//   //   title: '预约单',
//   //   icon: 'grow-a-icon_pitchon_order',
//   //   outside: true,
//   //   path: `${admin1PagePrefix}/container.html?companyId=$$companyId$$#/booking/bookings`,
//   //   params: [{ key: '$$companyId$$', value: 'companyId' }],
//   // },
//   {
//     title: '车单记录',
//     icon: 'grow-a-icon_pitchon_reception',
//     path: '/carBill/carBillList',
//     permission: 178,
//   },
//   {
//     title: '自费开单',
//     icon: 'grow-a-icon_pitchon_wallet',
//     path: '/carBill/editCarBill?type=0',
//     permission: 466,
//   },
//   {
//     title: '包干开单',
//     icon: 'grow-a-icon_pitchon_bao',
//     path: '/carBill/editCarBill?type=1',
//     permission: 467,
//   },
//   {
//     title: '理赔开单',
//     icon: 'grow-a-icon_pitchon_pei',
//     path: '/carBill/editCarBill?type=2',
//     permission: 468,
//   },
//   {
//     title: '索赔开单',
//     icon: 'grow-a-icon_pitchon_suo',
//     path: '/carBill/editCarBill?type=3',
//     permission: 469,
//   },
//   {
//     title: '车间工单',
//     icon: 'grow-a-icon_pitchon_workshop',
//     path: '/carBill/workshopBill',
//     permission: 493,
//   },
//   {
//     title: '在场车辆',
//     icon: 'grow-a-icon_pitchon_car',
//     path: '/carBill/presentCarList',
//     permission: 446,
//   },
//   // 1.0隐藏
//   // {
//   //   title: '帮助教程',
//   //   icon: 'grow-a-icon_pitchon_course',
//   //   path: '',
//   // },
// ];
