import http from '@/service/http';

//质保管理列表
export function carWarrantyRuleList(data) {
  return http.post('/admin/carWarrantyRule/list', data);
}
//新增质保规则
export function carWarrantyRuleSave(data) {
  return http.post('/admin/carWarrantyRule/save', data, {
    onlyReturnData: false,
  });
}
//修改质保规则
export function carWarrantyRuleUpdate(data) {
  return http.post('/admin/carWarrantyRule/update', data, {
    onlyReturnData: false,
  });
}
// 质保规则数据导入
export function carWarrantyImport(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/file/car/warranty/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}
//质保规则导入结果
export function carWarrantyImportResult(data) {
  return http.post('/admin/file/car/warranty/importResult', data);
}
//质保规则导入失败的列表导出
export const carWarrantyRuleFailDetail =
  '/admin/carWarrantyRule/importFailMessage/export';
//质保规则列表导出
export const exportCarWarrantyRuleUrl = '/admin/carWarrantyRule/list/export';
//质保详情
export function carWarrantyRuleDetail(id) {
  return http.post('/admin/carWarrantyRule/detail', { id });
}
