<template>
  <main class="login-wrap">
    <div class="content">
      <img src="@/assets/img/login_bg.jpg" alt="" />
      <div class="form">
        <div class="logo">
          <img src="@/assets/img/grow_logo.png" alt="grow" />
          <h2>格悦新能源·管理系统</h2>
        </div>
        <div class="box">
          <div class="login-way">
            <div
              class="account pointer"
              :class="{ active: activeName === 'account' }"
              @click="activeName = 'account'"
            >
              账号登录
            </div>
            <div
              class="qrcode pointer"
              :class="{ active: activeName === 'qrcode' }"
              @click="activeName = 'qrcode'"
            >
              扫码登录
            </div>
          </div>
          <div v-show="activeName === 'qrcode'" class="qrcode-tab">
            <div id="qrcodeDom"></div>
          </div>
          <div v-show="activeName === 'account'" class="account-tab">
            <div class="row">
              <img src="@/assets/img/icon_1.png" alt="" />
              <input
                type="text"
                v-model="ruleForm.phone"
                placeholder="请输入手机号"
                maxlength="11"
              />
            </div>
            <div class="row">
              <img src="@/assets/img/icon_2.png" alt="" />
              <input
                type="password"
                v-model="ruleForm.password"
                placeholder="请输入密码"
              />
            </div>
            <div class="flex">
              <div class="row">
                <input
                  type="text"
                  v-model="ruleForm.imageCode"
                  placeholder="请输入验证码"
                  @input="regCode"
                  @keyup.enter="submitForm('ruleForm')"
                />
              </div>
              <div class="captcha">
                <img :src="imgUrl" @click="changeImgCode()" />
              </div>
            </div>
            <div class="flex">
              <button class="log-btn pointer" @click="submitForm('ruleForm')">
                登 &nbsp;&nbsp; 录
              </button>
              <!-- <div class="download pointer">
              <img src="@/assets/img/icon_phone.png" alt="" />
              <span>下载手机版</span>
            </div> -->
            </div>
            <div class="forget-password">
              <a class="pointer" @click="forgetPassword">忘记密码</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 校验手机号弹窗 -->
    <verify-phone-number-dialog
      v-if="verifyPhoneNumberDialogVisibility"
      :visibility.sync="verifyPhoneNumberDialogVisibility"
      @pass="showChangePasswordDialog"
    ></verify-phone-number-dialog>
    <!-- 修改密码弹窗 -->
    <change-password-dialog
      v-if="changePasswordDialogVisibility"
      :visibility.sync="changePasswordDialogVisibility"
      :phoneNumber="ruleForm.phone"
    ></change-password-dialog>
  </main>
</template>

<script>
import axios from 'axios';
import cookieTool from '@/utils/tools/cookieTool';
import { oldServerUrl } from '@/config/compatibilityOlderSystems';
// import uuid from 'uuid';
import QRCode from 'qrcodejs2-fix';
// import SockJS from 'sockjs-client';
// import Stomp from 'stompjs';
import { userLogin, reservedCurrCompany, loginOverdue } from '@/api/system';

import { generateUUID } from '@/service/websocket/cim.web.sdk.js';

import WebsocketMixin from '@/service/websocket/WebsocketMixin';
import verifyPhoneNumberDialog from '@/components/businessComponent/verifyPhoneNumberDialog.vue';
import changePasswordDialog from '@/components/businessComponent/changePasswordDialog.vue';

export default {
  components: { verifyPhoneNumberDialog, changePasswordDialog },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'login',
  mixins: [WebsocketMixin],
  data: function () {
    return {
      // qrcode account
      activeName: 'account', //登录方式
      uid: '',
      // userUId: JSON.stringify(uuid.v1()),
      stompClient: '',
      ruleForm: {
        phone: '',
        password: '',
        imageCode: '',
      },
      rules: {
        // imageCode: [
        //     { required: true, message: '请输入验证码', trigger: 'blur' }
        // ]
      },
      imgUrl: '',

      // 校验手机号弹窗
      verifyPhoneNumberDialogVisibility: false,

      // 修改密码弹窗
      changePasswordDialogVisibility: false,
    };
  },
  created() {
    // this.init();
    localStorage.clear();
    sessionStorage.clear();
    cookieTool.clearCookie('growxny');
    cookieTool.clearCookie('sid');
    console.log(this.$route);
    if (this.$route.query.changePassword) {
      this.forgetPassword();
    }
  },
  mounted() {
    this.changeImgCode();
    this.createQrCode();
    // document.addEventListener('visibilitychange', this.handleDocument);
  },

  methods: {
    // init() {
    //   if (sessionStorage.getItem('superSuplierAuth'))
    //     return this.$router.push('/index');
    //   //参考https://www.jianshu.com/p/b8aa70bf1340\
    //   (this.uid = JSON.stringify({ type: 'login', uuid: uuid.v1() })), //登录二维码
    //     this.$api.auth.socketConfig().then((res) => {
    //       this.stompClient = Stomp.over(new SockJS(res.service));
    //       this.stompClient.connect(
    //         {},
    //         () => {
    //           // 向服务器发起websocket连接
    //           let uid = JSON.parse(this.uid).uuid;
    //           this.stompClient.send(
    //             '/wsApp/registerUid',
    //             {},
    //             JSON.stringify({ uid: uid })
    //           );
    //           this.stompClient.subscribe('/user/queue/scan-login', (msg) => {
    //             let msguid = JSON.parse(msg.body).uuid;
    //             if (uid == msguid) {
    //               this.$api.auth.loginUUID({ uuid: uid }).then((res) => {
    //                 // localStorage.setItem('company', JSON.stringify({realName:res.realName, phone:res.phone}));
    //                 let superSuplierAuth = JSON.stringify(res);
    //                 sessionStorage.setItem(
    //                   'superSuplierAuth',
    //                   superSuplierAuth
    //                 );
    //                 this.$router.push('/');
    //               });
    //             }
    //           }); //用户加入接口
    //         },
    //         (err) => {
    //           // 连接发生错误时的处理函数
    //         }
    //       );
    //     });
    // },
    // handleDocument() {
    //   if (!document.hidden) {
    //     this.$data.imgUrl = JSON.parse(localStorage.getItem('codeImg'));
    //     if (
    //       !JSON.parse(localStorage.getItem('codeImg')) &&
    //       !JSON.parse(localStorage.getItem('user'))
    //     ) {
    //       this.changeImgCode();
    //     } else {
    //       this.$router.push('/');
    //     }
    //   }
    // },
    createQrCode() {
      let deviceId = generateUUID();
      window.localStorage.setItem('deviceId', deviceId);
      this.uid = deviceId;
      let qrcode = new QRCode('qrcodeDom', {
        width: 184,
        height: 180, // 高度
        text: 'gyuser:' + this.uid,
      });
      this.initWebsocket();
      // { onMessageReceived: this.onMessageReceivedCustom.bind(this), }
    },
    handleMessageReceived(message) {
      let { scanLoginPhone, sanLoginPw, companyId } = JSON.parse(
        message.content
      );
      if (companyId) {
        sessionStorage.setItem('scanCompanyId', companyId);
      }
      if (scanLoginPhone) {
        this.doLogin({ phone: scanLoginPhone, password: sanLoginPw });
      } else {
        console.log(`扫码登录失败,手机号：${scanLoginPhone}`);
      }
    },

    regCode() {
      this.ruleForm.imageCode = this.ruleForm.imageCode.replace(
        /[^0-9a-zA-Z]/g,
        ''
      );
    },
    submitForm() {
      let { phone, password, imageCode } = this.ruleForm;
      if (!phone) {
        return this.$message.error('请输入账号');
      }
      if (!password) {
        return this.$message.error('请输入密码');
      }
      if (!imageCode) {
        return this.$message.error('请输入验证码');
      }
      this.doLogin();
    },
    async doLogin(params) {
      var form = params || this.ruleForm;
      // 新版登录
      userLogin(form).then(async (res) => {
        // 校验账号是否允许登录
        var loginOverdueResult = await loginOverdue(form.phone);
        if (!loginOverdueResult) {
          this.$alert(
            '密码超过1个月未修改，为了信息安全，请“找回密码”后再登录',
            '提示',
            {
              confirmButtonText: '找回密码',
              callback: (action) => {
                if (action == 'confirm') {
                  this.forgetPassword();
                }
              },
            }
          );
          return;
        }
        if (res.code == 200) {
          this.$store.state.user.userInfo = Object.assign(
            {},
            this.$store.state.user.userInfo,
            res.data
          );
          sessionStorage.setItem('userId', res.data.userId);
          sessionStorage.setItem('token', res.data.token);

          // this.$router.push('wel');

          // 旧版
          this.compatibleOldAdmin(form, !!params);
        } else {
          this.$message.success(res.message);
        }
      });
    },

    /**
     * 兼容旧系统
     */
    async compatibleOldAdmin(form, scan) {
      // 旧版登录
      // let { phone } = data;
      // localStorage.setItem('X-raid', phone);

      let oldLoginUrl = `${oldServerUrl}/web/admin/${
        scan ? 'loginScan' : 'loginCode'
      }`;

      let loginResult = await axios
        .post(oldLoginUrl, form, {
          noHandleError: true,
          noShowWaiting: true,
        })
        .catch((err) => {
          console.error(err);
        });

      if (loginResult.data?.success) {
        localStorage.setItem('phone', this.ruleForm.phone);
        localStorage.setItem(
          'companyId',
          loginResult.data?.resultObject?.companyId
        );
        let superSuplierAuth = JSON.stringify(loginResult.data.resultObject);
        localStorage.setItem('company', JSON.stringify(superSuplierAuth));
        sessionStorage.setItem(
          'superSuplierAuth',
          JSON.stringify(superSuplierAuth)
        );
        sessionStorage.setItem(
          'companyId',
          loginResult.data?.resultObject?.companyId
        );
        // 新版库存查询/批次余量中需使用该变量
        sessionStorage.setItem(
          'selectedCompany',
          JSON.stringify({
            companyId: loginResult.data?.resultObject?.companyId,
            companyName: '',
          })
        );

        this.$router.push('wel');

        //获取权限相关数据
        // axios
        //   .post(
        //     oldServerUrl + '/web/company/getCurrentUserAndCompany',
        //     {},
        //     {
        //       noHandleError: true,
        //     }
        //   )
        //   .then((res) => {
        //     console.log(res);
        //     let { dealer, user, company, authMark } = res.data.resultObject;
        //     localStorage.setItem('authMark', JSON.stringify(authMark));
        //     sessionStorage.setItem('authMark', JSON.stringify(authMark));
        //     localStorage.setItem('user', JSON.stringify(user));
        //     localStorage.setItem('company', JSON.stringify(company));

        //     this.$router.push('/wel');
        //   });
      } else {
        return this.$message.error(loginResult.data.msg);
      }

      let superSuplierAuth = JSON.parse(
        sessionStorage.getItem('superSuplierAuth')
      );
      let phone = JSON.parse(localStorage.getItem('phone')) || '';
      let companyId = JSON.parse(localStorage.getItem('companyId'));
      let _companyId, companyName;

      let last = await reservedCurrCompany();
      if (last?.companyId) {
        _companyId = last?.companyId;
      }

      // 旧版判断选中门店逻辑
      // if (
      //   !superSuplierAuth ||
      //   (phone.toString() === superSuplierAuth.phone.toString() && companyId)
      // ) {
      //   _companyId = companyId;
      // } else {
      //   superSuplierAuth.companyInfoList.forEach((item) => {
      //     if (item.companyId == superSuplierAuth.companyId) {
      //       _companyId = item.companyId;
      //       return;
      //     }
      //   });
      // }
      this.switchCompany(_companyId);
    },
    switchCompany(companyId, companyName = '') {
      // if (companyName && companyName === this.companyName) return;
      // else {
      axios
        .post(
          oldServerUrl + '/web/company/switchCompany',
          { companyId },
          {
            noHandleError: true,
            noShowWaiting: true,
          }
        )
        .then((res) => {
          // this.$api.carShop.switchCompany({ companyId }).then((res) => {
          const { userId, companyId, phone, headicon } =
            res?.data?.resultObject || {};
          // this.companyName = companyName;
          // this.companyId = companyId;
          // this.$store.commit('setCompany', res);
          // this.$store.commit('setHeadicon', headicon);
          localStorage.setItem('companyId', companyId);
          localStorage.setItem('phone', phone);
          sessionStorage.setItem('companyId', companyId);
          sessionStorage.setItem('phone', phone);
          sessionStorage.setItem(
            'superSuplierAuth',
            JSON.stringify(res?.data?.resultObject || {})
          );
          sessionStorage.setItem(
            'selectedCompany',
            JSON.stringify({ companyId, companyName })
          );
          //获取当前用户和车店信息
          this.initCarInfo();
          // });
        });

      // }
    },
    initCarInfo() {
      // let { getCurrentUserAndCompany, queryAllFeatureUsingStateV4_2_0 } = this.$api.carShop;

      axios
        .post(
          oldServerUrl + '/web/company/getCurrentUserAndCompany',
          {},
          {
            noHandleError: true,
            noShowWaiting: true,
          }
        )
        .then((res = {}) => {
          let { dealer, user, company, authMark } = res.data.resultObject;
          // if (dealer) {
          //   this.dealer = dealer;
          // } else {
          //   this.dealer = {
          //     brandPicture: logo,
          //     dealerName: '格悦新能源',
          //   };
          // }
          // this.realName = user.realName;
          // this.$store.state.public.realName = user.realName;
          // this.$store.commit('setHeadicon', user.headicon);
          // this.companyName = company.companyName;
          // this.companyId = company.companyId;
          localStorage.setItem('authMark', JSON.stringify(authMark));
          sessionStorage.setItem('authMark', JSON.stringify(authMark));
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('company', JSON.stringify(company));
          axios
            .post(
              oldServerUrl + '/feature/queryAllFeatureUsingStateV4_2_0',
              {},
              {
                noHandleError: true,
                noShowWaiting: true,
              }
            )
            .then((res) => {
              let featureList = res.featureList;
              sessionStorage.setItem(
                'companyMark',
                JSON.stringify(featureList)
              );
              // this.$store.commit('setInitSystem', true);
            })
            .catch(() => {
              sessionStorage.setItem('companyMark', '');
              // this.$store.commit('setInitSystem', true);
            });

          // this.$api.company.getOperationalCompanies().then((res) => {
          //   this.$methods.setLocalStorage('operationCompanies', res);
          // });
          //获取操作门店list
          axios
            .post(
              oldServerUrl + '/web/company/getCurrentUserManageCompanies',
              {},
              {
                noHandleError: true,
                noShowWaiting: true,
              }
            )
            .then((res) => {
              console.log('操作门店', res);
              res = res.data?.resultObject?.map((item) => {
                //根据ele-ui转换成需要的格式
                // item.id = item.companyId
                // item.value = item.companyName
                item.label = item.companyName;
                item.value = item.companyId;
                return item;
              });
              res &&
                localStorage.setItem('operationCompanies', JSON.stringify(res));
            });

          // this.$api.company.getListChainCompanies().then((res) => {
          //   this.$methods.setLocalStorage('listChainCompanies', res);
          // });

          // this.getOpersListing();
        });
      // this.searchCompany();
    },

    // enterUp(formName) {
    //   if (
    //     /macintosh|mac os x/i.test(navigator.userAgent) &&
    //     this.ruleForm.imageCode.length < 4
    //   ) {
    //     return;
    //   }
    //   this.submitForm(formName);
    // },
    // submitForm(formName) {
    //   setTimeout(() => {
    //     let { phone, password, imageCode } = this.ruleForm;
    //     if (!phone) {
    //       this.$alert('请输入账号', '提示', {
    //         confirmButtonText: '确定',
    //         closeOnClickModal: true,
    //       });
    //     } else if (!password) {
    //       this.$alert('请输入密码', '提示', {
    //         confirmButtonText: '确定',
    //         closeOnClickModal: true,
    //       });
    //     } else if (!imageCode) {
    //       this.$alert('请输入验证码', '提示', {
    //         confirmButtonText: '确定',
    //         closeOnClickModal: true,
    //       });
    //     } else {
    //       this.$api.auth
    //         .login({
    //           phone,
    //           password,
    //           imageCode,
    //         })
    //         .then((res) => {
    //           let superSuplierAuth = JSON.stringify(res);
    //           sessionStorage.setItem('superSuplierAuth', superSuplierAuth);
    //           this.$router.push('/');
    //           this.$methods.removeLocalStorage('codeImg');
    //           document.removeEventListener(
    //             'visibilitychange',
    //             this.handleDocument
    //           );
    //         });
    //     }
    //   }, 3e2);
    // },
    changeImgCode() {
      axios
        .get(oldServerUrl + '/code?type=2', {
          responseType: 'arraybuffer',
          noHandleError: true,
          noShowWaiting: true,
        })
        .then((res) => {
          this.imgUrl =
            'data:image/png;base64,' +
            btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
        });

      // this.$api.auth.getImgCode(2).then((res) => {
      //   this.$data.imgUrl = res;
      //   localStorage.setItem('codeImg', JSON.stringify(res));
      // });
    },

    /**
     * 忘记密码
     */
    forgetPassword() {
      this.verifyPhoneNumberDialogVisibility = true;
    },

    /**
     * 显示修改密码弹窗
     */
    showChangePasswordDialog(e) {
      this.ruleForm.phone = e.phoneNumber;
      this.changePasswordDialogVisibility = true;
    },
  },
  destroyed() {
    this.CIMPushManager?.stop();
    this.CIMPushManager = null;
  },
};
</script>

<style scoped lang="scss">
.login-wrap {
  width: 100%;
  height: 100%;
  display: block; // 兼容ie
  position: relative;
  overflow: hidden;
  background: #000;
  font-family: 'Microsoft Yahei', tahoma, arial, Hiragino Sans GB, '\5b8b\4f53',
    sans-serif;
  font-size: 14px;

  .content {
    width: 1440px;
    height: 900px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    img {
      width: 100%;
      // height: 100%; // 去掉高度，兼容ie
      object-fit: contain;
    }
  }

  .form {
    width: 320px;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    top: 24%;
    right: 0;
    bottom: 0;
    left: 0;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      > img {
        width: 90%;
      }

      > h2 {
        margin-top: 12px;
        font-size: 17px;
        font-weight: 500;
        color: #fff;
        letter-spacing: 2px;
      }
    }

    .box {
      margin-top: 20%;

      .login-way {
        display: flex;
        justify-content: space-around;
        margin: 10px 0;
        color: #fff;

        > .active {
          color: #fabe00;
        }
      }

      .qrcode-tab {
        display: flex;
        justify-content: center;
        background: #fff;
        border-radius: 9px;
        padding: 24px 0;
        margin: 10% 14%;
      }

      .account-tab {
        margin-top: 10%;

        .row {
          height: 30px;
          display: flex;
          align-items: center;
          padding: 4px 6px;
          border-radius: 3px;
          background: #fff;
          box-shadow: 0px 16px 30px 1px rgb(187 187 187 / 10%);
          flex: 1; // 兼容IE

          & + .row {
            margin-top: 6%;
          }

          > img {
            width: 16px;
            margin-right: 8px;
          }

          > input {
            outline: none;
            border: none;
            font-size: 14px;
            flex: 1;
            width: 100%;
          }
        }

        .flex {
          margin-top: 6%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            // width: 45%;

            & + div {
              margin-left: 10px;
            }
          }

          .captcha {
            width: 36%;
            height: 38px;
            line-height: 38px;
            border-radius: 3px;
            background: rgba(255, 255, 255, 0.3);
            > img {
              width: 100%;
              height: 100%;
              border-radius: 3px;
            }
          }

          .log-btn {
            width: 100%;
            height: 38px;
            border: none;
            border-radius: 3px;
            background: #fabe00;
            color: #fff;
            text-align: center;
            font-size: 16px;

            &:hover {
              background: #eeb702;
            }
          }

          .download {
            height: 38px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #fff;

            > span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.forget-password {
  margin-top: 6%;
  text-align: right;
  font-size: 14px;
  color: #eee;
  a:hover {
    color: #fff;
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ddd;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ddd;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ddd;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ddd;
}
</style>
