<template>
  <el-dialog
    title="温馨提示"
    :visible="visibility"
    width="50%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <p class="tip-word">
      <i class="el-icon-warning el-icon__left"></i>
      当前车单消费客户“{{
        customerName
      }}”车单项目（标红项）报价与协议价存在不一致
    </p>
    <el-table
      :data="list"
      style="width: 100%"
      class="service-table"
      border
      default-expand-all
      row-key="id"
      :tree-props="{
        children: 'goods',
      }"
    >
      <el-table-column prop="title" width="80" label="序号">
        <template #default="{ row }">
          <div :class="{ red: !row.isConsistent }">{{ row.title }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="itemName" label="项目名称" width="180">
        <template #default="{ row }">
          <div :class="{ red: !row.isConsistent }">{{ row.itemName }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="itemCode" label="项目编码" width="180">
        <template #default="{ row }">
          <div :class="{ red: !row.isConsistent }">{{ row.itemCode }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="车单报价" min-width="120">
        <template #default="{ row }">
          <div :class="{ red: !row.isConsistent }">{{ row.price }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="agreePrice" label="协议价" min-width="120">
        <template #default="{ row }">
          <div :class="{ red: !row.isConsistent }">
            {{ row.agreePrice }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <template slot="footer">
      <p class="tip-word">点击确定返回修改标红项的报价为协议价</p>
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'inconsistentAgreementPriceDialog',
  props: {
    visibility: { type: Boolean, default: false },
    // 不一致
    list: { type: Array, default: () => [] },
    customerName: String,
    id: [String, Number],
  },
  data() {
    return {
      agreementPriceMap: {},
    };
  },
  computed: {},
  created() {
    this.list.forEach((service, i) => {
      service.id = service.serviceItemId;
      service.title = i + 1 + '';
      service.type = 'service';
      if (!service.isConsistent) {
        this.agreementPriceMap[service.id] = service.agreePrice;
      }

      service.goods?.forEach((goods, j) => {
        goods.id = goods.goodsId;
        goods.title = i + 1 + '.' + (j + 1);
        goods.type = 'goods';
        if (!goods.isConsistent) {
          this.agreementPriceMap[goods.id] = goods.agreePrice;
        }
      });
    });
  },

  methods: {
    confirm() {
      const route = {
        name: 'editCarBill',
        query: {
          id: this.id,
        },
        params: {
          agreementPriceMap: this.agreementPriceMap,
        },
      };
      this.$store.dispatch('REPLACE_TAG', route);
      this.handleClose();
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.tip-word {
  margin: 10px 0 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: $orange;
}
.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}
// 不显示收起图标
.service-table.el-table ::v-deep .el-table__expand-icon {
  display: none;
}
.service-table.el-table ::v-deep .el-table__indent {
  padding: 0 !important;
}
.service-table.el-table ::v-deep .el-table__placeholder {
  width: 0 !important;
}
</style>
