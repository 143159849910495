<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        ref="form"
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  门店
                  <el-tooltip
                    effect="light"
                    content="门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="车牌">
              <el-input
                v-model="form.carNumber"
                placeholder="请输入车牌"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入车单号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="654"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="回款状态">
              <el-select
                v-model="form.receivingStatus"
                placeholder="请选择回款状态"
              >
                <el-option
                  v-for="item in receivingStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结账状态">
              <el-select
                v-model="form.checkoutStatus"
                placeholder="请选择结账状态"
              >
                <el-option
                  v-for="item in checkoutStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="核销状态">
              <el-select
                v-model="form.writeOffStatus"
                placeholder="请选择核销状态"
              >
                <el-option
                  v-for="item in writeOffStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="提单状态">
              <el-select
                v-model="form.submitStatus"
                placeholder="请选择提单状态"
              >
                <el-option
                  v-for="item in submitStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结账时间">
              <el-date-picker
                class="w100"
                v-model="form.checkDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="回款主体" prop="invoicingEntity">
              <el-select
                v-model="form.invoicingEntity"
                clearable
                filterable
                placeholder="请选择回款主体"
              >
                <el-option
                  v-for="item in invoicingEntities"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="保险公司" prop="insuranceCompanyId">
              <el-select
                v-model="form.insuranceCompanyId"
                clearable
                filterable
                placeholder="请选择保险公司"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="回款时间" prop="receivingDateTimes">
              <el-date-picker
                class="w100"
                v-model="form.receivingDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="认领人" prop="claimOperator">
              <el-select
                v-model="form.claimOperator"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAllUser"
                :loading="searchAllUserLoading"
                @select="doVerify('claimOperator')"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.realName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="流程单号">
              <el-input
                v-model="form.internalSettTrackingSeq"
                placeholder="请输入流程单号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="655"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <span
              v-auth="656"
              v-if="!scope.row.id || scope.row.hasWriteOffStatus !== '已核销'"
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleEdit(scope.row)"
              >编辑认领</span
            >
            <span
              v-auth="658"
              class="text_operation blue"
              v-if="scope.row.id"
              @click="handleGotoDetail(scope.row)"
              >认领详情</span
            >
          </template>
        </el-table-column>
        <el-table-column width="200" label="门店" prop="companyName">
        </el-table-column>
        <el-table-column width="140" label="车牌" prop="carNumber">
        </el-table-column>
        <el-table-column width="200" label="车单号" prop="billCode">
          <template #default="{ row }">
            <gotoCarDetail
              :billCode="row.billCode"
              :billId="row.billId"
            ></gotoCarDetail>
          </template>
        </el-table-column>
        <el-table-column width="120" label="车单金额(元)" prop="billAmount">
        </el-table-column>
        <el-table-column
          width="120"
          label="待收金额(元)"
          prop="unclearedDebtFixedAmount"
        >
        </el-table-column>
        <el-table-column width="100" label="结算状态" prop="hasSettlement">
        </el-table-column>
        <el-table-column width="160" label="结账时间" prop="settlementDateTime">
        </el-table-column>
        <el-table-column width="120" label="已认领金额(元)" prop="claimAmount">
        </el-table-column>
        <el-table-column
          width="120"
          label="可认领金额(元)"
          prop="unClaimAmount"
        >
        </el-table-column>
        <el-table-column width="120" label="挂账金额(元)" prop="pendingAmount">
        </el-table-column>
        <el-table-column width="120" label="坏账金额(元)" prop="badDebtsAmount">
        </el-table-column>
        <el-table-column width="100" label="回款状况" prop="receivingStatus">
        </el-table-column>
        <el-table-column width="100" label="认领人" prop="claimUserName">
        </el-table-column>
        <el-table-column width="160" label="认领时间" prop="claimDateTime">
        </el-table-column>
        <el-table-column
          width="140"
          label="认领备注"
          prop="claimRemark"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="140"
          label="核销备注"
          prop="writeOffRemark"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="140"
          label="反核销备注"
          prop="reversalWriteOffRemark"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column width="100" label="核销状态" prop="hasWriteOffStatus">
        </el-table-column>
        <el-table-column label="核销操作" width="300">
          <template slot-scope="scope">
            <span
              v-auth="659"
              v-if="canShowGotoSubmitWorkTask(scope.row)"
              style="margin-right: 10px"
              class="text_operation blue"
              @click="gotoSubmitWorkTask(scope.row)"
              >转到提单</span
            >
            <span
              v-auth="664"
              v-if="
                scope.row.id &&
                scope.row.hasSettlement === '已结算' &&
                (scope.row.hasWriteOffStatus === '已核销' ||
                  scope.row.hasWriteOffStatus === '部分核销')
              "
              style="margin-right: 10px"
              class="text_operation blue"
              @click="openChangeWriteOffRemark(scope.row)"
              >变更核销备注</span
            >
            <span
              v-auth="665"
              v-if="
                scope.row.id &&
                scope.row.hasSettlement === '已结算' &&
                (scope.row.hasWriteOffStatus === '已核销' ||
                  scope.row.hasWriteOffStatus === '部分核销')
              "
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleReversalWriteOffStatus(scope.row)"
              >反核销</span
            >
            <span
              v-auth="664"
              v-if="
                scope.row.id &&
                scope.row.hasSettlement === '已结算' &&
                (scope.row.hasWriteOffStatus === '未核销' ||
                  scope.row.hasWriteOffStatus === '部分核销')
              "
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleWriteOffStatus(scope.row)"
              >核销</span
            >
          </template>
        </el-table-column>
      </el-table>
      <ClaimBankTransactionDetailDialog
        v-if="showDetailDialog"
        :visibility.sync="showDetailDialog"
        :id="editId"
        :billId="billId"
        :entityData="rowEntityData"
      ></ClaimBankTransactionDetailDialog>
      <ClaimBankTransactionDialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :id="editId"
        :billId="billId"
        :rowEntityData="rowEntityData"
        :companyList="companyList"
        :insuranceCompanyList="insuranceCompanyList"
        :invoicingEntities="invoicingEntities"
        @save="getData"
      ></ClaimBankTransactionDialog>
      <el-dialog
        :close-on-click-modal="false"
        title="认领核销备注变更"
        :visible.sync="showChangeWriteOffRemarkDialog"
        width="30%"
      >
        <p class="dialogSecTitle">点击“确定”更新核销备注信息。</p>
        <div>
          <el-row>
            <el-col :span="24">
              <el-input
                maxlength="250"
                type="textarea"
                class="textarea"
                :rows="3"
                resize="none"
                placeholder="请输入核销备注"
                v-model="writeOffRemark"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showChangeWriteOffRemarkDialog = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmChangeWriteOffRemark"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        title="认领核销"
        :visible.sync="showWriteOffStatusDialog"
        width="30%"
      >
        <p>
          点击“确定”核销单据，车单已支付金额和未支付金额将更新, 应收账款将更新。
        </p>
        <div>
          <el-row>
            <el-col :span="24">
              <el-input
                maxlength="250"
                type="textarea"
                class="textarea"
                :rows="3"
                resize="none"
                placeholder="请输入核销说明"
                v-model="writeOffRemark"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showWriteOffStatusDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmWriteOff">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        title="认领反核销"
        :visible.sync="showReversalWriteOffStatusDialog"
        width="30%"
      >
        <p>
          点击“确定”反核销单据，单据认领金额核销收款将退回，车单已支付金额和未支付金额将更新。
        </p>
        <div>
          <el-row>
            <el-col :span="24">
              <el-input
                maxlength="250"
                type="textarea"
                class="textarea"
                :rows="3"
                resize="none"
                placeholder="请输入反核销原因"
                v-model="reversalWriteOffRemark"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showReversalWriteOffStatusDialog = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmReversalWriteOff"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import gotoCarDetail from '@/components/businessComponent/gotoCarDetail.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
import ClaimBankTransactionDialog from './components/ClaimBankTransactionDialog.vue';
import ClaimBankTransactionDetailDialog from './components/ClaimBankTransactionDetailDialog.vue';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  transactionClaimList,
  getPreLoadSaveOrUpdateDropListData,
  transactionClaimListExportUrl,
  transactionClaimHandleWriteOff,
  transactionClaimHandleReversalWriteOff,
  updateWriteOffRemark,
} from '@/api/financial/financialOver';
import { getAllUser } from '@/api/employee';
import { hasAuth } from '@/utils/permissions';
import { postGetAllCompanyList } from '@/api/stock/inventoryWarning/index';

import { mapState } from 'vuex';

export default {
  name: 'transaction_claim',
  components: {
    pagination,
    operateCompanyCascader,
    ClaimBankTransactionDialog,
    ClaimBankTransactionDetailDialog,
    gotoCarDetail,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      startPage: 1,
      endPage: 1,
      exportUrl: transactionClaimListExportUrl,

      form: {
        operateCompanyId: [],
        billCode: '',
        carNumber: '',
        receivingStatus: '',
        checkoutStatus: '',
        writeOffStatus: '',
      },
      receivingStatusEnum: [],
      checkoutStatusEnum: [],
      writeOffStatusEnum: [],
      submitStatusEnum: [
        {
          value: '',
          desc: '全部',
        },
        {
          value: 0,
          desc: '未提单',
        },
        {
          value: 1,
          desc: '已提单',
        },
        {
          value: 2,
          desc: '完成提单',
        },
      ],
      // 展示更多筛选
      showMore: false,
      listData: [],
      showDetailDialog: false,
      showEditDialog: false,
      billId: '',
      editId: '',
      rowEntityData: {},
      showChangeWriteOffRemarkDialog: false,
      showWriteOffStatusDialog: false,
      writeOffId: undefined,
      writeOffRemark: '',
      showReversalWriteOffStatusDialog: false,
      reversalWriteOffId: undefined,
      reversalWriteOffRemark: '',
      // 预留给认领的数据
      companyList: [],
      insuranceCompanyList: [],
      invoicingEntities: [],
      searchAllUserLoading: false,
      searchUserTimer: undefined,
      allUserList: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
    this.preLoadSaveOrUpdateDropListData();
    this.getData();
  },
  methods: {
    init() {
      this.form.operateCompanyId = [this.companyId];
      this.receivingStatusEnum.push({
        value: '',
        desc: '全部',
      });
      this.receivingStatusEnum.push({
        value: 0,
        desc: '部分回款',
      });
      this.receivingStatusEnum.push({
        value: 1,
        desc: '全部回款',
      });

      this.checkoutStatusEnum.push({
        value: '',
        desc: '全部',
      });
      this.checkoutStatusEnum.push({
        value: 0,
        desc: '未结算',
      });
      this.checkoutStatusEnum.push({
        value: 1,
        desc: '已结算',
      });

      this.writeOffStatusEnum.push({
        value: '',
        desc: '全部',
      });
      this.writeOffStatusEnum.push({
        value: 0,
        desc: '未核销',
      });
      this.writeOffStatusEnum.push({
        value: 1,
        desc: '已核销',
      });
      this.writeOffStatusEnum.push({
        value: 2,
        desc: '部分核销',
      });
      // 如果是跳转过来的，拿到门店、车单号、车牌号，直接定位到车单中，以便进行认领——如定损单页面、接车单页面
      const queryCompanyId = this.$route.query.companyId;
      if (queryCompanyId) {
        this.form.operateCompanyId = [Number(queryCompanyId)];
      }
      const queryCarNumber = this.$route.query.carNumber;
      if (queryCarNumber) {
        this.form.carNumber = queryCarNumber;
      }
      const queryBillCode = this.$route.query.billCode;
      if (queryBillCode) {
        this.form.billCode = queryBillCode;
      }
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    canShowGotoSubmitWorkTask(row) {
      return (
        hasAuth(659) &&
        hasAuth(661) &&
        row.id &&
        row.hasSettlement === '已结算' &&
        (row.hasWriteOffStatus === '未核销' ||
          row.hasWriteOffStatus === '部分核销')
      );
    },
    /**
     * 预先加载新增/编辑的下拉框数据
     */
    preLoadSaveOrUpdateDropListData() {
      this.getAllCompanyList();
      this.getInsuranceCompanyListAndInvoicingEntities();
    },
    /**
     * 查询体系内的门店列表
     */
    async getInsuranceCompanyListAndInvoicingEntities() {
      const _this = this;
      getPreLoadSaveOrUpdateDropListData().then((res) => {
        const { insuranceCompanyList, invoicingEntities } = res;
        _this.insuranceCompanyList = insuranceCompanyList;
        _this.invoicingEntities = invoicingEntities;
      });
    },
    /**
     * 查询体系内的门店列表
     */
    async getAllCompanyList() {
      const data = {};
      postGetAllCompanyList(data).then((res) => {
        this.companyList = [];
        const { company, companyChildren } = res.data.resultObject || {};
        if (companyChildren && companyChildren.length > 0) {
          companyChildren.forEach((com) => {
            this.companyList.push({
              companyId: com.companyId,
              companyName: com.companyName,
              shortName: com.shortName,
            });
          });
        } else {
          if (company) {
            this.companyList.push({
              companyId: company.companyId,
              companyName: company.companyName,
              shortName: company.shortName,
            });
          }
        }
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(654);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        checkBeginDateTime: this.form.checkDateTimes?.[0]
          ? this.form.checkDateTimes?.[0] + ' 00:00:00'
          : '',
        checkEndDateTime: this.form.checkDateTimes?.[1]
          ? this.form.checkDateTimes?.[1] + ' 23:59:59'
          : '',
        receivingBeginDateTime: this.form.receivingDateTimes?.[0]
          ? this.form.receivingDateTimes?.[0] + ' 00:00:00'
          : '',
        receivingEndDateTime: this.form.receivingDateTimes?.[1]
          ? this.form.receivingDateTimes?.[1] + ' 23:59:59'
          : '',
        hasAccountsReceivable: true,
      };
      var data = Object.assign({}, this.form, this.pageOption, times);
      transactionClaimList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    /**
     * 跳转到车单详情页面
     * @param billId 车单ID
     */
    goBillDetail(billId) {
      var res = hasAuth(35);
      if (!res) {
        this.$message.warning('没有接车单详情页面的权限，无法跳转');
        return;
      }
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: billId,
          t: new Date().getTime(),
        },
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign(this.form, {}),
      });
    },
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      if (this.searchUserTimer) {
        clearTimeout(this.searchUserTimer);
      }
      this.searchGoodTimer = setTimeout(() => {
        this.searchAllUserLoading = true;
        let params = {};
        if (typeof msg === 'object') {
          params = msg;
        } else {
          params = { msg };
        }
        getAllUser(params)
          .then((res) => {
            this.allUserList = res.records || [];
          })
          .finally(() => {
            this.searchAllUserLoading = false;
          });
      }, 800);
    },
    /**
     * 校验某个字段
     */
    doVerify(prop) {
      this.$refs.form.validateField(prop);
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        operateCompanyId: [this.companyId],
      };
    },
    /**
     * 编辑认领流水
     */
    handleEdit(row) {
      this.billId = row.billId;
      this.editId = row.id;
      this.rowEntityData = row;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },
    /**
     * 回款认领详情
     */
    handleGotoDetail(row) {
      this.billId = row.billId;
      this.editId = row.id;
      this.rowEntityData = row;
      this.$nextTick().then(() => {
        this.showDetailDialog = true;
      });
    },
    /**
     * 弹出核销备注修改框
     * @param row 认领记录
     */
    openChangeWriteOffRemark(row) {
      this.writeOffId = row.id;
      this.billId = row.billId;
      this.writeOffRemark = '';
      this.showChangeWriteOffRemarkDialog = true;
    },
    /**
     * 确认核销备注变更
     */
    confirmChangeWriteOffRemark() {
      if (this.writeOffRemark === '') {
        this.$message.warning('请输入核销备注！');
        return;
      }
      const data = {
        id: this.writeOffId,
        billId: this.billId,
        remark: this.writeOffRemark,
      };
      updateWriteOffRemark(data)
        .then((res) => {
          this.$message.warning(res || '保存成功');
          this.getData(false);
          this.showChangeWriteOffRemarkDialog = false;
        })
        .catch((err) => {
          this.showChangeWriteOffRemarkDialog = false;
          this.$message.error(err?.data?.message || '变更核销备注异常');
        });
    },
    /**
     * 核销
     * @param {Object} row
     */
    handleWriteOffStatus(row) {
      this.writeOffId = row.id;
      this.billId = row.billId;
      this.writeOffRemark = '';
      this.showWriteOffStatusDialog = true;
    },
    /**
     * 确认反核销
     */
    confirmWriteOff() {
      if (this.writeOffRemark === '') {
        this.$message.warning('请输入核销说明！');
        return;
      }
      const data = {
        id: this.writeOffId,
        billId: this.billId,
        remark: this.writeOffRemark,
      };
      transactionClaimHandleWriteOff(data)
        .then((res) => {
          this.$message.warning(res || '核销成功');
          this.getData(false);
          this.showWriteOffStatusDialog = false;
        })
        .catch((err) => {
          this.showWriteOffStatusDialog = false;
          this.$message.error(err?.data?.message || '核销异常');
        });
    },
    /**
     * 跳转到认领记录提单列表
     * @param row 认领记录
     */
    gotoSubmitWorkTask(row) {
      this.$router.push({
        name: 'transaction_submitwork',
        query: {
          companyId: row.companyId,
          carNumber: row.carNumber,
          billCode: row.billCode,
          t: new Date().getTime(),
        },
      });
    },
    /**
     * 反核销
     * @param {Object} row
     */
    handleReversalWriteOffStatus(row) {
      this.reversalWriteOffId = row.id;
      this.billId = row.billId;
      this.reversalWriteOffRemark = '';
      this.showReversalWriteOffStatusDialog = true;
    },
    /**
     * 确认反核销
     */
    confirmReversalWriteOff() {
      if (this.reversalWriteOffRemark === '') {
        this.$message.warning('请输入反核销原因！');
        return;
      }
      const data = {
        id: this.reversalWriteOffId,
        billId: this.billId,
        remark: this.reversalWriteOffRemark,
      };
      transactionClaimHandleReversalWriteOff(data)
        .then((res) => {
          this.$message.warning(res || '反核销成功');
          this.getData(false);
          this.showReversalWriteOffStatusDialog = false;
        })
        .catch((err) => {
          this.showReversalWriteOffStatusDialog = false;
          this.$message.error(err?.data?.message || '反核销异常');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
.dialogSecTitle {
  padding: 10px 5px;
}
</style>
