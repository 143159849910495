<template>
  <el-drawer
    title="流程详情"
    :visible="visibility"
    size="50%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="content">
      <el-table :data="tableList" border>
        <el-table-column type="index" label="序号" width="60" align="center">
        </el-table-column>
        <el-table-column width="85" label="操作类型" prop="type">
          <template #default="{ row }">
            {{ row.type | dict('carLossDeterminationBillOperationType') }}
          </template>
        </el-table-column>
        <el-table-column width="70" label="操作人" prop="userName">
        </el-table-column>
        <el-table-column width="100" label="操作时间" prop="lastUpdated">
        </el-table-column>
        <el-table-column label="留言" prop="message"> </el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>
<script>
import { getCarLossDeterminationBillMessageFlow } from '@/api/carBill/carLossDeterminationBill';
export default {
  name: 'operatingRecordDrawer',
  props: {
    visibility: { type: Boolean, default: false },
    id: Number,
  },
  data() {
    return {
      tableList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getCarLossDeterminationBillMessageFlow({ id: this.id }).then((res) => {
        this.tableList = res || [];
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 0 30px;
  box-sizing: border-box;
}
</style>
