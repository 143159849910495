<template>
  <!-- customStyle="background: #f1f7fa;" -->
  <!-- customMainStyle="margin-bottom: 16px;" -->
  <div>
    <div v-if="showSearch" class="flex-x-between">
      <div class="flex-y-center">
        <div style="flex-shrink: 0; margin-right: 10px">服务项目</div>
        <el-input
          v-model="serviceNameKeyword"
          @keyup.enter.native="getData"
          size="small"
          placeholder="搜索服务名称"
          clearable
        ></el-input>
      </div>
      <div>
        <el-button type="primary" size="small" @click="getData">查询</el-button>
        <el-button size="small" @click="serviceNameKeyword = ''"
          >置空</el-button
        >
      </div>
    </div>
    <div
      v-if="showTips"
      style="margin: 20px 0 14px 0; color: #999; font-size: 14px"
    >
      <p>项目折扣说明：1、仅适用自费、包干业务报价；</p>
    </div>

    <div class="container">
      <div class="left">
        <ul style="margin-top: 16px" class="car-brand-tree_ul">
          <div class="title">品牌车型</div>
          <li
            class="car-brand-item_li"
            v-for="(lv1, index) in treeData"
            :key="index"
          >
            <div
              class="car-brand-name_div"
              :class="{
                'has-children': lv1.series.length,
              }"
              @click="handleLv1Click(lv1)"
            >
              <img style="margin-right: 5px" :src="lv1.logoImage" />
              <span class="car-brand-name_text">
                {{ lv1.logoName }}
                ({{ lv1.series.length }})
              </span>

              <i
                v-show="lv1.series"
                class="item-expand_icon newicon grow-icon-arrow-right"
                :class="{ active: lv1.showChild }"
              ></i>
            </div>
            <ul class="tree-item_ul" v-show="lv1.showChild">
              <li
                v-for="(lv2, index) in lv1.series"
                :key="index"
                class="tree-item_li"
              >
                <div
                  :class="{
                    'tree-item-name_div': true,
                    active: lv2ActiveId == lv2.seriesId,
                  }"
                  @click="handleLv2Click(lv2)"
                >
                  <span>
                    {{ lv2.seriesName }}
                  </span>
                  <i
                    v-show="$lodash.get(lv2.modelNumbers, 'length', 0)"
                    class="item-expand_icon newicon grow-icon-arrow-right"
                    :class="{ active: lv2.showChild }"
                  ></i>
                </div>
                <ul class="tree-item_ul" v-show="lv2.showChild">
                  <li
                    v-for="(lv3, index) in lv2.modelNumbers"
                    :key="index"
                    class="tree-item_li"
                  >
                    <div
                      :class="{
                        'tree-item-name_div': true,
                        active: lv3ActiveId == lv3.modelNumberId,
                      }"
                      @click="handleLv3Click(lv3)"
                    >
                      <span>
                        {{ lv3.modelNumberName }}
                      </span>
                      <i
                        v-show="$lodash.get(lv3.modelYears, 'length', 0)"
                        class="item-expand_icon newicon grow-icon-arrow-right"
                        :class="{ active: lv3.showChild }"
                      ></i>
                    </div>
                    <ul class="tree-item_ul" v-show="lv3.showChild">
                      <li
                        v-for="(lv4, index) in lv3.modelYears"
                        :key="index"
                        class="tree-item_li"
                      >
                        <div
                          :class="{
                            'tree-item-name_div': true,
                            'last-level': true,
                            active: lv4ActiveId == lv4.modelYearId,
                          }"
                          @click="handleLv4Click(lv4)"
                        >
                          {{ lv4.modelYearName }}
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="right">
        <div class="right-content">
          <!-- <div style="margin-bottom: 16px" class="flex-x-between">
          <div>
            <el-button
              type="primary"
              size="small"
              @click="importDialogVisibility = true"
              >导入</el-button
            >
          </div>
        </div> -->

          <el-table
            :data="listData"
            border
            max-height="80vh"
            style="height: 50vh"
          >
            <!-- v-fixedScrollBar="$refs.baseContainer" -->
            <el-table-column :label="tableTitle" align="center">
              <el-table-column type="index" label="序号" width="60">
              </el-table-column>
              <el-table-column
                min-width="125"
                label="服务项目"
                prop="serviceName"
              ></el-table-column>
              <el-table-column
                width="100"
                label="服务时效（H）"
                prop="serviceTime"
              ></el-table-column>
              <el-table-column
                width="100"
                label="工时价（元）"
                prop="agreePrice"
              ></el-table-column>
              <el-table-column
                min-width="170"
                label="商品标准名"
                prop="standardName"
              ></el-table-column>
              <el-table-column
                min-width="100"
                label="原厂编码"
                prop="goodsCode"
              ></el-table-column>
              <el-table-column
                width="60"
                label="数量"
                prop="num"
                align="center"
              ></el-table-column>
              <el-table-column
                width="60"
                label="单位"
                prop="unit"
                align="center"
              ></el-table-column>
              <el-table-column width="200" label="商品价（元）" align="center">
                <el-table-column
                  width="100"
                  label="品牌"
                  prop="brandPrice"
                  align="center"
                ></el-table-column>
                <el-table-column
                  width="100"
                  label="原厂"
                  prop="factoryPrice"
                  align="center"
                ></el-table-column>
                <el-table-column
                  v-if="showOfficialCost"
                  width="100"
                  label="正厂"
                  prop="officialPrice"
                  align="center"
                ></el-table-column>
              </el-table-column>
              <el-table-column
                width="200"
                label="结算价（服务+商品）"
                align="center"
              >
                <el-table-column
                  width="100"
                  label="品牌"
                  prop="brandCheckPrice"
                  align="center"
                ></el-table-column>
                <el-table-column
                  width="100"
                  label="原厂"
                  prop="factoryCheckPrice"
                  align="center"
                ></el-table-column>
                <el-table-column
                  v-if="showOfficialCost"
                  width="100"
                  label="正厂"
                  prop="officialCheckPrice"
                  align="center"
                ></el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <!-- <pagination
        class="pagination-box"
        @pageChange="changePage"
        :total="total"
      ></pagination> -->
      </div>

      <div id="watermarkBox"></div>
    </div>
  </div>
</template>

<script>
// 组件
// import pagination from '@/components/Pagination.vue';

// 接口
import { getCarModelTree, getUsualQuoteList } from '@/api/customer/usualQuote';
import { getAgreementPriceList } from '@/api/customer/agreement';

import { mapState } from 'vuex';

export default {
  name: 'agreementPriceList',
  components: {
    // pagination,
  },
  props: {
    id: [Number, String],
    watermark: Boolean,
    watermarkText: String,
    showSearch: {
      type: Boolean,
      default: false,
    },
    showTips: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // 列表数据
      listData: [],

      treeData: [
        // {
        //   logoId: 250,
        //   logoName: '埃安',
        //   image:
        //     'https://growcny-pic.oss-cn-shenzhen.aliyuncs.com/car/aian.png',
        //   series: [
        //     {
        //       seriesId: 5548,
        //       seriesName: '广汽乘用车 Aion S',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 1,
        //           modelNumberName: '炫 580',
        //           modelYears: [
        //             {
        //               modelYearId: 2323,
        //               modelYearName: '2023',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 251,
        //   logoName: '星驰',
        //   image: 'http://www.realscloud.com/carlogo/88110.png',
        //   series: [
        //     {
        //       seriesId: 5549,
        //       seriesName: '星驰SUV',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 2,
        //           modelNumberName: '风翼',
        //           modelYears: [
        //             {
        //               modelYearId: 2324,
        //               modelYearName: '2024',
        //             },
        //             {
        //               modelYearId: 2325,
        //               modelYearName: '2025',
        //             },
        //           ],
        //         },
        //         {
        //           modelNumberId: 3,
        //           modelNumberName: '星光',
        //           modelYears: [
        //             {
        //               modelYearId: 2324,
        //               modelYearName: '2024',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 252,
        //   logoName: '优尼柯',
        //   image: 'http://www.realscloud.com/carlogo/chuanqi.png',
        //   series: [
        //     {
        //       seriesId: 5550,
        //       seriesName: '优尼柯轿车',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 4,
        //           modelNumberName: '雅致',
        //           modelYears: [
        //             {
        //               modelYearId: 2323,
        //               modelYearName: '2023',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 253,
        //   logoName: '极星',
        //   image: 'http://www.realscloud.com/carlogo/fengshen.png',
        //   series: [
        //     {
        //       seriesId: 5551,
        //       seriesName: '极星电动',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 5,
        //           modelNumberName: '星动',
        //           modelYears: [
        //             {
        //               modelYearId: 2325,
        //               modelYearName: '2025',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 254,
        //   logoName: '北汽新能源',
        //   image:
        //     'https://growcny-pic.oss-cn-shenzhen.aliyuncs.com/car/%E5%8C%97%E6%B1%BD%E6%96%B0%E8%83%BD%E6%BA%90.png',
        //   series: [
        //     {
        //       seriesId: 5552,
        //       seriesName: '北汽新能源E系列',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 6,
        //           modelNumberName: 'E150',
        //           modelYears: [
        //             {
        //               modelYearId: 2324,
        //               modelYearName: '2024',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      lv1ActiveId: '',
      lv2ActiveId: '',
      lv3ActiveId: '',
      lv4ActiveId: '',

      tableTitle: '',

      serviceNameKeyword: '',

      showOfficialCost: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  mounted() {
    if (this.watermark) {
      this.addWatermarkToElement('watermarkBox', this.watermarkText, 5);
    }
  },
  methods: {
    init() {
      this.getTreeData();
    },
    addWatermarkToElement(elementId, watermarkText, quantity) {
      // 获取目标 DOM 元素
      var targetElement = document.getElementById(elementId);

      if (!targetElement) {
        console.error('Element not found');
        return;
      }

      // 创建水印元素
      var createWatermark = function (text, top, left) {
        var watermarkElement = document.createElement('div');
        // watermarkElement.style.top = (Math.random() * 80).toFixed(0) + '%';
        watermarkElement.style.top = top;
        // watermarkElement.style.left = (Math.random() * 80).toFixed(0) + '%';
        watermarkElement.style.left = left;
        watermarkElement.className = 'watermark';
        watermarkElement.textContent = text;
        return watermarkElement;
      };

      var position = [
        { top: '17%', left: '0%' },
        { top: '16%', left: '60%' },
        { top: '40%', left: '20%' },
        { top: '80%', left: '0%' },
        { top: '80%', left: '60%' },
      ];
      // 循环添加指定数量的水印
      for (var i = 0; i < quantity; i++) {
        var watermark = createWatermark(
          watermarkText,
          position[i].top,
          position[i].left
        );
        targetElement.appendChild(watermark);
      }
    },

    getTreeData() {
      getCarModelTree({ agreeId: this.id }).then((res) => {
        console.log(res);
        res.forEach((logo) => {
          logo.series?.forEach((series) => {
            series.logoId = logo.logoId;
            series.logoName = logo.logoName;

            series.modelNumbers?.forEach((model) => {
              model.logoId = logo.logoId;
              model.logoName = logo.logoName;
              model.seriesId = series.seriesId;
              model.seriesName = series.seriesName;

              model.modelYears?.forEach((year) => {
                year.logoId = logo.logoId;
                year.logoName = logo.logoName;
                year.seriesId = series.seriesId;
                year.seriesName = series.seriesName;
                year.modelNumberId = model.modelNumberId;
                year.modelNumberName = model.modelNumberName;
              });
            });
          });
        });
        this.treeData = res || [];
        if (this.treeData.length) {
          this.handleLv1Click(res[0]);
          this.handleLv2Click(res[0].series[0]);
          this.handleLv3Click(res[0].series[0].modelNumbers[0]);
          this.handleLv4Click(res[0].series[0].modelNumbers[0].modelYears[0]);
        }
      });
    },
    /**
     * 点击一级
     */
    handleLv1Click(item) {
      if (item.series?.length && !item.showChild) {
        this.$set(item, 'showChild', true);
        this.lv1ActiveId = item.logoId;
      } else {
        this.$set(item, 'showChild', false);
      }
      this.lv2ActiveId = '';
      this.lv3ActiveId = '';
      this.lv4ActiveId = '';
    },
    /**
     * 点击二级
     */
    handleLv2Click(item) {
      if (item.modelNumbers?.length && !item.showChild) {
        this.$set(item, 'showChild', true);
        this.lv1ActiveId = item.logoId;
        this.lv2ActiveId = item.seriesId;
      } else {
        this.$set(item, 'showChild', false);
        this.lv2ActiveId = '';
      }
      this.lv3ActiveId = '';
      this.lv4ActiveId = '';
    },
    /**
     * 点击三级
     */
    handleLv3Click(item) {
      if (item.modelYears?.length && !item.showChild) {
        this.$set(item, 'showChild', true);
        this.lv1ActiveId = item.logoId;
        this.lv2ActiveId = item.seriesId;
        this.lv3ActiveId = item.modelNumberId;
      } else {
        this.$set(item, 'showChild', false);
        this.lv3ActiveId = '';
      }
      this.lv4ActiveId = '';
    },
    /**
     * 点击四级
     */
    handleLv4Click(item) {
      this.lv1ActiveId = item.logoId;
      this.lv2ActiveId = item.seriesId;
      this.lv3ActiveId = item.modelNumberId;
      this.lv4ActiveId = item.modelYearId;

      this.tableTitle = `${item.logoName}${item.seriesName}${item.modelNumberName} ${item.modelYearName}款 车型报价`;
      this.getData();
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign({}, { agreeId: this.id }, this.pageOption);
      // if (this.serviceName) {
      //   data.serviceName = this.serviceName;
      // }
      if (this.lv1ActiveId) {
        data.logoId = this.lv1ActiveId;
      }
      if (this.lv2ActiveId) {
        data.seriesId = this.lv2ActiveId;
      }
      if (this.lv3ActiveId) {
        data.modelNumberId = this.lv3ActiveId;
      }
      if (this.lv4ActiveId) {
        data.modelYearId = this.lv4ActiveId;
      }
      if (this.serviceNameKeyword) {
        data.serviceName = this.serviceNameKeyword;
      }
      getAgreementPriceList(data).then((res) => {
        // 修改总条数
        // this.total = res.total;
        // 赋值records
        this.listData = res || [];
        // .records;
        this.showOfficialCost = res.some((el) => el.officialPrice);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  // min-height: 800px;
  display: flex;
  position: relative;
  padding-bottom: 16px;
  // background: #f1f7fa;
  // > div {

  // }
  .left {
    // width: 259px;
    width: 15%;
    min-width: 150px;
    padding: 8px;
    // border-radius: 4px;
    background: #fff;
    box-shadow: -1px -2px 7px #eee;
    .car-brand-tree_ul {
      margin-top: 16px;
      flex: 1;
      overflow: auto;
      .title {
        height: 52px;
        background: #f5f7fa;
        border-radius: 4px;
        margin-bottom: 8px;
        padding: 16px;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #242526;
        line-height: 20px;
        user-select: none;
      }
      .car-brand-item_li {
        position: relative;
        .car-brand-name_div {
          height: 37px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          color: #242526;
          &.active .car-brand-name_text {
            color: $primary;
          }
          &:hover {
            &:not(.has-children) {
              background: rgba(51, 171, 121, 0.1);
            }
            .car-brand-name_text,
            .item-expand_icon {
              color: $primary;
            }
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        & + .car-brand-item_li {
          margin-top: 2px;
        }

        .car-brand-name_text {
          color: #242526;
          font-size: 14px;
          font-weight: 600;
        }
        .item-expand_icon {
          position: absolute;
          right: 8px;
          font-size: 14px;
          color: #606266;
          transition: 0.2s;
          transform: rotate(0deg);
          &.active {
            transform: rotate(90deg);
          }
        }

        .tree-item_ul {
          margin-left: 15px;
          padding-top: 6px;
          // position: absolute;
          .tree-item_li {
            // height: 32px;
            // display: flex;
            // align-items: center;
            // flex-direction: column;

            box-sizing: border-box;
            cursor: pointer;
            color: #3c3d40;
            font-size: 14px;
            font-weight: 400;
            & + .tree-item_li {
              margin-top: 2px;
            }
            .tree-item-name_div {
              position: relative;
              padding: 8px 12px 8px 20px;
              border-radius: 2px;
              &.active,
              &:hover {
                color: $primary;
              }
              &:hover,
              &.active.last-level {
                background: rgba(51, 171, 121, 0.1);
              }
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    // padding: 16px 0 0 0;
    background: #fff;
    margin-left: 16px;
    // border-radius: 4px;
    position: relative;
    .right-content {
      flex: 1;
      // height: 100%;
      // margin-left: 16px;
      // padding: 8px;
      // background: #fff;
      // min-width: 0;
      // padding-bottom: calc(4vh + 32px);
      overflow: auto;
      .el-table ::v-deep .el-table__body-wrapper {
        height: 100%;
      }
    }
    .pagination-box {
      width: calc(100% - 48px);
      background: #fff;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      text-align: center;
    }
  }
  #watermarkBox {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
  }
}
</style>
<style lang="scss">
.watermark {
  position: absolute;
  transform: rotate(325deg);
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2); /* 水印文字颜色和透明度 */
  pointer-events: none; /* 使水印不干扰用户交互 */
  z-index: 9999; /* 确保水印在最前面 */
  white-space: nowrap;
}
</style>
