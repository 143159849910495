<template>
  <div class="container">
    <div class="tags-box">
      <ul class="tags-ul_tags">
        <li
          class="tags-li_tag"
          v-for="(tag, index) in tagList"
          :class="{ 'tags-li-tag_active': index === activeTagIndex }"
          :title="tag.title"
          :key="tag.fullPath"
        >
          <router-link :to="tag.fullPath" class="tags-link_title">
            {{ tag.title }}
            <i
              class="tags-tag-icon_refresh iconfont grow-a-icon_tabbar_refresh"
              title="刷新"
              @click="refresh"
              v-if="index === activeTagIndex"
            ></i>
            <i
              class="tags-tag-icon_close iconfont grow-a-icon_tabbar_close"
              title="关闭"
              @click.stop.prevent="closeTag({ index })"
              v-if="tag.name != 'wel'"
            ></i>
          </router-link>
        </li>
      </ul>

      <!-- <div class="tags-right_tool"> -->
      <!-- <div class="tags-right_message">
        <i class="iconfont grow-icon_laba"></i>
        <marquee
          class="pointer tags-right-message_text"
          width="98%"
          scrollamount="5"
          direction="left"
          align="middle"
          ref="notifice"
        >
          汽车后市场是指汽车销售以后，围绕汽车使用过程中的各种服务，它涵盖了消费者买车后所需要的一切服务。</marquee
        >
      </div> -->
      <!-- <el-dropdown
          v-if="tagList.length > 1"
          class="tags-right_close"
          @command="handleTags"
        >
          <i class="iconfont grow-icon_close pointer"></i>
          <el-dropdown-menu size="small" slot="dropdown">
            <el-dropdown-item command="other">关闭其他</el-dropdown-item>
            <el-dropdown-item command="all">关闭所有</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      <!-- </div> -->
    </div>
    <div class="car-bill_shortcut">
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="add-box">
          <div class="add-btn flex-center">开单</div>
          <!-- <div class="add-icon flex-center">
            <i class="el-icon-plus"></i>
          </div>
          <span class="flex-y-center">开单</span> -->
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">自费开单</el-dropdown-item>
          <el-dropdown-item command="1">包干开单</el-dropdown-item>
          <el-dropdown-item command="2">理赔开单</el-dropdown-item>
          <el-dropdown-item command="3">索赔开单</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <search-car-bill-by-car-number-popover
        @select="goDetail($event.billId)"
      ></search-car-bill-by-car-number-popover>
    </div>
  </div>
</template>

<script>
import searchCarBillByCarNumberPopover from '@/components/businessComponent/searchCarBillByCarNumberPopover';
import { mapGetters, mapState, mapMutations } from 'vuex';
import bus from '@/service/bus';
import { verifyAuth } from '@/utils/permissions';
export default {
  name: 'HomeTags',
  components: {
    searchCarBillByCarNumberPopover,
  },
  data() {
    return {
      carBillList: [],
      searchCarBillLoading: false,
    };
  },
  computed: {
    ...mapState({
      tagList: (state) => state.tags.tagList,
      tagWel: (state) => state.tags.tagWel,
    }),
    ...mapGetters(['activeTagIndex']),
  },
  mounted() {
    // console.log(this.$store);
    // console.log(this.$store.state.tags.tagList);
    // console.log(this.$route);
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
      closeOtherTag: 'DEL_OTHER_TAG',
      closeAllTag: 'DEL_ALL_TAG',
    }),
    handleTags(command) {
      console.log(command);
      if (command === 'other') {
        this.closeOtherTag();
      } else if (command === 'all') {
        this.closeAllTag();
      }
    },
    refresh() {
      bus.$emit('refresh');
    },
    /**
     * 处理快捷开单
     */
    async handleCommand(command) {
      let authCode;
      switch (command) {
        case '0':
          authCode = 466;
          break;
        case '1':
          authCode = 467;
          break;
        case '2':
          authCode = 468;
          break;
        case '3':
          authCode = 469;
          break;
      }
      await verifyAuth(authCode);
      this.$router.push({
        name: 'editCarBill',
        query: {
          type: command,
        },
      });
    },
    /**
     * 跳转详情页
     */
    async goDetail(id) {
      await verifyAuth(35);
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.tags-box {
  // flex-shrink: 1;
  flex: 1;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  overflow: auto;
  .tags-ul_tags {
    height: 100%;
    display: flex;
    align-items: flex-end;
    overflow-x: auto;
    .tags-li_tag {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 0 6px 0 16px;
      margin-left: 10px;
      background: #f8fcfa;
      box-shadow: 0px -1px 10px 0px #f1faf6;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #eee;
      border-bottom: none;
      position: relative;
      cursor: pointer;

      .tags-link_title {
        // display: inline-block;
        // max-width: 100px;
        // padding: 1px 0;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // text-decoration: none;
        // color: #999;

        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 14px;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #999;
      }
      .tags-tag-icon_refresh {
        font-size: 12px;
        color: #666;
        margin-left: 5px;
      }
      .tags-tag-icon_close {
        position: absolute;
        top: 0;
        right: 0;
        color: #a1e2c7;
        overflow: hidden;
      }

      &.tags-li-tag_active {
        background: #fff;
        .tags-link_title {
          color: #333;
        }
        .tags-tag-icon_refresh {
          color: #333;
        }
        .tags-tag-icon_close {
          color: $primary;
        }
      }
    }
  }
  .tags-right_tool {
    display: flex;
    align-items: center;
    .tags-right_message {
      width: 220px;
      height: 28px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      > i {
        margin: 0 9px;
        color: #ff9200;
      }
      .tags-right-message_text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        color: #999;
      }
    }
    .tags-right_close {
      margin-right: 15px;
      > i {
        font-size: 18px;
        color: #666;
      }
    }
  }
}
.car-bill_shortcut {
  flex-shrink: 0;
  height: 54px;
  display: flex;
  align-items: center;
  // padding: 14px 30px 4px 10px;
  padding-right: 30px;
  box-sizing: border-box;

  .add-box {
    display: flex;
    align-content: center;
    margin-right: 16px;
    cursor: pointer;
    .add-btn {
      width: 52px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid $primary;

      font-size: 12px;
      color: $primary;
    }

    // .add-icon {
    //   width: 24px;
    //   height: 24px;
    //   border: 1px solid #dcdfe6;
    //   border-radius: 2px;
    //   i {
    //     font-size: 24px;
    //     color: #dcdfe6;
    //   }
    // }
    // span {
    //   margin-left: 8px;
    //   color: #909399;
    //   font-size: 14px;
    //   font-weight: 400;
    // }
  }
}
</style>
