<template>
  <div class="div">
    <batchWord2RichText v-if="false"></batchWord2RichText>
    <AMap v-if="false"></AMap>
    <changePasswordDialog :visibility="bool" />
    <changePhoneNumberDialog :visibility="bool2" />
  </div>
</template>

<script>
export default {
  name: 'demoPage',
  components: {
    AMap: () => import('@/page/system-data/AMap.vue'),
    batchWord2RichText: () => import('./batchWord2RichText.vue'),
    changePasswordDialog: () =>
      import('@/components/businessComponent/changePasswordDialog.vue'),
    changePhoneNumberDialog: () =>
      import('@/components/businessComponent/changePhoneNumberDialog.vue'),
  },

  data() {
    return {
      bool: false,
      bool2: true,
    };
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
// @font-face {
//   /* 自定义的字体名称; */
//   font-family: 'PangMenZhengDao';
//   /* 自定义的字体的存放路径、格式; */
//   src: url(../page/system-data/font/PangMenZhengDao.ttf);
// }
.div {
  width: 100%;
  height: 100%;
}
</style>
