<template>
  <div class="sidebar-component" v-if="isShow">
    <div class="sidebar-container" :class="{ lock: locked }">
      <img
        class="sidebar-logo_img"
        src="@/assets/img/logo.svg"
        alt="格悦新能源"
      />
      <ul class="sidebar-menu-first_ul">
        <li
          v-for="(firstMenu, index) in menus"
          :key="index"
          v-auth="{
            code: firstMenu.permission,
            additionalConditions: !firstMenu.onlyHeadquarters || isHeadquarters,
          }"
          :class="{
            'sidebar-menu-first_li': true,
            active: firstMenu.title === selectedFirstMenu.title,
          }"
          @click="handleFirstMenuClick(firstMenu)"
          @mouseenter="handleFirstMenuMouseenter(firstMenu)"
          @mouseleave="handleFirstMenuMouseleave(firstMenu)"
        >
          <i
            :class="['sidebar-menu-first_icon', 'newicon', firstMenu.icon]"
          ></i>
          <span v-show="!locked" class="sidebar-menu-first_text">{{
            firstMenu.title
          }}</span>
          <i
            v-show="!locked && firstMenu.children"
            class="sidebar-menu-expand_icon newicon grow-icon-arrow-right"
          ></i>
        </li>
      </ul>
      <div class="footer">
        <div class="untreated" @click="goUntreated">
          <img class="untreated-icon" src="@/assets/img/untreated.png" />
          <el-badge
            :value="num"
            :max="99"
            class="untreated-item"
            v-if="num > 0"
          >
            <div>待办</div>
          </el-badge>
          <div v-if="num == 0" class="untreated-item">待办</div>
        </div>
        <div class="store-name">
          <!-- 公司名称下拉菜单 -->
          <el-dropdown
            style="flex: 1; height: 100%; display: flex; align-item: center"
            trigger="click"
            placement="top-end"
          >
            <div
              style="flex: 1; height: 100%; display: flex; align-item: center"
            >
              <i class="store-icon newicon grow-icon-store" v-show="locked"></i>
              <div class="flex-y-center">
                <span
                  v-show="!locked"
                  :title="companyName"
                  class="store-name_text"
                  >{{ companyName }}</span
                >
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-input
                size="small"
                v-model="keyName"
                clearable
                placeholder="请输入车店名称"
                @input="getCompanyList(true, false, false)"
              ></el-input>
              <div v-loading="loading" class="company-list">
                <el-dropdown-item
                  v-for="(item, index) in companyList"
                  :key="index"
                  @click.native="switchCompany(item, true)"
                  >{{
                    $lodash.get(item, 'company[0].companyName', '')
                  }}</el-dropdown-item
                >
                <!-- 加载更多 -->
                <div class="flex-center">
                  <el-button
                    type="text"
                    size="small"
                    v-if="hasMore"
                    @click="getCompanyList(false, true, false)"
                    >加载更多</el-button
                  >
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <i
            v-show="!locked"
            class="store-name-expand_icon newicon grow-icon-arrow-right"
          ></i>
        </div>
        <div class="user-info">
          <!-- 用户下拉菜单 -->
          <el-dropdown style="flex: 1" trigger="click" @command="handleCommand">
            <div class="flex-y-center" :title="userInfo.userName">
              <img class="user-avatar" src="@/assets/img/default-avatar.png" />
              <div v-show="!locked" class="user-name_text">
                {{ userInfo.realName }}
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="editUser">个人信息</el-dropdown-item>
              <el-dropdown-item command="changePassword"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <i
            v-show="!locked"
            class="user-name-expand_icon newicon grow-icon-arrow-right"
          ></i>
        </div>
        <div class="lock">
          <i
            v-if="locked"
            class="newicon grow-icon-locked"
            style="padding-bottom: 10px"
            @click="locked = false"
          ></i>
          <el-divider v-else
            ><i class="newicon grow-icon-unlock" @click="locked = true"></i
          ></el-divider>
        </div>
      </div>
    </div>
    <div
      class="sidebar-menu-second_container"
      :class="{ lock: locked }"
      v-show="showSecondMenus"
      @mouseenter="handleSecondMenuMouseenter"
      @mouseleave="handleFirstMenuMouseleave"
    >
      <div class="top-icon">
        <i :class="['newicon', selectedFirstMenu.icon]"></i>
      </div>
      <ul class="sidebar-menu-second_ul">
        <li
          class="sidebar-menu-second_li"
          v-for="(secondMenu, index) in selectedFirstMenu.children"
          :key="index"
          v-auth="{
            code: secondMenu.permission,
            additionalConditions:
              !secondMenu.onlyHeadquarters || isHeadquarters,
          }"
        >
          <!-- active: secondMenu.showChild, -->
          <div
            class="sidebar-menu-second_btn"
            :class="{
              'has-children': secondMenu.children,
            }"
            @click="handleSecondMenuClick(secondMenu)"
          >
            <span class="sidebar-menu-second_text">{{ secondMenu.title }}</span>
            <i
              v-show="secondMenu.children"
              class="sidebar-menu-expand_icon newicon grow-icon-arrow-right"
              :class="{ active: secondMenu.showChild }"
            ></i>
          </div>
          <ul class="sidebar-menu-third_ul" v-show="secondMenu.showChild">
            <li
              class="sidebar-menu-third_li"
              v-for="(thirdMenu, index) in secondMenu.children"
              :key="index"
              v-auth="{
                code: thirdMenu.permission,
                additionalConditions:
                  !thirdMenu.onlyHeadquarters || isHeadquarters,
              }"
              @click="handleThirdMenuClick(thirdMenu)"
            >
              {{ thirdMenu.title }}
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- 临期密码提醒弹窗 -->
    <div v-if="dueSoonPassword" class="due-soon-password-warning-dialog">
      <p class="color626">为了你的信息安全，请尽快修改密码，并注意保管</p>
      <p class="color626">如不修改，系统将于{{ passwordDueTime }}不允许登录</p>
      <div class="btns">
        <el-button size="small" @click="dueSoonPassword = false"
          >暂不提示</el-button
        >
        <el-button type="primary" size="small" @click="handleChangePassword"
          >修改密码</el-button
        >
      </div>
    </div>

    <!-- 修改密码弹窗 -->
    <change-password-dialog
      v-if="changePasswordDialogVisibility"
      :visibility.sync="changePasswordDialogVisibility"
      :needOldPassword="true"
      :phoneNumber="userInfo.phone"
    ></change-password-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations, mapState } from 'vuex';

import ChangePasswordDialog from '@/components/businessComponent/changePasswordDialog.vue';
import {
  oldServerUrl,
  admin1PagePrefix,
  admin2PagePrefix,
} from '@/config/compatibilityOlderSystems';
import { MENUS } from '@/const/menus';
import bus from '@/service/bus';

//接口
import { userLogout, searchCompany } from '@/api/system';
import {
  companyDetermineCurrCompany,
  reservedCurrCompany,
  getCurrentUserPermission,
  loginRemind,
} from '@/api/system';

export default {
  name: 'HomeAside',
  props: {
    num: {
      type: String,
      default: '0',
    },
  },
  components: {
    ChangePasswordDialog,
  },
  data() {
    return {
      locked: false,
      showSecondMenus: false,
      selectedFirstMenu: {},
      menus: MENUS,
      timer: null,
      isShow: true,

      company: {},
      companyName: '',
      keyName: '',
      size: 999,
      current: 1,
      total: 0,
      loading: false,
      fullscreen: false,
      firstLoad: true,

      // 密码是否即将过期
      dueSoonPassword: false,
      // 密码到期时间
      passwordDueTime: '',
      // 是否显示修改密码弹窗
      changePasswordDialogVisibility: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      companyList: (state) => state.user.companyList,
      companyId: (state) => state.user.companyId,
      permissionChangeCount: (state) => state.user.permissionChangeCount,
    }),
    ...mapGetters(['isHeadquarters']),

    // filteredCompanyList() {
    //   if (this.keyName) {
    //     return this.companyList.filter((item) =>
    //       item.company?.[0].companyName.includes(this.keyName)
    //     );
    //   } else {
    //     return this.companyList;
    //   }
    // },

    hasMore() {
      return Math.ceil(this.total / this.size) > this.current;
    },
  },
  watch: {
    permissionChangeCount(val) {
      console.log('权限变化 ');
      this.$emit('permissionChangeCount');
      this.isShow = false;
      this.keyName = '';
      // val > 0 && this.getCompanyList(true, false, false);
      this.$nextTick().then(() => {
        this.isShow = true;
      });
    },
  },
  created() {
    this.getCompanyList(false, false, true);
    this.getLoginRemind();
  },
  methods: {
    ...mapMutations(['updateCompanyList']),
    // 跳转代办
    goUntreated() {
      this.$router.push('/unTreated');
    },
    /**
     * 点击一级菜单
     */
    handleFirstMenuClick(menu) {
      this.nav(menu);
    },
    /**
     * 鼠标进入一级菜单
     */
    handleFirstMenuMouseenter(menu) {
      if (menu.children?.length) {
        // if (
        //   this.showSecondMenus &&
        //   menu.title === this.selectedFirstMenu.title
        // ) {
        //   this.showSecondMenus = false;
        //   this.selectedFirstMenu = {};
        // } else {
        if (this.timer) clearTimeout(this.timer);
        this.showSecondMenus = true;
        this.selectedFirstMenu = menu;
        // }
      } else {
        this.hideSecondMenu();
      }
    },
    /**
     * 鼠标离开菜单
     */
    handleFirstMenuMouseleave() {
      this.timer = setTimeout(this.hideSecondMenu, 2e2);
    },
    /**
     * 鼠标进入二级菜单
     */
    handleSecondMenuMouseenter() {
      if (this.timer) clearTimeout(this.timer);
    },
    /**
     * 隐藏二级菜单
     */
    hideSecondMenu() {
      this.showSecondMenus = false;
      this.selectedFirstMenu = {};
    },
    /**
     * 点击二级菜单
     */
    handleSecondMenuClick(menu) {
      if (menu.path) {
        this.nav(menu);
        return;
      }
      if (menu.children?.length && !menu.showChild) {
        this.$set(menu, 'showChild', true);
      } else {
        this.$set(menu, 'showChild', false);
      }
    },
    /**
     * 点击三级菜单
     */
    handleThirdMenuClick(menu) {
      if (menu.path) {
        this.nav(menu);
      }
    },
    // nav(menu) {
    //   if (menu.path) {
    //     this.$router.push(menu.path);
    //   }
    // },
    // 跳转
    nav(menu) {
      // 此系统
      if (menu.path && !menu.outside) {
        let route = {
          path: menu.path,
          // name: menu.path?.split('/')?.at(-1)?.split('?')?.[0],
          params: menu.params || {},
        };
        console.log(route);
        this.$router.push(route);
        this.showSecondMenus = false;
        this.selectedFirstMenu = {};
      }
      // 外部
      else if (menu.outside) {
        // 需要参数
        if (menu.params?.length) {
          let companyId = this.$store.state.user.userInfo.companyId;
          // sessionStorage.getItem('companyId');

          // if (!companyId) {
          //   let res = await searchCompany(this.userId);
          //   if (res.code == 200) {
          //     this.companyInfoList.companyName =
          //       res.data.records.company.companyName;
          //   }
          // }
          var data = {
            companyId,
          };
          menu.params.forEach((item) => {
            menu.path = menu.path.replace(item.key, data[item.value]);
          });
        }
        window.open(menu.path, '_blank');
        // location.href = menu.path;
      }
    },
    /**
     * 获取门店列表
     */
    getCompanyList(reset, loadMore, firstLoad) {
      if (reset) this.current = 1;
      if (loadMore) this.current++;
      this.loading = true;
      searchCompany({
        userId: this.userInfo.userId,
        size: this.size,
        current: this.current,
        companyName: this.keyName,
      })
        .then(async (res) => {
          this.total = res.total;
          let list;
          if (reset || firstLoad) {
            list = res.records;
          } else {
            list = this.companyList.concat(res.records);
          }
          this.updateCompanyList({ list });
          if (loadMore || reset) {
            return;
          }
          // this.$store.state.user.companyList = res.records;
          let defaultSelected = res.records?.[0];

          let result;
          let scanCompanyId = sessionStorage.getItem('scanCompanyId');
          if (scanCompanyId) {
            result = { companyId: +scanCompanyId };
            sessionStorage.setItem('scanCompanyId', '');
          } else {
            result = await reservedCurrCompany();
          }
          console.log(result);
          if (result?.companyId) {
            let company = res?.records?.find(
              (item) => item.company?.[0]?.companyId === result.companyId
            );
            if (company) {
              defaultSelected = company;
            }
          }

          this.switchCompany(defaultSelected || {});
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 选择/切换门店
     */
    switchCompany(item, loadCompanyList) {
      const company = item.company[0];
      this.companyName = company.companyName;
      this.$store.commit('updateCompanyId', {
        id: company.companyId,
        name: company.companyName,
      });
      this.$store.commit('updateHeadquarters', {
        headquarters: !company.parentId,
      });

      const user = item.user;
      this.$store.commit('updateUser', {
        id: user.userId,
        name: user.userName,
      });

      if (!this.firstLoad) {
        this.$store.commit('DEL_ALL_TAG');
      }
      this.firstLoad = false;

      if (loadCompanyList) {
        this.keyName = '';
        this.getCompanyList(true, false, false);
      }

      // 跳转到老系统需要使用
      localStorage.setItem('companyId', company.companyId);
      sessionStorage.setItem('companyId', company.companyId);
      axios
        .post(
          oldServerUrl + '/web/company/switchCompany',
          { companyId: company.companyId },
          {
            noHandleError: true,
            noShowWaiting: true,
          }
        )
        .then((res) => {
          if (res.data?.code === '403') {
            this.$message.error(res.data?.msg);
            this.$store.dispatch('logout');
            return;
          }
          const { companyId, phone } = res?.data?.resultObject || {};
          localStorage.setItem('companyId', companyId);
          localStorage.setItem('phone', phone);
          sessionStorage.setItem('companyId', companyId);
          sessionStorage.setItem('phone', phone);
          sessionStorage.setItem(
            'superSuplierAuth',
            JSON.stringify(res?.data?.resultObject || {})
          );
          sessionStorage.setItem(
            'selectedCompany',
            JSON.stringify({ companyId, company: company.companyName })
          );
          axios
            .post(
              oldServerUrl + '/web/company/getCurrentUserAndCompany',
              {},
              {
                noHandleError: true,
                noShowWaiting: true,
              }
            )
            .then((res = {}) => {
              let { dealer, user, company, authMark } =
                res.data.resultObject || {};
              localStorage.setItem('authMark', JSON.stringify(authMark));
              sessionStorage.setItem('authMark', JSON.stringify(authMark));
              localStorage.setItem('user', JSON.stringify(user));
              localStorage.setItem('company', JSON.stringify(company));
              // axios
              //   .post(
              //     oldServerUrl + '/feature/queryAllFeatureUsingStateV4_2_0',
              //     {},
              //     {
              //       noHandleError: true,
              //       noShowWaiting: true,
              //     }
              //   )
              //   .then((res) => {
              //     let featureList = res.featureList;
              //     sessionStorage.setItem(
              //       'companyMark',
              //       JSON.stringify(featureList)
              //     );
              //     // this.$store.commit('setInitSystem', true);
              //   })
              //   .catch(() => {
              //     sessionStorage.setItem('companyMark', '');
              //   });

              // //获取操作门店list
              // axios
              //   .post(
              //     oldServerUrl + '/web/company/getCurrentUserManageCompanies',
              //     {},
              //     {
              //       noHandleError: true,
              //       noShowWaiting: true,
              //     }
              //   )
              //   .then((res) => {
              //     console.log('操作门店', res);
              //     res = res.data?.resultObject?.map((item) => {
              //       //根据ele-ui转换成需要的格式
              //       // item.id = item.companyId
              //       // item.value = item.companyName
              //       item.label = item.companyName;
              //       item.value = item.companyId;
              //       return item;
              //     });
              //     res &&
              //       localStorage.setItem(
              //         'operationCompanies',
              //         JSON.stringify(res)
              //       );
              //   });
            });
        });

      // 保存当前使用的门店
      companyDetermineCurrCompany(company.companyId, this.userInfo.userId).then(
        () => {
          // 获取用户权限
          getCurrentUserPermission().then((res) => {
            // console.log(res);
            let { roleContent: roles = [], roleName = '' } = res;
            this.$store.commit('updatePermission', { roles, roleName });
          });
          this.$store.dispatch('getCompanyTree');
          bus.$emit('switchCompany');
        }
      );
    },
    handleCommand(e) {
      switch (e) {
        case 'logout':
          userLogout();
          this.$store.dispatch('logout');
          break;
        case 'editUser':
          // 跳转旧版个人信息
          // let path = `${admin2PagePrefix}#/others/editUser?keepRoute=true`;
          // window.open(path, '_blank');

          // 新版个人信息
          this.$router.push({
            name: 'userInfo',
          });
          break;
        case 'changePassword':
          this.handleChangePassword();
          break;
      }
    },

    /**
     * 点击修改密码
     */
    handleChangePassword() {
      this.dueSoonPassword = false;
      this.changePasswordDialogVisibility = true;
    },

    /**
     * 获取登录提醒
     */
    getLoginRemind() {
      loginRemind().then((res) => {
        if (!res.state) {
          this.$message.error(
            '密码超过1个月未修改，为了信息安全，请“找回密码”后再登录'
          );
          this.$store.dispatch('logout');
        }
        this.passwordDueTime = res.time || '';
        this.dueSoonPassword = !!res.time;
      });
    },
  },
};
</script>

<style lang="scss">
.company-list {
  height: 300px;
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.sidebar-component {
  position: relative;
}
.sidebar-container {
  width: 160px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: $primary;
  overflow-y: auto;
  // transition: 0.1s;
  &.lock {
    width: 56px;
    .sidebar-logo_img {
      width: 46px;
      object-fit: contain;
    }
  }

  .sidebar-logo_img {
    width: 89px;
    height: 31px;
    margin: 16px auto;
    // transition: 0.1s;
    position: relative;
    left: -2px;
  }
  .sidebar-menu-first_ul {
    flex: 1;
    overflow-y: auto;
    .sidebar-menu-first_li {
      height: 44px;
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 16px;
      box-sizing: border-box;
      cursor: pointer;
      &.active,
      &:hover {
        background: #fff;
        .sidebar-menu-first_icon,
        .sidebar-menu-first_text,
        .sidebar-menu-expand_icon {
          color: $primary;
        }
      }
      & + .sidebar-menu-first_li {
        padding-top: 2px;
      }
      .sidebar-menu-first_icon {
        font-size: 26px;
        color: #fff;
        font-weight: 400;
      }
      .sidebar-menu-first_text {
        white-space: nowrap;
        margin-left: 8px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
      }
      .sidebar-menu-expand_icon {
        position: absolute;
        right: 8px;
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .footer {
    .untreated {
      height: 44px;
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 16px;
      box-sizing: border-box;
      cursor: pointer;
      color: #fff;
      .untreated-icon {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        overflow: hidden;
      }
      .untreated-item {
        // position: absolute;
        // margin-top: 10px;
        // margin-right: 40px;
      }
    }
    .store-name {
      height: 44px;
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 16px;
      box-sizing: border-box;
      cursor: pointer;
      color: #fff;

      .store-icon {
        font-size: 24px;
        color: #fff;
        font-weight: 400;
      }
      .store-name_text {
        max-width: 116px;
        // margin-left: 8px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .store-name-expand_icon {
        position: absolute;
        right: 8px;
        font-size: 14px;
        color: #fff;
      }
    }
    .user-info {
      height: 44px;
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 16px;
      box-sizing: border-box;
      cursor: pointer;
      color: #fff;

      .user-avatar {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        border-radius: 50%;
        overflow: hidden;
      }
      .user-name_text {
        margin-left: 8px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .user-name-expand_icon {
        position: absolute;
        right: 8px;
        font-size: 14px;
        color: #fff;
      }
    }
    .lock {
      margin: 10px 0;
      text-align: center;
      i {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
      ::v-deep .el-divider__text {
        padding: 0 8px;
        background: $primary;
      }
    }
  }
}
.sidebar-menu-second_container {
  width: 160px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 160px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  z-index: 199;
  overflow: auto;
  background: #fff;
  box-shadow: 0px 2px 6px 0px #eee;

  &.lock {
    left: 56px;
  }

  .top-icon {
    flex-shrink: 0;
    box-shadow: 0px 1px 0px 0px rgba(51, 171, 121, 0.09);
    text-align: center;
    i {
      font-size: 24px;
      color: $primary;
    }
  }

  .sidebar-menu-second_ul {
    margin-top: 16px;
    flex: 1;
    overflow: auto;
    .sidebar-menu-second_li {
      // flex-direction: column;
      position: relative;
      .sidebar-menu-second_btn {
        height: 32px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-radius: 2px;
        box-sizing: border-box;
        cursor: pointer;
        &.active,
        &:hover {
          &:not(.has-children) {
            background: rgba(51, 171, 121, 0.1);
          }
          .sidebar-menu-second_text,
          .sidebar-menu-expand_icon {
            color: $primary;
          }
        }
      }
      & + .sidebar-menu-second_li {
        margin-top: 2px;
      }

      .sidebar-menu-second_text {
        color: #303133;
        font-size: 14px;
        font-weight: 400;
      }
      .sidebar-menu-expand_icon {
        position: absolute;
        right: 8px;
        font-size: 14px;
        color: #606266;
        transition: 0.2s;
        transform: rotate(0deg);
        &.active {
          transform: rotate(90deg);
        }
      }

      .sidebar-menu-third_ul {
        padding-bottom: 8px;
        // position: absolute;
        .sidebar-menu-third_li {
          height: 32px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          color: #606266;
          font-size: 14px;
          font-weight: 400;
          & + .sidebar-menu-third_li {
            margin-top: 2px;
          }
          &.active,
          &:hover {
            color: $primary;
            background: rgba(51, 171, 121, 0.1);
          }
        }
      }
    }
  }
}
::v-deep .el-badge__content {
  border: none !important;
}
::v-deep .el-badge__content.is-fixed {
  top: -5px;
  right: 3px;
}
.due-soon-password-warning-dialog {
  position: absolute;
  bottom: 12%;
  left: 50%;
  padding: 30px 24px;

  width: 360px;
  height: 179px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: 1px solid #e4e7ed;

  box-sizing: border-box;

  text-align: center;
  font-size: 14px;
  z-index: 2000;
  p {
    + p {
      margin-top: 10px;
    }
  }

  .btns {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
</style>
