<template>
  <el-dialog
    title="编辑车单"
    :visible="visibility"
    width="200"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <el-form label-width="120px" size="small">
      <el-form-item label="消费客户">
        <div class="color626">
          <span>{{ pageClientInfo.consumerName }}</span>
          <span class="el-icon--right">{{ pageClientInfo.consumerPhone }}</span>
          <span
            v-if="billType === 0"
            class="pointer blue el-icon--right"
            @click="selectOtherPayClientDialogVisibility = true"
            >重选</span
          >
        </div>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <div style="line-height: 18px">
            <div>车单备注</div>
            <span style="font-size: 12px" class="color626">(客户不可见)</span>
          </div>
        </template>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入车单备注"
          v-model="pageRemark"
        >
        </el-input>
      </el-form-item>
      <!-- 进店里程 -->
      <el-form-item label="进店里程" prop="mileage">
        <number-input
          v-model="form.mileage"
          :max="999999"
          :enabledZero="false"
          :enabledDecimals="false"
          clearable
        >
          <template slot="append">KM</template>
        </number-input>
      </el-form-item>
      <!-- 双系统录单 -->
      <el-form-item
        label="录单情况"
        prop="doubleSysInputState"
        v-if="isSpecificClient"
      >
        <el-select
          v-model="form.doubleSysInputState"
          placeholder="双系统录单情况"
        >
          <el-option label="双系统待录单" :value="1"></el-option>
          <el-option label="双系统已录单" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <!-- 双系统单号 -->
      <el-form-item label="双系统单号" v-if="isSpecificClient">
        <div
          class="double-num-item"
          v-for="(item, index) in doubleSysNumArr"
          :key="index"
        >
          <el-input
            v-model="item.value"
            clearable
            placeholder="请输入双系统单号"
            size="small"
          >
            <template slot="append">
              <div
                class="blue pointer"
                v-if="index === 0"
                @click="addDoubleSysNum"
              >
                +添加更多
              </div>
              <i
                class="iconfont grow-icon_delete3 pointer orange"
                style="margin-right: 12px"
                title="点击删除"
                v-if="index !== 0"
                @click="delDoubleSysNum(index)"
              ></i>
            </template>
          </el-input>
        </div>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
    </template>

    <!-- 选择他人自费客户 -->
    <select-other-pay-client-dialog
      v-if="selectOtherPayClientDialogVisibility"
      :visibility.sync="selectOtherPayClientDialogVisibility"
      @select="handleSelectClient"
    ></select-other-pay-client-dialog>
  </el-dialog>
</template>

<script>
import selectOtherPayClientDialog from '@/components/businessComponent/selectOtherPayClientDialog.vue';
import { editCarBillAtToCheck } from '@/api/carBill';

export default {
  name: 'editCarBillDialog',
  components: {
    selectOtherPayClientDialog,
  },
  props: {
    visibility: Boolean,
    billId: [Number, String],
    billType: Number,
    remark: String,
    clientInfo: Object,
    isSpecificClient: Boolean,
    carBillInfo: Object,
  },
  data() {
    return {
      doubleSysNumArr: [{ value: '' }],
      // 客户信息
      pageClientInfo: {},
      form: {},
      // 备注
      pageRemark: '',

      selectOtherPayClientDialogVisibility: false,
    };
  },
  created() {
    this.pageClientInfo = this.$lodash.clone(this.clientInfo);
    this.pageRemark = this.remark;
    this.init();
  },
  methods: {
    init() {
      const _this = this;
      if (
        _this.isSpecificClient &&
        _this.carBillInfo?.doubleSysNums &&
        _this.carBillInfo?.doubleSysNums?.length > 0
      ) {
        _this.doubleSysNumArr.length = 0;
        _this.carBillInfo.doubleSysNums.forEach((item) => {
          _this.doubleSysNumArr.push({ value: item });
        });
      }
      if (!_this.doubleSysNumArr?.length) {
        _this.$set(this, 'doubleSysNumArr', [{ value: '' }]);
      }
      _this.$set(
        this.form,
        'doubleSysInputState',
        _this.carBillInfo?.doubleSysInputState
      );
      _this.$set(this.form, 'mileage', _this.carBillInfo?.mileage);
    },
    /**
     * 添加双系统单号
     */
    addDoubleSysNum() {
      this.doubleSysNumArr?.push({ value: '' });
    },
    /**
     * 删除双系统单号
     */
    delDoubleSysNum(index) {
      this.doubleSysNumArr?.splice(index, 1);
    },
    /**
     * 选择客户
     */
    handleSelectClient(val) {
      this.pageClientInfo = {
        consumerId: val.id,
        consumerName: val.clientName,
        consumerPhone: val.clientPhone,
      };
    },
    /**
     * 保存
     */
    save() {
      const _this = this;
      var form = {
        billId: this.billId,
        consumerId: this.pageClientInfo?.consumerId,
        companyRemark: this.pageRemark,
        mileage: this.form?.mileage,
      };
      let doubleSysData = {};
      if (
        _this.isSpecificClient &&
        [1, 2].includes(_this.form.doubleSysInputState)
      ) {
        doubleSysData.doubleSysInputState = _this.form.doubleSysInputState;
        doubleSysData.doubleSysNum = _this.doubleSysNumArr
          .map((item) => item.value)
          .filter((item) => item !== null && item !== undefined)
          .filter((item) => item !== '')
          .join();
      }
      const data = Object.assign({}, form, doubleSysData);
      editCarBillAtToCheck(data)
        .then((res) => {
          this.$message.success('保存成功！');
          this.$emit('save');
          this.handleClose();
        })
        .catch((err) => {
          console.error(err);
          this.$message.error('保存失败！');
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.double-num-item {
  margin: 6px 0px;
}
</style>
