import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 获取车辆列表
export function getCarList(data = defaultListOption) {
  return http.post('/admin/car/list', data);
}

// 获取车辆详情
export function getCarDetail(id) {
  return http.post('/admin/car/info', { id });
}

// 新增车辆详情
export function insertCar(data = {}, config = { onlyReturnData: false }) {
  return http.post('/admin/car/save', data, config);
}

// 更新车辆详情
export function updateCar(data = {}, config = { onlyReturnData: false }) {
  return http.post('/admin/car/update', data, config);
}

// 删除车辆详情
export function delCar(id, config = { onlyReturnData: false }) {
  return http.post('/admin/car/deleteById', { id }, config);
}

// 导出车辆列表
export const exportCarListUrl = '/admin/car/export';

// 根据车架号匹配配置详情
export function listCarModelDetailByVIN(
  keyWord = '',
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/car/listCarModelDetailByVIN',
    { vinCode: keyWord },
    config
  );
}

//选择车型
export function carModelsList(data = {}, config = { onlyReturnData: false }) {
  return http.post('/admin/car/queryBrandTree', data, config);
}

// 检索车型
export function searchCarModel(key = '') {
  return http.post(
    '/admin/car/queryBrandTreeBySearch',
    {
      key,
      current: 1,
      size: 100,
    },
    { noShowWaiting: true }
  );
}

// 查询下次年审日期 已废弃
export function getNextAnnualSurveyDate(registerDate, annualSurveyCarType) {
  return http.post(
    '/admin/car/queryNextAnnualSurveyDate',
    { registerDate, annualSurveyCarType },
    { noShowWaiting: true }
  );
}

//车辆档案-消费记录
export function carGetCarConsumptionRecord(data) {
  return http.post('/admin/car/getCarConsumptionRecord', data);
}
//车辆档案-消费记录
export function getChangeLogList(data) {
  return http.post('/admin/car/changeLog/list', data);
}

/**
 * 车辆绑定客户
 * @param clientId {number} 客户id
 * @param carIds {Array <number>} 车辆id
 */
export function bindingClientByCarId(clientId, carIds = []) {
  return http.post('/admin/car/bindingClientByCarId', { clientId, carIds });
}

// 批量操作车辆接口-查询批量车辆信息
export function carQueryCarListByIds(data) {
  return http.post('/admin/car/queryCarListByIds', data);
}
// 批量操作保存接口
export function caraBtchUpdateCar(data) {
  return http.post('/admin/car/batchUpdateCar', data);
}
// 车辆盘点表列表
export function getCarInventoryTableList(data) {
  return http.post('/admin/car/carInventoryTableList', data, {
    onlyReturnData: true,
  });
}
// 车辆盘点列表导出
export const exportCarInventoryTableUrl = '/admin/carInventoryTable/export';

// 获取型号和年款列表
export function getModelAndYearBySeries(
  data,
  config = { noShowWaiting: true }
) {
  return http.post('/admin/car/model/numberAndYear', data, config);
}

/**
 * 搜索车型（带年款）- 分页
 */
export function getCarModelList(data, config = { noShowWaiting: true }) {
  return http.post('/admin/car/model/page', data, config);
}

// ============================车辆年审==============
export function getCarAnnualReviewPreList(
  data,
  config = { noShowWaiting: true }
) {
  return http.post('/admin/mini/car/annual/review/apply/preList', data, config);
}
export function getCarAnnualReviewStat(data) {
  return http.post('/admin/mini/car/annual/review/apply/stat', data);
}
export function getCarAnnualReviewList(data) {
  return http.post('/admin/mini/car/annual/review/apply/list', data);
}
export function getCarAnnualReviewInfo(data) {
  return http.post('/admin/mini/car/annual/review/apply/info', data);
}
export function updateCarAnnualReview(data) {
  return http.post('/admin/mini/car/annual/review/apply/update', data);
}

export function acceptCarAnnualReview(data) {
  return http.post('/admin/mini/car/annual/review/apply/accept', data);
}
export function bookedCarAnnualReview(data) {
  return http.post('/admin/mini/car/annual/review/apply/booked', data);
}
export function illegalCarAnnualReview(data) {
  return http.post('/admin/mini/car/annual/review/apply/illegal', data);
}
export function unExpiredCarAnnualReview(data) {
  return http.post('/admin/mini/car/annual/review/apply/unExpired', data);
}
export function doneCarAnnualReview(data) {
  return http.post('/admin/mini/car/annual/review/apply/done', data);
}

export function updateRemarkCarAnnualReview(data) {
  return http.post('/admin/mini/car/annual/review/apply/updateRemark', data);
}
export const carAnnualReviewListExportUrl =
  '/admin/mini/car/annual/review/apply/list/export';
// ============================车辆年审==============
//车型管理-导入车系禁用-批量上传导入
export function seriesEnableOrDisableImport(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/car/series/enableOrDisable/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}

//车型管理-导入车系禁用-最近错误结果
export function seriesEnableOrDisableImportResult(data) {
  return http.post('/admin/car/series/enableOrDisable/import/result', data);
}

//车型管理-导入车系禁用-最近错误结果导出
export const seriesEnableOrDisableImportFailResultExportUrl =
  '/admin/car/series/enableOrDisable/import/FailResultExport';

//车型管理-导出车系禁用-地址
export const carSeriesEnableOrDisableExportUrl =
  '/admin/car/series/enableOrDisable/export';
