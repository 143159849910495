<template>
  <div class="page-container tabs-sty">
    <el-tabs class="page-tabs" v-model="activeTabOrderRecordType">
      <el-tab-pane v-auth="495" label="应收账款汇总" name="first">
        <receivable-summary-first
          @updateActiveName="updateActiveName"
        ></receivable-summary-first>
      </el-tab-pane>
      <el-tab-pane v-auth="161" label="应收账款明细" name="second">
        <receivable-details-second
          :forms="forms"
          :times="times"
          v-if="activeTabOrderRecordType == 'second'"
        ></receivable-details-second>
      </el-tab-pane>
      <el-tab-pane v-auth="216" label="销账收款记录" name="third">
        <sales-collection-third
          v-if="activeTabOrderRecordType == 'third'"
        ></sales-collection-third>
      </el-tab-pane>
      <el-tab-pane v-auth="444" label="包干分摊批次" name="fourth">
        <parcel-dry-fourch
          v-if="activeTabOrderRecordType == 'fourth'"
        ></parcel-dry-fourch>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import receivableSummaryFirst from './receivableSummaryFirst.vue';
import receivableDetailsSecond from './receivableDetailsSecond.vue';
import salesCollectionThird from './salesCollectionThird.vue';
import parcelDryFourch from './parcelDryFourch.vue';
export default {
  name: 'accountsReceivableList',
  components: {
    receivableSummaryFirst,
    receivableDetailsSecond,
    salesCollectionThird,
    parcelDryFourch,
  },
  data() {
    return {
      //标签页切换
      activeTabOrderRecordType: 'first',
      forms: {},
      times: [],
    };
  },
  activated() {
    console.log('刷新页面');
    console.log(this.$route);
    if (this.$route?.params?.activeName) {
      this.activeTabOrderRecordType = this.$route.params.activeName;
    }
  },
  created() {},
  mounted() {},
  methods: {
    updateActiveName(data, form, times) {
      this.forms = form;
      this.times = times;
      // 修改activeTabOrderRecordType的名称
      this.activeTabOrderRecordType = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-sty {
  width: 96%;
  margin-left: 2%;
  margin-top: 30px;
}
.page-container {
  height: 100%;
  box-sizing: border-box;
}

.page-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  &::v-deep .el-tabs__content {
    flex: 1;
    overflow: auto;
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
