<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="接车员">
                <el-select
                  v-model="form.pickUpUserId"
                  filterable
                  clearable
                  remote
                  placeholder="搜索姓名"
                  :remote-method="searchAdminJobListUser"
                  :loading="searchAdminJobListUserLoading"
                >
                  <el-option
                    v-for="item in adminJobListUserList"
                    :key="item.userId"
                    :label="item.realName"
                    :value="item.userId"
                  >
                    <span
                      style="float: left; color: #8492a6; font-size: 13px"
                      >{{ item.realName }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="跟进人">
                <el-select
                  v-model="form.followUpPeople"
                  filterable
                  clearable
                  remote
                  placeholder="搜索姓名、手机号"
                  :remote-method="searchAllUser"
                  :loading="searchAllUserLoading"
                >
                  <el-option
                    v-for="item in allUserList"
                    :key="item.userId"
                    :label="item.realName"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="跟进进展">
              <el-select
                v-model="form.followUpState"
                filterable
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in followUpStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="flex-y-center" style="width: 100%; height: 100%">
                    <div :style="item.iconStyle">{{ item.iconText }}</div>
                    <span style="margin-left: 5px">{{ item.label }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="提醒">
              <el-select
                v-model="form.remind"
                multiple
                clearable
                placeholder="筛选提醒（支持多选）"
              >
                <el-option
                  v-for="item in remindList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="保险公司">
              <el-select
                v-model="form.insuranceCompanyName"
                filterable
                clearable
                remote
                placeholder="请输入公司名称、编号"
                :remote-method="searchInsurance"
                :loading="searchInsuranceLoading"
              >
                <el-option
                  v-for="item in insuranceList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.insuranceName
                  }}</span>
                  <span style="float: right">{{ item.insuranceNum }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="进店日期">
              <el-date-picker
                class="w100"
                v-model="form.intoStoreTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <template v-if="showMore">
              <el-form-item label="接车单号">
                <el-input
                  v-model="form.billCode"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>

              <el-form-item label="回款状态">
                <el-select
                  v-model="form.collectionState"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in collectionState"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车单状态">
                <el-select
                  v-model="form.billState"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in carBillState"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="提交定损日期">
                <el-date-picker
                  class="w100"
                  v-model="form.breakageTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="报案号">
                <el-input
                  v-model="form.caseNum"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="签约客户">
                <el-select
                  v-model="form.clientMessage"
                  filterable
                  clearable
                  remote
                  placeholder="请输入姓名"
                  :remote-method="searchClient"
                  :loading="searchClientLoading"
                >
                  <el-option
                    v-for="item in customList"
                    :key="item.id"
                    :label="item.clientName"
                    :value="item.id"
                  >
                    <span
                      style="float: left; color: #8492a6; font-size: 13px"
                      >{{ item.clientName }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    开票主体
                    <el-tooltip effect="light" placement="bottom-start">
                      <div
                        slot="content"
                        style="
                          color: #666666;
                          font-size: 13px;
                          line-height: 20px;
                          text-indent: 15px;
                        "
                      >
                        开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                        的回款管理门店(保险公司的开票主体也是定损主体)。保险公司<br />管理的开票主体，可查已成为保险公司开票
                        主体的全部门店。
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <el-select
                  v-model="form.insuranceId"
                  filterable
                  clearable
                  remote
                  placeholder="搜索开票主体名称"
                  :remote-method="searchInvoice"
                  :loading="searchInvoiceLoading"
                >
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="定损报备日期">
              <el-date-picker
                class="w100"
                v-model="form.breakageTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item> -->
              <el-form-item label="定损金额">
                <number-input
                  v-model="form.startLossAmount"
                  decimalDigits="2"
                  placeholder="开始金额"
                  style="width: 84px"
                ></number-input>
                -
                <number-input
                  v-model="form.endLossAmount"
                  decimalDigits="2"
                  placeholder="截止金额"
                  style="width: 84px"
                ></number-input>
              </el-form-item>
              <el-form-item label="回款认领日期">
                <el-date-picker
                  class="w100"
                  v-model="form.paymentCollectionTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="出损日期">
                <el-date-picker
                  class="w100"
                  v-model="form.damageOfTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="事故责任">
                <el-select
                  v-model="form.dutyType"
                  filterable
                  placeholder="请选择事故责任"
                  clearable
                >
                  <el-option
                    v-for="item in dutyTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="已结未出损">
                <el-select
                  v-model="form.finishedNoLossState"
                  placeholder="请选择"
                  clearable
                >
                  <el-option label="是" :value="1"> </el-option>
                </el-select>
              </el-form-item>
            </template>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="display: flex; justify-content: end">
        <el-button
          v-auth="465"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <div class="flex h100">
        <el-table
          class="custom-table"
          :data="carBillList"
          border
          @row-dblclick="goCarBillInfo($event)"
          v-fixedScrollBar="$refs.baseContainer"
        >
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column width="100" label="提醒" prop="remind">
            <template #default="{ row }">
              <p v-if="row.remind != 0" class="red">
                {{ row.remind | dict('remind') }}
              </p>
            </template>
          </el-table-column>
          <el-table-column width="100" label="跟进进展" align="center">
            <template #default="{ row }">
              <el-tooltip
                :content="
                  $lodash.get(
                    followUpStateList.find(
                      (item) => item.value === row.followUpState
                    ),
                    'tooltipText',
                    ''
                  )
                "
                placement="top-start"
              >
                <div
                  class="pointer"
                  :style="
                    $lodash.get(
                      followUpStateList.find(
                        (item) => item.value === row.followUpState
                      ),
                      'iconStyle',
                      ''
                    )
                  "
                >
                  {{
                    row.followUpState | dict('followUpStateList', 'iconText')
                  }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            label="操作门店"
            prop="operateCompanyName"
          >
          </el-table-column>
          <el-table-column width="180" label="报案号">
            <template slot-scope="scope">
              <span
                class="pointer text_operation blue"
                @click="goCarBillInfo(scope.row)"
                v-if="!['', undefined, null].includes(scope.row.caseNum)"
                >{{ scope.row.caseNum }}</span
              >
              <span class="orange pointer" v-else>鼠标双击打开详情</span>
            </template>
          </el-table-column>
          <el-table-column width="180" label="车牌号" prop="carNumber">
            <template #default="{ row }">
              <p>
                {{ row.carNumber }}
                <el-tag
                  size="small"
                  v-if="
                    row.isSupplementaryOrder !== undefined &&
                    row.isSupplementaryOrder === 1
                  "
                  >补单</el-tag
                >
              </p>
            </template>
          </el-table-column>
          <el-table-column width="150" label="客户名称" prop="clientName">
          </el-table-column>
          <!-- <el-table-column width="100" label="接车员" prop="pickUpUserName">
          </el-table-column> -->
          <el-table-column width="100" label="跟进人" prop="followUpPeopleName">
          </el-table-column>
          <el-table-column width="200" label="进店时间" prop="intoStoreTime">
          </el-table-column>
          <el-table-column width="150" prop="insuranceName">
            <template #header>
              <span>保险公司</span>
              <el-tooltip content="商业险保险公司">
                <i class="iconfont grow-icon_doubt color626"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="100" label="事故责任" prop="dutyType">
            <template #default="{ row }">
              <p>{{ row.dutyType | dict('dutyType') }}</p>
            </template>
          </el-table-column>
          <el-table-column width="100" label="定损状态">
            <template #default="{ row }">
              <p>
                <template
                  v-if="
                    row.lossDeterminationState === 2 &&
                    row.investigationState === 2
                  "
                  >待复勘</template
                >
                <template
                  v-else-if="
                    row.lossDeterminationState === 2 && row.recoveryState === 2
                  "
                  >待收旧</template
                >
                <template v-else>
                  {{
                    row.lossDeterminationState | dict('lossDeterminationState')
                  }}
                </template>
              </p>
            </template>
          </el-table-column>
          <el-table-column width="180" label="开票(定损)主体" prop="invoice">
          </el-table-column>
          <el-table-column width="180" label="定损金额" prop="lossAmount">
          </el-table-column>
          <el-table-column width="180" label="备注" prop="remark">
            <template #default="{ row }">
              <el-tooltip :content="row.remark">
                <div class="text-ellipsis-2-row">
                  {{ row.remark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="180" label="报案日期" prop="reportingTime">
          </el-table-column>
          <el-table-column
            width="120"
            label="定损员"
            prop="lossDeterminationPersonName"
          >
          </el-table-column>
          <el-table-column width="120" label="旧件情况" prop="recoveryState">
            <template #default="{ row }">
              <p v-if="row.recoveryState != 0">
                {{ row.recoveryState | dict('recoveryState') }}
              </p>
            </template>
          </el-table-column>
          <el-table-column width="120" label="复勘" prop="investigationState">
            <template #default="{ row }">
              <p v-if="row.investigationState != 0">
                {{ row.investigationState | dict('investigationState') }}
              </p>
            </template>
          </el-table-column>
          <el-table-column width="200" label="接车单号">
            <template slot-scope="scope">
              <span
                class="pointer text_operation blue"
                @click="goDetail(scope.row.billId)"
                >{{ scope.row.billCode }}</span
              >
            </template>
          </el-table-column>
          <el-table-column width="100" label="车单状态">
            <template #default="{ row }">
              <!-- 车单状态 -->
              <p>
                {{ row.billState | dict('carBillState') }}
                <span class="red" v-if="row.billOtherState"
                  >({{ row.billOtherState }})</span
                >
              </p>
              <p class="color999">
                <!-- 报价审核状态 -->
                <!-- {{ row.offerReviewState== 0 ? '/待审核' : '/报价已审' }} -->
                <!-- 检车状态 -->
                <!-- {{ row.detectionState | dict('detectionState') }} -->
              </p>
              <!-- 施工状态字段 -->
              <div v-if="row.dispatchState !== undefined">
                <span> /</span>
                {{ row.dispatchState | dict('dispatchState') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="回款状态" prop="collectionState">
            <template #default="{ row }">
              <p>{{ row.collectionState == 0 ? '未回款' : '已回款' }}</p>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            label="回款认领状态"
            prop="receivingAmountClaimState"
          >
          </el-table-column>
          <el-table-column
            width="180"
            label="报损时间"
            prop="reportingLossesTime"
          >
          </el-table-column>
          <el-table-column width="180" label="调度时间" prop="dispatchTime">
          </el-table-column>

          <el-table-column
            width="180"
            label="拍照对件时间"
            prop="photographTime"
          >
          </el-table-column>
          <el-table-column width="180" label="提交定损时间" prop="breakageTime">
          </el-table-column>
          <el-table-column width="180" label="出损时间" prop="damageOfTime">
          </el-table-column>
          <el-table-column
            width="180"
            label="复勘时间"
            prop="investigationTime"
          >
          </el-table-column>
          <el-table-column width="180" label="收旧时间" prop="recoveryTime">
          </el-table-column>
          <el-table-column
            width="180"
            label="提交资料时间"
            prop="submissionTime"
          >
          </el-table-column>
          <el-table-column
            width="200"
            label="部分回款时间"
            prop="partPaymentTime"
          >
          </el-table-column>
          <el-table-column
            width="200"
            label="全部回款时间"
            prop="allPaymentTime"
          >
          </el-table-column>
          <el-table-column
            width="120"
            label="最终定损金额"
            prop="finalLossAmount"
          >
          </el-table-column>
          <el-table-column width="100" label="单据金额" prop="documentAmount">
          </el-table-column>
          <el-table-column
            width="100"
            label="待收金额"
            prop="accountsReceivable"
          >
          </el-table-column>
          <el-table-column
            width="150"
            label="回款认领金额"
            prop="receivingAmount"
          >
          </el-table-column>
          <el-table-column
            width="150"
            label="挂账车队金额"
            prop="pendingAmount"
          >
          </el-table-column>
          <el-table-column width="100" label="坏账金额" prop="badDebtsAmount">
          </el-table-column>
          <el-table-column width="150" label="回款认领备注" prop="claimRemark">
            <template #default="{ row }">
              <el-tooltip :content="row.claimRemark">
                <div class="text-ellipsis-2-row">
                  {{ row.claimRemark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="200" label="车单备注" prop="companyRemark">
            <template #default="{ row }">
              <el-tooltip :content="row.companyRemark">
                <div class="text-ellipsis-2-row">
                  {{ row.companyRemark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="class-box">
          <!-- <el-table height="100%" :data="countTableData" border> -->
          <!-- <el-table-column prop="count" label="定损状态与统计(定损单数)">
              <template #default="{ row }">
                {{ row.name }}
                <span v-if="row.number !== undefined">({{ row.number }})</span>
              </template>
            </el-table-column> -->
          <p class="state-title">定损状态(单数)</p>
          <el-tree
            class="custom-tree"
            ref="my-tree"
            :data="countTableData"
            :props="treeProp"
            highlight-current
            node-key="id"
            :default-expanded-keys="defaultKeys"
            :current-node-key="nodeKey"
            @node-click="onNodeClick"
          >
            <template #default="{ data }">
              <div class="tree-node">
                <span>{{ data.name }}</span>
                <span>({{ data.number }})</span>
              </div>
            </template>
          </el-tree>
          <!-- </el-table> -->
        </div>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <!-- 定损单详情弹窗 -->
    <car-loss-record-drawer
      v-if="lossRecordDrawer"
      :visibility.sync="lossRecordDrawer"
      :carLossDeterminationBillId="this.ids"
      :billId="this.billId"
      @updateData="doSearch"
    >
    </car-loss-record-drawer>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// import { exportCusterListUrl } from '@/api/customer/customer';
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
//模糊搜索接口
import { searchStore as searchStoreApi } from '@/api/customer/store';
import { searchAdminJobListUsr } from '@/api/financial/financialOver';
import { searchClient } from '@/api/customer/customer';
import { searchInsuranceCompanyWithInvoiceAndPaging } from '@/api/customer/sysmanent';
import { getUserList, getAllUser } from '@/api/employee';
//列表接口
import {
  getCarLossDeterminationBillList,
  getCarLossDeterminationBillStatistic,
  exportCarLossDeterminationBillListUrl,
} from '@/api/financial/pickSingle';
import { searchInvoice } from '@/api/storeManagement/index';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';
// 定损单详情弹窗
import carLossRecordDrawer from './carLossDeterminationBillDetailDrawer.vue';
// 字典数据
import {
  dutyType,
  carLossBillState,
  followUpStateList,
} from '@/service/dict/dictData';
// 工具
import Big from 'big.js';
import { mapState } from 'vuex';
export default {
  name: 'carLossDeterminationBillList',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
    carLossRecordDrawer,
  },
  data() {
    return {
      form: {
        billCode: '',
        remind: [],
        followProgress: '',
        finishedNoLossState: '',
      },
      // 展示更多筛选
      showMore: false,
      //导出的页面设置
      exportUrl: exportCarLossDeterminationBillListUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      // 车单列表数据
      carBillList: [],
      countTableData: [
        {
          id: '',
          name: '全部',
          number: 0,
          prop: 'total',
        },
        {
          id: 0,
          name: '待报损',
          number: 0,
          prop: 'lossReportedSum',
        },
        {
          id: 7,
          name: '待调度',
          number: 0,
          prop: 'reportingLossesSum',
        },
        {
          id: 4,
          name: '待拍照对件',
          number: 0,
          prop: 'photographSum',
        },
        {
          id: 8,
          name: '待提定损',
          number: 0,
          prop: 'dispatchSum',
        },
        {
          id: 1,
          name: '待出定损',
          number: 0,
          prop: 'breakageSum',
        },
        {
          id: 2,
          name: '已出定损',
          number: 0,
          prop: 'damageOfSum',
          children: [
            {
              id: 30,
              name: '待复勘',
              number: 0,
              prop: 'investigationSum',
            },
            {
              id: 31,
              name: '待收旧',
              number: 0,
              prop: 'recoverySum',
            },
          ],
        },
        {
          id: 5,
          name: '已提资料',
          number: 0,
          prop: 'submissionSum',
        },
        {
          id: 3,
          name: '已认领',
          number: 0,
          prop: 'paymentCollectionSum',
          children: [
            {
              id: 3100,
              name: '部分回款',
              number: 0,
              prop: 'partialPaymentReceivedSum',
            },
            {
              id: 3101,
              name: '全部回款',
              number: 0,
              prop: 'allPaymentReceivedSumSum',
            },
          ],
        },
        {
          id: 6,
          name: '已取消',
          number: 0,
          prop: 'cancelSum',
        },
      ],
      treeProp: {
        children: 'children',
        label: 'name',
      },
      //定损记录详情抽屉
      lossRecordDrawer: false,
      //搜索操作门店字段
      searchInsuranceCompanyLoading: false,
      insuranceCompanyList: [],
      selectKey: 1,
      //搜索开票主体门店字段
      searchInvoiceLoading: false,
      invoiceList: [],
      //事故责任字段
      dutyTypeList: dutyType,
      //车单状态字段
      carBillState: carLossBillState,
      followUpStateList,
      ids: '', //定损单ID字段
      billId: '', //车单ID字段
      remindList: [
        {
          value: 1,
          label: '提报超期',
        },
        {
          value: 5,
          label: '调度超期',
        },
        {
          value: 2,
          label: '对件超期',
        },
        {
          value: 6,
          label: '定损超期',
        },
        {
          value: 3,
          label: '出损超期',
        },
        {
          value: 4,
          label: '回款超期',
        },
        {
          value: 7,
          label: '复勘超期',
        },
        {
          value: 8,
          label: '收旧超期',
        },
      ],
      collectionState: [
        {
          value: '0',
          label: '未回款',
        },
        {
          value: '1',
          label: '已回款',
        },
      ],
      //定损状态默认值
      nodeKey: 0,
      defaultKeys: [],
      lossDeterminationState: 0,
      customList: [],
      searchClientLoading: false,
      //保险公司
      searchInsuranceLoading: false,
      insuranceList: [],
      //搜索接车员列表字段
      // searchAdminJobListUserLoading: false,
      // adminJobListUserList: [],

      searchAllUserLoading: false,
      allUserList: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  watch: {
    nodeKey(id) {
      //设置选中的车单状态
      this.$refs['my-tree'].setCurrentKey(id);
    },
  },
  //从其他页面跳转过来
  activated() {
    if (this.$route.params.form) {
      this.empty();
      this.form.operateCompanyId = this.$route.params.form.operateCompanyId;
      this.form.operateCompanyUrl = this.$route.params.form.operateCompanyUrl;
      this.form.remind = this.$route.params.form.remind || [];
      this.nodeKey = this.$route.params.nodeKey;
      this.defaultKeys = [];
      this.lossDeterminationState = this.$route.params.nodeKey;
      this.form.billCode = this.$route.params.form.billCode || '';
      this.showMore = this.$route.params.showMore;
      this.$set(
        this.form,
        'followUpState',
        this.$route.params.form.followUpState ?? ''
      );
      // this.form.followUpState = this.$route.params.form.followUpState ?? '';
      // this.form.finishedNoLossState =
      //   this.$route.params.form.finishedNoLossState ?? '';
      this.$set(
        this.form,
        'finishedNoLossState',
        this.$route.params.form.finishedNoLossState ?? ''
      );
      this.getList();
      this.getCarStatistic();
    }
  },
  created() {
    this.searchInvoice();
    //从接车单详情-取消接待跳转
    // if (this.$route?.params?.billCode) {
    //   // this.$set(this.form, 'billCode', this.$route.params.billCode || '');
    //   this.form.billCode = this.$route.params.billCode || '';
    //   this.showMore = true;
    // }
    if (!this.$route.params.form) {
      this.form.operateCompanyId = [this.companyId];
      this.form.operateCompanyUrl = [this.companyId];
      this.getList();
      this.getCarStatistic();
    }
    this.searchAllUser();
  },
  mounted() {},
  methods: {
    // 置空输入框数据
    empty() {
      this.form = {
        billCode: '',
        remind: [],
        followProgress: '',
        finishedNoLossState: '',
      };
    },
    // 更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },

    // 定损记录详情抽屉弹出
    goCarBillInfo(row) {
      this.ids = row.id;
      this.billId = row.billId;
      // this.getDialogList();
      this.lossRecordDrawer = true;
      // this.searchInsuranceCompany();
    },
    /**
     * 远程搜索开票列表
     */
    searchInsuranceCompany(keyWord = '') {
      this.searchInsuranceCompanyLoading = true;
      searchStoreApi(keyWord, this.companyId)
        .then((res) => {
          this.insuranceCompanyList = res;
        })
        .finally(() => {
          this.searchInsuranceCompanyLoading = false;
        });
    },
    //远程搜索开票主体门店列表
    searchInvoice(keyWord = '') {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },
    /**
     * 远程搜索车主客户列表
     */
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },

    //保险公司远程查询列表
    searchInsurance(keyWord = '') {
      this.searchInsuranceLoading = true;
      searchInsuranceCompanyWithInvoiceAndPaging(keyWord)
        .then((res) => {
          this.insuranceList = res.records;
        })
        .finally(() => {
          this.searchInsuranceLoading = false;
        });
    },
    // //远程搜索接车员列表
    // searchAdminJobListUser(keyWord = '') {
    //   this.searchAdminJobListUserLoading = true;
    //   getUserList(keyWord)
    //     .then((res) => {
    //       this.adminJobListUserList = res.records || [];
    //     })
    //     .finally(() => {
    //       this.searchAdminJobListUserLoading = false;
    //     });
    // },
    /**
     * 点击查询
     */
    doSearch() {
      this.getList();
      this.getCarStatistic();
    },
    //定损单列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var allTimes = {
        intoStoreStartTime: this.form.intoStoreTime?.[0]
          ? this.form.intoStoreTime?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTime?.[1]
          ? this.form.intoStoreTime?.[1]
          : '',
        breakageStartTime: this.form.breakageTime?.[0]
          ? this.form.breakageTime?.[0]
          : '',
        breakageEndTime: this.form.breakageTime?.[1]
          ? this.form.breakageTime?.[1]
          : '',
        damageOfStartTime: this.form.damageOfTime?.[0]
          ? this.form.damageOfTime?.[0]
          : '',
        damageOfEndTime: this.form.damageOfTime?.[1]
          ? this.form.damageOfTime?.[1]
          : '',
        paymentCollectionStartTime: this.form.paymentCollectionTime?.[0]
          ? this.form.paymentCollectionTime?.[0]
          : '',
        paymentCollectionEndTime: this.form.paymentCollectionTime?.[1]
          ? this.form.paymentCollectionTime?.[1]
          : '',
        companyId: this.companyId,
        lossDeterminationState: this.lossDeterminationState,
      };
      var data = Object.assign(this.form, allTimes, this.pageOption);
      delete data.exportStartNum;
      delete data.exportEndNum;
      getCarLossDeterminationBillList(data).then((res) => {
        this.total = res.total;
        this.carBillList = res.records;
      });
    },
    //获取定损状态数据
    getCarStatistic() {
      var allTimes = {
        intoStoreStartTime: this.form.intoStoreTime?.[0]
          ? this.form.intoStoreTime?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTime?.[1]
          ? this.form.intoStoreTime?.[1]
          : '',
        breakageStartTime: this.form.breakageTime?.[0]
          ? this.form.breakageTime?.[0]
          : '',
        breakageEndTime: this.form.breakageTime?.[1]
          ? this.form.breakageTime?.[1]
          : '',
        damageOfStartTime: this.form.damageOfTime?.[0]
          ? this.form.damageOfTime?.[0]
          : '',
        damageOfEndTime: this.form.damageOfTime?.[1]
          ? this.form.damageOfTime?.[1]
          : '',
        paymentCollectionStartTime: this.form.paymentCollectionTime?.[0]
          ? this.form.paymentCollectionTime?.[0]
          : '',
        paymentCollectionEndTime: this.form.paymentCollectionTime?.[1]
          ? this.form.paymentCollectionTime?.[1]
          : '',
        companyId: this.companyId,
        lossDeterminationState: this.lossDeterminationState,
      };
      var data = Object.assign(this.form, allTimes);
      delete data.exportStartNum;
      delete data.exportEndNum;
      getCarLossDeterminationBillStatistic(data).then((res) => {
        this.countTableData.forEach((item) => {
          item.number = res[item.prop] || 0;
        });

        this.countTableData
          .find((el) => el.id === 2)
          .children.forEach((item) => {
            item.number = res[item.prop] || 0;
          });
        this.countTableData
          .find((el) => el.id === 3)
          .children.forEach((item) => {
            item.number = res[item.prop] || 0;
          });
      });
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
    //节点事件
    onNodeClick(node) {
      this.nodeKey = node.id;
      this.defaultKeys = [node.id];
      this.lossDeterminationState = node.id;
      this.getList();
    },

    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      this.searchAllUserLoading = true;
      getAllUser({ msg })
        .then((res) => {
          this.allUserList = res.records || [];
        })
        .finally(() => {
          this.searchAllUserLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__header {
  margin: 25px 30px 0px 30px !important;
}

.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  .state-title {
    text-align: center;
    line-height: 44px;
    height: 44px;
    background: #f5f7fa;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
    color: #303133;
  }
}
::v-deep .el-tree-node__content {
  height: 44px !important;
  border-bottom: 1px solid#EBEEF5;
}
.tree-node {
  span {
    color: #303133;
    font-size: 14px;
  }
}
//定损金额输入框样式修改
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 10px !important;
}
</style>
