<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    top="1vh"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="120px"
      style="margin-top: 18px"
    >
      <div
        style="margin-bottom: 30px; margin-left: 40px; height: 40px"
        class="flex"
      >
        <el-button
          size="small"
          type="primary"
          class="el-icon-refresh"
          circle
          title="刷新认领流水"
          style="position: absolute; right: 30px"
          @click="refreshAmountClaimData"
        ></el-button>
      </div>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="跟进人：" prop="followUpPeople">
            <el-select
              v-model="editForm.followUpPeople"
              filterable
              clearable
              remote
              placeholder="搜索姓名、手机号"
              :remote-method="searchAllUser"
              :loading="searchAllUserLoading"
              @select="doVerify('followUpPeople')"
            >
              <el-option
                v-for="item in allUserList"
                :key="item.userId"
                :label="item.realName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="最终定损金额：" prop="finalLossAmount">
            <number-input
              v-model="editForm.finalLossAmount"
              decimalDigits="2"
              :max="999999.99"
              placeholder="请输入最终定损金额"
              clearable
              @blur="doVerify('finalLossAmount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item style="text-align: right">
            <el-button
              v-auth="654"
              size="small"
              type="primary"
              @click="gotoClaimDetailPage"
              >去认领</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="车单状态：">
            {{ billVo.billState | dict('carBillState') }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="单据金额:">
            {{ billVo.documentAmount | toThousands }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="待收金额:">
            {{ unclearedDebt | toThousands }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="hasReceivingAmount">
        <el-col :span="24"
          ><el-form-item label="认领情况："></el-form-item
        ></el-col>
      </el-row>
      <el-row
        :gutter="10"
        v-if="hasReceivingAmount"
        style="margin-bottom: 20px"
      >
        <el-col :span="24">
          <el-table
            show-summary
            :height="receivingAmountHeight"
            :summary-method="getSummaries"
            max-height="300"
            width="360"
            :data="claimTableInfoList"
            border
          >
            <el-table-column
              min-width="180"
              label="银行流水号"
              prop="transactionSeq"
            >
            </el-table-column>
            <el-table-column
              width="180"
              label="回款认领金额"
              prop="claimAmount"
            >
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="回款情况：" prop="receivingAmountStatus">
            <el-switch
              v-model="editForm.receivingAmountStatus"
              @change="handleReceivingAmountStatusChange"
              :active-value="1"
              :inactive-value="0"
              active-text="全部回款"
              inactive-text="部分回款"
              class="mb-2"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="10"
        v-if="billVo.billState !== 1 && editForm.receivingAmountStatus === 1"
      >
        <el-col :span="24">
          <el-form-item label="是否全部自费：" prop="isAllSelfPay">
            <el-switch
              v-model="editForm.isAllSelfPay"
              :active-value="true"
              :inactive-value="false"
              active-text="是"
              inactive-text="否"
              class="mb-2"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 有银行流水认领 和 部分回款 -->
      <el-row :gutter="10" v-if="partialPaymentReceived">
        <el-col :span="24">
          <el-form-item label="多保司回款:" prop="multiInsurancePayment">
            <el-switch
              v-model="editForm.multiInsurancePayment"
              :active-value="true"
              :inactive-value="false"
              class="mb-2"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="partialPaymentReceived">
        <el-col :span="12">
          <el-form-item label="挂账车队：">
            {{ infoVo.settlementClientName }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="坏账：" prop="includeBadDebts">
            <el-switch
              v-model="editForm.includeBadDebts"
              :active-value="true"
              :inactive-value="false"
              class="mb-2"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="partialPaymentReceived">
        <el-col :span="12">
          <el-form-item label="挂账金额：" prop="pendingAmount">
            <number-input
              v-model="editForm.pendingAmount"
              :max="999999.99"
              decimalDigits="2"
              placeholder="请输入挂账金额"
              clearable
              @blur="doVerify('lossAssessmentAmount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="坏账金额：" prop="badDebtsAmount">
            <number-input
              v-model="editForm.badDebtsAmount"
              :max="999999.99"
              :disabled="!editForm.includeBadDebts"
              decimalDigits="2"
              placeholder="请输入坏账金额"
              clearable
              @blur="doVerify('lossAmount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="当次留言：" prop="message">
            <el-input
              type="textarea"
              v-model="editForm.message"
              maxlength="300"
              show-word-limit
              placeholder="请输入当次留言，最多300个字符"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="图片" prop="feeListPicA">
            <span style="color: #909399; font-size: 12px"
              >支持上传 10 张图片，建议每张图片大小不超过 2 MB</span
            >

            <div>
              <upload-file
                v-model="editForm.runningLicenceImg"
                title="行驶证"
              ></upload-file>
              <upload-file
                v-model="editForm.picDrivingLicence"
                title="驾驶证"
              ></upload-file>
              <upload-file
                v-model="editForm.feeListPicA"
                title="定损单1"
                :requiredIcon="type >= 8"
              >
              </upload-file>
              <upload-file
                v-model="editForm.feeListPicB"
                title="定损单2"
              ></upload-file>
              <upload-file
                v-model="editForm.feeListPicC"
                title="定损单3"
              ></upload-file>
              <upload-file-list
                :max="5"
                v-model="editForm.feeListPicOthersList"
              ></upload-file-list>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="附件" prop="attachment">
            <span style="color: #909399; font-size: 12px"
              >只支持上传zip文件，最多5个</span
            >
            <div class="flex">
              <ul style="max-width: 300px">
                <li v-for="(item, i) in attachmentList" :key="i">
                  <i
                    class="iconfont grow-icon_delete3 orange el-icon--left pointer"
                    @click="handleDelete(i)"
                  ></i>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
              <div style="margin-left: 20px" v-if="attachmentList.length < 5">
                <el-button size="small" @click="chooseFile">选择文件</el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 字典数据
import {
  followUpStateList,
  recoveryState,
  investigationState,
  carBillState,
} from '@/service/dict/dictData';

import { getAllUser } from '@/api/employee';
import {
  receivingAmountClaimSave,
  getClaimInfo,
  uploadZipFile,
} from '@/api/carBill/carLossDeterminationBill';

import UploadFile from '@/components/uploadFile.vue';
import uploadFileList from '@/components/uploadFileList';
import { chooseFile } from '@/utils/fileApi';

export default {
  name: 'editDialog',
  components: { UploadFile, uploadFileList },
  props: {
    visibility: Boolean,
    id: Number,
    type: Number,
    title: {
      type: String,
      default: '编辑',
    },
    billVoData: {
      type: Object,
      default: () => ({}),
    },
    carVoData: {
      type: Object,
      default: () => ({}),
    },
    infoVoData: {
      type: Object,
      default: () => ({}),
    },
    picVoData: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      followUpStateList,
      recoveryState,
      investigationState,
      carBillState: carBillState,

      // 车单信息
      billVo: {},
      // 车辆信息
      carVo: {},
      // 定损单信息
      infoVo: {},
      // 图片信息
      picVo: {},

      // 认领流水列表
      claimTableInfoList: [],
      // 是否可以切换 部分回款和全部回款，如果有银行流水认领金额，并且不等于待收金额，不能切换到全部回款
      disabledSwitchReceivingAmountStatus: false,
      unclearedDebt: null,
      // 是否有回款认领记录
      hasReceivingAmount: false,
      // 部分回款——单据金额和认领金额比较
      partialPaymentReceived: false,

      // 表单信息
      editForm: {
        receivingAmountStatus: 0,
        includeBadDebts: false,
        pendingAmount: 0,
        badDebtsAmount: 0,
        isAllSelfPay: false,
        message: '',
      },
      attachmentList: [],
      searchAllUserLoading: false,
      searchUserTimer: undefined,
      allUserList: [],
      receivingAmountHeight: 300,
    };
  },
  computed: {
    // 表单规则
    rules() {
      const part1 = {
        followUpPeople: [
          { required: true, message: '请选择跟进人', trigger: 'blur' },
        ],
        receivingAmountStatus: [
          { required: true, message: '请选择回款情况', trigger: 'blur' },
        ],
        finalLossAmount: [
          { required: true, message: '请输入最终定损金额', trigger: 'blur' },
        ],
      };
      const part2 = {};
      // 完成调度
      if (this.partialPaymentReceived) {
        part2.pendingAmount = [
          { required: true, message: '请输入挂账金额', trigger: 'blur' },
        ];
        part2.multiInsurancePayment = [
          { required: true, message: '请选择是否多保司回款', trigger: 'blur' },
        ];
      }
      // 完成对件
      if (this.editForm.includeBadDebts === true) {
        part2.badDebtsAmount = [
          { required: true, message: '请输入坏账金额', trigger: 'blur' },
        ];
      }
      return Object.assign({}, part1, part2);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.billVo = this.$lodash.cloneDeep(this.billVoData);
      this.carVo = this.$lodash.cloneDeep(this.carVoData);
      this.infoVo = this.$lodash.cloneDeep(this.infoVoData);
      this.picVo = this.$lodash.cloneDeep(this.picVoData);
      this.editForm = { ...this.infoVo, ...this.picVo };
      this.$set(this.editForm, 'isAllSelfPay', false);
      this.refreshAmountClaimData();
      if (this.editForm.followUpPeople) {
        this.allUserList = [
          {
            userId: this.editForm.followUpPeople,
            realName: this.editForm.followUpPeopleName,
          },
        ];
      }
      if (this.billVo.billState == 1) {
        this.unclearedDebt = this.billVo.unclearedDebt;
      } else {
        this.unclearedDebt = null;
      }
      this.editForm.feeListPicOthersList =
        this.editForm.feeListPicOthersList.join(',');
      if (this.editForm.attachmentUrl) {
        const url = this.editForm.attachmentUrl.split(',').filter((el) => el);
        const name = this.editForm.attachmentName.split(',').filter((el) => el);
        this.attachmentList = url.map((item, index) => {
          return {
            url: item,
            name: name[index],
          };
        });
      }
      if (
        this.infoVo.exemptionPayMoney !== undefined &&
        this.infoVo.settlementMethod === 0 &&
        this.infoVo.pendingAmount === undefined
      ) {
        this.editForm.pendingAmount = this.infoVo.exemptionPayMoney;
      }
      if (this.editForm.pendingAmount === undefined) {
        this.editForm.pendingAmount = 0;
      }
      // 默认是全部回款
      this.editForm.receivingAmountStatus = 1;
      // this.handleReceivingAmountStatusChange(
      //   this.editForm.receivingAmountStatus || 0
      // );
    },
    /**
     * 刷新认领数据
     */
    refreshAmountClaimData() {
      const _this = this;
      getClaimInfo({ id: this.infoVo.id }).then((res) => {
        _this.claimTableInfoList = res.data || [];
        let _height = _this.claimTableInfoList.length * 40 + 80;
        if (_height > 300) {
          _height = 300;
        }
        if (_height < 160) {
          _height = 160;
        }
        _this.receivingAmountHeight = _height;
        if (_this.claimTableInfoList.length > 0) {
          _this.hasReceivingAmount = true;
          let _claimAmount = 0;
          _this.claimTableInfoList
            .map((val) => val.claimAmount)
            .forEach((c) => {
              _claimAmount += c;
            });
          if (_this.unclearedDebt > _claimAmount) {
            _this.editForm.receivingAmountStatus = 0;
            _this.partialPaymentReceived = true;
          } else {
            _this.editForm.receivingAmountStatus = 1;
            _this.partialPaymentReceived = false;
          }
          if (
            _this.infoVo.lossDeterminationState === 3100 &&
            _this.infoVo.multiInsurancePayment === true
          ) {
            _this.editForm.receivingAmountStatus = 0;
            _this.partialPaymentReceived = true;
          }
          _this.disabledSwitchReceivingAmountStatus = true;
        } else {
          // 如果是0结算的，或者多保司认领
          if (
            _this.infoVo.lossDeterminationState === 3100 &&
            _this.infoVo.multiInsurancePayment === true
          ) {
            _this.editForm.receivingAmountStatus = 0;
            _this.partialPaymentReceived = true;
          }
        }
      });
    },
    /**
     * 回款认领流水汇总统计
     * @param {Object} params 数据
     * @param columns 列
     * @param data 认领流水
     */
    getSummaries(params) {
      const { columns, data } = params;
      const sums = [];
      sums[0] = '合计';
      let _claimAmount = 0;
      data.forEach((row, index) => {
        _claimAmount += row.claimAmount;
      });
      sums[1] = _claimAmount;
      return sums;
    },
    handleReceivingAmountStatusChange(val) {
      // 如果有免赔金额并且是挂账车队的，否则挂账金额是0，带过来显示到挂账金额中
      if (
        this.infoVo.exemptionPayMoney !== undefined &&
        this.infoVo.settlementMethod === 0 &&
        this.infoVo.pendingAmount === undefined
      ) {
        this.editForm.pendingAmount = this.infoVo.exemptionPayMoney;
      }
      if (this.editForm.pendingAmount === undefined) {
        this.editForm.pendingAmount = 0;
      }
      if (val == 1) {
        this.partialPaymentReceived = false;
      } else {
        this.partialPaymentReceived = true;
      }
      this.$forceUpdate();
    },
    /**
     * 跳转到认领页面
     */
    gotoClaimDetailPage() {
      this.$router.push({
        name: 'transaction_claim',
        query: {
          companyId: this.billVo.companyId,
          carNumber: this.carVo.carNumber,
          billCode: this.billVo.billCode,
          t: new Date().getTime(),
        },
      });
      // 关闭这2层弹出框和抽屉
      this.$emit('update:visibility', false);
      this.$emit('gotoClaimDetailPage');
    },
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      if (this.searchUserTimer) {
        clearTimeout(this.searchUserTimer);
      }
      this.searchGoodTimer = setTimeout(() => {
        this.searchAllUserLoading = true;
        let params = {};
        if (typeof msg === 'object') {
          params = msg;
        } else {
          params = { msg };
        }
        getAllUser(params)
          .then((res) => {
            this.allUserList = res.records || [];
          })
          .finally(() => {
            this.searchAllUserLoading = false;
          });
      }, 800);
    },
    /**
     * 保存
     */
    save() {
      const _this = this;
      this.$refs.form.clearValidate();
      this.$refs.form
        .validate()
        .then((result, object) => {
          // 校验回款认领金额  是全部还是部分
          // 无法校验，因为即使是结账了， 也可能是部分认领， 主责和次责 分开认领， 不同的保险公司
          // 待认领金额 可能是 undefined or >= 0
          // 不能校验, 因为结账了，保险公司还没打款回来呢， 流水可能还未上传， 认领金额是0， 先打个false 不校验先
          // eslint-disable-next-line no-constant-condition
          //处理图片
          const picObj = {
            runningLicenceImg: this.editForm.runningLicenceImg
              ?.split('/')
              .at(-1),
            picDrivingLicence: this.editForm.picDrivingLicence
              ?.split('/')
              .at(-1),
            feeListPicA: this.editForm.feeListPicA?.split('/').at(-1),
            feeListPicB: this.editForm.feeListPicB?.split('/').at(-1),
            feeListPicC: this.editForm.feeListPicC?.split('/').at(-1),
            feeListPicOthers: this.editForm.feeListPicOthersList
              ?.split(',')
              .map((item) => item.split('/').at(-1))
              .join(),
          };
          const attachmentObj = {
            attachmentName: this.attachmentList
              .map((item) => item.name)
              .join(','),
            attachmentUrl: this.attachmentList
              .map((item) => item.url.split('/').at(-1))
              .join(','),
          };
          const body = Object.assign(
            {
              id: this.id,
              billId: this.billVo.billId,
              receivingAmountStatus: this.editForm.receivingAmountStatus,
              pendingAmount: this.editForm.pendingAmount,
              badDebtsAmount: this.editForm.badDebtsAmount,
              includeBadDebts: this.editForm.includeBadDebts,
              message: this.editForm.message,
              finalLossAmount: this.editForm.finalLossAmount,
              multiInsurancePayment: this.editForm.multiInsurancePayment,
              followUpPeople: this.editForm.followUpPeople,
              isAllSelfPay: this.editForm.isAllSelfPay,
            },
            picObj,
            attachmentObj
          );
          if (this.editForm.receivingAmountStatus == 1) {
            delete body.pendingAmount;
            delete body.badDebtsAmount;
            delete body.includeBadDebts;
          }
          receivingAmountClaimSave(body).then((res) => {
            this.$message.success(res.data || '操作成功!');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },

    /**
     * 校验某个字段
     */
    doVerify(prop) {
      this.$refs.form.validateField(prop);
    },

    /**
     * 选择文件
     */
    chooseFile() {
      chooseFile({ accept: '.zip', multiple: false }).then((file) => {
        console.log(file[0]);
        // 大于100M不允许上传
        if (file[0].size > 1024 * 1024 * 100) {
          this.$message.error('上传的附件大小不能超过100MB!');
          return;
        }
        uploadZipFile(file[0]).then((res) => {
          this.attachmentList.push({
            url: res.imageUrl,
            // name: res.name.replace(/,/g, ''),
            name: file[0].name.replace(/,/g, ''),
          });
        });
      });
    },

    /**
     * 删除
     */
    handleDelete(index) {
      this.attachmentList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
