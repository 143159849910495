<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="车型简称" prop="abbreviatedName">
            <el-input
              v-model="editForm.abbreviatedName"
              placeholder="请输入车型简称"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="20">
          <el-form-item label="品牌" prop="logoId">
            <el-select
              v-model="editForm.logoId"
              filterable
              remote
              placeholder="搜索品牌"
              :remote-method="searchCarLogo"
              :loading="searchCarLogoLoading"
              size="small"
              class="w100"
              @change="handleCarLogoChange"
            >
              <el-option
                v-for="(item, index) in carLogoList"
                :key="index"
                :label="item.logoName"
                :value="item.logoId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button
            v-auth="587"
            class="w100"
            size="small"
            type="primary"
            @click="showCarLogoEditDialog = true"
            >新增品牌</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="车系" prop="seriesId">
            <el-select
              v-model="editForm.seriesId"
              filterable
              allow-create
              remote
              :placeholder="editForm.logoId ? '搜索车系' : '请先选择品牌'"
              :disabled="!editForm.logoId"
              :remote-method="searchCarSeries"
              :loading="searchCarSeriesLoading"
              size="small"
              class="w100"
              @change="handleSelectSeries"
            >
              <el-option
                v-for="(item, index) in carSeriesList"
                :key="index"
                :label="item.seriesName"
                :value="item.seriesId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="型号" prop="modelNumberName">
            <el-input
              v-model="editForm.modelNumberName"
              placeholder="请输入型号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="年份" prop="modelYearName">
            <el-input
              v-model="editForm.modelYearName"
              placeholder="请输入年份（如2022）"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="标准名称">
            <span>
              {{ editForm.logoName }}{{ editForm.seriesName }}
              {{ editForm.modelNumberName }}{{ editForm.modelYearName }}</span
            >
          </el-form-item>
        </el-col>
        <!-- <div style="margin-left: 100px">
          标准名称：{{ editForm.logoName }}{{ editForm.seriesName }}
          {{ editForm.modelNumberName }}{{ editForm.modelYearName }}
        </div> -->
      </el-row>
    </el-form>

    <template slot="footer">
      <div class="flex-x-between flex-y-center">
        <div>
          <span
            v-auth="{
              code: 589,
              additionalConditions: !!detail.logoId,
            }"
            class="red pointer"
            style="font-size: 12px"
            @click="deleteModel"
            >删除车型</span
          >
        </div>
        <div>
          <el-button size="small" @click="handleClose"> 取 消 </el-button>
          <el-button size="small" type="primary" @click="save">
            确 定
          </el-button>
        </div>
      </div>
      <edit-car-brand-dialog
        v-if="showCarLogoEditDialog"
        :visibility.sync="showCarLogoEditDialog"
        @save="handleAddLogo"
      ></edit-car-brand-dialog>
    </template>
  </el-dialog>
</template>

<script>
import editCarBrandDialog from './editCarBrandDialog.vue';

import { mapState } from 'vuex';

import {
  searchCarLogo as searchCarLogoApi,
  searchCarSeries as searchCarSeriesApi,
  addCarModel as addCarModelApi,
  editCarModel as editCarModelApi,
  queryModelIsBindingCar as queryModelIsBindingCarApi,
  deleteCarModel as deleteCarModelApi,
} from '@/api/customer/carModelManage';
export default {
  name: 'editGoodsAliasDialog',
  components: { editCarBrandDialog },
  props: {
    visibility: Boolean,
    detail: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      // 表单信息
      editForm: {},
      // 表单规则
      rules: {
        abbreviatedName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入车型简称！',
          },
        ],
        logoId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择品牌！',
          },
        ],
        seriesId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择车系！',
          },
        ],
        // class: [
        //   {
        //     required: true,
        //     // trigger: 'blur',
        //     validator: this.validateClass,
        //   },
        // ],
      },

      // 品牌列表
      carLogoList: [],
      // 搜索品牌loading
      searchCarLogoLoading: false,
      // 车系列表
      carSeriesList: [],
      // 搜索车系loading
      searchCarSeriesLoading: false,

      // 新增车品牌弹窗
      showCarLogoEditDialog: false,
    };
  },
  computed: {
    // ...mapState({
    //   userId: (state) => state.user.userInfo.userId,
    // }),
    title() {
      return this.detail.logoId ? '编辑车型' : '新增车型';
    },
  },
  created() {
    this.editForm = this.$lodash.clone(this.detail);
    this.searchCarLogo(this.editForm.logoName);
    if (this.editForm.logoId) {
      this.searchCarSeries(this.editForm.seriesName);
    }
  },
  methods: {
    /**
     * 搜索品牌列表
     */
    searchCarLogo(keyWord) {
      this.searchCarLogoLoading = true;
      searchCarLogoApi(
        { carLogoName: keyWord || '', current: 1, size: 300 },
        { noShowWaiting: true }
      )
        .then((res) => {
          this.carLogoList = res.records || [];
        })
        .finally(() => {
          this.searchCarLogoLoading = false;
        });
    },
    /**
     * 品牌变更
     */
    handleCarLogoChange(id) {
      if (id) {
        var val = this.carLogoList.find((el) => el.logoId === id);
        this.$set(this.editForm, 'logoName', val.logoName);
      }
      this.clearData('logo');
    },
    /**
     * 新增品牌
     */
    handleAddLogo(val) {
      this.carLogoList.push(val);
      this.$set(this.editForm, 'logoId', val.logoId);
      this.$set(this.editForm, 'logoName', val.logoName);
      this.clearData('logo');
    },
    /**
     * 清除下级数据
     */
    clearData(type) {
      if (type === 'logo') {
        // 清空下级
        this.$set(this.editForm, 'brandId', '');
        this.$set(this.editForm, 'brandName', '');
        this.$set(this.editForm, 'seriesId', '');
        this.$set(this.editForm, 'seriesName', '');
      }
      this.$set(this.editForm, 'modelNumberId', '');
      this.$set(this.editForm, 'modelNumberName', '');
      this.$set(this.editForm, 'modelYearId', '');
      this.$set(this.editForm, 'modelYearName', '');
      this.searchCarSeries();
    },
    /**
     * 搜索车系列表
     */
    searchCarSeries(keyWord) {
      this.searchCarSeriesLoading = true;
      searchCarSeriesApi(
        {
          carLogoId: this.editForm.logoId,
          carSeriesName: keyWord || '',
          current: 1,
          size: 300,
        },
        { noShowWaiting: true }
      )
        .then((res) => {
          this.carSeriesList = res.records || [];
        })
        .finally(() => {
          this.searchCarSeriesLoading = false;
        });
    },
    /**
     * 选择车系
     */
    handleSelectSeries(id) {
      var val = this.carSeriesList.find((el) => el.seriesId === id);
      if (val) {
        this.$set(this.editForm, 'seriesName', val.seriesName);
        this.$set(this.editForm, 'brandId', val.brandId);
        this.$set(this.editForm, 'brandName', val.brandName);
      } else {
        // this.$set(this.editForm, 'seriesId', '');
        this.$set(this.editForm, 'seriesName', id);
        this.$set(this.editForm, 'brandId', '');
        this.$set(this.editForm, 'brandName', '');
      }
      this.clearData();
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var body = this.editForm;
          // 新增车系
          if (!body.brandId) {
            body.seriesId = '';
          }
          // 型号变更
          // 年款变更
          var api = this.editForm.abbreviatedId
            ? editCarModelApi
            : addCarModelApi;
          api(this.editForm).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 删除
     */
    deleteModel() {
      this.$confirm('确定删除？', '提示').then(() => {
        queryModelIsBindingCarApi({ id: this.editForm.abbreviatedId }).then(
          async (res) => {
            if (res) {
              var next = await this.$confirm(
                '当前系统存在车辆关联此车型，是否确定删除',
                '提示',
                {
                  type: 'warning',
                }
              );
              if (next !== 'confirm') return;
            }
            deleteCarModelApi({ id: this.editForm.abbreviatedId }).then(
              (result) => {
                this.$message.success('删除成功！');
                this.$emit('save');
                this.handleClose();
              }
            );
          }
        );
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
