<template>
  <div ref="printDom" class="print-template">
    <div ref="print">
      <div class="print-template__header">
        <h1 class="print-template__header__title">
          {{ billMessage.operateCompanyName }}
        </h1>
        <p style="margin-top: 5px; font-size: 18px; text-align: center">
          {{ config.title }}
        </p>
        <img
          class="print-template__header__lt-img"
          v-if="config.leftImagePath"
          :src="config.leftImagePath"
        />
        <img
          class="print-template__header__rt-img"
          v-if="config.rightImagePath"
          :src="config.rightImagePath"
        />
      </div>
      <div class="print-template__date" style="font-size: 12px">
        打印时间：{{ printTime }}
      </div>
      <div class="print-template__user">
        <h3 ref="clientName">客户：{{ carInfo.clientName }}</h3>
        <h3>接车单号：{{ billMessage.billCode }}</h3>
      </div>
      <div class="print-template__box__border">
        <table class="print-template__box__border__table">
          <tbody>
            <!-- 理赔单  -->
            <tr v-if="billType === 2">
              <td style="width: 85px; text-align: left">消费客户</td>
              <td>
                {{ carBillInsuranceInfo.claimsInsuranceCompanyName }}
              </td>
              <td style="width: 85px; text-align: left">报案号</td>
              <td>
                {{ carBillInsuranceInfo.caseNum }}
              </td>
              <td style="width: 85px; text-align: left">事故责任</td>
              <td>
                {{ carBillInsuranceInfo.dutyType | dict('dutyType') }}
              </td>
            </tr>

            <!-- 索赔单 -->
            <tr v-if="billType === 3">
              <td style="width: 85px; text-align: left">消费客户</td>
              <td>
                {{ carBillUndertakeInfo.undertakeInsuranceCompanyName }}
              </td>
              <td style="width: 85px; text-align: left">索赔单号</td>
              <td colspan="3">
                {{ carBillUndertakeInfo.claimsNum.join('、') }}
              </td>
            </tr>

            <tr>
              <td style="width: 85px; text-align: left">送修司机</td>
              <td style="width: 165px; text-align: left">
                {{ billMessage.customerName }}
              </td>
              <td style="width: 100px; text-align: left">送修司机电话</td>
              <td style="width: 179px; text-align: left">
                {{ billMessage.customerPhone }}
              </td>
              <td style="width: 85px; text-align: left">车牌号</td>
              <td style="width: 162px; text-align: left">
                {{ carInfo.carNumber }}
              </td>
            </tr>

            <tr>
              <td style="width: 85px; text-align: left">车型</td>
              <td style="width: 165px; text-align: left">
                {{ carInfo.brandName }}
              </td>
              <td style="width: 90px; text-align: left">车架号</td>
              <td style="width: 179px; text-align: left">
                {{ carInfo.uniqueId }}
              </td>
              <td style="width: 85px; text-align: left">发动机号</td>
              <td style="width: 162px; text-align: left">
                {{ carInfo.engineNumber }}
              </td>
            </tr>

            <tr>
              <td style="width: 85px; text-align: left">接车员</td>
              <td style="width: 165px; text-align: left">
                {{ billMessage.pickUpUserName }}
              </td>
              <td style="width: 90px; text-align: left">进店时间</td>
              <td style="width: 179px; text-align: left">
                {{ billMessage.dateCreated }}
              </td>
              <td style="width: 85px; text-align: left">进店里程</td>
              <td style="width: 162px; text-align: left">
                {{ billMessage.mileage ? billMessage.mileage + 'km' : '' }}
              </td>
            </tr>

            <tr>
              <td style="width: 85px; text-align: left">结账收银员</td>
              <td style="width: 165px; text-align: left">
                {{ cashier }}
              </td>
              <td style="width: 90px; text-align: left">结账时间</td>
              <td style="text-align: left">
                {{ billMessage.checkDate }}
              </td>
              <td style="width: 90px; text-align: left">提车时间</td>
              <td style="text-align: left">
                {{ billMessage.takeTime || billMessage.checkDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="print-template__box__border"
        v-if="serviceList && serviceList.length"
      >
        <h3 class="print-template__title">服务明细</h3>
        <table class="print-template__box__border__table">
          <thead>
            <tr>
              <th style="width: 32px; text-align: center">序号</th>
              <th style="min-width: 130px; text-align: center">服务名称</th>
              <!-- <th style="width: 80px; text-align: center">服务编码</th> -->
              <!-- <th style="min-width: 50px; text-align: center">技师</th> -->
              <th style="width: 65px; text-align: center">工时</th>
              <th style="width: 70px; text-align: center">单价</th>
              <th style="width: 40px; text-align: center">折扣</th>
              <th style="width: 75px; text-align: center">小计</th>
              <!-- <th style="width: 149px; text-align: center">备注</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in serviceList" :key="index">
              <td style="text-align: center">
                {{ index + 1 }}
              </td>
              <td style="text-align: left">{{ item.name }}</td>
              <td style="text-align: right">
                {{ item.num }}
              </td>
              <td style="text-align: right">
                {{ item.price | toThousands }}
              </td>
              <td style="text-align: right">
                {{ item.discount ? item.discount : '无' }}
              </td>
              <td style="text-align: right">
                {{ item.total | toThousands }}
              </td>
            </tr>
            <tr style="text-align: right">
              <td :colspan="6" style="padding: 4px">
                <b
                  style="font-weight: 600"
                  class="print-template__box__border__table__total text-right"
                  >折前服务费合计：{{
                    beforeTotalServicePrice | toThousands
                  }}</b
                >
                <b
                  style="font-weight: 600"
                  class="print-template__box__border__table__total text-right"
                  >折后服务费合计：{{ afterTotalServicePrice | toThousands }}</b
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="print-template__box__border"
        v-if="goodsList && goodsList.length"
      >
        <h3 class="print-template__title" ref="materialTitle">材料明细</h3>
        <table class="print-template__box__border__table" ref="materialTable">
          <thead>
            <tr ref="goodTr">
              <th style="width: 32px; text-align: center">序号</th>
              <th style="min-width: 176px; text-align: center">商品名称</th>
              <th style="width: 75px; text-align: center">数量</th>
              <th style="width: 70px; text-align: center">单价</th>
              <th style="width: 40px; text-align: center">折扣</th>
              <th style="width: 75px; text-align: center">小计</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in goodsList" :key="index">
              <td style="text-align: center">
                {{ index + 1 }}
              </td>
              <td style="text-align: left">{{ item.name }}</td>
              <td style="text-align: right">{{ item.num }}</td>
              <td style="text-align: right">
                {{ item.price | toThousands }}
              </td>
              <td style="text-align: right">
                {{ item.discount ? item.discount : '无' }}
              </td>
              <td style="text-align: right">
                {{ item.total | toThousands }}
              </td>
            </tr>
            <tr style="text-align: right">
              <td :colspan="6" style="padding: 4px">
                <b
                  style="font-weight: 600"
                  class="print-template__box__border__table__total"
                  >折前材料费合计：{{ beforeTotalGoodsPrice | toThousands }}</b
                >
                <b
                  style="font-weight: 600"
                  class="print-template__box__border__table__total"
                  >折后材料费合计：{{ afterTotalGoodsPrice | toThousands }}</b
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="print-template__common">
        <div class="print-template__box__border print-template__common__column">
          <h3 class="print-template__title" ref="receivableTitle">应收明细</h3>
          <table
            class="print-template__box__border__table"
            ref="receivableTable"
          >
            <thead>
              <tr>
                <th style="width: 32px; text-align: center">序号</th>
                <th style="min-width: 180px; text-align: center">名称</th>
                <th style="width: 74px; text-align: center">金额</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align: center">1</td>
                <td style="text-align: left">工时费</td>
                <td style="text-align: right">
                  {{ serviceTotalPrice | toThousands }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center">2</td>
                <td style="text-align: left">材料费</td>
                <td style="text-align: right">
                  {{ goodsTotalPrice | toThousands }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center">3</td>
                <td style="text-align: left">优惠金额</td>
                <td style="text-align: right">
                  -{{ discountAmount | toThousands }}
                </td>
              </tr>
              <tr>
                <td :colspan="3" style="text-align: right; font-weight: 600">
                  应收合计(大写): {{ needPay | toThousands }} ({{
                    needPay | amountToChinese
                  }})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="print-template__box__border"
          style="border-bottom: none; width: 45%"
        >
          <h3 class="print-template__title" ref="chargeTitle">收费结算</h3>

          <table class="print-template__box__border__table" ref="chargeTable">
            <tbody>
              <tr>
                <th style="width: 32px; text-align: center">序号</th>
                <th style="min-width: 180px; text-align: center">支付方式</th>
                <th style="text-align: center; width: 74px">金额</th>
              </tr>
              <tr v-for="(item, index) in paidList" :key="index">
                <td style="text-align: center">{{ index + 1 }}</td>
                <td style="text-align: left">{{ item.payType }}</td>
                <td style="text-align: right">
                  {{ item.money | toThousands }}
                </td>
              </tr>
              <tr>
                <td :colspan="3" style="text-align: right; font-weight: 600">
                  实收合计(大写): {{ paidTotal | toThousands }} ({{
                    paidTotal | amountToChinese
                  }})
                </td>
              </tr>
              <tr>
                <td :colspan="3" style="text-align: right; font-weight: 600">
                  欠收合计(大写): {{ needPaidTotal | toThousands }} ({{
                    needPaidTotal | amountToChinese
                  }})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="print-template__signature" ref="signature">
        <b class="print-template__title">服务顾问签名：</b>
        <b class="print-template__title">客户签名：</b>
      </div>
      <div class="print-template__contact">
        <div class="print-template__contact__item fontsize" style="width: 60%">
          联系地址：{{ billMessage.companyAddress }}
        </div>
        <div class="print-template__contact__item fontsize">
          联系电话：{{ billMessage.telephone }}
          <span style="margin-left: 30px">{{
            billMessage.alternativeTelephone
          }}</span>
        </div>
        <!-- <div
          ref="bankName"
          class="print-template__contact__item fontsize"
          style="width: 61%"
        >
          开户行：{{ billMessage.bankName }}
        </div>
        <div
          ref="bankAccount"
          class="print-template__contact__item fontsize"
          v-if="configList.indexOf(21) !== -1"
        >
          银行账号：{{ billMessage.bankAccount }}
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { Print } from '@/utils/print';
export default {
  name: 'settlementPrintTemplate',
  data() {
    return {
      billType: 0,
      printTime: new Date().Format('yyyy-MM-dd hh:mm'),
      config: {
        title: '出厂结算单',
        leftImagePath: require('@/assets/slogan.png'),
      },
      // 单据信息
      billMessage: {},
      // 车辆信息
      carInfo: {},
      // 客户信息
      payerInfo: {},
      // 理赔信息
      carBillInsuranceInfo: {},
      // 索赔信息
      carBillUndertakeInfo: {},
      // 服务项
      serviceList: [],
      beforeTotalServicePrice: '',
      afterTotalServicePrice: '',
      // 商品
      goodsList: [],
      beforeTotalGoodsPrice: '',
      afterTotalGoodsPrice: '',

      serviceTotalPrice: '',
      goodsTotalPrice: '',

      discountAmount: '',

      needPay: '',
      // 结账收银员
      cashier: '',
      // 支付明细
      paidList: [],
      paidTotal: '',
      needPaidTotal: '',
    };
  },
  created() {},
  methods: {
    doPrint(data) {
      this.printTime = new Date().Format('yyyy-MM-dd hh:mm');
      Object.keys(data).forEach((key) => {
        // this[key] = data[key];
        this.$set(this, key, data[key]);
      });

      this.$nextTick().then(() => {
        Print(this.$refs.printDom);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.print-template {
  width: 800px;
  // box-shadow: 0px 0px 10px 0px rgba(71, 71, 71, 0.1);
  padding: 12px;
  background: white;

  .fontsize {
    font-size: 14px;
  }

  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

  &__title {
    justify-content: center;
    margin: 7px 0 1px;
    font-weight: 600;
  }

  &__header {
    width: 100%;
    height: 75px;
  }

  &__user {
    margin-bottom: 4px;
  }

  &__user {
    justify-content: space-between;
  }

  &__date,
  &__user,
  &__main__ul,
  &__signature,
  &__contact,
  &__common,
  &__title {
    display: flex;
  }

  &__header {
    position: relative;

    &__title {
      text-align: center;
      margin: 0 150px;
      font-size: 26px;
      font-weight: normal;
    }

    &__lt-img,
    &__rt-img {
      min-width: 75px;
      max-width: 300px;
      height: 75px;
      position: absolute;
      top: 2px;
    }

    &__lt-img {
      left: 0;
    }

    &__rt-img {
      right: 0;
    }
  }

  &__header__lt-img > img,
  &__header__rt-img > img {
    min-width: 75px;
    max-width: 300px;
    height: 75px;
  }

  &__date {
    justify-content: flex-end;
    margin: 8px 0 7px 0;
  }

  &__box__border {
    &__table {
      width: 100%;
      font-weight: normal;
      &:last-child {
        border-bottom: 1px solid #000 !important;
      }
      th,
      td {
        vertical-align: middle;
        padding: 2px;
        border-top: 1px solid #000 !important;
        border-left: 1px solid #000 !important;
        word-break: break-all;
        font-weight: normal;
      }
      th:last-child,
      td:last-child {
        border-right: 1px solid #000 !important;
      }
      &__total {
        width: 200px;
        display: inline-block;
      }
    }
  }

  &__common {
    justify-content: space-between;
    &__column {
      width: 45%;
    }
  }

  &__line {
    border-bottom: 1px dashed #000;
  }

  &__signature {
    justify-content: space-between;
  }

  &__contact {
    margin-top: 15px;
    flex-wrap: wrap;
  }

  &__signature {
    padding: 20px 0;
    border-bottom: 1px solid #000 !important;
    b {
      justify-content: initial;
      padding-right: 280px;
    }
  }

  &__contact__item {
    width: 39%;
  }
}
</style>
