<template>
  <el-drawer
    title="选择门店"
    :visible="storeDrawerVisibility"
    size="50%"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div>
      <div class="screen conten_body">
        <p style="margin-right: 15px">车店信息:</p>
        <el-select
          style="width: 30%"
          size="small"
          v-model="storeId"
          @change="selectStore"
          @clear="deleteStore"
          filterable
          clearable
          remote
          placeholder="搜索车店名称、编号"
          :remote-method="searchStore"
          :loading="searchStoreLoading"
        >
          <el-option
            v-for="item in storeList"
            :key="item.companyId"
            :label="item.companyName"
            :value="item.companyId"
          >
            <span style="float: left; color: #8492a6; font-size: 13px">{{
              item.companyId
            }}</span>
            <span style="float: right">{{ item.companyName }}</span>
          </el-option>
        </el-select>
      </div>
      <div style="display: flex; margin-top: 21px" class="conten_body">
        <el-table
          ref="multipleTable"
          :data="tableData"
          height="calc(100vh - 300px)"
          border
          style="width: 61%"
          class="ant-table-body"
          @selection-change="handleSelectionChange"
          v-el-table-infinite-scroll="loadTableData"
        >
          <el-table-column type="selection" width="40"> </el-table-column>
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column prop="companyId" label="车店编号" width="100">
          </el-table-column>
          <el-table-column prop="companyName" label="车店名称" width="100">
          </el-table-column>
          <el-table-column prop="chainCompanyType" label="连锁类型">
            <template slot-scope="scope">
              <span>{{
                scope.row.chainCompanyType | dict('chainCompanyType')
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="chioce_store">
          <p class="title">门店管辖组</p>
          <el-tree
            :data="treeData"
            show-checkbox
            node-key="id"
            :default-expanded-keys="[2, 3]"
            :default-checked-keys="[5]"
            :props="{
              children: 'children',
              label: 'label',
            }"
          >
          </el-tree>
        </div>
      </div>
      <div class="footer">
        <p>
          <i class="iconfont grow-icon_warn_32 orange"></i>
          <span
            >共选择门店{{ selectedStores.length }}，本次新增选择{{
              addLength
            }}</span
          >
        </p>
        <el-button type="primary" size="small" @click="confirmSelect"
          >确 定</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { searchStore } from '@/api/customer/store';
import { selListTwo } from '@/api/customer/sysmanent';
import elTableInfiniteScroll from 'el-table-infinite-scroll'; //引入el-table无限滚动指令
import { mapState } from 'vuex';
export default {
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll,
  },
  props: {
    storeDrawerVisibility: { type: Boolean, default: false },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      storeId: '',
      tableData: [],
      selectedStores: [], //选中的门店信息
      hasMore: true,
      treeData: [
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1',
                },
                {
                  id: 10,
                  label: '三级 1-1-2',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1',
            },
            {
              id: 6,
              label: '二级 2-2',
            },
          ],
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1',
            },
            {
              id: 8,
              label: '二级 3-2',
            },
          ],
        },
      ],
      searchStoreLoading: false,
      storeList: [],

      current: 0,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    addLength() {
      var l = this.selectedStores.length - this.stores.length;
      return l > 0 ? l : 0;
    },
  },
  created() {
    // this.loadTableData();
  },
  methods: {
    /**
     * table选中事件
     */
    handleSelectionChange(val) {
      this.selectedStores = val;
    },
    /**
     * 同步选中状态
     */
    syncSelectedStatus() {
      this.$nextTick().then(() => {
        if (this.stores?.length) {
          var ids = this.stores.map((item) => item.companyId);
          var shouldBeSelected = this.tableData.filter((item) =>
            ids.includes(item.companyId)
          );
          shouldBeSelected.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
        //  else {
        //   this.$refs.multipleTable.clearSelection();
        // }
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:storeDrawerVisibility', false);
    },
    /**
     * 确认选择
     */
    confirmSelect() {
      // 调用自定义事件，传入参数
      this.$emit('select', this.selectedStores);
      this.$emit('update:storeDrawerVisibility', false);
    },
    /**
     * 远程搜索门店列表
     */
    searchStore(keyWord) {
      if (keyWord) {
        this.searchStoreLoading = true;
        searchStore(keyWord, this.companyId)
          .then((res) => {
            this.storeList = res;
            // this.tableData = res;
          })
          .finally(() => {
            this.searchStoreLoading = false;
          });
      }
    },
    /**
     * 选择门店
     */
    selectStore(storeId) {
      if (storeId) {
        let store = this.storeList.find((item) => item.companyId === storeId);
        if (!this.tableData.find((item) => item.companyId == storeId)) {
          this.tableData.unshift(store);
        } else {
          store = this.tableData.find((item) => item.companyId == storeId);
        }
        this.$nextTick().then(() => {
          this.$refs.multipleTable.toggleRowSelection(store, true);
          this.storeId = '';
        });
      }
    },
    /**
     * 删除门店
     */
    deleteStore() {
      // if (this.storeId) this.storeId = '';
    },
    /**
     * 获取数据
     */
    loadTableData() {
      if (!this.hasMore) return;
      var data = {
        currCompanyId: this.companyId,
        current: ++this.current,
        size: 20,
      };
      selListTwo(data).then((res) => {
        this.tableData.push(...res.data.records);
        this.tableData = this.$lodash.uniqBy(this.tableData, 'companyId');
        this.hasMore = res.data.total > this.current * 20; //判断如果总条数大于当前请求的总条数就实现分页加载数据
        this.syncSelectedStatus();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.conten_body {
  width: 90%;
  margin-left: 5%;
}
.screen {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 14px;
  padding-top: 10px;
}
.screen input {
  border: none;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  margin-left: 8px;
}
.chioce_store {
  width: 30%;
  height: calc(100vh - 300px);
  margin-left: 5%;
  border: 1px solid #e4e4e4;
}
.chioce_store .title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
}
.el-drawer {
  position: relative;
}
.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 109px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 60px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  box-shadow: -4px 0px 30px 0px rgb(0 0 0 / 10%);
}
.footer > p {
  margin-right: 30px;
}
.footer span {
  color: #999999;
  font-size: 14px;
  margin-left: 10px;
}
</style>
