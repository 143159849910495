<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  录入门店
                  <el-tooltip
                    effect="light"
                    content="商品的录入门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.companyIds"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodsName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品编码">
              <el-input
                v-model="form.goodsCode"
                placeholder="请输入商品编码"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                v-auth="633"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <template>
            <el-form-item label="是否启用">
              <el-select v-model="form.isActive" placeholder="请选择启用状态">
                <el-option
                  v-for="item in availableStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否共享">
              <el-select v-model="form.isShare" placeholder="请选择共享状态">
                <el-option
                  v-for="item in sharedStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否禁止采购">
              <el-select
                v-model="form.purchaseDisable"
                placeholder="请选择是否禁止采购"
              >
                <el-option
                  v-for="item in purchaseDisableEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品ID">
              <number-input
                v-model="form.goodsId"
                decimalDigits="0"
                :max="9999999999999999"
                placeholder="请输入完整的商品ID"
                clearable
              >
              </number-input>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 5px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="634"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <span
              v-auth="635"
              class="text_operation blue"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column
          width="140"
          label="录入门店"
          prop="company.companyName"
        >
        </el-table-column>
        <el-table-column width="180" label="商品ID">
          <template #default="{ row }">
            {{ row.goods.goodsId }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="商品名称">
          <template #default="{ row }">
            {{ row.goods.name }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="商品编码">
          <template #default="{ row }">
            {{ row.goods.goodsCode }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="商品标准名称">
          <template #default="{ row }">
            {{ row.goods.standardName }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="是否禁止采购">
          <template #default="{ row }">
            {{ row.disablePurchase }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="是否启用">
          <template #default="{ row }">
            {{ row.isActiveOption.desc }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="是否共享">
          <template #default="{ row }">
            {{ row.isSharedOption.desc }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="商品品牌">
          <template #default="{ row }">
            {{ row.goods.brandName }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="总库存"
          prop="allInventoryNum"
        ></el-table-column>
        <el-table-column width="180" label="单位">
          <template #default="{ row }">
            {{ row.goods.unit }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="售价">
          <template #default="{ row }">
            {{ row.goods.price }}
          </template>
        </el-table-column>
      </el-table>
      <editGoodsControlDialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :id="editId"
        :detail="editData"
        :inventoryGoodsControlModuleList="inventoryGoodsControlModuleList"
        :sharedStatusEnum="sharedStatusEnum"
        :availableStatusEnum="availableStatusEnum"
        @save="getData"
      ></editGoodsControlDialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import editGoodsControlDialog from './components/editGoodsControlDialog.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import {
  getGoodsControlList,
  getInventoryGoodsControlModuleList,
  inventoryGoodsControlExportUrl,
} from '@/api/stock/goodsControl';
import { downloadFile } from '@/utils/fileApi';
import { hasAuth } from '@/utils/permissions';

import { mapState } from 'vuex';

export default {
  name: 'goodsControlManage',
  components: {
    pagination,
    editGoodsControlDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      editId: '',
      editData: {},
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showEditDialog: false,
      exportUrl: inventoryGoodsControlExportUrl,
      startPage: 1,
      endPage: 1,
      form: {
        isActive: '',
        isShare: '',
        purchaseDisable: '',
      },
      // 展示更多筛选
      showMore: false,
      listData: [],
      inventoryGoodsControlModuleList: [],
      purchaseDisableEnum: [],
      sharedStatusEnum: [],
      availableStatusEnum: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.purchaseDisableEnum.push({
        value: '',
        desc: '全部',
      });
      this.purchaseDisableEnum.push({
        value: true,
        desc: '禁止',
      });
      this.purchaseDisableEnum.push({
        value: false,
        desc: '不禁止',
      });
      this.form.companyIds = [this.companyId];
      var res = hasAuth(633);
      if (res) {
        this.getData();
      }
      this.getInventoryGoodsControlModule();
    },
    getInventoryGoodsControlModule() {
      getInventoryGoodsControlModuleList().then((res) => {
        this.inventoryGoodsControlModuleList = res.inventoryGoodsControlModule;
        this.sharedStatusEnum = res.sharedStatusEnum;
        this.availableStatusEnum = res.availableStatusEnum;
        // 对2个进行排序
        this.sharedStatusEnum.forEach((item) => {
          if (item.value === -1) {
            item.value = '';
          }
        });
        this.availableStatusEnum.forEach((item) => {
          if (item.value === -1) {
            item.value = '';
          }
        });
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign({}, this.form, this.pageOption);
      getGoodsControlList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
        this.listData.forEach((item) => {
          item.disablePurchase = '不禁止';
          if (
            item.goodsControlSetting &&
            item.goodsControlSetting.disableModule &&
            item.goodsControlSetting.disableModule.includes(1)
          ) {
            item.disablePurchase = '禁止';
          }
        });
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign(this.form, {}),
      });
    },
    /**
     * 编辑
     */
    handleEdit(row) {
      this.editId = row.goods.goodsId;
      this.editData = row;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        isActive: '',
        isShare: '',
        companyIds: [this.companyId],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
