// 金额处理部分参考 https://juejin.cn/post/6844904004154032136

/**
 * 千位符 toThousands
 * num  字符串或者数字 *必传
 * fixed 保留位数 *非必填
 * check 是否校验修改数据 *非必填
 * dropZero 是否舍弃小数点后面的0 *非必填
 */
export function toThousands(num, fixed = 2, check = true, dropZero = true) {
  if (num === undefined || num === null || num === '') {
    return '';
  }
  try {
    let text = '';
    if (check) {
      text = num ? parseFloat(String(num).replace(/,/g, '')) : 0.0;
      if (isNaN(text)) {
        return '';
        // throw new Error(`数据异常:${num}`);
      }
      text =
        Number(fixed) > 0
          ? text.toFixed(Number(fixed))
          : Number(fixed) === 0
          ? Math.round(text).toString()
          : text.toString();
    } else {
      text = String(num);
    }
    let result = text.replace(
      text.indexOf('.') < 0 ? /(\d)(?=(\d{3})+$)/g : /(\d)(?=(\d{3})+\.)/g,
      function ($0, $1) {
        return $1 + ',';
      }
    );
    // 舍弃小数点后面的0
    if (dropZero && Number(result.split('.')[1]) === 0) {
      result = result.split('.')[0];
    }
    return result;
  } catch (e) {
    console.error(e || `千位符转换失败:${num}`);
    return num;
  }
}

/**
 * 数字转中文
 */
export function amountToChinese(num) {
  try {
    if (!/^(0|-*[1-9]\d*)(\.\d+)?$/.test(num)) return '';
    // throw new Error(`数据异常:${num}`);
    const fuhao = num.toString().indexOf('-') === 0 ? '负' : '';
    let unit = '仟佰拾万仟佰拾亿仟佰拾万仟佰拾元角分';
    let str = '';
    let n = num + '00';
    if (fuhao === '负') {
      n = n.substring(1, n.length);
    }
    if (String(num).replace('-', '') === '0') {
      return '零元';
    }
    const p = n.indexOf('.');
    if (p >= 0) {
      n = n.substring(0, p) + n.substr(p + 1, 2);
    }
    unit = unit.substr(unit.length - n.length);
    for (const i in n) {
      str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
    }
    return (
      fuhao +
      str
        .replace(/零(仟|佰|拾|角)/g, '零')
        .replace(/(零)+/g, '零')
        .replace(/零(万|亿|元)/g, '$1')
        .replace(/(亿)万|壹(拾)/g, '$1$2')
        .replace(/^元零?|零分/g, '')
        .replace(/元$/g, '元整')
    );
  } catch (e) {
    console.error(e || `转换中文失败:${num}`);
    return num;
  }
}

/**
 * 数字类型过滤器
 * val  值
 * obj 过滤方式
 */
export function setIntFilter(val, obj = {}) {
  try {
    const initData = Object.assign(
      {
        min: -999999999999, // 最小值
        max: 99999999, // 最大值 到千亿
        fixed: -1, // 保留位数  -1不限制 0>=其他保留的位数
        default: '', // 默认值
        fixedSup: false, // 保留小数位数大于0时开启 小数位数不足是否补 0
        toThousands: false, // 是否转换千位符
        amountToChinese: false, //  转中文
        prefix: '', // 内容添加前缀
        suffix: '', // 内容添加后缀
        empty: '', // 数据为空时显示
        simplify: false, // 数字简化展示
        simplifyData: {
          0: { suffix: '' },
          10000: { suffix: '万' },
          100000000: { suffix: '亿' },
        },
        math: '', // round:保留小数四舍五入    ceil:保留小数向上取值   floor:保留小数向下取值 *使用时候请注意ceil与floor的使用与特性 Math.ceil(8.540*Math.pow(10,2))/Math.pow(10,2)
      },
      obj
    );
    let simplifySuffix = '';
    let value = !isNaN(parseFloat(String(val).replace(/,/g, '')))
      ? parseFloat(String(val).replace(/,/g, ''))
      : !isNaN(parseFloat(String(initData.default).replace(/,/g, '')))
      ? parseFloat(String(initData.default).replace(/,/g, ''))
      : '';
    const roundFun = function (value, n, type) {
      if (type === 'ceil') {
        const returnValue =
          Math.ceil(value * Math.pow(10, n)) / Math.pow(10, n);
        return initData.fixedSup && n ? returnValue.toFixed(n) : returnValue;
      } else if (type === 'floor') {
        const returnValue =
          Math.floor(value * Math.pow(10, n)) / Math.pow(10, n);
        return initData.fixedSup && n ? returnValue.toFixed(n) : returnValue;
      } else {
        const returnValue =
          Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
        return initData.fixedSup && n ? returnValue.toFixed(n) : returnValue;
      }
    };
    if (typeof value === 'number') {
      /**
       * 初始值过滤判断
       */
      if (initData.min > value) {
        value = initData.min;
      } else if (initData.max < value) {
        value = initData.max;
      }
      /**
       * 简化展示
       */
      if (initData.simplify && initData.simplifyData) {
        let item = {};
        for (const num in initData.simplifyData) {
          if (num && value >= num) {
            item = initData.simplifyData[num];
            item.num = num;
          }
        }
        simplifySuffix = item.suffix || '';
        value = value / item.num;
      }
      /**
       * 保留位数转换
       */
      if (initData.fixed >= 0) {
        value = roundFun(value, initData.fixed, initData.math);
      }
      /**
       * 简化展示后缀
       */
      if (simplifySuffix) {
        value += simplifySuffix;
      }
      /**
       * 转换千位符 else 转中文
       */
      if (initData.toThousands && String(value) && !initData.simplify) {
        value = toThousands(value, initData.fixed, false);
      } else if (
        initData.amountToChinese &&
        String(value) &&
        !initData.simplify
      ) {
        value = amountToChinese(value);
      }
      /**
       * 新增前缀、后缀
       */
      if (String(value) && (initData.prefix || initData.suffix)) {
        value = `${initData.prefix}${value}${initData.suffix}`;
      } else if (initData.empty && !String(value)) {
        value = initData.empty;
      }
    }
    return value || initData.empty;
  } catch (e) {
    console.error(e || `数字类型过滤失败:${val}`);
    return val;
  }
}

/**
 * 数字转成中文
 */
export function numToChinese(num) {
  var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  var arr2 = [
    '',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
  ]; //可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return '零';
  }
  var english = num.toString().split('');
  var result = '';
  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i; //倒序排列设值
    result = arr2[i] + result;
    var arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  //将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
  //合并中间多个零为一个零
  result = result.replace(/零+/g, '零');
  //将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
  //将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿');
  //移除末尾的零
  result = result.replace(/零+$/, '');
  //将【零一十】换成【零十】
  //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  //将【一十】换成【十】
  result = result.replace(/^一十/g, '十');
  return result;
}
