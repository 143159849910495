<template>
  <el-dialog
    title="修改密码"
    :visible="visibility"
    width="400px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <el-form-item v-if="needOldPassword" label="旧密码" prop="oldPassword">
        <el-input
          type="password"
          placeholder="请输入旧密码"
          v-model="editForm.oldPassword"
          maxlength="16"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          type="password"
          placeholder="请输入6-16位新密码"
          v-model="editForm.newPassword"
          @input="filterSpace($event, 'newPassword')"
          maxlength="16"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="confirmNewPassword">
        <el-input
          type="password"
          placeholder="请再次输入6-16位新密码"
          v-model="editForm.confirmNewPassword"
          @input="filterSpace($event, 'confirmNewPassword')"
          maxlength="16"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { changePassword as changePasswordApi, offline } from '@/api/system';
import { oldServerUrl } from '@/config/compatibilityOlderSystems';
import axios from 'axios';

export default {
  name: 'changePasswordDialog',
  props: {
    visibility: Boolean,
    phoneNumber: [Number, String],
    needOldPassword: Boolean,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码！'));
      } else {
        // 校验密码是否符合规则
        if (value.length < 6) {
          callback(new Error('密码长度不能小于6位！'));
        }
        if (this.editForm.confirmNewPassword !== '') {
          this.$refs.form.validateField('confirmNewPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码！'));
      } else if (value !== this.editForm.newPassword) {
        callback(new Error('两次输入密码不一致！'));
      } else {
        callback();
      }
    };
    return {
      // 表单信息
      editForm: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      // 表单规则
      rules: {
        oldPassword: [],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        confirmNewPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    if (this.needOldPassword) {
      this.rules.oldPassword.push({
        required: true,
        message: '请输入旧密码！',
        trigger: 'blur',
      });
    }
  },
  methods: {
    /**
     * 过滤空格
     */
    filterSpace(e, type) {
      if (e.includes(' ')) {
        this.editForm[type] = e.replace(/\s+/g, '');
        this.$message.warning('密码不允许输入空格!');
      }
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var phone = localStorage.getItem('phone');
          var body = {
            phoneNumber: this.phoneNumber || phone,
            newPassword: this.editForm.newPassword,
            confirmNewPassword: this.editForm.confirmNewPassword,
          };
          if (this.needOldPassword) {
            body.oldPassword = this.editForm.oldPassword;
          }
          console.log(body);
          changePasswordApi(body).then((res) => {
            this.$message.success('修改成功!');
            axios
              .get(`${oldServerUrl}/user/extrusion?phone=${this.phoneNumber}`, {
                noHandleError: true,
                noShowWaiting: true,
              })
              .then((res) => {
                console.log(res);
                offline({ phone: this.phoneNumber }).then((val) => {
                  console.log(val);
                  this.$store.dispatch('logout');
                  this.handleClose();
                });
              });
            // this.$emit('save');
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
