<template>
  <base-container paddingTop>
    <el-tabs class="tabs" v-model="activeName">
      <el-tab-pane label="车辆信息" name="first">
        <el-descriptions
          :column="3"
          border
          labelClassName="table-label"
          :contentStyle="{ verticalAlign: 'middle' }"
          style="margin-bottom: 60px"
        >
          <el-descriptions-item label="车牌号">{{
            detailData.carNumber
          }}</el-descriptions-item>

          <el-descriptions-item label="车型">
            {{ detailData.type }}
          </el-descriptions-item>
          <el-descriptions-item label="车架号">
            {{ detailData.uniqueId }}
            <span
              v-if="detailData.uniqueId"
              class="text_operation blue"
              @click="showCarConfigDialog = true"
              >配置信息</span
            >
          </el-descriptions-item>
          <el-descriptions-item label="车辆所有人">
            {{ detailData.vehicleOwner }}
          </el-descriptions-item>
          <el-descriptions-item label="签约客户">
            <div
              class="client"
              v-if="$lodash.get(detailData, 'clientBriefMessage.id', '')"
            >
              <div class="client-info">
                <div class="flex-x-between flex-y-center">
                  <span class="blue pointer" @click="goClientDetail">{{
                    $lodash.get(detailData, 'clientBriefMessage.clientName', '')
                  }}</span>
                  <div
                    style="
                      position: relative;
                      min-width: 20px;
                      min-height: 16px;
                      line-height: 16px;
                    "
                  >
                    <span>{{
                      $lodash.get(
                        detailData,
                        'clientBriefMessage.settlementType',
                        ''
                      )
                        ? '月结'
                        : '现结'
                    }}</span>
                    <i
                      class="iconfont icon_grade"
                      :class="
                        levelIconsOther[
                          $lodash.get(
                            detailData,
                            'clientBriefMessage.clientLevel',
                            ''
                          )
                        ]
                      "
                      :title="
                        [
                          $lodash.get(
                            detailData,
                            'clientBriefMessage.clientLevel',
                            ''
                          ),
                        ] || ''
                      "
                    ></i>
                    <span
                      v-if="
                        $lodash.get(
                          detailData,
                          'clientBriefMessage.hasClientAgreement',
                          ''
                        )
                      "
                    >
                      协议客户
                      <span
                        v-if="
                          $lodash.get(
                            detailData,
                            'clientBriefMessage.beContract',
                            ''
                          )
                        "
                        >（包干）</span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="color999">车辆暂未绑定客户</div>
          </el-descriptions-item>
          <el-descriptions-item label="注册时间"
            >{{ detailData.registerDate }}
          </el-descriptions-item>
          <el-descriptions-item label="在租状态">
            {{ detailData.underLeaseState | dict('underLeaseState') }}
          </el-descriptions-item>
          <el-descriptions-item label="真实续航">
            {{ detailData.trueEndurance }}
            <span class="color666" v-if="detailData.newVehicleMileage">
              （新车续航:{{ detailData.newVehicleMileage }}）
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="使用性质">
            {{ detailData.annualSurveyCarType | dict('annualSurveyCarType') }}
          </el-descriptions-item>
          <el-descriptions-item label="动力类型">
            {{ detailData.fuelTypeValue }}
          </el-descriptions-item>
          <el-descriptions-item label="电机号/发动机号" :span="2">
            {{ detailData.engineNumber }}
          </el-descriptions-item>
          <el-descriptions-item label="备注" :span="3">
            <p class="pre-wrap">{{ detailData.remark }}</p>
          </el-descriptions-item>
          <el-descriptions-item :span="3" label="车辆图片">
            <div
              class="flex"
              v-if="$lodash.get(detailData, 'picCarLists.length', false)"
            >
              <upload-file
                v-for="(item, index) in detailData.picCarLists"
                :key="index"
                disabled
                :title="item.picTypeName"
                :value="item.picUrlName"
                :imagesForPreview="
                  detailData.picCarLists.map((img) => img.picUrlName)
                "
              ></upload-file>
            </div>
            <div v-else class="color999">无附件图片</div>
          </el-descriptions-item>
        </el-descriptions>

        <div class="box">
          <div class="title">在保状况</div>
          <el-descriptions
            :column="5"
            direction="vertical"
            border
            labelClassName="table-label"
            :contentStyle="{ verticalAlign: 'middle', padding: 0 }"
          >
            <el-descriptions-item label="整车质保">
              <div class="flex-y-center">
                <span class="cell-left">
                  <span v-if="detailData.hasVehicleWarranty"> 在保 </span>
                  <span v-else-if="detailData.hasVehicleWarranty === 0"
                    >过保</span
                  >
                  <span v-else>未知</span>
                </span>
                <span class="cell-right">{{
                  detailData.vehicleWarrantyContent
                }}</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="三电质保">
              <div class="flex-y-center">
                <span class="cell-left">
                  <span v-if="detailData.hasThreePowerWarranty"> 在保 </span>
                  <span v-else-if="detailData.hasThreePowerWarranty === 0"
                    >过保</span
                  >
                  <span v-else>未知</span>
                </span>
                <span class="cell-right">{{
                  detailData.threePowerWarrantyContent
                }}</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="动力电池质保">
              <div class="flex-y-center">
                <span class="cell-left">
                  <span v-if="detailData.hasPowerBatteryWarranty"> 在保 </span>
                  <span v-else-if="detailData.hasPowerBatteryWarranty === 0"
                    >过保</span
                  >
                  <span v-else>未知</span>
                </span>
                <span class="cell-right">{{
                  detailData.powerBatteryWarrantyContent
                }}</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="电芯质保">
              <div class="flex-y-center">
                <span class="cell-left">
                  <span v-if="detailData.hasBatteryWarranty"> 在保 </span>
                  <span v-else-if="detailData.hasBatteryWarranty === 0"
                    >过保</span
                  >
                  <span v-else>未知</span>
                </span>
                <span class="cell-right">{{
                  detailData.batteryWarrantyContent
                }}</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="易损件质保">
              <div class="flex-y-center">
                <span class="cell-left">
                  <span v-if="detailData.hasWarrantyWearingParts"> 在保 </span>
                  <span v-else-if="detailData.hasWarrantyWearingParts === 0"
                    >过保</span
                  >
                  <span v-else>未知</span>
                </span>
                <span class="cell-right">{{
                  detailData.warrantyWearingPartsContent
                }}</span>
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <div class="title">消费情况</div>
          <el-descriptions
            :column="3"
            direction="vertical"
            border
            labelClassName="table-label"
            :contentStyle="{ verticalAlign: 'middle' }"
          >
            <el-descriptions-item label="最后到店时间">
              {{ detailData.lastIntoStoreDate }}
            </el-descriptions-item>
            <el-descriptions-item label="最后到店里程">
              {{ detailData.lastIntoStoreMileage }}
            </el-descriptions-item>
            <el-descriptions-item label="到店详情">
              {{ detailData.intoStoreDetails }}
            </el-descriptions-item>
          </el-descriptions>

          <div class="title">运营情况</div>
          <el-descriptions
            :column="3"
            direction="vertical"
            border
            labelClassName="table-label"
            :contentStyle="{ verticalAlign: 'middle' }"
          >
            <el-descriptions-item label="车辆来源">
              {{ detailData.vehicleSource }}
            </el-descriptions-item>
            <el-descriptions-item label="发车模式">
              {{ detailData.departureMode }}
            </el-descriptions-item>
            <el-descriptions-item label="车辆状态">
              {{ detailData.state | dict('carState') }}
            </el-descriptions-item>
          </el-descriptions>

          <div class="title">保险信息</div>
          <el-descriptions
            :column="3"
            border
            labelClassName="table-label"
            :contentStyle="{ verticalAlign: 'middle' }"
            style="margin-bottom: 60px"
          >
            <el-descriptions-item label="车损险">
              {{ detailData.insuranceCompanyLabel }}
              <span v-if="detailData.insuranceValidDate"
                >/ {{ detailData.insuranceValidDate }}起保</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="三者险">
              {{ detailData.thirdPartyInsuranceCompanyName }}
              <span v-if="detailData.thirdPartyInsuranceValidDate"
                >/ {{ detailData.thirdPartyInsuranceValidDate }}起保</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="座位险">
              {{ detailData.seatInsuranceCompanyName }}
              <span v-if="detailData.seatInsuranceValidDate"
                >/ {{ detailData.seatInsuranceValidDate }}起保</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="交强险">
              {{ detailData.tcInsuranceCompanyLabel }}
              <span v-if="detailData.tcInsuranceValidDate"
                >/ {{ detailData.tcInsuranceValidDate }}起保</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="承运险">
              {{ detailData.vehicleInsuranceCompanyLabel }}
              <span v-if="detailData.vehicleInsuranceValidDate"
                >/ {{ detailData.vehicleInsuranceValidDate }}起保</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="驾乘险">
              {{ detailData.drivingInsuranceCompanyName }}
              <span v-if="detailData.drivingInsuranceValidDate"
                >/ {{ detailData.drivingInsuranceValidDate }}起保</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="格悦出单">
              <p v-if="detailData.geyueIssuer || detailData.geyueIssuer == 0">
                {{ detailData.geyueIssuer == 0 ? '否' : '是' }}
              </p>
            </el-descriptions-item>
            <el-descriptions-item label="团车政策">
              <p
                v-if="
                  detailData.groupCarPolicy || detailData.groupCarPolicy == 0
                "
              >
                {{ detailData.groupCarPolicy == 0 ? '否' : '是' }}
              </p>
            </el-descriptions-item>
            <el-descriptions-item label="团车代码">
              {{ detailData.groupCarCode }}
            </el-descriptions-item>
            <el-descriptions-item label="承保特殊要求" :span="3">
              <p class="pre-wrap">{{ detailData.insureSpecialAsk }}</p>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-tab-pane>
      <el-tab-pane v-auth="337" label="消费记录" name="second">
        <consumption-records :carId="$route.query.id"></consumption-records>
      </el-tab-pane>
      <el-tab-pane v-auth="667" label="修改日志" name="third">
        <carChangeLogRecords :carId="$route.query.id"></carChangeLogRecords>
      </el-tab-pane>
    </el-tabs>

    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <el-button v-auth="53" type="primary" @click="showAddCarDialog = true"
          >编辑车辆</el-button
        >
        <el-dropdown v-auth="336" size="small" trigger="click" placement="top">
          <el-button>
            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="width: 110px">
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="del"
              class="btn-dropdown"
              >删除</el-dropdown-item
            >
            <!-- <el-button>删除</el-button> -->
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>
    <add-car-dialog
      v-if="showAddCarDialog"
      :showAddCarDialog.sync="showAddCarDialog"
      :id="$route.query.id"
      @save="refresh"
    ></add-car-dialog>

    <!-- 配置详情 -->
    <car-config-detail-dialog
      v-if="showCarConfigDialog"
      :showCarConfigDialog.sync="showCarConfigDialog"
      :vehicleList="vehicleList"
      :uniqueId="detailData.uniqueId"
    ></car-config-detail-dialog>
  </base-container>
</template>

<script>
import AddCarDialog from '../addCar/addCarDialog.vue';
// import carInsurance from './carInsurance.vue';
import consumptionRecords from './consumptionRecords.vue';
import carChangeLogRecords from './carChangeLogRecords.vue';
// import InspectionCarRecords from './inspectionCarRecords.vue';
// import MaintenanceManual from './maintenanceManual.vue';
import UploadFile from '@/components/uploadFile.vue';
import carConfigDetailDialog from '@/views/customerManagement/car/addCar/carConfigDialog.vue';

import { getCarDetail, delCar } from '@/api/customer/car';

import { mapGetters, mapMutations } from 'vuex';

import { levelIconsOther } from '@/const/client';

// 权限
import { verifyAuth } from '@/utils/permissions';

export default {
  name: 'carDetail',
  components: {
    // carInsurance,
    consumptionRecords,
    // InspectionCarRecords,
    // MaintenanceManual,
    AddCarDialog,
    UploadFile,
    carConfigDetailDialog,
    carChangeLogRecords,
  },
  data() {
    return {
      showAddCarDialog: false,
      activeName: 'first',
      detailData: {},
      // 客户等级对应图标
      levelIconsOther,
      showCarConfigDialog: false,
      vehicleList: [],
    };
  },
  created() {
    this.getData();
    this.activeName = this.$route.params?.activeTabName || 'first';
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
    getData() {
      getCarDetail(this.$route.query.id).then((res) => {
        this.detailData = res;
        // 车架号
        // if (this.detailData.uniqueId?.length === 17) {
        //   this.getVehicleList();
        // }
      });
    },
    refresh() {
      this.getData();
    },
    del() {
      this.$confirm('确认要删除该车辆?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        delCar(this.$route.query.id).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            this.closeTag({ index: this.activeTagIndex });
          }
        });
      });
    },
    async goClientDetail() {
      await verifyAuth(107);
      this.$router.push({
        name: 'customerDetails',
        query: {
          id: this.detailData?.clientBriefMessage?.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-descriptions ::v-deep .table-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: $descriptions-label-bg;
}
.rows-tip {
  color: #999;
  line-height: 18px;
}
.box {
  .title {
    margin: 24px 0 8px 0;
    color: #303133;
    font-size: 16px;
    font-weight: 600;
  }
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  .el-dropdown {
    margin-left: 24px;
  }
  .el-dropdown-item ::v-deep .el-dropdown-menu {
    padding: 0;
  }
}
.client-info {
  // > div + div {
  //   margin-top: 10px;
  // }
  i {
    margin: 0 5px;
  }
  // .icon_person {
  //   color: $blue;
  // }
  // .icon_sex {
  //   color: $blue;
  // }
  .icon_grade {
    color: $blue;
    position: absolute;
    left: -30px;
  }
  // .grow-icon_girl {
  //   color: #f48080;
  // }
  // .icon_wechat {
  //   color: #999;
  // }
  // .icon_wechat_active {
  //   color: #00c800;
  // }
  // .icon_phone {
  //   color: #42bbb8;
  // }
  // .icon_vip {
  //   color: $orange;
  // }
  // .icon_sex,
  // .icon_wechat,
  // .icon_wechat_active,
  // .icon_vip,
  .icon_grade {
    font-size: 22px;
  }
}
.btn-dropdown:focus,
.btn-dropdown:hover {
  color: #606266;
  background: #f5f7fa;
  border-color: #f5f7fa;
}
.cell-left {
  width: 20%;
  min-height: 46px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-right: 1px solid #ebeef5;
  text-align: center;
  span {
    width: 100%;
  }
}
.cell-right {
  height: 100%;
  flex: 1;
  padding: 0 12px;
}
.tabs {
  margin-bottom: 30px;
}
.el-descriptions ::v-deep .el-descriptions-item__content {
  height: 46px;
  min-height: 46px;
}
</style>
