<template>
  <el-dialog
    :title="title"
    top="5vh"
    width="60%"
    :append-to-body="true"
    :visible="showAddCarDialog"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div style="height: 70vh; overflow: auto">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="110px"
        size="small"
      >
        <el-row>
          <el-col :span="10">
            <el-form-item label="车牌号" prop="carNumber">
              <el-input
                v-model="form.carNumber"
                clearable
                placeholder="请输入车牌号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="车架号" prop="uniqueId">
              <el-input
                v-model="form.uniqueId"
                clearable
                placeholder="请输入车架号"
                @input="inputUniqueId"
                maxlength="17"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div
              v-show="vehicleList.length"
              style="height: 32px; margin-left: 15px"
              class="flex-y-center"
            >
              <span
                class="text_operation blue"
                @click="showCarConfigDialog = true"
                >配置详情</span
              >
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="电机号/发动机号" prop="engineNumber">
              <el-input
                v-model="form.engineNumber"
                placeholder="请输入电机号/发动机号"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="车型" prop="type">
              <el-select
                v-model="currentCarModel"
                filterable
                remote
                clearable
                placeholder="搜索车型"
                :remote-method="searchModel"
                :loading="searchModelLoading"
                @clear="clearCarModel"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in carModelData"
                  :key="index"
                  :label="item.name"
                  :value="item"
                  @click.native="changeCarModel(item)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <div style="height: 32px; margin-left: 15px" class="flex-y-center">
              <span
                class="text_operation blue"
                @click="selectCarModelDialogVisibility = true"
                >选择车型</span
              >
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="车辆所有人" prop="vehicleOwner">
              <el-input
                v-model="form.vehicleOwner"
                placeholder="请输入车辆所有人"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="动力类型" prop="fuelType">
              <el-select
                v-model="form.fuelType"
                clearable
                placeholder="请选择动力类型"
              >
                <el-option
                  v-for="type in fuelTypes"
                  :key="type.value"
                  :value="type.value"
                  :label="type.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="使用性质" prop="annualSurveyCarType">
              <el-select
                v-model="form.annualSurveyCarType"
                placeholder="请选择使用性质"
                clearable
              >
                <el-option
                  v-for="type in annualVerificationTypes"
                  :key="type.value"
                  :value="type.value"
                  :label="type.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item
              style="margin-bottom: 0"
              label="注册时间"
              prop="registerDate"
            >
              <el-date-picker
                v-model="form.registerDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择注册时间"
                :picker-options="registerDatePickerOptions"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="真实续航" prop="trueEndurance">
              <number-input
                v-model="form.trueEndurance"
                placeholder="请输入真实续航"
                :enabledDecimals="false"
                :enabledZero="false"
                :max="99999"
              >
              </number-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="新车续航" prop="newVehicleMileage">
              <number-input
                v-model="form.newVehicleMileage"
                placeholder="请输入新车续航"
                :enabledDecimals="false"
                :enabledZero="false"
                :max="99999"
              >
              </number-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="在租状态" prop="underLeaseState">
              <el-select
                v-model="form.underLeaseState"
                placeholder="请选择在租状态"
                clearable
              >
                <el-option
                  v-for="type in underLeaseState"
                  :key="type.value"
                  :value="type.value"
                  :label="type.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="是否风控车" prop="isRiskControlCar">
              <el-switch
                v-model="form.isRiskControlCar"
                :active-text="form.isRiskControlCar ? '是' : '否'"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入车辆备注"
                v-model="form.remark"
                maxlength="100"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="车辆图片">
              <p class="img-note">
                （最多可上传12张图片，建议每张图片大小不超过1M）
              </p>
              <div class="car-imgs">
                <upload-file
                  v-model="form.runningLicenceImg"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                  title="行驶证"
                ></upload-file>
                <upload-file
                  v-model="form.vciGuaranteeSlipImg"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                  title="商业险保单"
                ></upload-file>
                <upload-file
                  v-model="form.tciGuaranteeSlipImg"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                  title="交强险保单"
                ></upload-file>
                <upload-file
                  v-model="form.viGuaranteeSlipImg"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                  title="承运险保单"
                ></upload-file>
                <upload-file
                  v-model="form.vehicleNameplateImg"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                  title="车辆铭牌"
                ></upload-file>
                <upload-file
                  v-model="form.batteryNameplateImg"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                  title="电池铭牌"
                ></upload-file>
                <upload-file-list
                  v-model="form.picOther"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                  :max="6"
                ></upload-file-list>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <div class="block-title">客户信息</div>
          </el-col>
        </el-row>

        <el-row>
          <el-col v-auth="63" :span="24">
            <el-form-item label="选择已有客户">
              <!-- <el-select
                style="width: 30%"
                v-model="form.clientId"
                @change="selectClient"
                @clear="deleteClient"
                filterable
                remote
                placeholder="请输入客户名称搜索"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
              <select-client-popover
                :clearable="false"
                :searchKeyWord="clientBriefMessage.clientName"
                @select="handleSelectClient"
              />

              <span v-auth="61" style="margin: 0 15px">或</span>
              <span
                v-auth="61"
                class="orange text_operation"
                @click="showAddCusterDialog = true"
                >新增客户</span
              >
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="23" :offset="1">
            <div class="client-list">
              <div class="client" v-show="form.clientId">
                <i
                  v-auth="64"
                  class="iconfont grow-icon_delete3 icon_delete pointer"
                  @click="deleteClient"
                ></i>
                <div class="client-info">
                  <div class="flex">
                    <i
                      class="iconfont icon_grade"
                      :class="levelIconsOther[clientBriefMessage.clientLevel]"
                      :title="[clientBriefMessage.clientLevel] || ''"
                    ></i>
                    <span style="margin-right: 5px">{{
                      clientBriefMessage.clientName
                    }}</span>

                    <!-- <i class="iconfont grow-icon_person icon_person"></i>
                  <span>{{ clientBriefMessage.clientName }}</span>
                  <i
                    class="iconfont icon_sex"
                    :class="{
                      'grow-icon_man':
                        clientBriefMessage.clientGenderValue == 0,
                      'grow-icon_girl':
                        clientBriefMessage.clientGenderValue == 1,
                    }"
                    :title="
                      ['男士', '女士'][clientBriefMessage.clientGenderValue] ||
                      ''
                    "
                  ></i>
                  <i
                    class="iconfont icon_grade"
                    :class="levelIconsOther[clientBriefMessage.clientLevel]"
                    :title="[clientBriefMessage.clientLevel] || ''"
                  ></i> -->
                    <!-- bug764隐藏 -->
                    <!-- <i
                    class="iconfont grow-icon_VX_16"
                    :class="{
                      icon_wechat: clientBriefMessage.isBindingWx == 0,
                      icon_wechat_active: clientBriefMessage.isBindingWx == 1,
                    }"
                  ></i> -->
                  </div>
                  <!-- <div class="flex">

                  <p v-if="clientBriefMessage.vipType == 4">
                    <i class="iconfont grow-icon_vip icon_vip"></i>
                    会员卡/协议客户（包干）
                  </p>
                  <p
                    v-if="['', undefined].includes(clientBriefMessage.vipType)"
                  >
                    <i class="iconfont grow-icon_vip_nonactivated"></i>
                    非会员卡
                  </p>
                </div> -->
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <div class="block-title">运营信息</div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="车辆来源" prop="vehicleSource">
              <el-input
                v-model="form.vehicleSource"
                placeholder="请输入车辆来源"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="发车模式" prop="departureMode">
              <el-input
                v-model="form.departureMode"
                placeholder="请输入发车模式"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!--
        <el-row>
          <el-col :span="10">
            <el-form-item
              style="margin-bottom: 0"
              label="车辆状态"
              prop="state"
            >
              <div class="flex-y">
                <el-select v-model="form.state" placeholder="请选择" clearable>
                  <el-option
                    v-for="type in state"
                    :key="type.value"
                    :value="type.value"
                    :label="type.label"
                  >
                  </el-option>
                </el-select>
                <p style="color: #909399; font-size: 12px">
                  内部车辆请选择白名单
                </p>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        -->
        <el-row>
          <el-col :span="24">
            <div class="block-title">保险信息</div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" :offset="2">
            <el-form-item label="车损险起保日" prop="insuranceValidDate">
              <el-date-picker
                v-model="form.insuranceValidDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择车损险起保日"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="承保公司" prop="insuranceCompany">
              <el-select
                v-model="form.insuranceCompany"
                filterable
                clearable
                remote
                placeholder="请输入承保公司名称查询"
                :remote-method="searchInsuranceCompany"
                :loading="searchInsuranceCompanyLoading"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.insuranceName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.insuranceNum
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" :offset="2">
            <el-form-item
              label="三者险起保日"
              prop="thirdPartyInsuranceValidDate"
            >
              <el-date-picker
                v-model="form.thirdPartyInsuranceValidDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择三者险起保日"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="承保公司" prop="thirdPartyInsuranceCompanyId">
              <el-select
                v-model="form.thirdPartyInsuranceCompanyId"
                filterable
                clearable
                remote
                placeholder="请输入承保公司名称查询"
                :remote-method="searchInsuranceCompany"
                :loading="searchInsuranceCompanyLoading"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.insuranceName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.insuranceNum
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" :offset="2">
            <el-form-item label="座位险起保日" prop="seatInsuranceValidDate">
              <el-date-picker
                v-model="form.seatInsuranceValidDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择座位险起保日"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="承保公司" prop="seatInsuranceCompanyId">
              <el-select
                v-model="form.seatInsuranceCompanyId"
                filterable
                clearable
                remote
                placeholder="请输入承保公司名称查询"
                :remote-method="searchInsuranceCompany"
                :loading="searchInsuranceCompanyLoading"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.insuranceName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.insuranceNum
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" :offset="2">
            <el-form-item label="交强险起保日" prop="tcInsuranceValidDate">
              <el-date-picker
                v-model="form.tcInsuranceValidDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择交强险起保日"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="承保公司" prop="tcInsuranceCompany">
              <el-select
                v-model="form.tcInsuranceCompany"
                filterable
                clearable
                remote
                placeholder="请输入承保公司名称查询"
                :remote-method="searchInsuranceCompany"
                :loading="searchInsuranceCompanyLoading"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.insuranceName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.insuranceNum
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" :offset="2">
            <el-form-item label="承运险起保日" prop="vehicleInsuranceValidDate">
              <el-date-picker
                v-model="form.vehicleInsuranceValidDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择承运险起保日"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="承保公司" prop="vehicleInsuranceCompany">
              <el-select
                v-model="form.vehicleInsuranceCompany"
                filterable
                clearable
                remote
                placeholder="请输入承保公司名称查询"
                :remote-method="searchInsuranceCompany"
                :loading="searchInsuranceCompanyLoading"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.insuranceName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.insuranceNum
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" :offset="2">
            <el-form-item label="驾乘险起保日" prop="drivingInsuranceValidDate">
              <el-date-picker
                v-model="form.drivingInsuranceValidDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择驾乘险起保日"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="承保公司" prop="drivingInsuranceCompanyId">
              <el-select
                v-model="form.drivingInsuranceCompanyId"
                filterable
                clearable
                remote
                placeholder="请输入承保公司名称查询"
                :remote-method="searchInsuranceCompany"
                :loading="searchInsuranceCompanyLoading"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.insuranceName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.insuranceNum
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" :offset="2">
            <el-form-item label="格悦出单">
              <el-switch
                v-model="form.geyueIssuer"
                :active-text="form.geyueIssuer ? '是' : '否'"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="团车政策">
              <el-switch
                v-model="form.groupCarPolicy"
                :active-text="form.groupCarPolicy ? '是' : '否'"
                :active-value="1"
                :inactive-value="0"
                @change="groupCarPolicyChange"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-input
              v-if="form.groupCarPolicy"
              size="small"
              v-model="form.groupCarCode"
              placeholder="请输入团车代码"
            ></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20" :offset="2">
            <el-form-item label="承保特殊要求">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入"
                v-model="form.insureSpecialAsk"
                maxlength="100"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <select-car-model-dialog
        v-if="selectCarModelDialogVisibility"
        :visibility.sync="selectCarModelDialogVisibility"
        @select="selectCarModel"
      ></select-car-model-dialog>

      <car-config-detail-dialog
        v-if="showCarConfigDialog"
        :showCarConfigDialog.sync="showCarConfigDialog"
        :vehicleList="vehicleList"
        :uniqueId="form.uniqueId"
        :levelId="levelId"
        @selectedVehicle="selectedVehicle"
      ></car-config-detail-dialog>
      <!-- <car-models-dialog
        v-if="selectCarModelDialogVisibility"
        :showCarModelsDialog.sync="selectCarModelDialogVisibility"
        @carModel="selectCarModel"
      ></car-models-dialog> -->
      <add-customer-dialog
        v-if="showAddCusterDialog"
        :showAddCusterDialog.sync="showAddCusterDialog"
        @custerInforma="custerInforma"
      ></add-customer-dialog>
    </div>

    <template slot="footer">
      <div>
        <el-button size="small" @click="verifyBeforeSave" type="primary"
          >确定保存</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
// 组件
import carConfigDetailDialog from './carConfigDialog.vue';
import carModelsDialog from './carModelsDialog.vue';
import addCustomerDialog from '../../addCustomer.vue';
import uploadFileList from '@/components/uploadFileList';
import UploadFile from '@/components/uploadFile.vue';
import selectClientPopover from '@/components/businessComponent/selectClientPopover.vue';
// 接口
import {
  insertCar,
  updateCar,
  getCarDetail,
  listCarModelDetailByVIN,
  searchCarModel,
  getModelAndYearBySeries,
} from '@/api/customer/car';
import { searchClient } from '@/api/customer/customer';
import { getCarModelList } from '@/api/customer/car';
import { searchInsuranceCompany } from '@/api/customer/sysmanent';

// 字典
import {
  fuelType,
  annualSurveyCarType,
  carState,
  underLeaseState,
} from '@/service/dict/dictData';
import { getDictValueByLabel } from '@/service/dict/dictTool';
import { levelIconsOther } from '@/const/client';
import selectCarModelDialog from '@/components/businessComponent/selectCarModelDialog.vue';

export default {
  components: {
    carConfigDetailDialog,
    UploadFile,
    uploadFileList,
    // carModelsDialog,
    selectCarModelDialog,
    addCustomerDialog,
    selectClientPopover,
  },
  props: ['showAddCarDialog', 'id'],
  data() {
    return {
      title: '新增车辆',
      // 配置详情
      showCarConfigDialog: false,
      //选择车型
      selectCarModelDialogVisibility: false,
      //新增客户
      showAddCusterDialog: false,

      // 表单数据
      form: {
        // 车辆状态 默认包干中
        state: 0,
        // // 车牌号
        // carNumber: '',
        // // 车架号
        // uniqueId: '',
        // // 车型
        // type: '',
        // // 燃料类型
        // fuelType: '',
        // // 指导车价
        // guidingPrice: '',
        // // 发动机号
        // engineNumber: '',
        // // 注册日期
        // registerDate: '',
        // 年审类型
        annualSurveyCarType: 0,
        // // 备注
        // remark: '',
        // // 车辆图片
        // // 行驶证图片名称
        // runningLicenceImg: '',
        // // 商业险保单图片名称
        // vciGuaranteeSlipImg: '',
        // // 交强险保单图片名称
        // tciGuaranteeSlipImg: '',
        // // 承运险保单图片名称
        // viGuaranteeSlipImg: '',
        // // 其他图片(多个图片用逗号","拼接)
        // picOther: '',
        // // 商业险保险日期
        // insuranceValidDate: '',
        // // 承保公司: 0-商业险
        // insuranceCompany: '',
        // // 交强险保险日期
        // tcInsuranceValidDate: '',
        // // 承保公司: 1-交强险
        // tcInsuranceCompany: '',
        // // 承运险保险日期
        // vehicleInsuranceValidDate: '',
        // // 承保公司：2-承运险
        // vehicleInsuranceCompany: '',

        // // 客户ID
        // clientId: '',
        // // 品牌
        // brand: '',
        // // 车辆品牌ID
        // carBrandId: '',
        // // 车型ID
        // carModelId: '',
        // // 门店ID
        // companyId: '',
        // // 云车型ID
        // cloudCarModelId: '',

        carImgs: [],
        //新增的开关按钮
        geyueIssuer: 0,
        groupCarPolicy: 0,
      },
      // 表单规则
      rules: {
        carNumber: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
        ],
      },

      searchModelLoading: false,
      models: [],
      // 燃料类型
      fuelTypes: fuelType,
      // 年审类型
      annualVerificationTypes: annualSurveyCarType,
      // 车辆状态
      state: carState,
      // 在租状态
      underLeaseState,

      clientBriefMessage: {},

      // 搜索保险公司
      insuranceCompanyList: [],
      searchInsuranceCompanyLoading: false,
      // 客户等级对应图标
      levelIconsOther,

      // 是否修改图片
      oldImgString: '',

      // 配置详情
      vehicleList: [],
      levelId: '',
      carModelData: [],
      registerDatePickerOptions: {
        disabledDate(time) {
          return time > Date.now();
        },
      },

      allPictures: [],

      currentCarModel: {},
    };
  },
  computed: {},
  created() {
    if (this.id) {
      this.title = '编辑车辆';
      getCarDetail(this.id).then((res) => {
        this.form = res;
        if (res.carModelName) {
          const model = {
            name: res.carModelName,
            id:
              '' +
              res.modelDetailLogoId +
              res.modelDetailBrandId +
              (res.modelDetailSeriesId || 0) +
              (res.modelDetailModelNumberId || 0) +
              (res.modelDetailModelYearId || 0),
          };
          this.carModelData.push(model);
          this.currentCarModel = model;
          this.initCarModelSelList(res.carModelName);
          this.setChangeEditVo(res);
        }
        this.form.carType = res.carModelName;
        this.form.clientId = res.clientBriefMessage?.id || '';
        this.clientBriefMessage = res.clientBriefMessage || {};
        //处理回显数据
        // 保险公司
        let insuranceCompanyList = [];
        insuranceCompanyList.push({
          id: res.insuranceCompany,
          insuranceName: res.insuranceCompanyLabel,
        });
        insuranceCompanyList.push({
          id: res.tcInsuranceCompany,
          insuranceName: res.tcInsuranceCompanyLabel,
        });
        insuranceCompanyList.push({
          id: res.vehicleInsuranceCompany,
          insuranceName: res.vehicleInsuranceCompanyLabel,
        });
        this.insuranceCompanyList = this.$lodash.unionBy(
          insuranceCompanyList.filter((item) => item.id),
          'id'
        );
        // 处理图片
        this.form.runningLicenceImg =
          res.picCarLists.find((item) => item.picTypeName === '行驶证')
            ?.picUrlName ?? '';
        this.form.vciGuaranteeSlipImg =
          res.picCarLists.find((item) => item.picTypeName === '商业险保单')
            ?.picUrlName ?? '';
        this.form.tciGuaranteeSlipImg =
          res.picCarLists.find((item) => item.picTypeName === '交强险保单')
            ?.picUrlName ?? '';
        this.form.viGuaranteeSlipImg =
          res.picCarLists.find((item) => item.picTypeName === '承运险保单')
            ?.picUrlName ?? '';
        this.form.vehicleNameplateImg =
          res.picCarLists.find((item) => item.picTypeName === '车辆铭牌')
            ?.picUrlName ?? '';
        this.form.batteryNameplateImg =
          res.picCarLists.find((item) => item.picTypeName === '电池铭牌')
            ?.picUrlName ?? '';

        this.form.picOther = res.picCarLists
          .filter((item) => item.picTypeName == '其他资料')
          .map((item) => item.picUrlName)
          .join();
        this.oldImgString =
          String(this.form.runningLicenceImg) +
          String(this.form.vciGuaranteeSlipImg) +
          String(this.form.tciGuaranteeSlipImg) +
          String(this.form.viGuaranteeSlipImg) +
          String(this.form.vehicleNameplateImg) +
          String(this.form.batteryNameplateImg) +
          String(this.form.picOther);

        this.levelId = res.levelId;

        // listCarModelDetailByVIN(this.form.uniqueId).then((res) => {
        //   this.vehicleList = res.vehicleList;
        //   this.$message.success(
        //     '输入的车架号存在多条车型配置信息,建议点击【配置详情】二次确认'
        //   );
        // });
        // 车架号
        if (this.form.uniqueId?.length === 17) {
          this.getVehicleList();
        }

        this.handlerAllPictures();
      });
    }
    this.searchInsuranceCompany();
  },
  methods: {
    init() {
      this.initCarModelSelList();
    },
    /**
     * 首次加载编辑数据的时候，初始化车型下拉框
     */
    initCarModelSelList(carModelName) {
      this.searchModelLoading = true;
      const _this = this;
      getCarModelList(
        { msg: carModelName || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          this.carModelData =
            res.records.map((el) => {
              el.id =
                '' +
                el.logoId +
                el.brandId +
                el.seriesId +
                (el.modelNumberId || 0) +
                (el.modelYearId || 0);
              el.name = `${el.logoName}${el.seriesName || ''}${
                el.modelNumberName || ''
              }${el.modelYearName ? ' ' + el.modelYearName : ''}`;
              return el;
            }) || [];
          console.log(_this.currentCarModel);
          console.table(_this.carModelData);
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    /**
     * 全部图片
     */
    handlerAllPictures() {
      let form = this.form;
      this.allPictures = [
        form.runningLicenceImg,
        form.vciGuaranteeSlipImg,
        form.tciGuaranteeSlipImg,
        form.viGuaranteeSlipImg,
        form.vehicleNameplateImg,
        form.batteryNameplateImg,
        ...form.picOther?.split(','),
      ].filter((pic) => !!pic);
    },
    /**
     * 关闭/取消
     */
    handleClose() {
      this.$emit('update:showAddCarDialog', false);
    },
    /**
     * 选择客户
     */
    handleSelectClient(client) {
      this.form.clientId = client.id;
      this.clientBriefMessage = client;
    },
    /**
     * 删除客户
     */
    deleteClient() {
      if (this.form.clientId) this.form.clientId = '';
      this.form.clientBriefMessage = {};
      this.clientBriefMessage = {};
    },
    /**
     * 远程搜索保险公司列表
     */
    insuranceCompanyKeyWorkChange(keyWord) {
      if (keyWord) {
        this.searchInsuranceCompany(keyWord);
      }
    },
    searchInsuranceCompany(keyWord = '') {
      this.searchInsuranceCompanyLoading = true;
      searchInsuranceCompany(keyWord)
        .then((res) => {
          this.insuranceCompanyList = res;
        })
        .finally(() => {
          this.searchInsuranceCompanyLoading = false;
        });
    },
    /**
     * 选择保险公司
     */
    selectInsuranceCompany(company) {
      console.log(company);
    },
    // 车架号输入事件
    inputUniqueId(w) {
      var reg = /[^A-Za-z0-9]/;
      this.form.uniqueId = w.replace(reg, '');
      if (w.length === 17) {
        this.getVehicleList();
      }
    },
    // 搜索配置详情
    getVehicleList() {
      listCarModelDetailByVIN(this.form.uniqueId).then((res) => {
        this.vehicleList = res.vehicleList;

        // 匹配到唯一
        if (res.vehicleList?.length === 1) {
          this.form.levelId = res.vehicleList[0].levelId;
          this.form.type = res.vehicleList[0]?.modelDetail?.series?.name;
          this.$message.success(
            '输入的车架号已匹配到唯一的车型配置信息,可点击【配置详情】查看'
          );
        } else {
          this.$message.success(
            '输入的车架号存在多条车型配置信息,建议点击【配置详情】二次确认'
          );
        }
      });
    },
    /**
     * 搜索车型方法
     */
    searchModel(key) {
      this.searchModelLoading = true;
      getCarModelList(
        { msg: key || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          this.carModelData =
            res.records.map((el) => {
              el.id =
                '' +
                el.logoId +
                el.brandId +
                el.seriesId +
                (el.modelNumberId || 0) +
                (el.modelYearId || 0);
              el.name = `${el.logoName}${el.seriesName || ''}${
                el.modelNumberName || ''
              }${el.modelYearName ? ' ' + el.modelYearName : ''}`;
              return el;
            }) || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    /**
     * 清空车型
     */
    clearCarModel() {
      delete this.form.modelDetailLogoId;
      delete this.form.modelDetailLogoName;
      delete this.form.modelDetailBrandId;
      delete this.form.modelDetailBrandName;
      delete this.form.modelDetailSeriesId;
      delete this.form.modelDetailSeriesName;
      delete this.form.modelDetailModelNumberId;
      delete this.form.modelDetailModelNumberName;
      delete this.form.modelDetailModelYearId;
      delete this.form.modelDetailModelYearName;
      this.currentCarModel = {};
    },
    /**
     * 选择车型
     */
    changeCarModel(value) {
      console.log('value', value);
      // this.form.type = value?.modelDetail?.series?.name;
      // this.form.type = value?.modelDetailSeriesName;

      // value.modelDetailLogoName +
      // '/' +
      // value.modelDetailBrandName +
      // '/' +
      // value.modelDetailSeriesName;
      this.form.modelDetailLogoId = value.logoId;
      this.form.modelDetailLogoName = value.logoName;
      this.form.modelDetailBrandId = value.brandId;
      this.form.modelDetailBrandName = value.brandName;
      this.form.modelDetailSeriesId = value.seriesId;
      this.form.modelDetailSeriesName = value.seriesName;
      this.form.modelDetailModelNumberId = value.modelNumberId || 0;
      this.form.modelDetailModelNumberName = value.modelNumberName || '';
      this.form.modelDetailModelYearId = value.modelYearId || 0;
      this.form.modelDetailModelYearName = value.modelYearName || '';

      const model = {
        id:
          '' +
          value.logoId +
          value.brandId +
          value.seriesId +
          (value.modelNumberId || 0) +
          (value.modelYearId || 0),
        name: `${value.logoName}${value.seriesName ? value.seriesName : ''}${
          value.modelNumberName ? value.modelNumberName : ''
        }${value.modelYearName ? ' ' + value.modelYearName : ''}`,
      };
      //this.carModelData.push(model);
      this.currentCarModel = model;
    },
    /**
     * 设置编辑的车型信息
     * @param infoVo 车辆信息
     */
    setChangeEditVo(infoVo) {
      this.form.modelDetailLogoId = infoVo.modelDetailLogoId || 0;
      this.form.modelDetailLogoName = infoVo.modelDetailLogoName || '';
      this.form.modelDetailBrandId = infoVo.modelDetailBrandId || 0;
      this.form.modelDetailBrandName = infoVo.modelDetailBrandName || '';
      this.form.modelDetailSeriesId = infoVo.modelDetailSeriesId || 0;
      this.form.modelDetailSeriesName = infoVo.modelDetailSeriesName || '';
      this.form.modelDetailModelNumberId = infoVo.modelDetailModelNumberId || 0;
      this.form.modelDetailModelNumberName =
        infoVo.modelDetailModelNumberName || '';
      this.form.modelDetailModelYearId = infoVo.modelDetailModelYearId || 0;
      this.form.modelDetailModelYearName =
        infoVo.modelDetailModelYearName || '';
    },
    /**
     * 选择车型
     */
    selectCarModel(val) {
      let value = val[0];
      // this.form.type = value.type;
      if (value.seriesName && !value.modelYearName) {
        this.form.type = value.seriesName;
      } else {
        if (value.modelYearName) {
          this.form.type =
            value.seriesName +
            ' ' +
            value.modelNumberName +
            ' ' +
            value.modelYearName;
        }
      }
      this.form.modelDetailLogoId = value.logoId;
      this.form.modelDetailLogoName = value.logoName;
      this.form.modelDetailBrandId = value.brandId;
      this.form.modelDetailBrandName = value.brandName;
      this.form.modelDetailSeriesId = value.seriesId;
      this.form.modelDetailSeriesName = value.seriesName;
      this.form.modelDetailModelNumberId = value.modelNumberId || 0;
      this.form.modelDetailModelNumberName = value.modelNumberName || '';
      this.form.modelDetailModelYearId = value.modelYearId || 0;
      this.form.modelDetailModelYearName = value.modelYearName || '';

      const model = {
        id:
          '' +
          value.logoId +
          value.brandId +
          value.seriesId +
          (value.modelNumberId || 0) +
          (value.modelYearId || 0),
        name: `${value.logoName}${value.seriesName ? value.seriesName : ''}${
          value.modelNumberName ? value.modelNumberName : ''
        }${value.modelYearName ? ' ' + value.modelYearName : ''}`,
      };
      const warpCarModelData = [value].map((el) => {
        el.id =
          '' +
          el.logoId +
          el.brandId +
          el.seriesId +
          (el.modelNumberId || 0) +
          (el.modelYearId || 0);
        el.name = `${el.logoName}${el.seriesName || ''}${
          el.modelNumberName || ''
        }${el.modelYearName ? ' ' + el.modelYearName : ''}`;
        return el;
      });
      this.carModelData.push(warpCarModelData[0]);
      this.currentCarModel = model;
      console.log(model);
      console.table(warpCarModelData);
    },
    /**
     * 保存前校验
     */
    verifyBeforeSave() {
      console.log('this.form', this.form);
      if (this.form.uniqueId && this.form.uniqueId.length !== 17) {
        this.$message.warning('车架号无效！');
        return;
      }
      // //保险到期时间需要和保险公司同时存在，任意一个为空弹出提示
      // let res = [
      //   ['insuranceCompany', 'insuranceValidDate'],
      //   ['tcInsuranceCompany', 'tcInsuranceValidDate'],
      //   ['vehicleInsuranceCompany', 'vehicleInsuranceValidDate'],
      // ].every((item) => {
      //   return (
      //     (!!this.form[item[0]] && !!this.form[item[1]]) ||
      //     (!this.form[item[0]] && !this.form[item[1]])
      //   );
      // });
      // console.log(res);
      // if (!res) {
      //   return this.$message.warning(
      //     '保险信息中保险到期日跟承保公司须同时存在'
      //   );
      // }
      const _this = this;
      this.$refs.form.validate((result, object) => {
        if (result) {
          if (_this.currentCarModel.id === undefined) {
            this.$message.warning('请选择车型必填项');
            return;
          }
          this.doSave();
        } else {
          this.$message.warning('请填写必填项');
          console.log(result, object);
        }
      });
    },
    /**
     * 保存
     */
    doSave() {
      // 处理图片
      let cloneForm = JSON.parse(JSON.stringify(this.form));
      cloneForm.runningLicenceImg = this.form.runningLicenceImg
        ?.split('/')
        .at(-1);
      cloneForm.vciGuaranteeSlipImg = this.form.vciGuaranteeSlipImg
        ?.split('/')
        .at(-1);
      cloneForm.tciGuaranteeSlipImg = this.form.tciGuaranteeSlipImg
        ?.split('/')
        .at(-1);
      cloneForm.viGuaranteeSlipImg = this.form.viGuaranteeSlipImg
        ?.split('/')
        .at(-1);
      cloneForm.vehicleNameplateImg = this.form.vehicleNameplateImg
        ?.split('/')
        .at(-1);
      cloneForm.batteryNameplateImg = this.form.batteryNameplateImg
        ?.split('/')
        .at(-1);

      // 处理图片
      cloneForm.picOther = cloneForm.picOther
        ?.split(',')
        .map((item) => item.split('/').at(-1))
        .join();

      // 保存
      if (this.id) {
        // 判断是否修改过图片
        let newImgString =
          String(this.form.runningLicenceImg) +
          String(this.form.vciGuaranteeSlipImg) +
          String(this.form.tciGuaranteeSlipImg) +
          String(this.form.viGuaranteeSlipImg) +
          String(this.form.vehicleNameplateImg) +
          String(this.form.batteryNameplateImg) +
          String(this.form.picOther);
        cloneForm.isEditPicState = newImgString === this.oldImgString ? 0 : 1;

        updateCar(cloneForm).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.oldImgString = newImgString;
            this.$message.success('保存成功');
            this.$emit('save');
            this.handleClose();
          }
        });
      }
      // 新增
      else {
        cloneForm.companyId = this.$store?.state?.user?.userInfo?.companyId;
        insertCar(cloneForm).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message.success('保存成功');
            this.$emit('save', res.data);
            this.handleClose();
          }
        });
      }
    },
    // 配置详情
    selectedVehicle(validate) {
      // this.form.type = validate?.modelDetail?.series?.name;
      this.form.type = this.$lodash.get(
        validate?.modelDetail,
        'series.name',
        this.$lodash.get(
          validate?.modelDetail,
          'brand.name',
          this.$lodash.get(validate?.modelDetail, 'logo.name', '')
        )
      );
      // validate.brandName +
      // '/' +
      // validate.familyName +
      // '/' +
      // validate.vehicleName; //车型
      this.$set(
        this.form,
        'fuelType',
        getDictValueByLabel('fuelType', validate.fuelType)
      ); //燃料类型
      this.$set(this.form, 'guidingPrice', validate.guidingPrice); //指导车价
      this.$set(this.form, 'engineNumber', validate.engineModel); //发动机号
      this.$set(this.form, 'levelId', validate.levelId);
      this.$set(this.form, 'modelDetailLogoId', validate.modelDetail.logo.id);
      this.$set(
        this.form,
        'modelDetailLogoName',
        validate.modelDetail.logo.name
      );
      this.$set(this.form, 'modelDetailBrandId', validate.modelDetail.brand.id);
      this.$set(
        this.form,
        'modelDetailBrandName',
        validate.modelDetail.brand.name
      );
      this.$set(
        this.form,
        'modelDetailSeriesId',
        validate.modelDetail.series.id
      );
      this.$set(
        this.form,
        'modelDetailSeriesName',
        validate.modelDetail.series.name
      );
    },
    //新增客户成功时返回的事件
    custerInforma(value) {
      this.clientBriefMessage = value;
      this.form.clientId = value.id;
    },
    /**
     * 团车政策变更
     */
    groupCarPolicyChange(val) {
      if (!val) {
        this.form.groupCarCode = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.rows-tip {
  color: #999;
  line-height: 18px;
}
.img-note {
  color: #999;
  font-size: 14px;
}
.car-imgs {
  display: flex;
}
.block-title {
  margin: 30px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.client-list {
  .client {
    display: flex;
    align-items: center;
    > .icon_delete {
      font-size: 18px;
      color: #ff9200;
      margin-right: 15px;
    }
    .client-info {
      width: 95%;
      padding: 12px 18px;
      background: #f5f7fa;
      border-radius: 4px;
      > div + div {
        margin-top: 10px;
      }
      i {
        margin: 0 5px;
      }
      .icon_person {
        color: $blue;
      }
      .icon_sex {
        color: $blue;
      }
      .icon_grade {
        color: $blue;
      }
      .grow-icon_girl {
        color: #f48080;
      }
      .icon_wechat {
        color: #999;
      }
      .icon_wechat_active {
        color: #00c800;
      }
      .icon_phone {
        color: #42bbb8;
      }
      .icon_vip {
        color: $orange;
      }
      .icon_sex,
      .icon_grade,
      .icon_wechat,
      .icon_wechat_active,
      .icon_vip {
        font-size: 14px;
      }
    }
  }
}
</style>
