<template>
  <base-container paddingTop>
    <div class="switch-style flex">
      <p>车间工单</p>
      <el-switch
        class="el-icon--right"
        v-model="singleValue"
        @change="changeSingleValue"
      >
      </el-switch>
      <span v-show="singleValue"
        >点击关闭施工单流程，后车单将无需走施工流程</span
      >
      <span v-show="singleValue == false"
        >点击开启施工单流程，选中的门店后续车单将走施工流程</span
      >
    </div>
    <p
      class="orange"
      style="font-size: 12px; margin-bottom: 12px"
      v-show="singleValue"
    >
      注：下方门店显示范围为当前登录账号所属管辖组范围的门店，不在登陆账号管辖组范围的不显示
    </p>
    <div @click="titleShow" class="flex" v-show="singleValue">
      <p class="blue pointer">{{ tipTitle }}</p>
      <i
        class="iconfont"
        :class="{
          'grow-icon_Choice': !showIcon,
          'grow-icon_Choice_activate': showIcon,
        }"
        style="color: #9a9a9a; margin-left: 3px"
      ></i>
    </div>
    <!-- 表格数据 -->
    <div v-if="showTable && singleValue">
      <div class="chioce-style flex">
        <p>门店名称</p>
        <div style="width: 60%; margin-top: 8px">
          <el-select
            :key="selectKey"
            size="small"
            v-model="storeName"
            @change="selectStore"
            @clear="deleteStore"
            filterable
            clearable
            remote
            placeholder="请输入门店名称"
            :remote-method="searchStore"
            :loading="searchStoreLoading"
          >
            <el-option
              v-for="item in storeList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            >
              <span style="float: left">{{ item.companyName }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          height="300"
          border
          style="width: 40%; border-radius: 0px 0px 4px 4px"
          class="ant-table-body"
          @selection-change="handleSelectionChange"
          v-el-table-infinite-scroll="loadTableData"
          :infinite-scroll-disabled="!hasMore"
        >
          <el-table-column type="selection" width="40"> </el-table-column>
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column prop="companyName" label="车店名称" width="180">
          </el-table-column>
          <el-table-column prop="chainCompanyName" label="连锁类型">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <template slot="footer">
      <div class="flex-x-end foot-btn">
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </div>
    </template>
  </base-container>
</template>

<script>
import { mapState } from 'vuex';

import elTableInfiniteScroll from 'el-table-infinite-scroll'; //引入el-table无限滚动指令
//接口
import {
  getDispatchStateList,
  updateDispatchState,
} from '@/api/singleSet/index';
//模糊搜索接口
import { searchStoreWithJurisdiction } from '@/api/customer/store';
export default {
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll,
  },
  name: 'storeManagement',
  components: {},
  data() {
    return {
      singleValue: true,
      storeName: '',
      tipTitle: '展示适用门店',

      hasMore: true,
      tableData: [],
      selectedStores: [],
      current: 0,
      dialogVisibleTile: '',
      showIcon: false,
      showTable: false,

      //搜索操作门店字段
      searchStoreLoading: false,
      storeList: [],
      selectKey: 1,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      companyName: (state) => state.user.companyName,
    }),
  },
  created() {
    this.searchStore();
  },
  methods: {
    //远程搜索操作门店列表
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStoreWithJurisdiction(keyWord)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    // 选择操作门店
    selectStore(storeId) {
      this.selectKey = Math.random();
      var data = {
        name: this.storeName,
      };
      getDispatchStateList(data).then((res) => {
        this.tableData = res.records;
        this.$nextTick().then(() => {
          this.selectedStores = this.tableData.filter(
            (item) => item.dispatchState == 1
          );
        });
        this.syncSelectedStatus();
      });
    },
    //删除操作门店
    deleteStore() {},
    //点击是否展示门店表格
    titleShow() {
      if (this.tipTitle == '展示适用门店') {
        this.tipTitle = '收起适用门店';
        this.showIcon = !this.showIcon;
        this.showTable = !this.showTable;
        this.syncSelectedStatus();
      } else {
        this.tipTitle = '展示适用门店';
        this.showIcon = !this.showIcon;
        this.showTable = !this.showTable;
      }
    },
    /**
     * 获取数据
     */
    loadTableData() {
      if (!this.hasMore) return;
      this.hasMore = false;
      var data = {
        name: this.storeName,
        current: ++this.current,
        size: 10,
      };
      getDispatchStateList(data).then((res) => {
        this.tableData.push(...res.records);
        this.hasMore = res.total > this.current * 10; //判断如果总条数大于当前请求的总条数就实现分页加载数据
        this.selectedStores = this.tableData.filter(
          (item) => item.dispatchState == 1
        );
        this.syncSelectedStatus();
      });
    },

    /**
     * table选中事件
     */
    handleSelectionChange(val) {
      this.selectedStores = val;
    },
    /**
     * 同步选中状态
     */
    syncSelectedStatus() {
      this.$nextTick().then(() => {
        this.selectedStores.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      });
    },

    //更改车单开关
    changeSingleValue() {
      if (this.singleValue) {
        this.dialogVisibleTile =
          '开启后，后续车单需要施工流程，确认开启施工单流程？';
      } else {
        this.dialogVisibleTile =
          '关闭后，后续车单将不在需要走施工流程，确认关闭施工单流程？';
      }
      this.$confirm(this.dialogVisibleTile, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.selectedStores = this.tableData.filter(
            (item) => item.dispatchState == 1
          );
          this.syncSelectedStatus();
        })
        .catch(() => {
          this.singleValue = !this.singleValue;
          this.selectedStores = this.tableData.filter(
            (item) => item.dispatchState == 1
          );
          this.syncSelectedStatus();
        });
    },
    /*
     **保存更改
     */
    save() {
      var dispatchState = '';
      //   let noChioce = [];
      let changeStoreList = this.$lodash.cloneDeep(this.selectedStores);
      //判断车间工单开关状态
      if (this.singleValue) {
        dispatchState = 1;
      } else {
        dispatchState = 0;
      }
      //重组数据请求
      changeStoreList = changeStoreList.map((item) => ({
        dispatchState: dispatchState,
        companyId: item.companyId,
      }));
      //过滤出没有选中的数据
      //   var allIds = changeStoreList.map((item) => item.companyId);
      //   noChioce = this.tableData.filter(
      //     (item) => !allIds.includes(item.companyId)
      //   );
      //   noChioce = noChioce.map((item) => ({
      //     dispatchState: item.dispatchState,
      //     companyId: item.companyId,
      //   }));
      updateDispatchState(changeStoreList).then((res) => {
        if (res.code == 200) {
          this.$message.success('更改成功！');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.switch-style {
  margin-bottom: 10px;
  p {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c0c4cc;
    margin-left: 8px;
    margin-top: 3px;
  }
}
.chioce-style {
  margin-top: 12px;
  height: 48px;
  border: 1px solid #ebeef5;
  border-bottom: 0px;
  width: 40%;
  border-radius: 4px 4px 0px 0px;
  p {
    margin-right: 10px;
    margin-left: 15px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    line-height: 48px;
  }
  div:nth-child(3) {
    margin-left: 82px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3e95e0;
  }
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
}
</style>
