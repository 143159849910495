import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 查询定损单流程信息
 * @params data {id:number}
 */
export function getCarLossDeterminationBillMessageFlow(data = {}) {
  return http.post('/admin/carLossDeterminationBill/messageFlow', data);
}

/**
 * 查询定损单留言记录
 * @params data {id:number}
 */
export function getCarLossDeterminationBillMessageRecord(data = {}) {
  return http.post(
    '/admin/carLossDeterminationBill/messageRecord',
    Object.assign({}, defaultListOption, data)
  );
}

/**
 * 定损单 添加留言
 */
export function addMessageForCarLossDeterminationBill(data = {}) {
  return http.post('/admin/carLossDeterminationBill/message', data);
}

/**
 * 撤销操作流程
 */
export function reversalOperateForCarLossDeterminationBill(data = {}) {
  return http.post('/admin/carLossDeterminationBill/reversalOperate', data);
}

/**
 * 更新定损单
 */
export function updateCarLossDeterminationBill(data) {
  return http.post('/admin/carLossDeterminationBill/update', data, {
    onlyReturnData: false,
  });
}
/**
 * 更新定损单
 */
export function updateAttachmentFile(data) {
  return http.post(
    '/admin/carLossDeterminationBill/updateAttachmentFile',
    data
  );
}
/**
 * 上传zip文件
 */
export function uploadZipFile(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.requestNative({
    method: 'post',
    url: '/oss/admin/oss/updateZipFile',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 完成复勘和收旧
 */
export function finishInvestigationRecovery(data) {
  return http.post(
    '/admin/carLossDeterminationBill/finishInvestigationRecovery',
    data,
    {
      onlyReturnData: false,
    }
  );
}

/**
 * 已提资料和回款认领
 */
export function submissionAndPaymentCollection(data) {
  return http.post(
    '/admin/carLossDeterminationBill/submissionAndPaymentCollection',
    data,
    {
      onlyReturnData: false,
    }
  );
}
/**
 * 赔回款认领审核理处理
 */
export function getClaimInfo(data) {
  return http.post('/admin/carLossDeterminationBill/getClaimInfo', data, {
    onlyReturnData: false,
  });
}
/**
 * 赔回款认领审核理处理
 */
export function auditingSave(data) {
  return http.post('/admin/carLossDeterminationBill/auditingSave', data, {
    onlyReturnData: false,
  });
}
/**
 * 赔回款认领
 */
export function receivingAmountClaimSave(data) {
  return http.post(
    '/admin/carLossDeterminationBill/receivingAmountClaimSave',
    data,
    {
      onlyReturnData: false,
    }
  );
}

/**
 * 调整免赔信息
 */
export function updateExemptionInfo(data) {
  return http.post(
    '/admin/carLossDeterminationBill/updateExemptionInfo',
    data,
    {
      onlyReturnData: false,
    }
  );
}
/**
 * 下载zip附件地址
 */
export const downloadZipFileUrl = '/admin/oss/downloadZipFile';

/**
 * 定损业绩看板
 */
export function carLossDeterminationStatistic(data = {}) {
  return http.post(
    '/admin/carLossDeterminationBill/carLossDeterminationStatistic',
    data
  );
}

/**
 * 定损明细列表
 */
export function carLossDeterminationDetailedList(data = {}) {
  return http.post('/admin/carLossDeterminationBill/detailedList', data);
}

/**
 * 定损明细列表导出
 */
export const carLossDeterminationDetailedListExport =
  '/admin/carLossDeterminationBill/detailedList/export';

/**
 * 定损执行报表
 */
export function carLossDeterminationExecutionReport(data = {}) {
  return http.post('/admin/carLossDeterminationBill/executionReport', data);
}

/**
 * 定损执行报表导出
 */
export const carLossDeterminationExecutionReportExport =
  '/admin/carLossDeterminationBill/executionReport/export';
