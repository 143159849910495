<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div class="form-top">
        <el-form :model="form" label-width="100px" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                ></operateCompanyCascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    开票主体
                    <el-tooltip effect="light" placement="bottom-start">
                      <div
                        slot="content"
                        style="
                          color: #666666;
                          font-size: 13px;
                          line-height: 20px;
                          text-indent: 15px;
                        "
                      >
                        开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                        的回款管理门店(保险公司的开票主体也是定损主体)。保险公司<br />管理的开票主体，可查已成为保险公司开票
                        主体的全部门店。
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>

                <el-select
                  v-model="form.invoiceCompanyId"
                  @change="selectInvoice"
                  @clear="deleteInvoice"
                  filterable
                  clearable
                  remote
                  placeholder="搜索开票主体名称"
                  :remote-method="searchInvoice"
                  :loading="searchinvoiceLoading"
                >
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款日期">
                <el-date-picker
                  class="w100"
                  v-model="form.times"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div class="el-form-item--small" style="margin-left: 20%">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="getList()"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="消费客户">
                <div class="last-type-sty">
                  <el-select
                    v-model="form.consumerType"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in customerList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    v-model="form.clientId"
                    placeholder="请输入客户名称"
                    clearable
                    @keyup.enter.native="getList"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 分割线 -->
      <el-divider v-if="showMore"></el-divider>
      <transition name="fade">
        <div v-show="showMore">
          <el-form :model="form" label-width="100px" size="small">
            <el-row>
              <el-col :span="6">
                <el-form-item label="收款单号">
                  <el-input
                    v-model="form.repaymentBillCode"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="getList"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="接车单号">
                  <el-input
                    v-model="form.carBillCode"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="getList"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="支付方式">
                  <el-select
                    v-model="form.payType"
                    filterable
                    placeholder="选择支付方式"
                    clearable
                  >
                    <el-option
                      v-for="item in payType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="销账收款备注">
                  <el-input
                    v-model="form.remark"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="getList"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </transition>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="content-container">
        <div class="btns flex-x-between">
          <div>
            <div class="custom-tabs">
              <div
                @click="status = 0"
                :class="{
                  'custom-tab-item': true,
                  active: status == 0,
                }"
              >
                正常
              </div>
              <div
                @click="status = 1"
                :class="{
                  'custom-tab-item': true,
                  active: status == 1,
                }"
              >
                已作废
              </div>
            </div>
          </div>
          <div>
            <el-button
              v-auth="503"
              size="small"
              type="text"
              class="blue"
              @click="showExportDialog = true"
              >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
            ></el-button>
          </div>
        </div>
        <div class="flex" style="flex: 1; overflow: auto">
          <div style="width: 85%; flex: 1; overflow: auto">
            <el-table
              :data="tableData"
              border
              class="custom-table"
              :summary-method="getSummaries"
              show-summary
              :header-cell-style="headerCellStyle"
            >
              <el-table-column type="index" label="序号" width="60">
              </el-table-column>
              <el-table-column
                prop="operateCompany"
                label="操作门店"
                width="230"
              >
              </el-table-column>
              <el-table-column label="收款单号" min-width="180">
                <template slot-scope="scope">
                  <span
                    class="pointer text_operation blue"
                    @click="goDetail(scope.row)"
                    >{{ scope.row.repaymentBillCode }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="repaymentDateTime"
                label="收款时间"
                min-width="180"
              >
              </el-table-column>
              <el-table-column label="消费客户" min-width="180">
                <template #default="{ row }">
                  <div v-if="row.consumer">
                    <p>{{ row.consumer.name }}</p>
                    <p class="color999">{{ row.consumer.phone }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="客户类型" min-width="150">
                <template slot-scope="scope">{{
                  scope.row.clientType | dict('clientType')
                }}</template>
              </el-table-column>
              <el-table-column
                prop="contact"
                label="联系人/联系电话"
                min-width="150"
              >
                <template #default="{ row }">
                  <div v-if="row.contact">
                    <p>{{ row.contact.name }}</p>
                    <p class="color999">{{ row.contact.phone }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="invoiceName"
                label="开票主体"
                min-width="230"
              >
              </el-table-column>
              <el-table-column prop="billPaid" label="单据金额" min-width="100">
              </el-table-column>
              <el-table-column prop="paidInfo" label="支付信息" min-width="100">
                <el-table-column
                  prop="paidInfo.cash"
                  label="现金"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="paidInfo.transfer"
                  label="转账"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="paidInfo.other"
                  label="其他本期销账收款使用了的支付方式"
                  min-width="250"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column
                prop="operateDateTime"
                label="操作时间"
                min-width="180"
              >
              </el-table-column>
              <el-table-column prop="operator" label="收款人" min-width="100">
              </el-table-column>
              <el-table-column prop="remark" label="备注" min-width="200">
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 15%; padding-left: 30px; box-sizing: border-box">
            <el-table :data="tableData2" style="width: 100%">
              <el-table-column prop="filder14" label="消费客户与统计（客户数）">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :maxExportNum="2e4"
        :url="exportUrl"
      ></export-dialog>
      <template slot="footer">
        <pagination @pageChange="changePage" :total="total"></pagination>
      </template>
      <charge-collection-drawer
        v-if="chargeDrawerVisibility"
        :chargeDrawerVisibility.sync="chargeDrawerVisibility"
        :billRepaymentId="billRepaymentId"
        @update="getList"
      ></charge-collection-drawer>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import { downloadFile } from '@/utils/fileApi';
import { mapState } from 'vuex';
import ChargeCollectionDrawer from '@/views/financialManagement/accountsReceivable/chargeCollectionDrawer.vue'; //接口
import { searchInvoice } from '@/api/storeManagement/index';
import {
  getRecordOnAccountList,
  exportRecordOnAccountListUrl,
} from '@/api/financial/financialOver';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';
// 工具
import Big from 'big.js';
export default {
  name: 'salesCollectionThird',
  components: {
    pagination,
    ChargeCollectionDrawer,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      form: {
        times: [],
      },
      filterForm: {},
      //导出的页面设置
      exportUrl: exportRecordOnAccountListUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      status: 0,
      tableData: [],
      showMore: false,
      customers: [],
      tableData2: [],
      chargeDrawerVisibility: false,
      //搜索开票主体门店字段
      searchinvoiceLoading: false,
      invoiceList: [],
      //消费客户字段
      customerList: [
        {
          value: 0,
          label: '自费',
        },
        {
          value: 1,
          label: '包干',
        },
        {
          value: 2,
          label: '保险公司',
        },
        {
          value: 3,
          label: '三包公司',
        },
      ],
      //支付方式字段
      payType: [
        {
          value: 0,
          label: '现金',
        },
        {
          value: 1,
          label: '支付宝',
        },
        {
          value: 2,
          label: '微信',
        },
        {
          value: 3,
          label: '其他',
        },
        {
          value: 4,
          label: '会员卡',
        },
        {
          value: 5,
          label: '挂账',
        },
        {
          value: 6,
          label: '免账',
        },
      ],
      billRepaymentId: '', //销账记录详情的id
      selectKey: 1,
    };
  },
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.searchInvoice();
    this.pickTimes();
    this.getList();
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  watch: {
    status() {
      this.getList();
    },
  },
  mounted() {},
  methods: {
    // 重置筛选表单
    resetFilterForm() {
      this.filterForm = {};
    },
    empty() {
      this.form = {};
      this.form.operateCompanyId = [];
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //表头合并
    headerCellStyle({ column: { label } }) {
      if (label == '支付信息') {
        return 'text-align:center';
      }
    },
    /**
     * 跳转详情页
     */
    goDetail(row) {
      this.billRepaymentId = row.id;
      this.chargeDrawerVisibility = true;
    },
    // 补零函数
    doHandleZero(zero) {
      var date = zero;
      if (zero.toString().length == 1) {
        date = '0' + zero;
      }
      return date;
    },
    //设置查询日期的默认时间
    pickTimes() {
      var tYear = new Date().getFullYear();
      var tMonth = new Date().getMonth();
      tMonth = tYear + '-' + this.doHandleZero(tMonth + 1) + '-' + '01';
      this.form.times = [tMonth, new Date().Format('yyyy-MM-dd')];
    },
    //远程搜索开票主体门店列表
    searchInvoice(keyWord = '') {
      this.searchinvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchinvoiceLoading = false;
        });
    },
    // 选择开票主体门店
    selectInvoice(invoiceId) {},
    //删除开票主体门店
    deleteInvoice() {},
    //获取数据接口
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var allTimes = {
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
        status: this.status,
        operateCompanyId: this.form.operateCompanyId,
      };
      var data = Object.assign(this.form, allTimes, this.pageOption);
      getRecordOnAccountList(data).then((res) => {
        this.total = res.total;
        this.tableData = res.records;
      });
    },

    //表格尾部自定义表格合计
    getSummaries(param) {
      //columns是每列的信息，data是每行的信息
      const { columns, data } = param;
      console.log(param);
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (
          [
            'billPaid',
            'paidInfo.cash',
            'paidInfo.transfer',
            'paidInfo.other',
          ].includes(column.property)
        ) {
          sums[index] = data
            .reduce((prev, curr) => {
              const value = Number(this.$lodash.get(curr, column.property, 0));
              return prev + value;
            }, 0)
            .toFixed(2);
          sums[index] += ' 元';
        }
      });

      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 30px 30px 0;
  box-sizing: border-box;
}
//设置表格中合计行颜色
::v-deep .el-table__footer-wrapper tbody td.el-table__cell {
  color: $orange !important;
}
.content-container {
  height: 100%;
  //   padding: 30px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}
.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}
//导出列表样式
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
.custom-table {
  display: flex;
  flex-direction: column;
  overflow: auto;
  ::v-deep .el-table__header-wrapper {
    flex-shrink: 0;
  }
  ::v-deep .el-table__body-wrapper {
    flex: 1;
    overflow: auto;
  }
  ::v-deep .el-table__footer-wrapper {
    flex-shrink: 0;
  }
}
</style>
