import router from '@/router';
import bus from '@/service/bus';

import { welTag } from './config';
import defaultState from './defaultState';

const isSameTag = (x, y) => x.path === y.path;

const state = defaultState();

export default {
  state,
  getters: {
    activeTagIndex: (state) => {
      return state.tagList.findIndex((tag) => tag.path === state.tag.path);
    },
  },
  mutations: {
    ADD_TAG: (state, action) => {
      // console.log(action);
      state.tag = action;
      if (action.name == 'wel') {
        state.tagList[0] = action;
        return;
      }
      if (state.tagList.some((ele) => isSameTag(ele, action))) {
        let index = state.tagList.findIndex((ele) => isSameTag(ele, action));
        // 参数不一致
        if (action.fullPath != state.tagList[index].fullPath) {
          console.log('参数变化，刷新页面');
          bus.$emit('refresh');
        }
        state.tagList[index] = action;
        return;
      }
      state.tagList.push(action);
    },
    DEL_TAG: (state, { index, needJump = true }) => {
      // state.tagList = state.tagList.filter((item) => {
      //   return !isSameTag(item, action);
      // });

      let isActiveTag =
        index ===
        state.tagList.findIndex((tag) => tag.fullPath === state.tag.fullPath);

      state.tagList.splice(index, 1);
      if (needJump) {
        // 关闭了最后一个标签页
        if (!state.tagList.length) {
          router.push(state.tagWel);
          return;
        }
        // 如果关闭的是当前页
        if (isActiveTag) router.push(state.tagList[index - 1].fullPath);
      }
    },
    DEL_ALL_TAG: (state) => {
      state.tagList = [welTag];
      state.tag = welTag;
      router.push(state.tagWel);
    },
    DEL_OTHER_TAG: (state) => {
      state.tagList = [state.tag];
    },
    UPDATE_TAG_TITLE: (state, { title = '' }) => {
      state.tag.title = title;
    },
  },
  actions: {
    REPLACE_TAG: (context, route) => {
      // 记录当前标签页下标
      let index = context.getters.activeTagIndex;
      router.push(route);
      // context.commit('ADD_TAG', route);
      context.commit('DEL_TAG', {
        index,
        needJump: false,
      });
    },
  },
  modules: {},
};
