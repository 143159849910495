<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-warp flex-shrink0">
            <el-form-item label="车型信息">
              <el-select
                v-model="form.carModel"
                filterable
                remote
                placeholder="搜索车型"
                :remote-method="searchModel"
                :loading="searchModelLoading"
                clearable
                @keyup.enter.native="getData"
              >
                <el-option
                  v-for="item in carModelList"
                  :key="$lodash.get(item, 'series.id')"
                  :value="
                    $lodash.get(
                      item,
                      'series.name',
                      $lodash.get(
                        item,
                        'brand.name',
                        $lodash.get(item, 'logo.name')
                      )
                    )
                  "
                  @click.native="selectCarModel(item)"
                >
                  <span v-html="item.markedRedName"></span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车辆所有人">
              <el-input
                v-model="form.vehicleOwner"
                placeholder="请输入"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <div class="el-form-item--small">
              <div class="flex">
                <el-button
                  class="btn_search"
                  type="primary"
                  size="small"
                  @click="getData"
                  >查询</el-button
                >
                <el-button
                  class="btn_search"
                  size="small"
                  @click="resetFilterForm"
                  >置空</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div class="flex-x-between">
        <div style="margin-bottom: 15px; font-size: 14px">
          <el-button
            v-auth="533"
            size="small"
            class="btn_insert"
            @click="addWarranty"
            >新增</el-button
          >
          <i
            class="pointer iconfont grow-icon_warn_32"
            style="color: #ff9200; font-size: 15px; margin: 5px 10px"
          ></i
          ><span style="color: #909399"
            >质保规则新增修改后将于次日零点生效</span
          >
        </div>

        <el-button
          v-auth="543"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        id="customTable"
        class="custom-table summary"
        :data="tableData"
        border
        :span-method="objectSpanMethod"
      >
        <el-table-column label="序号" width="60" prop="sequence">
        </el-table-column>
        <el-table-column label="操作" width="90">
          <template slot-scope="scope">
            <span
              v-auth="534"
              class="text_operation blue"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column min-width="140" label="车型" prop="name">
          <template #default="{ row }">
            <el-tooltip :content="row.name">
              <p class="text-ellipsis">{{ row.name }}</p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="车辆所有人" prop="vehicleOwner">
        </el-table-column>
        <el-table-column label="整车质保" header-align="center">
          <el-table-column
            min-width="90"
            label="区间（月/公里）"
            header-align="center"
          >
            <template #default="{ row }">
              <p style="text-align: center">
                {{ row.vehicleMonth }}
                <span v-if="row.vehicleKm || row.vehicleKm == 0">/</span>
                {{ row.vehicleKm }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="质保内容"
            header-align="center"
            prop="vehicleContent"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="三电质保" header-align="center">
          <el-table-column
            min-width="90"
            label="区间（月/公里）"
            header-align="center"
          >
            <template #default="{ row }">
              <p style="text-align: center">
                {{ row.threePowerMonth }}
                <span v-if="row.threePowerKm || row.threePowerKm == 0">/</span>
                {{ row.threePowerKm }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="质保内容"
            header-align="center"
            prop="threePowerContent"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="动力电池质保" header-align="center">
          <el-table-column
            min-width="90"
            label="区间（月/公里）"
            header-align="center"
          >
            <template #default="{ row }">
              <p style="text-align: center">
                {{ row.powerBatteryMonth }}
                <span v-if="row.powerBatteryKm || row.powerBatteryKm == 0"
                  >/</span
                >
                {{ row.powerBatteryKm }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="质保内容"
            header-align="center"
            prop="powerBatteryContent"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="电芯质保" header-align="center">
          <el-table-column
            min-width="90"
            label="区间（月/公里）"
            header-align="center"
          >
            <template #default="{ row }">
              <p style="text-align: center">
                {{ row.batteryMonth }}
                <span v-if="row.batteryKm || row.batteryKm == 0">/</span>
                {{ row.batteryKm }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="质保内容"
            header-align="center"
            prop="batteryContent"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="易损件质保" header-align="center">
          <el-table-column
            min-width="90"
            label="区间（月/公里）"
            header-align="center"
          >
            <template #default="{ row }">
              <p style="text-align: center">
                {{ row.wearingPartsMonth }}
                <span v-if="row.wearingPartsKm || row.wearingPartsKm == 0"
                  >/</span
                >
                {{ row.wearingPartsKm }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="质保内容"
            header-align="center"
            prop="wearingPartsContent"
          >
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 导出弹窗 -->
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="exportForm"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>

    <!-- 新增/编辑质保规则弹窗 -->
    <add-quality-rules
      v-if="addWarrantyDialog"
      :addWarrantyDialog.sync="addWarrantyDialog"
      :id="carId"
      @updateData="getData"
    >
    </add-quality-rules>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination';
import ExportDialog from '@/components/ExportDialog';
import addQualityRules from '@/views/systemManagement/warranty/addQualityRules.vue';
// 接口
import {
  carWarrantyRuleList,
  exportCarWarrantyRuleUrl,
} from '@/api/system/warrantyRule';

import { mapState, mapGetters, mapMutations } from 'vuex';

//模糊搜索接口
import { searchCarModel } from '@/api/customer/car';
// 字典数据
import { carBillType, dutyType, clientLevel } from '@/service/dict/dictData';
export default {
  name: 'warrantyRule',
  components: {
    pagination,
    ExportDialog,
    addQualityRules,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      form: {},

      //搜索车型信息列表字段
      searchModelLoading: false,
      carModelList: [],
      //选中车型
      carLogoName: '',
      carBrandName: '',
      carSeriesName: '',

      // 列表数据
      tableData: [],
      // 导出弹窗可见性
      showExportDialog: false,
      // 导出路径
      exportUrl: exportCarWarrantyRuleUrl,
      //新增/编辑质保弹窗可见性
      addWarrantyDialog: false,
      carId: '',
    };
  },
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.companyId,
    }),
    exportForm() {
      let merge = {
        intoStoreStartTime: this.form?.intoStoreDateRange?.[0] || '',
        intoStoreEndTime: this.form?.intoStoreDateRange?.[1] || '',
      };
      return Object.assign({}, this.form, merge);
    },
  },

  created() {
    this.getData();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 清除筛选表单
     */
    resetFilterForm() {
      this.form = {
        vehicleOwner: '',
        carModel: '',
      };
    },
    //远程搜索车型信息列表
    searchModel(key = '') {
      this.searchModelLoading = true;
      searchCarModel(key)
        .then((res) => {
          this.carModelList = res.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    selectCarModel(val) {
      this.carLogoName = val.logo.name;
      this.carBrandName = val.brand.name;
      this.carSeriesName = val.series.name;
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      let data = Object.assign(this.pageOption, this.form);
      carWarrantyRuleList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.tableData = res.records;
        this.rowspan(this.tableData);
      });
    },
    //编辑
    handleEdit(row) {
      this.carId = row.id;
      this.addWarrantyDialog = true;
    },
    //新增
    addWarranty() {
      this.carId = '';
      this.addWarrantyDialog = true;
    },
    //处理数据
    //表格中对数据进行处理
    rowspan(arr) {
      let billMap = {};
      let vehicleMap = {};
      let wearingPartsMap = {};
      let threePowerMap = {};
      let powerBatteryMap = {};
      let batteryMap = {};
      arr.forEach((item) => {
        if (billMap[item.id]) {
          billMap[item.id]++;
        } else {
          billMap[item.id] = 1;
        }
        if (vehicleMap[item.vehicleId]) {
          vehicleMap[item.vehicleId]++;
        } else {
          vehicleMap[item.vehicleId] = 1;
        }
        if (threePowerMap[item.threePowerId]) {
          threePowerMap[item.threePowerId]++;
        } else {
          threePowerMap[item.threePowerId] = 1;
        }
        if (powerBatteryMap[item.powerBatteryId]) {
          powerBatteryMap[item.powerBatteryId]++;
        } else {
          powerBatteryMap[item.powerBatteryId] = 1;
        }
        if (batteryMap[item.batteryId]) {
          batteryMap[item.batteryId]++;
        } else {
          batteryMap[item.batteryId] = 1;
        }
        if (wearingPartsMap[item.wearingPartsId]) {
          wearingPartsMap[item.wearingPartsId]++;
        } else {
          wearingPartsMap[item.wearingPartsId] = 1;
        }
        item.sequence = Object.keys(billMap).length;
      });

      arr.forEach((item, index) => {
        if (index === 0 || item.id != arr[index - 1].id) {
          item.billSpan = billMap[item.id];
        }
        if (index === 0 || item.vehicleId != arr[index - 1].vehicleId) {
          item.vehicleSpan = vehicleMap[item.vehicleId];
        }
        if (index === 0 || item.threePowerId != arr[index - 1].threePowerId) {
          item.threePowerSpan = threePowerMap[item.threePowerId];
        }
        if (
          index === 0 ||
          item.powerBatteryId != arr[index - 1].powerBatteryId
        ) {
          item.powerBatterySpan = powerBatteryMap[item.powerBatteryId];
        }
        if (index === 0 || item.batteryId != arr[index - 1].batteryId) {
          item.batterySpan = batteryMap[item.batteryId];
        }
        if (
          index === 0 ||
          item.wearingPartsId != arr[index - 1].wearingPartsId
        ) {
          item.wearingPartSpan = wearingPartsMap[item.wearingPartsId];
        }
      });

      // let table = arr.map((item) => ({
      //   id: item.id,
      //   billSpan: item.billSpan,
      //   vehicleId: item.vehicleId,
      //   wearingPartsId: item.wearingPartsId,
      //   vehicleSpan: item.vehicleSpan,
      //   wearingPartSpan: item.wearingPartSpan,
      // }));
      // console.table(table);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 2, 3].includes(columnIndex)) {
        return {
          rowspan: row.billSpan || 0, //行
          colspan: row.billSpan ? 1 : 0, //列
        };
      }
      if ([4, 5].includes(columnIndex)) {
        return {
          rowspan: row.vehicleSpan || 0, //行
          colspan: row.vehicleSpan ? 1 : 0, //列
        };
      }
      if ([6, 7].includes(columnIndex)) {
        return {
          rowspan: row.threePowerSpan || 0, //行
          colspan: row.threePowerSpan ? 1 : 0, //列
        };
      }
      if ([8, 9].includes(columnIndex)) {
        return {
          rowspan: row.powerBatterySpan || 0, //行
          colspan: row.powerBatterySpan ? 1 : 0, //列
        };
      }
      if ([10, 11].includes(columnIndex)) {
        return {
          rowspan: row.powerBatterySpan || 0, //行
          colspan: row.powerBatterySpan ? 1 : 0, //列
        };
      }
      if ([12, 13].includes(columnIndex)) {
        return {
          rowspan: row.wearingPartSpan || 0, //行
          colspan: row.wearingPartSpan ? 1 : 0, //列
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 隐藏滚动条
.custom-table ::v-deep .el-table__body-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
  // IE
  -ms-overflow-style: none;
}
</style>
