import http from '@/service/http';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 车单列表
export function getCarBillList(data = defaultListOption) {
  return http.post('/admin/car/bill/list', data);
}

// 新增车单
export function insertCarBill(data = {}) {
  return http.post('/admin/car/bill/save', data);
}

// 查询车单详情
export function getCarBillDetail(id) {
  return http.post('/admin/car/bill/info', { id });
}

// 编辑车单
export function updateCarBillDetail(data) {
  return http.post('/admin/car/bill/update', data);
}

// 搜索已有车辆
export function searchCarForCreateCarBill(data) {
  return http.post('/admin/car/bill/getClientMsgForBill', data, {
    noShowWaiting: true,
  });
}

/**
 * 检查车辆是否允许被接待
 * @param billType{number} 车单类型
 * @param carId{number} 车辆ID
 */
export function checkMakeCarBill(billType, carId) {
  return http.post('/admin/car/bill/checkMakeCarBill', { billType, carId });
}

/**
 * 30天内有重复进店维修开单进行弹窗提示
 * @param billType{number} 车单类型
 * @param carId{number} 车辆ID
 */
export function checkMakeCarBill30Day(billType, carId) {
  return http.post('/admin/car/bill/checkMakeCarBill30Day', {
    billType,
    carId,
  });
}

/**
 * 商品检测
 * 检测报价项目内的商品和材料单是否一致
 */
export function billGoodsEditCheck(data) {
  return http.post('/admin/car/bill/billGoodsEditCheck', data);
}

// 搜索他人自费客户
export function searchClientForCreateCarBill(data) {
  return http.post('/admin/car/bill/getClientMsg', data, {
    noShowWaiting: true,
  });
}

// 保养方案
export function getMaintenancePlan(data) {
  return http.post('/admin/maintenance/getMaintenanceInstructions', data, {
    noShowWaiting: true,
  });
}

// 材料单详情
export function getMaterialBillDetails(data) {
  return http.post('/admin/car/bill/materialBillDetails', data, {
    noShowWaiting: true,
  });
}

// 编辑材料单
export function editMaterialBill(data) {
  return http.post('/admin/car/bill/editMaterialBill', data);
}

// 服务项分类
export function getServicesClasses(companyId, config = {}) {
  return http.post('/admin/service/getCategory', { companyId }, config);
}

// 搜索服务列表
export function searchServiceForCreateCarBill(
  data,
  config = { noShowWaiting: true }
) {
  return http.post('/admin/service/queryPageList', data, config);
}

// 搜索服务列表 - 协议价
export function searchServiceWithAgreementPriceForCreateCarBill(
  data,
  config = { noShowWaiting: true }
) {
  return http.post('/admin/service/getBillServicePage', data, config);
}

// 商品分类
export function getGoodsClasses(companyId) {
  return http.post('/admin/good/getCategory', { companyId }, {});
}

// 搜索商品列表
export function searchGoodsForCreateCarBill(
  data,
  config = { noShowWaiting: true }
) {
  return http.post('/admin/good/searchGoodPageLists', data, config);
}

/**
 * 搜索商品列表-协议价
 */
export function searchGoodsWithAgreementPriceForCreateCarBill(
  data,
  config = { noShowWaiting: true }
) {
  return http.post('/admin/good/getBillGoodsPage', data, config);
}

//车单待结账状态
export function carBillCheckOut(data) {
  return http.post('/admin/car/bill/toCheck', data, { onlyReturnData: false });
}

//提交结账
export function getCarBillCheck(data) {
  return http.post('/admin/car/bill/check', data, { onlyReturnData: false });
}

//支付方式列表
export function getCarBillListPayType(data) {
  return http.post('/admin/car/bill/listPayType', data, {
    noShowWaiting: true,
  });
}
//服务中-更多操作-挂单
export function getCarBillNoServiceTake(data) {
  return http.post('/admin/car/bill/noServiceTake', data, {
    onlyReturnData: false,
  });
}
//服务中-更多操作-收定金
export function getCarBillDepositAdd(data) {
  return http.post('/admin/car/bill/deposit/add', data, {
    onlyReturnData: false,
  });
}
//服务中-更多操作-取消接待
export function getCarBillCancelReception(data) {
  return http.post('/admin/car/bill/cancelReception', data, {
    onlyReturnData: false,
  });
}
//待结账-更多操作-重新服务
export function getCarBillReturnService(data) {
  return http.post('/admin/car/bill/returnService', data, {
    onlyReturnData: false,
  });
}
//待结账-更多操作-提前提车
export function getCarBillServiceNoPaidTake(data) {
  return http.post('/admin/car/bill/serviceNoPaidTake', data, {
    onlyReturnData: false,
  });
}
//已结账-更多操作-反结算
export function getCarBillReverseSettlement(data) {
  return http.post('/admin/car/bill/reverseSettlement', data, {
    onlyReturnData: false,
  });
}

// 施工单 列表数据
export function getDispatchList(data) {
  return http.post('/admin/dispatch/list', data);
}

// 服务中车单提交施工
export function submitDispatchBill(billId) {
  return http.post('/admin/car/bill/submitDispatchBill', { billId });
}

// 施工单详情
export function getForDispatchDetailsById(data) {
  return http.post('/admin/dispatch/getForDispatchDetailsById', data);
}

// 施工单派工、领工操作
export function submitForDispatch(data) {
  return http.post('/admin/dispatch/submitForDispatch', data, {
    onlyReturnData: false,
  });
}

// 施工单 提交完工
export function submitCompletion(data) {
  return http.post('/admin/dispatch/submitCompletion', data, {
    onlyReturnData: false,
  });
}

// 施工单 提交改派
export function submitReassignment(data) {
  return http.post('/admin/dispatch/submitReassignment', data, {
    onlyReturnData: false,
  });
}

// 施工单 施工改派
export function constructionReassignment(data) {
  return http.post('/admin/dispatch/constructionReassignment', data, {
    onlyReturnData: false,
  });
}

// 施工单 完工质检
export function completionQualityInspection(data) {
  return http.post('/admin/dispatch/completionQualityInspection', data, {
    onlyReturnData: false,
  });
}

// 车单结账 - 异常物料
export function getCarBillMaterialInfo(data) {
  return http.post('/admin/car/bill/getCarBillMaterialInfo', data);
}

//判断车辆是否存在挂单状态
export function checkCarExistenceServiceTakeByCarId(data) {
  return http.post('/admin/car/bill/checkCarExistenceServiceTakeByCarId', data);
}

//检验锁单
export function getBillLockState(id) {
  return http.post(
    '/admin/car/bill/billLockState',
    { id },
    {
      onlyReturnData: false,
    }
  );
}

//施工单的施工班组统计
export function dispatchStatistics(data) {
  return http.post('/admin/dispatch/statistics', data);
}

//结算单批量打印
//未打印结算单
export function printNotPrint(data) {
  return http.post('/admin/print/notPrint', data);
}
//查询车单-结算单列表
export function getBatchBillPrintResults(data) {
  return http.post('/admin/car/bill/getBatchBillPrintResults', data);
}
//结算单打印详情
export function getBatchBillDetails(billIds, config = { noShowWaiting: true }) {
  return http.post('/admin/car/bill/getBatchBillDetails', { billIds }, config);
}
//新增批量打印结算单
export function printBatchPrintAdd(data, config = { noShowWaiting: true }) {
  return http.post('/admin/print/batchPrintAdd', data, config);
}
//打印完成
export function printPrintFinish(
  data,
  config = { noShowWaiting: true, onlyReturnData: false }
) {
  return http.post('/admin/print/printFinish', data, config);
}

//判断车单是否可以变更业务类型
export function checkCarBillUpdate(data) {
  return http.post('/admin/car/bill/checkCarBillUpdate', data);
}

//查询车辆质保情况
export function getCarQueryWarranty(data) {
  return http.post('/admin/car/queryWarranty', data);
}
//查询他店库存列表
export function goodOtherInventory(data) {
  return http.post('/admin/good/otherInventory', data);
}
//查询检车单处理状态
export function getCarDetectionDisposeState(billId) {
  return http.post('/admin/car/detection/getCarDetectionDisposeState', {
    billId,
  });
}
//查询检车单服务信息
export function getCarDetectionServiceByBillId(billId) {
  return http.post('/admin/car/detection/getCarDetectionServiceByBillId', {
    billId,
  });
}

//车单详情-车单流程列表
export function getBillFlowList(billId) {
  return http.post('/admin/billFlow/list', {
    billId,
  });
}
//车单详情-车单流程详情
export function getDetailsByLogId(id) {
  return http.post('/admin/billFlow/getDetailsByLogId', {
    id,
  });
}

//车单详情-审核报价
export function carBillLinkContent(id) {
  return http.post(
    '/admin/car/bill/linkContent',
    { id },
    { onlyReturnData: false }
  );
}

// 待结账状态下 编辑车单
export function editCarBillAtToCheck(data) {
  return http.post('/admin/car/bill/toCheckUpdate', data);
}

/**
 * 提车
 * @param {number} id 车单ID
 */
export function takeCarApi(id) {
  return http.post('/admin/car/bill/takeCar', { billId: id });
}

/**
 * 撤销提车
 * @param {number} id 车单ID
 */
export function revocationTakeCarApi(id) {
  return http.post('/admin/car/bill/revocationTakeCar', { billId: id });
}

/**
 * 检查报价 （提交结账操作前校验）
 * @param {number} id 车单ID
 */
export function checkQuotationApi(id) {
  return http.post('/admin/car/bill/checkQuotation', { id });
}

/**
 * 查询客户的结算方式（月结/日结）。
 *
 * @param {Object} data - 包含查询参数的对象。
 * @param {Number} data.clientId - 消费客户ID
 * @param {Number} data.billType - 单据类型
 * @param {Number} data.companyId - 门店ID
 * @returns {AxiosPromise<any>}
 */
export function getClientSettlementWay(data) {
  return http.post('/admin/client/getClientSettlementWay', data);
}
/**
 * 记录打印操作日志
 * @param {Object} data 打印操作日志
 * @returns 处理结果
 */
export function recordPrintLog(data) {
  return http.post('/admin/car/bill/recordPrintLog', data);
}

/**
 * 更新客户协议价
 */
export function getAgreementPriceByConsumer(data) {
  return http.post('/admin/car/bill/checkQuotationByConsumer', data);
}

/**
 * 查询车辆最近的车单
 */
export function findTracingBillByCarId(data) {
  return http.post('/admin/car/bill/findTracingBillByCarId', data);
}

/**
 * 根据车辆ID查询是否有生效的的主机厂索赔活动
 */
export function findCarFactoryCompensationActivityByCarId(data) {
  return http.post(
    '/admin/car/bill/findCarFactoryCompensationActivityByCarId',
    data
  );
}
