<template>
  <div class="container">
    <div class="header">
      <img
        class="header-title"
        src="./images/header-title.png"
        alt="门店运营指挥中心"
      />
      <img
        v-auth="536"
        class="eye"
        :src="
          hideNumber
            ? require('./images/icon_eye_close.png')
            : require('./images/icon_eye_open.png')
        "
        @click="hideNumber = !hideNumber"
      />
      <div class="time">当前时间：{{ time }}</div>
    </div>
    <div class="middle">
      <div class="side">
        <div class="left-top">
          <div class="title">经营数据</div>
          <div class="manage-data">
            <div class="row">
              <div class="top">
                <img src="./images/icon_fuwutaici@2x.png" alt="" />
                <div class="text">
                  <div class="transition turnover data-number">
                    {{ manageData.intoNumOfYear | toThousands(0) }}
                  </div>
                  <div class="label">本年服务（台次）</div>
                </div>
              </div>
              <div class="top">
                <img src="./images/icon_bennianleiji@2x.png" alt="" />
                <div class="text">
                  <div class="transition turnover data-number">
                    <template v-if="!hideNumber">
                      {{ manageData.turnoverOfYear | toThousands(0) }}
                    </template>
                    <template v-else>{{ hideNumberText }}</template>
                  </div>
                  <div class="label">本年累计（元）</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="box">
                <div class="label">本月</div>
                <div class="number">
                  <span class="transition data-number">{{
                    manageData.intoNumOfMonth | toThousands(0)
                  }}</span>
                  <span>台次</span>
                </div>
                <div class="money">
                  <span class="transition data-number">
                    <template v-if="!hideNumber">
                      ￥{{ manageData.turnoverOfMonth | toThousands(0) }}
                    </template>
                    <template v-else>{{ hideNumberText }}</template>
                  </span>
                </div>
              </div>
              <div class="box">
                <div class="label">本日</div>
                <div class="number">
                  <span class="transition data-number">{{
                    manageData.intoNumOfDay | toThousands(0)
                  }}</span>
                  <span>台次</span>
                </div>
                <div class="transition money data-number">
                  <template v-if="!hideNumber"
                    >￥{{ manageData.turnoverOfDay | toThousands(0) }}</template
                  >
                  <template v-else>{{ hideNumberText }}</template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="left-bottom">
          <div class="title">服务品牌</div>
          <div class="service-brand">
            <div id="service-brand-chart"></div>
            <div class="service-brand-list">
              <div class="row">
                <div class="service-brand-item first">
                  <div class="left-info">
                    <div class="square"></div>
                    <div class="brand-name" :title="serviceBrandData[0].name">
                      {{ serviceBrandData[0].name }}
                    </div>
                  </div>
                  <div class="number">
                    <span class="data-number">
                      <template v-if="!hideNumber">
                        {{ serviceBrandData[0].carNum | toThousands(0) }}
                      </template>
                      <template v-else>{{ hideNumberText }}</template>
                    </span>
                    台
                  </div>
                </div>
                <div class="service-brand-item second">
                  <div class="left-info">
                    <div class="square"></div>
                    <div class="brand-name" :title="serviceBrandData[1].name">
                      {{ serviceBrandData[1].name }}
                    </div>
                  </div>
                  <div class="number">
                    <span class="data-number">
                      <template v-if="!hideNumber">
                        {{ serviceBrandData[1].carNum | toThousands(0) }}
                      </template>
                      <template v-else>{{ hideNumberText }}</template>
                    </span>
                    台
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="service-brand-item third">
                  <div class="left-info">
                    <div class="square"></div>
                    <div class="brand-name" :title="serviceBrandData[2].name">
                      {{ serviceBrandData[2].name }}
                    </div>
                  </div>
                  <div class="number">
                    <span class="data-number">
                      <template v-if="!hideNumber">
                        {{ serviceBrandData[2].carNum | toThousands(0) }}
                      </template>
                      <template v-else>{{ hideNumberText }}</template>
                    </span>
                    台
                  </div>
                </div>
                <div class="service-brand-item fourth">
                  <div class="left-info">
                    <div class="square"></div>
                    <div class="brand-name" :title="serviceBrandData[3].name">
                      {{ serviceBrandData[3].name }}
                    </div>
                  </div>
                  <div class="number">
                    <span class="data-number">
                      <template v-if="!hideNumber">
                        {{ serviceBrandData[3].carNum | toThousands(0) }}
                      </template>
                      <template v-else>{{ hideNumberText }}</template>
                    </span>
                    台
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <!-- <mapChart
          @pause="pauseInterval"
          @continue="continueInterval"
        ></mapChart> -->
        <AMap ref="AMap"></AMap>
      </div>
      <div class="side">
        <div class="right-top">
          <div class="title">本月经营</div>
          <div class="button-list">
            <div
              :class="{ 'button-item': true, active: showType == 1 }"
              @click="showType = 1"
            >
              营业额
            </div>
            <div
              :class="{ 'button-item': true, active: showType == 2 }"
              @click="showType = 2"
            >
              毛利率
            </div>
          </div>
          <div v-show="showType == 1" class="current-month-target">
            <div class="left">
              <div id="current-month-target-chart"></div>
              <div class="target-text">
                目标额：
                <span class="data-number">
                  <template v-if="!hideNumber">
                    {{
                      (currentMonthTargetData.totalTarget / 1e4)
                        | toThousands(0)
                    }}
                  </template>
                  <template v-else>{{ hideNumberText }}</template>
                </span>
                万
              </div>
            </div>
            <div class="right">
              <div class="target-list">
                <div class="target-item">
                  <div>自费</div>
                  <div class="money data-number">
                    <template v-if="!hideNumber"
                      >￥
                      {{
                        currentMonthTargetData.selfPayTurnover | toThousands(0)
                      }}
                    </template>
                    <template v-else>{{ hideNumberText }}</template>
                  </div>
                  <div class="data-number">
                    {{
                      currentMonthTargetData.selfPayPercentageComplete
                        | toThousands(0)
                    }}%
                  </div>
                </div>
                <div class="target-item">
                  <div>包干</div>
                  <div class="money data-number">
                    <template v-if="!hideNumber"
                      >￥
                      {{
                        currentMonthTargetData.contractForTurnover
                          | toThousands(0)
                      }}
                    </template>
                    <template v-else>{{ hideNumberText }}</template>
                  </div>
                  <div class="data-number">
                    {{
                      currentMonthTargetData.contractForPercentageComplete
                        | toThousands(0)
                    }}%
                  </div>
                </div>
                <div class="target-item">
                  <div>理赔</div>
                  <div class="money data-number">
                    <template v-if="!hideNumber"
                      >￥
                      {{
                        currentMonthTargetData.claimSettlementTurnover
                          | toThousands(0)
                      }}
                    </template>
                    <template v-else>{{ hideNumberText }}</template>
                  </div>
                  <div class="data-number">
                    {{
                      currentMonthTargetData.claimSettlementPercentageComplete
                        | toThousands(0)
                    }}%
                  </div>
                </div>
                <div class="target-item">
                  <div>索赔</div>
                  <div class="money data-number">
                    <template v-if="!hideNumber"
                      >￥
                      {{
                        currentMonthTargetData.claimForCompensationTurnover
                          | toThousands(0)
                      }}
                    </template>
                    <template v-else>{{ hideNumberText }}</template>
                  </div>
                  <div class="data-number">
                    {{
                      currentMonthTargetData.claimForCompensationPercentageComplete
                        | toThousands(0)
                    }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="showType == 2" class="radar-chart-box">
            <div id="radar-chart"></div>
          </div>
        </div>
        <div class="right-bottom">
          <div class="title">
            {{ showType === 1 ? '门店排行榜' : '门店明细' }}
          </div>
          <div v-show="showType == 1" class="company-ranked-box">
            <!-- 图例 -->
            <div class="types">
              <div
                class="type-item"
                v-for="(item, index) in companyRankedLegend"
                :key="index"
              >
                <div
                  class="circle"
                  :style="{ backgroundColor: item.color }"
                ></div>
                <div class="name">{{ item.name }}</div>
              </div>
            </div>
            <!-- 排行 -->
            <div class="company-ranked-list">
              <div
                class="company-ranked-item"
                v-for="(item, index) in activatedCompanyList"
                :key="index"
              >
                <div class="rank data-number">{{ item.index }}</div>
                <div class="part2">
                  <div class="company-name" :title="item.companyName">
                    {{ item.companyName }}
                  </div>
                  <div class="waring">
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[0].color,
                        opacity: item.selfPayTurnoverWarning ? 1 : 0,
                      }"
                    ></div>
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[1].color,
                        opacity: item.contractForTurnoverWarning ? 1 : 0,
                      }"
                    ></div>
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[2].color,
                        opacity: item.claimSettlementTurnoverWarning ? 1 : 0,
                      }"
                    ></div>
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[3].color,
                        opacity: item.claimForCompensationTurnoverWarning
                          ? 1
                          : 0,
                      }"
                    ></div>
                  </div>
                </div>
                <div class="progress-box">
                  <div
                    class="total-progress"
                    :style="{
                      width:
                        item.totalPercentageComplete > 100
                          ? '100%'
                          : item.totalPercentageComplete + '%',
                    }"
                  >
                    <span
                      v-if="item.totalPercentageComplete"
                      :class="{
                        'number-progress': true,
                        'data-number': true,
                        warning: item.totalTurnoverWarning,
                      }"
                    >
                      {{ Math.ceil(item.totalPercentageComplete) }}%
                    </span>
                  </div>
                  <div class="progress-types">
                    <div class="type1">
                      <div
                        class="type1-progress"
                        :style="{
                          width:
                            item.selfPayPercentageComplete > 100
                              ? '100'
                              : item.selfPayPercentageComplete + '%',
                        }"
                        :title="item.selfPayPercentageComplete + '%'"
                      ></div>
                    </div>
                    <div class="type2">
                      <div
                        class="type2-progress"
                        :style="{
                          width:
                            item.contractForPercentageComplete > 100
                              ? '100'
                              : item.contractForPercentageComplete + '%',
                        }"
                        :title="item.contractForPercentageComplete + '%'"
                      ></div>
                    </div>
                    <div class="type3">
                      <div
                        class="type3-progress"
                        :style="{
                          width:
                            item.claimSettlementPercentageComplete > 100
                              ? '100'
                              : item.claimSettlementPercentageComplete + '%',
                        }"
                        :title="item.claimSettlementPercentageComplete + '%'"
                      ></div>
                    </div>
                    <div class="type4">
                      <div
                        class="type4-progress"
                        :style="{
                          width:
                            item.claimForCompensationPercentageComplete > 100
                              ? '100'
                              : item.claimForCompensationPercentageComplete +
                                '%',
                        }"
                        :title="
                          item.claimForCompensationPercentageComplete + '%'
                        "
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="company-number">
                  <span class="data-number">
                    <template v-if="!hideNumber">
                      {{ item.totalTarget_changed }}
                    </template>
                    <template v-else>{{ hideNumberText }}</template>
                  </span>
                  万
                </div>
              </div>
            </div>
          </div>
          <div v-show="showType == 2" class="company-ranked-box">
            <!-- 图例 -->
            <div class="types">
              <div
                class="type-item"
                v-for="(item, index) in companyRankedLegend"
                :key="index"
              >
                <div
                  class="circle"
                  :style="{ backgroundColor: item.color }"
                ></div>
                <div class="name">{{ item.name }}</div>
              </div>
            </div>
            <!-- 排行 -->
            <div class="company-ranked-list">
              <div
                class="company-ranked-item"
                v-for="(item, index) in activeGrossMarginCompanyList"
                :key="index"
              >
                <div class="rank data-number">{{ item.index }}</div>
                <div class="part2">
                  <div class="company-name" :title="item.companyName">
                    {{ item.companyName }}
                  </div>
                  <div class="waring">
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[0].color,
                        opacity: item.selfPayGrossProfitRateWarning ? 1 : 0,
                      }"
                    ></div>
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[1].color,
                        opacity: item.contractForGrossProfitRateWarning ? 1 : 0,
                      }"
                    ></div>
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[2].color,
                        opacity: item.claimSettlementGrossProfitRateWarning
                          ? 1
                          : 0,
                      }"
                    ></div>
                    <div
                      class="circle"
                      :style="{
                        backgroundColor: companyRankedLegend[3].color,
                        opacity: item.claimForCompensationGrossProfitRateWarning
                          ? 1
                          : 0,
                      }"
                    ></div>
                  </div>
                </div>
                <div class="progress-box">
                  <div
                    class="total-progress"
                    :style="{
                      width:
                        item.totalGrossProfitRate > 100
                          ? '100%'
                          : item.totalGrossProfitRate + '%',
                    }"
                  ></div>
                  <div class="progress-types">
                    <div class="type1">
                      <div
                        class="type1-progress"
                        :style="{
                          width:
                            item.selfPayGrossProfitRate > 100
                              ? '100'
                              : item.selfPayGrossProfitRate + '%',
                        }"
                        :title="item.selfPayGrossProfitRate + '%'"
                      ></div>
                    </div>
                    <div class="type2">
                      <div
                        class="type2-progress"
                        :style="{
                          width:
                            item.contractForGrossProfitRate > 100
                              ? '100'
                              : item.contractForGrossProfitRate + '%',
                        }"
                        :title="item.contractForGrossProfitRate + '%'"
                      ></div>
                    </div>
                    <div class="type3">
                      <div
                        class="type3-progress"
                        :style="{
                          width:
                            item.claimSettlementGrossProfitRate > 100
                              ? '100'
                              : item.claimSettlementGrossProfitRate + '%',
                        }"
                        :title="item.claimSettlementGrossProfitRate + '%'"
                      ></div>
                    </div>
                    <div class="type4">
                      <div
                        class="type4-progress"
                        :style="{
                          width:
                            item.claimForCompensationGrossProfitRate > 100
                              ? '100'
                              : item.claimForCompensationGrossProfitRate + '%',
                        }"
                        :title="item.claimForCompensationGrossProfitRate + '%'"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="company-number">
                  <span
                    :class="{
                      'data-number': true,
                      warning: item.totalGrossProfitRateWarning,
                    }"
                  >
                    {{ item.totalGrossProfitRate }}%
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-show="showType == 2" class="company-detail-box">
            <div class="company-name">
              {{ grossMarginCompanyData.companyName }}
            </div>
            <div id="company-detail-chart"></div>
            <div class="types">
              <div class="type-item">
                <div class="circle type1"></div>
                <div class="name">自费</div>
                <div
                  :class="{
                    rate: true,
                    'data-number': true,
                    warning: grossMarginCompanyData.selfPayTurnoverWarning,
                  }"
                >
                  {{
                    grossMarginCompanyData.selfPayGrossProfitRate | toThousands
                  }}%
                </div>
              </div>
              <div class="type-item">
                <div class="circle type2"></div>
                <div class="name">包干</div>
                <div
                  :class="{
                    rate: true,
                    'data-number': true,
                    warning: grossMarginCompanyData.contractForTurnoverWarning,
                  }"
                >
                  {{
                    grossMarginCompanyData.contractForGrossProfitRate
                      | toThousands
                  }}%
                </div>
              </div>
            </div>
            <div class="types">
              <div class="type-item">
                <div class="circle type3"></div>
                <div class="name">理赔</div>
                <div
                  :class="{
                    rate: true,
                    'data-number': true,
                    warning:
                      grossMarginCompanyData.claimSettlementTurnoverWarning,
                  }"
                >
                  {{
                    grossMarginCompanyData.claimSettlementGrossProfitRate
                      | toThousands
                  }}%
                </div>
              </div>
              <div class="type-item">
                <div class="circle type4"></div>
                <div class="name">索赔</div>
                <div
                  :class="{
                    rate: true,
                    'data-number': true,
                    warning:
                      grossMarginCompanyData.claimForCompensationTurnoverWarning,
                  }"
                >
                  {{
                    grossMarginCompanyData.claimForCompensationGrossProfitRate
                      | toThousands
                  }}%
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="title">
          事故进场
          <!-- <img
            v-auth="536"
            class="eye"
            :src="
              hideChartNumber
                ? require('./images/icon_eye_close.png')
                : require('./images/icon_eye_open.png')
            "
            @click="hideChartNumber = !hideChartNumber"
          /> -->
        </div>
        <div id="accident-repair-statistic"></div>
      </div>
    </div>

    <!-- 隐藏内容 用于显示全屏 -->
    <div class="full-screen" @click="handleFullScreen"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
// echarts 水波图
// import 'echarts-liquidfill';

import { mapState } from 'vuex';
import { toThousands } from '@/utils/filters/number';
import { verifyAuth } from '@/utils/permissions';
// 组件
// import mapChart from '@/views/map/map-chart.vue';
// 接口
import {
  getManageData,
  getServiceBrand,
  getCurrentMonthTarget,
  getCompanyCountryDistribution,
  getCompanyRanked,
  getCurrentMonthGrossMargin,
  getAccidentIntoFactory,
  getAllCompanyGrossMargin,
} from '@/api/commandCenter';

import WebsocketMixin from '@/service/websocket/WebsocketMixin';

export default {
  name: 'systemData',
  components: {
    AMap: () => import(/* webpackChunkName: "AMap" */ './AMap.vue'),
    // mapChart: () =>
    //   import(/* webpackChunkName: "map-chart" */ '@/views/map/map-chart.vue'),
  },
  mixins: [WebsocketMixin],
  data() {
    return {
      // 时间
      time: '',
      // 时间定时器
      timeInterval: null,

      // 门店分布数据
      companyProvinceDistributionData: [],

      // 本月目标图表
      currentMonthTargetChart: null,
      // 本月目标数据
      currentMonthTargetData: {
        // 总目标
        totalTarget: '',
        // 已完成
        totalTurnover: '',

        // 自费目标
        selfPayTarget: '',
        // 自费营业额
        selfPayTurnover: '',
        // 包干目标
        contractForTarget: '',
        // 包干营业额
        contractForTurnover: '',
        // 理赔目标
        claimSettlementTarget: '',
        // 理赔营业额
        claimSettlementTurnover: '',
        // 索赔目标
        claimForCompensationTarget: '',
        // 索赔营业额
        claimForCompensationTurnover: '',
      },

      // 门店排行榜数据
      companyRankedData: [],
      // 门店排行榜图例
      companyRankedLegend: [
        { name: '自费', color: '#EA8431' },
        { name: '包干', color: '#40F0A7' },
        { name: '理赔', color: '#1685FF' },
        { name: '索赔', color: '#FFDD3D' },
      ],

      // 服务品牌图表
      serviceBrandChart: null,
      // 服务品牌数据
      serviceBrandData: [{}, {}, {}, {}],

      // 本月营业额毛利率数据
      currentMonthGrossMarginData: {},
      currentMonthGrossMarginChart: null,

      // 事件进场图表
      accidentRepairStatisticChart: null,
      // 事故进场数据
      accidentRepairData: [],

      // 经营数据
      manageData: {},

      // 门店排行榜分页
      rankPage: 0,
      // 门店排行榜分页定时器
      companyRankInterval: null,

      // 门店毛利率明细图表
      // companyDetailChart: null,

      // 门店毛利率明细排行数据
      grossMarginRankData: [],
      // 门店毛利率明细排行页码
      grossMarginRankPage: 0,
      // 门店毛利率明细排行序号定时器
      grossMarginRankInterval: null,

      // 显示类型 1: 营业额 2: 毛利率
      showType: 1,

      // 隐藏具体数字
      hideNumber: true,
      // 隐藏具体数字代替内容
      hideNumberText: '******',

      // 隐藏图表数字
      // hideChartNumber: true,

      // 防抖函数
      debounce: null,

      // 重置数据定时器
      resetDataInterval: null,

      // 全屏 点击次数
      clickCount: 0,
    };
  },
  provide: function () {
    return {
      getMarkData: () => this.companyProvinceDistributionData,
      getActiveStoreList: () =>
        this.showType === 1
          ? this.activatedCompanyList
          : this.activeGrossMarginCompanyList,
    };
  },
  watch: {
    showType(val) {
      this.$nextTick().then(() => {
        this.handleResize(val);
        if (val === 1) {
          this.resetCompanyRankInterval();
          clearInterval(this.grossMarginRankInterval);
        } else {
          this.resetGrossMarginRankIndexInterval();
          clearInterval(this.companyRankInterval);
        }
      });
    },
    // hideChartNumber(val) {
    //   this.drawAccidentRepairStatisticChart(this.accidentRepairData, val);
    // },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    // 门店排行榜分页数据
    activatedCompanyList() {
      var start = this.rankPage * 5;
      var end =
        start + 5 > this.companyRankedData.length
          ? this.companyRankedData.length
          : start + 5;
      var result = this.companyRankedData.slice(start, end);
      return result || [];
    },
    // 门店毛利率排行榜分页数据
    activeGrossMarginCompanyList() {
      // return this.grossMarginRankData[this.grossMarginRankPage] || {};
      var start = this.grossMarginRankPage * 5;
      var end =
        start + 5 > this.grossMarginRankData.length
          ? this.grossMarginRankData.length
          : start + 5;
      var result = this.grossMarginRankData.slice(start, end);
      return result || [];
    },
  },
  async mounted() {
    // 检测浏览器标识是否是 IE
    var isIE = /Trident\/|MSIE/.test(window.navigator.userAgent);

    // 如果是 IE，则在 HTML 标签上添加一个类名
    if (isIE) {
      document.documentElement.classList.add('is-ie');
    }

    // 手动调用自适应方法
    this.handleResize();

    if (!this.companyId) {
      let vuexData = localStorage.getItem('vuexData');
      // console.log(vuexData);
      try {
        let obj = JSON.parse(vuexData);
        this.$store.replaceState(Object.assign({}, this.$store.state, obj));
        localStorage.removeItem('vuexData');
      } catch (error) {
        console.log(error);
      }
      // await this.listenerMessage();
      // this.companyId =
      //   +this.$store.state.user?.userInfo?.companyId ||
      //   +localStorage.getItem('companyId');
    }

    // 开始执行时间定时器
    this.startTimeInterval();

    this.debounce = this.$lodash.debounce(this.handleResize, 300);
    // 添加resize事件
    window.addEventListener('resize', this.debounce);

    // 初始化数据
    this.initData();

    this.resetDataInterval = setInterval(this.initData, 1000 * 60 * 10);

    try {
      this.hideNumber = !(await verifyAuth(536, false));
      //  this.hideChartNumber =
    } catch (error) {
      console.log(error);
    }

    window.localStorage.setItem('deviceId', 'web-mapper');
    this.initWebsocket();
  },
  methods: {
    // websocket 收到消息
    handleMessageReceived(message) {
      console.log('收到消息  ws:', message);
      if (message.action === '2') {
        this.getManageData();
      } else if (message.action == 'todoMsg') {
        this.$bus.$emit('todoMsgReceivedEvent', message);
      }
    },

    // listenerMessage() {
    //   return new Promise((resolve, reject) => {
    //     window.addEventListener(
    //       'message',
    //       this.handleMessage.bind(this, resolve)
    //     );
    //   });
    // },
    // handleMessage(resolve, event) {
    //   if (event.data.type === 'vuexData') {
    //     this.$store.replaceState(
    //       Object.assign({}, this.$store.state, JSON.parse(event.data.data))
    //     );
    //     resolve();
    //     window.removeEventListener('message', this.handleMessage);
    //   }
    // },
    /**
     * 初始化数据
     */
    initData() {
      this.getManageData();
      this.getServiceBrandData();
      this.getCurrentMonthTargetData();
      this.getCompanyCountryDistributionData();
      this.getAccidentRepairData();
      this.getCompanyRankedData();
      this.getCurrentMonthGrossMarginData();
      this.getCompanyDetailData();

      this.$refs.AMap?.refresh();
    },

    /**
     * 获取经营数据
     */
    getManageData() {
      getManageData({
        id: this.companyId,
      }).then((res) => {
        function setTransition(targetObj, originObj, key) {
          console.log(key);
          console.log('对比: ', key, targetObj[key], originObj[key]);
          if (targetObj[key] < originObj[key]) {
            console.log('变化: ', key, targetObj[key], originObj[key]);
            // 递增值，默认为1
            let change = 1;
            // 判断变化量需要更新完成的时间是否>5s
            let number = originObj[key] - targetObj[key];
            if (number > 500) {
              change = Math.ceil(number / 500);
            }
            // 使用定时器进行累加操作
            let timer = setInterval(() => {
              targetObj[key] += change;
              if (targetObj[key] >= originObj[key]) {
                targetObj[key] = originObj[key];
                clearInterval(timer);
              }
            }, 10);
          } else {
            // 值变小直接修改，无累加动效
            targetObj[key] = originObj[key];
          }
        }
        // 如果是刷新数据，添加过渡效果
        // 判断this.manageData对象是否是空对象

        if (Object.keys(this.manageData).length) {
          var keys = [
            'intoNumOfYear', // 本年进场台次
            'turnoverOfYear', // 本年营业额
            'intoNumOfMonth', // 本月进场台次
            'turnoverOfMonth', // 本月营业额
            'intoNumOfDay', // 本日进场台次
            'turnoverOfDay', // 本日营业额
          ];
          keys.forEach((key) => {
            setTransition(this.manageData, res, key);
          });
        } else {
          this.manageData = res;
        }
      });
    },

    /**
     * 获取本月营业额毛利率数据
     */
    getCurrentMonthGrossMarginData() {
      getCurrentMonthGrossMargin({
        id: this.companyId,
      }).then((res) => {
        this.currentMonthGrossMarginData = res;

        // 字段key顺序
        var keyArr = [
          ['selfPayGrossProfitRate', 'selfPayGrossProfitRateWarning'],
          ['contractForGrossProfitRate', 'contractForGrossProfitRateWarning'],
          [
            'claimSettlementGrossProfitRate',
            'claimSettlementGrossProfitRateWarning',
          ],
          [
            'claimForCompensationGrossProfitRate',
            'claimForCompensationGrossProfitRateWarning',
          ],
          ['totalGrossProfitRate', 'totalGrossProfitRateWarning'],
        ];
        // 标准值
        var standard = keyArr.map((item) => {
          let value =
            this.currentMonthGrossMarginData?.grossMarginStandardVo?.[item[0]];
          return { label: value, value: Math.max(Math.min(100, value), 0) };
        });

        // 实际值
        var practical = keyArr.map((item) => {
          let value =
            this.currentMonthGrossMarginData?.companyGrossMarginVo?.[item[0]];
          let waring =
            this.currentMonthGrossMarginData?.companyGrossMarginVo?.[item[1]];
          return {
            label: value,
            value: Math.max(Math.min(100, value), 0),
            waring,
          };
        });

        this.currentMonthGrossMarginChart = echarts.init(
          document.getElementById('radar-chart')
        );
        var option = {
          // grid: {
          //   left: '2%',
          //   right: '2%',
          //   top: '5%',
          //   bottom: '5%',
          //   containLabel: true,
          // },
          color: ['#7AF6FF', '#FFDD3D'],
          legend: {
            right: '5%',
            bottom: '15%',
            icon: 'circle',
            orient: 'vertical',
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              color: '#31C1EA',
              fontSize: '0.8rem',
            },
            data: [
              {
                name: '标准值',
                // icon: `image://${require('./images/legend/standard.png')}`,
              },
              {
                name: '实际值',
                // icon: `image://${require('./images/legend/practical.png')}`,
              },
            ],
          },
          radar: [
            {
              indicator: ['自费', '包干', '理赔', '索赔', '合计'].map(
                (item, i) => {
                  return {
                    text: `${item} ${practical[i].label || 0}%`,
                    max: 100,
                    color: practical[i].waring ? '#F22727' : '#31C1EA',
                  };
                }
              ),
              center: ['45%', '55%'],
              radius: '75%',
              startAngle: 90,
              splitNumber: 5,
              axisName: {
                formatter: '{value}',
                color: '#7AF6FF',
                fontSize: '0.8rem',
                fontFamily: 'MiSans',
              },
              splitArea: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: 'rgba(122,246,255, 0.4)',
                },
              },
              splitLine: {
                lineStyle: {
                  color: 'rgba(122,246,255, 0.4)',
                },
              },
            },
          ],
          series: [
            {
              type: 'radar',
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
              data: [
                {
                  value: standard.map((item) => item.value),
                  name: '标准值',
                  areaStyle: {
                    color: 'rgba(255,221,61, 0.2)',
                  },
                },
                {
                  value: practical.map((item) => item.value),
                  name: '实际值',
                  areaStyle: {
                    color: 'rgba(122,246,255, 0.2)',
                  },
                },
              ],
            },
          ],
        };
        this.currentMonthGrossMarginChart.setOption(option);
        this.currentMonthGrossMarginChart.resize();
      });
    },

    /**
     * 获取服务品牌数据
     */
    getServiceBrandData() {
      getServiceBrand({
        id: this.companyId,
      }).then((res) => {
        var colors = ['#4091FF', '#766EF5', '#5DC6FF', '#7FA5FF'];
        var data = (this.serviceBrandData = res.map((item, index) =>
          Object.assign(
            {},
            item,
            { value: item.carPercentage, name: item.carLogoName },
            { label: { color: colors[index] } }
          )
        ));

        this.serviceBrandChart = echarts.init(
          document.getElementById('service-brand-chart')
        );
        var option = {
          // tooltip: {
          //   trigger: 'item',
          // },
          // legend: {
          //   top: '5%',
          //   left: 'center',
          // },
          series: [
            {
              name: 'Service Brand',
              type: 'pie',
              radius: ['70%', '90%'],
              avoidLabelOverlap: false,
              top: '10%',
              color: colors,
              label: {
                show: true,
                position: 'outside',
                formatter(param) {
                  // console.log(param);
                  // correct the percentage
                  return param.percent + '%';
                },
                fontSize: '0.8rem',
                fontFamily: 'MiSans',
                // color: '#4091FF',
              },
              labelLine: {
                show: true,
                // length: 20,
                // length2: 15,
                // // minTurnAngle: 90,
                // lineStyle: {
                //   width: 2,
                //   // color: '#2d5e9e',
                //   // shadowColor: '#143370',
                //   // shadowOffsetX: 2,
                //   // shadowOffsetY: 2,
                //   // opacity: 0.7,
                // },
              },
              // emphasis: {
              //   label: {
              //     show: false,
              //   },
              // },

              data,
            },
          ],
        };
        this.serviceBrandChart.setOption(option);
        this.serviceBrandChart.resize();
      });
    },

    /**
     * 获取门店排名数据
     */
    getCompanyRankedData() {
      getCompanyRanked({
        companyId: this.companyId,
        statisticsPeriod: new Date().Format('yyyy/MM'),
      }).then((res) => {
        res.forEach((item, index) => {
          item.index = index + 1;
          item.totalTarget_changed = toThousands(
            Math.round(item.totalTarget / 10000),
            0
          );
        });
        this.companyRankedData = res;
        this.resetCompanyRankInterval();
      });
    },

    /**
     * 获取门店详情数据
     */
    getCompanyDetailData() {
      getAllCompanyGrossMargin({
        companyId: this.companyId,
        statisticsPeriod: new Date().Format('yyyy/MM'),
      }).then((res) => {
        res.forEach((item, index) => {
          item.index = index + 1;
        });

        this.grossMarginRankData = res;

        // this.companyDetailChart = echarts.init(
        //   document.getElementById('company-detail-chart')
        // );
        // this.drawGrossMarginChart(this.grossMarginCompanyData);
      });
    },

    /**
     * 绘制门店毛利率详情图表
     */
    // drawGrossMarginChart(data) {
    //   var option = {
    //     series: [
    //       {
    //         type: 'liquidFill',
    //         data: [(data.totalGrossProfitRate / 100).toFixed(2)],
    //         center: ['50%', '50%'],
    //         radius: '90%',
    //         color: [
    //           {
    //             type: 'linear',
    //             x: 0,
    //             y: 0,
    //             x2: 0,
    //             y2: 1,
    //             colorStops: [
    //               {
    //                 offset: 0,
    //                 color: '#7AF6FF', // 0% 处的颜色
    //               },
    //               {
    //                 offset: 1,
    //                 color: '#294FB2', // 100% 处的颜色
    //               },
    //             ],
    //             global: false, // 缺省为 false
    //           },
    //         ],
    //         outline: {
    //           show: true,
    //           borderDistance: 0,
    //           itemStyle: {
    //             color: 'none',
    //             borderColor: '#31C1EA',
    //             borderWidth: 4,
    //             shadowBlur: 20,
    //             shadowColor: 'rgba(0, 0, 0, 0.25)',
    //           },
    //         },
    //         backgroundStyle: {
    //           color: '#09184b',
    //         },
    //         label: {
    //           // formatter: '{a}\n{b}\nValue: {c}',
    //           fontSize: '1.5rem',
    //           insideColor: '#051035',
    //           fontFamily: 'MiSans',
    //         },
    //       },
    //     ],
    //   };
    //   this.companyDetailChart.setOption(option);
    //   this.companyDetailChart.resize();
    // },

    /**
     * 获取本月目标数据
     */
    getCurrentMonthTargetData() {
      getCurrentMonthTarget({ id: this.companyId }).then((res) => {
        this.currentMonthTargetData = res;
        var chartDom = document.getElementById('current-month-target-chart');
        this.currentMonthTargetChart = echarts.init(chartDom);

        var value = this.currentMonthTargetData.totalPercentageComplete; //当前进度
        var maxValue = 100; //进度条最大值
        //指定图标的配置项和数据
        var option = {
          top: '40%',
          left: 'center',
          //饼图中间显示文字
          graphic: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                text: value + '%', //文字内容
                fill: '#6ee5ff', //文字颜色
                fontSize: '1.5rem', //文字字号
                fontWeight: '600',
                fontFamily: 'MiSans',
              },
            },
            // {
            //   type: 'text',
            //   left: 'center',
            //   top: '45%',
            //   style: {
            //     text: toThousands(data.totalTurnover / 1e4, 0) + '万', //文字内容
            //     fill: '#6ee5ff', //文字颜色
            //     fontSize: '16', //文字字号
            //     fontWeight: '600',
            //   },
            // },
            // {
            //   type: 'text',
            //   left: 'center',
            //   top: '58%',
            //   style: {
            //     text: '已完成:' + value + '%', //文字内容
            //     fill: '#48a4e2', //文字颜色
            //     fontSize: '12', //文字字号
            //     fontWeight: '300',
            //   },
            // },
          ],

          series: [
            {
              //第一张圆环
              name: '本月目标',
              type: 'pie',
              radius: ['75%', '90%'],
              center: ['50%', '50%'],
              // 隐藏指示线
              labelLine: { show: false },
              //隐藏圆环上文字
              label: {
                show: false,
              },
              data: [
                //value当前进度 + 颜色
                {
                  name: '完成',
                  value: value,
                  itemStyle: {
                    normal: {
                      color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                          {
                            offset: 0,
                            color: '#294FB2', // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: '#7AF6FF', // 100% 处的颜色
                          },
                        ],
                        global: false, // 缺省为 false
                      },
                    },
                  },
                },
                {
                  name: '未完成',
                  value: maxValue - value,
                  itemStyle: {
                    normal: { color: '#051035' },
                  },
                },
              ],
            },
          ],
        };
        //使用刚指定的配置项和数据显示图表
        this.currentMonthTargetChart.setOption(option);
        this.currentMonthTargetChart.resize();
      });
    },

    /**
     * 获取门店分布数据
     */
    getCompanyCountryDistributionData() {
      getCompanyCountryDistribution({
        companyId: this.companyId,
        statisticsPeriod: new Date().Format('yyyy/MM'),
      }).then((res) => {
        this.companyProvinceDistributionData = res;
      });
    },

    /**
     * 获取事故进场数据
     */
    getAccidentRepairData() {
      getAccidentIntoFactory({
        id: this.companyId,
      }).then((res) => {
        // var res = {
        //   accidentInfoOfLastYear: [
        //     {
        //       statisticsPeriod: '2022/01',
        //       claimSettlementBillNum: 1245,
        //       allDutyBillNum: 643,
        //       threeDutyNum: 602,
        //       accidentPercentage: 14,
        //     },
        //     {
        //       statisticsPeriod: '2022/02',
        //       claimSettlementBillNum: 978,
        //       allDutyBillNum: 562,
        //       threeDutyNum: 416,
        //       accidentPercentage: 11.76,
        //     },
        //     {
        //       statisticsPeriod: '2022/03',
        //       claimSettlementBillNum: 1558,
        //       allDutyBillNum: 484,
        //       threeDutyNum: 1074,
        //       accidentPercentage: 13.33,
        //     },
        //     {
        //       statisticsPeriod: '2022/04',
        //       claimSettlementBillNum: 1270,
        //       allDutyBillNum: 414,
        //       threeDutyNum: 856,
        //       accidentPercentage: 15.08,
        //     },
        //     {
        //       statisticsPeriod: '2022/05',
        //       claimSettlementBillNum: 1753,
        //       allDutyBillNum: 685,
        //       threeDutyNum: 1068,
        //       accidentPercentage: 14.33,
        //     },
        //     {
        //       statisticsPeriod: '2022/06',
        //       claimSettlementBillNum: 1643,
        //       allDutyBillNum: 1300,
        //       threeDutyNum: 343,
        //       accidentPercentage: 17.33,
        //     },
        //     {
        //       statisticsPeriod: '2022/07',
        //       claimSettlementBillNum: 1232,
        //       allDutyBillNum: 356,
        //       threeDutyNum: 876,
        //       accidentPercentage: 19.33,
        //     },
        //     {
        //       statisticsPeriod: '2022/08',
        //       claimSettlementBillNum: 1165,
        //       allDutyBillNum: 814,
        //       threeDutyNum: 351,
        //       accidentPercentage: 14.62,
        //     },
        //     {
        //       statisticsPeriod: '2022/09',
        //       claimSettlementBillNum: 1747,
        //       allDutyBillNum: 1104,
        //       threeDutyNum: 643,
        //       accidentPercentage: 18.12,
        //     },
        //     {
        //       statisticsPeriod: '2022/10',
        //       claimSettlementBillNum: 797,
        //       allDutyBillNum: 673,
        //       threeDutyNum: 124,
        //       accidentPercentage: 8.12,
        //     },
        //     {
        //       statisticsPeriod: '2022/11',
        //       claimSettlementBillNum: 1856,
        //       allDutyBillNum: 1234,
        //       threeDutyNum: 622,
        //       accidentPercentage: 17.75,
        //     },
        //     {
        //       statisticsPeriod: '2022/12',
        //       claimSettlementBillNum: 1692,
        //       allDutyBillNum: 947,
        //       threeDutyNum: 745,
        //       accidentPercentage: 15.11,
        //     },
        //   ],
        //   accidentInfoOfThisYear: [
        //     {
        //       statisticsPeriod: '2023/01',
        //       claimSettlementBillNum: 957,
        //       allDutyBillNum: 543,
        //       threeDutyNum: 414,
        //       accidentPercentage: 7.12,
        //     },
        //     {
        //       statisticsPeriod: '2023/02',
        //       claimSettlementBillNum: 1675,
        //       allDutyBillNum: 1444,
        //       threeDutyNum: 213,
        //       accidentPercentage: 14.58,
        //     },
        //     {
        //       statisticsPeriod: '2023/03',
        //       claimSettlementBillNum: 1418,
        //       allDutyBillNum: 854,
        //       threeDutyNum: 564,
        //       accidentPercentage: 4.918,
        //     },
        //     {
        //       statisticsPeriod: '2023/04',
        //       claimSettlementBillNum: 63,
        //       allDutyBillNum: 41,
        //       threeDutyNum: 22,
        //       accidentPercentage: 4.56,
        //     },
        //     {
        //       statisticsPeriod: '2023/05',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //     {
        //       statisticsPeriod: '2023/06',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //     {
        //       statisticsPeriod: '2023/07',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //     {
        //       statisticsPeriod: '2023/08',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //     {
        //       statisticsPeriod: '2023/09',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //     {
        //       statisticsPeriod: '2023/10',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //     {
        //       statisticsPeriod: '2023/11',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //     {
        //       statisticsPeriod: '2023/12',
        //       claimSettlementBillNum: 0,
        //       allDutyBillNum: 0,
        //       threeDutyNum: 0,
        //       accidentPercentage: 0,
        //     },
        //   ],
        // };

        var data = res.accidentInfoOfThisYear.map((item, index) => {
          return {
            total: item?.claimSettlementBillNum, // 总量
            fullResponsibilityNumber: item?.allDutyBillNum, // 全责
            thirdPartyInsuranceNumber: item?.threeDutyNum, // 三责
            rate: item?.accidentPercentage, // 事故率

            lastTotal:
              res.accidentInfoOfLastYear[index]?.claimSettlementBillNum, // 总量
            lastFullResponsibilityNumber:
              res.accidentInfoOfLastYear[index]?.allDutyBillNum, // 全责
            lastThirdPartyInsuranceNumber:
              res.accidentInfoOfLastYear[index]?.threeDutyNum, // 三责
            lastRate: res.accidentInfoOfLastYear[index]?.accidentPercentage, // 事故率

            // 双y轴 防止重叠
            position1:
              item?.accidentPercentage <
              res.accidentInfoOfLastYear[index]?.accidentPercentage
                ? 'top'
                : 'bottom',
            position2:
              item?.accidentPercentage >
              res.accidentInfoOfLastYear[index]?.accidentPercentage
                ? 'top'
                : 'bottom',
          };
        });

        let month = new Date().getMonth() + 1;
        for (var i = month; i < 12; i++) {
          data[i].total = undefined;
          data[i].fullResponsibilityNumber = undefined;
          data[i].thirdPartyInsuranceNumber = undefined;
          data[i].rate = undefined;
        }

        this.accidentRepairData = data;

        this.accidentRepairStatisticChart = echarts.init(
          document.getElementById('accident-repair-statistic')
        );

        // this.drawAccidentRepairStatisticChart(data, this.hideChartNumber);
        this.drawAccidentRepairStatisticChart(data, false);
      });
    },
    /**
     * 绘制事故进场统计图
     * @param {Array} data 数值
     * @param {Boolean} hide 是否隐藏具体数值
     */
    drawAccidentRepairStatisticChart(data, hide) {
      // console.log('事故进场数据：', data);
      var option = {
        grid: [
          { left: '1%', right: '2%', top: '13%', height: '0%' },
          { left: '1%', right: '2%', top: '15%', height: '70%' },
          // 旧版双y轴布局
          // { left: '1%', right: '2%', top: '13%', height: '28%' },
          // { left: '1%', right: '2%', top: '55%', height: '35%' },
        ],

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        legend: {
          left: 'right',
          itemWidth: 16,
          itemHeight: 16,
          data: [
            {
              name: '去年总量',
              icon: `image://${require('./images/legend/last.png')}`,
            },
            {
              name: '今年总量',
              icon: `image://${require('./images/legend/current.png')}`,
            },
            // {
            //   name: '去年全责',
            //   icon: `image://${require('./images/legend/all.png')}`,
            // },
            // {
            //   name: '去年三者',
            //   icon: `image://${require('./images/legend/three.png')}`,
            // },
            {
              name: '全责',
              icon: `image://${require('./images/legend/all.png')}`,
            },
            {
              name: '三者',
              icon: `image://${require('./images/legend/three.png')}`,
            },
            // {
            //   name: '去年事故车进场率',
            //   icon: `image://${require('./images/legend/last-rate.png')}`,
            // },
            // {
            //   name: '今年事故车进场率',
            //   icon: `image://${require('./images/legend/current-rate.png')}`,
            // },
          ],
          selectedMode: false,
          textStyle: {
            // color: '#31C1EA',
            color: '#fff',
          },
        },
        xAxis: [
          {
            type: 'category',
            gridIndex: 0,
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月',
            ],
            axisPointer: {
              type: 'shadow',
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: 'category',
            gridIndex: 1,
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月',
            ],
            axisPointer: {
              type: 'shadow',
            },
            axisLabel: {
              color: '#31C1EA',
              fontFamily: 'MiSans',
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: 'category',
            gridIndex: 1,
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月',
            ],
            position: 'bottom',
            offset: 0, // X 轴相对于默认位置的偏移，在相同的 position 上有多个 X 轴的时候有用
            axisPointer: {
              type: 'shadow',
            },
            axisLabel: {
              // interval: 0,
              color: '#31C1EA',
              fontFamily: 'MiSans',
            },
            axisTick: {
              show: false,
            },

            axisLine: {
              lineStyle: {
                color: 'rgba(49,193,234 ,0.5)',
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            gridIndex: 0,
            // position: 'right',
            name: '',
            // name: '进场率',
            nameLocation: 'end',
            nameTextStyle: { color: '#31C1EA', align: 'left' },
            // min: 0,
            // max: 16,
            // interval: 2,
            axisLabel: {
              show: false,
              color: '#31C1EA',
              formatter: '{value} %',
              fontFamily: 'MiSans',
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          {
            type: 'value',
            gridIndex: 1,
            name: '',
            // name: '台',
            nameLocation: 'end',
            nameTextStyle: {
              color: '#31C1EA',
              align: 'left',
            },
            // min: 0,
            // max: 2000,
            // interval: 250,
            axisLabel: {
              show: false,
              color: '#31C1EA',
              formatter: '{value}',
              fontFamily: 'MiSans',
            },

            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          // 左
          {
            name: '去年全责',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            barWidth: '20%',
            label: {
              show: false,
              // show: !hide,
              // position: 'inside',
              // align: 'center',
              // verticalAlign: 'middle',
              // color: 'rgba(122,246,255,0.5)',
              // fontFamily: 'MiSans',
              // fontSize: 13,
              // fontWeight: 300,
            },
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#3db3f0', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#102c64', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            ],
            tooltip: {
              valueFormatter: function (value) {
                return value !== undefined ? value + ' 台' : '';
              },
            },
            data: data.map((v) => v.lastFullResponsibilityNumber),
          },
          {
            name: '去年三者',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            barWidth: '20%',
            label: {
              show: false,
              // show: !hide,
              // position: 'inside',
              // align: 'center',
              // verticalAlign: 'middle',
              // color: 'rgba(122,246,255,0.5)',
              // fontFamily: 'MiSans',
              // fontSize: 13,
              // fontWeight: 300,
            },
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#6decc9', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#1b536d', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            ],
            tooltip: {
              valueFormatter: function (value) {
                return value !== undefined ? value + ' 台' : '';
              },
            },

            data: data.map((v) => v.lastThirdPartyInsuranceNumber),
          },

          // 空 占位
          {
            type: 'bar',
            barWidth: '8%',
            xAxisIndex: 1,
            yAxisIndex: 1,
          },

          // 右
          {
            name: '全责',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            barWidth: '20%',
            label: {
              show: false,
              // show: !hide,
              // position: 'inside',
              // align: 'center',
              // verticalAlign: 'middle',
              // color: 'rgba(122,246,255,0.5)',
              // fontFamily: 'MiSans',
              // fontSize: 13,
              // fontWeight: 300,
            },
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#3db3f0', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#102c64', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            ],
            tooltip: {
              valueFormatter: function (value) {
                return value !== undefined ? value + ' 台' : '';
              },
            },
            data: data.map((v) => v.fullResponsibilityNumber),
          },
          {
            name: '三者',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            barWidth: '20%',
            barGap: '0%',
            label: {
              show: false,
              // show: !hide,
              // position: 'inside',
              // align: 'center',
              // verticalAlign: 'middle',
              // color: 'rgba(122,246,255,0.5)',
              // fontFamily: 'MiSans',
              // fontSize: 13,
              // fontWeight: 300,
            },
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#6decc9', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#1b536d', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            ],
            tooltip: {
              valueFormatter: function (value) {
                return value !== undefined ? value + ' 台' : '';
              },
            },

            data: data.map((v) => v.thirdPartyInsuranceNumber),
          },

          {
            name: '去年总量',
            type: 'bar',
            xAxisIndex: 2,
            yAxisIndex: 1,
            barWidth: '40%',
            label: {
              show: !hide,
              position: 'top',
              // align: 'center',
              // verticalAlign: 'middle',
              color: '#7AF6FF',
              fontFamily: 'MiSans',
              fontSize: 13,
              fontWeight: 300,
            },

            itemStyle: {
              borderWidth: 1, //柱状的宽度
              borderColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#7AF6FF', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgb(9,77,32,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              }, //柱状边框颜色
              color: 'rgba(0,0,0,0)', // 将柱状设置为透明色
            },
            tooltip: {
              valueFormatter: function (value) {
                return value !== undefined ? value + ' 台' : '';
              },
            },

            data: data.map((v) => v.lastTotal),
          },
          // 空 占位
          // {
          //   type: 'bar',
          //   barWidth: '10%',
          //   xAxisIndex: 1,
          // },

          {
            name: '今年总量',
            type: 'bar',
            xAxisIndex: 2,
            yAxisIndex: 1,
            barWidth: '40%',
            barCategoryGap: '10%',

            label: {
              show: !hide,
              position: 'top',
              // align: 'center',
              // verticalAlign: 'middle',
              color: '#FFDD3D',
              fontFamily: 'MiSans',
              fontSize: 13,
              fontWeight: 300,
            },

            itemStyle: {
              borderWidth: 1, //柱状的宽度
              borderColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#FFDD3D', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(77,38,9,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              }, //柱状边框颜色
              color: 'rgba(0,0,0,0)', // 将柱状设置为透明色
            },
            tooltip: {
              valueFormatter: function (value) {
                return value !== undefined ? value + ' 台' : '';
              },
            },

            data: data.map((v) => v.total),
          },
          // {
          //   name: '去年事故车进场率',
          //   type: 'line',
          //   xAxisIndex: 0,
          //   yAxisIndex: 0,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value !== undefined ? value + ' %' : '';
          //     },
          //   },
          //   label: {
          //     show: true,
          //     position: 'top',
          //     color: '#7AF6FF',
          //     fontFamily: 'MiSans',
          //     fontSize: 13,
          //     fontWeight: 300,
          //     // formatter: '{c} %',
          //     formatter: (params) => {
          //       const { data, value } = params;
          //       return data.position == 'bottom'
          //         ? `{bottom|${value} %}`
          //         : `${value} %`;
          //     },
          //     textStyle: {
          //       rich: {
          //         bottom: {
          //           padding: [0, 0, -50, 0],
          //         },
          //       },
          //     },
          //   },
          //   color: '#7AF6FF',
          //   data: data.map((v) => ({
          //     value: v.lastRate,
          //     position: v.position1,
          //   })),
          // },
          // {
          //   name: '今年事故车进场率',
          //   type: 'line',
          //   xAxisIndex: 0,
          //   yAxisIndex: 0,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value !== undefined ? value + ' %' : '';
          //     },
          //   },
          //   label: {
          //     show: true,
          //     position: 'top',
          //     // formatter: '{c} %',
          //     color: '#FFDD3D',
          //     fontFamily: 'MiSans',
          //     fontSize: 13,
          //     fontWeight: 300,
          //     formatter: (params) => {
          //       const { data, value } = params;
          //       return data.position == 'bottom'
          //         ? `{bottom|${value} %}`
          //         : `${value} %`;
          //     },
          //     textStyle: {
          //       rich: {
          //         bottom: {
          //           padding: [0, 0, -50, 0],
          //         },
          //       },
          //     },
          //   },
          //   color: '#FFDD3D',
          //   // data: data.map((v) => v.rate),
          //   data: data.map((v) => ({
          //     value: v.rate,
          //     position: v.position2,
          //   })),
          // },
        ],
      };

      this.accidentRepairStatisticChart?.setOption(option);
      this.accidentRepairStatisticChart?.resize({ autoResize: true });
    },

    /**
     * @description: 处理屏幕大小变化
     */
    handleResize() {
      // 计算两侧宽度
      this.setSideDivWidth();
      // 绘制本月目标图表
      this.currentMonthTargetChart?.resize();
      // 绘制服务品牌图表
      this.serviceBrandChart?.resize();
      // 绘制事故进场图表
      this.accidentRepairStatisticChart?.resize({ autoResize: true });
      // 绘制毛利率雷达图表
      this.currentMonthGrossMarginChart?.resize();
      // 门店明细图表
      // this.companyDetailChart?.resize();

      // 获取设备宽度
      let deviceWidth = document.documentElement.clientWidth;
      document.documentElement.style.fontSize = deviceWidth / 6.4 + 'px'; // 6.4是设备宽度的10分之1

      // 基准大小
      const baseSize = 16;
      // 设置 rem 函数
      // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
      const scale = document.documentElement.clientWidth / 1920;
      // 设置页面根节点字体大小
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 2) + 'px';
    },

    /**
     * @description: 开始时间定时器
     */
    startTimeInterval() {
      this.timeInterval = setInterval(() => {
        this.time = new Date().Format('yyyy/MM/dd hh:mm:ss');
      }, 1000);
    },

    /**
     * 设置侧边内容块宽度
     * @description: 侧边内容块宽度 = 侧边内容块高度 * 2/3
     */
    setSideDivWidth() {
      let windowHeight = window.innerHeight;
      let windowWidth = window.innerWidth;
      let width = (0.28 * windowHeight) / (2 / 3);
      Array.from(document.getElementsByClassName('side')).forEach((element) => {
        element.style.width = width + 'px';
      });
      let centerWidth = windowWidth - 0.02 * windowWidth - 2 * width;
      // console.log(centerWidth);
      document.getElementsByClassName('center')[0].style.width =
        centerWidth + 'px';
    },

    /**
     * @description: 重置门店排名翻页定时器
     */
    resetCompanyRankInterval() {
      if (this.companyRankInterval) {
        clearInterval(this.companyRankInterval);
      }

      // console.log('重置门店排行榜定时器');
      this.companyRankInterval = setInterval(() => {
        this.rankPage++;
        if (this.rankPage >= Math.ceil(this.companyRankedData.length / 5)) {
          this.rankPage = 0;
        }
        // console.log('当前门店页数', this.rankPage + 1);
      }, 5e3);
    },

    /**
     * @description: 重置门店明细排行序号定时器
     */
    resetGrossMarginRankIndexInterval() {
      if (this.grossMarginRankInterval) {
        clearInterval(this.grossMarginRankInterval);
      }
      // console.log('重置门店明细排名定时器');
      this.grossMarginRankInterval = setInterval(() => {
        // this.grossMarginRankPage++;
        // if (this.grossMarginRankPage >= this.grossMarginRankData.length) {
        //   this.grossMarginRankPage = 0;
        // }
        // this.drawGrossMarginChart(
        //   this.grossMarginRankData[this.grossMarginRankPage]
        // );
        // console.log('当前门店排名', this.grossMarginRankPage + 1);
        this.grossMarginRankPage++;
        if (
          this.grossMarginRankPage >=
          Math.ceil(this.grossMarginRankData.length / 5)
        ) {
          this.grossMarginRankPage = 0;
        }
      }, 5e3);
    },
    /**
     * 停止定时器
     */
    pauseInterval() {
      clearInterval(this.grossMarginRankInterval);
      clearInterval(this.companyRankInterval);
    },
    /**
     * 恢复定时器
     */
    continueInterval() {
      if (this.showType === 1) {
        this.resetCompanyRankInterval();
      } else {
        this.resetGrossMarginRankIndexInterval();
      }
    },
    /**
     * 触发全屏
     */
    handleFullScreen() {
      this.clickCount++;
      setTimeout(() => {
        this.clickCount = 0;
      }, 5e3);
      if (this.clickCount > 5) {
        (function toggleFullScreen() {
          var doc = window.document;
          var docEl = doc.documentElement;

          var requestFullScreen =
            docEl.requestFullscreen ||
            docEl.mozRequestFullScreen ||
            docEl.webkitRequestFullScreen ||
            docEl.msRequestFullscreen;
          var cancelFullScreen =
            doc.exitFullscreen ||
            doc.mozCancelFullScreen ||
            doc.webkitExitFullscreen ||
            doc.msExitFullscreen;

          if (
            !doc.fullscreenElement &&
            !doc.mozFullScreenElement &&
            !doc.webkitFullscreenElement &&
            !doc.msFullscreenElement
          ) {
            requestFullScreen.call(docEl);
          } else {
            cancelFullScreen.call(doc);
          }
        })();
      }
    },
  },
  beforeDestroy() {
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = '16px';

    window.removeEventListener('resize', this.debounce);

    clearInterval(this.timeInterval);
    clearInterval(this.resetDataInterval);
    clearInterval(this.companyRankInterval);
    clearInterval(this.grossMarginRankInterval);

    this.CIMPushManager?.stop();
    this.CIMPushManager = null;
  },
};
</script>

<style scoped lang="scss">
@import './css/common.scss';
@import './css/index.scss';
</style>
