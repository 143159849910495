<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="操作门店">
              <operateCompanySelect
                v-model="form.companyId"
                :maxCollapseTags="3"
                placeholder="请选择操作门店, 可以多选"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanySelect>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品编码">
              <el-input
                v-model="form.goodCode"
                placeholder="请输入商品编码"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                v-auth="624"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button
                v-auth="624"
                class="btn_search"
                size="small"
                @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <template>
            <el-form-item label="库存预警">
              <el-select
                v-model="form.isWarning"
                placeholder="请选择库存预警状态"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option label="启用" :value="1"> </el-option>
                <el-option label="禁用" :value="0"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="汇总显示">
              <el-switch
                v-model="form.isSummary"
                class="ml-2"
                style="
                  --el-switch-on-color: #13ce66;
                  --el-switch-off-color: #ff4949;
                "
              />
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div class="supplier-management__btn">
          <el-button
            v-auth="625"
            size="small"
            class="btn_insert"
            style="height: 35px"
            @click="add"
            >新增</el-button
          >
          <el-button
            size="small"
            class="btn_insert"
            style="height: 35px"
            v-auth="631"
            @click.native="batchOperation(2, '批量删除')"
            >批量删除</el-button
          >
          <el-button
            size="small"
            class="btn_insert"
            style="height: 35px; margin-left: 10px"
            v-auth="627"
            @click="importDialogVisibility = true"
            >批量导入<i class="el-icon-arrow-right"></i
          ></el-button>
        </div>

        <el-button
          v-auth="628"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="60"
          prop="selection"
          align="center"
        >
        </el-table-column>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="100" v-if="!selectedSummary">
          <template slot-scope="scope">
            <span
              v-auth="626"
              class="text_operation blue"
              v-if="scope.row && scope.row.id && scope.row.id > 0"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="门店"
          prop="companyName"
          v-if="!selectedSummary"
        >
        </el-table-column>
        <el-table-column width="140" label="商品ID" prop="goodsId">
        </el-table-column>
        <el-table-column width="140" label="商品名称" prop="goodName">
        </el-table-column>
        <el-table-column
          width="180"
          label="是否禁止采购"
          prop="disablePurchaseStr"
        >
        </el-table-column>
        <el-table-column width="140" label="商品大类" prop="goodFirstCategory">
        </el-table-column>
        <el-table-column width="140" label="商品细类" prop="goodSecondCategory">
        </el-table-column>
        <el-table-column width="120" label="商品品牌" prop="goodBrand">
        </el-table-column>
        <el-table-column width="120" label="商品编码" prop="goodCode">
        </el-table-column>
        <el-table-column width="120" label="商品规格" prop="goodsFormat">
        </el-table-column>
        <el-table-column width="120" label="特征" prop="feature">
        </el-table-column>
        <el-table-column width="120" label="品质" prop="manufactoryType">
          <template #default="{ row }">
            <span
              v-if="row.manufactoryTypeOption && row.manufactoryTypeOption.desc"
            >
              {{ row.manufactoryTypeOption.desc }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="品名" prop="prodName">
        </el-table-column>
        <el-table-column width="100" label="单位" prop="unit">
        </el-table-column>
        <el-table-column
          width="210"
          label="近一周领料出库量"
          prop="lastWeekShowOut"
        >
        </el-table-column>
        <el-table-column
          width="210"
          label="近一个月领料出库量"
          prop="lastMonthShowOut"
        >
        </el-table-column>
        <el-table-column
          width="210"
          label="前3个月月均领料出库数量"
          prop="firstThreeMonthsShowOut"
        >
        </el-table-column>
        <el-table-column
          width="210"
          label="前6个月月均领料出库数量"
          prop="firstSixMonthsShowOut"
        >
        </el-table-column>
        <el-table-column
          width="210"
          label="前3个月月均其他出库数量"
          prop="firstThreeMonthsOtherOut"
        >
        </el-table-column>
        <el-table-column
          width="210"
          label="前6个月月均其他出库数量"
          prop="firstSixMonthsOtherOut"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="安全库存"
          prop="safeStock"
          v-if="!selectedSummary"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="预警状态"
          prop="warnStatus"
          v-if="!selectedSummary"
        >
        </el-table-column>
      </el-table>
      <edit-inventory-warning-dialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :id="editId"
        :entityData="rowEntityData"
        :companyList="companyList"
        @save="getData"
      ></edit-inventory-warning-dialog>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
      <!-- 导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :visibility.sync="importDialogVisibility"
        title="库存预警导入"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240408/%E5%BA%93%E5%AD%98%E9%A2%84%E8%AD%A6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
        :uploadFileApi="fileImportApi"
        :getLastResultApi="fileImportResultApi"
        :importFailDetail="fileImportMessageExportUrlApi"
      ></import-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import importDialog from '@/components/businessComponent/importDialog.vue';
import editInventoryWarningDialog from './components/editInventoryWarningDialog.vue';
import operateCompanySelect from '@/components/businessComponent/operateCompanySelect';
// 接口
import {
  searchStoreRoomByName,
  getInventoryWarningList,
  updateInventoryWarningList,
  exportUrl,
  postGetAllCompanyList,
} from '@/api/stock/inventoryWarning/index';
import { hasAuth } from '@/utils/permissions';

import {
  inventoryWarningImport,
  inventoryWarningImportResult,
  inventoryWarningImportMessageExportUrlApi,
} from '@/api/system/dataImport';

import { mapState } from 'vuex';

export default {
  name: 'inventoryWarning',
  components: {
    pagination,
    editInventoryWarningDialog,
    operateCompanySelect,
    ExportDialog,
    importDialog,
  },
  data() {
    return {
      // 导入弹窗可见性
      importDialogVisibility: false,
      fileImportApi: inventoryWarningImport,
      fileImportResultApi: inventoryWarningImportResult,
      fileImportMessageExportUrlApi: inventoryWarningImportMessageExportUrlApi,
      editId: '',
      rowEntityData: {},

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      storeRoomList: [],
      searchStoreRoomLoading: false,

      companyList: [],

      showEditDialog: false,
      showExportDialog: false,

      startPage: 1,
      endPage: 1,
      exportUrl: exportUrl,

      form: {
        isWarning: '',
        isSummary: false,
      },
      selectedSummary: false,
      listData: [],
      selectData: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.form.companyId = [this.companyId];
    this.getData();
    // 拉取用户的门店列表，以便给用户新增、编辑使用
    this.getAllCompanyList();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    handleSelectionChange(items, row) {
      this.selectData = [];
      items.forEach((item) => {
        this.selectData.push(item.id);
      });
    },
    /**
     * 查询体系内的门店列表
     */
    getAllCompanyList() {
      const data = {};
      postGetAllCompanyList(data).then((res) => {
        this.companyList = [];
        const { company, companyChildren } = res.data.resultObject || {};
        if (companyChildren && companyChildren.length > 0) {
          companyChildren.forEach((com) => {
            this.companyList.push({
              companyId: com.companyId,
              companyName: com.companyName,
              shortName: com.shortName,
            });
          });
        } else {
          if (company) {
            this.companyList.push({
              companyId: company.companyId,
              companyName: company.companyName,
              shortName: company.shortName,
            });
          }
        }
      });
    },
    /**
     * 批量操作
     * @param {Integer} status
     * @param {String} str
     */
    batchOperation(status, str) {
      if (this.selectData.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请选择数据后操作',
        });
      }
      let txt = `确定要${str}选中的库存预警记录吗？`;
      this.$confirm(txt, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        updateInventoryWarningList(status, this.selectData).then((res) => {
          this.$message({
            type: 'success',
            message: '操作库存预警记录成功',
          });
          this.getData(false);
        });
      });
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(624);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.selectedSummary = this.form.isSummary;
      var data = Object.assign({}, this.form, this.pageOption);
      getInventoryWarningList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },

    /**
     * 新增
     */
    add() {
      this.editId = '';
      this.rowEntityData = {};
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 编辑
     */
    handleEdit(row) {
      this.editId = row.id;
      this.rowEntityData = row;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        isWarning: '',
        companyId: [this.companyId],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
