<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0"> 00:00-01:00 </el-col>
      <el-col :span="6" :offset="0"> 01:00-02:00 </el-col>
      <el-col :span="6" :offset="0"> 02:00-03:00 </el-col>
      <el-col :span="6" :offset="0"> 03:00-04:00 </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase0"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase0Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase1"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase1Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase2"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase2Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase3"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase3Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0"> 04:00-05:00 </el-col>
      <el-col :span="6" :offset="0"> 05:00-06:00 </el-col>
      <el-col :span="6" :offset="0"> 06:00-07:00 </el-col>
      <el-col :span="6" :offset="0"> 07:00-08:00 </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase4"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase4Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase5"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase5Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase6"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase6Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase7"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase7Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0"> 08:00-09:00 </el-col>
      <el-col :span="6" :offset="0"> 09:00-10:00 </el-col>
      <el-col :span="6" :offset="0"> 10:00-11:00 </el-col>
      <el-col :span="6" :offset="0"> 11:00-12:00 </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase8"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase8Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase9"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase9Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase10"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase10Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase11"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase11Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0"> 12:00-13:00 </el-col>
      <el-col :span="6" :offset="0"> 13:00-14:00 </el-col>
      <el-col :span="6" :offset="0"> 14:00-15:00 </el-col>
      <el-col :span="6" :offset="0"> 15:00-16:00 </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase12"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase12Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase13"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase13Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase14"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase14Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase15"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase15Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0"> 16:00-17:00 </el-col>
      <el-col :span="6" :offset="0"> 17:00-18:00 </el-col>
      <el-col :span="6" :offset="0"> 18:00-19:00 </el-col>
      <el-col :span="6" :offset="0"> 19:00-20:00 </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase16"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase16Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase17"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase17Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase18"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase18Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase19"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase19Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0"> 20:00-21:00 </el-col>
      <el-col :span="6" :offset="0"> 21:00-22:00 </el-col>
      <el-col :span="6" :offset="0"> 22:00-23:00 </el-col>
      <el-col :span="6" :offset="0"> 23:00-00:00 </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase20"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase20Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase21"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase21Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase22"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase22Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <number-input
          v-model="form.timePhase23"
          placeholder="请输入最大预约数"
          :disabled="form.timePhase23Disabled"
          :min="0"
          :max="999999"
          :nullable="false"
          :enabledZero="true"
          :enabledDecimals="false"
          :negativeNumber="false"
          clearable
        ></number-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'miniProgramBookingConfigCalendar',
  props: {
    defaultMaxBookingNum: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      // 日期，格式 yyyy-MM-dd
      newDate: '',
      allBookingTimePhase: [
        '00:00-01:00',
        '01:00-02:00',
        '02:00-03:00',
        '03:00-04:00',
        '04:00-05:00',
        '05:00-06:00',
        '06:00-07:00',
        '07:00-08:00',
        '08:00-09:00',
        '09:00-10:00',
        '10:00-11:00',
        '11:00-12:00',
        '12:00-13:00',
        '13:00-14:00',
        '14:00-15:00',
        '15:00-16:00',
        '16:00-17:00',
        '17:00-18:00',
        '18:00-19:00',
        '19:00-20:00',
        '20:00-21:00',
        '21:00-22:00',
        '22:00-23:00',
        '23:00-00:00',
      ],
      form: {
        timePhase0: 0,
        timePhase0Disabled: false,
        timePhase1: 0,
        timePhase1Disabled: false,
        timePhase2: 0,
        timePhase2Disabled: false,
        timePhase3: 0,
        timePhase3Disabled: false,
        timePhase4: 0,
        timePhase4Disabled: false,
        timePhase5: 0,
        timePhase5Disabled: false,
        timePhase6: 0,
        timePhase6Disabled: false,
        timePhase7: 0,
        timePhase7Disabled: false,
        timePhase8: 0,
        timePhase8Disabled: false,
        timePhase9: 0,
        timePhase9Disabled: false,
        timePhase10: 0,
        timePhase10Disabled: false,
        timePhase11: 0,
        timePhase11Disabled: false,
        timePhase12: 0,
        timePhase12Disabled: false,
        timePhase13: 0,
        timePhase13Disabled: false,
        timePhase14: 0,
        timePhase14Disabled: false,
        timePhase15: 0,
        timePhase15Disabled: false,
        timePhase16: 0,
        timePhase16Disabled: false,
        timePhase17: 0,
        timePhase17Disabled: false,
        timePhase18: 0,
        timePhase18Disabled: false,
        timePhase19: 0,
        timePhase19Disabled: false,
        timePhase20: 0,
        timePhase20Disabled: false,
        timePhase21: 0,
        timePhase21Disabled: false,
        timePhase22: 0,
        timePhase22Disabled: false,
        timePhase23: 0,
        timePhase23Disabled: false,
      },
      defaultForm: {
        timePhase0: 0,
        timePhase0Disabled: false,
        timePhase1: 0,
        timePhase1Disabled: false,
        timePhase2: 0,
        timePhase2Disabled: false,
        timePhase3: 0,
        timePhase3Disabled: false,
        timePhase4: 0,
        timePhase4Disabled: false,
        timePhase5: 0,
        timePhase5Disabled: false,
        timePhase6: 0,
        timePhase6Disabled: false,
        timePhase7: 0,
        timePhase7Disabled: false,
        timePhase8: 0,
        timePhase8Disabled: false,
        timePhase9: 0,
        timePhase9Disabled: false,
        timePhase10: 0,
        timePhase10Disabled: false,
        timePhase11: 0,
        timePhase11Disabled: false,
        timePhase12: 0,
        timePhase12Disabled: false,
        timePhase13: 0,
        timePhase13Disabled: false,
        timePhase14: 0,
        timePhase14Disabled: false,
        timePhase15: 0,
        timePhase15Disabled: false,
        timePhase16: 0,
        timePhase16Disabled: false,
        timePhase17: 0,
        timePhase17Disabled: false,
        timePhase18: 0,
        timePhase18Disabled: false,
        timePhase19: 0,
        timePhase19Disabled: false,
        timePhase20: 0,
        timePhase20Disabled: false,
        timePhase21: 0,
        timePhase21Disabled: false,
        timePhase22: 0,
        timePhase22Disabled: false,
        timePhase23: 0,
        timePhase23Disabled: false,
      },
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    /**
     * 重置预约数
     * @param defaultMaxBookingNum 默认预约数
     */
    resetMaxBookingNum(defaultMaxBookingNum) {
      this.form.timePhase0 = defaultMaxBookingNum || 0;
      this.form.timePhase1 = defaultMaxBookingNum || 0;
      this.form.timePhase2 = defaultMaxBookingNum || 0;
      this.form.timePhase3 = defaultMaxBookingNum || 0;
      this.form.timePhase4 = defaultMaxBookingNum || 0;
      this.form.timePhase5 = defaultMaxBookingNum || 0;
      this.form.timePhase6 = defaultMaxBookingNum || 0;
      this.form.timePhase7 = defaultMaxBookingNum || 0;
      this.form.timePhase8 = defaultMaxBookingNum || 0;
      this.form.timePhase9 = defaultMaxBookingNum || 0;
      this.form.timePhase10 = defaultMaxBookingNum || 0;
      this.form.timePhase11 = defaultMaxBookingNum || 0;
      this.form.timePhase12 = defaultMaxBookingNum || 0;
      this.form.timePhase13 = defaultMaxBookingNum || 0;
      this.form.timePhase14 = defaultMaxBookingNum || 0;
      this.form.timePhase15 = defaultMaxBookingNum || 0;
      this.form.timePhase16 = defaultMaxBookingNum || 0;
      this.form.timePhase17 = defaultMaxBookingNum || 0;
      this.form.timePhase18 = defaultMaxBookingNum || 0;
      this.form.timePhase19 = defaultMaxBookingNum || 0;
      this.form.timePhase20 = defaultMaxBookingNum || 0;
      this.form.timePhase21 = defaultMaxBookingNum || 0;
      this.form.timePhase22 = defaultMaxBookingNum || 0;
      this.form.timePhase23 = defaultMaxBookingNum || 0;
    },
    /**
     * 处理日期切换
     * @param {String} newDate 新日期，格式 yyyy-MM-dd
     * @param {Array} defaultTimePhaseArray 已经配置好的时间段预约数
     */
    handleChangeDate(newDate, defaultTimePhaseArray) {
      // 切换日期,重新设置值
      this.newDate = newDate;
      for (let i = 0; i < defaultTimePhaseArray.length; i++) {
        const ele = defaultTimePhaseArray[i];
        const idxStr = this.findIndexByBookingTimePhase(ele.bookingTimePhase);
        if (idxStr !== '') {
          this.form['timePhase' + idxStr] = ele.maxBookingNum || 0;
          this.form['timePhase' + idxStr + 'Disabled'] = ele.disable || false;
        } else {
          this.form['timePhase' + idxStr] =
            this.defaultForm['timePhase' + idxStr];
          this.form['timePhase' + idxStr + 'Disabled'] =
            this.defaultForm['timePhase' + idxStr + 'Disabled'];
        }
      }
    },
    findIndexByBookingTimePhase(bookingTimePhase) {
      for (let i = 0; i < this.allBookingTimePhase.length; i++) {
        const element = this.allBookingTimePhase[i];
        if (element === bookingTimePhase) {
          return i + '';
        }
      }
      return '';
    },
    /**
     * 获取当前用户配置的时间段预约数数据
     */
    getUserInputData() {
      const items = [];
      for (let i = 0; i < this.allBookingTimePhase.length; i++) {
        const idxStr = i + '';
        items.push({
          bookingDate: this.newDate,
          bookingTimePhase: this.allBookingTimePhase[i],
          maxBookingNum: this.form['timePhase' + idxStr],
          disable: this.form['timePhase' + idxStr + 'Disabled'],
        });
      }
      return {
        bookingDate: this.newDate,
        items: items,
      };
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.calendar {
  width: 500px;
  height: 475px;
  border: 1px solid #edeef1;
  //   margin-left: 1px;
  background: #ffffff;
}
.currentTime {
  margin: 0px px;
  display: inline-block;
  color: #333;
  /* font-weight: 900; */
}

.min-arrow-left {
  margin-right: 90px;
}

.min-arrow-right {
  margin-left: 90px;
}

.today {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-top: 1px solid #edeef1;
  margin-top: 25px;
}

.calendarHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  height: 65px;
  /* line-height: 50px; */
  /* text-align: center; */
  padding: 0px 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #edeef1;
  margin-bottom: 25px;
  font-size: 20px;
}

.calendarMain {
  width: 500px;
  height: 395px;
  box-sizing: border-box;
  font-size: 14px;
}

.lineright {
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
  display: inline-block;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

.lineleft {
  width: 5px;
  height: 5px;
  transform: rotate(-135deg);
  display: inline-block;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

.calendarMainHeader {
  /* display: flex; */
  /* background: #f0f8ff; */
  /* color: #1e9bff; */
}

.weekItem {
  display: inline-block;
  width: calc(100% / 7 - 6px);
  height: 34.85px;
  line-height: 34.85px;
  margin: 0px 3px;
  text-align: center;
  box-sizing: border-box;
}

.calendarMainBody {
  //   padding: 10px;
  /* display: flex;
          flex-wrap: wrap; */
}

.dataItem {
  position: relative;
  display: inline-block;
  width: calc(100% / 7 - 6px);
  height: 55px;
  line-height: 50px;
  margin: 0px 3px;
  text-align: center;
  font-size: 18px;
  .itemBadge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    // text-align: center;
    border-radius: 50%;
    background-color: red;
    top: 0px;
    right: 6px;
    font-size: 12px;
    color: #ffffff;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      width: 22px;
      height: 22px;
    }
  }
}

.color666 {
  color: #ccc;
  /* cursor: not-allowed !important; */
}

.cursor {
  cursor: pointer;
}

.checkStyle {
  border-radius: 50%;
  background-color: #1e9bff;
  color: white;
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
}

.hoverStyle:hover {
  text-align: center;
  border-radius: 50%;
  background-color: #33ab79;
  color: white;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.selected {
  text-align: center;
  border-radius: 50%;
  background-color: #33ab79;
  color: white;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.el-row {
  height: 50px;
  .el-col {
    line-height: 50px;
    text-align: center;
    border: 1px solid;
  }
}
</style>
