<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="车队">
              <el-input
                v-model="form.clientName"
                placeholder="请输入车队名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input
                v-model="form.userName"
                placeholder="请输入姓名"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="722"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="车牌">
              <el-input
                v-model="form.carNumber"
                placeholder="请输入车牌"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="排号">
              <el-input
                v-model="form.takeNumber"
                placeholder="请输入排号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否完成" prop="isComplete">
              <el-select
                v-model="form.isComplete"
                clearable
                filterable
                placeholder="请选择是否完成"
              >
                <el-option
                  v-for="item in completeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                class="w100"
                v-model="form.createDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="723"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <span
              v-auth="724"
              v-if="scope.row.state === 0 || scope.row.state === 1"
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleComplete(scope.row)"
              >完成开单</span
            >
          </template>
        </el-table-column>
        <el-table-column width="100" label="状态" prop="stateDesc">
        </el-table-column>
        <el-table-column width="200" label="排号" prop="takeNumber">
        </el-table-column>
        <el-table-column width="100" label="姓名" prop="userName">
        </el-table-column>
        <el-table-column width="130" label="手机号" prop="phone">
        </el-table-column>
        <el-table-column width="130" label="车牌号" prop="carNumber">
        </el-table-column>
        <el-table-column width="200" label="车队" prop="clientName">
        </el-table-column>
        <el-table-column min-width="200" label="备注" prop="remark">
        </el-table-column>
        <el-table-column width="200" label="创建时间" prop="dateCreated">
        </el-table-column>
      </el-table>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  miniNumberTakeComplete,
  miniNumberTakeList,
  miniNumberTakeListExportUrl,
} from '@/api/carBill/miniProgramBooking';
import { hasAuth } from '@/utils/permissions';

export default {
  name: 'miniProgramBookingNumberTakeList',
  components: {
    pagination,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      exportUrl: miniNumberTakeListExportUrl,
      form: {},
      // 展示更多筛选
      showMore: true,
      listData: [],
      completeList: [
        {
          value: true,
          label: '是',
        },
        {
          value: false,
          label: '否',
        },
      ],
    };
  },

  computed: {},
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.getData();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(722);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var data = this.buildRequestBodyData();
      miniNumberTakeList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, this.buildRequestBodyData()),
      });
    },
    buildRequestBodyData() {
      var times = {
        beginCreated: this.form.createDateTimes?.[0]
          ? this.form.createDateTimes?.[0] + ' 00:00:00'
          : '',
        endCreated: this.form.createDateTimes?.[1]
          ? this.form.createDateTimes?.[1] + ' 23:59:59'
          : '',
      };

      var data = Object.assign({}, this.form, this.pageOption, times);
      delete data.createDateTimes;
      return data;
    },
    /**
     * 编辑
     */
    async handleComplete(row) {
      // 跳转到开单页面，并且把当前预约ID和类型传递过去
      this.$router.push({
        name: 'editCarBill',
        query: {
          businessType: 'numberTake',
          businessTypeId: row.id,
          carNumber: row.carNumber,
          carId: row.carId,
          type: 0,
          contact: row.userName,
          contactPhone: row.phone,
          repairType: 0,
          t: new Date().getTime(),
        },
      });
      /*
      let res = await this.$confirm('确定要完成这个现场排号吗?', {
        title: '提示',
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (res === 'confirm') {
        const bodyData = {
          id: row.id,
        };
        miniNumberTakeComplete(bodyData).then((res) => {
          this.$message.success(res || '完成叫号成功！');
          this.getData(false);
        });
      }
      */
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
      this.form.createDateTimes = [
        new Date().Format('yyyy-MM-dd'),
        new Date().Format('yyyy-MM-dd'),
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
