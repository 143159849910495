<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="接车进店日期">
                <el-date-picker
                  class="w100"
                  v-model="form.intoStoreTimes"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  @input="dateRangeChange()"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="接车员">
              <el-select
                v-model="form.pickUpUserId"
                @change="selectAdminJobListUser"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAdminJobListUser"
                :loading="searchAdminJobListUserLoading"
              >
                <el-option
                  v-for="item in adminJobListUserList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.realName
                  }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                @change="selectClient"
                filterable
                clearable
                remote
                placeholder="请输入客户名称"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in customList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务类型">
              <el-select
                v-model="form.billType"
                clearable
                multiple
                placeholder="全部 支持多选"
              >
                <el-option
                  v-for="item in billType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车单状态">
              <el-select v-model="form.billState" placeholder="请选择">
                <el-option
                  v-for="item in billStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <template v-if="showMore">
              <el-form-item label="事故责任">
                <el-select
                  v-model="form.dutyType"
                  filterable
                  multiple
                  placeholder="搜索事故责任"
                  clearable
                >
                  <el-option
                    v-for="item in dutyType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="施工技师">
                <el-select
                  v-model="form.technicianId"
                  filterable
                  placeholder="搜索姓名、手机号"
                  clearable
                >
                  <el-option
                    v-for="item in techniciansList"
                    :key="item.userId"
                    :label="item.realName"
                    :value="item.userId"
                  >
                    <span
                      style="float: left; color: #8492a6; font-size: 13px"
                      >{{ item.realName }}</span
                    >
                    <span style="float: right">{{ item.phone }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="margin-bottom: 15px; font-size: 14px">
        <i
          class="pointer iconfont grow-icon_warn_32"
          style="color: #ff9200; font-size: 15px; margin: 5px 10px"
        ></i
        ><span style="color: #909399"
          >今日在场只统计【服务中】【挂单中未提车】【待结账未提车】【已结账未提车】的车辆</span
        >
      </div>
      <div class="btns flex-x-between">
        <div>
          <div class="custom-tabs nopadding">
            <div
              @click="status = -1"
              :class="{
                'custom-tab-item': true,
                active: status == -1,
              }"
            >
              <p>全部({{ carBillPaidStatistic.carBillNum }})</p>
            </div>
            <div
              @click="status = 0"
              :class="{
                'custom-tab-item': true,
                active: status == 0,
              }"
            >
              <p>自费({{ carBillPaidStatistic.selfPayBillNum }})</p>
            </div>
            <div
              @click="status = 1"
              :class="{
                'custom-tab-item': true,
                active: status == 1,
              }"
            >
              <p>包干({{ carBillPaidStatistic.contractForBillNum }})</p>
            </div>
            <div
              @click="status = 2"
              :class="{
                'custom-tab-item': true,
                active: status == 2,
              }"
            >
              <p>理赔({{ carBillPaidStatistic.claimSettlementBillNum }})</p>
            </div>
            <div
              @click="status = 3"
              :class="{
                'custom-tab-item': true,
                active: status == 3,
              }"
            >
              <p>
                索赔({{ carBillPaidStatistic.claimForCompensationBillNum }})
              </p>
            </div>
          </div>
        </div>
        <div>
          <el-button
            v-auth="449"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <el-table
        class="custom-table"
        :data="carBillList"
        border
        @row-dblclick="goDetail($event.billId)"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="150" label="操作门店" prop="companyName">
          <template #default="{ row }">
            <el-tooltip :content="row.companyName">
              <div class="text-ellipsis-2-row">
                {{ row.companyName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="100" label="操作" prop="carNumber">
          <template slot-scope="scope">
            <div
              v-auth="451"
              class="detail-btn flex-center blue pointer"
              @click="handleEdit(scope.row)"
            >
              滞留说明
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140" label="车牌号" prop="carNumber">
          <template #default="{ row }">
            {{ row.carNumber }}
            <span v-if="row.manyBill == 1">
              <el-tooltip
                effect="light"
                content="该车辆存在多张未完成单据"
                placement="bottom-start"
              >
                <img
                  src="@/assets/duo.png"
                  class="primary"
                  style="vertical-align: middle"
                />
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="170" label="车单号/业务类型">
          <template #default="{ row }">
            <div class="blue pointer" @click="goDetail(row.billId)">
              {{ row.billCode }}
            </div>
            <div v-if="row.billType !== 0">
              {{ row.billType | dict('carBillType') }}
            </div>
            <div v-else>
              {{ row.isOthersSelfPayState == 0 ? '自费' : '他人自费' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="车型/车架号" show-overflow-tooltip>
          <template #default="{ row }">
            <div class="text-ellipsis" v-if="row.carModelName">
              {{ row.carModelName }}
            </div>
            <div class="text-ellipsis" v-if="row.uniqueId">
              {{ row.uniqueId }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" label="签约客户" show-overflow-tooltip>
          <template #default="{ row }">
            <div v-if="row.ownerName">
              <div class="text-ellipsis">{{ row.ownerName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" label="进店时间" prop="intoStoreTime">
        </el-table-column>
        <el-table-column width="100" label="预交车时间">
          <template #default="{ row }">
            <!-- <div v-if="row.waitInStore">{{ row.planFinishTime }}</div>
                  <div class="color999">
                    {{ row.waitInStore ? '在店等' : '待定' }}
                  </div> -->
            <div v-if="row.undetermined">待定</div>
            <div v-else>
              <p>{{ row.planFinishTime }}</p>
              <p>{{ row.waitInStore ? '在店等' : '' }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140" label="车单状态">
          <template #default="{ row }">
            <div>
              <p>
                {{ row.state | dict('carBillState') }}
                <span class="red" v-if="row.billOtherState"
                  >({{ row.billOtherState }})</span
                >
              </p>
            </div>
            <div style="font-size: 12px" class="flex color626">
              <!-- 检车状态字段 -->
              <!-- {{ row.detectionState | dict('detectionState') }} -->

              <!-- 报价审核状态字段 -->
              <!-- bug764隐藏 -->
              <!-- {{ row.quotationReviewState == 0 ? '/待审核' : '/已审核' }}  注释时间2022年8月1日-->
              <!-- 施工状态字段 -->
              <div v-if="row.dispatchState !== undefined">
                <span> /</span>
                {{ row.dispatchState | dict('dispatchState') }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" label="接车员" prop="pickUpCarName">
        </el-table-column>
        <el-table-column width="130" prop="paid">
          <template #header>
            <div class="flex">
              <el-tooltip content="单据金额">
                <span class="text-ellipsis">单据金额</span>
              </el-tooltip>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  包干业务车单的包干金额,结账当月显示为，查询时<br />点的预分摊金额,结账次月显示为实际分摊金额
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <span v-if="row.billType == 1 && row.state === 1">
              <div class="color666" v-if="!row.takeTime">
                <p>{{ row.paid }}</p>
                <div>
                  包干:
                  <span v-auth="{ code: 473, handle: 'dataMarking' }">{{
                    row.contractAmount | toThousands
                  }}</span>
                </div>
              </div>
              <div v-else>
                <span v-auth="{ code: 473, handle: 'dataMarking' }">{{
                  row.contractAmount | toThousands
                }}</span>
              </div>
            </span>
            <div v-else>{{ row.paid | toThousands }}</div>
          </template>
        </el-table-column>
        <el-table-column width="100" label="进店里程" prop="mileage">
        </el-table-column>
        <el-table-column width="100" label="初诊与备注" prop="firstVisitRemark">
          <template #default="{ row }">
            <el-tooltip :content="row.firstVisitRemark">
              <div class="text-ellipsis-2-row">
                {{ row.firstVisitRemark }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="100" label="施工技师" prop="technicianNames">
          <template #default="{ row }">
            <el-tooltip :content="row.technicianNames">
              <div class="text-ellipsis-2-row">
                {{ row.technicianNames }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="150" label="在店时长" prop="billInStoreTime">
        </el-table-column>
        <el-table-column
          min-width="120"
          label="滞留|中断原因"
          prop="strandedReason"
        >
          <template #default="{ row }">
            <el-tooltip :content="row.strandedReason">
              <div class="text-ellipsis-2-row">
                {{ row.strandedReason }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="planCompletionTime">
          <template slot="header">
            <div class="flex">
              <el-tooltip content="卡点解决时间">
                <span class="text-ellipsis">卡点解决时间</span>
              </el-tooltip>
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  当前阶段预计完成时间
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <div>{{ row.planCompletionTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="滞留报告人"
          prop="reporterName"
        ></el-table-column>
        <el-table-column width="100" prop="lastUpdated">
          <template slot="header">
            <el-tooltip content="滞留更新时间">
              <span class="text-ellipsis">滞留更新时间</span>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>

    <!-- 滞留说明弹窗 -->
    <el-dialog
      title="滞留说明"
      :visible.sync="dialogVisible"
      width="200"
      :close-on-click-modal="false"
    >
      <div class="tarry-dialog flex">
        <span class="red" style="margin-top: 10px">*</span
        ><span class="title">滞留原因</span>
        <el-input
          type="textarea"
          :rows="8"
          placeholder="请填写车辆滞留在场原因或维修中断原因（必填）"
          v-model="editInfo.strandedReason"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </div>
      <div class="tarry-dialog">
        <div class="flex">
          <p class="title">
            <span class="red" style="margin-top: 10px">*</span>卡点解决时间
          </p>
          <el-date-picker
            v-model="editInfo.planCompletionTime"
            type="date"
            placeholder="请选择日期"
            size="small"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>

        <p style="color: #84878c; margin-left: 98px; margin-top: 10px">
          设置当前阶段预计完成时间
        </p>
      </div>
      <div class="tarry-dialog flex">
        <p class="title">
          <span class="red" style="margin-top: 10px">*</span>预交车时间
        </p>
        <el-date-picker
          v-model="editInfo.planFinishTime"
          type="datetime"
          :placeholder="finishPlaceholder"
          size="small"
          :disabled="planFinishDisabled"
          @focus="changes"
        ></el-date-picker>
      </div>

      <div slot="footer">
        <el-button size="small" type="primary" @click="editDetention">
          确 定
        </el-button>
        <el-button size="small" @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import { downloadFile } from '@/utils/fileApi';
import { hasAuth } from '@/utils/permissions';

//接口
import {
  exportCarBillListUrl,
  getCarBillPaidStatistic,
} from '@/api/financial/pickSingle';
import { getUserList, getTechniciansList } from '@/api/employee';

import {
  getCarStrandedDetailed,
  getCarStrandedDetailedStatistics,
  editDetentionInstructions,
  exportCarStrandedDetailedListUrl,
} from '@/api/carBill/strandedCar';

import { searchClient } from '@/api/customer/customer';

//组件
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 字典
import { carBillType, dutyType } from '@/service/dict/dictData';

// 工具
import Big from 'big.js';
import { mapState } from 'vuex';

export default {
  name: 'presentCar',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      form: {
        billState: '',
        intoStoreTimes: [],
      },
      billStateList: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '服务中',
        },
        {
          value: 4,
          label: '挂单中（未提车)',
        },
        {
          value: 2,
          label: '待结账（未提车）',
        },
        {
          value: 1,
          label: '已结账（未提车）',
        },
      ],
      // 展示更多筛选
      showMore: false,
      //导出的页面设置
      exportUrl: exportCarStrandedDetailedListUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      status: -1,
      // 车单列表数据
      carBillList: [],
      //搜索接车员列表字段
      searchAdminJobListUserLoading: false,
      adminJobListUserList: [],
      //业务类型
      billType: carBillType,
      // 施工技师列表
      techniciansList: [],
      //事故责任字段
      dutyType: dutyType,
      //服务分类
      serviceClassList: [],
      //签约客户
      customList: [],
      searchClientLoading: false,
      dispatchState: '', // 施工单状态
      carBillPaid: {},
      carBillPaidStatistic: {},
      //滞留说明弹窗可见性
      dialogVisible: false,

      // 预交车时间时间禁用性
      planFinishDisabled: false,
      //滞留说明数据
      editInfo: {
        // 滞留原因
        strandedReason: '',
        //预完成时间
        planFinishTime: '',
        //预交车时间
        deliveryTime: '',
      },
    };
  },
  watch: {
    status() {
      this.form.hasStay = false;
      this.getList();
      this.getCarBillPaidStatistic();
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    finishPlaceholder() {
      return this.editInfo.undetermined ? '待定' : '请选择预交车时间';
    },
  },
  activated() {
    // 从其他页面跳转过来时附带了查询条件
    if (this.$route.params.filterForm) {
      this.customList = [
        {
          id: this.$route.params.filterForm.clientId,
          clientName: this.$route.params.filterForm.clientName,
        },
      ];
      this.form = this.$route.params.filterForm;
      this.getList();
      this.getCarBillPaidStatistic();
      this.searchClient();
    }
  },
  created() {
    this.getTechniciansList();
    if (!this.$route.params.filterForm) {
      this.form.operateCompanyId = [this.companyId];
      this.form.operateCompanyUrl = [this.companyId];
      this.getList();
      this.getCarBillPaidStatistic();
    }
  },
  mounted() {},
  methods: {
    setCurrentKey(key) {},
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    // 置空输入框数据
    empty() {
      this.form = { intoStoreTimes: [] };
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //远程搜索接车员列表
    adminJobUsrKeyWordChange(keyWord) {
      if (keyWord) {
        this.searchAdminJobListUser(keyWord);
      }
    },
    searchAdminJobListUser(keyWord = '') {
      this.searchAdminJobListUserLoading = true;
      getUserList(keyWord)
        .then((res) => {
          this.adminJobListUserList = res.records || [];
        })
        .finally(() => {
          this.searchAdminJobListUserLoading = false;
        });
    },
    //选中接车员
    selectAdminJobListUser() {},
    /**
     * 远程搜索车主客户列表
     */
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择车主客户
     */
    selectClient(clientId) {},

    /**
     * 删除车主客户
     */
    deleteClient() {},
    // 获取技师列表
    getTechniciansList() {
      getTechniciansList(this.companyId).then((res) => {
        this.techniciansList = res || [];
      });
    },
    /**
     * 点击查询
     */
    doSearch() {
      this.form.hasStay = false;
      this.getList();
      this.getCarBillPaidStatistic();
    },
    //获取列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        intoStoreStartTime: this.form.intoStoreTimes?.[0]
          ? this.form.intoStoreTimes?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTimes?.[1]
          ? this.form.intoStoreTimes?.[1]
          : '',
        type: this.status == -1 ? '' : this.status, //车单状态
      };
      var data = Object.assign(this.form, this.pageOption, times);
      this.carBillPaid = data;
      getCarStrandedDetailed(data).then((res) => {
        this.total = res.data.total;
        this.carBillList = res.data.records;
      });
    },
    //新增需求-增加查询结果数据的单据金额合计
    getCarBillPaidStatistic() {
      getCarStrandedDetailedStatistics(this.carBillPaid).then((res) => {
        this.carBillPaidStatistic = res;
      });
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
    //滞留说明
    handleEdit(value) {
      this.dialogVisible = true;
      this.editInfo = this.$lodash.cloneDeep(value);
      this.planFinishDisabled = false;
    },
    cancel() {
      this.dialogVisible = false;
    },
    editDetention() {
      if (['', undefined, null].includes(this.editInfo.strandedReason)) {
        return this.$message.warning('请填写车辆滞留在场原因或维修中断原因');
      } else if (
        ['', undefined, null].includes(this.editInfo.planCompletionTime)
      ) {
        return this.$message.warning('请选择卡点解决时间');
      }
      let result = hasAuth(524);
      if (this.editInfo.planFinishTime != undefined) {
        if (this.editInfo.planFinishTime.length < 19) {
          this.editInfo.planFinishTime = this.editInfo.planFinishTime + ':00';
        } else {
          this.editInfo.planFinishTime = this.editInfo.planFinishTime.Format(
            'yyyy-MM-dd hh:mm:ss'
          );
        }
      } else {
        if (result) {
          return this.$message({
            type: 'warning',
            message: '请设置预交车时间',
          });
        }
      }
      var data = {
        billId: this.editInfo.billId,
        carStrandedId: this.editInfo.carStrandedId,
        strandedReason: this.editInfo.strandedReason,
        planFinishTime: this.editInfo.planFinishTime,
        planCompletionTime: this.editInfo.planCompletionTime + ' 00:00:00',
      };
      editDetentionInstructions(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '操作成功!',
          });
          this.dialogVisible = false;
          this.form.hasStay = false;
          this.getList();
        } else {
          this.$message({
            type: 'warning',
            message: '操作失败!',
          });
        }
      });
    },

    changes() {
      let result = hasAuth(524);
      if (!result) {
        this.planFinishDisabled = true;
        this.$message({
          type: 'error',
          message: '暂无 ”在场车辆>滞留说明>更新预交车时间“权限，请联系管理员!',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//修改选择时间框长度
// .el-form {
//   width: 100%;
//   .el-form-item__content > div {
//     width: 100%;
//   }
// }

.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 10px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    text-align: center;
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
    p:nth-child(2) {
      font-size: 14px;
      margin-top: 3px;
    }
  }
  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}
.tarry-dialog {
  margin-bottom: 20px;
  .title {
    width: 100px;
    margin-top: 10px;
    color: #242526;
  }
}
</style>
