<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10" v-if="!confirmState">
        <el-col :span="24" :offset="0">
          <el-form-item label="协议编号" prop="agreementNum">
            <el-input
              v-model="editForm.agreementNum"
              maxlength="200"
              clearable
              @clear="agreementNumInputClear()"
              @keyup.enter.native="doSearch"
              placeholder="请输入协议编号，并回车"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="!confirmState">
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 协议编号 </el-col>
        <el-col :span="16" :offset="0">{{
          agreementData.agreementNumber
        }}</el-col>
      </el-row>
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 协议客户 </el-col>
        <el-col :span="16" :offset="0">{{
          agreementData.agreementClientName
        }}</el-col>
      </el-row>
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 有效期 </el-col>
        <el-col :span="16" :offset="0">
          {{ agreementData.startDate }}
          至
          {{ agreementData.endDate }}
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 已配置协议价 </el-col>
        <el-col :span="16" :offset="0">
          {{ agreementData.agreementPriceCount }}个
        </el-col>
      </el-row>
    </div>
    <div v-if="confirmState">
      <p class="myTitle">被选择协议</p>
      <el-row :gutter="10" v-if="extendAgreementData" class="myRow">
        <el-col :span="8" :offset="0"> 协议编号 </el-col>
        <el-col :span="16" :offset="0">{{
          extendAgreementData.agreementNumber
        }}</el-col>
      </el-row>
      <el-row :gutter="10" v-if="extendAgreementData" class="myRow">
        <el-col :span="8" :offset="0"> 协议客户 </el-col>
        <el-col :span="16" :offset="0">{{
          extendAgreementData.agreementClientName
        }}</el-col>
      </el-row>
      <el-row :gutter="10" v-if="extendAgreementData" class="myRow">
        <el-col :span="8" :offset="0"> 有效期 </el-col>
        <el-col :span="16" :offset="0">
          {{ extendAgreementData.startDate }}
          至
          {{ extendAgreementData.endDate }}
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="extendAgreementData" class="myRow">
        <el-col :span="8" :offset="0"> 已配置协议价 </el-col>
        <el-col :span="16" :offset="0">
          {{ extendAgreementData.agreementPriceCount }}个
        </el-col>
      </el-row>
      <el-divider content-position="left">
        <i class="el-icon-arrow-down"></i>
      </el-divider>
      <p class="myTitle">
        以下协议生效
        <span class="mySubTitle">(生效协议将按照被选择的协议的协议价进行)</span>
      </p>
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 协议编号 </el-col>
        <el-col :span="16" :offset="0">{{
          agreementData.agreementNumber
        }}</el-col>
      </el-row>
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 协议客户 </el-col>
        <el-col :span="16" :offset="0">{{
          agreementData.agreementClientName
        }}</el-col>
      </el-row>
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 有效期 </el-col>
        <el-col :span="16" :offset="0">
          {{ agreementData.startDate }}
          至
          {{ agreementData.endDate }}
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="agreementData" class="myRow">
        <el-col :span="8" :offset="0"> 已配置协议价 </el-col>
        <el-col :span="16" :offset="0">
          {{ agreementData.agreementPriceCount }}个
        </el-col>
      </el-row>
    </div>
    <template slot="footer">
      <el-button
        size="small"
        type="primary"
        v-if="!confirmState"
        @click="confirmFun"
      >
        确 定
      </el-button>
      <el-button
        size="small"
        type="primary"
        v-if="confirmState"
        @click="saveFun"
      >
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 组件
// 接口
import {
  searchByAgreementNumber,
  getByAgreementId,
  extendAgreementPrice,
} from '@/api/customer/agreement';
export default {
  name: 'selectExtendAgreementPriceDialog',
  components: {},
  props: {
    visibility: Boolean,
    agreementId: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    title: {
      type: [String],
      required: true,
      default: '继承协议价',
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {},
      confirmState: false,
      agreementData: undefined,
      extendAgreementData: undefined,
      // 表单规则
      rules: {
        agreementNum: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入协议编号，并回车',
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getData();
    },
    /**
     * 获取数据
     */
    getData() {
      const _this = this;
      const data = {
        agreementId: this.agreementId,
      };
      getByAgreementId(data).then((res) => {
        _this.extendAgreementData = res;
      });
    },
    agreementNumInputClear() {
      this.agreementData = undefined;
    },
    doSearch() {
      const _this = this;
      const data = {
        agreementNumber: _this.editForm.agreementNum,
      };
      searchByAgreementNumber(data).then((res) => {
        _this.agreementData = res;
      });
    },
    confirmFun() {
      const _this = this;
      if (!_this.agreementData) {
        _this.$message.warning('请先查询搜索要继承的协议！');
        return;
      }
      if (!_this.extendAgreementData) {
        _this.$message.warning('继承协议错误，请重新选择');
        return;
      }
      this.confirmState = true;
    },
    /**
     * 保存
     */
    saveFun() {
      const _this = this;
      const data = {
        agreementId: _this.agreementData.id,
        extendAgreementId: _this.extendAgreementData.id,
      };
      extendAgreementPrice(data).then((res) => {
        _this.$message.info('继承协议价操作成功');
        _this.$emit('update:visibility', false);
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    margin-left: 100px;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
.myTitle {
  font-size: 15px;
  font-weight: bold;
}
.mySubTitle {
  font-size: 15px;
  font-weight: normal;
}
.myRow {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
