<template>
  <el-dialog
    title="更换手机号"
    :visible="visibility"
    width="420px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="80px"
      style="margin-top: 18px"
    >
      <p style="margin: 0 0 18px 16px; font-size: 13px; color: #999">
        <i style="margin-right: 5px" class="el-icon-warning orange"></i>
        更换成功后使用新的手机号进行登录
      </p>
      <el-form-item label="手机号" prop="phoneNumber">
        <el-input
          v-model="editForm.phoneNumber"
          @input="filterNonDigits"
          placeholder="请输入新手机号"
          maxlength="11"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="验证码" prop="verificationCode">
        <div class="flex">
          <el-input
            style="width: 57%"
            placeholder="请输入验证码"
            v-model="editForm.verificationCode"
          >
          </el-input>
          <el-button
            type="primary"
            size="small"
            style="width: 43%; margin-left: 5px"
            :disabled="countdown > 0"
            @click="getCode"
            >{{ text }}</el-button
          >
        </div>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { getVerificationCode, changePhoneNumber, offline } from '@/api/system';
import { oldServerUrl } from '@/config/compatibilityOlderSystems';
import axios from 'axios';

export default {
  name: 'changePhoneNumberDialog',
  props: {
    visibility: Boolean,
    accountId: Number,
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新手机号'));
      } else {
        // 1.校验11位手机号
        if (value.length !== 11) {
          callback(new Error('手机号格式有误，请检查'));
          return;
        }
        // 2.校验第一位必须为1，第二位为大于3
        if (value.charAt(0) !== '1' || value.charAt(1) < 3) {
          callback(new Error('手机号格式有误，请检查'));
          return;
        }

        callback();
      }
    };

    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else {
        callback();
      }
    };
    return {
      // 表单信息
      editForm: {
        phoneNumber: '',
        verificationCode: '',
        accountId: '',
      },
      // 表单规则
      rules: {
        phoneNumber: [
          {
            required: true,
            validator: validatePhone,
            trigger: 'blur',
          },
        ],

        verificationCode: [
          { required: true, validator: validateCode, trigger: 'blur' },
        ],
      },

      countdown: 0,
      timer: null,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo.userId,
    }),
    text() {
      // 如果没有倒计时,则显示发送验证码按钮
      if (this.countdown > 0) {
        return this.countdown + 's后重新发送';
      } else {
        return '获取验证码';
      }
    },
  },
  created() {},
  methods: {
    /**
     * 过滤非数字
     */
    filterNonDigits(e) {
      this.editForm.phoneNumber = e.replace(/\D/g, '');
    },
    /**
     * 获取验证码
     */
    getCode() {
      this.$refs.form.validateField('phoneNumber', (res) => {
        console.log('校验结果', res);
        if (res) {
          this.$message.warning('手机号格式有误，请检查');
          return;
        }

        getVerificationCode({ phoneNumber: this.editForm.phoneNumber, type: 2 })
          .then((res) => {
            this.$message.success('验证码发送成功!');
            this.countdown = 59;
            this.timer = setInterval(() => {
              if (this.countdown === 0) {
                clearInterval(this.timer);
              } else {
                this.countdown--;
              }
            }, 1e3);
          })
          .catch((err) => {
            this.$message.error('获取验证码失败!');
          });
      });
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          changePhoneNumber({
            phoneNumber: this.editForm.phoneNumber,
            verificationCode: this.editForm.verificationCode,
            accountId: this.accountId,
          })
            .then((res) => {
              this.$message.success('更换成功！');
              axios
                .get(
                  `${oldServerUrl}/user/extrusion?phone=${this.editForm.phoneNumber}`,
                  {
                    noHandleError: true,
                    noShowWaiting: true,
                  }
                )
                .then((res) => {
                  console.log(res);
                  offline({ phone: this.editForm.phoneNumber }).then((val) => {
                    console.log(val);
                    this.$store.dispatch('logout');
                    this.handleClose();
                  });
                });
            })
            .catch((err) => {
              this.editForm.verificationCode = '';
            });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
