import http from '@/service/http';
import axios from 'axios';

import { oldServerUrl } from '@/config/compatibilityOlderSystems';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 库存仓库搜索
export function searchStoreRoomByName(data = defaultListOption) {
  return http.post('/admin/inventory/storeRoom/list', data);
}
// 库存预警列表查询
export function getInventoryWarningList(data = defaultListOption) {
  return http.post('/admin/inventory/warningList', data);
}
export function getInventoryWarningGoodsList(data = defaultListOption) {
  return http.post('/admin/inventory/warning/goods', data);
}

export function inventoryWarningAdd(data = defaultListOption) {
  return http.post('/admin/inventory/warning/add', data);
}

export function inventoryWarningEdit(data = defaultListOption) {
  return http.post('/admin/inventory/warning/edit', data);
}
export function inventoryWarningDetail(data = defaultListOption) {
  return http.post('/admin/inventory/warning/detail', data);
}
export function inventoryWarningBatchDelete(data = defaultListOption) {
  return http.post('/admin/inventory/warning/deleteInBatches', data);
}
export function updateInventoryWarningList(type, ids) {
  if (2 == type) {
    const data = [];
    ids.forEach((item) => {
      data.push({ id: item });
    });
    return inventoryWarningBatchDelete(data);
  }
  return new Promise((l, r) => {
    l();
  });
}

export function postGetAllCompanyList(data) {
  return axios.post(
    oldServerUrl + '/web/company/getCurrentUserAndCompany',
    data,
    {
      noHandleError: true,
      noShowWaiting: true,
    }
  );
}

// 导出
export const exportUrl = '/admin/data/inventory/warningList/export';
