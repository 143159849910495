import http from '@/service/http';

const pakelist = {
  current: 1,
  size: 10,
};
// 获取三包公司列表
export function getPakeManuList(data = pakelist) {
  return http.post('/admin/undertakeCompany/list', data);
}

// 查询三包厂家详情信息
export function getPakeManuDetailList(data) {
  return http.post('/admin/undertakeCompany/detail', data);
}

// 新增三包公司
export function addUndertakeCompany(data) {
  return http.post('/admin/undertakeCompany', data, { onlyReturnData: false });
}

// 更新三包公司
export function updateUndertakeCompany(data) {
  return http.put('/admin/undertakeCompany', data, { onlyReturnData: false });
}
// 导出三包厂家列表
export const exportManuListUrl = '/admin/car/export';

//批量修改启用/禁用/删除三包厂家
export function undertakeCompanyMulti(data) {
  return http.put('/admin/undertakeCompany/multi', data, {
    onlyReturnData: false,
  });
}
//三包厂家列表里面的适用门店
export function undertakeCompanyEnableStore(data) {
  return http.post('/admin/undertakeCompany/enableStore', data, {
    onlyReturnData: false,
  });
}
// 模糊搜索三包厂家
export function searchUndertakeCompany(data) {
  return http.post('/admin/undertakeCompany/fuzzy', data);
}
// 分页模糊搜索三包厂家 带开票主体
export function searchUndertakeCompanyWithInvoice(data) {
  return http.post('/admin/undertakeCompany/fuzzyWInvoicePage', data);
}

// 导出三包厂家客户列表
export const exportUndertakeCompanyUrl = '/admin/undertakeCompany/export';

//查询保险公司的列表
const insuList = {
  current: 1,
  size: 10,
};
export function getInsuranList(data = insuList) {
  return http.post('/admin/insuranceCompany/list', data);
}
// 查询保险公司详情信息
export function getInsuranDetailList(data) {
  return http.post('/admin/insuranceCompany/detail', data);
}

// 新增保险公司
export function addInsuran(data) {
  return http.post('/admin/insuranceCompany', data, { onlyReturnData: false });
}

// 更新保险公司
export function updateInsuran(data) {
  return http.put('/admin/insuranceCompany', data, { onlyReturnData: false });
}

//保险公司列表里面的适用门店
export function insuranceCompanyEnableStore(data) {
  return http.post('/admin/insuranceCompany/enableStore', data, {
    onlyReturnData: false,
  });
}

//批量修改启用/禁用/删除保险公司
export function insuranceCompanyMulti(data) {
  return http.put('/admin/insuranceCompany/multi', data, {
    onlyReturnData: false,
  });
}

// 检索开票主体门店列表
export function searchClient(
  keyWord = '',
  currCompanyId = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/company/fuzzy',
    {
      companyIdOrCompanyName: keyWord,
      currCompanyId: currCompanyId,
      size,
      current,
    },
    config
  );
}

//选择门店弹窗中没有进行模糊查询的时候列表请求
export function selListTwo(data) {
  return http.post('/admin/company/selList', data, {
    onlyReturnData: false,
  });
}
export function selList(
  keyWord = '',
  currCompanyId = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/company/selList',
    {
      companyIdOrCompanyName: keyWord,
      currCompanyId: currCompanyId,
      size,
      current,
    },
    config
  );
}

// 导出保险公司客户列表
export const exportInsuranceCompanyUrl = '/admin/insuranceCompany/export';

// 检索保险公司列表
export function searchInsuranceCompany(
  keyWord = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/insuranceCompany/fuzzy',
    {
      insuranceName: keyWord,
      size,
      current,
    },
    config
  );
}

// 检索保险公司列表+开票主体
export function searchInsuranceCompanyWithInvoice(
  keyWord = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/insuranceCompany/fuzzyWInvoice',
    {
      insuranceName: keyWord,
      size,
      current,
    },
    config
  );
}
// 检索保险公司列表+开票主体+分页
export function searchInsuranceCompanyWithInvoiceAndPaging(
  keyWord = '',
  companyId,
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/insuranceCompany/fuzzyWInvoicePage',
    {
      companyId,
      insuranceName: keyWord,
      size,
      current,
    },
    config
  );
}
