<template>
  <div>
    <div class="box">
      <el-descriptions :column="3" border labelClassName="table-label">
        <el-descriptions-item label="车牌"
          >{{ detailData.carNumber }}
        </el-descriptions-item>
        <el-descriptions-item label="检车人"
          >{{ detailData.name }}
        </el-descriptions-item>
        <el-descriptions-item label="车型"
          >{{ detailData.automobileBrand }}
        </el-descriptions-item>
        <el-descriptions-item label="进店时间"
          >{{ detailData.time }}
        </el-descriptions-item>
        <el-descriptions-item label="进店里程"
          >{{ detailData.mileage }}
        </el-descriptions-item>
        <el-descriptions-item label="操作门店"
          >{{ detailData.storeName }}
        </el-descriptions-item>
        <el-descriptions-item label="检车结论"
          >{{ detailData.note }}
        </el-descriptions-item>
      </el-descriptions>

      <div class="problem-title">
        <h5>发现问题</h5>
        <span class="red">(2)</span>
      </div>

      <div class="border-table">
        <div class="left">
          <div>
            <h2>一、空调格</h2>
            <span class="red">已不制冷</span>
          </div>
        </div>
        <div class="right">
          <div class="imgs">
            <img
              v-for="(img, index) in imgs"
              :key="img"
              @click="previewImage(imgs, index)"
              :src="img"
              alt=""
            />
          </div>
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                <div class="collapse-item_label">
                  <div>1、更换刹车片</div>
                  <div>
                    工时X5

                    <el-tag size="mini" type="warning">vip</el-tag>
                  </div>
                </div>
              </template>
              <div class="fix-detail">
                <p class="flex-x-between">
                  <span>· 刹车片</span><span>X5</span>
                </p>
                <p class="flex-x-between"><span>· 螺母</span><span>X5</span></p>
                <p>施工技师：张三（技师所在的班组）</p>
                <p>
                  项目外部便签与备注：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item>
              <template slot="title">
                <div class="collapse-item_label">
                  <div>2、洗车</div>
                  <div>工时X5</div>
                </div>
              </template>
              <div class="fix-detail">
                <p class="flex-x-between">
                  <span>· 刹车片</span><span>X5</span>
                </p>
                <p class="flex-x-between"><span>· 螺母</span><span>X5</span></p>
                <p>施工技师：张三（技师所在的班组）</p>
                <p>
                  项目外部便签与备注：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div class="border-table">
        <div class="left">
          <div>
            <h2>二、轮胎</h2>
            <span class="red">左前轮爆胎</span>
          </div>
        </div>
        <div class="right">
          <div class="imgs">
            <img
              v-for="(img, index) in imgs"
              :key="img"
              @click="previewImage(imgs, index)"
              :src="img"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { previewImage as previewImageApi } from '@/utils/fileApi';
export default {
  data() {
    return {
      imgs: [
        'https://picsum.photos/id/45/1440/900',
        'https://picsum.photos/id/25/1440/900',
        'https://picsum.photos/id/16/1440/900',
      ],
      detailData: {
        carNumber: '粤A·SSBB7',
        name: '高园园',
        automobileBrand: '一汽丰田',
        time: '2014-11-18',
        mileage: '5668 KM',
        storeName: 'xxxx店',
        note: '刹车失灵，爆胎了',
      },
    };
  },
  methods: {
    previewImage(imgs, index) {
      previewImageApi({
        images: imgs,
        options: {
          initialViewIndex: index,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 0 0px 30px;
}
.el-descriptions ::v-deep .table-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: $descriptions-label-bg;
}
.problem-title {
  display: flex;
  margin: 45px 0 15px;
  > h5 {
    color: #333;
    font-size: 16px;
    font-weight: 500;
  }
  > span {
    margin-left: 8px;
  }
}
.border-table {
  display: flex;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  & + .border-table {
    margin-top: 30px;
  }
  .left {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > div {
      text-align: center;
      color: #333333;
      > span {
        font-size: 12px;
      }
    }
  }
  .right {
    flex: 1;
    border-left: 1px solid #e6e6e6;
    ::v-deep .el-collapse {
      border: none;
      .el-collapse-item__header {
        // border: none;
        background: $bg;
      }
      .el-collapse-item__wrap {
        border: none;
      }
    }
    .imgs {
      margin-bottom: 20px;
      > img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        & + img {
          margin-left: 10px;
        }
      }
    }
    .collapse-item_label {
      width: 100%;
      padding: 0 15px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > .el-tag {
        margin-left: 5px;

        color: #ff9200;
      }
    }
    .fix-detail {
      padding: 25px;
      > p {
        font-size: 14px;
        color: #666;
        & + p {
          margin-top: 30px;
        }
      }
    }
  }
  .left,
  .right {
    padding: 20px;
  }
}
</style>
