<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodsName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="原厂编码">
              <el-input
                v-model="form.goodsCode"
                placeholder="请输入原厂编码"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span>他店库存</span>
                <el-tooltip
                  content="需要查询其他连锁门店库存余量时，请打开开关"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a"
                  ></i>
                </el-tooltip>
              </template>
              <el-switch v-model="form.includeOtherCompany"></el-switch>
            </el-form-item>
            <el-form-item label="库龄">
              <el-select
                v-model="form.stockAges"
                placeholder="请选择库龄"
                multiple
                clearable
              >
                <!--<el-option label="全部" :value="0"> </el-option>-->
                <el-option label="90天以内" :value="1"> </el-option>
                <el-option label="90-180天" :value="2"> </el-option>
                <el-option label="180-360天" :value="3"> </el-option>
                <el-option label="360天以上" :value="4"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <el-form-item label="自编码">
            <el-input
              v-model="form.ownCode"
              placeholder="请输入自编码"
              clearable
              @keyup.enter.native="getData"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品标准名">
            <el-input
              v-model="form.standardName"
              placeholder="请输入商品标准名"
              clearable
              @keyup.enter.native="getData"
            ></el-input>
          </el-form-item>
          <el-form-item label="适用车型">
            <el-select
              v-model="form.matchCarType"
              filterable
              remote
              placeholder="搜索车型"
              :remote-method="searchCarModelList"
              :loading="searchCarModelListLoading"
              clearable
            >
              <el-option
                v-for="(item, index) in carModelData"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="库存余量">
            <el-select
              v-model="form.inventoryFilterType"
              placeholder="请选择库存余量"
              clearable
            >
              <el-option label="全部" :value="0"> </el-option>
              <el-option label="库存大于0" :value="1"> </el-option>
              <el-option label="库存为0" :value="4"> </el-option>
            </el-select>
          </el-form-item>
          <template v-if="showMore">
            <el-form-item label="商品分类">
              <select-goods-class-cascader
                ref="goodsClass"
                :value="[form.firstCategoryId, form.secondCategoryId]"
                @select="handleSelectGoodsClass"
                checkStrictly
              ></select-goods-class-cascader>
            </el-form-item>
            <el-form-item label="品质">
              <el-select
                v-model="form.manufactoryType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in manufactoryTypeDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div class="btns flex-x-between">
        <span></span>
        <el-button
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        show-summary
        :summary-method="getSummaries"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="90">
          <template #default="{ row }">
            <span class="text_operation blue" @click="handleClick(row)"
              >收发流水</span
            >
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="操作门店"
          prop="company.companyName"
        >
        </el-table-column>
        <el-table-column
          width="120"
          label="商品ID"
          prop="goods.goodsId"
        ></el-table-column>
        <el-table-column width="140" label="适用车型" prop="goods.matchCarType">
        </el-table-column>
        <el-table-column min-width="180" label="商品标准名">
          <template #default="{ row }">
            <span class="blue pointer" @click="handleDetail(row)">{{
              row.goods.standardName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="60" label="品质">
          <template #default="{ row }">
            <span>{{
              row.goods.manufactoryType | dict('manufactoryType')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="原厂编码"
          prop="goods.goodsCode"
        ></el-table-column>
        <el-table-column min-width="180" label="商品名称">
          <template #default="{ row }">
            <span class="blue pointer" @click="handleDetail(row)">{{
              row.goods.goodsName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="单位"
          prop="goods.unit"
        ></el-table-column>
        <el-table-column width="160" label="商品分类">
          <template #default="{ row }">
            <span>{{
              $lodash.get(row, 'goods.firstCategory.categoryName')
            }}</span>
            <span v-if="$lodash.get(row, 'goods.secondCategory.categoryName')"
              >/{{
                $lodash.get(row, 'goods.secondCategory.categoryName')
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="100" label="上次采购价">
          <template #default="{ row }">
            <span v-if="row.goods.lastPurchasePrice !== undefined">{{
              row.goods.lastPurchasePrice | toThousands
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          label="上次采购时间"
          prop="lastPurchaseReceiptDate"
        >
        </el-table-column>
        <el-table-column
          width="130"
          label="上次采购供应商"
          prop="lastPurchaseSupplierName"
        >
        </el-table-column>
        <el-table-column
          width="90"
          label="库龄/天"
          prop="diffLastReceiptDateDays"
        >
        </el-table-column>
        <el-table-column
          width="160"
          label="上次领料出库时间"
          prop="lastMaterialOutboundDate"
        >
        </el-table-column>
        <el-table-column
          width="130"
          label="在途库存余量"
          prop="onWayInventoryTotalNum"
        >
        </el-table-column>
        <el-table-column width="130" label="在途库存成本">
          <template #default="{ row }">
            <span>{{ row.onWayInventoryTotalPrice | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="库存余量(不含在途)"
          prop="inventoryTotalNum"
        >
        </el-table-column>
        <el-table-column width="180" label="总成本(不含在途)">
          <template #default="{ row }">
            <span>{{ row.inventoryTotalPrice | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column width="140" label="库位（余量）">
          <template #default="{ row }">
            <div v-for="(item, index) in row.goodsInventories" :key="index">
              <span>{{ item.storeRoom.storeRoomName }}</span>
              <span v-if="$lodash.get(item, 'location.locationName')"
                >-{{ item.location.locationName }}</span
              >
              <span>({{ item.inventoryNum }})</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="localExportUrl"
      ></export-dialog>
      <inventoryDetailDrawer
        v-if="showInventoryDetailDrawer"
        :visibility.sync="showInventoryDetailDrawer"
        :id="selectedGoodsId"
        :companyId="selectedCompanyId"
      ></inventoryDetailDrawer>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import {
  admin1PagePrefix,
  admin2PagePrefix,
} from '@/config/compatibilityOlderSystems';
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import selectGoodsClassCascader from '@/components/businessComponent/selectGoodsClassCascader.vue';
import inventoryDetailDrawer from '@/views/stock/query/inventorySummary/inventoryDetailDrawer';
// 接口
import { listGoodsInventory, exportUrl } from '@/api/stock/inventory';
import { getCarModelList } from '@/api/customer/car';

import { toThousands } from '@/utils/filters/number';

// 字典数据
import { manufactoryType } from '@/service/dict/dictData';

import { mapState } from 'vuex';

export default {
  name: 'inventorySummary',
  components: {
    ExportDialog,
    pagination,
    selectGoodsClassCascader,
    inventoryDetailDrawer,
    //  ExportDialog
  },
  data() {
    return {
      manufactoryTypeDict: manufactoryType,

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      showEditDialog: false,
      showExportDialog: false,
      localExportUrl: exportUrl,

      form: {
        inventoryFilterType: 1,
        companyIds: [],
      },

      carModelData: [],
      searchCarModelListLoading: false,

      // 展示更多筛选
      showMore: false,

      listData: [],
      // 详情抽屉
      showInventoryDetailDrawer: false,
      selectedGoodsId: '',
      selectedCompanyId: 0,
      inventoryCount: '',
      inventoryPriceTotal: '',
    };
  },

  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
    }),
  },
  activated() {},
  created() {
    this.getData();
    this.form.companyIds[0] = this.companyId;
  },
  methods: {
    getSummaries(param) {
      const sums = [19];
      sums[0] = '合计';
      sums[16] = this.inventoryCount;
      sums[17] = toThousands(this.inventoryPriceTotal);
      return sums;
    },
    /**
     * 选择商品分类
     */
    handleSelectGoodsClass(val) {
      this.form.firstCategoryId = val[0] || '';
      this.form.secondCategoryId = val[1] || '';
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.form.companyIds[0] = this.companyId;
      var data = Object.assign(
        { companyIds: [this.companyId] },
        this.form,
        this.pageOption
      );
      listGoodsInventory(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
        this.inventoryCount = res.inventoryCount;
        this.inventoryPriceTotal = res.inventoryPriceTotal;
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
      this.$refs.goodsClass.clear();
    },

    /**
     * 搜索车型列表
     */
    searchCarModelList(keyWord) {
      this.searchCarModelListLoading = true;
      getCarModelList(
        { msg: keyWord || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          this.carModelData =
            res.records.map((el) => {
              el.id =
                '' +
                el.logoId +
                el.brandId +
                el.seriesId +
                el.modelNumberId +
                el.modelYearId;
              el.name = `${el.logoName}${el.seriesName ? el.seriesName : ''}${
                el.modelNumberName ? el.modelNumberName : ''
              }${el.modelYearName ? ' ' + el.modelYearName : ''}`;
              return el;
            }) || [];
        })
        .finally(() => {
          this.searchCarModelListLoading = false;
        });
    },
    /**
     * 详情
     */
    handleDetail(row) {
      console.log(row);
      this.selectedGoodsId = row.goods.goodsId;
      this.selectedCompanyId = row.company.companyId;
      this.showInventoryDetailDrawer = true;
    },
    /**
     * 跳转收发流水
     */
    handleClick(row) {
      console.log(row);
      window.open(
        admin2PagePrefix +
          `#/stock/query/receiveRecord?recordGoodsId=${row.goods.goodsId}&keepRoute=true`,
        '_blank'
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
.count {
  text-align: right;
  padding: 20px 30px 0 0;
  font-size: 13px;
  color: red;
}
</style>
