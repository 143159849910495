<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-shrink0 flex">
            <el-form-item label="协议编号">
              <el-input
                v-model="form.agreementNumber"
                placeholder="请输入协议编号"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="协议客户">
              <el-input
                v-model="form.agreementClientName"
                placeholder="请输入客户名称"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input
                v-model="form.contactUserOrPhone"
                placeholder="请输入联系人或联系电话"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="725"
                class="btn_search"
                type="primary"
                size="small"
                @click="doSearch"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <template v-if="showMore">
          <div class="flex-warp"></div>
        </template>
      </el-form>
    </template>

    <template>
      <div style="margin-bottom: 14px" class="btns flex-x-between">
        <div></div>
        <el-button
          v-auth="478"
          size="small"
          type="text"
          class="blue"
          @click="downloadAllRecords"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        @row-dblclick="goDetailList"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <span
              v-auth="727"
              class="text_operation blue"
              @click="goDetailList(scope.row)"
              >查看</span
            >
          </template>
        </el-table-column>
        <el-table-column width="180" label="协议编号" prop="agreementNumber">
        </el-table-column>
        <el-table-column
          width="180"
          label="协议客户"
          prop="agreementClientName"
        >
        </el-table-column>
        <el-table-column width="180" label="联系人/联系电话">
          <template slot-scope="scope">
            <div>{{ scope.row.contactUserName }}</div>
            <div>{{ scope.row.contactPhone }}</div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="开票主体" prop="invoicingEntity">
        </el-table-column>
        <el-table-column
          width="180"
          label="自费业务适用门店"
          prop="selfPayingCompany"
        >
        </el-table-column>
        <el-table-column
          width="180"
          label="包干业务适用门店"
          prop="contractForCompany"
        >
        </el-table-column>
        <el-table-column
          width="180"
          label="理赔业务适用门店"
          prop="claimSettlementCompany"
        >
        </el-table-column>
        <el-table-column
          width="180"
          label="索赔业务适用门店"
          prop="claimCompensationCompany"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="建档人"
          prop="creator"
        ></el-table-column>
        <el-table-column
          width="100"
          label="导入人"
          prop="importer"
        ></el-table-column>
        <el-table-column
          width="100"
          label="继承人"
          prop="heir"
        ></el-table-column>
        <el-table-column label="协议价导入" width="80">
          <template slot-scope="scope">
            <span
              v-auth="728"
              class="text_operation blue"
              @click="openImportDialog(scope.row)"
              >导入</span
            >
          </template>
        </el-table-column>
        <el-table-column label="协议价导出" width="80">
          <template slot-scope="scope">
            <span
              v-auth="729"
              class="text_operation blue"
              @click="openExportDialog(scope.row)"
              >导出</span
            >
          </template>
        </el-table-column>
        <el-table-column label="协议价继承" width="80">
          <template slot-scope="scope">
            <span
              v-auth="743"
              class="text_operation blue"
              @click="openExtendsDialog(scope.row)"
              >继承</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 继承协议价选择弹框 -->
      <selectExtendAgreementPriceDialog
        v-if="openExtendsDialogVisibility"
        :visibility.sync="openExtendsDialogVisibility"
        title="继承协议价"
        :agreementId="selectExtendAgreementId"
        @save="saveExtendAgreementSuccess"
      >
      </selectExtendAgreementPriceDialog>
      <!-- 导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :visibility.sync="importDialogVisibility"
        :showTip="false"
        title="服务和商品协议价批量导入"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240813/%EF%BC%88%E6%96%B0%EF%BC%89%E5%AE%A2%E6%88%B7%E5%8D%8F%E8%AE%AE%E4%BB%B7%E5%85%B3%E7%B3%BB%E6%A8%A1%E6%9D%BF.xlsx"
        :uploadFileApi="importApi"
        :getLastResultApi="importResultApi"
        :importFailDetail="importMessageExportUrlApi"
      ></import-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';
import selectExtendAgreementPriceDialog from './components/selectExtendAgreementPriceDialog.vue';

import {
  getValidClientAgreementPriceList,
  validClientAgreementListExportUrl,
  clientAgreementPriceImport,
  getClientAgreementPriceImportResult,
  clientAgreementPriceImportFailedExportUrl,
  getValidClientAgreementPriceDetailList,
  validClientAgreementDetailListExportUrl,
  clientAgreementPriceInfo,
  clientAgreementPriceSave,
  clientAgreementPriceUpdate,
  clientAgreementPriceBatchDelete,
} from '@/api/customer/agreement';
import { downloadFile } from '@/utils/fileApi';
import { mapState } from 'vuex';
// 权限
import { hasAuth } from '@/utils/permissions';
export default {
  name: 'agreementPriceList',
  components: { pagination, importDialog, selectExtendAgreementPriceDialog },
  data() {
    return {
      //importApi: clientAgreementPriceImport,
      importResultApi: getClientAgreementPriceImportResult,
      importMessageExportUrlApi: clientAgreementPriceImportFailedExportUrl,
      validClientAgreementDetailListExportUrlApi:
        validClientAgreementDetailListExportUrl,
      // 导入弹窗可见性
      importDialogVisibility: false,
      importAgreementId: null,
      selectExtendAgreementId: 0,
      openExtendsDialogVisibility: false,
      openExtendConfirmDialogVisibility: false,
      selectAgreementData: {},
      selectExtendAgreementData: {},
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      form: {},
      // 展示更多筛选
      showMore: false,

      // 协议列表数据
      listData: [],
      exportUrl: validClientAgreementListExportUrl,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.getData();
    },
    /**
     * 查询列表
     */
    doSearch() {
      this.getData(true);
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(725);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {};
      var data = Object.assign({}, this.form, this.pageOption, times);
      getValidClientAgreementPriceList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, this.form, {}),
      });
    },
    /**
     * 跳转详情列表
     */
    goDetailList(row) {
      this.$router.push({
        name: 'agreementPriceDetailList',
        query: { agreementId: row.id },
      });
    },
    /**
     * 打开导入
     */
    openImportDialog(row) {
      this.importDialogVisibility = true;
      this.importAgreementId = row.id;
    },
    importApi(file) {
      return clientAgreementPriceImport(file, this.importAgreementId);
    },
    /**
     * 打开导出下载明细
     */
    openExportDialog(row) {
      var res = hasAuth(729);
      if (!res) {
        this.$message.error('没有权限导出协议价明细');
        return;
      }
      downloadFile({
        url: this.validClientAgreementDetailListExportUrlApi,
        data: Object.assign(
          {},
          {
            agreementId: row.id,
          }
        ),
      });
    },
    openExtendsDialog(row) {
      var res = hasAuth(743);
      if (!res) {
        this.$message.error('没有权限操作协议价继承');
        return;
      }
      this.selectExtendAgreementId = row.id;
      this.openExtendsDialogVisibility = true;
    },
    saveExtendAgreementSuccess() {
      this.getData(false);
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}
.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
  color: #888;
  font-size: 14px;
}
.tree-node {
  color: #888;
  font-size: 14px;
}
</style>
