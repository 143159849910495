import http from '@/service/http';

// 登录接口
export function userLogin(data) {
  return http.post('/admin/user/login', data, { onlyReturnData: false });
}

// 退出登录
export function userLogout() {
  return http.get('/admin/user/logout', {
    onlyReturnData: false,
    noHandleError: true,
  });
}

// 检索公司列表
export function searchCompany(
  { userId = '', size = 10, current = 1, companyName = '' },
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/user/company/list',
    {
      userId: userId,
      size,
      current,
      companyName,
    },
    config
  );
}

// 获取当前使用的门店
export function reservedCurrCompany() {
  return http.get('/admin/user/company/reservedCurrCompany');
}

// 保存当前使用的门店
export function companyDetermineCurrCompany(companyId = '', userId = '') {
  return http.post(
    '/admin/user/company/determineCurrCompany',
    { companyId, userId },
    {
      onlyReturnData: false,
    }
  );
}

// 获取当前用户权限
export function getCurrentUserPermission() {
  return http.get('/admin/user/queryAuthMark');
}

// 获取websocket服务器ip
export function getWebsocketIp() {
  return http.get('/admin/im/ipList');
}

/**
 * 获取验证码
 */
export function getVerificationCode(data) {
  return http.post('/admin/user/getVerificationCode', data);
}

/**
 * 手机号码验证
 */
export function numberVerification(data) {
  return http.post('/admin/user/numberVerification', data);
}

/**
 * 修改密码
 */
export function changePassword(data) {
  return http.post('/admin/user/changePassword', data);
}

/**
 * 个人账号信息
 */
export function getAccountInfo() {
  return http.post('/admin/user/accountInfo', {});
}

/**
 * 更换手机号
 */
export function changePhoneNumber(data) {
  return http.post('/admin/user/changePhoneNumber', data);
}

/**
 * 登录超期
 */
export function loginOverdue(phone) {
  return http.post('/admin/user/loginOverdue', { phone });
}

/**
 * 登录提示
 */
export function loginRemind() {
  return http.post('/admin/user/loginRemind', {});
}

/**
 * 强制下线
 */
export function offline(data) {
  return http.post('/admin/user/offline', data, {
    noHandleError: true,
    noShowWaiting: true,
  });
}
