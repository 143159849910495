<template>
  <el-drawer
    title="选择商品"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div style="height: 80%; padding: 0 30px">
      <base-container :default-padding="false">
        <template slot="header">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-input
                size="small"
                v-model="goodsName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getListData"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-input
                size="small"
                v-model="goodsCode"
                placeholder="请输入商品编码"
                clearable
                @keyup.enter.native="getListData"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-button size="small" type="primary" @click="getListData"
                >查询</el-button
              >
            </el-col>
          </el-row>
        </template>
        <div class="content-box">
          <el-table
            ref="multipleTable"
            :data="tableData"
            border
            class="data-table"
            @selection-change="handleSelectionChange"
            @select="selectedData"
          >
            <el-table-column type="selection" width="40"> </el-table-column>
            <el-table-column label="序号" type="index" width="60">
            </el-table-column>
            <el-table-column prop="goodsName" label="商品项目" width="110">
            </el-table-column>
            <el-table-column prop="goodsCode" label="编码" width="100">
            </el-table-column>
            <el-table-column label="本店库存/单位" min-width="120">
              <template #default="{ row }">
                <span>
                  {{ row.inventoryTotalNum }}
                </span>
                <span>
                  {{ row.unit ? `(${row.unit})` : '' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="售价" min-width="100">
              <template #default="{ row }">
                <span>
                  {{ row.price | toThousands }}
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="品质" width="110">
              <template #default="{ row }">
                <span>
                  {{ row.goodsType | dict('goodsType') }}
                </span>
              </template>
            </el-table-column> -->

            <el-table-column prop="matchCarType" label="适用车型" width="110">
            </el-table-column>
            <!-- <el-table-column
              prop="cloudServiceItemId"
              label="云商品编码"
              width="110"
            >
            </el-table-column>
            <el-table-column
              prop="referencePrice"
              label="4S参考价"
              min-width="110"
            >
            </el-table-column> -->
            <el-table-column
              min-width="110"
              prop="categoryName"
              label="所属分类"
            >
            </el-table-column>
          </el-table>
          <div class="class-box">
            <p class="title">服务分类</p>
            <el-tree
              class="custom-tree"
              :data="treeData"
              :props="treeProp"
              highlight-current
              node-key="categoryId"
              @node-click="onNodeClick"
            >
            </el-tree>
          </div>
        </div>
        <template slot="footer">
          <pagination @pageChange="changePage" :total="total"></pagination>
        </template>
      </base-container>
    </div>
    <div class="footer">
      <p>
        <i class="iconfont grow-icon_warn_32 orange"></i>
        <span>共选择商品{{ totalLength }}，本次新增选择{{ addLength }}</span>
      </p>
      <el-button type="primary" size="small" @click="confirmSelect"
        >确 定</el-button
      >
      <el-button size="small" @click="handleClose">取 消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { searchGoodsForCreateCarBill, getGoodsClasses } from '@/api/carBill';
import pagination from '@/components/Pagination.vue';
import { mapState } from 'vuex';
export default {
  name: 'selectGoodsForCarBill',
  props: {
    visibility: { type: Boolean, default: false },
    oldGoodsData: {
      type: Array,
      default: () => [],
    },
  },
  components: { pagination },
  data() {
    return {
      // 列表数据
      tableData: [],
      selectedItems: [],
      treeData: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      treeProp: {
        children: 'subCategories',
        label: 'name',
      },

      // 商品编码
      goodsCode: '',
      // 商品名称
      goodsName: '',
      // 车型名称名称
      modelName: '',

      firstCategoryId: '',
      secondCategoryId: '',
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    addLength() {
      return this.totalLength - this.oldGoodsData.length < 0
        ? '0'
        : this.totalLength - this.oldGoodsData.length;
    },
    totalSelected() {
      return this.$lodash.uniqBy(
        // this.selectedItems.concat(this.oldGoodsData),
        this.selectedItems,
        'goodsId'
      );
    },
    totalLength() {
      return this.totalSelected.length;
    },
    addItems() {
      return this.$lodash.differenceBy(
        this.selectedItems,
        this.oldGoodsData,
        'goodsId'
      );
    },
  },
  created() {
    this.getStatistic();
    this.getListData();
    this.selectedItems = this.$lodash.cloneDeep(this.oldGoodsData || []);
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getListData();
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * table选中事件
     */
    handleSelectionChange(val) {
      this.selectedItems = val;
      // this.selectedItems = this.$lodash.uniqBy(
      //   this.selectedItems.concat(val),
      //   'goodsId'
      // );
    },

    selectedData(val, row) {
      if (!val.find((item) => item.goodsId == row.goodsId)) {
        this.selectedItems = this.selectedItems.filter(
          (item) => item.goodsId != row.goodsId
        );
      }
    },
    /**
     * 同步选中状态
     */
    syncSelectedStatus() {
      this.$nextTick().then(() => {
        if (this.totalSelected?.length) {
          var serviceItemId = this.totalSelected.map((item) => item.goodsId);
          var shouldBeSelected = this.tableData.filter((item) =>
            serviceItemId.includes(item.goodsId)
          );
          shouldBeSelected.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    /**
     * 确认选择
     */
    confirmSelect() {
      // 调用自定义事件，传入参数
      this.$emit('select', this.selectedItems);
      this.$emit('update:visibility', false);
    },

    /**
     * 获取数据
     */
    getListData() {
      var form = {
        companyId: this.companyId,
        // 商品编码
        goodsCode: this.goodsCode,
        // 商品名称
        goodsName: this.goodsName,
        // 车型名称名称
        modelName: this.modelName,
      };
      if (this.firstCategoryId) {
        form.firstCategoryId = this.firstCategoryId;
      }
      if (this.secondCategoryId) {
        form.secondCategoryId = this.secondCategoryId;
      }

      let data = Object.assign({}, form, this.pageOption);
      searchGoodsForCreateCarBill(data, { noShowWaiting: false }).then(
        (res) => {
          this.tableData = res.records;
          this.total = res.total;
          this.syncSelectedStatus();
        }
      );
    },
    /**
     * 获取分类数据
     */
    getStatistic() {
      getGoodsClasses(this.companyId).then((res) => {
        // 添加全部选项
        res.unshift({
          categoryId: 0,
          name: '全部',
        });
        this.treeData = res;
      });
    },
    /**
     * 点击节点
     */
    onNodeClick(obj, node, origin) {
      if (obj.categoryId) {
        if (obj.parentId) {
          this.firstCategoryId = obj.parentId;
          this.secondCategoryId = obj.categoryId;
        } else {
          this.firstCategoryId = obj.categoryId;
          this.secondCategoryId = 0;
        }
      } else {
        this.firstCategoryId = '';
        this.secondCategoryId = 0;
      }

      this.getListData();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-box {
  height: 100%;
  display: flex;
  padding-top: 20px;
  box-sizing: border-box;
  .data-table {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::v-deep .el-table__header-wrapper {
      flex-shrink: 0;
    }
    ::v-deep .el-table__body-wrapper {
      flex: 1;
      overflow: auto;
    }
  }
}

.class-box {
  width: 30%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}

.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 109px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 60px;
  // border-top: 1px solid #e4e4e4;
  // border-bottom: 1px solid #e4e4e4;
  box-shadow: -4px 0px 30px 0px rgb(0 0 0 / 10%);
  background: #fff;
  z-index: 99;
}
.footer > p {
  margin-right: 30px;
}
.footer span {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
}
</style>
