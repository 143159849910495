<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="90px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="门店名称" prop="companyId">
            <el-select
              v-model="editForm.companyId"
              filterable
              placeholder="请选择门店名称"
              @change="handleCompanyChange"
            >
              <el-option
                v-for="item in companyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="用户名称" prop="userId">
            <el-select
              v-model="editForm.userId"
              filterable
              clearable
              placeholder="请选择用户名称"
            >
              <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.realName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="启用状态" prop="isActive">
            <el-switch
              v-model="editForm.isActive"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0"></el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入备注"
              v-model="editForm.remark"
              maxlength="100"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0"></el-col>
      </el-row>
    </el-form>

    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';

// 接口
import {
  saveData,
  updateData,
  detailData,
  listAllCompany,
  listUserByCompanyId,
} from '@/api/system/GyBillRepairNotificationSetting';

export default {
  name: 'EditGyBillRepairNotificationSettingDialog',
  components: {},
  props: {
    visibility: Boolean,
    id: [Number, String],
  },
  data() {
    return {
      // 表单信息
      editForm: {
        isActive: 1,
      },
      companyList: [],
      userList: [],
      // 表单规则
      rules: {
        companyId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择门店名称！',
          },
        ],
        userId: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择用户名称！',
          },
        ],
        isActive: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择启用状态！',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.id ? '编辑返修追溯通知配置' : '新增返修追溯通知配置';
    },
  },
  created() {
    this.findAllCompany();
    if (this.id) {
      this.getData();
    }
  },
  methods: {
    findAllCompany() {
      listAllCompany({}).then((res) => {
        this.companyList = res;
      });
    },
    handleCompanyChange(val) {
      listUserByCompanyId({
        companyId: val,
      }).then((res) => {
        this.userList = res;
      });
    },
    findUserByCompany(companyId) {
      listUserByCompanyId({
        companyId: companyId,
      }).then((res) => {
        this.userList = res;
      });
    },
    /**
     * 获取数据
     */
    getData() {
      detailData({ id: this.id }).then((res) => {
        this.editForm = res || {};
        this.userList = [];
        this.$set(this.editForm, 'isActive', res.isActive ? 1 : 0);
        this.userList.push(res.userInfo);
        this.handleCompanyChange(res.companyId);
      });
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var api = this.id ? updateData : saveData;
          var body = Object.assign({}, this.editForm, {});
          api(body).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
