import http from '@/service/http';
import store from '@/store';

const defaultListOption = {
  current: 1,
  size: 10,
};

// 项目标签列表
export function getLabelList(
  data = defaultListOption,
  config = {
    noShowWaiting: true,
  }
) {
  data = Object.assign(
    { companyId: store?.state?.user?.userInfo?.companyId },
    data || {}
  );
  return http.post('/admin/car/billLabel/list', data, config);
}
