<template>
  <el-dialog
    :visible="visibility"
    width="400px"
    title="毛利率标准"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div class="container">
      <p class="tip-word">
        <i class="el-icon-warning orange"></i>
        设置各项毛利率标准，低于设置值时将预警
      </p>
      <table class="set-table">
        <thead>
          <tr>
            <th>业务类型</th>
            <th>毛利率(%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>自费</td>
            <td>
              <number-input
                v-model="selfPayGrossProfitRate"
                :min="0"
                :max="100"
                :enabledDecimals="false"
                placeholder="请输入0-100的正整数"
              ></number-input>
            </td>
          </tr>
          <tr>
            <td>包干</td>
            <td>
              <number-input
                v-model="contractForGrossProfitRate"
                :min="0"
                :max="100"
                :enabledDecimals="false"
                placeholder="请输入0-100的正整数"
              ></number-input>
            </td>
          </tr>
          <tr>
            <td>理赔</td>
            <td>
              <number-input
                v-model="claimSettlementGrossProfitRate"
                :min="0"
                :max="100"
                :enabledDecimals="false"
                placeholder="请输入0-100的正整数"
              ></number-input>
            </td>
          </tr>
          <tr>
            <td>索赔</td>
            <td>
              <number-input
                v-model="claimForCompensationGrossProfitRate"
                :min="0"
                :max="100"
                :enabledDecimals="false"
                placeholder="请输入0-100的正整数"
              ></number-input>
            </td>
          </tr>
          <tr>
            <td>合计</td>
            <td>{{ totalGrossProfitRate || '请先设置各类型毛利率标准' }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSave"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';

import {
  getGrossMarginStandard,
  setGrossMarginStandard,
} from '@/api/commandCenter';

export default {
  name: 'setGrossMarginStandardDialog',
  components: {},
  props: {
    visibility: { type: Boolean, default: false },
  },
  data() {
    return {
      // 自费毛利率
      selfPayGrossProfitRate: '',
      // 包干毛利率
      contractForGrossProfitRate: '',
      // 理赔毛利率
      claimSettlementGrossProfitRate: '',
      // 索赔毛利率
      claimForCompensationGrossProfitRate: '',
      // 合计毛利率
      // totalGrossProfitRate: '',
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    totalGrossProfitRate() {
      return (
        (+this.selfPayGrossProfitRate +
          +this.contractForGrossProfitRate +
          +this.claimSettlementGrossProfitRate +
          +this.claimForCompensationGrossProfitRate) /
          4 || ''
      );
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * 获取当前设定的毛利率标准
     */
    getData() {
      getGrossMarginStandard({ id: this.companyId }).then((res) => {
        this.selfPayGrossProfitRate = res.selfPayGrossProfitRate ?? '';
        this.contractForGrossProfitRate = res.contractForGrossProfitRate ?? '';
        this.claimSettlementGrossProfitRate =
          res.claimSettlementGrossProfitRate ?? '';
        this.claimForCompensationGrossProfitRate =
          res.claimForCompensationGrossProfitRate ?? '';
      });
    },

    /**
     * 保存
     */
    handleSave() {
      //  校验必填
      if (!this.selfPayGrossProfitRate) {
        this.$message.warning('请先设置自费毛利率标准');
        return;
      }
      if (!this.contractForGrossProfitRate) {
        this.$message.warning('请先设置包干毛利率标准');
        return;
      }
      if (!this.claimSettlementGrossProfitRate) {
        this.$message.warning('请先设置理赔毛利率标准');
        return;
      }
      if (!this.claimForCompensationGrossProfitRate) {
        this.$message.warning('请先设置索赔毛利率标准');
        return;
      }
      setGrossMarginStandard({
        companyId: this.companyId,
        selfPayGrossProfitRate: this.selfPayGrossProfitRate,
        contractForGrossProfitRate: this.contractForGrossProfitRate,
        claimSettlementGrossProfitRate: this.claimSettlementGrossProfitRate,
        claimForCompensationGrossProfitRate:
          this.claimForCompensationGrossProfitRate,
        totalGrossProfitRate: +this.totalGrossProfitRate,
      }).then((res) => {
        this.$message.success('保存成功');
        this.handleClose();
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-word {
  margin: 15px 0;
  font-size: 13px;
  color: #666;
}
.set-table {
  border-collapse: collapse;
  width: 100%;
  color: #606266;
}

.set-table th,
.set-table tr {
  height: 45px;
  line-height: 45px;
}

.set-table th,
.set-table td {
  box-sizing: border-box;
  border: 1px solid #ebeef5;
  padding: 6px 12px;
  text-align: center;
}
.set-table td {
  &:first-child {
    width: 100px;
  }
}

// .set-table th {
//   background-color: #f5f5f5;
// }

// .set-table tbody tr:nth-child(even) {
//   background-color: #f9f9f9;
// }
</style>
