import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
import { MessageBox } from 'element-ui';
import { hasAuth, verifyAuth } from '@/utils/permissions';

import routes from './routes';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

// 全局解析守卫
const beforeEach = async (
  {
    //元数据
    meta = {},
    //名称
    name,
    //路径
    path,
    //全路径
    fullPath,
    //路径参数
    params = {},
    //查询参数
    query = {},
    hash = '',
    //已匹配
    // matched,
  },
  from,
  next
) => {
  console.log(`尝试跳转：${path}`);

  // 需要进行权限控制
  if (meta.permission) {
    try {
      await verifyAuth(meta.permission, false);
    } catch {
      next('login');
      // next(false);
      return;
    }
    // let result = hasAuth(meta.permission);
    // if (!result) {
    //   MessageBox.alert('权限不足！', '提示');
    //   // next('login');
    //   return;
    // }
  }

  let title = meta.title || name || '未命名页面';

  if (!meta.isPage) {
    store.commit('ADD_TAG', {
      title,
      fullPath,
      name,
      path,
      query,
      meta,
      params,
      hash,
    });
  }
  console.log(`允许跳转：${path}`);
  next();
};

const router = new VueRouter({
  routes,
});

router.beforeResolve(beforeEach);

export default router;
