<template>
  <div>
    <p class="undertake-title">
      包干金额分摊规则说明（仅适用“固定金额”、“固定单价”模式）：
    </p>
    <div class="tip-block">
      <p class="tip-title">1、结账当月预分摊制：</p>
      <p class="tip-line">M日预分摊金额 = 包干总额 ÷ 当月天数 × M</p>
      <p class="tip-line">
        单笔分摊金额=单笔结算金额 ×（M日预分摊金额÷M日累计结算金额）
      </p>
      <p class="tip-line">
        单项分摊金额=单项结算总额 ×（单笔分摊金额÷单笔结算金额）
      </p>
      <p class="tip-line">
        注：仅M日累计结算金额大于M日预分摊金额时才进行预分摊
      </p>
    </div>
    <div class="tip-block">
      <p class="tip-title">2、结账次月开始正式分摊制：</p>
      <p class="tip-line">
        结账次月，自动跑批，形成正式分摊，本月分摊金额完结，次月结算的单据纳入下个月的包干分摊金额计算。
      </p>
      <p class="tip-line">注：仅累计结算金额大于包干总额时才进行分摊</p>
    </div>
    <div style="height: 32px; margin: 30px 0" class="flex-y-center">
      <el-switch :value="detail.beContract"></el-switch>
      <p style="margin-left: 15px; color: #999">
        {{ detail.beContract ? '已启用包干业务' : '未启用包干业务' }}
      </p>
    </div>
    <div v-if="detail.undertakeType == 0">
      <div class="title">固定金额</div>
      <div class="title">包干金额每月固定，不限车辆台数</div>
      <div class="type1">
        <div>首月包干金额：{{ detail.firstMonthPayment | toThousands }}</div>
        <div>中间月包干金额：{{ detail.middMonthPayment | toThousands }}</div>
        <div>末月包干金额：{{ detail.endMonthPayment | toThousands }}</div>
      </div>
    </div>
    <div v-else-if="detail.undertakeType == 1">
      <div class="title">固定单价</div>
      <div class="flex-x-between flex-y-center">
        <div class="title">
          包干金额每月非固定，每月包干金额 = 每月包干车辆台数 x 包干单价
        </div>
        <div class="title price">
          包干单价:
          {{ $lodash.get(tableData, '[0].moneyAmout', 0) | toThousands }}
        </div>
      </div>
      <el-table :data="tableData" border>
        <el-table-column type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column prop="f1" label="月份">
          <template #default="{ row }">{{ row.title }}</template>
        </el-table-column>
        <el-table-column prop="carAmount" label="包干车辆（台）">
        </el-table-column>
        <el-table-column label="包干单价">
          <template #default="{ row }">{{
            row.moneyAmout | toThousands
          }}</template>
        </el-table-column>
        <el-table-column label="包干金额">
          <template #default="{ row }">{{
            (row.carAmount * row.moneyAmout) | toThousands
          }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="detail.undertakeType == 2">
      <div class="title">单据金额比例</div>
      <div class="title">
        包干金额每月按单据金额百分比，每月包干金额=每月包干单据金额 x 比例
      </div>
      <div class="type1">比例（%）：{{ detail.contractRatio }}</div>
    </div>
    <div v-else-if="detail.undertakeType == 3">
      <div class="title">理赔返点比例</div>
      <div class="title">包干金额 = 当月已结账理赔单据金额 * 比例</div>
      <div class="type1">比例（%）：{{ detail.returnPoint }}</div>
    </div>
    <div v-if="[0, 1].includes(detail.vehicleRange)" class="title">
      车辆范围
    </div>
    <div v-if="[0, 1].includes(detail.vehicleRange)" class="type1">
      {{ detail.vehicleRange ? '部分车辆' : '全部车辆' }}
      <!-- 包干车辆范围 -->
      <el-button
        v-if="detail.beContract && detail.vehicleRange"
        type="primary"
        style="margin-left: 10px"
        @click="exportContractCarList"
        >导出车辆清单</el-button
      >
    </div>
    <div v-if="carScopeImportResult">
      <p class="result">
        成功<span class="primary"
          >{{ carScopeImportResult.importSuccessNum }}条</span
        >，失败<span class="red"
          >{{ carScopeImportResult.importFailNum }}条</span
        >；
      </p>
      <p class="result">
        操作人：{{ carScopeImportResult.operator }}，操作时间：{{
          carScopeImportResult.operateDate
        }}
        <span
          v-if="carScopeImportResult.importFailNum"
          class="blue pointer"
          @click="downloadCarScopeImportResult"
          >查看详情</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import Big from 'big.js';
import { getAgreementUndertake } from '@/api/customer/agreement';
import {
  contractForCarImportResult,
  contractForCarImportMessageExportUrl,
  contractForCarListExportUrl,
} from '@/api/system/dataImport';
import { getApartMonths } from '@/utils/tools/date';

import { downloadFile } from '@/utils/fileApi';
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      Big,
      tableData: [],
      carScopeImportResult: null,
    };
  },
  created() {
    if (this.detail.undertakeType == 1) {
      getAgreementUndertake(this.detail.id).then((res) => {
        res = this.setTitle(res);
        this.tableData = res;
      });
    }

    // 开启包干时，获取车辆范围导入结果
    if (this.detail.vehicleRange === 1) {
      contractForCarImportResult({ clientId: this.detail.clientId }).then(
        (res) => {
          this.carScopeImportResult = res;
        }
      );
    }
  },
  methods: {
    setTitle(res) {
      let months = getApartMonths(this.detail.startDate, this.detail.endDate);
      return res.map((item, i) => {
        item.title = months[i];
        return item;
      });
    },
    exportContractCarList() {
      const data = {
        clientId: this.detail.clientId,
        agreementId: this.detail.id,
      };
      downloadFile({ url: contractForCarListExportUrl, data: data });
    },
    /**
     * 下载车辆范围导入结果
     */
    downloadCarScopeImportResult() {
      downloadFile({ url: contractForCarImportMessageExportUrl, data: {} });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 30px 0;
  color: #333;
  font-size: 14px;
}
.type1 {
  display: flex;
  padding: 0 30px;
  vertical-align: middle;
  line-height: 40px;
  > div {
    width: 33%;
    font-size: 14px;
    color: $orange;
  }
}
.price {
  font-size: 14px;
  color: $orange;
}

.undertake-title {
  margin: 20px 0;
  font-size: 14px;
  color: #333;
}
.tip-block {
  margin-bottom: 24px;
  font-size: 14px;
  .tip-title {
    color: #666;
  }
  .tip-line {
    color: #999;
    margin: 8px 15px 0;
  }
}

.result {
  margin-top: 16px;
  font-size: 14px;
  color: #666;
}
</style>
