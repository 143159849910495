export const levelIcons = {
  0: 'grow-icon_grade',
  1: 'grow-icon_grade_B',
  2: 'grow-icon_grade_C',
};

export const levelIconsWord = {
  0: 'A级',
  1: 'B级',
  2: 'C级',
};

export const levelIconsOther = {
  A级: 'grow-icon_grade',
  B级: 'grow-icon_grade_B',
  C级: 'grow-icon_grade_C',
};
