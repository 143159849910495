<template>
  <base-container
    ref="baseContainer"
    customStyle="background: #f1f7fa;"
    customMainStyle="margin-bottom: 16px;"
    :defaultPadding="false"
  >
    <template>
      <el-form
        style="background: #fff"
        class="filter-form"
        label-width="80px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="车系" style="margin-left: -30px">
              <el-input
                v-model="carSeriesName"
                placeholder="请输入车系"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="车型简称">
              <el-input
                v-model="abbreviatedName"
                placeholder="请输入车型简称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div class="container">
        <div class="left">
          <div>
            <div class="title">品牌车系</div>
            <div>
              <el-input
                size="small"
                v-model="filterWord"
                placeholder="请输入车系名称筛选"
                clearable
              ></el-input>
            </div>
          </div>
          <ul ref="tree" style="margin-top: 16px" class="car-brand-tree_ul">
            <li
              class="car-brand-item_li"
              v-for="(lv1, index) in treeDataFiltered"
              :key="index"
            >
              <div
                class="car-brand-name_div"
                :class="{
                  'has-children': lv1.childrenFiltered.length,
                  active: lv1ActiveId == lv1.id,
                }"
                @click="handleLv1Click(lv1)"
              >
                <img style="margin-right: 5px" :src="lv1.imagePath" />
                <span class="car-brand-name_text">
                  {{ lv1.name }}
                  ({{ lv1.childrenFiltered.length }})
                </span>

                <i
                  v-auth="588"
                  class="edit-icon iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                  @click.stop="editLogo(lv1)"
                ></i>

                <i
                  v-show="lv1.childrenFiltered.length"
                  class="item-expand_icon newicon grow-icon-arrow-right"
                  :class="{ active: lv1.showChild }"
                ></i>
              </div>
              <ul class="tree-item_ul" v-show="lv1.showChild">
                <li
                  v-for="(lv2, index) in lv1.childrenFiltered"
                  :key="index"
                  class="tree-item_li"
                >
                  <div
                    :class="{
                      'tree-item-name_div': true,
                      'flex-x-between': true,
                      active: lv2ActiveId == lv2.id,
                    }"
                    @click="handleLv2Click(lv2)"
                  >
                    <span>
                      {{ lv2.name }}
                    </span>
                    <i
                      v-auth="588"
                      class="edit-icon iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                      @click.stop="editSeries(lv2)"
                    ></i>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="right">
          <div class="right-content">
            <div style="margin-bottom: 16px" class="flex-x-between">
              <div>
                <el-button v-auth="587" type="primary" size="small" @click="add"
                  >新增</el-button
                >
              </div>
              <div>
                <el-button
                  v-auth="718"
                  type="primary"
                  size="small"
                  @click="importCarSeriesWithDisable"
                  >导入车系禁用</el-button
                >
                <el-button
                  v-auth="719"
                  type="primary"
                  size="small"
                  @click="exportCarSeriesWithDisable"
                  >导出车系禁用</el-button
                >
              </div>
            </div>

            <el-table :data="listData" border>
              <el-table-column type="index" label="序号" width="60">
              </el-table-column>
              <el-table-column label="操作" width="60">
                <template #default="{ row }">
                  <span
                    v-auth="588"
                    class="text_operation blue"
                    @click="handleEdit(row)"
                    >编辑</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                width="125"
                label="品牌"
                prop="logoName"
              ></el-table-column>
              <el-table-column
                min-width="100"
                label="车系"
                prop="seriesName"
              ></el-table-column>
              <el-table-column
                width="100"
                label="型号"
                prop="modelNumberName"
              ></el-table-column>
              <el-table-column
                width="80"
                label="年份"
                prop="modelYearName"
              ></el-table-column>
              <el-table-column
                min-width="140"
                label="标准名称"
                prop="standardName"
              >
                <template #default="{ row }">
                  {{ row.logoName }}{{ row.seriesName }} {{ row.modelNumberName
                  }}{{ row.modelYearName }}
                </template>
              </el-table-column>
              <el-table-column
                width="120"
                label="车型简称"
                prop="abbreviatedName"
              >
              </el-table-column>
            </el-table>
          </div>
          <pagination
            class="pagination-box"
            @pageChange="changePage"
            :total="total"
          ></pagination>
        </div>
      </div>
    </template>
    <edit-car-model-dialog
      v-if="showCarModelEditDialog"
      :visibility.sync="showCarModelEditDialog"
      :detail="editModelDetail"
      @save="init"
    ></edit-car-model-dialog>
    <edit-car-brand-dialog
      v-if="showCarLogoEditDialog"
      :visibility.sync="showCarLogoEditDialog"
      :detail="editLogoDetail"
      @save="init"
    ></edit-car-brand-dialog>
    <edit-car-series-dialog
      v-if="showCarSeriesEditDialog"
      :visibility.sync="showCarSeriesEditDialog"
      :detail="editSeriesDetail"
      @save="init"
    ></edit-car-series-dialog>

    <!-- 导入弹窗 -->
    <import-dialog
      v-if="importDialogVisibility"
      :visibility.sync="importDialogVisibility"
      :showTip="false"
      title="导入车系禁用"
      templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/%E8%BD%A6%E7%B3%BB%E5%90%AF%E7%94%A8%E7%A6%81%E7%94%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-20240813085603.xlsx"
      :uploadFileApi="seriesEnableOrDisableImportApi"
      :getLastResultApi="seriesEnableOrDisableImportResultApi"
      :importFailDetail="seriesEnableOrDisableImportFailResultExportUrlApi"
    ></import-dialog>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
// import editGoodsAliasDialog from './components/editGoodsAliasDialog';
// import selectGoodsClassCascader from '@/components/businessComponent/selectGoodsClassCascader.vue';
import editCarModelDialog from './editCarModelDialog.vue';
import editCarBrandDialog from './editCarBrandDialog.vue';
import editCarSeriesDialog from './editCarSeriesDialog.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';
// 接口
import {
  carModelsList as getTreeDataApi,
  seriesEnableOrDisableImport,
  seriesEnableOrDisableImportResult,
  seriesEnableOrDisableImportFailResultExportUrl,
  carSeriesEnableOrDisableExportUrl,
} from '@/api/customer/car';
import { downloadFile } from '@/utils/fileApi';
import { getModelList } from '@/api/customer/carModelManage';

// 字典数据
// import { enableState } from '@/service/dict/dictData';

import { mapState } from 'vuex';

export default {
  name: 'carModelList',
  components: {
    // editGoodsAliasDialog,
    // selectGoodsClassCascader,
    pagination,
    editCarModelDialog,
    editCarBrandDialog,
    editCarSeriesDialog,
    importDialog,
  },
  data() {
    return {
      addDialogVisibility: false,
      importDialogVisibility: false,
      seriesEnableOrDisableImportApi: seriesEnableOrDisableImport,
      seriesEnableOrDisableImportResultApi: seriesEnableOrDisableImportResult,
      seriesEnableOrDisableImportFailResultExportUrlApi:
        seriesEnableOrDisableImportFailResultExportUrl,
      carSeriesEnableOrDisableExportUrlApi: carSeriesEnableOrDisableExportUrl,
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // 弹窗控制
      showCarModelEditDialog: false,

      editLogoDetail: {},
      showCarLogoEditDialog: false,
      editSeriesDetail: {},

      showCarSeriesEditDialog: false,

      // 查询条件
      carSeriesName: '',
      abbreviatedName: '',

      // 列表数据
      listData: [],

      treeData: [],
      lv1ActiveId: '',
      lv2ActiveId: '',

      filterWord: '',
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    treeDataFiltered() {
      return this.treeData.filter((logo) => {
        // 品牌搜索，搜索到了，获取所有子节点，并尝试继续搜索，如果子节点搜索不到，则展示所有
        if (logo.name?.toLowerCase().includes(this.filterWord.toLowerCase())) {
          const list = logo.children.filter((series) =>
            series.name?.toLowerCase().includes(this.filterWord.toLowerCase())
          );
          if (list.length) {
            logo.childrenFiltered = list;
            return true;
          } else {
            logo.childrenFiltered = logo.children;
            return true;
          }
        } else {
          const list = logo.children.filter((series) =>
            series.name?.toLowerCase().includes(this.filterWord.toLowerCase())
          );
          if (list.length) {
            logo.childrenFiltered = list;
            return true;
          }
          return false;
        }
      });
    },
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.getData();
      this.getTreeData();
    },
    getTreeData() {
      getTreeDataApi().then((res) => {
        var list =
          res.data?.letterList?.map((logo) => {
            var series = [];
            logo.children?.forEach((el) => {
              el.children?.forEach((val) => {
                series.push({
                  id: val.id,
                  name: val.name,
                  logoId: logo.id,
                  logoName: logo.name,
                  brandId: el.id,
                  brandName: el.name,
                });
              });
            });
            return {
              id: logo.id,
              name: logo.name,
              imagePath: logo.imagePath,
              prefixLetter: logo.prefixLetter,
              children: series,
            };
          }) || [];
        this.treeData = list;

        if (this.treeData.length) {
          this.lv1ActiveId = this.treeData[0].id;
          this.$set(this.treeData[0], 'showChild', true);
          if (this.treeData[0]?.children?.length) {
            this.lv2ActiveId = this.treeData[0].children[0]?.id;
            this.getData();
          }
        }
        this.$nextTick().then(() => {
          this.$refs.tree.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        });
      });
    },
    /**
     * 点击一级
     */
    handleLv1Click(item) {
      if (item.children?.length && !item.showChild) {
        this.treeData.forEach((logo) => {
          this.$set(logo, 'showChild', false);
        });
        this.$set(item, 'showChild', true);
        this.lv1ActiveId = item.id;
        this.lv2ActiveId = '';
        // this.getData();
      } else {
        this.$set(item, 'showChild', false);
      }
    },
    /**
     * 点击二级
     */
    handleLv2Click(item) {
      this.lv1ActiveId = item.logoId;
      this.lv2ActiveId = item.id;
      this.getData();
    },
    /**
     * 编辑品牌
     */
    editLogo(logo) {
      console.log(logo);
      // this.editLogoDetail = this.$lodash.clone(logo);
      const { id, name, imagePath, prefixLetter } = logo;
      this.editLogoDetail = { id, name, imagePath, prefixLetter };
      this.showCarLogoEditDialog = true;
    },
    /**
     * 编辑车系
     */
    editSeries(series) {
      console.log(series);
      this.editSeriesDetail = series;
      this.showCarSeriesEditDialog = true;
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign(
        {},
        { msg: this.carSeriesName, abbreviatedName: this.abbreviatedName },
        this.pageOption
      );
      if (this.carSeriesName || this.abbreviatedName) {
        this.lv1ActiveId = '';
        this.lv2ActiveId = '';
      } else {
        if (this.lv1ActiveId) {
          data.logoId = this.lv1ActiveId;
        }
        if (this.lv2ActiveId) {
          data.seriesId = this.lv2ActiveId;
        }
      }

      getModelList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },

    /**
     * 新增
     */
    add() {
      this.editModelDetail = {};
      this.$nextTick().then(() => {
        this.showCarModelEditDialog = true;
      });
    },

    /**
     * 编辑
     */
    handleEdit(row) {
      this.editModelDetail = row;
      this.$nextTick().then(() => {
        this.showCarModelEditDialog = true;
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.carSeriesName = '';
      this.abbreviatedName = '';
    },
    importCarSeriesWithDisable() {
      this.importDialogVisibility = true;
    },
    exportCarSeriesWithDisable() {
      downloadFile({
        url: this.carSeriesEnableOrDisableExportUrlApi,
        data: {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}
.container {
  width: 100%;
  height: calc(100% - 89px);
  display: flex;
  padding-top: 16px;
  box-sizing: border-box;
  .left {
    width: 259px;
    // height: calc(100% - 32px);
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-sizing: border-box;
    // overflow: auto;
    .title {
      height: 52px;
      background: #f5f7fa;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 16px;
      box-sizing: border-box;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #242526;
      line-height: 20px;
      user-select: none;
    }
    .car-brand-tree_ul {
      height: calc(100% - 108px);
      margin-top: 16px;
      flex: 1;
      overflow: auto;
      box-sizing: border-box;
      .car-brand-item_li {
        position: relative;
        .car-brand-name_div {
          height: 37px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          color: #242526;
          &.active .car-brand-name_text {
            color: $primary;
          }
          &:hover {
            &:not(.has-children) {
              background: rgba(51, 171, 121, 0.1);
            }
            .car-brand-name_text,
            .item-expand_icon {
              color: $primary;
            }
            .edit-icon {
              display: inline;
            }
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        & + .car-brand-item_li {
          margin-top: 2px;
        }

        .car-brand-name_text {
          color: #242526;
          font-size: 14px;
          font-weight: 600;
        }
        .item-expand_icon {
          position: absolute;
          right: 8px;
          font-size: 14px;
          color: #606266;
          transition: 0.2s;
          transform: rotate(0deg);
          &.active {
            transform: rotate(90deg);
          }
        }

        .edit-icon {
          display: none;
          font-size: 12px;
          color: $primary;
        }

        .tree-item_ul {
          margin-left: 15px;
          padding-top: 6px;
          // position: absolute;
          .tree-item_li {
            // height: 32px;
            // display: flex;
            // align-items: center;
            // flex-direction: column;

            box-sizing: border-box;
            cursor: pointer;
            color: #3c3d40;
            font-size: 14px;
            font-weight: 400;
            & + .tree-item_li {
              margin-top: 2px;
            }
            .tree-item-name_div {
              position: relative;
              padding: 8px 8px 8px 20px;
              border-radius: 2px;
              &.active,
              &:hover {
                color: $primary;
              }
              &:hover,
              &.active {
                background: rgba(51, 171, 121, 0.1);
                .edit-icon {
                  display: inline;
                }
              }
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    background: #fff;
    margin-left: 16px;
    border-radius: 4px;
    position: relative;
    .right-content {
      flex: 1;
      // height: 100%;
      // margin-left: 16px;
      // padding: 8px;
      // background: #fff;
      // min-width: 0;
      padding-bottom: calc(4vh + 32px);
      overflow: auto;
    }
    .pagination-box {
      width: calc(100% - 48px);
      background: #fff;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      text-align: center;
      z-index: 2;
    }
  }
}
</style>
