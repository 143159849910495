<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>操作门店</div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="通知人">
              <el-input
                v-model="form.userName"
                placeholder="请输入通知人"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否启用">
              <el-select v-model="form.isActive" placeholder="请选择启用状态">
                <el-option
                  v-for="item in availableStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                v-auth="640"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button v-auth="642" size="small" class="btn_insert" @click="add"
            >新增</el-button
          >
          <el-button
            size="small"
            class="btn_insert"
            style="height: 35px"
            v-auth="643"
            @click.native="batchOperation(2, '批量删除')"
            >批量删除</el-button
          >
        </div>

        <el-button
          v-auth="641"
          size="small"
          type="text"
          class="blue"
          @click="downloadAllRecords()"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="60"
          prop="selection"
          align="center"
        >
        </el-table-column>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <span
              v-auth="643"
              class="text_operation blue"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column width="200" label="门店名称" prop="companyName">
        </el-table-column>
        <el-table-column width="200" label="用户名称" prop="userName">
        </el-table-column>
        <el-table-column width="180" label="是否启用" prop="isActiveStr">
        </el-table-column>
        <el-table-column width="300" label="备注说明" prop="remark">
        </el-table-column>
      </el-table>
      <EditGyBillRepairNotificationSettingDialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :id="editId"
        @save="getData"
      ></EditGyBillRepairNotificationSettingDialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import EditGyBillRepairNotificationSettingDialog from './EditGyBillRepairNotificationSettingDialog.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import { hasAuth } from '@/utils/permissions';
import { downloadFile } from '@/utils/fileApi';
import {
  listData,
  deleteData,
  exportDataUrl,
} from '@/api/system/GyBillRepairNotificationSetting';

import { getInventoryGoodsControlModuleList } from '@/api/stock/goodsControl';

import { mapState } from 'vuex';

export default {
  name: 'GyBillRepairNotificationSetting',
  components: {
    pagination,
    EditGyBillRepairNotificationSettingDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      editId: '',
      exportUrl: exportDataUrl,
      availableStatusEnum: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showEditDialog: false,
      showExportDialog: false,
      startPage: 1,
      endPage: 1,
      form: {
        isActive: '',
      },
      listData: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  activated() {},
  created() {
    this.init();
    this.getData();
  },
  methods: {
    init() {
      getInventoryGoodsControlModuleList().then((res) => {
        this.availableStatusEnum = res.availableStatusEnum;
        // 对2个进行排序
        this.availableStatusEnum.forEach((item) => {
          if (item.value === -1) {
            item.value = '';
          }
        });
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign(this.form, {}),
      });
    },
    handleSelectionChange(items, row) {
      this.selectData = [];
      items.forEach((item) => {
        this.selectData.push(item.id);
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 批量操作
     * @param {Integer} status
     * @param {String} str
     */
    batchOperation(status, str) {
      if (this.selectData.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请选择数据后操作',
        });
      }
      let txt = `确定要${str}选中的记录吗？`;
      this.$confirm(txt, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let body = {
          deletedIds: this.selectData,
        };
        deleteData(body).then((res) => {
          this.$message({
            type: 'success',
            message: `${str}成功`,
          });
          this.getData(false);
        });
      });
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(640);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign({}, this.form, this.pageOption);
      listData(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },

    /**
     * 新增
     */
    add() {
      this.editId = '';
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 编辑
     */
    handleEdit(row) {
      this.editId = row.id;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        isActive: '',
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
