<template>
  <el-drawer
    title="车单商品检测"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div class="content">
      <p class="orange" style="margin-bottom: 10px; font-size: 14px">
        当前接车单存在商品报价数量与出库数量不一致情况，点击确定，进行同步本单商品报价数量
      </p>
      <div style="margin-bottom: 16px">
        <el-cascader
          ref="commodity"
          v-model="sortData"
          :options="treeData"
          @change="handleChange"
          :show-all-levels="false"
          :props="{
            value: 'id',
            label: 'name',
            children: 'goodsSecondCategory',
            multiple: true,
          }"
          size="small"
          clearable
          placeholder="选择分类"
        ></el-cascader>
        <i
          class="iconfont grow-icon_warn_32 orange"
          style="margin-top: 10px; margin-left: 10px"
        ></i
        ><span
          style="
            font-size: 14px;
            color: #999999;
            margin-top: 11px;
            margin-left: 5px;
          "
          >按商品分类快速勾选材料单商品</span
        >
      </div>
      <el-table
        ref="goodsListTable"
        :data="list"
        border
        @selection-change="handleSelect"
      >
        <el-table-column
          type="selection"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center">
        </el-table-column>
        <el-table-column prop="name" label="项目名称" min-width="200">
          <template #default="{ row }">
            <span v-if="row.serviceItemName">{{ row.serviceItemName }}</span>
            <select-service-popover
              v-else
              :key="row.goodsId"
              :carId="carId"
              :error="!row.serviceItemName && hasClickSave"
              errorPlaceholder="请设定该商品所属服务项"
              @select="onSelectServiceItem($event, row)"
            ></select-service-popover>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" min-width="120">
          <template #default="{ row }">
            <span>{{ row.goodsName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品编码" width="100">
          <template #default="{ row }">
            <span>{{ row.goodsCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品分类" width="120">
          <template #default="{ row }">
            <span
              >{{ row.firstCategoryName }}/{{ row.secondCategoryName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="原车单报价数量" width="120">
          <template #default="{ row }">
            <span>{{ row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="材料单待领数" width="120">
          <template #default="{ row }">
            <span>{{ row.waitPickedNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="材料单已领数" width="120">
          <template #default="{ row }">
            <span>{{ row.pickedNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="确定后报价数量" width="120">
          <template #default="{ row }">
            <number-input
              v-model="row.newNum"
              @blur="verifyNum($event, row)"
              :min="1"
              :clearable="false"
              :nullable="false"
            ></number-input>
          </template>
        </el-table-column>
      </el-table>

      <div class="footer flex-x-between">
        <div class="flex-y-center">
          <el-checkbox v-model="isSelectAll" @change="handleSelectAll"
            >全选</el-checkbox
          >
          <el-button
            v-if="selectedGoods.length"
            style="margin-left: 30px"
            size="small"
            type="text"
            @click="isBatchSettingOwningService = true"
            >设定所属服务</el-button
          >
          <select-service-popover
            v-if="isBatchSettingOwningService"
            :carId="carId"
            @select="batchSettingOwningService($event)"
          ></select-service-popover>
        </div>
        <div>
          <el-button size="small" type="primary" @click="doSave"
            >保存</el-button
          >
          <el-button size="small" @click="handleClose">取消</el-button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
//接口
import { billGoodsEditCheck } from '@/api/carBill';

import { mapState } from 'vuex';

import selectServicePopover from '@/components/businessComponent/selectServicePopover';

export default {
  name: 'editCarBillGoods',
  components: { selectServicePopover },
  props: {
    visibility: { type: Boolean, default: false },
    carId: {
      type: [Number, String],
    },
    list: {
      type: Array,
      default: () => [],
    },
    treeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 选中商品行
      selectedGoods: [],

      // 全选
      isSelectAll: false,

      // 是否操作过保存
      hasClickSave: false,

      // 是否正在批量设置所属服务
      isBatchSettingOwningService: false,

      sortData: [],
      changeTreeData: [],
      allSelectData: [],
    };
  },
  watch: {
    selectedGoods: {
      handler() {
        this.isBatchSettingOwningService = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.list.forEach((goods) => {
        // 保存原服务id
        if (goods.serviceItemName) {
          goods.originServiceId = goods.serviceItemId;
        } else {
          // 设置默认报价数量 报价数量大于材料单已领数量：报价数不变，仍为报价单商品数量；报价数量小于材料单已领数量：报价数默认为材料单已领数量；
          this.$set(
            goods,
            'newNum',
            goods.num < goods.pickedNum ? goods.pickedNum : goods.num
          );
        }
      });
      //深拷贝传过来的树组，并进行处理
      this.changeTreeData = this.$lodash.cloneDeep(this.treeData);
      var arr = [];
      this.changeTreeData.forEach((item) => {
        if (item.goodsSecondCategory?.length) {
          item.goodsSecondCategory.forEach((el) => {
            arr.push(
              Object.assign(this.$lodash.cloneDeep(el), {
                id: [item.id, el.id],
              })
            );
          });
        } else {
          arr.push(item.id);
        }
      });
      arr.forEach((item) => {
        this.allSelectData.push(
          Object.keys(item.id).map((key) => item.id[key])
        );
      });
    },

    /**
     * 表格勾选
     */
    handleSelect(selection, row) {
      this.selectedGoods = selection;
      // 更新是否全选状态
      this.isSelectAll = this.selectedGoods.length === this.list.length;

      //处理选中表格中的数据id
      var selectIds = [];
      if (selection.length > 0) {
        selection.forEach((item) => {
          selectIds.push([item.firstCategoryId, item.secondCategoryId]);
        });
      }
      //要是取消全选把选中分类也取消
      if (selection.length == 0) {
        this.$refs.commodity.$refs.panel.clearCheckedNodes();
      }
      //判断选中表格中哪一个就选中对应的商品分类中的选项
      if (selection.length == this.list.length) {
        this.sortData = this.allSelectData;
      } else {
        this.sortData = selectIds;
      }
    },

    /**
     * 勾选全部
     */
    handleSelectAll(val) {
      this.$refs?.goodsListTable?.toggleAllSelection(val);
      this.selectedGoods = val ? this.list : [];
      //判断选中全选按钮时在商品分类中也全部选中
      if (val == true) {
        this.sortData = this.allSelectData;
      }
    },

    /**
     * 选择服务
     */
    onSelectServiceItem(service, row) {
      row.serviceItemName = service.name;
      this.$set(row, 'service', service);
      this.$set(row, 'serviceItemId', service.serviceItemId);
    },

    /**
     * 批量设置所属服务
     */
    batchSettingOwningService(service) {
      this.selectedGoods.forEach((goods) => {
        // goods.serviceItemName = service.name;
        this.$set(goods, 'serviceItemName', service.name);
        this.$set(goods, 'serviceItemId', service.serviceItemId);
        this.$set(goods, 'service', service);
      });
    },

    /**
     * 校验数量是否合规
     */
    verifyNum(num, row) {
      // if (num < row.pickedNum) {
      //   // row.newNum = row.pickedNum;
      //   this.$set(row, 'newNum', row.pickedNum);
      //   this.$message.warning(
      //     `当前商品「${row.goodsName}」已领${row.pickedNum}件，报价数量不能低于${row.pickedNum}，如仍需修改，请联系仓库退料后再修改`
      //   );
      // }
    },

    /**
     * 保存
     */
    doSave() {
      this.hasClickSave = true;
      let errorArr = [];
      this.list.forEach((row, index) => {
        // 校验确定后报价数量
        // if (row.newNum < row.pickedNum) {
        //   errorArr.push(
        //     `<p>第 ${index + 1} 行商品「${row.goodsName}」已领${
        //       row.pickedNum
        //     }件，报价数量不能低于${
        //       row.pickedNum
        //     }，如仍需修改，请联系仓库退料后再修改</p>`
        //   );
        //   row.newNum = row.pickedNum;
        // }
        // 校验是否设置所属服务项
        if (!row.serviceItemName) {
          errorArr.push(
            `<p>第 ${index + 1} 行商品「${
              row.goodsName
            }」未设置服务项目，请设置所属服务</p>`
          );
        }
      });
      if (errorArr.length) {
        this.$alert(errorArr.join(''), '提示', {
          dangerouslyUseHTMLString: true,
        });
        return;
      }

      this.$emit('save', this.list);

      this.handleClose();
    },

    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 点击节点
     */
    handleChange(value) {
      var newData = [];
      //选中二级时只取二级中的ID，避免包含一级ID
      value.forEach((item) => {
        if (item.length == 2) {
          newData.push(item.slice(-1));
        } else {
          newData.push(item);
        }
      });
      var serviceId = Array.from(new Set(newData.flat())); //二维数组转一维并去重
      //获取没有选中的数据
      var noSelected = this.list.filter(
        (item) =>
          !serviceId.includes(item.secondCategoryId || item.firstCategoryId)
      );
      noSelected.forEach((row) => {
        this.$nextTick().then(() => {
          this.$refs.goodsListTable.toggleRowSelection(row, false);
        });
      });
      serviceId.forEach((item) => {
        this.list.forEach((i) => {
          if (item == i.firstCategoryId || item == i.secondCategoryId) {
            this.$refs.goodsListTable.toggleRowSelection(i, true);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100% - 52px);
  position: relative;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: auto;
}

.footer {
  width: 60%;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  z-index: 1;
}
</style>
