<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="是否有免赔" prop="isExemptionPay2000">
            <el-radio-group v-model="editForm.isExemptionPay2000">
              <el-radio
                :label="0"
                @click.native.prevent="handleClickChangeIsExemptionPay2000(0)"
                >无</el-radio
              >
              <el-radio
                :label="1"
                @click.native.prevent="handleClickChangeIsExemptionPay2000(1)"
                >有</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="免赔金额" prop="exemptionPayMoney">
            <number-input
              v-model="editForm.exemptionPayMoney"
              :max="999999.99"
              decimalDigits="2"
              placeholder="请输入免赔金额"
              :disabled="!localIsExemptionPay2000"
              clearable
              @blur="doVerify('exemptionPayMoney')"
            >
            </number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="免赔支付方" prop="settlementMethod">
            <el-radio-group
              v-model="editForm.settlementMethod"
              :disabled="!localIsExemptionPay2000"
            >
              <el-radio
                :label="0"
                @click.native.prevent="handleClickChangeSettlementMethod(0)"
                >挂账车队({{ editForm.settlementClientName }})</el-radio
              >
              <el-radio
                :label="1"
                @click.native.prevent="handleClickChangeSettlementMethod(1)"
                >司机现结</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="当次留言" prop="message">
            <el-input
              type="textarea"
              v-model="editForm.message"
              maxlength="300"
              show-word-limit
              placeholder="请输入当次留言，最多300个字符"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { updateExemptionInfo } from '@/api/carBill/carLossDeterminationBill';

export default {
  name: 'EditExemptionDialog',
  components: {},
  props: {
    visibility: Boolean,
    id: Number,
    type: Number,
    title: {
      type: String,
      default: '编辑',
    },
    infoVoData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {
        isExemptionPay2000: 0,
        exemptionPayMoney: 0,
        settlementMethod: 0,
        message: '',
      },
      settlementPayWay: '',
      localIsExemptionPay2000: false,
      localAllowExemptionFill: true,
    };
  },
  computed: {
    // 表单规则
    rules() {
      const part1 = {};
      const part2 = {};
      part2.isExemptionPay2000 = [
        { required: true, message: '请选择是否有免赔' },
      ];
      if (this.editForm.isExemptionPay2000 == 1) {
        part2.exemptionPayMoney = [
          { required: true, message: '请输入免赔金额', trigger: 'blur' },
        ];
        part2.settlementMethod = [
          { required: true, message: '请选择免赔支付方', trigger: 'blur' },
        ];
      }
      return Object.assign({}, part1, part2);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.infoVo = this.$lodash.cloneDeep(this.infoVoData);
      this.editForm.isExemptionPay2000 = this.infoVo.isExemptionPay2000;
      this.editForm.exemptionPayMoney = this.infoVo.exemptionPayMoney;
      this.editForm.settlementMethod = this.infoVo.settlementMethod;
      this.editForm.settlementClientName = this.infoVo.settlementClientName;
      if (this.editForm.settlementMethod == 0) {
        this.settlementPayWay = 0;
      }
      if (
        this.editForm.isExemptionPay2000 == 0 ||
        this.editForm.isExemptionPay2000 == undefined ||
        this.editForm.isExemptionPay2000 == null
      ) {
        this.localIsExemptionPay2000 = false;
      } else {
        this.localIsExemptionPay2000 = true;
      }
    },
    /**
     * 处理变更是否有免赔2000
     * @param {Integer} val 0 无 1 有
     */
    handleClickChangeIsExemptionPay2000(val) {
      if (!this.localAllowExemptionFill) {
        return false;
      }
      this.$set(this.editForm, 'isExemptionPay2000', val);
      if (val === this.localIsExemptionPay2000) {
        return true;
      } else {
        this.localIsExemptionPay2000 = val;
        if (val == 1) {
          // 增加校验规则
          this.rules.exemptionPayMoney = [
            { required: true, message: '请输入免赔金额', trigger: 'blur' },
          ];
          this.rules.settlementMethod = [
            { required: true, message: '请选择免赔支付方', trigger: 'blur' },
          ];
        } else if (val == 0) {
          // 去除校验规则
          this.rules.exemptionPayMoney = [];
          this.rules.settlementMethod = [];
          // 去除默认值，让理赔员填写
        }
      }
      return true;
    },
    /**
     * 处理变更 免赔支付方
     * @param {Integer} val 0 出行公司 1 司机现结
     */
    handleClickChangeSettlementMethod(val) {
      if (!this.localAllowExemptionFill) {
        return false;
      }
      this.$set(this.editForm, 'settlementMethod', val);
      if (val === this.settlementPayWay) {
        return true;
      } else {
        this.settlementPayWay = val;
      }
      return true;
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form.clearValidate();
      this.$refs.form
        .validate()
        .then((result, object) => {
          // 手工处理下 已出定损， 必须填写 是否免赔、如果有，则需填写免赔金额和支付方
          if (this.editForm.isExemptionPay2000 === '1') {
            if (this.editForm.exemptionPayMoney === undefined) {
              this.$message.error('免赔金额必须填写!');
              return;
            }
            if (this.editForm.settlementMethod === undefined) {
              this.$message.error('免赔支付方必须选择!');
              return;
            }
          }
          const body = Object.assign({}, this.editForm, {
            id: this.id,
          });
          updateExemptionInfo(body).then((res) => {
            this.$message.success(res.data || '操作成功!');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },
    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 校验某个字段
     */
    doVerify(prop) {
      this.$refs.form.validateField(prop);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
