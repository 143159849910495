<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="商品标准名">
              <el-input
                v-model="form.standardName"
                placeholder="请输入商品标准名"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="适用车型">
              <el-autocomplete
                v-model="form.matchCarType"
                :fetch-suggestions="searchCarModelList"
                placeholder="请输入车型"
                clearable
              ></el-autocomplete>
              <!-- <el-select
                v-model="form.matchCarType"
                allow-create
                filterable
                remote
                placeholder="搜索车型"
                :remote-method="searchCarModelList"
                :loading="searchCarModelListLoading"
                clearable
              >
                <el-option
                  v-for="(item, index) in carModelData"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="自编码">
              <el-input
                v-model="form.ownCode"
                placeholder="请输入自编码"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <template>
            <el-form-item label="原厂编码">
              <el-input
                v-model="form.goodsCode"
                placeholder="请输入原厂编码"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model="form.name"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="索赔属性">
              <el-select
                v-model="form.claimType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in claimTypeDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="商品分类">
              <select-goods-class-cascader
                ref="goodsClass"
                :value="[form.firstCategoryId, form.secondCategoryId]"
                @select="handleSelectGoodsClass"
                checkStrictly
              ></select-goods-class-cascader>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select v-model="form.isActive" placeholder="请选择启用状态">
                <el-option label="已启用" :value="true"> </el-option>
                <el-option label="未启用" :value="false"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品ID">
              <number-input
                v-model="form.goodsId"
                decimalDigits="0"
                :max="9999999999999999"
                placeholder="请输入完整的商品ID"
                clearable
              >
              </number-input>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button v-auth="19" size="small" class="btn_insert" @click="add"
            >新增</el-button
          >
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="importDialogVisibility = true"
          >
            批量导入<i class="el-icon-arrow-right"></i>
          </el-button>
        </div>

        <el-button
          size="small"
          type="text"
          class="blue"
          @click="downloadAllRecords()"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <span
              v-auth="21"
              class="text_operation blue"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column width="100" label="商品ID" prop="goodsId">
        </el-table-column>
        <el-table-column width="140" label="适用车型" prop="matchCarType">
        </el-table-column>
        <el-table-column width="180" label="商品标准名">
          <template #default="{ row }">
            <span class="blue">{{ row.standardName }}</span>
          </template>
        </el-table-column>
        <el-table-column width="180" label="商品名称" prop="name">
        </el-table-column>
        <el-table-column width="100" label="品质">
          <template #default="{ row }">
            <span>{{ row.manufactoryType | dict('manufactoryType') }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="140"
          label="自编码"
          prop="ownCode"
        ></el-table-column>
        <el-table-column
          width="140"
          label="原厂编码"
          prop="goodsCode"
        ></el-table-column>
        <el-table-column width="100" label="单位" prop="unit"></el-table-column>
        <el-table-column width="160" label="商品分类">
          <template #default="{ row }">
            <span>{{ row.firstCategoryName }}</span>
            <span v-if="row.secondCategoryName"
              >/{{ row.secondCategoryName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="库存预警值"
          prop="alarmNum"
        ></el-table-column>
        <el-table-column
          width="100"
          label="总库存"
          prop="allInventoryNum"
        ></el-table-column>
        <el-table-column
          min-width="160"
          label="备注"
          prop="remark"
        ></el-table-column>
      </el-table>
      <edit-goods-dialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :id="editId"
        @save="getData"
      ></edit-goods-dialog>
      <!-- <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog> -->

      <!-- 导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :visibility.sync="importDialogVisibility"
        title="商品导入"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240418/%E5%95%86%E5%93%81%E7%AE%A1%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20240418.xlsx"
        :uploadFileApi="goodsImportApi"
        :getLastResultApi="goodsImportResultApi"
        :importFailDetail="goodsImportMessageExportUrlApi"
      ></import-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import selectGoodsClassCascader from '@/components/businessComponent/selectGoodsClassCascader.vue';
import editGoodsDialog from './components/editGoodsDialog.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';
// 接口
import { getGoodsList, goodsExportUrl } from '@/api/stock/goodsSetting';
import { getCarModelList } from '@/api/customer/car';

import {
  goodsImport,
  goodsImportResult,
  goodsImportMessageExportUrl,
} from '@/api/system/dataImport';
import { downloadFile } from '@/utils/fileApi';

// 字典数据
import { claimType } from '@/service/dict/dictData';

import { mapState } from 'vuex';

export default {
  name: 'goodsManage',
  components: {
    pagination,
    editGoodsDialog,
    selectGoodsClassCascader,
    importDialog,
    //  ExportDialog
  },
  data() {
    return {
      goodsImportApi: goodsImport,
      goodsImportMessageExportUrlApi: goodsImportMessageExportUrl,
      goodsImportResultApi: goodsImportResult,

      editId: '',

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      showEditDialog: false,
      showExportDialog: false,
      startPage: 1,
      endPage: 1,
      exportUrl: goodsExportUrl,

      form: {
        isActive: true,
      },

      carModelData: [],
      searchCarModelListLoading: false,

      // 展示更多筛选
      showMore: false,

      claimTypeDict: claimType,

      listData: [],

      // 导入弹窗可见性
      importDialogVisibility: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  activated() {},
  created() {
    this.getData();
  },
  methods: {
    /**
     * 选择商品分类
     */
    handleSelectGoodsClass(val) {
      this.form.firstCategoryId = val[0] || '';
      this.form.secondCategoryId = val[1] || '';
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign({}, this.form, this.pageOption);
      getGoodsList(data).then((res) => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign(this.form, {}),
      });
    },
    /**
     * 新增
     */
    add() {
      this.editId = '';
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 编辑
     */
    handleEdit(row) {
      this.editId = row.goodsId;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        isActive: true,
      };
      this.$refs.goodsClass.clear();
    },

    /**
     * 搜索车型列表
     */
    searchCarModelList(keyWord, cb) {
      this.searchCarModelListLoading = true;
      getCarModelList(
        { msg: keyWord || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          var data =
            res.records.map((el) => {
              return {
                value: `${el.logoName}${el.seriesName ? el.seriesName : ''}${
                  el.modelNumberName ? el.modelNumberName : ''
                }${el.modelYearName ? ' ' + el.modelYearName : ''}`,
              };
            }) || [];
          cb(data);
          // this.carModelData =
          //   res.records.map((el) => {
          //     el.id =
          //       '' +
          //       el.logoId +
          //       el.brandId +
          //       el.seriesId +
          //       el.modelNumberId +
          //       el.modelYearId;
          //     el.name = `${el.logoName}${el.seriesName ? el.seriesName : ''}${
          //       el.modelNumberName ? el.modelNumberName : ''
          //     }${el.modelYearName ? ' ' + el.modelYearName : ''}`;
          //     return el;
          //   }) || [];
        })
        .finally(() => {
          this.searchCarModelListLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
