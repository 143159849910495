<template>
  <div>
    <span :class="[pointerShow ? 'pointer blue' : '']" @click="goBillDetail">
      {{ billCode }}
    </span>
    <slot name="append"></slot>
  </div>
</template>
<script>
import { hasAuth } from '@/utils/permissions';
export default {
  name: 'gotoCarDetail',
  props: {
    /**
     * 车单号
     */
    billCode: {
      type: String,
      default: '',
    },
    /**
     * 车单ID
     */
    billId: {
      type: Number,
      default: 0,
    },
    /**
     * 无权限的时候是否静默
     */
    silent: {
      type: Boolean,
      default: true,
    },
  },
  watch: {},
  data() {
    return {
      pointerShow: false,
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.pointerShow = hasAuth(35);
    },
    /**
     * 跳转到车单详情页面
     */
    goBillDetail() {
      if (this.pointerShow === false) {
        if (this.silent === false) {
          this.$message.warning('没有查看接车单详情权限');
        }
        return;
      }
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: this.billId,
          t: new Date().getTime(),
        },
      });
    },
  },
};
</script>
