import store from '@/store';

/**
 * 判断是否具有权限
 */
function hasAuth(el, binding, vnode, oldVnode) {
  let params = binding.value;
  // 权限码
  let code;
  // 处理操作
  let handle;
  // 是否具有权限
  let auth;

  const roles = store?.state?.user?.roles || [];

  // Number 或者 String 类型 认为是对应权限码
  if (['number', 'string'].includes(typeof params)) {
    code = '' + params;
    handle = 'remove';
    auth = roles?.includes(code);
  }
  // Array 类型 认为是一组权限 判断是否有修饰符 .some .every 待开发
  else if (Array.isArray(params)) {
    return;
  }
  // 对象类型
  else if (params instanceof Object) {
    code = params.code;
    handle = params.handle || 'remove';
    auth = roles?.includes('' + code);
    // 附加条件
    let add;
    if (params.additionalConditions !== undefined) {
      add = params.additionalConditions;
      auth = auth && add;
    }
  }

  // 如果没有权限码并且附加条件不===false时中断
  if (!code && params?.additionalConditions !== false) return;

  if (!auth) {
    let comment = document.createComment(' ');
    switch (handle) {
      // 移除元素
      case 'remove':
        // el.style.display = 'none';

        // setTimeout(() => {
        //   el.remove();
        // }, 0);

        // 创建一个注释元素

        // 设置value值
        Object.defineProperty(comment, 'setAttribute', {
          value: () => undefined,
        });
        // 用注释节点替换 当前页面元素
        vnode.elm = comment;
        // 下面作为初始化操作 赋值为空
        vnode.text = ' ';
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;
        vnode.data.directives = undefined;

        // 判断当前元素是否是组件  如果是组件的话也替换成 注释元素
        if (vnode.componentInstance) {
          vnode.componentInstance.$el = comment;
        }

        // 判断当前元素是否是文档节点或者是文档碎片节点
        if (el.parentNode) {
          // 从 DOM 树中删除 node 节点，除非它已经被删除了。
          el.parentNode.replaceChild(comment, el);
        }
        break;

      // 数据脱敏
      case 'dataMarking':
        if (el.innerHTML.trim()) {
          el.innerHTML = params.replaceString ?? '****';
        }
        break;

      // 默认handle为处理函数
      default:
        handle?.({ el, binding, vnode, oldVnode });
    }
  }
}

function update(el, binding, vnode, oldVnode) {
  hasAuth(el, binding, vnode, oldVnode);
}

function bind(el, binding, vnode, oldVnode) {
  hasAuth(el, binding, vnode, oldVnode);
}

const directiveOptions = {
  bind,
  update,
};

export default directiveOptions;
