export default function (options = {}) {
  return Object.assign(
    {
      userInfo: {
        companyId: 0,
      },
      companyId: 0,
      companyName: '',
      // 总店 默认否
      headquarters: false,
      companyList: [],
      // 操作门店树
      companyTree: [],
      // 权限
      roles: [],
      // 角色
      roleName: '',
      // 权限变化次数
      permissionChangeCount: 0,
      // 显示成本价
      showCost: false,
      // 车单开单-添加商品-是否勾选有库存
      inStock: false,
    },
    options
  );
}
