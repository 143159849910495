<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="80%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <p style="margin: 20px 0px; font-size: 20px">认领记录</p>
    <el-table
      class="custom-table"
      :data="claimRecordDetails"
      empty-text="没有认领记录"
      border
    >
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column label="核销操作" width="300">
        <template slot-scope="scope">
          <span
            v-auth="665"
            v-if="scope.row.writeOffStatus === 1"
            class="text_operation blue"
            style="margin-right: 10px"
            @click="handleSingleReversalWriteOffStatus(scope.row)"
            >反核销</span
          >
        </template>
      </el-table-column>
      <el-table-column width="200" label="回款门店" prop="receivingCompanyName">
      </el-table-column>
      <el-table-column
        width="200"
        label="系统流水编号"
        prop="sysStatementSeqCode"
      >
      </el-table-column>
      <el-table-column width="200" label="回款主体" prop="invoicingEntity">
      </el-table-column>
      <el-table-column width="200" label="银行账号" prop="bankAccount">
      </el-table-column>
      <el-table-column width="200" label="开户行" prop="depositBank">
      </el-table-column>
      <el-table-column width="162" label="回款时间" prop="receivingDatetime">
      </el-table-column>
      <el-table-column width="200" label="保险公司" prop="insuranceCompanyName">
      </el-table-column>
      <el-table-column width="200" label="付款账号" prop="paymentAccount">
      </el-table-column>
      <el-table-column min-width="140" label="银行摘要" prop="bankSummary">
        <template #default="{ row }">
          <el-tooltip :content="row.bankSummary">
            <div class="text-ellipsis-2-row">
              {{ row.bankSummary }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column min-width="140" label="资金流水号" prop="transactionSeq">
      </el-table-column>
      <el-table-column width="140" label="已认领金额" prop="claimAmount">
      </el-table-column>
      <el-table-column width="140" label="退款金额" prop="refundAmount">
      </el-table-column>
      <el-table-column width="140" label="业务冲抵费" prop="offsetFee">
      </el-table-column>
    </el-table>
    <p style="margin: 20px 0px; font-size: 20px">备注</p>
    <el-input
      type="textarea"
      v-model="editForm.remark"
      maxlength="200"
      show-word-limit
      disabled
      placeholder="请输入认领资金流水备注"
    >
    </el-input>
    <template slot="footer">
      <span style="margin: 20px 0px; font-size: 15px; margin-right: 100px">
        <span>已认领：{{ claimAmount | toThousands }} </span>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// 组件

// 接口
import {
  transactionClaimDetail,
  transactionClaimHandleSingleReversalWriteOff,
} from '@/api/financial/financialOver';
export default {
  name: 'ClaimBankTransactionDetailDialog',
  components: {},
  props: {
    visibility: Boolean,
    billId: [Number, String],
    id: [Number, String],
    rowEntityData: [Object],
    companyList: [Array],
  },
  data() {
    return {
      // 表单信息
      queryForm: {},
      editForm: {
        remark: '',
      },
      // 表单规则
      rules: {},
      selectedTransationData: [],
      listData: [],
      claimRecordDetails: [],
      totalAmount: 0,
      claimAmount: 0,
      unClaimAmount: 0,
      detailData: {},
    };
  },
  computed: {
    title() {
      return '接车单认领详情';
    },
  },
  created() {
    if (this.billId) {
      this.getData();
    }
  },
  methods: {
    /**
     * 重新计算 已领和认领金额
     */
    calcClaimAmount() {
      let tempAmount = 0;
      for (let index = 0; index < this.claimRecordDetails.length; index++) {
        const element = this.claimRecordDetails[index];
        tempAmount += element.claimAmount;
      }
      this.claimAmount = tempAmount;
    },
    /**
     * 获取数据
     */
    getData() {
      transactionClaimDetail({ id: this.billId }).then((res) => {
        let { carBill, transactionBillList, detail, claimRecordDetails } =
          res || {};
        this.selectedTransationData = transactionBillList;
        this.detailData = detail || {};
        this.editForm.remark = detail?.claimRemark;
        // 已结算的，未结算的
        if (carBill.state === 1) {
          this.totalAmount = carBill.unclearedDebtFixed;
        } else {
          this.totalAmount = carBill.total;
        }
        this.claimRecordDetails = claimRecordDetails;
        this.calcClaimAmount();
      });
    },
    async handleSingleReversalWriteOffStatus(row) {
      const _this = this;
      let res = await this.$confirm('确定要反核销吗？', {
        title: '提示',
        cancelButtonText: '否',
        confirmButtonText: '是',
        distinguishCancelAndClose: true,
      }).catch((err) => {
        if (err === 'cancel') {
          console.log('cancel');
        }
      });
      if (!res) {
        return;
      }
      const data = {
        billId: row.billId,
        id: row.claimId,
        detailId: row.detailId,
        remark: '单独反核销',
      };
      transactionClaimHandleSingleReversalWriteOff(data)
        .then((res) => {
          _this.$message.success('反核销成功');
          _this.getData();
        })
        .catch((err) => {
          console.error(err);
          _this.$message.error('反核销失败：' + err);
        });
    },
    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
