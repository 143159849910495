<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="客户">
                <el-input
                  v-model="form.clientNameOrPhone"
                  placeholder="名称或手机号"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="车牌号码">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入车牌号码"
                  clearable
                  @clear="carNumberInputClear()"
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="车辆识别代码">
                <el-input
                  v-model="form.carVim"
                  placeholder="请输入车辆识别代码"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    v-auth="697"
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp" v-if="showMore">
            <el-form-item label="预约地点">
              <el-select
                v-model="form.bookingPositionId"
                placeholder="搜索预约地点"
                filterable
                clearable
              >
                <el-option
                  v-for="item in bookingPositionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="进度状况">
              <el-select
                v-model="form.state"
                placeholder="请选择进度状况"
                filterable
                clearable
                @change="selStateChangeVal"
              >
                <el-option
                  v-for="item in stateTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="记录创建时间">
              <el-date-picker
                class="w100"
                v-model="form.createdTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="接单时间">
              <el-date-picker
                class="w100"
                v-model="form.acceptTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="预约时间">
              <el-date-picker
                class="w100"
                v-model="form.bookingTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <!--
            <el-form-item label="年审时间">
              <el-date-picker
                class="w100"
                v-model="form.annualReviewTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          -->
            <el-form-item label="回传时间">
              <el-date-picker
                class="w100"
                v-model="form.returnTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div>
          <div class="custom-tabs nopadding">
            <template>
              <div
                v-for="item in annualReviewStates"
                :key="item.state"
                @click="status = item.state"
                :class="{
                  'custom-tab-item': true,
                  active: status == item.state,
                }"
              >
                <p>{{ item.stateDesc }}({{ item.count }})</p>
              </div>
            </template>
          </div>
        </div>
        <div>
          <el-button
            v-auth="698"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <div style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="dataList"
            border
            @row-dblclick="goDetail($event.id)"
            v-fixedScrollBar="$refs.baseContainer"
          >
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column width="130" label="操作">
              <template #default="{ row }">
                <span
                  v-auth="699"
                  class="text_operation blue"
                  style="margin-right: 10px"
                  @click="handleGotoDetail(row)"
                  >详情</span
                >
                <span
                  v-auth="701"
                  class="text_operation blue"
                  style="margin-right: 10px"
                  @click="handleOpenEditRemarkDialog(row)"
                  >编辑备注</span
                >
              </template>
            </el-table-column>
            <el-table-column width="200" label="客户名称" prop="clientName">
            </el-table-column>
            <el-table-column width="160" label="联系电话" prop="bookingPhone">
            </el-table-column>
            <el-table-column width="200" label="车队名称" prop="fleetName">
            </el-table-column>
            <el-table-column
              width="200"
              label="预约地点"
              prop="bookingPosition"
            >
            </el-table-column>
            <el-table-column width="100" label="车牌号码" prop="carNumber">
            </el-table-column>
            <el-table-column width="160" label="车辆识别代码" prop="carVim">
            </el-table-column>
            <el-table-column
              width="100"
              label="注册日期"
              prop="carRegisterDate"
            >
            </el-table-column>
            <el-table-column width="100" label="发证日期" prop="carIssueDate">
            </el-table-column>
            <el-table-column width="160" label="创建时间" prop="dateCreated">
            </el-table-column>
            <el-table-column width="160" label="接单时间" prop="acceptDatetime">
            </el-table-column>
            <el-table-column
              width="160"
              label="预约时间"
              prop="bookingDatetime"
            >
            </el-table-column>
            <!--
            <el-table-column width="160" label="年审时间" prop="reviewDatetime">
            </el-table-column>
            -->
            <el-table-column width="160" label="回传时间" prop="returnDatetime">
            </el-table-column>
            <el-table-column width="100" label="跟进人" prop="updater">
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 导出弹窗 -->
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :maxExportNum="20000"
        :url="exportUrl"
      ></export-dialog>
      <!-- 编辑备注弹窗 -->
      <editAnnualReviewApplyRemarkDialog
        :visibility.sync="editRemarkDialogVisible"
        :id="editId"
        :submitRemark="editRemark"
        @updateData="getList"
      ></editAnnualReviewApplyRemarkDialog>
    </template>
    <!-- 详情抽屉 -->
    <detailAnnualReviewApplyDrawer
      v-if="detailRecordDrawer"
      :visibility.sync="detailRecordDrawer"
      :id="this.detailId"
      @updateData="doUpdateDataAfterRecordUpdate"
    >
    </detailAnnualReviewApplyDrawer>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import detailAnnualReviewApplyDrawer from './components/detailAnnualReviewApplyDrawer.vue';
import editAnnualReviewApplyRemarkDialog from './components/editAnnualReviewApplyRemarkDialog.vue';

//接口
import {
  getCarAnnualReviewPreList,
  getCarAnnualReviewStat,
  getCarAnnualReviewList,
  carAnnualReviewListExportUrl,
} from '@/api/customer/car';

import { hasAuth } from '@/utils/permissions';

export default {
  name: 'annualReview',
  components: {
    pagination,
    ExportDialog,
    detailAnnualReviewApplyDrawer,
    editAnnualReviewApplyRemarkDialog,
  },
  data() {
    return {
      form: {
        clientNameOrPhone: '',
        carNumber: '',
        carVim: '',
      },
      // 展示更多筛选
      showMore: false,
      exportUrl: carAnnualReviewListExportUrl,
      showExportDialog: false,
      //分页组件
      total: 0,
      dataList: [],
      pageOption: {
        size: 10,
        current: 1,
      },
      status: 0,
      // 预约地点列表
      bookingPositionList: [],
      // 进度状况列表
      stateTypeList: [],
      // 进度状态表头
      annualReviewStates: [],
      editId: 0,
      editRemark: '',
      editRemarkDialogVisible: false,
      detailId: 0,
      detailRecordDrawer: false,
    };
  },
  watch: {
    status() {
      this.getList();
      // this.getStatistic();
    },
  },
  computed: {},
  activated() {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      const _this = this;
      // 初始化
      this.annualReviewStates.push({
        state: 0,
        stateDesc: '未接单',
        count: 0,
      });
      this.annualReviewStates.push({
        state: 1,
        stateDesc: '未预约',
        count: 0,
      });
      getCarAnnualReviewPreList().then((res) => {
        const { bookingPositionList, stateTypeList } = res || {};
        _this.bookingPositionList = bookingPositionList;
        _this.stateTypeList = stateTypeList;
      });
      this.getList();
      this.getStatistic();
    },
    setCurrentKey(key) {},
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    carNumberInputClear() {
      this.form.carNumber = '';
      this.$forceUpdate();
    },
    // 置空输入框数据
    empty() {
      this.form = {};
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    /**
     * 点击查询
     */
    doSearch() {
      this.getList();
    },
    doUpdateDataAfterRecordUpdate() {
      this.getStatistic();
      this.getList(false);
    },
    getStatistic() {
      var res = hasAuth(697);
      if (!res) {
        return;
      }
      const _this = this;
      const formData = this.getFormData();
      getCarAnnualReviewStat(formData).then((res) => {
        // 一个列表，要逐个匹配
        _this.annualReviewStates = res;
      });
    },
    getFormData() {
      var times = {
        beginCreatedTime: this.form.createdTimes?.[0]
          ? this.form.createdTimes?.[0] + ' 00:00:00'
          : '',
        endCreatedTime: this.form.createdTimes?.[1]
          ? this.form.createdTimes?.[1] + ' 23:59:59'
          : '',
        beginAcceptTime: this.form.acceptTimes?.[0]
          ? this.form.acceptTimes?.[0] + ' 00:00:00'
          : '',
        endAcceptTime: this.form.acceptTimes?.[1]
          ? this.form.acceptTimes?.[1] + ' 23:59:59'
          : '',
        beginBookingTime: this.form.bookingTimes?.[0]
          ? this.form.bookingTimes?.[0] + ' 00:00:00'
          : '',
        endBookingTime: this.form.bookingTimes?.[1]
          ? this.form.bookingTimes?.[1] + ' 23:59:59'
          : '',
        beginAnnualReviewTime: this.form.annualReviewTimes?.[0]
          ? this.form.annualReviewTimes?.[0] + ' 00:00:00'
          : '',
        endAnnualReviewTime: this.form.annualReviewTimes?.[1]
          ? this.form.annualReviewTimes?.[1] + ' 23:59:59'
          : '',
        beginReturnTime: this.form.returnTimes?.[0]
          ? this.form.returnTimes?.[0] + ' 00:00:00'
          : '',
        endReturnTime: this.form.returnTimes?.[1]
          ? this.form.returnTimes?.[1] + ' 23:59:59'
          : '',
      };
      var data = Object.assign(this.form, this.pageOption, times);
      return data;
    },
    //获取列表数据
    getList(reset = true) {
      var res = hasAuth(697);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      // 状态切换 查询条件和tab要一致
      if (this.form.state !== this.status) {
        this.form.state = '' + this.status;
      }
      const formData = this.getFormData();
      var data = Object.assign({}, this.pageOption, formData, {});
      getCarAnnualReviewList(data).then((res) => {
        this.total = res.total;
        this.dataList = res.records;
      });
    },
    selStateChangeVal(newVal) {
      this.status = Number(newVal);
    },
    /**
     * 详情页抽屉弹出
     */
    handleGotoDetail(row) {
      this.detailId = row.id;
      this.detailRecordDrawer = true;
    },
    /**
     * 双击行事件处理
     * @param id 记录ID
     */
    goDetail(id) {
      this.detailId = id;
      this.detailRecordDrawer = true;
    },
    /**
     * 编辑备注弹出框
     * @param row 当前编辑行
     */
    handleOpenEditRemarkDialog(row) {
      this.editId = row.id;
      this.editRemark = row.remark || '';
      this.editRemarkDialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
//取消内容部分的内边距30px
// ::v-deep.base-container .main {
//   padding: 0px !important;
// }
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}

.choice-sty {
  width: 80px !important;
  height: 31px;
  background: rgba(66, 187, 184, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #42bbb8;
  text-align: center;
}
.goods {
  background: rgba(85, 183, 142, 0.11);
  color: #55b78e;
}
.service {
  background: rgba(255, 146, 0, 0.1);
  color: #ff9200;
}

.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 10px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    text-align: center;
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
    p:nth-child(2) {
      font-size: 14px;
      margin-top: 3px;
    }
  }
  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}
.bill-dialog {
  color: #333;
  font-size: 20px span {
    font-size: 14px;
    color: #999;
  }
}
.billLabel {
  height: 440px;
  overflow-y: scroll;
}
.dialog-footer {
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  height: 109px;
  margin-top: 40px;
  line-height: 109px;
  button {
    height: 30px;
  }
}
.artisan-list {
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  .label {
    margin-top: 10px;
    margin-right: 15px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #eee;
    cursor: pointer;
    &.active {
      background: $bg;
      color: $primary;
    }
  }
}
.icon-sty {
  position: absolute;
  top: -8px;
  right: 0px;
}
//状态与统计的样式
.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .title {
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #fafafa;
    color: #888;
    font-size: 14px;
  }
}
.tree-node {
  color: #888;
  font-size: 14px;
}
.tags {
  .el-tag {
    margin-left: 5px;
    margin-top: 5px;
  }
}
</style>
