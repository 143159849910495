<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex-warp flex-shrink0">
            <el-form-item label="业务来源">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <!-- <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip> -->
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.companyIds"
                :operateCompanyUrl="form.operateCompanyUrl"
                :placeholder="'搜索门店名称'"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select
                v-model="form.isActive"
                clearable
                @keyup.enter.native="getData"
              >
                <el-option
                  v-for="item in enablingStatus"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex">
            <div class="el-form-item--small">
              <div class="flex">
                <el-button
                  class="btn_search"
                  type="primary"
                  size="small"
                  @click="getData"
                  >查询</el-button
                >
                <el-button
                  class="btn_search"
                  size="small"
                  @click="resetFilterForm"
                  >置空</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div class="flex-x-between">
        <div style="margin-bottom: 15px; font-size: 14px">
          <el-button v-auth="344" size="small" class="btn_insert" @click="add"
            >新增</el-button
          >
        </div>
        <!-- 
        <el-button
          v-auth="543"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button> -->
      </div>

      <el-table
        id="customTable"
        class="custom-table summary"
        :data="tableData"
        border
      >
        <el-table-column label="序号" width="60" type="index" prop="sequence">
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <span
              v-auth="345"
              class="text_operation blue"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
            <span
              v-auth="346"
              style="margin-left: 10px"
              class="text_operation blue"
              @click="handleDele(scope.row)"
              >删除</span
            >
          </template>
        </el-table-column>
        <el-table-column
          min-width="140"
          label="业务来源"
          prop="name"
          header-align="left"
        >
        </el-table-column>
        <el-table-column
          min-width="100"
          label="所属客户"
          header-align="left"
          prop="clientName"
        >
          <template slot-scope="scope">
            <span
              class="pointer text_operation blue"
              @click="goClientDetail(scope.row)"
              >{{ scope.row.clientName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="共享状态" header-align="left">
          <template slot-scope="scope">
            {{ scope.row.isShare ? '已共享' : '未共享' }}
          </template>
        </el-table-column>
        <el-table-column label="启用状态" header-align="left">
          <template slot-scope="scope">
            {{ scope.row.isActive ? '启用' : '未启用' }}
          </template>
        </el-table-column>
        <el-table-column label="过滤现结提车" header-align="left" width="110">
          <template slot-scope="scope">
            {{ scope.row.isSelfPayingTakeCarMustPaid ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          label="创建门店"
          header-align="left"
          prop="companyName"
        >
        </el-table-column>
        <el-table-column
          label="创建时间"
          header-align="left"
          prop="dateCreated"
        >
        </el-table-column>
        <el-table-column label="创建人" header-align="left" prop="creatorName">
        </el-table-column>
        <el-table-column
          label="更新时间"
          header-align="left"
          prop="lastUpdated"
        >
        </el-table-column>
        <el-table-column label="更新人" header-align="left" prop="updaterName">
        </el-table-column>
      </el-table>

      <!-- 导出弹窗
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="exportForm"
        :url="exportUrl"
      ></export-dialog> -->
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>

    <business-source-dialog
      v-if="showBusinessSourceDialog"
      :showBusinessSourceDialog.sync="showBusinessSourceDialog"
      :id="editId"
      :detail="editDetail"
      @save="() => getData(false)"
    >
    </business-source-dialog>
    <delect-dialog
      :delectDialogVisible.sync="delectDialogVisible"
      @delect="delectRow"
      @close="closeDialog"
    />
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination';
// import ExportDialog from '@/components/ExportDialog';
import businessSourceDialog from '@/views/systemManagement/businessSource/businessSourceDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
import { getCustomerDetail } from '@/api/customer/customer';
import delectDialog from './delectDialog';
// 接口
import {
  getClientSimpleList,
  delectBillSource,
  getBillSourceTypeList,
} from '@/api/systemManagement/carOrderSettings/businessSource';
// import { getBillSourceTypeList } from '@/api/billSource';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { verifyAuth } from '@/utils/permissions';

export default {
  name: 'businessSource',
  components: {
    pagination,
    // ExportDialog,
    businessSourceDialog,
    operateCompanyCascader,
    delectDialog,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      form: {
        isActive: 1,
      },
      editId: '',
      editDetail: {},
      // 列表数据
      tableData: [],
      billSourceTypeIdRow: '',
      enablingStatus: [
        {
          label: '启用',
          value: 1,
        },
        {
          label: '未启用',
          value: 0,
        },
      ], //启用状态
      showBusinessSourceDialog: false,
      delectDialogVisible: false,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.companyId,
    }),
    ...mapGetters(['isHeadquarters']),
    exportForm() {
      let merge = {
        intoStoreStartTime: this.form?.intoStoreDateRange?.[0] || '',
        intoStoreEndTime: this.form?.intoStoreDateRange?.[1] || '',
      };
      return Object.assign({}, this.form, merge);
    },
  },
  activated() {
    // 从其他页面跳转过来时附带了查询条件
    if (this.$route.params.forms) {
      // this.empty();
      this.form.companyIds = this.$route.params.forms.operateCompanyId;
      this.form.operateCompanyUrl = this.$route.params.forms.operateCompanyUrl;
    }
  },
  created() {
    if (!this.$route.params.forms) {
      this.form.companyIds = [this.companyId];
    }
    this.getData();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 清除筛选表单
     */
    resetFilterForm() {
      this.form = {
        isActive: '',
        companyIds: null,
      };
    },
    /**
     * 查看客户详情
     */
    async goClientDetail(row) {
      await verifyAuth(107);
      this.$router.push({
        name: 'customerDetails',
        query: {
          id: row.clientId,
        },
      });
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.form.companyId = this.companyId;
      console.log(this.form.companyIds, 'this.form.companyIds');
      if (this.form.companyIds) {
        // this.form.companyIds = [
        //   this.form.companyIds[this.form.companyIds.length - 1],
        // ];

        if (this.form.companyIds[0] == null) this.form.companyIds = null;
      } else {
        this.form.companyIds = null;
      }
      if (this.isHeadquarters) {
        this.form.isShare = null;
      }
      let data = Object.assign(this.pageOption, this.form);
      getBillSourceTypeList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.tableData = res.records;
      });
    },
    //编辑
    handleEdit(row) {
      this.editDetail = {
        clientName: row.clientName,
        name: row.name,
        isActive: row.isActive,
        isShare: row.isShare,
        isSelfPayingTakeCarMustPaid: row.isSelfPayingTakeCarMustPaid,
        clientId: row.clientId,
        billSourceTypeId: row.billSourceTypeId,
      };
      this.editId = row.billSourceTypeId;
      this.showBusinessSourceDialog = true;
    },
    closeDialog(v) {
      this.delectDialogVisible = v;
    },
    delectRow(bol) {
      if (bol) {
        let currentCompanyId = this.companyId;
        delectBillSource(this.billSourceTypeIdRow, currentCompanyId).then(
          (res) => {
            this.$message.success('操作成功');
            this.delectDialogVisible = false;
            this.getData();
          }
        );
      }
    },
    //删除
    handleDele(row) {
      this.billSourceTypeIdRow = row.billSourceTypeId;
      this.delectDialogVisible = true;
    },
    //新增
    add() {
      this.editDetail = {};
      this.editId = '';
      this.$nextTick().then(() => {
        this.showBusinessSourceDialog = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 隐藏滚动条
.custom-table ::v-deep .el-table__body-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
  // IE
  -ms-overflow-style: none;
}
</style>
