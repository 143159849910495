<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="故障现象">
                <el-input
                  v-model="form.appearanceName"
                  placeholder="故障现象"
                  @keyup.enter.native="getList"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="接待类型">
                <el-select v-model="form.type" placeholder="请选择" clearable>
                  <el-option
                    v-for="item in receptionType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="getList"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <el-table class="custom-table" :data="faultList" border>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column width="120" label="操作">
            <template #default="{ row }">
              <span
                v-auth="560"
                class="blue pointer"
                style="margin-right: 6px"
                @click="editData(row)"
                >编辑</span
              >
              <span
                v-auth="561"
                class="red pointer"
                style="margin-left: 8px"
                @click="deleteData(row)"
                >删除</span
              >
            </template>
          </el-table-column>
          <el-table-column width="180" label="故障编号" prop="selfNumber">
          </el-table-column>
          <el-table-column label="故障现象" prop="name"> </el-table-column>
          <el-table-column width="100" label="接待类型">
            <template #default="{ row }">
              {{ row.type | dict('receptionType') }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="创建人" prop="creatorName">
          </el-table-column>
          <el-table-column width="160" label="创建时间" prop="dateCreated">
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
    <!-- 删除提示 -->
    <el-dialog
      title="提示"
      :visible.sync="deleteDialog"
      width="25%"
      top="15%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      modal
    >
      <div class="prompt-sty">
        <p>确定删除故障现象“{{ deleteFaultData.name }}”，删除后不可恢复</p>
      </div>
      <template slot="footer">
        <div style="text-align: center">
          <el-button @click="deleteDialog = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small" @click="determine"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 编辑故障现象 -->
    <el-dialog
      title="编辑故障现象"
      :visible.sync="editDialog"
      width="30%"
      top="15%"
      :before-close="handleCloseEdit"
      :close-on-click-modal="false"
      modal
      ><el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="故障现象" prop="appearanceName">
          <el-input
            v-model="ruleForm.appearanceName"
            placeholder="这个是故障现象名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="接待类型">
          <el-radio-group v-model="ruleForm.type">
            <el-radio
              v-for="item in receptionType"
              :key="item.value"
              :label="item.value"
            >
              <span style="margin-left: 5px">{{ item.label }}</span>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <div style="text-align: center">
          <el-button @click="editDialog = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
//字典
import { receptionType } from '@/service/dict/dictData';
//接口
import {
  getAppearanceByPage,
  deleteAppearance,
  editAppearance,
} from '@/api/system/inquiryModel';
export default {
  name: 'faultPhenomenon',
  components: { pagination },
  data() {
    return {
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      form: {},
      receptionType: receptionType,
      faultList: [],
      deleteFaultData: {},
      //删除提示框可见性
      deleteDialog: false,
      //编辑故障现象弹窗可见性
      editDialog: false,
      ruleForm: {
        appearanceName: '',
        type: '',
      },
      rules: {
        appearanceName: [
          { required: true, message: '请输入故障现象名称', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 置空输入框数据
    empty() {
      this.form = {};
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //关闭提示框
    handleClose() {
      this.deleteDialog = false;
    },
    handleCloseEdit() {
      this.editDialog = false;
    },
    //删除弹窗确定按钮
    determine() {
      deleteAppearance(this.deleteFaultData.id).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '操作成功',
            type: 'success',
          });
          this.deleteDialog = false;
          this.getList();
        }
      });
    },
    //获取数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var data = Object.assign(this.form, this.pageOption);
      getAppearanceByPage(data).then((res) => {
        this.faultList = res.records;
        this.total = res.total;
      });
    },
    //删除
    deleteData(row) {
      this.deleteDialog = true;
      this.deleteFaultData = row;
    },
    editData(row) {
      this.editDialog = true;
      this.ruleForm.appearanceName = row.name;
      this.ruleForm.type = row.type;
      this.deleteFaultData = row;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editAppearance({
            ...this.ruleForm,
            id: this.deleteFaultData.id,
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success('操作成功');
              this.editDialog = false;
              this.getList();
            }
          });
        } else {
          this.$message.warning('请填写必填项');
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.prompt-sty {
  text-align: center;
  margin: 24px 0px 60px 0;
}
</style>
