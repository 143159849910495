<template>
  <el-dialog
    :visible="visibility"
    width="45%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <template slot="title">
      <div class="flex-y-center custom-dialog-title">
        <h2>选择业务员</h2>
        <span>（可双击直接单选，或单击批量选择再确定选择）</span>
      </div>
    </template>
    <div class="custom-dialog">
      <slot name="header">
        <p class="color999">项目的业务员只能单选一个员工，可选本店任一员工</p>
      </slot>

      <div class="content-box flex">
        <div class="item-box">
          <div class="item-list">
            <div
              v-for="item in listData"
              :key="item.userId"
              :class="{ item: true, active: item.checked }"
              @click="handleChecked(item)"
              @dblclick="dblclickItem(item)"
            >
              {{ item.realName }}
            </div>
          </div>
        </div>
        <div class="dept-tree">
          <div class="class-box">
            <p class="title">部门</p>
            <el-tree
              class="custom-tree"
              :data="treeData"
              :props="treeProp"
              highlight-current
              node-key="categoryId"
              @node-click="onNodeClick"
            >
            </el-tree>
          </div>
        </div>
      </div>

      <div>
        <span>已选择：</span>
        <span class="primary">{{ selectedItem.realName }}</span>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="confirmSelect"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { getSalesmanList, getSalesmanDepartmentTree } from '@/api/employee';
import { mapState } from 'vuex';
export default {
  name: 'selectSalesmanDialog',
  props: {
    visibility: { type: Boolean, default: false },
  },
  data() {
    return {
      // 列表数据
      listData: [],

      // 选中项目
      selectedItem: {},

      //部门
      treeData: [],
      treeProp: {
        children: 'subDepartments',
        label: 'name',
      },
    };
  },
  created() {
    getSalesmanList(this.companyId).then((res) => {
      console.log(res);
      this.listData = res;
    });

    getSalesmanDepartmentTree().then((res) => {
      // 添加全部选项
      res.unshift({
        categoryId: 0,
        name: '全部',
      });
      this.treeData = res;
    });
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  methods: {
    /**
     * 处理选择操作
     */
    handleChecked(item) {
      var res = !item.checked;
      this.listData.forEach((el) => {
        el.checked = false;
      });
      this.$set(item, 'checked', res);
      if (item.checked) {
        this.selectedItem = item;
      } else {
        this.selectedItem = {};
      }
    },
    /**
     * 确定事件
     */
    dblclickItem(item) {
      this.$set(item, 'checked', true);
      this.selectedItem = item;
      this.confirmSelect();
    },
    /**
     * 向父组件发送事件
     */
    confirmSelect() {
      this.$emit('select', this.selectedItem);
      this.handleClose();
    },
    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },

    /**
     * 点击节点
     */
    onNodeClick(obj, node, origin) {
      if (obj.categoryId) {
        if (obj.parentId) {
          this.firstCategoryId = obj.parentId;
          this.secondCategoryId = obj.categoryId;
        } else {
          this.firstCategoryId = obj.categoryId;
          this.secondCategoryId = 0;
        }
      } else {
        this.firstCategoryId = '';
        this.secondCategoryId = 0;
      }

      getSalesmanList(this.companyId, obj.departmentId).then((res) => {
        this.listData = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog-title {
  h2 {
    line-height: 28px;
    color: #333;
    font-size: 20px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
    color: #999;
  }
}
.custom-dialog {
  padding-top: 10px;
  > p {
    color: #999;
    & + p {
      margin-top: 10px;
    }
  }
  .content-box {
    min-height: 300px;
  }
  .item-box {
    width: 65%;
    margin: 15px 0;
    padding: 30px;
    border: 1px solid #eee;
    border-radius: 4px;
    .title {
      .label {
        color: #333;
        font-size: 16px;
      }
      .value {
        font-size: 14px;
        color: #666;
      }
    }
    .lv {
      margin: 10px 0;
      .lv-title {
        flex-shrink: 0;
        width: 80px;
        text-align: right;
        font-size: 16px;
        color: #333;
      }
    }
    .item-list {
      margin-left: 5px;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-top: 10px;
        margin-right: 15px;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid #eee;
        cursor: pointer;
        user-select: none;
        &.active {
          background: $bg;
          color: $primary;
        }
      }
    }
  }
  .dept-tree {
    padding: 15px;
    width: 30%;
  }
}

//部门tree样式
.class-box {
  width: 100%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}

.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
}
</style>
