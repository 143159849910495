<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="filterForm"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="filterForm.operateCompanyId"
                  :operateCompanyUrl="filterForm.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="车牌号">
                <el-input
                  v-model="filterForm.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="getList"
                ></el-input>
              </el-form-item>
              <el-form-item label="签约客户">
                <el-select
                  size="small"
                  v-model="filterForm.clientId"
                  filterable
                  clearable
                  remote
                  placeholder="请输入客户名称搜索"
                  :remote-method="searchClient"
                  :loading="searchClientLoading"
                >
                  <el-option
                    v-for="item in clientList"
                    :key="item.id"
                    :label="item.clientName"
                    :value="item.id"
                  >
                    <span>{{ item.clientName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="getList"
                    >查询</el-button
                  >
                  <el-button
                    class="btn_search"
                    size="small"
                    @click="resetFilterForm"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="业务类型">
              <el-select
                class="w100"
                v-model="filterForm.billType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in businessType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车间班组">
              <el-input
                v-model="filterForm.dept"
                placeholder=""
                @keyup.enter.native="getList"
              ></el-input>
              <!-- <el-select
                  class="w100"
                  v-model="filterForm.field5"
                  filterable
                  clearable
                  remote
                  placeholder="请选择"
                  :remote-method="searchStore"
                  :loading="searchStoreLoading"
                >
                  <el-option
                    v-for="item in storeList"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item label="车架号">
              <el-input
                v-model="filterForm.uniqueId"
                placeholder="请输入"
                clearable
                @keyup.enter.native="getList"
              ></el-input>
            </el-form-item>
            <!--
            <el-form-item>
              <template slot="label">
                <div>
                  <el-tooltip
                    effect="light"
                    content="根据下方状态各页面筛选本次提交派工时间，最后派工时间、本次提交终检时间，质检交车时间。"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                  操作时间
                </div>
              </template>
              <el-date-picker
                class="w100"
                v-model="filterForm.timeRange"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          --></div>
        </el-form>
        <div class="flex h100">
          <div class="custom-tabs">
            <div
              @click="changeDispatchType(4)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 4,
              }"
            >
              待派工
            </div>
            <div
              @click="changeDispatchType(0)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 0,
              }"
            >
              施工中
            </div>
            <div
              @click="changeDispatchType(5)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 5,
              }"
            >
              质检中
            </div>
            <div
              @click="changeDispatchType(1)"
              :class="{
                'custom-tab-item': true,
                active: dispatchType == 1,
              }"
            >
              车间完工
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="flex h100">
      <el-table
        class="custom-table"
        :data="carBillList"
        border
        @row-dblclick="goCarBillDetail($event.billId)"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="80" :key="1">
        </el-table-column>
        <el-table-column label="操作" width="200" :key="2">
          <template #default="{ row }">
            <div class="flex-x-center">
              <div
                v-if="dispatchType == 4"
                v-auth="95"
                class="detail-btn flex-center"
                @click="dispatching(row.dispatchBillId)"
              >
                派工
              </div>
              <div
                v-if="dispatchType == 4"
                v-auth="452"
                style="margin-left: 5px"
                class="detail-btn flex-center"
                @click="getTask(row.dispatchBillId)"
              >
                领工
              </div>
              <div
                v-if="dispatchType == 0"
                style="margin-left: 5px"
                class="detail-btn flex-center"
                @click="showUpdateFixItemDrawer(row.dispatchBillId)"
              >
                施工项目
              </div>
              <div
                v-if="dispatchType == 5"
                style="margin-left: 5px"
                class="detail-btn flex-center"
                @click="showQualityTestingDialog(row.dispatchBillId)"
              >
                完工质检
              </div>
              <div
                v-if="dispatchType == 1"
                style="margin-left: 5px"
                class="detail-btn flex-center"
                @click="showQualityTestResultDialog(row.dispatchBillId)"
              >
                终检详情
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="操作门店"
          prop="companyName"
          :key="3"
        >
        </el-table-column>
        <el-table-column width="100" label="车牌号" prop="carNumber" :key="4">
        </el-table-column>
        <el-table-column width="200" label="车型/车架号" :key="5">
          <template #default="{ row }">
            <div v-if="row.carType">车型:{{ row.carType }}</div>
            <div v-if="row.uniqueId">VIN:{{ row.uniqueId }}</div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="签约客户" :key="6">
          <template #default="{ row }">
            <div>{{ row.clientName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="本次提交派工时间/提交人"
          v-if="[4].includes(dispatchType)"
          :key="7"
        >
          <template #default="{ row }">
            <div>{{ row.dispatchUpdatedTime }}</div>
            <div>{{ row.submitterName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="本次提交终检时间/提交人"
          v-if="[1, 5].includes(dispatchType)"
          :key="8"
        >
          <template #default="{ row }">
            <div>{{ row.dispatchUpdatedTime }}</div>
            <div>{{ row.submitterName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="最后派工时间/派工人"
          v-if="dispatchType == 0"
          :key="9"
        >
          <template #default="{ row }">
            <div>{{ row.dispatchUpdatedTime }}</div>
            <div>{{ row.submitterName }}</div>
          </template>
        </el-table-column>
        <el-table-column width="190" label="接车单" :key="10">
          <template #default="{ row }">
            <div class="pointer blue" @click="goCarBillDetail(row.billId)">
              {{ row.billCode }}
            </div>
            <div v-if="row.billType !== 0">
              {{ row.billType | dict('carBillType') }}
            </div>
            <div v-else>
              {{ row.isOthersSelfPayState | dict('isOthersSelfPayState') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="车单状态" :key="11">
          <template #default="{ row }">
            <div>
              <p>
                {{ row.billState | dict('carBillState') }}
                <span class="red" v-if="row.billOtherState"
                  >({{ row.billOtherState }})</span
                >
              </p>
            </div>
            <div style="font-size: 12px" class="flex">
              <!-- 检车状态字段 -->
              {{ row.detectionState | dict('detectionState') }}

              <!-- 报价审核状态字段 -->
              <!-- bug764隐藏 -->
              <!-- {{ row.quotationReviewState == 0 ? '/待审核' : '/已审核' }}  注释时间2022年8月1日-->
              <!-- 施工状态字段 -->
              <span v-if="row.detectionState !== undefined"> /</span>
              <div v-if="row.dispatchState !== undefined">
                {{ row.dispatchState | dict('dispatchState') }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="进店时间/接车员" :key="12">
          <template #default="{ row }">
            <div>{{ row.intoStoreTime }}</div>
            <div>{{ row.pickUpUserName }}</div>
          </template>
        </el-table-column>
        <el-table-column width="150" label="进店里程" prop="mileage" :key="13">
        </el-table-column>
        <el-table-column width="180" label="预交车时间" :key="14">
          <template #default="{ row }">
            <!-- <div v-if="row.waitInStore">{{ row.planFinishTime }}</div>
            <div class="color999">
              {{ row.waitInStore ? '在店等' : '待定' }}
            </div> -->

            <div v-if="row.undetermined">待定</div>
            <div v-else>
              <p>{{ row.planFinishTime }}</p>
              <p>{{ row.waitInStore ? '在店等' : '' }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" label="施工类型" :key="15">
          <template #default="{ row }">
            {{ row.difficulty | dict('difficulty') }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="服务类别" :key="16">
          <template #default="{ row }">
            {{ $lodash.get(row, 'categoryName', []).join() }}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          label="施工技师/班组"
          v-if="[0, 1, 5].includes(dispatchType)"
          :key="17"
        >
          <template #default="{ row }">
            {{ row.technicianNames }}
          </template>
        </el-table-column>
        <!-- <el-table-column width="180" label="初诊与备注" prop="field23">
        </el-table-column> -->

        <el-table-column
          label="车辆当前滞留情况"
          header-align="center"
          :key="18"
        >
          <el-table-column
            width="180"
            label="更新日期"
            prop="billUpdatedTime"
          ></el-table-column>
          <el-table-column width="180" label="需要协助">
            <template #default="{ row }">
              <p v-if="row.needHelp !== undefined">
                {{ row.needHelp == 0 ? '否' : '是' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            label="报告人"
            prop="reporterName"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="滞留原因"
            prop="strandedReason"
          ></el-table-column>
        </el-table-column>
      </el-table>
      <div
        style="margin-left: 30px; width: 150px; height: 100%"
        v-if="[0, 5].includes(dispatchType)"
      >
        <el-table
          ref="singleTable"
          height="100%"
          :data="countTableData"
          border
          row-key="departmentId"
          default-expand-all
          :tree-props="{ children: 'subDepartments' }"
          highlight-current-row
          @current-change="handleCurrentChange"
        >
          <el-table-column label="施工班组统计(车单数)">
            <template #default="{ row }">
              <span>{{ row.name }}</span>
              <span>({{ row.statisticsSum }})</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 派工弹窗 -->
    <dispatching-dialog
      v-if="dispatchingDialogVisible"
      :visibility.sync="dispatchingDialogVisible"
      :id="editId"
      :dispatchType="dispatchType"
      @finish="doRefresh"
    ></dispatching-dialog>

    <!-- 施工单领工 -->
    <get-task-dialog
      v-if="getTaskDialogVisible"
      :visibility.sync="getTaskDialogVisible"
      :id="editId"
      :dispatchType="dispatchType"
      @finish="doRefresh"
    ></get-task-dialog>

    <!-- 完工、改派 -->
    <update-fix-item-drawer
      v-if="updateFixItemDrawerVisible"
      :visibility.sync="updateFixItemDrawerVisible"
      :id="editId"
      :dispatchType="dispatchType"
      @finish="doRefresh"
    ></update-fix-item-drawer>

    <!-- 完工质检 -->
    <quality-testing-dialog
      v-if="qualityTestingDialogVisible"
      :visibility.sync="qualityTestingDialogVisible"
      :id="editId"
      :dispatchType="dispatchType"
      @finish="doRefresh"
    ></quality-testing-dialog>

    <!-- 完工质检 -->
    <quality-test-result-dialog
      v-if="qualityTestResultDialogVisible"
      :visibility.sync="qualityTestResultDialogVisible"
      :id="editId"
      :dispatchType="dispatchType"
    ></quality-test-result-dialog>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import dispatchingDialog from '@/components/businessComponent/dispatchingDialog.vue';
import getTaskDialog from '@/components/businessComponent/getTaskDialog.vue';
import UpdateFixItemDrawer from '@/components/businessComponent/updateFixItemDrawer.vue';
import qualityTestingDialog from '@/components/businessComponent/qualityTestingDialog.vue';
import qualityTestResultDialog from '@/components/businessComponent/qualityTestResultDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';
// 接口
import { getDispatchList, dispatchStatistics } from '@/api/carBill';
import { searchClient } from '@/api/customer/customer';

// 字典数据
import { carType, carBillType } from '@/service/dict/dictData';

import { mapState, mapGetters, mapMutations } from 'vuex';

// 工具
import { previewImage as previewImageApi } from '@/utils/fileApi';

export default {
  name: 'constructionBill',
  components: {
    pagination,
    dispatchingDialog,
    getTaskDialog,
    UpdateFixItemDrawer,
    qualityTestingDialog,
    qualityTestResultDialog,
    operateCompanyCascader,
  },
  props: {},
  data() {
    return {
      // 状态 (4,0,5,1 >>> 待派工,施工中,质检中,车间完工)
      dispatchType: 4,

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // 筛选表单
      filterForm: {
        timeRange: [],
      },

      // 搜索用户
      clientList: [],
      searchClientLoading: false,

      carType: carType,
      carModels: [],
      //业务类型
      businessType: carBillType,

      // 车单列表数据
      carBillList: [],
      countTableData: [],

      // 正在操作行的详情数据
      operatingRowDetail: {},

      // 施工领工派工弹窗
      dispatchingDialogVisible: false,

      // 领工弹窗
      getTaskDialogVisible: false,

      // 完工、改派抽屉
      updateFixItemDrawerVisible: false,

      // 完工质检弹窗可见性
      qualityTestingDialogVisible: false,

      // 终检详情弹窗可见性
      qualityTestResultDialogVisible: false,

      // ========================= split line =============================

      // 派检弹窗可见性
      appointDialogVisible: false,

      // 选中技工
      checkedArtisans: [],

      // 检车报告抽屉可见性
      inspectionOfCarReportVisible: false,

      // 提交报价弹窗
      submitPriceDialogVisible: false,
      // 提交报价时间
      submitPriceDateTime: '',
      // 提交报价  时间待定
      indeterminationTimeOfSubmitPrice: false,

      //施工项目弹窗可见性
      constructionProjectVisible: false,

      // 项目完工弹窗可见性
      workFinishedDialogVisible: false,

      // 改派弹窗可见性
      submitChangeDialogVisible: false,

      // 选择技师可见性
      selectAppointDialogVisible: false,
      // 选择技师 是否外包 或 免施工
      epiboly: false,

      // 质检详情弹窗可见性
      qualityTestingResultDialogVisible: false,

      //施工项目表格数据

      departmentId: '', //施工班组统计传的部门id
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {
    // 从其他页面跳转过来时附带的查询条件
    if (this.$route.params.form) {
      this.filterForm = this.$route.params.form || {};
    }
    if (this.$route.params.dispatchType == 1) {
      this.filterForm.timeRange = [
        new Date().Format('yyyy-MM-dd'),
        new Date().Format('yyyy-MM-dd'),
      ];
      this.filterForm.operateCompanyId = [this.companyId];
    } else {
      this.filterForm.timeRange = [
        new Date(new Date().setDate(1)).Format('yyyy-MM-dd'),
        new Date().Format('yyyy-MM-dd'),
      ];
      this.filterForm.operateCompanyId = [this.companyId];
    }

    this.dispatchType = this.$route.params.dispatchType ?? this.dispatchType;

    this.getList();
  },
  created() {
    // this.getList();
  },
  mounted() {},
  methods: {
    doRefresh() {
      this.getList();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 改变状态
     */
    changeDispatchType(val) {
      this.dispatchType = val;
      this.getList();
      if ([0, 5].includes(val)) {
        this.$nextTick(() => {
          this.$refs.singleTable.setCurrentRow(this.countTableData[0]);
        });
      }
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },

    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord) {
      if (keyWord) {
        this.searchClientLoading = true;
        searchClient(keyWord)
          .then((res) => {
            this.clientList = res.records;
          })
          .finally(() => {
            this.searchClientLoading = false;
          });
      }
    },
    /**
     * 重置筛选表单
     */
    resetFilterForm() {
      this.filterForm = {};
    },
    /**
     * 派工
     */
    async dispatching(id) {
      this.editId = id;
      this.dispatchingDialogVisible = true;
    },
    /**
     * 领工
     */
    async getTask(id) {
      this.editId = id;
      this.getTaskDialogVisible = true;
    },
    /**
     * 完工、改派
     */
    showUpdateFixItemDrawer(id) {
      this.editId = id;
      this.updateFixItemDrawerVisible = true;
    },
    /**
     * 完工质检
     */
    showQualityTestingDialog(id) {
      this.editId = id;
      this.qualityTestingDialogVisible = true;
    },
    /**
     * 终检详情
     */
    showQualityTestResultDialog(id) {
      this.editId = id;
      this.qualityTestResultDialogVisible = true;
    },
    /**
     * 派检选择操作
     */
    handleArtisanChecked(artisan) {
      this.$set(artisan, 'checked', !artisan.checked);
      if (artisan.checked) {
        this.checkedArtisans.push(artisan);
      } else {
        const index = this.checkedArtisans.findIndex(
          (item) => item.id == artisan.id
        );
        if (index !== undefined) {
          this.checkedArtisans.splice(index, 1);
        }
      }
    },
    /**
     * 确定派检
     */
    confirmArtisan(artisan) {
      if (artisan) {
        this.$set(artisan, 'checked', true);
        this.checkedArtisans.push(artisan);
      }
    },
    /**
     * 查看检车报告
     */
    openInspectionOfCarReport() {
      this.inspectionOfCarReportVisible = true;
    },
    /**
     * 预览图片
     */
    previewImage(imgs, index) {
      previewImageApi({
        images: imgs,
        options: {
          initialViewIndex: index,
        },
      });
    },
    /**
     * 项目完工弹窗确认事件
     */
    confirmFinished() {
      this.workFinishedDialogVisible = false;
    },
    /**
     * 改派弹窗确认事件
     */
    confirmChange() {
      this.submitChangeDialogVisible = false;
    },
    /**
     * 选择技师弹窗确认事件
     */
    selectArtisan() {
      this.selectAppointDialogVisible = false;
    },

    /**
     * 获取数据列表
     */
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.carBillList = [];
      var times = {
        startTime: this.filterForm.timeRange?.[0] || '',
        endTime: this.filterForm.timeRange?.[1] || '',
        dispatchState: +this.dispatchType,
        companyId: this.companyId,
        departmentId: this.departmentId,
      };
      var data = Object.assign(times, this.filterForm, this.pageOption);
      getDispatchList(data).then((res) => {
        this.total = res.total;
        this.carBillList = res.records;
      });
      //获取施工班组统计
      dispatchStatistics(data).then((res) => {
        this.countTableData = res.department || [];
        this.countTableData.unshift({
          departmentId: -1,
          name: '全部',
          statisticsSum: res.total ?? 0,
        });
      });
    },
    handleCurrentChange(row, column, event) {
      this.departmentId = row.departmentId == -1 ? ' ' : row.departmentId;
      this.getList();
    },
    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
    //施工单-施工中-施工项目-表格选择的行
    handleSelectionChange(val) {},
  },
};
</script>
<style lang="scss" scoped>
::v-deep.base-container .main {
  padding-top: 1px;
}
.page-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  &::v-deep .el-tabs__content {
    flex: 1;
    .el-tab-pane {
      height: 100%;
    }
  }
}
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}

.content-container {
  height: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}

.detail-btn {
  width: 84px;
  height: 28px;
  background: rgba(85, 183, 142, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(85, 183, 142, 0.1);
  cursor: pointer;
  font-size: 14px;
  color: $primary;
  line-height: 20px;
}
.detail-oth-btn {
  width: 120px;
  height: 28px;
  background: rgba(232, 73, 49, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(232, 73, 49, 0.1);
  cursor: pointer;
  font-size: 14px;
  color: #e84931;
  line-height: 20px;
}

.custom-dialog-title {
  h2 {
    line-height: 28px;
    color: #333;
    font-size: 20px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
    color: #999;
  }
}

.appoint-dialog {
  padding-top: 10px;
  > p {
    color: #999;
    & + p {
      margin-top: 10px;
    }
  }
  .artisan-box {
    width: 65%;
    margin: 15px 0;
    padding: 30px;
    border: 1px solid #eee;
    border-radius: 4px;
    .title {
      .label {
        color: #333;
        font-size: 16px;
      }
      .value {
        font-size: 14px;
        color: #666;
      }
    }
    .lv {
      margin: 10px 0;
      .lv-title {
        flex-shrink: 0;
        width: 80px;
        text-align: right;
        font-size: 16px;
        color: #333;
      }
    }
    .artisan-list {
      margin-left: 5px;
      display: flex;
      flex-wrap: wrap;
      .artisan {
        margin-top: 10px;
        margin-right: 15px;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid #eee;
        cursor: pointer;
        &.active {
          background: $bg;
          color: $primary;
        }
      }
    }
  }
  .dept-tree {
    padding: 15px;
    width: 30%;
  }
}

.inspection-of-car-report_drawer {
  padding: 0 30px 30px;
  .el-descriptions ::v-deep .table-label {
    vertical-align: middle;
    color: #666;
    text-align: center;
    background: $descriptions-label-bg;
  }
  .problem-title {
    display: flex;
    margin: 45px 0 15px;
    > h5 {
      color: #333;
      font-size: 16px;
      font-weight: 500;
    }
    > span {
      margin-left: 8px;
    }
  }
  .border-table {
    display: flex;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    & + .border-table {
      margin-top: 30px;
    }
    .left {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > div {
        text-align: center;
        color: #333333;
        > span {
          font-size: 12px;
        }
      }
    }
    .right {
      flex: 1;
      border-left: 1px solid #e6e6e6;
      ::v-deep .el-collapse {
        border: none;
        .el-collapse-item__header {
          // border: none;
          background: $bg;
        }
        .el-collapse-item__wrap {
          border: none;
        }
      }
      .imgs {
        margin-bottom: 20px;
        > img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 4px;
          overflow: hidden;
          cursor: pointer;
          & + img {
            margin-left: 10px;
          }
        }
      }
      .collapse-item_label {
        width: 100%;
        padding: 0 15px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .el-tag {
          margin-left: 5px;

          color: #ff9200;
        }
      }
      .fix-detail {
        padding: 25px;
        > p {
          font-size: 14px;
          color: #666;
          & + p {
            margin-top: 30px;
          }
        }
      }
    }
    .left,
    .right {
      padding: 20px;
    }
  }
}

.submit-price-dialog {
  padding: 0 30px;
  font-size: 14px;
  color: #333;
  .row {
    margin-top: 20px;
  }
}

.work-finished-dialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  > p:first-child {
    margin: 14px 0;
    font-size: 16px;
    color: #333;
  }
  > p:nth-child(2) {
    margin-bottom: 30px;
    line-height: 20px;
    font-size: 14px;
    color: #999;
  }
}

.update-fix-items_box {
  .bill-info {
    margin: 30px 30px 60px;
    .row {
      color: #333;
      .col {
        width: 50%;
        .label {
          flex-shrink: 0;
        }
        // .value {
        // }
      }
    }
  }
}

.quality-testing-result_box {
  height: 65vh;
  overflow: auto;
  padding: 30px 0 60px;
  .bill-info {
    padding-bottom: 60px;
    .row {
      display: flex;
      .col {
        .label {
          margin-right: 10px;
        }
        &:first-child {
          width: 50%;
        }
      }
      & + .row {
        margin-top: 14px;
      }
    }
  }
  .title {
    margin: 14px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  .list {
    .item {
      border-left: 1px solid #eee;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-radius: 4px 4px 0 0;
      .cell {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        .p {
          line-height: 18px;
          // 三行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          word-break: break-all;
          word-wrap: break-word;
          text-overflow: ellipsis;
        }
        & + .cell {
          border-left: 1px solid #eee;
        }
      }
    }
    .split-row {
      height: 30px;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
    }
  }
}

.quality-testing_box {
  height: 60vh;
  overflow: auto;
  padding: 30px 0 60px;
  .bill-info {
    padding-bottom: 60px;
    .row {
      display: flex;
      .col {
        .label {
          margin-right: 10px;
        }
        &:first-child {
          width: 50%;
        }
      }
      & + .row {
        margin-top: 14px;
      }
    }
  }
  .title {
    margin: 14px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  .list {
    .item {
      border-left: 1px solid #eee;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-radius: 4px 4px 0 0;
      .cell {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        .p {
          line-height: 18px;
          // 三行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          word-break: break-all;
          word-wrap: break-word;
          text-overflow: ellipsis;
        }
        & + .cell {
          border-left: 1px solid #eee;
        }
      }
    }
    .split-row {
      height: 30px;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
    }
  }
}
.header-top {
  margin-left: 2.5%;
  font-size: 14px;
  color: #333;
}
.checkOperation {
  margin: 56px 2.5% 14px;
  font-size: 14px;
  color: #333333;
  span {
    margin-right: 9px;
    & + span {
      margin-right: 28px;
    }
  }
}
.table-list {
  margin: 0px 2.5%;
  height: 60vh;
  overflow-y: scroll;
  p {
    font-size: 14px;
    color: #333;
    margin: 30px 0px 14px;
  }
}
</style>
