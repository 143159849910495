<template>
  <base-container
    ref="baseContainer"
    customStyle="background: #f1f7fa;"
    customMainStyle="margin-bottom: 16px;"
    :defaultPadding="false"
  >
    <template>
      <el-form
        style="background: #fff"
        class="filter-form"
        label-width="120px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                filterable
                clearable
                remote
                placeholder="请输入姓名"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  操作门店
                  <el-tooltip
                    effect="light"
                    content="定损单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="接车进店日期">
              <el-date-picker
                class="w100"
                v-model="form.intoStoreTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div class="container">
        <div class="title flex-y-center">
          <div style="background: #33ab78" class="line"></div>
          <div class="text">出定损</div>
        </div>

        <ul class="list first">
          <li class="item">
            <div class="left">
              <span class="item-name"
                >进店理赔（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内理赔开单量（不含补单、已取消）"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{ tableData.claimSettlementNum }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/进店理赔.png"
              alt="进店理赔"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >已提定损（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已提定损的定损单（不含待报损、待调度、待拍照对件、待提定损）"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{
                tableData.submitLossDeterminationNum
              }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/已提定损.png"
              alt="已提定损"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >已出损（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已出损的定损单（含已出定损、待复勘、待收旧、已提资料、已回款）"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{
                tableData.issueLossDeterminationNum
              }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/已出损.png"
              alt="已出损"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >待出定损（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段待出定损状态的定损单"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{ tableData.breakageNum }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/待出定损.png"
              alt="待出定损"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >超期未出损（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="当前出损超期的定损单"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{ tableData.overdueLossNum }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/超期未出损.png"
              alt="超期未出损"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >出损率
                <el-tooltip style="margin-left: 5px" content="=已出损/已提定损">
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num"
                >{{ tableData.issueLossDeterminationRate }} <sub>%</sub></span
              >
            </div>
            <img
              src="@/assets/img/loss_assessment/出损率.png"
              alt="出损率"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >定损超期率
                <el-tooltip
                  style="margin-left: 5px"
                  content="=超时未出损/已提定损"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num"
                >{{ tableData.overdueLossRate }} <sub>%</sub></span
              >
            </div>
            <img
              src="@/assets/img/loss_assessment/定损超期率.png"
              alt="定损超期率"
              class="item-icon"
            />
          </li>
        </ul>

        <div class="title flex-y-center">
          <div style="background: #4973e1" class="line"></div>
          <div class="text">催回款</div>
        </div>

        <ul class="list second">
          <li class="item">
            <div class="left">
              <span class="item-name"
                >已回款（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已回款的定损单"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{ tableData.paymentCollectionNum }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/已回款.png"
              alt="已回款"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >未回款（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已提定损，未回款的定损单"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{
                tableData.noPaymentCollectionNum
              }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/未回款.png"
              alt="未回款"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >超期未回款（台次）
                <el-tooltip
                  style="margin-left: 5px"
                  content="当前提示回款超期的定损单"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{ tableData.overdueCollectionNum }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/超期未回款.png"
              alt="超期未回款"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >回款率
                <el-tooltip style="margin-left: 5px" content="=已回款/已提定损">
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num"
                >{{ tableData.paymentCollectionRate }} <sub>%</sub></span
              >
            </div>
            <img
              src="@/assets/img/loss_assessment/回款率.png"
              alt="回款率"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >回款超期率
                <el-tooltip
                  style="margin-left: 5px"
                  content="=超期未回款/已提定损"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num"
                >{{ tableData.overdueCollectionRate }} <sub>%</sub></span
              >
            </div>
            <img
              src="@/assets/img/loss_assessment/回款超期率.png"
              alt="回款超期率"
              class="item-icon"
            />
          </li>
        </ul>

        <div class="title flex-y-center">
          <div style="background: #f6bf39" class="line"></div>
          <div class="text">提结算</div>
        </div>

        <ul class="list third">
          <li class="item">
            <div class="left">
              <span class="item-name"
                >定损金额
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已出定损台次的定损金额"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{
                tableData.issueLossDeterminationAmount | toThousands
              }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/定损金额.png"
              alt="定损金额"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >已回款金额
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已回款台次的定损金额"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{
                tableData.paymentCollectionAmount | toThousands
              }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/已回款金额.png"
              alt="已回款金额"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >未回款金额
                <el-tooltip
                  style="margin-left: 5px"
                  content="=定损金额-已回款金额"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{
                tableData.noPaymentCollectionAmount | toThousands
              }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/未回款金额.png"
              alt="未回款金额"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >结算金额
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已结账状态的理赔单单据金额（不含补单）"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{
                tableData.claimSettlementFinishedAmount | toThousands
              }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/结算金额.png"
              alt="结算金额"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >毛利率
                <el-tooltip
                  style="margin-left: 5px"
                  content="查询时间段内已结账状态的理赔单毛利率 （1-车单成本/结算金额）*100%"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num"
                >{{ tableData.grossProfitRate }} <sub>%</sub></span
              >
            </div>
            <img
              src="@/assets/img/loss_assessment/毛利率.png"
              alt="毛利率"
              class="item-icon"
            />
          </li>
          <li class="item">
            <div class="left">
              <span class="item-name"
                >单车产值
                <el-tooltip
                  style="margin-left: 5px"
                  content="=结算金额/已结账台次"
                >
                  <i
                    class="iconfont grow-icon_doubt"
                    style="color: #9a9a9a; font-size: 18px"
                  ></i>
                </el-tooltip>
              </span>
              <span class="item-num">{{ tableData.carOutputValue }}</span>
            </div>
            <img
              src="@/assets/img/loss_assessment/单车产值.png"
              alt="单车产值"
              class="item-icon"
            />
          </li>
        </ul>
      </div>
    </template>
  </base-container>
</template>

<script>
// 组件
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import { searchClient as searchClientApi } from '@/api/customer/customer';
import { carLossDeterminationStatistic } from '@/api/carBill/carLossDeterminationBill';

// 字典数据
// import { enableState } from '@/service/dict/dictData';

import { mapState } from 'vuex';

export default {
  name: 'lossAssessmentOverview',
  components: { operateCompanyCascader },
  data() {
    return {
      form: {},
      tableData: {},

      //签约客户
      clientList: [],
      searchClientLoading: false,
    };
  },

  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
    }),
  },
  activated() {},
  created() {
    this.form.operateCompanyId = [this.companyId];
    var thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    this.$set(this.form, 'intoStoreTime', [
      thirtyDaysAgo.Format('yyyy-MM-dd'),
      new Date().Format('yyyy-MM-dd'),
    ]);
    this.getData();
    this.searchClient();
  },
  methods: {
    dateRangeChange(val) {
      this.$set(this.form, 'intoStoreTime', val);
    },
    /**
     * 搜索客户
     */
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClientApi(keyWord)
        .then((res) => {
          this.clientList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 加载数据
     */
    getData() {
      var data = {
        ...this.form,
      };
      if (this.form.intoStoreTime?.length) {
        data.intoStoreStartTime = this.form.intoStoreTime[0];
        data.intoStoreEndTime = this.form.intoStoreTime[1];
      }
      carLossDeterminationStatistic(data).then((res) => {
        this.tableData = res || {};
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}
.container {
  width: 100%;
  height: calc(100% - 89px);
  overflow: auto;
  // display: flex;
  margin-top: 16px;
  background: #fff;
  box-sizing: border-box;
  padding: 10px 12px;
  .title {
    padding: 0 12px;
    height: 76px;
    line-height: 76px;
    .line {
      width: 4px;
      height: 20px;
      border-radius: 2px;
      // background-color: #33ab78;
    }
    .text {
      margin-left: 12px;
      color: #333333;
      font-weight: 500;
      font-size: 20px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;

    &.first .item {
      background: #f5fffb;
    }
    &.second .item {
      background: #f6fbff;
    }
    &.third .item {
      background: #fff8f4;
    }
    .item {
      width: 310px;
      height: 127px;
      display: flex;
      justify-content: space-between;
      margin: 10px;
      align-items: center;
      padding: 28px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #f5fffb;
      .left {
        display: flex;
        flex-direction: column;
        .item-name {
          color: #444;
          font-weight: 400;
          font-size: 18px;
        }
        .item-num {
          margin-top: 16px;
          color: #333;
          font-weight: 600;
          font-size: 32px;
          line-height: 30px;
          sub {
            font-size: 20px;
          }
        }
      }
      .item-icon {
        width: 56px;
        height: 56px;
        // box-shadow: 0px 3px 4px 0px rgba(173, 235, 207, 1);
      }
    }
  }
}
</style>
