<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div class="form-top" style="margin-left: -30px">
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp">
              <el-form-item label="三包厂家">
                <el-select
                  v-model="form.undertakeNameOrNum"
                  @change="selectUndertake"
                  @clear="deleteUndertake"
                  filterable
                  clearable
                  remote
                  placeholder="请输入厂家名称、编码"
                  :remote-method="searchUndertake"
                  :loading="searchUndertakeLoading"
                >
                  <el-option
                    v-for="item in undertakeList"
                    :key="item.id"
                    :label="item.undertakeName"
                    :value="item.undertakeName"
                  >
                    <span
                      style="float: left; color: #8492a6; font-size: 13px"
                      >{{ item.undertakeName }}</span
                    >
                    <span style="float: right">{{ item.undertakeNum }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    适用门店
                    <el-tooltip effect="light" placement="bottom-start">
                      <div
                        slot="content"
                        style="
                          color: #666666;
                          font-size: 13px;
                          line-height: 20px;
                          text-indent: 15px;
                        "
                      >
                        适用门店，可查当前登录门店和当前登录用户
                        <br />所属管辖组内其他门店
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <el-select
                  class="w100"
                  v-model="form.enableStoreNameOrNum"
                  filterable
                  clearable
                  remote
                  placeholder="搜索车店名称、编号"
                  :remote-method="searchStore"
                  :loading="searchStoreLoading"
                >
                  <el-option
                    v-for="item in storeList"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId"
                  >
                    <span
                      style="float: left; color: #8492a6; font-size: 13px"
                      >{{ item.companyName }}</span
                    >
                    <span style="float: right">{{ item.companyId }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    开票主体
                    <el-tooltip effect="light" placement="bottom-start">
                      <div
                        slot="content"
                        style="
                          color: #666666;
                          font-size: 13px;
                          line-height: 20px;
                          text-indent: 15px;
                        "
                      >
                        开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                        的回款管理门店(保险公司的开票主体也是定损主体)。三包厂<br />
                        家管理的开票主体，可查已成为三包厂家开票主体的全部门店
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>

                <el-select
                  v-model="form.invoiceStoreNameOrNum"
                  @change="selectInvoice"
                  @clear="deleteInvoice"
                  filterable
                  clearable
                  remote
                  placeholder="搜索开票主体名称"
                  :remote-method="searchInvoice"
                  :loading="searchInvoiceLoading"
                >
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="height: 100%" class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getDataList"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </template>

    <template>
      <div style="display: flex">
        <el-form
          :model="form"
          label-width="100px"
          size="small"
          style="margin-top: 20px; margin-left: -96px"
        >
          <el-form-item>
            <el-select
              v-model="form.menberType"
              placeholder="批量操作"
              style="width: 120px"
              @change="changeType"
            >
              <el-option v-auth="491" label="启用" :value="0" v-if="nums != 0">
              </el-option>
              <el-option v-auth="491" label="禁用" :value="1" v-if="nums != 1">
              </el-option>
              <el-option v-auth="489" label="删除" :value="2"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-button
          v-auth="487"
          class="btn_add"
          type="primary"
          size="small"
          @click="addManu"
          >新增三包厂家</el-button
        >
      </div>
      <div class="btns flex-x-between">
        <ul class="chioce_type">
          <li
            v-for="(item, index) in chang_list"
            :key="index"
            :class="{ active: nums == index }"
            @click="change_type(index)"
          >
            {{ item.name }}
          </li>
        </ul>
        <el-button
          v-auth="492"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        tooltip-effect="dark"
        class="custom-table"
        @selection-change="handleSelectionChange"
        :span-method="spanMethod"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="selection" width="60"> </el-table-column>
        <el-table-column label="序号" width="60" prop="index">
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="70">
          <template slot-scope="scope">
            <span
              v-auth="488"
              class="text_operation orange"
              @click="getUpdate(scope.$index, scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="undertakeName"
          label="三包厂家名称"
          min-width="120"
        >
        </el-table-column>
        <el-table-column prop="undertakeNum" label="厂家编码" min-width="150">
        </el-table-column>
        <el-table-column
          prop="hasAppliedStore"
          label="适用门店"
          min-width="180"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.hasAppliedStore == false">全部门店</div>
            <div v-else>
              <el-popover
                placement="top"
                trigger="click"
                width="400"
                :ref="'popover-' + scope.row.id"
              >
                <div class="header_title">
                  <span>部分门店({{ scope.row.storeCount }}),</span>
                  <span>{{
                    scope.row.selfContained == 0 ? '不包含本店' : '包含本店'
                  }}</span>
                  <i
                    class="iconfont grow-icon_close_24"
                    style="color: #666666"
                    @click="pClose(scope.row.id)"
                  ></i>
                </div>
                <el-table :data="applyStoresTable" border style="width: 100%">
                  <el-table-column type="index" label="序号" width="60">
                  </el-table-column>
                  <el-table-column
                    prop="companyId"
                    label="车店编号"
                    width="130"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="companyName"
                    label="车店名称"
                    width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="chainCompanyType"
                    label="连锁类型"
                    width="90"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.chainCompanyType | dict('chainCompanyType')
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
                <p
                  :class="scope.row.hasAppliedStore ? 'pointer blue' : ''"
                  slot="reference"
                  @click="goStores(scope.row.id)"
                >
                  {{ '部分门店（' + scope.row.storeCount + '）' }},
                  <span>{{
                    scope.row.selfContained == 0 ? '不包含本店' : '包含本店'
                  }}</span>
                </p>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceCompanyName" min-width="180">
          <template slot="header">
            <div class="flex flex-x-center">
              开票主体
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666666;
                    font-size: 13px;
                    line-height: 20px;
                    text-indent: 15px;
                  "
                >
                  开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                  的回款管理门店(保险公司的开票主体也是定损主体)。
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            {{ row.invoices }}
          </template>
        </el-table-column>
        <el-table-column prop="undertakeContact" label="联系人" min-width="120">
        </el-table-column>
        <el-table-column prop="undertakePhone" label="联系电话" min-width="140">
        </el-table-column>
        <el-table-column
          prop="undertakeAlternatePhone"
          label="备用电话"
          min-width="140"
        >
        </el-table-column>
        <el-table-column prop="undertakeFax" label="传真" min-width="100">
        </el-table-column>
        <el-table-column
          prop="undertakeAddress"
          label="联系地址"
          min-width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="pointer text_operation">{{
              scope.row.undertakeAddress
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="undertakeRemark"
          label="备注"
          min-width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="pointer text_operation">{{
              scope.row.undertakeRemark
            }}</span>
          </template>
        </el-table-column>
      </el-table>

      <add-manufacturer
        v-if="dialogTableVisible"
        :dialogTableVisible.sync="dialogTableVisible"
        :id="editCarId"
        @update="getDataList"
      ></add-manufacturer>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import addManufacturer from './addManufacturer.vue';
import {
  getPakeManuList,
  undertakeCompanyMulti,
  undertakeCompanyEnableStore,
  exportUndertakeCompanyUrl,
  searchClient,
  searchUndertakeCompanyWithInvoice,
} from '@/api/customer/sysmanent';

import { searchStore } from '@/api/customer/store';
import { searchInvoice } from '@/api/storeManagement/index';

import { downloadFile } from '@/utils/fileApi';
import { mapState } from 'vuex';
// 工具
import Big from 'big.js';

export default {
  name: 'pakeManufacturer',
  components: { pagination, addManufacturer, ExportDialog },
  data() {
    return {
      form: {},
      // 搜索门店
      clientId: '',
      clientBriefMessage: [],
      searchInvoiceLoading: false,
      invoiceList: [],

      loading: false,
      activeName: 'first',

      showExportDialog: false,
      exportUrl: exportUndertakeCompanyUrl,

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      editCarId: '',

      chang_list: [
        {
          name: '启用状态',
          value: 1,
        },
        {
          name: '禁用状态',
          value: 0,
        },
      ],
      nums: 0,
      tableData: [],
      applyStoresTable: [
        {
          companyId: '',
          companyName: '',
          isChainCompany: '',
          chainCompanyType: '',
        },
      ],
      dialogTableVisible: false,
      ids: [], //这是表格中选中列的id数组
      undertake: '',
      //三包厂家查询
      undertakeList: [],
      searchUndertakeLoading: false,
      // 搜索适用门店
      storeList: [],
      searchStoreLoading: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.getDataList();
    this.searchInvoice();
    this.searchStore();
    this.searchUndertake();
  },
  methods: {
    /**
     * 批量修改三包厂家
     */
    changeType(e) {
      this.undertake = e;
      var data;
      if (this.ids != 0) {
        if (this.undertake == 0) {
          data = {
            ids: this.ids,
            undertakeEnabled: 1,
          };
          this.$confirm('确认要启用该客户?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.form.menberType = '';
            undertakeCompanyMulti(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '启用成功!',
                });
                this.getDataList();
              }
            });
          });
        } else if (this.undertake == 1) {
          data = {
            ids: this.ids,
            undertakeEnabled: 0,
          };
          this.$confirm('确认要禁用该客户?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.form.menberType = '';
            undertakeCompanyMulti(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '禁用成功!',
                });
                this.getDataList();
              }
            });
          });
        } else {
          data = {
            ids: this.ids,
            deleted: 1,
          };
          this.$confirm('确认要删除该客户?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.form.menberType = '';
            undertakeCompanyMulti(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                });
                this.getDataList();
              }
            });
          });
        }

        // undertakeCompanyMulti(data).then((res) => {
        //   if (res.code == 200) {
        //     this.getDataList();
        //     this.form.menberType = '';
        //   }
        // });
      } else {
        this.$message.warning('请先选中要操作的数据');
        this.form.menberType = '';
      }
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getDataList(false);
    },
    /**
     * 加载数据
     */
    getDataList(reset = true) {
      this.tableData = [];
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var undertakeEnabled = {
        undertakeEnabled: this.nums == 0 ? 1 : 0,
      };
      // this.form.invoiceStoreNameOrNum = this.clientBriefMessage.companyName;
      let data = Object.assign(this.form, this.pageOption, undertakeEnabled);
      getPakeManuList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        //处理数据
        var cloneData = this.$lodash.cloneDeep(res.records);
        let rowIndex = 0;
        cloneData.forEach((item) => {
          if (item.invoices?.length) {
            item.rowspan = item.invoices.length;
            item.invoices.forEach((el, index) => {
              if (index === 0) {
                el.index = ++rowIndex;
                el.isParent = true;
                el.rowspan = item.invoices.length;
              } else {
                el.isChild = true;
              }
              this.tableData.push(
                Object.assign(this.$lodash.cloneDeep(el), {
                  id: item.id,
                  createTime: item.createTime,
                  hasAppliedStore: item.hasAppliedStore,
                  selfContained: item.selfContained,
                  storeCount: item.storeCount,
                  undertakeAddress: item.undertakeAddress,
                  undertakeAlternatePhone: item.undertakeAlternatePhone,
                  undertakeContact: item.undertakeContact,
                  undertakeEnabled: item.undertakeEnabled,
                  undertakeFax: item.undertakeFax,
                  undertakeName: item.undertakeName,
                  undertakeNum: item.undertakeNum,
                  undertakePhone: item.undertakePhone,
                  undertakeRemark: item.undertakeRemark,
                  invoiceCompanyName: item.invoiceCompanyName,
                  invoices: el.invoiceCompanyName,
                })
              );
            });
          } else {
            item.index = ++rowIndex;
            this.tableData.push(item);
          }
        });
      });
    },

    change_type(index) {
      this.nums = index;
      this.pageOption.current = 1;
      if (index == 0) {
        this.form.undertakeEnabled = 1;
      } else {
        this.form.undertakeEnabled = 0;
      }
      this.getDataList();
    },
    /**
     * 表格中的选中列
     */
    handleSelectionChange(val) {
      this.ids = val.map((item) => {
        return item.id;
      });
    },
    goStores(id) {
      this.applyStoresTable = [];
      var data = {
        companyId: this.companyId,
        undertakeId: id,
      };
      undertakeCompanyEnableStore(data).then((res) => {
        console.log('res', res);
        this.applyStoresTable = res.data.records;
      });
    },
    /**
     * 点击图标关闭弹出层的方法
     */
    pClose(id) {
      this.$refs['popover-' + id].doClose();
    },
    /**
     * 新增三包厂家
     */
    addManu() {
      this.editCarId = '';
      this.$nextTick().then(() => {
        this.dialogTableVisible = true;
      });
    },
    /**
     * 跳转编辑三包厂家信息
     */
    getUpdate(index, row) {
      this.editCarId = row.id;
      this.$nextTick().then(() => {
        this.dialogTableVisible = true;
      });
    },
    /**
     * 远程搜索开票主体列表
     */
    invoiceKeyWordChange(keyword) {
      if (keyword) {
        this.searchInvoice(keyword);
      }
    },
    searchInvoice(keyWord = '') {
      this.searchClientLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择开票主体
     */
    selectInvoice(clientId) {
      if (clientId) {
        const client = this.invoiceList.find(
          (item) => item.companyId === clientId
        );
        this.clientBriefMessage = client;
      }
    },
    /**
     * 删除开票主体
     */
    deleteInvoice() {
      if (this.clientId) this.clientId = '';
      this.clientBriefMessage = {};
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
    //三包厂家模糊查询
    searchUndertake(keyWord = '') {
      searchUndertakeCompanyWithInvoice({
        companyId: this.companyId,
        undertakeName: keyWord,
      })
        .then((res) => {
          this.undertakeList = res.records;
        })
        .finally(() => {});
    },
    deleteUndertake() {},
    selectUndertake() {},
    /**
     * 远程搜索适用门店列表
     */
    storeKeyWorkChange(keyWord) {
      if (keyWord) {
        this.searchStore(keyWord);
      }
    },
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStore(keyWord, this.companyId)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    // 选择操作门店
    selectStore(storeId) {
      console.log('选中的门店', storeId);
    },
    //删除操作门店
    deleteStore() {},

    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (![6].includes(columnIndex)) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-top {
}
.form-item__customlabel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > i:last-child {
    margin-left: 5px;
  }
}
.primary {
  color: $primary;
}
.btn_search {
  width: 76px;
}
.btn_add {
  width: 120px;
  height: 30px;
  margin-top: 21px;
  margin-left: 10px;
  background: $orange;
  border: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.chioce_type {
  display: flex;
}
.chioce_type li {
  width: 96px;
  height: 39px;
  border: 1px solid #e1e1e1;
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  line-height: 39px;
  color: #999999;
  font-size: 16px;
  margin-right: 16px;
}
.active {
  background: #eef7f3;
  color: $primary !important;
}

// .content-container {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   box-sizing: border-box;
//   overflow: auto;
// }
::v-deep.el-table thead {
  color: #000000 !important;
}
.header_title {
  display: flex;
  height: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 21px;
}
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
</style>
