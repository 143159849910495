<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <div style="margin-bottom: 30px" class="flex">
        <i class="el-icon-warning orange el-icon--left"></i>
        <p style="font-size: 14px">点击确定将定损单退回上一个状态</p>
      </div>
      <el-form-item label="跟进人" prop="followUpPeople">
        <el-select
          v-model="editForm.followUpPeople"
          filterable
          clearable
          remote
          placeholder="搜索姓名、手机号"
          :remote-method="searchAllUser"
          :loading="searchAllUserLoading"
        >
          <el-option
            v-for="item in allUserList"
            :key="item.userId"
            :label="item.realName"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跟进进展" prop="followUpState">
        <el-radio-group
          style="line-height: 1.4rem"
          v-model="editForm.followUpState"
        >
          <el-radio
            v-for="option in followUpStateList"
            :key="option.value"
            :label="option.value"
            @click.native.prevent="handleClickFollowUpState(option.value)"
          >
            <div :style="option.iconStyle">{{ option.iconText }}</div>
            <span style="margin-left: 5px">{{ option.label }}</span>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="当次留言" prop="message">
        <el-input
          type="textarea"
          size="small"
          v-model="editForm.message"
          :rows="2"
          maxlength="300"
          show-word-limit
          placeholder="请输入当次留言，最多300个字符"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 字典数据
import { followUpStateList } from '@/service/dict/dictData';

import { getAllUser } from '@/api/employee';
import { reversalOperateForCarLossDeterminationBill } from '@/api/carBill/carLossDeterminationBill';

export default {
  name: 'reversalDialog',
  props: {
    visibility: Boolean,
    id: Number,
    type: Number,
    title: {
      type: String,
      default: '撤销',
    },
    lastName: {
      type: String,
      default: '',
    },
    lastId: Number,
  },
  data() {
    return {
      followUpStateList,
      // 表单信息
      editForm: {},
      // 表单规则
      rules: {
        followUpPeople: [
          { required: true, message: '请选择跟进人', trigger: 'blur' },
        ],
        // followUpState: [
        //   {required: true, message:'请选择跟进状态', trigger: 'blur'}
        // ],
      },
      searchAllUserLoading: false,
      allUserList: [],
    };
  },
  created() {
    if (this.lastId) {
      // this.editForm.followUpPeople = this.lastId;
      this.$set(this.editForm, 'followUpPeople', this.lastId);
    }
    // this.searchAllUser({
    //   msg: this.lastName,
    //   userId: this.lastId,
    // });
    if (this.editForm.followUpPeople) {
      this.allUserList = [
        {
          userId: this.lastId,
          realName: this.lastName,
        },
      ];
    }
  },
  methods: {
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      this.searchAllUserLoading = true;
      let params = {};
      if (typeof msg === 'object') {
        params = msg;
      } else {
        params = { msg };
      }
      getAllUser(params)
        .then((res) => {
          this.allUserList = res.records || [];
        })
        .finally(() => {
          this.searchAllUserLoading = false;
        });
    },
    /**
     * 跟进进展单选框选中时，再次点击取消选中
     */
    handleClickFollowUpState(value) {
      if (this.editForm.followUpState === value) {
        this.editForm.followUpState = '';
      } else {
        this.$set(this.editForm, 'followUpState', value);
      }
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          const body = Object.assign(this.editForm, {
            id: this.id,
            type: this.type,
          });
          reversalOperateForCarLossDeterminationBill(body).then((res) => {
            this.$message.success('操作成功!');

            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
