export default [
  {
    code: '178',
    name: '车单记录',
    children: [
      {
        code: '35',
        name: '接车单',
        children: [
          {
            code: '179',
            name: '接车',
          },
          {
            code: '424',
            name: '洗车开单',
          },
          {
            code: '425',
            name: '洗车结账',
          },
          {
            code: '230',
            name: '保养方案',
          },
          {
            code: '224',
            name: '报价单',
            children: [
              {
                code: '579',
                name: '客户协议',
              },
              {
                code: '507',
                name: '审核报价',
              },
              {
                code: '508',
                name: '环检',
              },
              {
                code: '4',
                name: '编辑车单',
                children: [
                  {
                    code: '575',
                    name: '待结账编辑',
                  },
                ],
              },
              {
                code: '349',
                name: '查看现货',
              },
              {
                code: '227',
                name: '重新服务',
              },
              {
                code: '5',
                name: '结账',
              },
              {
                code: '120',
                name: '反结算',
              },
              {
                code: '285',
                name: '修改账单时间',
              },
              {
                code: '414',
                name: '推送报价单',
              },
              {
                code: '443',
                name: '本次借卡',
              },
            ],
          },
          {
            code: '225',
            name: '检车',
            children: [
              {
                code: '226',
                name: '进行检车',
              },
              {
                code: '119',
                name: '发送检车报告',
              },
            ],
          },
          {
            code: '125',
            name: '编辑用料',
          },
          {
            code: '494',
            name: '接车单导出',
          },
          {
            code: '215',
            name: '取消接待',
          },
          {
            code: '466',
            name: '自费开单',
          },
          {
            code: '467',
            name: '包干开单',
          },
          {
            code: '468',
            name: '理赔开单',
          },
          {
            code: '469',
            name: '索赔开单',
          },
          {
            code: '470',
            name: '提交施工',
          },
          {
            code: '471',
            name: '挂单',
          },
          //          {
          //            "code": "472",
          //            "name": "提前提车"
          //          },
          {
            code: '473',
            name: '查看包干金额',
          },
        ],
      },
      {
        code: '439',
        name: '车单反向记录',
        children: [
          {
            code: '440',
            name: '导出',
          },
        ],
      },
      {
        code: '600',
        name: '车单评价记录',
        children: [
          {
            code: '601',
            name: '车单评价记录查询',
          },
          {
            code: '602',
            name: '车单评价记录导出',
          },
        ],
      },
      {
        code: '172',
        name: '预约单',
        children: [
          {
            code: '173',
            name: '新建预约',
          },
          {
            code: '174',
            name: '预约设置',
          },
          {
            code: '176',
            name: '编辑预约单',
          },
          {
            code: '177',
            name: '取消预约单',
          },
        ],
      },
      {
        code: '457',
        name: '定损单',
        children: [
          {
            code: '458',
            name: '编辑',
          },
          {
            code: '459',
            name: '提交定损',
          },
          {
            code: '517',
            name: '完成对件',
          },
          {
            code: '518',
            name: '已提资料',
          },
          {
            code: '519',
            name: '撤销提交',
          },
          {
            code: '520',
            name: '撤销对件',
          },
          {
            code: '460',
            name: '已出定损',
          },
          {
            code: '461',
            name: '撤销报备',
          },
          {
            code: '462',
            name: '回款认领',
          },
          {
            code: '463',
            name: '撤销出损',
          },
          {
            code: '464',
            name: '撤销认领',
          },
          {
            code: '465',
            name: '导出',
          },
          {
            code: '592',
            name: '完成报损',
          },
          {
            code: '593',
            name: '撤销报损',
          },
          {
            code: '594',
            name: '完成调度',
          },
          {
            code: '595',
            name: '撤销调度',
          },
          //          {
          //            "code": "596",
          //            "name": "提交定损"
          //          },
          {
            code: '597',
            name: '撤销提损',
          },
          {
            code: '598',
            name: '完成复勘',
          },
          {
            code: '599',
            name: '完成收旧',
          },
        ],
      },
      {
        code: '600',
        name: '车单评价记录',
        children: [
          {
            code: '601',
            name: '导出',
          },
        ],
      },
    ],
  },
  {
    code: '493',
    name: '车间工单',
    children: [
      {
        code: '94',
        name: '施工单',
        children: [
          {
            code: '95',
            name: '派工',
          },
          {
            code: '96',
            name: '改派',
          },
          {
            code: '452',
            name: '领工',
          },
          {
            code: '453',
            name: '提交改派',
          },
          {
            code: '454',
            name: '项目完工',
          },
          {
            code: '455',
            name: '提交返工',
          },
          {
            code: '456',
            name: '车间完工',
          },
        ],
      },
    ],
  },
  {
    code: '446',
    name: '在场车辆',
    children: [
      {
        code: '447',
        name: '今日在场车辆',
        children: [
          {
            code: '449',
            name: '导出',
          },
          {
            code: '451',
            name: '滞留说明',
            children: [
              {
                code: '524',
                name: '更新预交车时间',
              },
              {
                code: '523',
                name: '导出',
              },
            ],
          },
        ],
      },
      {
        code: '448',
        name: '历史滞留车辆',
        children: [
          {
            code: '450',
            name: '导出',
          },
        ],
      },
      {
        code: '521',
        name: '今日在场统计',
        children: [
          {
            code: '522',
            name: '导出',
          },
        ],
      },
    ],
  },
  {
    code: '55',
    name: '财务管理',
    children: [
      {
        code: '163',
        name: '应付款',
        children: [
          {
            code: '164',
            name: '付款',
          },
          {
            code: '165',
            name: '作废',
          },
        ],
      },
      {
        code: '353',
        name: '调拨应收应付',
        children: [
          {
            code: '354',
            name: '付款',
          },
          {
            code: '355',
            name: '确认收款',
          },
          {
            code: '356',
            name: '导出',
          },
        ],
      },
      {
        code: '421',
        name: '销账记录',
        children: [
          {
            code: '422',
            name: '作废',
          },
          {
            code: '423',
            name: '导出',
          },
        ],
      },
      {
        code: '499',
        name: '应收账款',
        children: [
          {
            code: '216',
            name: '销账收款记录',
            children: [
              {
                code: '217',
                name: '作废',
              },
              {
                code: '503',
                name: '导出',
              },
            ],
          },
          {
            code: '161',
            name: '应收账款明细',
            children: [
              {
                code: '162',
                name: '收款',
              },
              {
                code: '332',
                name: '导出',
              },
            ],
          },
          {
            code: '495',
            name: '应收账款汇总',
            children: [
              {
                code: '496',
                name: '导出',
              },
            ],
          },
          {
            code: '444',
            name: '包干分摊批次',
            children: [
              {
                code: '445',
                name: '重跑',
              },
              {
                code: '544',
                name: '导出',
              },
            ],
          },
        ],
      },
      {
        code: '150',
        name: '现金流表',
      },
      {
        code: '149',
        name: '营业总表',
        children: [
          {
            code: '274',
            name: '营业总表导出',
          },
        ],
      },
      {
        code: '302',
        name: '定金',
        children: [
          {
            code: '303',
            name: '定金明细',
            children: [
              {
                code: '304',
                name: '收定金',
              },
              {
                code: '305',
                name: '结转',
              },
              {
                code: '306',
                name: '作废',
              },
              {
                code: '307',
                name: '退款',
              },
              {
                code: '308',
                name: '导出',
              },
            ],
          },
          {
            code: '309',
            name: '退款明细',
            children: [
              {
                code: '310',
                name: '作废',
              },
            ],
          },
          {
            code: '311',
            name: '结转明细',
            children: [
              {
                code: '312',
                name: '作废',
              },
            ],
          },
        ],
      },
      {
        code: '56',
        name: '按日期查询营业统计',
      },
      {
        code: '147',
        name: '销售目标',
      },
      {
        code: '154',
        name: '其他收支',
        children: [
          {
            code: '155',
            name: '记一笔',
          },
          {
            code: '156',
            name: '编辑收支',
          },
          {
            code: '157',
            name: '删除收支',
          },
          {
            code: '158',
            name: '编辑收支类别',
          },
          {
            code: '168',
            name: '删除收支类别',
          },
        ],
      },
      {
        code: '497',
        name: '营业对账',
        children: [
          {
            code: '527',
            name: '客户对账',
            children: [
              {
                code: '528',
                name: '导出',
              },
            ],
          },
          {
            code: '529',
            name: '对账批次记录',
            children: [
              {
                code: '530',
                name: '重跑',
              },
              {
                code: '531',
                name: '下载',
              },
            ],
          },
          {
            code: '159',
            name: '车单结账明细',
            children: [
              {
                code: '160',
                name: '导出权限',
              },
            ],
          },
          {
            code: '213',
            name: '车单项目明细',
            children: [
              {
                code: '214',
                name: '导出权限',
              },
            ],
          },
          {
            code: '411',
            name: '车单实收明细',
            children: [
              {
                code: '412',
                name: '导出权限',
              },
            ],
          },
          {
            code: '509',
            name: '项目毛利明细',
            children: [
              {
                code: '510',
                name: '导出权限',
              },
            ],
          },
        ],
      },
      //      {
      //        "code": "213",
      //        "name": "接车单项目明细",
      //        "children": [
      //          {
      //            "code": "214",
      //            "name": "导出权限"
      //          }
      //        ]
      //      },
      //      {
      //        "code": "411",
      //        "name": "工单实收明细",
      //        "children": [
      //          {
      //            "code": "412",
      //            "name": "导出权限"
      //          }
      //        ]
      //      },
      {
        code: '194',
        name: '报销单',
        children: [
          {
            code: '195',
            name: '报销单查询',
          },
          {
            code: '196',
            name: '编辑报销单',
          },
          {
            code: '197',
            name: '报销客户管理',
          },
        ],
      },
      {
        code: '97',
        name: '绩效提成',
        children: [
          {
            code: '99',
            name: '绩效报表',
          },
          {
            code: '167',
            name: '审核绩效',
          },
          {
            code: '98',
            name: '查看所有员工绩效',
          },
          {
            code: '100',
            name: '提成设置',
          },
          {
            code: '101',
            name: '查看提成规则',
          },
        ],
      },
      {
        code: '223',
        name: '拉卡拉',
      },
      {
        code: '442',
        name: '扫码收款（收钱吧）',
      },
    ],
  },
  {
    code: '69',
    name: '库存管理',
    children: [
      {
        code: '130',
        name: '库存概况',
      },
      {
        code: '169',
        name: '查看库存成本价',
      },
      {
        code: '357',
        name: '库存查询',
        children: [
          {
            code: '131',
            name: '库存总览',
            children: [
              {
                code: '146',
                name: '导出',
              },
            ],
          },
          {
            code: '358',
            name: '批次余量',
            children: [
              {
                code: '359',
                name: '导出',
              },
            ],
          },
          {
            code: '70',
            name: '出入库记录',
            children: [
              {
                code: '360',
                name: '导出',
              },
              {
                code: '170',
                name: '作废',
              },
            ],
          },
          {
            code: '361',
            name: '收发流水',
            children: [
              {
                code: '362',
                name: '导出',
              },
            ],
          },
        ],
      },
      {
        code: '204',
        name: '采购入库',
        children: [
          {
            code: '129',
            name: '新增编辑采购单',
          },
          {
            code: '73',
            name: '入库',
          },
          {
            code: '364',
            name: '撤销入库',
          },
          {
            code: '365',
            name: '采购付款',
          },
          {
            code: '366',
            name: '撤销付款',
          },
          {
            code: '128',
            name: '作废单据',
          },
          {
            code: '133',
            name: '待采购清单查询',
          },
        ],
      },
      {
        code: '367',
        name: '领料出库',
        children: [
          {
            code: '139',
            name: '待领/已领清单查询',
            children: [
              {
                code: '410',
                name: '即采即用',
              },
              {
                code: '71',
                name: '维修领料',
              },
              {
                code: '140',
                name: '退料',
              },
            ],
          },
          {
            code: '72',
            name: '其他出库',
            children: [
              {
                code: '368',
                name: '新增编辑其他出库单',
              },
              {
                code: '369',
                name: '领料出库',
              },
              {
                code: '370',
                name: '撤销出库',
              },
              {
                code: '371',
                name: '作废单据',
              },
            ],
          },
        ],
      },
      {
        code: '75',
        name: '采购退货',
        children: [
          {
            code: '372',
            name: '新增编辑采购退货单',
          },
          {
            code: '373',
            name: '退货出库',
          },
          {
            code: '374',
            name: '撤销退货',
          },
          {
            code: '375',
            name: '退货收款',
          },
          {
            code: '376',
            name: '撤销收款',
          },
          {
            code: '377',
            name: '作废单据',
          },
        ],
      },
      {
        code: '74',
        name: '顾客退货',
        children: [
          {
            code: '378',
            name: '新增编辑顾客退货单',
          },
          {
            code: '379',
            name: '退货入库',
          },
          {
            code: '380',
            name: '撤销退货',
          },
          {
            code: '381',
            name: '退货付款',
          },
          {
            code: '382',
            name: '撤销付款',
          },
          {
            code: '383',
            name: '作废单据',
          },
        ],
      },
      {
        code: '203',
        name: '移库',
        children: [
          {
            code: '384',
            name: '新增编辑移库单',
          },
          {
            code: '385',
            name: '移库完成',
          },
          {
            code: '386',
            name: '撤销移库',
          },
          {
            code: '387',
            name: '作废单据',
          },
        ],
      },
      {
        code: '77',
        name: '库存盘点',
        children: [
          {
            code: '76',
            name: '新增编辑盘点单',
          },
          {
            code: '388',
            name: '盘点完成',
          },
          {
            code: '389',
            name: '撤销盘点',
          },
          {
            code: '390',
            name: '作废单据',
          },
        ],
      },
      {
        code: '78',
        name: '库存调拨',
        children: [
          {
            code: '391',
            name: '新增编辑调拨单',
          },
          {
            code: '392',
            name: '发货',
          },
          {
            code: '393',
            name: '撤销发货',
          },
          {
            code: '427',
            name: '编辑待收货调拨单（编辑入库库位）',
          },
          {
            code: '394',
            name: '收货',
          },
          {
            code: '395',
            name: '撤销收货',
          },
          {
            code: '396',
            name: '退回',
          },
          {
            code: '397',
            name: '撤销退回',
          },
          {
            code: '398',
            name: '退回收货',
          },
          {
            code: '399',
            name: '新增编辑申请调拨单',
          },
          {
            code: '400',
            name: '提交申请',
          },
          {
            code: '401',
            name: '撤销申请',
          },
          {
            code: '402',
            name: '驳回申请',
          },
          {
            code: '403',
            name: '撤销驳回',
          },
          {
            code: '404',
            name: '确认调拨',
          },
          {
            code: '405',
            name: '取消调拨',
          },
          {
            code: '406',
            name: '作废单据',
          },
        ],
      },
      {
        code: '407',
        name: '库存报表',
        children: [
          {
            code: '218',
            name: '采购报表',
            children: [
              {
                code: '219',
                name: '导出',
              },
            ],
          },
          {
            code: '408',
            name: '调拨报表',
            children: [
              {
                code: '409',
                name: '导出',
              },
            ],
          },
          {
            code: '228',
            name: '进销存报表',
            children: [
              {
                code: '229',
                name: '导出',
              },
            ],
          },
        ],
      },
      {
        code: '180',
        name: '仓库设置',
        children: [
          {
            code: '18',
            name: '商品管理',
            children: [
              {
                code: '21',
                name: '商品编辑',
              },
              {
                code: '19',
                name: '新增商品',
              },
              //              {
              //                "code": "20",
              //                "name": "商品分类设置"
              //              },
              {
                code: '22',
                name: '商品删除',
              },
            ],
          },
          {
            code: '67',
            name: '基础设置',
          },
          {
            code: '86',
            name: '库房设置',
            children: [
              {
                code: '87',
                name: '新增库房、新增库位',
              },
              {
                code: '88',
                name: '库房|库位编辑',
              },
              {
                code: '89',
                name: '库房|库位删除',
              },
            ],
          },
          {
            code: '82',
            name: '供应商管理',
            children: [
              {
                code: '83',
                name: '新增供应商',
              },
              {
                code: '84',
                name: '编辑供应商',
              },
              {
                code: '85',
                name: '删除供应商',
              },
            ],
          },
          {
            code: '582',
            name: '品名管理',
            children: [
              {
                code: '583',
                name: '新增品名',
              },
              {
                code: '584',
                name: '编辑品名',
              },
              {
                code: '585',
                name: '删除品名',
              },
              //            ,
              //              {
              //                "code": "20",
              //                "name": "修改分类"
              //              }
            ],
          },
          {
            code: '20',
            name: '商品分类设置',
          },
        ],
      },
    ],
  },
  {
    code: '79',
    name: '智能提醒',
    children: [
      {
        code: '68',
        name: '提醒设置',
      },
      {
        code: '80',
        name: '编辑提醒',
      },
    ],
  },
  {
    code: '199',
    name: '统计分析',
    children: [
      {
        code: '148',
        name: '配件销售毛利报表',
      },
      {
        code: '253',
        name: '会员卡业务统计',
        children: [
          {
            code: '255',
            name: '会员开卡充值明细',
            children: [
              {
                code: '256',
                name: '导出',
              },
            ],
          },
          {
            code: '257',
            name: '会员卡消费明细',
            children: [
              {
                code: '258',
                name: '储值金额消费明细',
                children: [
                  {
                    code: '259',
                    name: '导出',
                  },
                ],
              },
              {
                code: '260',
                name: '套餐项目消费明细',
                children: [
                  {
                    code: '261',
                    name: '导出',
                  },
                ],
              },
            ],
          },
          {
            code: '270',
            name: '会员卡作废明细',
            children: [
              {
                code: '271',
                name: '导出',
              },
            ],
          },
          {
            code: '272',
            name: '会员卡变更明细',
            children: [
              {
                code: '273',
                name: '导出',
              },
            ],
          },
        ],
      },
      {
        code: '350',
        name: '业务来源统计',
        children: [
          {
            code: '351',
            name: '导出',
          },
        ],
      },
    ],
  },
  {
    code: '207',
    name: '运营指标',
    children: [
      {
        code: '208',
        name: '今日看板',
      },
      {
        code: '209',
        name: '客流量',
      },
      {
        code: '210',
        name: '会员业务',
      },
      {
        code: '211',
        name: '客户量',
      },
      {
        code: '212',
        name: '微信营销',
      },
      {
        code: '333',
        name: '定金余额',
      },
    ],
  },
  {
    code: '102',
    name: '营销',
    children: [
      {
        code: '187',
        name: '分享活动',
      },
      {
        code: '188',
        name: '关注奖励',
      },
      {
        code: '231',
        name: '渠道分红',
        children: [
          {
            code: '232',
            name: '渠道分红汇总',
            children: [
              {
                code: '233',
                name: '分红变更记录',
              },
              {
                code: '234',
                name: '编辑人脉',
              },
              {
                code: '235',
                name: '编辑分红',
              },
              {
                code: '251',
                name: '分红提现',
              },
            ],
          },
          {
            code: '236',
            name: '渠道客户管理',
            children: [
              {
                code: '237',
                name: '新增',
              },
              {
                code: '238',
                name: '编辑',
              },
              {
                code: '266',
                name: '分红设置',
              },
            ],
          },
          {
            code: '239',
            name: '渠道管理规则',
          },
        ],
      },
      {
        code: '240',
        name: '股东分红',
        children: [
          {
            code: '241',
            name: '股东分红汇总',
            children: [
              {
                code: '242',
                name: '分红变更记录',
              },
              {
                code: '243',
                name: '编辑分红',
              },
              {
                code: '252',
                name: '分红提现',
              },
              {
                code: '244',
                name: '编辑人脉',
              },
            ],
          },
          {
            code: '245',
            name: '分红股东管理',
            children: [
              {
                code: '246',
                name: '股本变更记录',
              },
              {
                code: '247',
                name: '新增',
              },
              {
                code: '248',
                name: '编辑',
              },
              {
                code: '249',
                name: '股本提现',
              },
              {
                code: '267',
                name: '股本充值',
              },
              {
                code: '268',
                name: '分红设置',
              },
            ],
          },
          {
            code: '250',
            name: '股东规则管理',
          },
        ],
      },
    ],
  },
  {
    code: '189',
    name: '微信',
    children: [
      {
        code: '275',
        name: '商城',
        children: [
          {
            code: '276',
            name: '线上商品管理',
          },
          {
            code: '277',
            name: '分组管理',
          },
          {
            code: '278',
            name: '商城设置',
          },
        ],
      },
      {
        code: '103',
        name: '微信活动',
      },
      {
        code: '201',
        name: '微信总览',
      },
      {
        code: '113',
        name: '微信卡券',
        children: [
          {
            code: '118',
            name: '卡券分析',
          },
          {
            code: '114',
            name: '卡券编辑',
          },
          {
            code: '115',
            name: '券派发',
          },
          {
            code: '116',
            name: '卡券数据',
            children: [
              {
                code: '334',
                name: '作废卡券',
              },
            ],
          },
          {
            code: '117',
            name: '使用记录',
          },
        ],
      },
      {
        code: '122',
        name: '微信设置',
        children: [
          {
            code: '279',
            name: '基础设置',
            children: [
              {
                code: '280',
                name: '首页设置',
              },
              {
                code: '281',
                name: '车店设置',
              },
            ],
          },
          {
            code: '123',
            name: '微信设置',
          },
          {
            code: '205',
            name: '关注回复',
          },
          {
            code: '206',
            name: '微信素材',
          },
          {
            code: '262',
            name: '推广设置',
            children: [
              {
                code: '263',
                name: '默认推广设置',
              },
              {
                code: '264',
                name: '股东推广设置',
              },
              {
                code: '265',
                name: '渠道推广设置',
              },
            ],
          },
        ],
      },
      {
        code: '348',
        name: '公众号助手',
      },
    ],
  },
  {
    code: '186',
    name: '客户管理',
    children: [
      {
        code: '107',
        name: '客户档案',
        children: [
          {
            code: '576',
            name: '基本信息',
          },
          {
            code: '577',
            name: '车辆情况',
          },
          {
            code: '578',
            name: '业务信息',
          },
          {
            code: '352',
            name: '查看手机号',
          },
          {
            code: '61',
            name: '新增客户',
          },
          {
            code: '62',
            name: '编辑客户',
            children: [
              {
                code: '335',
                name: '删除客户',
              },
            ],
          },
          {
            code: '282',
            name: '导出',
          },
        ],
      },
      {
        code: '546',
        name: '客户指令',
        children: [
          {
            code: '545',
            name: '编辑客户指令',
          },
        ],
      },
      {
        code: '52',
        name: '车辆档案',
        children: [
          {
            code: '54',
            name: '新增车辆',
          },
          {
            code: '63',
            name: '绑定车辆',
          },
          {
            code: '64',
            name: '解除绑定',
          },
          {
            code: '53',
            name: '编辑车辆',
            children: [
              {
                code: '336',
                name: '删除车辆',
              },
            ],
          },
          {
            code: '337',
            name: '车辆消费记录',
          },
          {
            code: '283',
            name: '导出',
          },
        ],
      },
      {
        code: '41',
        name: '会员管理',
        children: [
          {
            code: '284',
            name: '导出',
          },
          {
            code: '42',
            name: '新增卡种',
          },
          {
            code: '106',
            name: '查看总储值金额',
          },
          {
            code: '44',
            name: '编辑卡种',
          },
          {
            code: '45',
            name: '删除卡种',
          },
          {
            code: '43',
            name: '开卡',
            children: [
              {
                code: '323',
                name: '赠送金额',
              },
              {
                code: '324',
                name: '赠送项目',
              },
              {
                code: '325',
                name: '打折',
              },
            ],
          },
          {
            code: '108',
            name: '充值',
            children: [
              {
                code: '326',
                name: '赠送金额',
              },
              {
                code: '327',
                name: '赠送项目',
              },
              {
                code: '328',
                name: '打折',
              },
            ],
          },
          {
            code: '109',
            name: '记录明细',
          },
          {
            code: '112',
            name: '项目延期',
          },
          {
            code: '51',
            name: '卡作废',
          },
          {
            code: '104',
            name: '变更项目',
          },
          {
            code: '105',
            name: '变更金额',
          },
          {
            code: '111',
            name: '变更卡种',
          },
          {
            code: '269',
            name: '变更客户',
          },
          {
            code: '110',
            name: '编辑卡',
          },
          {
            code: '190',
            name: '套餐管理',
            children: [
              {
                code: '191',
                name: '新增套餐',
              },
              {
                code: '192',
                name: '编辑套餐',
              },
              {
                code: '193',
                name: '删除套餐',
              },
            ],
          },
        ],
      },
      {
        code: '474',
        name: '自费（包干）协议',
        children: [
          {
            code: '475',
            name: '新增协议',
          },
          {
            code: '476',
            name: '编辑协议',
          },
          {
            code: '477',
            name: '作废协议',
          },
          {
            code: '478',
            name: '导出',
          },
        ],
      },
      {
        code: '525',
        name: '车辆盘点表',
        children: [
          {
            code: '526',
            name: '导出',
          },
        ],
      },
      {
        code: '580',
        name: '常用报价模板',
        children: [
          {
            code: '581',
            name: '导入报价模板',
          },
        ],
      },
      {
        code: '586',
        name: '车型管理',
        children: [
          {
            code: '587',
            name: '新增车型',
          },
          {
            code: '588',
            name: '编辑',
          },
          {
            code: '589',
            name: '删除',
          },
        ],
      },
      {
        code: '607',
        name: '车管反馈',
        children: [
          {
            code: '608',
            name: '导出',
          },
        ],
      },
    ],
  },
  {
    code: '40',
    name: '接车单打印设置',
  },
  {
    code: '38',
    name: '系统管理',
    children: [
      {
        code: '293',
        name: '门店管理',
        children: [
          {
            code: '66',
            name: '编辑门店信息',
          },
        ],
      },
      {
        code: '479',
        name: '保险公司管理',
        children: [
          {
            code: '480',
            name: '新增保险公司',
          },
          {
            code: '481',
            name: '编辑保险公司',
          },
          {
            code: '482',
            name: '删除保险公司',
          },
          {
            code: '484',
            name: '启用/禁用',
          },
          {
            code: '485',
            name: '导出',
          },
        ],
      },
      {
        code: '516',
        name: '定损提醒设置',
      },
      {
        code: '486',
        name: '三包厂家管理',
        children: [
          {
            code: '487',
            name: '新增三包厂家',
          },
          {
            code: '488',
            name: '编辑三包厂家',
          },
          {
            code: '489',
            name: '删除三包厂家',
          },
          {
            code: '491',
            name: '启用/禁用',
          },
          {
            code: '492',
            name: '导出',
          },
        ],
      },
      {
        code: '294',
        name: '管辖门店组',
        children: [
          {
            code: '295',
            name: '新增',
          },
          {
            code: '296',
            name: '编辑',
          },
          {
            code: '297',
            name: '删除',
          },
        ],
      },
      {
        code: '12',
        name: '角色权限管理',
        children: [
          {
            code: '15',
            name: '新增角色',
          },
          {
            code: '16',
            name: '编辑角色',
          },
          {
            code: '198',
            name: '删除角色',
          },
        ],
      },
      {
        code: '289',
        name: '部门管理',
        children: [
          {
            code: '290',
            name: '新增部门',
          },
          {
            code: '291',
            name: '编辑部门',
          },
          {
            code: '292',
            name: '删除部门',
          },
        ],
      },
      {
        code: '298',
        name: '岗位等级管理',
        children: [
          {
            code: '299',
            name: '新增职位',
          },
          {
            code: '300',
            name: '编辑职位',
          },
          {
            code: '301',
            name: '删除职位',
          },
        ],
      },
      {
        code: '10',
        name: '员工管理',
        children: [
          {
            code: '13',
            name: '编辑员工',
          },
          {
            code: '288',
            name: '新增员工',
          },
          {
            code: '329',
            name: '禁用',
          },
          {
            code: '330',
            name: '解除禁用',
          },
          /*,{
                      "code": "331",
                      "name": "导出"
                    }*/
        ],
      },
      {
        code: '343',
        name: '业务来源管理',
        children: [
          {
            code: '344',
            name: '新增',
          },
          {
            code: '345',
            name: '编辑',
          },
          {
            code: '346',
            name: '删除',
          },
        ],
      },
      {
        code: '347',
        name: '工单设置',
      },
      {
        code: '24',
        name: '服务管理',
        children: [
          {
            code: '25',
            name: '新增服务',
          },
          {
            code: '26',
            name: '服务分类设置',
          },
          {
            code: '27',
            name: '编辑服务',
          },
          {
            code: '28',
            name: '删除服务',
          },
          {
            code: '426',
            name: '本地服务导出',
          },
        ],
      },
      {
        code: '39',
        name: '结账方式管理',
        children: [
          {
            code: '415',
            name: '新增结账方式',
          },
          {
            code: '416',
            name: '编辑结账方式',
          },
          {
            code: '417',
            name: '删除结账方式',
          },
        ],
      },
      {
        code: '184',
        name: '检车项目设置',
      },
      {
        code: '413',
        name: '品牌设置',
      },
      {
        code: '500',
        name: '打印',
        children: [
          {
            code: '501',
            name: '批量打印',
            children: [
              {
                code: '502',
                name: '结算单',
                children: [
                  {
                    code: '506',
                    name: '盖章',
                  },
                ],
              },
              {
                code: '505',
                name: '派工单',
              },
              {
                code: '504',
                name: '材料单',
              },
            ],
          },
        ],
      },
      {
        code: '419',
        name: '数据导入',
        children: [
          {
            code: '420',
            name: '车辆信息导入',
          },
          {
            code: '542',
            name: '质保规则导入',
          },
          {
            code: '547',
            name: '客户指令导入',
          },
          {
            code: '565',
            name: '问诊模型导入',
          },
        ],
      },
      {
        code: '428',
        name: '工位设置',
        children: [
          {
            code: '429',
            name: '新增编辑',
          },
        ],
      },
      {
        code: '441',
        name: '保养项目常用品牌设置',
      },
      {
        code: '532',
        name: '质保规则管理',
        children: [
          {
            code: '533',
            name: '新增',
          },
          {
            code: '534',
            name: '编辑',
          },

          {
            code: '543',
            name: '导出',
          },
        ],
      },
      {
        code: '548',
        name: '技术中心',
        children: [
          {
            code: '549',
            name: '素材管理',
            children: [
              {
                code: '550',
                name: '上架',
              },
              {
                code: '551',
                name: '下架',
              },
              {
                code: '590',
                name: '导出',
              },
              {
                code: '591',
                name: '下载案例',
              },
            ],
          },
          {
            code: '552',
            name: '问诊模型',
            children: [
              {
                code: '553',
                name: '问诊模型库',
                children: [
                  {
                    code: '557',
                    name: '新增模型',
                  },
                  {
                    code: '558',
                    name: '编辑模型',
                  },
                  {
                    code: '559',
                    name: '删除模型',
                  },
                ],
              },
              {
                code: '554',
                name: '故障现象库',
                children: [
                  {
                    code: '560',
                    name: '编辑',
                  },
                  {
                    code: '561',
                    name: '删除',
                  },
                ],
              },
              {
                code: '555',
                name: '故障原因库',
                children: [
                  {
                    code: '562',
                    name: '编辑',
                  },
                  {
                    code: '563',
                    name: '删除',
                  },
                ],
              },
              {
                code: '556',
                name: '疑难杂症库',
                children: [
                  {
                    code: '564',
                    name: '处理',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    code: '432',
    name: '摄像识别',
    children: [
      {
        code: '437',
        name: '摄像识别记录',
        children: [
          {
            code: '438',
            name: '导出',
          },
        ],
      },
      {
        code: '430',
        name: '摄像头管理',
        children: [
          {
            code: '431',
            name: '编辑',
          },
        ],
      },
      {
        code: '511',
        name: '摄像识别统计',
        children: [
          {
            code: '512',
            name: '摄像预警设置',
          },
        ],
      },
    ],
  },
  {
    code: '185',
    name: '救援管理',
  },
  {
    code: '121',
    name: '车险业务',
  },
  {
    code: '418',
    name: '超级车险',
  },
  {
    code: '222',
    name: '连锁管理',
    children: [
      {
        code: '220',
        name: '连锁管控',
        children: [
          {
            code: '221',
            name: '设置目标',
          },
        ],
      },
    ],
  },
  {
    code: '286',
    name: '数据看板',
    children: [
      {
        code: '287',
        name: '设置健康指标',
      },
      {
        code: '535',
        name: '指挥中心',
        children: [
          {
            code: '536',
            name: '查看指标',
          },
          {
            code: '537',
            name: '查看目标',
          },
          {
            code: '538',
            name: '设置目标',
          },
          {
            code: '539',
            name: '修改当月目标',
          },
          {
            code: '540',
            name: '设置总负责人',
          },
          {
            code: '541',
            name: '门店范围管理',
          },
        ],
      },
      {
        code: '567',
        name: '毛利总榜',
        children: [
          {
            code: '569',
            name: '综合毛利',
          },
          {
            code: '570',
            name: '自费毛利',
          },
          {
            code: '571',
            name: '包干毛利',
          },
          {
            code: '572',
            name: '理赔毛利',
          },
          {
            code: '573',
            name: '索赔毛利',
          },
        ],
      },
      {
        code: '568',
        name: '毛利明细',
        children: [
          {
            code: '574',
            name: '设置成本',
          },
        ],
      },
      {
        code: '602',
        name: '定损业绩看板',
      },
      {
        code: '603',
        name: '定损执行报表',
        children: [
          {
            code: '604',
            name: '导出',
          },
        ],
      },
      {
        code: '605',
        name: '定损明细',
        children: [
          {
            code: '606',
            name: '导出',
          },
        ],
      },
      {
        code: '609',
        name: '故障分诊明细',
        children: [
          {
            code: '610',
            name: '导出',
          },
        ],
      },
    ],
  },
  {
    code: '313',
    name: '消息推送',
    children: [
      {
        code: '314',
        name: '接收服务完成通知',
      },
      {
        code: '315',
        name: '接收新预约通知',
      },
      {
        code: '316',
        name: '接收拉卡拉到账',
      },
      {
        code: '317',
        name: '接收接车完成通知',
      },
      {
        code: '318',
        name: '接收重新服务通知',
      },
      {
        code: '320',
        name: '接收结账完成通知',
      },
      {
        code: '322',
        name: '道路救援通知',
      },
      {
        code: '433',
        name: '车辆进车店通知',
      },
      {
        code: '434',
        name: '车辆出车店通知',
      },
      {
        code: '435',
        name: '车辆进工位通知',
      },
      {
        code: '436',
        name: '车辆出工位通知',
      },
      {
        code: '513',
        name: '车辆进车间通知',
      },
      {
        code: '514',
        name: '车辆出车间通知',
      },
      {
        code: '515',
        name: '车辆未开单通知',
      },
    ],
  },
];
//610
