<template>
  <el-drawer
    title="行驶证年审业务详情"
    :visible="visibility"
    size="62%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    :wrapperClosable="true"
  >
    <div style="margin: 0 8px 40px 24px; overflow: scroll">
      <el-descriptions
        border
        :column="2"
        labelClassName="table-label"
        :contentStyle="{ verticalAlign: 'middle' }"
      >
        <el-descriptions-item label="客户名称" :span="1">
          {{ infoVo.clientName }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话" :span="1">
          {{ infoVo.bookingPhone }}
        </el-descriptions-item>
        <el-descriptions-item label="预约地点" :span="2">
          {{ infoVo.bookingPosition }}
        </el-descriptions-item>
        <el-descriptions-item label="预约时间" :span="1">
          {{ infoVo.bookingDate }}
        </el-descriptions-item>
        <el-descriptions-item label="车队名称" :span="1">
          {{ infoVo.fleetName }}
        </el-descriptions-item>
        <el-descriptions-item label="上传照片" :span="1">
          <img
            v-if="
              infoVo.vehicleLicenseImageUrl &&
              infoVo.vehicleLicenseImageUrl !== ''
            "
            class="thumbnail-img"
            @click="previewImgs(infoVo.vehicleLicenseImageUrl)"
            :src="infoVo.vehicleLicenseImageUrl"
          />
          <span v-else>没有行驶证图片</span>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        border
        :column="2"
        labelClassName="table-label"
        :contentStyle="{ verticalAlign: 'middle' }"
        style="margin-top: 32px"
      >
        <el-descriptions-item label="车牌号码" :span="1">
          {{ infoVo.carNumber }}
        </el-descriptions-item>
        <el-descriptions-item label="车辆类型" :span="1" style="width: 450px">
          {{ infoVo.carType }}
        </el-descriptions-item>
        <el-descriptions-item label="所有人" :span="2">
          {{ infoVo.ownerName }}
        </el-descriptions-item>
        <el-descriptions-item label="住址" :span="2">
          {{ infoVo.address }}
        </el-descriptions-item>
        <el-descriptions-item label="使用性质" :span="1">
          {{ infoVo.useCase }}
        </el-descriptions-item>
        <el-descriptions-item label="品牌型号" :span="1">
          {{ infoVo.model }}
        </el-descriptions-item>
        <el-descriptions-item label="车辆识别代码" :span="1">
          {{ infoVo.carVim }}
        </el-descriptions-item>
        <el-descriptions-item label="发动机号码" :span="1">
          {{ infoVo.engineNumber }}
        </el-descriptions-item>
        <el-descriptions-item label="注册日期" :span="1">
          {{ infoVo.carRegisterDate }}
        </el-descriptions-item>
        <el-descriptions-item label="发证日期" :span="1">
          {{ infoVo.carIssueDate }}
        </el-descriptions-item>
        <el-descriptions-item label="备注" :span="1">
          {{ infoVo.remark }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 预约完成，显示上传回传图片 -->
      <div class="car-imgs" v-if="infoVo.state === 2">
        <p><span style="color: red">*</span>上传回传图片（最多两张）</p>
      </div>
      <div
        class="car-imgs"
        style="padding-bottom: 30px"
        v-if="
          infoVo.state === 2 ||
          infoVo.state === 4 ||
          infoVo.state === 5 ||
          infoVo.state === 6
        "
      >
        <p>回传图片</p>
        <div class="flex">
          <upload-file-list
            v-model="infoVo.returnImageUrls"
            :max="2"
            :disabled="infoVo.state !== 2"
            title="回传图片"
          ></upload-file-list>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="footer">
      <el-button
        v-auth="702"
        v-if="infoVo.state == 0"
        size="small"
        type="primary"
        @click="nextStep(1)"
        >接单</el-button
      >
      <el-button
        v-auth="703"
        v-if="infoVo.state == 1"
        size="small"
        type="primary"
        @click="nextStep(2)"
        >已预约</el-button
      >
      <el-button
        v-auth="704"
        v-if="infoVo.state == 2 || infoVo.state == 3"
        size="small"
        type="primary"
        @click="nextStep(4)"
        >存在违法</el-button
      >
      <el-button
        v-auth="705"
        v-if="infoVo.state == 2 || infoVo.state == 3"
        size="small"
        type="primary"
        @click="nextStep(5)"
        >未到期</el-button
      >
      <el-button
        v-auth="706"
        v-if="infoVo.state == 2 || infoVo.state == 3"
        size="small"
        type="primary"
        @click="nextStep(6)"
        >完成</el-button
      >
    </div>
  </el-drawer>
</template>
<script>
//列表接口
import {
  getCarAnnualReviewInfo,
  acceptCarAnnualReview,
  bookedCarAnnualReview,
  illegalCarAnnualReview,
  unExpiredCarAnnualReview,
  doneCarAnnualReview,
} from '@/api/customer/car';

import { previewImage, downloadFile } from '@/utils/fileApi';
import uploadFileList from '@/components/uploadFileList';

export default {
  name: 'carLossDeterminationBillDetailDrawer',
  props: {
    // 弹窗可见性
    visibility: { type: Boolean, default: false },
    // 车辆定损单ID
    id: {
      type: [Number, String],
      default: '',
    },
  },
  components: {
    uploadFileList,
  },
  data() {
    return {
      // 详细信息
      infoVo: {},
    };
  },
  computed: {
    // 回传图片
    returnImages() {
      let result = [];
      const returnImagesUrl = this.infoVo.returnImagesUrl || '';
      if (returnImagesUrl !== '') {
        returnImagesUrl.split(',').forEach((item) => {
          if (item !== '') {
            result.push(item);
          }
        });
      }
      return result;
    },
    // 编辑
    editForm() {
      return { ...this.infoVo, ...this.picVo };
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      // 重新获取详情内容，留言记录
      this.getData();
    },
    /**
     * 获取详情数据
     */
    getData() {
      var data = {
        id: this.id,
      };
      getCarAnnualReviewInfo(data).then((res) => {
        this.infoVo = res;
        this.infoVo.returnImageUrls = res.returnImageUrls.join(',');
      });
    },

    /**
     * 处理关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 预览图片
     * @param {Array<String>} imgs 图片数组
     */
    previewImgs(imgs, titles) {
      previewImage(imgs, 0, titles);
    },
    /**
     * 进行下一步
     */
    nextStep(state) {
      var body = {
        id: this.id,
      };
      // 校验是否有图片上传
      if (this.infoVo.state === 2) {
        const returnImageUrls = this.infoVo.returnImageUrls;
        if (
          returnImageUrls === undefined ||
          returnImageUrls == '' ||
          (Object.prototype.toString.call(returnImageUrls) ===
            '[object Array]' &&
            returnImageUrls.length === 0)
        ) {
          this.$message({
            type: 'warning',
            message: '请上传回传图片',
          });
          return;
        }
        body.returnImagesUrl = returnImageUrls
          ?.split(',')
          .map((item) => item.split('/').at(-1))
          .join();
      }
      let api = null;
      switch (state) {
        case 1:
          api = acceptCarAnnualReview;
          break;
        case 2:
          api = bookedCarAnnualReview;
          break;
        case 4:
          api = illegalCarAnnualReview;
          break;
        case 5:
          api = unExpiredCarAnnualReview;
          break;
        case 6:
          api = doneCarAnnualReview;
          break;
      }
      body.state = state;
      api(body).then((res) => {
        this.$message.success(res || '保存成功！');
        this.$emit('updateData');
        this.handleClose();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-descriptions ::v-deep .table-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: $descriptions-label-bg;
  width: 15%;
}

.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}

.report-sty {
  display: flex;
  margin: 31px 0px 16px 0px;
  justify-content: space-between;
}
.car-imgs {
  display: flex;
  margin: 30px 0px;
  color: #666;
}
.footer {
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 62%;
  padding: 10px 40px;
  box-sizing: border-box;
  background: #fff;
  text-align: right;
  z-index: 999;
}
.car-logo {
  width: 64px;
  height: 64px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.car-info {
  .car-number {
    color: #303133;
    font-size: 20px;
    font-weight: 500;
  }

  .car-info-tag {
    display: inline-block;
    margin-left: 16px;
    padding: 4px 6px;
    border-radius: 2px;
    border: 1px solid #ebeef5;
    font-size: 12px;
    &.car-brand {
      color: #606266;
    }
  }
}
//设置描述列表的行高
::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 7px 10px !important;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: inherit !important;
}
.thumbnail-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
}
</style>
