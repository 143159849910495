<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="品牌名称" prop="name">
            <el-input v-model="editForm.name" placeholder="请输入品牌名称">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="首字母" prop="prefixLetter">
            <el-input
              v-model="editForm.prefixLetter"
              placeholder="请输入首字母"
              maxlength="1"
              @input="handlePrefixLetterInput"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="品牌LOGO" prop="imagePath">
            <p style="font-size: 12px">
              上传该品牌logo，需正方形，建议尺寸大小 80*80
            </p>
            <div>
              <upload-file v-model="editForm.imagePath"></upload-file>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template slot="footer">
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import uploadFile from '@/components/uploadFile.vue';

import { mapState } from 'vuex';

import {
  addCarLogo as addCarLogoApi,
  updateCarLogo as updateCarLogoApi,
} from '@/api/customer/carModelManage';
export default {
  name: 'editCarBrandDialog',
  components: { uploadFile },
  props: {
    visibility: Boolean,
    detail: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      // 表单信息
      editForm: {},
      // 表单规则
      rules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入品牌名称！',
          },
        ],
        prefixLetter: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入首字母！',
          },
        ],
        imagePath: [
          {
            required: true,
            trigger: 'blur',
            message: '请上传品牌LOGO！',
          },
        ],

        // imagePath: [
        //   {
        //     required: true,
        //     // trigger: 'blur',
        //     validator: this.validateImage,
        //   },
        // ],
      },
    };
  },
  computed: {
    // ...mapState({
    //   userId: (state) => state.user.userInfo.userId,
    // }),
    title() {
      return this.detail.id ? '编辑品牌' : '新增品牌';
    },
  },
  created() {
    this.editForm = this.$lodash.clone(this.detail);
  },
  methods: {
    getData() {},
    handlePrefixLetterInput(val) {
      val = val.replace(/[^a-zA-Z]/g, '');
      val = val.toUpperCase();
      this.$set(this.editForm, 'prefixLetter', val);
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var api = this.editForm.id ? updateCarLogoApi : addCarLogoApi;
          api(this.editForm).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save', res);
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
