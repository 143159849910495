<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="签约客户">
                <el-select
                  v-model="form.clientId"
                  @change="selectClient"
                  filterable
                  clearable
                  remote
                  placeholder="请输入客户名称搜索"
                  :remote-method="searchClient"
                  :loading="searchClientLoading"
                >
                  <el-option
                    v-for="item in customList"
                    :key="item.id"
                    :label="item.clientName"
                    :value="item.id"
                  >
                    <span style="color: #8492a6; font-size: 13px">{{
                      item.clientName
                    }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    开票主体
                    <el-tooltip effect="light" placement="bottom-start">
                      <div
                        slot="content"
                        style="
                          color: #666666;
                          font-size: 13px;
                          line-height: 20px;
                          text-indent: 15px;
                        "
                      >
                        开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                        的回款管理门店(保险公司的开票主体也是定损主体)。保险公司<br />管理的开票主体，可查已成为保险公司开票
                        主体的全部门店。
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <el-select
                  v-model="form.invoiceId"
                  @change="selectInvoice"
                  @clear="deleteInvoice"
                  filterable
                  clearable
                  remote
                  placeholder="搜索开票主体名称"
                  :remote-method="searchInvoice"
                  :loading="searchInvoiceLoading"
                >
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="结算周期">
                <el-date-picker
                  class="w100"
                  v-model="form.period"
                  type="month"
                  placeholder="选择时间"
                  value-format="yyyy/MM"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="downEmpty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div class="custom-tabs nopadding">
          <el-button
            v-auth="530"
            type="primary"
            size="small"
            @click="reloadLast"
            >重跑上月</el-button
          >
          <el-button
            v-auth="531"
            size="small"
            style="border-radius: 3px 0px 0px 3px"
            id="reloadButton"
            class="reload-btn"
            @click="download('', lastMoth)"
          >
            <span> 下载上月</span>
          </el-button>
          <el-button
            v-auth="531"
            size="small"
            id="lotButton"
            class="lot-btn"
            @click="lotDrawerEvent"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
          <i
            class="iconfont grow-icon_warn_32 orange"
            style="margin-top: 10px; margin-left: 10px"
          ></i
          ><span
            style="
              font-size: 14px;
              color: #999999;
              margin-top: 11px;
              margin-left: 5px;
            "
            >注：每月1号1点，系统将批量生成上一月对账批次，如之后单据有修改，请操作重跑（仅支持重跑上月）</span
          >
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto; margin-top: 16px">
        <div style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="reconciliationList"
            border
            v-fixedScrollBar="$refs.baseContainer"
          >
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column width="120" label="操作">
              <template #default="{ row }">
                <div class="flex">
                  <span
                    v-auth="530"
                    @click="reload(row.clientId, row.clientName, row.state)"
                    style="margin-right: 10px"
                    class="blue pointer"
                  >
                    重跑
                  </span>
                  <span
                    v-auth="531"
                    class="primary pointer"
                    @click="download(row.filePath, form.period)"
                    v-if="row.state == 0"
                  >
                    下载
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120" label="签约客户" show-overflow-tooltip>
              <template #default="{ row }">
                <div v-if="row.clientName">
                  <div
                    class="text-ellipsis blue pointer"
                    @click="goDetail(row.clientId)"
                  >
                    {{ row.clientName }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120" label="结算周期" prop="period">
            </el-table-column>
            <el-table-column width="120" label="本期合计" prop="paid">
            </el-table-column>
            <el-table-column width="120" label="本期已收" prop="alreadyPaid">
            </el-table-column>
            <el-table-column width="120" label="本期待收" prop="unclearedDebt">
            </el-table-column>
            <el-table-column min-width="160" label="子批次号" prop="subBatchNo">
            </el-table-column>
            <el-table-column
              min-width="100"
              label="生成时间"
              prop="lastUpdated"
            >
            </el-table-column>
            <el-table-column width="120" label="状态" prop="state">
              <template #default="{ row }">
                <div class="flex" v-if="row.state == 0">
                  <p class="dot" style="background: #33ab79"></p>
                  <p>成功</p>
                </div>
                <div class="flex" v-else>
                  <p class="dot" style="background: #e84c4c"></p>
                  <p>失败</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120" label="重跑操作人" prop="operator">
            </el-table-column>
            <el-table-column min-width="120" label="备注" prop="remarks">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
    <!-- 下载批次的抽屉 -->
    <el-drawer
      :visible.sync="lotDrawer"
      size="50%"
      :modal="true"
      :wrapperClosable="true"
    >
      <template slot="title">
        <span style="font-size: 16px; color: #303133">选择要下载的批次</span>
      </template>
      <base-container ref="baseContainer" paddingTop>
        <template>
          <div class="flex-x-between" style="margin-bottom: 20px">
            <div class="flex">
              <p style="margin-top: 10px; margin-right: 10px">结算周期</p>
              <el-date-picker
                class="w100"
                v-model="downLoadPeriod.period"
                type="month"
                size="small"
                placeholder="选择时间"
                value-format="yyyy/MM"
              >
              </el-date-picker>
            </div>
            <div class="el-form-item--small">
              <div class="flex">
                <el-button
                  class="btn_search"
                  type="primary"
                  size="small"
                  @click="getFinanceDownloadBatch"
                  >查询</el-button
                >
                <el-button class="btn_search" size="small" @click="empty"
                  >置空</el-button
                >
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="btns flex-x-between">
            <div class="flex h100" style="flex: 1; overflow: auto">
              <div style="flex: 1; overflow: auto">
                <el-table
                  class="custom-table"
                  :data="downLoadList"
                  border
                  v-fixedScrollBar="$refs.baseContainer"
                >
                  <el-table-column type="index" label="序号" width="60">
                  </el-table-column>
                  <el-table-column width="180" label="操作">
                    <template #default="{ row }">
                      <p
                        v-auth="531"
                        class="primary pointer"
                        @click="download(row.filePath, downLoadPeriod.period)"
                      >
                        下载
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="120"
                    label="批次号"
                    prop="batchNo"
                  >
                  </el-table-column>
                  <el-table-column
                    min-width="120"
                    label="结算周期"
                    prop="period"
                  >
                  </el-table-column>
                  <el-table-column
                    min-width="120"
                    label="生成时间"
                    prop="lastUpdated"
                  >
                  </el-table-column>
                  <el-table-column min-width="120" label="状态" prop="state">
                    <template #default="{ row }">{{
                      row.state == 0 ? '成功' : '失败'
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    min-width="120"
                    label="重跑操作人"
                    prop="operator"
                  >
                  </el-table-column>
                  <el-table-column min-width="120" label="备注" prop="remarks">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <pagination
            @pageChange="downLoadChangePage"
            :total="downloadTotal"
          ></pagination>
        </template>
      </base-container>
    </el-drawer>
    <!-- 重跑上月二次确认弹窗 -->
    <el-dialog :visible.sync="repeatLastMonthDialog" width="26%" top="15%">
      <template slot="title">
        <span style="font-size: 16px; color: #303133">重跑上月</span>
      </template>
      <p style="text-align: left; line-height: 20px">
        点击确认系统将重新生成上月（{{
          lastMoth
        }}）对账数据，本次重跑耗时可能较长，建议1小时后再下载
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="reloadLastMonth(1)"
          >确定</el-button
        >
        <el-button @click="repeatLastMonthDialog = false" size="small"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <!-- 重跑弹窗 -->
    <el-dialog :visible.sync="repeatDialog" width="26%" top="15%">
      <template slot="title">
        <span style="font-size: 16px; color: #303133">重跑</span>
      </template>
      <p style="text-align: left; line-height: 20px">
        点击确认系统将重新生成上月（{{ lastMoth }}）{{
          clientName
        }}客户名称对账数据，本次重跑耗时可能较长，建议1小时后再下载（单个客户数据重跑后，整月数据存在滞后，请留意批次生成时间）
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="reloadLastMonth(2)"
          >确定</el-button
        >
        <el-button @click="repeatDialog = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';

//字典
import { dutyType } from '@/service/dict/dictData';

// 权限
import { verifyAuth } from '@/utils/permissions';

import {
  getReconciliationBatch,
  getFinanceDownloadBatch,
  getReloadUltimoAccountCheckData,
  getDownloadAccountCheckData,
} from '@/api/financial/financialOver';
import { searchClient } from '@/api/customer/customer';
import { searchInvoice } from '@/api/storeManagement/index';

// 工具
import Big from 'big.js';
import { mapState } from 'vuex';
import { downloadFile } from '@/utils/fileApi';
import axios from '@/service/axios';

export default {
  name: 'reconciliationLot',
  components: {
    pagination,
  },
  data() {
    return {
      form: {
        period: '',
      },
      downLoadPeriod: {},
      // 展示更多筛选
      showMore: false,
      //导出的页面设置
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      //批次分页
      downloadTotal: 0,
      downPageOption: {
        size: 10,
        current: 1,
      },
      // 列表数据
      reconciliationList: [],
      downLoadList: [],
      //事故责任字段
      dutyTypeList: dutyType,
      //签约客户
      customList: [],
      searchClientLoading: false,
      //搜索开票主体门店字段
      searchInvoiceLoading: false,
      invoiceList: [],

      //选择下载批次抽屉可见性
      lotDrawer: false,
      //重跑上月二次确认弹窗可见性
      repeatLastMonthDialog: false,
      //重跑弹窗可见性
      repeatDialog: false,
      clientId: '', //重跑单个客户ID
      clientName: '', //重跑单个客户名字
      state: '', //重跑单个客户状态是否成功
      lastMoth: '',

      // 重跑上月按钮节流
      timer: null,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      userId: (state) => state.user.userInfo?.userId,
    }),
  },
  activated() {},
  created() {
    if (!this.$route.params.forms) {
      this.getLastMonth();
      this.getList();
    }
  },
  mounted() {},
  methods: {
    /**
     * 获取上个月月份
     */
    getLastMonth() {
      let now = new Date();
      // 当前月的日期
      let nowDate = now.getDate();
      let lastMonth = new Date(now.getTime());
      // 设置上一个月（这里不需要减1）
      lastMonth.setMonth(lastMonth.getMonth());
      // 设置为0，默认为当前月的最后一天
      lastMonth.setDate(0);
      // 上一个月的天数
      let daysOflastMonth = lastMonth.getDate();
      // 设置上一个月的日期，如果当前月的日期大于上个月的总天数，则为最后一天
      lastMonth.setDate(nowDate > daysOflastMonth ? daysOflastMonth : nowDate);
      this.form.period = lastMonth.Format('yyyy/MM');
      return (this.lastMoth = lastMonth.Format('yyyy/MM'));
    },
    // 置空输入框数据
    empty() {
      this.downLoadPeriod = {};
    },
    downEmpty() {
      this.downLoadPeriod = {};
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    downLoadChangePage(e) {
      this.downPageOption = e;
      this.getFinanceDownloadBatch(false);
    },
    //远程搜索开票主体门店列表
    searchInvoice(keyWord = '') {
      this.searchInvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchInvoiceLoading = false;
        });
    },
    // 选择开票主体门店
    selectInvoice(invoiceId) {},
    //删除开票主体门店
    deleteInvoice() {},
    /**
     * 远程搜索车主客户列表
     */
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择车主客户
     */
    selectClient(clientId) {},

    /**
     * 删除车主客户
     */
    deleteClient() {},
    /**
     * 点击查询
     */
    doSearch() {
      this.getList();
    },
    //获取列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var data = Object.assign(this.form, this.pageOption);
      this.carBillPaid = data;
      getReconciliationBatch(data).then((res) => {
        this.total = res.total;
        this.reconciliationList = res.records;
      });
    },
    //获取批次列表
    getFinanceDownloadBatch(reset = true) {
      if (reset) {
        this.downPageOption.current = 1;
        this.downloadTotal = 0;
      }
      var data = Object.assign(this.downLoadPeriod, this.downPageOption);
      getFinanceDownloadBatch(data).then((res) => {
        this.downloadTotal = res.total;
        this.downLoadList = res.records;
      });
    },
    lotDrawerEvent() {
      this.lotDrawer = true;
      var btn = document.getElementById('lotButton');
      btn.style.cssText =
        'background: #fff;border:1px solid #dedfe6;border-left:0px';
      this.getFinanceDownloadBatch();
    },

    /**
     * 跳转客户详情页
     */
    async goDetail(clientId) {
      await verifyAuth(107);
      this.$router.push({
        name: 'customerDetails',
        query: { id: clientId },
      });
    },
    download(filePath, time) {
      var reBtn = document.getElementById('reloadButton');
      reBtn.style.cssText = 'background: #fff';
      var data = {
        fileName: filePath,
        monthTime: filePath == '' ? time : '',
        companyId: this.companyId,
      };
      downloadFile({
        url: getDownloadAccountCheckData,
        data,
        prefix: '/batch',
      });
    },
    //重跑整个月
    reloadLast() {
      this.repeatLastMonthDialog = true;
      this.clientId = '';
    },
    //重跑上月单个客户
    reload(clientId, clientName, state) {
      this.clientId = Number(clientId);
      this.clientName = clientName;
      this.state = state;
      this.repeatDialog = true;
    },
    reloadLastMonth(number) {
      var data = {
        clientId: this.clientId,
        companyId: this.companyId,
        operatorId: this.userId,
        monthTime: this.clientId == '' ? '' : this.form.period,
      };
      if (number == 1) {
        this.repeatLastMonthDialog = false;
        if (this.timer) {
          return;
        }
        this.timer = setTimeout(() => {
          this.timer = null;
        }, 1e4);
        axios({
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
          method: 'post',
          url: '/batch/reconciliation/finance/reloadUltimoAccountCheckData',
          data,
        }).then((res) => {});
      } else {
        this.repeatDialog = false;
        axios({
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
          method: 'post',
          url: '/batch/reconciliation/finance/reloadOneClientAccountCheckData',
          data,
        }).then((res) => {});
      }
      // getReloadUltimoAccountCheckData(data).then((res) => {
      //   console.log('2222', res);
      // if (res.code === 200) {
      //   this.$message({
      //     type: 'success',
      //     message: '操作成功!',
      //   });
      //  this.getList();
      // }
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}
.dot {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-top: 9px;
  margin-right: 5px;
}
.lot-btn {
  padding: 8px 10px !important;
  margin-left: 0px;
  border-radius: 0px 3px 3px 0px;
  border-left: 0px;
  &:hover {
    background: #ebf7f2 !important;
  }
}
.reload-btn {
  &:hover {
    background: #ebf7f2 !important;
  }
}
</style>
