import {
  onConnect,
  onbindAccount,
  CIMPushManager,
} from '@/service/websocket/cim.web.sdk.js';

export default {
  data() {
    return {
      CIMPushManager,
    };
  },
  methods: {
    initWebsocket() {
      // 挂载
      if (this.CIMPushManager) {
        this.CIMPushManager.onReplyReceived = this.onReplyReceived;
        this.CIMPushManager.onMessageReceived = this.onMessageReceived;
        this.CIMPushManager.onConnectFinished = this.onConnectFinished;
        this.initConnect();
      }
      // console.log(CIMPushManager);
      // window.CIMPushManager = CIMPushManager;
    },
    // 方法
    async initConnect() {
      // let { ip, webPort } = await getWebsocketIp();
      // onConnect(`wss://${ip}:${webPort}`); //
      let ws = process.env.VUE_APP_WEBSOCKET_PATH;
      // let ws = 'wss://im.growxny.com';
      // let ws = 'wss://preim.growxny.com';
      console.log('ws URL:', ws);
      onConnect(ws); // 初始化
    },
    onConnectFinished() {
      let deviceId = window.localStorage.getItem('deviceId');
      console.log('uuid：', deviceId);
      // 登陆
      onbindAccount(deviceId);
    },
    onReplyReceived(reply) {
      // 上线响应
      console.log(reply);
      if (reply.key === 'client_bind' && reply.code == 200) {
        console.log('上线响应');
      }
    },
    onMessageReceived(message) {
      // 收消息
      console.log(message);
      console.log(message.sender + ': ' + message.content);
      if (message.action === 'ACTION_999') {
        // 账号在其他设备登陆
        return false;
      }
      this.handleMessageReceived?.(message);
    },
  },
};
