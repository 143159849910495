import router from '@/router';

import { getCompanyTree as getCompanyTreeApi } from '@/api/customer/store';

import defaultState from './defaultState';
const state = defaultState();
export default {
  state,
  getters: {
    isHeadquarters(state) {
      // 当前选中门店
      // let current = state.companyList.find(
      //   (item) => item?.company?.[0]?.companyId === state.companyId
      // );
      // return !current?.company?.[0]?.parentId;
      return state.headquarters;
    },
  },
  mutations: {
    updateCompanyList(state, { list = [] }) {
      state.companyList = list;
    },
    updateCompanyId(state, { id, name }) {
      state.userInfo.companyId = id;
      state.companyId = id;
      state.companyName = name;
    },
    updateHeadquarters(state, { headquarters }) {
      state.headquarters = headquarters;
    },
    updateCompanyTree(state, { list = [] }) {
      state.companyTree = list;
    },
    updateUser(state, { id, name }) {
      state.userInfo.userId = id;
      state.userInfo.realName = name;
    },
    updatePermission(state, { roles, roleName }) {
      state.roles = roles;
      state.roleName = roleName;
      state.permissionChangeCount++;
    },
    /**
     * 切换显示隐藏成本价
     */
    changeShowCostState(state) {
      state.showCost = !state.showCost;
    },
    /**
     * 切换选中库存大于0  开单-搜索商品组件
     */
    changeInStock(state, { value }) {
      state.inStock = value;
    },
    changeUnTreatedState(state, value) {
      state.unTreatedState = value++;
    },
  },
  actions: {
    // 登出
    logout(context) {
      router.push('/login');
      context.commit('RESET_STATE');
    },
    // 获取操作门店列表树
    async getCompanyTree(context) {
      let list = await getCompanyTreeApi();
      if (!list) list = [];

      function isHasChild(item, parents = []) {
        // for (let i = 0; i < arr.length; i++) {
        // let item = arr[i];
        if (item.isCompany) {
          parents.forEach((el) => (el.hasChild = true));
        }
        if (item.nextGroup?.length) {
          parents.push(item);
          // isHasChild(item.nextGroup, parents);
          item.nextGroup.forEach((el) => isHasChild(el, parents));
        }
        // }
      }
      // isHasChild(list);

      list.forEach((el) => isHasChild(el));

      function delEmpty(arr) {
        for (var i = 0; i < arr.length; ) {
          let item = arr[i];

          if (!item.hasChild && !item.isCompany) {
            arr.splice(i, 1);
          } else {
            i++;
          }
          if (item.nextGroup) {
            delEmpty(item.nextGroup);
          }
        }
      }
      delEmpty(list);

      // 添加当前门店选项
      list.push({
        id: context.state.companyId,
        companyIds: [context.state.companyId],
        // fakeId: `${}-${context.state.companyId}`,
        name: context.state.companyName,
      });

      context.commit('updateCompanyTree', { list });
    },
  },
  modules: {},
};
