<template>
  <el-dialog
    :title="title"
    top="15vh"
    width="45%"
    :append-to-body="true"
    :visible="showBusinessSourceDialog"
    :before-close="handleClose"
    :close-on-click-modal="false"
    class="dialogSize"
  >
    <div style="overflow: auto">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务来源" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入来源名称"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属客户" prop="clientName">
              <el-popover placement="bottom" trigger="manual" v-model="visible">
                <el-table
                  :data="clientList"
                  class="customer-table"
                  :max-height="350"
                  border
                  @current-change="handleCurrentChange"
                >
                  <el-table-column
                    width="100"
                    property="clientName"
                    label="客户名称"
                  ></el-table-column>
                  <el-table-column
                    width="100"
                    property="isAgreementClient"
                    label="协议客户"
                  >
                    <template #default="{ row }">
                      <span v-if="row.isAgreementClient == 1"
                        >协议客户<span v-if="row.beContract"
                          >（包干）</span
                        ></span
                      >
                      <span v-else></span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="70"
                    property="clientLevel"
                    label="等级"
                  ></el-table-column>
                </el-table>
                <div
                  v-if="hasMore"
                  class="load-more-btn"
                  @click="getCustomerData(false, true)"
                >
                  加载更多数据
                </div>
                <div ref="divRef" slot="reference" @click="showPopover">
                  <el-input
                    v-model="form.clientName"
                    size="small"
                    placeholder="请输入客户名称搜索"
                    @input="getCustomerData(true)"
                    @clear="handleClear"
                    clearable
                  ></el-input>
                </div>
                <!-- @focus="showPopover" -->
                <!-- @blur="visible = false" -->
              </el-popover>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="启用状态">
              <el-switch v-model="form.isActive" active-color="#409eff">
              </el-switch>
              <span style="color: red; margin-left: 10px"
                >(已经选择<span>{{ form.isActive ? '启用' : '禁用' }}</span
                >本业务来源)</span
              >
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="isHeadquarters">
            <el-form-item label="共享状态">
              <el-switch v-model="form.isShare" active-color="#409eff">
              </el-switch>
              <span style="color: red; margin-left: 10px"
                >(已经选择<span>{{ form.isShare ? '共享' : '不共享' }}</span
                >到分店)</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="过滤现结提车"
              prop="isSelfPayingTakeCarMustPaid"
            >
              <el-switch
                v-model="form.isSelfPayingTakeCarMustPaid"
                active-color="#409eff"
              >
              </el-switch>
              <span style="color: red; margin-left: 10px"
                >(自费车单<span>{{
                  form.isSelfPayingTakeCarMustPaid ? '不必' : '必须'
                }}</span
                >结账后才能提车)</span
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template slot="footer">
      <div>
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" @click="verifyBeforeSave" type="primary"
          >确定</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
// 接口
import {
  saveBillSource,
  updateBillSource,
} from '@/api/systemManagement/carOrderSettings/businessSource';

import { getDictValueByLabel } from '@/service/dict/dictTool';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { levelIconsOther } from '@/const/client';
import lodash from 'lodash';
import { searchClient, getClientBySearch } from '@/api/customer/customer';

//组件
// import selectServiceCategory from './selectServiceCategory';
export default {
  // props: ['showServiceManagementDialog', 'id'],
  props: {
    showBusinessSourceDialog: Boolean,
    id: [Number, String],
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  //   components: { selectServiceCategory },
  data() {
    return {
      title: '新增业务来源',
      // 表单数据
      form: {
        isActive: true, //启用状态
        isShare: true, //共享状态
        companyId: '',
      },
      productIdsList: [],
      //远程搜索加载
      loading: false,
      //品名数据
      productNamesList: [],
      product: [],

      // 搜索用户
      clientList: [],
      searchClientLoading: false,
      clientInfo: {},
      // 分页所在页数
      pageCurrent: 0,
      // 可见性
      visible: false,
      // 是否未加载完所有数据
      hasMore: true,
      // 表单规则
      rules: {
        name: [{ required: true, message: '请输入业务来源', trigger: 'blur' }],
        clientName: [
          { required: true, message: '请输入所属客户', trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.companyId,
    }),
    ...mapGetters(['isHeadquarters']),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  created() {
    if (this.id) {
      this.title = '编辑业务来源';
      this.form = this.detail;
      console.log('form', this.form);
      console.log('detail', this.detail);
    }

    this.getCustomerData();
  },
  mounted() {
    // 在 mounted 钩子中添加事件监听器
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    // 在组件销毁之前移除事件监听器
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    /**
     * 关闭/取消
     */
    handleClose() {
      this.$emit('update:showBusinessSourceDialog', false);
    },
    /**
     * 保存前校验
     */
    verifyBeforeSave() {
      // console.log(this.isHeadquarters, 'this.isHeadquarters');
      // if (!this.isHeadquarters) this.form.isShare = null;
      if (!this.isHeadquarters) delete this.form.isShare;
      this.$refs.form.validate((result, object) => {
        if (result) {
          this.doSave();
        } else {
          this.$message.warning('请填写必填项');
          console.log(result, object);
        }
      });
    },
    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord = '', concat = false) {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.clientList = concat
            ? this.clientList.concat(res.records)
            : res.records;
          this.clientList = this.$lodash.unionBy(this.clientList, 'id');
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    //搜索客户列表-实现翻页
    getCustomerData(reset, loadMore) {
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      console.log(this.form.clientName, 'form.clientName');
      if (!this.isFirstLoad) this.isLoading = true;
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;
        //  滚动到头部
        // this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      getClientBySearch(
        {
          clientMsg: this.form.clientName,
          companyId: this.$store.state.user.userInfo.companyId,
          ...this.pagination,
        },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (reset) this.clientList = [];
          this.clientList.push(...res.records);
          if (this.clientList.length == 0) {
            this.visible = true;
            if (this.form.clientId) this.form.clientId = '';
            if (this.form.clientName) this.form.clientName = '';
            this.getCustomerData(true);
          }
          this.hasMore = this.pageCurrent < res.pages;
        })
        .finally(() => {
          this.isLoading = false;
          this.isFirstLoad = false;
        });
    }, 5e2),
    // 弹框常显
    showPopover() {
      this.getCustomerData(true);
      // setTimeout(() => {
      this.visible = true;
      // }, 1000);
    },
    //失去焦点取消下拉框
    handleClickOutside(event) {
      // 判断点击事件是否发生在你的 div 以外的地方
      if (this.$refs.divRef && !this.$refs.divRef.contains(event.target)) {
        this.visible = false;
        console.log(1);
      } else {
        this.visible = true;
      }
    },
    /**
     * 选中
     */
    handleCurrentChange(val) {
      if (val != null) {
        const client = this.clientList.find((item) => item.id === val.id);
        this.clientInfo = client;
        // this.form.name = client.clientName;
        this.form.clientId = val.id;
        this.form.clientName = val.clientName;
        this.visible = false;
      }
    },
    /**
     * 清空
     */
    handleClear() {
      if (this.form.clientId) this.form.clientId = '';
      if (this.form.clientName) this.form.clientName = '';
      this.visible = true;
      console.log(this.visible, '  this.visibl');
      // this.clientInfo = {};
    },
    /**
     * 保存
     */
    async doSave() {
      this.form.companyId = this.companyId;
      //编辑
      if (this.id) {
        updateBillSource(this.form).then((res) => {
          this.$message.success('操作成功');
          this.$emit('save');
          this.handleClose();
        });
      } else {
        saveBillSource(this.form).then((res) => {
          this.$message.success('操作成功');
          this.$emit('save');
          this.handleClose();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.customer-table ::v-deep .el-table__body-wrapper {
  overflow: auto;
}

.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}

.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.dialogSize {
  height: 800px;
}

.rows-tip {
  color: #999;
  line-height: 18px;
}
.img-note {
  color: #999;
  font-size: 14px;
}
.car-imgs {
  display: flex;
}
.block-title {
  margin: 30px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.client-list {
  .client {
    display: flex;
    align-items: center;
    > .icon_delete {
      font-size: 18px;
      color: #ff9200;
      margin-right: 15px;
    }
    .client-info {
      width: 95%;
      padding: 12px 18px;
      background: #f5f7fa;
      border-radius: 4px;
      > div + div {
        margin-top: 10px;
      }
      i {
        margin: 0 5px;
      }
      .icon_person {
        color: $blue;
      }
      .icon_sex {
        color: $blue;
      }
      .icon_grade {
        color: $blue;
      }
      .grow-icon_girl {
        color: #f48080;
      }
      .icon_wechat {
        color: #999;
      }
      .icon_wechat_active {
        color: #00c800;
      }
      .icon_phone {
        color: #42bbb8;
      }
      .icon_vip {
        color: $orange;
      }
      .icon_sex,
      .icon_grade,
      .icon_wechat,
      .icon_wechat_active,
      .icon_vip {
        font-size: 14px;
      }
    }
  }
}
</style>
