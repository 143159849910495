<template>
  <el-dialog
    title="导入批次明细列表"
    :visible="visibility"
    width="90%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-table class="custom-table" ref="queryDataTable" :data="listData" border>
      <el-table-column
        v-for="(item, index) in showColumns"
        :key="index"
        width="200"
        :label="item.label"
        :prop="item.prop"
      >
      </el-table-column>
    </el-table>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
      <el-button
        v-auth="717"
        size="small"
        type="primary"
        @click="downloadAllRecords"
        >导出批次列表</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  dataImportRecordBatchDetailList,
  dataImportRecordBatchDetailExportUrl,
} from '@/api/system/dataImport';
import { hasAuth } from '@/utils/permissions';

export default {
  name: 'dataImportBatchDetailPageDialog',
  components: {
    pagination,
  },
  props: {
    visibility: Boolean,
    batchNumber: [String],
    batchType: [String],
  },
  data() {
    return {
      listData: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      // 展示列表信息——动态的，不同的数据类型列不一样
      showColumns: [],
      exportUrl: dataImportRecordBatchDetailExportUrl,
    };
  },
  computed: {},
  created() {
    this.getListData(false);
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getListData(false);
    },
    /**
     * 查询银行流水数据，以便能够认领
     * @param reset 是否重置查询条件
     */
    getListData(reset) {
      var res = hasAuth(716);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var data = Object.assign({}, this.pageOption, {
        batchNumber: this.batchNumber,
        batchType: this.batchType,
      });
      const _this = this;
      dataImportRecordBatchDetailList(data).then((res) => {
        const { pageData, headColumns } = res;
        //
        _this.showColumns = headColumns;
        // 修改总条数
        _this.total = pageData.total;
        // 赋值records
        let _listData = [];
        pageData.records.forEach((item) => {
          const objType = Object.prototype.toString.call(item.dataJson);
          let dataJsonObj;
          if (objType === '[object String]') {
            dataJsonObj = JSON.parse(item.dataJson);
          } else {
            dataJsonObj = item.dataJson;
          }
          // 平铺下数据
          let itemData = {
            ...dataJsonObj,
            ...item,
          };
          _listData.push(itemData);
        });
        _this.listData = _listData;
      });
    },
    downloadAllRecords() {
      var preData = {
        batchNumber: this.batchNumber,
        batchType: this.batchType,
      };
      downloadFile({
        url: this.exportUrl,
        data: preData,
      });
    },
    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
