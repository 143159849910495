<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="故障现象">
                <el-input
                  v-model="form.wzAppearanceName"
                  placeholder="故障现象"
                  @keyup.enter.native="getList"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="故障原因">
                <el-input
                  v-model="form.wzCauseName"
                  placeholder="搜索故障原因"
                  @keyup.enter.native="getList"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="维修方案">
                <el-input
                  v-model="form.wzServiceName"
                  placeholder="搜索维修方案"
                  @keyup.enter.native="getList"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="getList"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="状态">
              <el-select v-model="form.state" placeholder="请选择" clearable>
                <el-option label="待处理" :value="1"> </el-option>
                <el-option label="已处理" :value="2"> </el-option>
                <el-option label="已新增" :value="3"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车型">
              <el-select
                v-model="form.carModel"
                filterable
                remote
                placeholder="搜索车型"
                :remote-method="searchModel"
                :loading="searchModelLoading"
                clearable
              >
                <el-option
                  v-for="item in carModelList"
                  :key="$lodash.get(item, 'series.id')"
                  :value="
                    $lodash.get(
                      item,
                      'series.name',
                      $lodash.get(
                        item,
                        'brand.name',
                        $lodash.get(item, 'logo.name')
                      )
                    )
                  "
                  @click.native="selectCarModel(item)"
                >
                  <span v-html="item.markedRedName"></span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签约客户">
              <el-select
                v-model="form.clientId"
                @change="selectClient"
                filterable
                clearable
                remote
                placeholder="请输入客户名称"
                :remote-method="searchClient"
                :loading="searchClientLoading"
              >
                <el-option
                  v-for="item in customList"
                  :key="item.id"
                  :label="item.clientName"
                  :value="item.id"
                >
                  <span style="color: #8492a6; font-size: 13px">{{
                    item.clientName
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="进店时间">
              <el-date-picker
                class="w100"
                v-model="form.intoStoreTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="btns flex-x-between">
        <div>
          <div class="custom-tabs nopadding">
            <el-button size="small" type="primary" @click="batchProcessing"
              >批量处理</el-button
            >
            <i
              class="pointer iconfont grow-icon_warn_32"
              style="color: #ff9200; font-size: 15px; margin: 8px 6px"
            ></i
            ><span style="color: #909399; margin-top: 10px; font-size: 12px"
              >可提炼问诊模型库时请点击操作新增模型，已有模型时请点击处理标记状态</span
            >
          </div>
        </div>
        <div>
          <el-button
            v-auth="494"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <el-table
          class="custom-table"
          :data="faultList"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column width="150" label="操作">
            <template #default="{ row }">
              <span
                v-auth="557"
                v-if="row.state == 1"
                class="blue pointer"
                style="margin-right: 6px"
                @click="addEvent(row)"
                >新增模型</span
              >
              <span
                v-auth="564"
                v-if="row.state == 1"
                class="red pointer"
                @click="dealEvent(row.id)"
                >处理</span
              >
            </template>
          </el-table-column>
          <el-table-column
            min-width="100"
            label="故障现象"
            prop="wzAppearanceName"
          >
          </el-table-column>
          <el-table-column width="150" label="故障原因" prop="wzCauseName">
          </el-table-column>
          <el-table-column width="150" label="维修方案" prop="wzServiceName">
          </el-table-column>
          <el-table-column width="150" label="故障图">
            <template #default="{ row }">
              <div class="flex-y-center" style="padding: 10px 0">
                <span
                  v-if="!$lodash.get(row, 'faultPicList.length', 0)"
                  style="width: 32px; text-align: center"
                  >无</span
                >
                <el-popover v-else placement="top" trigger="hover">
                  <upload-file-list
                    disabled
                    :value="row.preImages"
                  ></upload-file-list>
                  <div class="video-list">
                    <video
                      class="thumbnail-video"
                      preload="auto"
                      v-for="(src, index) in row.preVideos"
                      :key="index"
                      :src="src"
                      @click="handleClickVideo($event, row.preVideos, index)"
                      :poster="playImg"
                    >
                      <source :src="src" type="video/mp4" />
                      您的设备不支持 video 标签。
                    </video>
                  </div>
                  <template slot="reference">
                    <el-badge
                      style="margin-right: 8px"
                      :value="row.faultPicList.length"
                    >
                      <img class="thumbnail-img" :src="row.prePoster" />
                    </el-badge>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" label="车牌" prop="carNumber">
          </el-table-column>
          <el-table-column min-width="100" label="车型" prop="carModel">
          </el-table-column>
          <el-table-column min-width="150" label="操作门店" prop="companyName">
          </el-table-column>
          <el-table-column width="170" label="关联车单号" prop="billCode">
            <template #default="{ row }">
              <div class="blue pointer" @click="goDetail(row.billId)">
                {{ row.billCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100" label="接车员" prop="creatorName">
          </el-table-column>
          <el-table-column width="120" label="签约客户" prop="clientName">
          </el-table-column>
          <el-table-column min-width="100" label="进店时间" prop="dateCreated">
          </el-table-column>
          <el-table-column width="100" label="状态" prop="state">
            <template #default="{ row }">
              {{
                row.state == 1 ? '待处理' : row.state == 2 ? '已处理' : '已新增'
              }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="操作人" prop="operatorName">
          </el-table-column>
          <el-table-column width="100" label="操作时间" prop="lastUpdated">
          </el-table-column>
        </el-table>
      </div>

      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
    <!-- 处理提示 -->
    <el-dialog
      title="提示"
      :visible.sync="dealDialog"
      width="25%"
      top="15%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      modal
    >
      <div class="prompt-sty">
        <p>点击“确定”将选择的疑难杂症项标记为已处理</p>
      </div>
      <template slot="footer">
        <div style="text-align: center">
          <el-button @click="dealDialog = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="changeSate"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 新增问诊模型 -->
    <consultation-dialog
      v-if="editDialog"
      :visibility.sync="editDialog"
      :title="title"
      :rowData="rowData"
      :showContent="false"
      @upList="getList"
    ></consultation-dialog>

    <videoPlayerDialog
      v-if="showVideoPlayerDialog"
      :visibility.sync="showVideoPlayerDialog"
      :videos="previewVideos"
      :index="previewVideoIndex"
    ></videoPlayerDialog>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
//模糊搜索接口
import { searchCarModel } from '@/api/customer/car';
import { searchClient } from '@/api/customer/customer';
import {
  getWzDifficultByPage,
  editWzDifficultState,
  exportWzDifficultUrl,
} from '@/api/system/inquiryModel';
//字典
import { receptionType } from '@/service/dict/dictData';
import uploadFileList from '@/components/uploadFileList';
import consultationDialog from '@/components/businessComponent/consultationDialog.vue';
// 方法
import { previewImage, isImage, isVideo } from '@/utils/fileApi';
import playImg from '@/assets/img/play.jpeg';

import videoPlayerDialog from '@/components/videoPlayerDialog.vue';
//新增问诊弹窗
export default {
  name: 'complicatedDisease',
  components: {
    pagination,
    ExportDialog,
    uploadFileList,
    consultationDialog,
    videoPlayerDialog,
  },
  data() {
    return {
      //导出
      exportUrl: exportWzDifficultUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      form: {},
      //搜索车型信息列表字段
      searchModelLoading: false,
      carModelList: [],
      //签约客户
      customList: [],
      searchClientLoading: false,
      receptionType: receptionType,
      faultList: [],
      //删除提示框可见性
      dealDialog: false,
      ids: [],
      carList: [],
      //新增问诊弹窗可见性
      editDialog: false,
      title: '新增问诊模型',
      rowData: {},

      previewVideos: [],
      previewVideoIndex: 0,
      showVideoPlayerDialog: false,
      playImg,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 置空输入框数据
    empty() {
      this.form = {};
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //远程搜索车型信息列表
    searchModel(key = '') {
      this.searchModelLoading = true;
      searchCarModel(key)
        .then((res) => {
          this.carModelList = res.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    selectCarModel(val) {
      //   this.carLogoName = val.logo.name;
      //   this.carBrandName = val.brand.name;
      //   this.carSeriesName = val.series.name;
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择车主客户
     */
    selectClient(clientId) {},

    /**
     * 删除车主客户
     */
    deleteClient() {},
    dealEvent(id) {
      this.ids = [id];
      this.dealDialog = true;
    },
    handleSelectionChange(val) {
      this.ids = val.map((item) => {
        return item.id;
      });
    },
    //批量处理
    batchProcessing() {
      if (this.ids.length) {
        this.dealDialog = true;
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择!',
        });
      }
    },
    //获取列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var time = {
        intoStoreStartTime: this.form.intoStoreTime?.[0]
          ? this.form.intoStoreTime?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTime?.[1]
          ? this.form.intoStoreTime?.[1]
          : '',
      };
      var data = Object.assign(this.form, time, this.pageOption);
      getWzDifficultByPage(data).then((res) => {
        this.total = res.total;
        this.faultList = res.records.map((fault) => {
          // 处理故障、完工图片视频
          if (fault.faultPicList) {
            let pre = [...fault.faultPicList];
            let preImages = [];
            let preVideos = [];
            pre.forEach((url) => {
              // 图片
              if (isImage(url)) {
                preImages.push(url);
              } else if (isVideo(url)) {
                // 视频
                preVideos.push(url);
              }
            });
            fault.preImages = preImages;
            fault.preVideos = preVideos;
            // 封面
            if (preImages.length) {
              fault.prePoster = preImages[0];
            } else {
              fault.prePoster = playImg;
            }
          }
          return fault;
        });
        console.log(' this.faultList:', this.faultList);
      });
    },
    //编辑疑难杂症状态为已处理
    changeSate() {
      editWzDifficultState(this.ids).then((res) => {
        if (res.code == 200) {
          this.$message.success('操作成功');
          this.dealDialog = false;
          this.getList();
        }
      });
    },
    //关闭提示框
    handleClose() {
      this.dealDialog = false;
    },
    //删除弹窗确定按钮
    determine() {
      this.$message({
        message: '操作成功',
        type: 'success',
      });
      this.dealDialog = false;
    },
    //新增
    addEvent(row) {
      this.rowData = row;
      this.editDialog = true;
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
    //点击列表视频
    handleClickVideo(e, list, index) {
      e.preventDefault();
      this.previewVideos = list;
      this.previewVideoIndex = index;
      this.showVideoPlayerDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.btns {
  margin-bottom: 20px;
}
.prompt-sty {
  text-align: center;
  margin: 24px 0px 60px 0;
}

.video-list {
  display: inline-flex;
  .thumbnail-video {
    margin-left: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
}
.thumbnail-img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
}
</style>
