<template>
  <base-container :defaultPadding="false">
    <template slot="header">
      <div style="margin-bottom: 30px">
        <el-form :model="form" label-width="100px" size="small">
          <el-col :span="6">
            <el-form-item>
              <template slot="label">
                <div>
                  消费门店
                  <el-tooltip
                    effect="light"
                    content="车辆的消费门店，可查连锁范围内全部门店"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                size="small"
                v-model="form.consumeCompanyId"
                @change="selectStore"
                @clear="deleteStore"
                filterable
                clearable
                remote
                placeholder="搜索车店名称、编号"
                :remote-method="searchStore"
                :loading="searchStoreLoading"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left">{{ item.companyName }}</span>
                  <span style="float: right">{{ item.companyId }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目名称">
              <el-input
                v-model="form.billServiceMsg"
                placeholder="输入服务、商品项目名称"
                clearable
                @keyup.enter.native="getDataList"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-form-item>
              <template slot="label">
                <el-button
                  class="btn_search"
                  type="primary"
                  @click="getDataList"
                  >查询</el-button
                >
              </template>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
    </template>
    <el-tabs
      class="custom-tabs"
      v-model="activeName"
      type="card"
      @tab-click="log"
    >
      <el-tab-pane label="用户管理" name="first">
        <template slot="label">
          <div style="padding: 10px 0">
            <el-badge :value="total" :max="99">
              <span>消费记录（￥{{ totalConsumptionRecord }}）</span>
            </el-badge>
          </div>
        </template>
      </el-tab-pane>
      <!-- bug764隐藏 -->
      <!-- <el-tab-pane label="用户管理" name="second">
        <template slot="label">
          <div style="padding: 10px 0">
            <el-badge :value="8" :max="99">
              <span>消费记录（导入）（￥{{ totalConsumptionRecord }}）</span>
            </el-badge>
          </div>
        </template>
      </el-tab-pane> -->
    </el-tabs>
    <el-table
      :data="tableData"
      border
      class="custom-table"
      style="height: calc(63vh)"
    >
      <el-table-column type="index" label="序号" width="60"> </el-table-column>
      <el-table-column width="180" label="消费门店" prop="consumeCompanyName">
      </el-table-column>
      <el-table-column min-width="180" label="单据号">
        <template slot-scope="scope">
          <span
            class="text_operation blue"
            @click="goDetail(scope.row.billId)"
            >{{ scope.row.billCode }}</span
          >

          <p v-if="scope.row.billType != 0">
            {{ scope.row.billType | dict('carBillType') }}
          </p>
          <p v-else>
            {{ scope.row.isOthersSelfPayState | dict('isOthersSelfPayState') }}
          </p>
        </template>
      </el-table-column>
      <el-table-column min-width="180" label="车牌号" prop="carNumber">
      </el-table-column>
      <el-table-column min-width="180" label="车架号" prop="uniqueId">
      </el-table-column>
      <el-table-column min-width="180" label="消费客户">
        <template slot-scope="scope">
          <span>{{ scope.row.consumerName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="180"
        label="进店时间"
        prop="intoStoreStartTime"
      ></el-table-column>
      <el-table-column
        width="180"
        label="进店里程"
        prop="mileage"
      ></el-table-column>

      <el-table-column min-width="200" label="服务">
        <template slot-scope="scope">
          <p v-if="scope.row.billServiceNames">
            {{
              scope.row.billServiceNames
                .map((i) => {
                  return i;
                })
                .join()
            }}
          </p>
        </template>
        <!-- <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.services" :key="index">
            {{ index + 1 }}、{{ item.name }}
          </span>
        </template> -->
      </el-table-column>
      <el-table-column min-width="200" label="商品">
        <!-- <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.goods" :key="index">
            {{ index + 1 }}、{{ item.name }}
          </span>
        </template> -->
        <template slot-scope="scope">
          <p v-if="scope.row.billGoodsNames">
            {{
              scope.row.billGoodsNames
                .map((i) => {
                  return i;
                })
                .join()
            }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        label="单据金额"
        prop="total"
      ></el-table-column>
      <el-table-column
        width="150"
        label="实收金额"
        prop="paid"
      ></el-table-column>
      <el-table-column min-width="180" label="结账备注" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.billCheckRemark }}
        </template>
      </el-table-column>
    </el-table>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>

    <!-- 接车单详情抽屉 -->
    <details-list
      v-if="billId"
      :billId.sync="billId"
      :dialogTableVisible.sync="dialogTableVisible"
    >
    </details-list>
  </base-container>
</template>

<script>
import DetailsList from '@/views/customerManagement/detailsList.vue';

import pagination from '@/components/Pagination.vue';

import { mapState } from 'vuex';

//接口
import { searchStore } from '@/api/customer/store';
import { carGetCarConsumptionRecord } from '@/api/customer/car';
export default {
  name: 'consumptionRecords',
  components: { pagination, DetailsList },
  props: {
    carId: { type: [Number, String], default: '' },
  },
  data() {
    return {
      form: {},

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      //消费门店
      searchStoreLoading: false,
      storeList: [],
      // 是否正在加载门店数据
      loading: false,
      activeName: 'first',
      tableData: [],
      totalConsumptionRecord: '',

      billId: '',
      dialogTableVisible: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.searchStore();
    this.getDataList();
  },
  methods: {
    // 远程加载门店数据方法
    remoteMethod() {},
    log(e) {},
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getDataList();
    },
    //远程搜索消费门店列表
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchStore(keyWord, this.companyId)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    // 选择消费门店
    selectStore(storeId) {},
    //删除消费门店
    deleteStore() {},

    /**
     * 加载数据
     */
    getDataList() {
      var carId = {
        carId: this.carId,
      };
      var data = Object.assign(this.form, carId, this.pageOption);
      carGetCarConsumptionRecord(data).then((res) => {
        this.total = res.recordVos?.total || 0;
        this.tableData = res.recordVos?.records || [];
        this.totalConsumptionRecord = res?.totalAmount || 0;
      });
    },
    /**
     * 跳转车单详情抽屉
     */
    goDetail(id) {
      this.billId = id; // 车单ID
      this.dialogTableVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  width: 100%;
  display: inline-flex;
}
.custom-tabs {
  ::v-deep .el-tabs__header {
    margin: 0px 0px 0px !important;
    border-bottom: none;
    .el-tabs__nav {
      // border-bottom: 1px solid #e4e7ed;
    }
    .el-tabs__item {
      height: auto;
      border-bottom: 1px solid #e4e7ed;
      &.is-active {
        background: rgba(51, 171, 121, 0.1);
      }
    }
  }
}
</style>
