import http from '@/service/http';
import store from '@/store';

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 跨门店库存查询/库存共享查询
 */
export function listGoodsInventory(data, config = {}) {
  return http.post('/admin/inventory/listGoodsInventory', data, config);
}

/**
 * 查询库存商品详情
 */
export function goodsInventoryDetail(data, config = {}) {
  return http.post('/admin/inventory/goodsInventoryDetail', data, config);
}

/**
 * 查询库存商品供应商
 */
export function queryGoodsSupplierInfo(data, config = {}) {
  return http.post('/admin/inventory/queryGoodsSupplierInfo', data, config);
}

export const exportUrl = '/admin/inventory/listGoodsInventory/export';

// =============================采购单================================
/**
 * 采购报表(按配件)列表查询
 */
export function purchaseReportByGoods(data, config = {}) {
  return http.post('/admin/report/purchase/reportByGoods', data, config);
}

/**
 * 采购报表(按配件)列表导出
 */
export const purchaseReportByGoodsExportUrl =
  '/admin/report/purchase/reportByGoods/export';

/**
 * 供应商商品配件采购价管控配置-列表查询
 */
export function purchasePriceList(data, config = {}) {
  return http.post('/admin/purchase/price/list', data, config);
}
/**
 * 供应商商品配件采购价管控配置-列表导出
 */
export const purchasePriceListExportUrl = '/admin/purchase/price/list/export';
/**
 * 供应商商品配件采购价管控配置-列表查询
 */
export function purchasePricedeleteBatch(data, config = {}) {
  return http.post('/admin/purchase/price/deleteBatch', data, config);
}
/**
 * 供应商商品配件采购价管控配置-批量导入
 */
export function purchasePriceImport(file) {
  let formData = new FormData();
  formData.append('file', file);

  return http.request({
    method: 'post',
    url: '/admin/purchase/price/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    timeout: 18e5,
  });
}

/**
 * 供应商商品配件采购价管控配置-批量导入结果
 */
export function purchasePriceImportResult() {
  return http.post('/admin/purchase/price/importResult');
}

/**
 * 供应商商品配件采购价管控配置-批量导入下载
 */
export const purchasePriceImportResultExportUrl =
  '/admin/purchase/price/importFailMessage/export';
// ==============================采购单===============================
