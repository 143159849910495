<template>
  <el-dialog
    :visible="stayInvoicingDialog"
    size="50%"
    :append-to-body="true"
    :before-close="handleClose"
    :wrapperClosable="false"
  >
    <template slot="title">
      <div class="flex">
        <span style="font-size: 16px">车单结账</span>
        <!-- <div v-if="otherData.isOthersSelfPayState && [0].includes(billType)">
          <i class="iconfont grow-icon_warn_32 orange" style="margin: 0px 5px">
          </i>
          <span style="font-size: 12px; color: #999999; font-weight: 400"
            >自费类型：他人自费</span
          >
        </div> -->
        <!-- 2022-12-05产品需求去掉 -->
        <!-- <div>
          <i class="iconfont grow-icon_warn_32 orange" style="margin: 0px 5px">
          </i>
          <span
            style="font-size: 12px; color: #999999; font-weight: 400"
            v-if="billType != 0"
            >{{ billType | dict('carBillType') }}业务</span
          >
          <span v-else>
            自费业务：{{
              otherData.isOthersSelfPayState | dict('isOthersSelfPayState')
            }}
          </span>
        </div> -->
      </div>
    </template>
    <div class="contrent">
      <div class="content-header">
        <div class="car-infor">
          <p>{{ carInfo.carNumber }}</p>
          <div style="margin-top: 30px">
            <span class="primary" v-if="billType == 0">
              {{
                otherData.isOthersSelfPayState | dict('isOthersSelfPayState')
              }}</span
            >
            <span v-else-if="billType == 1" class="primary">车主包干</span>
            <span v-else-if="billType == 2" class="primary">保险公司</span>
            <span v-else-if="billType == 3" class="primary">三包厂家</span>

            <div class="primary" style="display: inline-block">
              <div
                class="flex"
                v-if="billType == 0 && otherData.isOthersSelfPayState == false"
              >
                <span v-if="payerInfo.consumerName">
                  {{ payerInfo.consumerName }}</span
                >
                <span v-else> 暂未绑定客户</span>
              </div>
              <div v-else-if="!billType">
                <span v-if="payerInfo.consumerName">
                  {{ payerInfo.consumerName }}</span
                >
                <span v-else> 暂未绑定客户</span>
              </div>
              <div
                class="primary"
                v-else-if="
                  billType == 0 && otherData.isOthersSelfPayState == true
                "
              >
                {{ payerInfo.consumerName }}
              </div>
            </div>
            <!-- <span
              class="primary"
              v-if="billType == 0 && otherData.isOthersSelfPayState == true"
            >
              {{ payerInfo.consumerName }}</span
            > -->
            <span v-if="billType == 1" class="primary">{{ customerName }}</span>
            <span v-else-if="billType == 2" class="primary">{{
              insuranceCompanyName
            }}</span>
            <span v-else-if="billType == 3" class="primary">{{
              undertakeInsuranceCompanyName
            }}</span>
            <!-- <span
              class="primary"
              v-if="otherData.isOthersSelfPayState && [0].includes(billType)"
              >他人自费</span
            > -->
          </div>
        </div>
        <div class="bill">
          <p>
            应收总额：<span>￥</span
            ><span>{{ amountOfDocuments | toThousands }}</span>
          </p>
          <p v-auth="{ code: 285, handle: handleMethod }">
            账单时间：
            <el-date-picker
              :disabled="authDisabled"
              type="datetime"
              v-model="checkDate"
              placeholder="请选择结账时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="{
                disabledDate,
                selectableRange: time,
              }"
              size="small"
            >
            </el-date-picker>
          </p>
        </div>
      </div>
      <div class="other-pay">
        <span>其他收款方式</span>
        <span class="orange">剩余应收：￥{{ remainingMoney }}</span>
      </div>

      <div class="other-chioce-pay">
        <div class="flex">
          <el-form :model="form" label-width="150px" size="small">
            <el-row
              v-for="(item, index) in billCheckDetails"
              v-bind:key="index"
            >
              <div class="flex" style="margin-bottom: 20px">
                <i
                  class="pointer iconfont grow-icon_delete3"
                  style="color: #ff9200; font-size: 22px; margin: 5px 15px"
                  @click="deletePayType(index)"
                ></i>
                <el-col :span="8">
                  <el-select
                    v-model="item.payTypeName"
                    @change="selectPayType($event, item)"
                    filterable
                    placeholder="请选择支付方式"
                    size="small"
                  >
                    <el-option
                      v-for="item in payTypeOptions"
                      :key="item.payTypeName"
                      :label="item.payTypeName"
                      :value="item.payTypeName"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10" :offset="1">
                  <el-input
                    size="small"
                    v-model="item.price"
                    placeholder=" 请输入收款金额"
                    :disabled="item.payType === undefined"
                    @input="changePrice(item.price, index)"
                  />
                </el-col>
              </div>
            </el-row>
          </el-form>
        </div>
        <p
          @click="addOtherPay()"
          class="pointer blue addOtherPay"
          v-if="![1, 3].includes(billType)"
        >
          +添加其他收款方式
        </p>
        <div class="orange gatherTogether">
          <!-- 2022-12-05产品需求去掉 -->
          <!-- <div class="flex">
            <p class="payType">收款合计：</p>
            <p>￥{{ gatheringTogether | toThousands }}</p>
          </div> -->

          <div
            v-for="(item, index) in billCheckDetails"
            :key="index"
            class="flex"
            style="margin-top: 10px"
          >
            <p class="payType">
              <!-- {{ item.payType | payTypeLabel(payTypeList) }}&nbsp;&nbsp; -->
              {{ item.payTypeName }}&nbsp;&nbsp;
            </p>
            <p v-if="item.price != null">￥{{ item.price | toThousands }}</p>
          </div>
        </div>
      </div>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="输入本次结账收款备注"
        v-model="billCheckRemark"
      >
      </el-input>
    </div>
    <template slot="footer">
      <el-button type="primary" @click="receivingCheckout">收款结账</el-button>
      <el-button @click="handleClose">取消</el-button>
    </template>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      top="15%"
      :before-close="handleCloseDialog"
      :modal="false"
    >
      <template slot="title">
        <span style="font-size: 16px">提示</span>
      </template>
      <span
        >当前车单消费客户“{{
          this.payerInfo.consumerName
        }}”无有效协议合同，不支持挂账方式，请使用现结或与总部确认协议</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { getCarBillListPayType, getCarBillCheck } from '@/api/carBill/index';
import Big from 'big.js';
export default {
  name: 'carCheckoutDrawer',
  props: {
    stayInvoicingDialog: { type: Boolean, default: false },
    billId: { type: [Number, String], default: '' },
    companyId: { type: [Number, String], default: '' },
    carInfo: { type: Object, default: () => ({}) },
    customerName: { type: [String], default: '' },
    insuranceCompanyName: { type: [String], default: '' }, //保险公司名称
    undertakeInsuranceCompanyName: { type: [String], default: '' }, //三包厂家名称
    //单据金额
    amountOfDocuments: { type: [Number, String], default: 0 },
    //其他信息
    otherData: { type: Object, default: () => ({}) },
    billType: { type: [Number, String], default: '' },
    //他人自费客户信息
    payerInfo: { type: Object, default: () => ({}) },
    //接待开单时间
    orderTime: { type: [Number, String], default: '' },
    //是否可以挂账结账
    beHang: { type: Boolean, default: false },
  },
  data() {
    return {
      billCheckRemark: '',
      payTypeList: [],
      disabledType: true,
      billCheckDetails: [],
      form: {},
      checkDate: '',
      authDisabled: false,
      dialogVisible: false,
    };
  },
  filters: {
    payTypeLabel(id, list) {
      if (id !== undefined) {
        return list.find((item) => item.payType === id)?.payTypeName;
      } else {
        return '';
      }
    },
  },
  computed: {
    //判断如果已经存在的付款方式就不能再选择了
    payTypeOptions() {
      let list = this.payTypeList.map((item) => {
        if (
          this.billCheckDetails.find(
            (el) => el.payTypeName === item.payTypeName
          )
        ) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        return item;
      });
      return list;
    },
    //计算剩余应收
    remainingMoney: function () {
      return Big(this.amountOfDocuments).minus(
        this.billCheckDetails.reduce((prev, item) => {
          return prev + (+item.price || 0);
        }, 0)
      );
    },
    //收款合计
    gatheringTogether: function () {
      return this.billCheckDetails.reduce((prev, item) => {
        return prev + (+item.price || 0);
      }, 0);
    },
    //结账结束时间 设置禁用的时间段
    time() {
      if (this.orderTime.split(' ')[0] == new Date().Format('yyyy-MM-dd')) {
        return (
          this.orderTime.split(' ')[1] + '-' + new Date().Format('hh:mm:ss')
        );
      } else if (this.checkDate.split(' ')[0] == this.orderTime.split(' ')[0]) {
        return this.orderTime.split(' ')[1] + '-' + ' 23:59:59';
      } else if (
        this.checkDate.split(' ')[0] == new Date().Format('yyyy-MM-dd')
      ) {
        return '00:00:00' + '-' + new Date().Format('hh:mm:ss');
      } else {
        return '00:00:00-23:59:59';
      }
    },
  },
  created() {
    this.checkDate = new Date().Format('yyyy-MM-dd hh:mm:ss');
    this.getPayType();
  },
  methods: {
    /**
     * 权限禁用结账日期
     */
    handleMethod() {
      this.authDisabled = true;
    },
    /**
     * 账单时间禁用日期
     */
    disabledDate(date) {
      return (
        new Date(date).getTime() > new Date().getTime() ||
        new Date(this.orderTime).setHours(0, 0, 0, 0) > new Date(date).getTime()
      );
    },
    //关闭弹窗
    handleClose() {
      this.$emit('update:stayInvoicingDialog', false);
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    //获取支付方式列表
    getPayType() {
      getCarBillListPayType().then((res) => {
        //判断如果是包干业务或者索赔其他支付方式不能选择，只能选择挂账方式
        if (this.billType == 1 || this.billType == 3) {
          this.payTypeList = res.filter((item) => item.payType == 3);
          this.billCheckDetails.push({
            payType: 3,
            price: this.amountOfDocuments,
            payTypeName: '挂账',
          });
          return;
        }
        //没有绑定客户的时候并且是自费业务的,只有挂账不能选择
        //2022-12-05 产品需求提出未绑定消费客户也可以选择挂账
        // if (
        //   !this.customerName &&
        //   this.billType == 0 &&
        //   this.otherData.isOthersSelfPayState == false
        // ) {
        //   this.payTypeList = res.filter((item) => item.payType != 3);
        //   //判断如果是未绑定客户，默认未第一个支付方式，其他还是默认挂账
        //   this.billCheckDetails.push({
        //     payType: this.payTypeList[0].payType,
        //     price: this.amountOfDocuments,
        //   });
        //   return;
        // } else {
        //   this.billCheckDetails.push({
        //     payType: 3,
        //     price: this.amountOfDocuments,
        //   });
        // }
        this.billCheckDetails.push({
          payType: 3,
          price: this.amountOfDocuments,
          payTypeName: '挂账',
        });
        this.payTypeList = res;
      });
    },
    //收款结账请求接口
    receivingCheckout() {
      var hasNoPay = this.billCheckDetails.some((item) => {
        return ['', undefined].includes(item.payType);
      });
      if (hasNoPay) {
        return this.$message.warning('请选择支付方式');
      }
      //判断是否可以进行挂账结账
      var hasHang = this.billCheckDetails.some((item) => {
        return [3].includes(item.payType);
      });
      if (hasHang && !this.beHang) {
        //添加判断对于未绑定客户的不弹出无协议的提示弹窗
        if (this.payerInfo.consumerName) {
          return (this.dialogVisible = true);
        }
      }
      var data = {
        billId: this.billId,
        companyId: this.companyId,
        billCheckRemark: this.billCheckRemark,
        billCheckDetails: this.billCheckDetails,
        checkDate: this.checkDate, //账单时间字段
      };
      if (this.gatheringTogether != this.amountOfDocuments) {
        this.$message({
          type: 'warning',
          message: '收款结账金额没有等于应收总额!',
        });
      } else {
        getCarBillCheck(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!',
            });
            this.$emit('update:stayInvoicingDialog', false);
            this.$emit('updateData');
          } else {
            this.$message({
              type: 'warning',
              message: '操作失败!',
            });
          }
        });
      }
    },
    //添加其他收款方式
    addOtherPay() {
      this.billCheckDetails.push({
        //实现添加时自动把剩下的金额填充上去
        price: this.remainingMoney.toString(),
      });
    },
    //下面是支付方式必须要存在一个
    deletePayType(index) {
      if (index > 0) {
        this.billCheckDetails.splice(index, 1);
      } else {
        this.billCheckDetails[0].payType = '';
        this.billCheckDetails[0].payTypeName = '';
        this.billCheckDetails[0].price = '';
      }
    },
    /**
     * 修改金额
     */
    changePrice(price, index) {
      if (this.remainingMoney < 0) {
        this.billCheckDetails[index].price = +price + +this.remainingMoney;
      }
    },
    selectPayType(value, item) {
      let curr = this.payTypeOptions.find((el) => el.payTypeName === value);
      item.payType = curr.payType;
      if (curr.customPayTypeId) item.customPayTypeId = curr.customPayTypeId;
    },
  },
};
</script>

<style lang="scss" scoped>
.contrent {
  width: 95%;
  margin-left: 2.5%;
  .content-header {
    display: flex;
    justify-content: space-between;
    height: 111px;
    background: rgba(85, 183, 142, 0.02);
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .car-infor {
      margin: 30px 20px;
      color: #333333;
      p:first-child {
        font-size: 20px;
        margin-bottom: 14px;
        font-weight: 600;
        & + p {
          font-size: 14px;
        }
      }
      span {
        margin-left: 10px;
      }
    }
    .bill {
      margin: 30px 20px;
      font-size: 12px;
      color: #999999;
      p:first-child {
        margin-bottom: 14px;
        span {
          color: $primary;
          font-size: 20px;
          & + span {
            font-size: 30px;
          }
        }
      }
    }
  }
}
.other-pay {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 10px;
}
.other-chioce-pay {
  position: relative;
  width: 96%;
  height: 250px;
  padding: 15px 20px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  overflow-y: scroll;
}
.addOtherPay {
  margin-left: 50px;
  width: 129px;
}
.gatherTogether {
  position: absolute;
  right: 30px;
  width: 26%;
  text-align: end;
  float: right;
  .payType {
    width: 50%;
  }
}
//设置账单时间框长度
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 185px;
}
</style>
