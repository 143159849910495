<template>
  <el-dialog
    top="5vh"
    width="950px"
    title="选择车型"
    :visible="visibility"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="container">
      <div class="filter-item letter-list">
        <ul>
          <li
            v-for="(letter, i) in letterList"
            :key="i"
            :class="{ active: letter === currentLetter }"
            @click="selectLetter(letter)"
          >
            {{ letter }}
          </li>
        </ul>
      </div>
      <div class="filter-item brand-list">
        <div class="title">品牌</div>
        <ul ref="brandList" @scroll="onItemListScroll">
          <li
            ref="brandItem"
            v-for="(brand, i) in brandList"
            :key="i"
            :class="{ active: brand.id === currentBrandId }"
            :data-letter="brand.prefixLetter"
            @click="brandChange(brand)"
          >
            <img :src="brand.imagePath" :alt="brand.name" />
            <span>{{ brand.name }}</span>
          </li>
        </ul>
      </div>
      <div class="filter-item series-list">
        <div class="title">车系</div>
        <ul>
          <li
            v-for="(series, i) in seriesList"
            :key="i"
            :class="{ active: series.id === currentSeriesId }"
            @click="seriesChange(series)"
          >
            {{ series.name }}
          </li>
        </ul>
      </div>
      <div class="filter-item model-list">
        <div class="title">型号</div>
        <ul>
          <li
            v-for="(model, i) in modelList"
            :key="i"
            :class="{ active: model.modelNumberId === currentModelId }"
            @click="modelChange(model)"
          >
            {{ model.name }}
          </li>
        </ul>
      </div>
      <div class="filter-item year-list">
        <div class="title">年份</div>
        <ul>
          <li
            v-for="(year, i) in yearList"
            :key="i"
            :class="{ active: year.modelYearId === currentYearId }"
            @click="yearChange(year)"
          >
            {{ year.name }}
          </li>
        </ul>
      </div>
    </div>

    <template slot="footer">
      <div class="flex-x-between flex-y-center">
        <div class="tag-box">
          <div>已选：</div>
          <div class="tag-list">
            <el-tag
              v-for="tag in selectedList"
              :key="tag.seriesId"
              style="margin-left: 8px"
              type="info"
              size="small"
              :closable="multiple"
              disable-transitions
              @close="handleTagClose(tag)"
            >
              <!-- {{ tag.brandName }}+ -->
              <!-- {{ tag.modelNumberName ? '+' : '' }} -->
              {{ tag.seriesName }}{{ tag.modelNumberName
              }}{{ tag.modelYearName ? ' ' : '' }}{{ tag.modelYearName
              }}{{ tag.modelYearName ? '款' : '' }}
            </el-tag>
          </div>
        </div>
        <el-button size="small" type="primary" @click="confirmSelect"
          >确 定</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { carModelsList, getModelAndYearBySeries } from '@/api/customer/car';
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    multiple: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedList: [],

      letterList: [],
      currentLetter: 'A',

      brandList: [],
      currentBrandId: '',

      seriesList: [],
      currentSeriesId: '',

      modelList: [],
      currentModelId: '',

      yearList: [],
      currentYearId: '',

      scrollLock: false,
    };
  },
  computed: {},
  created() {
    if (this.value?.length) {
      this.selectedList = this.value;
    }
    this.getTreeData();
  },
  methods: {
    brandChange(brand) {
      this.currentBrandId = brand.id;

      this.currentSeriesId = '';
      this.currentModelId = '';
      this.currentYearId = '';
      this.modelList = [];
      this.yearList = [];

      var series = [];
      brand.children?.forEach((el) => {
        el.children?.forEach((val) => {
          series.push({ ...val, parentId: el.id, parentName: el.name });
        });
      });
      this.seriesList = series;
    },
    seriesChange(series) {
      this.currentSeriesId = series.id;
      this.getModelList(series.id);

      let item = {
        logoId: this.currentBrandId,
        logoName: this.brandList.find((el) => el.id === this.currentBrandId)
          ?.name,

        brandId: series.parentId,
        brandName: series.parentName,

        seriesId: series.id,
        seriesName: series.name,

        modelNumberId: '',
        modelNumberName: '',
        modelYearId: '',
        modelYearName: '',
      };

      // 单选多选判断
      if (this.multiple) {
        // 车系是否已存在
        const index = this.selectedList.findIndex(
          (el) => el.seriesId === this.currentSeriesId
        );
        if (index < 0) {
          // 添加
          this.selectedList.push(item);
        } else {
          // 清除下级
          this.$set(this.selectedList[index], 'modelNumberId', '');
          this.$set(this.selectedList[index], 'modelNumberName', '');
          this.$set(this.selectedList[index], 'modelYearId', '');
          this.$set(this.selectedList[index], 'modelYearName', '');
        }
      } else {
        this.selectedList = [item];
      }
    },
    getModelList(id) {
      this.currentModelId = '';
      this.currentYearId = '';
      this.modelList = [];
      this.yearList = [];
      getModelAndYearBySeries({ id }).then((res) => {
        this.modelList = res;
      });
    },
    modelChange(model) {
      this.currentModelId = model.modelNumberId;
      this.yearList = model.years || [];

      const index = this.selectedList.findIndex(
        (el) => el.seriesId === this.currentSeriesId
      );
      this.$set(this.selectedList[index], 'modelNumberId', model.modelNumberId);
      this.$set(this.selectedList[index], 'modelNumberName', model.name);
      this.$set(this.selectedList[index], 'modelYearId', '');
      this.$set(this.selectedList[index], 'modelYearName', '');
    },
    yearChange(year) {
      this.currentYearId = year.modelYearId;
      const index = this.selectedList.findIndex(
        (el) => el.seriesId === this.currentSeriesId
      );
      this.$set(this.selectedList[index], 'modelYearId', year.modelYearId);
      this.$set(this.selectedList[index], 'modelYearName', year.name);
    },

    selectLetter(letter) {
      this.scrollLock = true;
      setTimeout(() => {
        this.scrollLock = false;
      }, 1e3);
      this.currentLetter = letter;
      const index = this.brandList.findIndex(
        (item) => item.prefixLetter === letter
      );
      var targetItem = this.$refs.brandItem[index];

      if (targetItem) {
        // 滚动到目标项目
        targetItem.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onItemListScroll() {
      if (this.scrollLock) {
        return;
      }
      // 获取右侧列表的滚动容器
      const brandList = this.$refs.brandList;

      // 查找当前可见的项目
      const visibleItems = this.$refs.brandItem.filter((item) => {
        const rect = item.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= brandList.clientHeight;
      });

      // 获取第一个可见项目首字母
      if (visibleItems.length > 0) {
        this.currentLetter = visibleItems[0].dataset.letter;
      }
    },
    handleTagClose(tag) {
      this.selectedList.splice(
        this.selectedList.findIndex((el) => el.seriesId === tag.seriesId),
        1
      );
    },
    /**
     * 车型数据请求
     */
    getTreeData() {
      carModelsList().then((res) => {
        this.letterList = res.data.letters.split(',');
        this.brandList = res.data.letterList || [];

        this.currentLetter = this.letterList[0] || '';
        if (this.brandList.length) {
          this.brandChange(this.brandList[0]);
        }
      });
    },
    /**
     * 选择事件
     */
    confirmSelect() {
      // 调用自定义事件，传入参数
      this.$emit('select', this.selectedList);
      this.$emit('update:visibility', false);
    },
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  padding: 16px 0;
  .filter-item {
    + .filter-item {
      margin-left: 16px;
    }
    ul {
      height: 65vh;
      overflow: auto;
      padding: 0 8px;
    }
    .title {
      width: 200px;
      height: 52px;
      background: #f5f7fa;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 16px;
      box-sizing: border-box;

      font-size: 14px;
      font-weight: 500;
      color: #242526;
      line-height: 20px;
      user-select: none;
    }
  }

  .letter-list {
    padding-top: 60px;
    ul {
      padding: 0 8px;
      li {
        + li {
          margin-top: 16px;
        }
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #f0f2f5;
        cursor: pointer;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #3c3d40;
        user-select: none;
        &.active {
          color: #33ab79;
          background: rgba(51, 171, 121, 0.1);
        }
      }
    }
  }

  .brand-list,
  .series-list,
  .model-list,
  .year-list {
    ul {
      width: 200px;
      box-sizing: border-box;

      li {
        + li {
          margin-top: 8px;
        }
        &.active,
        &:hover {
          color: #33ab79;
          background: rgba(51, 171, 121, 0.1);
          border-radius: 4px;
          span {
            color: #33ab79;
          }
        }

        // width: 188px;
        // height: 40px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        // background: #656673;
        line-height: 18px;
        user-select: none;
        img {
          width: 24px;
          height: 24px;
        }
        span {
          margin-left: 12px;
          font-size: 14px;
          font-weight: 400;
          color: #242526;
          line-height: 20px;
        }
      }
    }
  }
}
.tag-box {
  display: flex;
  div:first-child {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  .tag-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .el-tag {
      margin-bottom: 5px;
    }
  }
}
</style>
