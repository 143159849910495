<template>
  <base-container paddingTop>
    <div class="filter flex-x-between">
      <div class="date">
        <span>统计周期：</span>
        <el-date-picker
          v-model="date"
          type="month"
          size="small"
          value-format="yyyy/MM"
          placeholder="选择月"
        >
        </el-date-picker>
      </div>
      <el-button type="primary" size="small" @click="doSearch">查询</el-button>
    </div>
    <div class="flex-x-between">
      <p class="tip-word color666">
        <i class="el-icon-warning orange"></i>
        数据更新于{{ new Date().Format('yyyy/MM/dd hh:mm:ss') }}
      </p>
      <div class="flex">
        <el-button type="text" @click="setGrossMarginStandardDialog = true"
          >毛利率设置
          <i class="el-icon-s-tools"></i>
        </el-button>
        <el-button v-auth="541" type="text" @click="setStoreScopeDialog = true"
          >门店管理
          <i class="el-icon-s-tools"></i>
        </el-button>
        <el-button
          v-auth="537"
          type="text"
          @click="viewTurnoverTargetListDialog = true"
          >查看目标
          <i class="el-icon-view"></i>
        </el-button>
        <!-- 点击打开新窗口  $router.push('/system-data') -->
        <el-button type="text" @click="openNewWindow"
          >指挥中心
          <i class="el-icon-monitor"></i>
        </el-button>
      </div>
    </div>
    <set-store-scope-dialog
      v-if="setStoreScopeDialog"
      :visibility.sync="setStoreScopeDialog"
    />
    <view-turnover-target-list-dialog
      v-if="viewTurnoverTargetListDialog"
      :visibility.sync="viewTurnoverTargetListDialog"
    />
    <set-gross-margin-standard-dialog
      v-if="setGrossMarginStandardDialog"
      :visibility.sync="setGrossMarginStandardDialog"
    />
  </base-container>
</template>

<script>
import setStoreScopeDialog from '@/components/businessComponent/setStoreScopeDialog.vue';
import viewTurnoverTargetListDialog from '@/components/businessComponent/viewTurnoverTargetListDialog.vue';
import setGrossMarginStandardDialog from '@/components/businessComponent/setGrossMarginStandardDialog.vue';

export default {
  name: 'turnoverTargetBoard',
  components: {
    setStoreScopeDialog,
    viewTurnoverTargetListDialog,
    setGrossMarginStandardDialog,
  },
  data() {
    return {
      date: new Date().Format('yyyy/MM'),

      // 门店管理弹窗
      setStoreScopeDialog: false,

      // 查看所有门店目标弹窗
      viewTurnoverTargetListDialog: false,

      // 设置毛利率标准值弹窗
      setGrossMarginStandardDialog: false,
    };
  },
  methods: {
    doSearch() {},
    openNewWindow() {
      let data = JSON.stringify(this.$store.state);
      localStorage.setItem('vuexData', data);
      let newWindow = window.open('./#/system-data');
      // newWindow.addEventListener('load', () => {
      //   newWindow.postMessage(
      //     {
      //       type: 'vuexData',
      //       data,
      //     },
      //     '*'
      //   );
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter {
  margin-bottom: 15px;
  .date > span {
    font-size: 14px;
  }
}
.tip-word {
  font-size: 14px;
}
</style>
