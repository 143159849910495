var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { attrs: { paddingTop: "" } },
    [
      _c("div", { staticClass: "client_name" }, [
        _c("i", {
          staticClass: "iconfont icon_grade",
          class: _vm.levelIcons[_vm.detailData.clientLevel],
          staticStyle: { margin: "0px 5px 0px 16px", color: "#3fa1e6" },
          attrs: { title: _vm.levelIconsWord[_vm.detailData.clientLevel] },
        }),
        _c("span", { staticStyle: { color: "#303133" } }, [
          _vm._v(_vm._s(_vm.detailData.clientName)),
        ]),
        _c(
          "span",
          {
            directives: [
              {
                name: "auth",
                rawName: "v-auth",
                value: 546,
                expression: "546",
              },
            ],
            staticClass: "primary pointer",
            staticStyle: {
              "padding-bottom": "2px",
              "border-bottom": "1px solid #33ab79",
              "margin-left": "16px",
            },
            on: {
              click: function ($event) {
                _vm.clientCommandDrawerVisibility = true
              },
            },
          },
          [_vm._v("客户指令")]
        ),
        _c("div", { staticClass: "client_remark" }, [
          _vm.detailData.remark
            ? _c("p", { staticClass: "pre-wrap" }, [
                _vm._v(" " + _vm._s(_vm.detailData.remark) + " "),
              ])
            : _c("p", [_vm._v("暂无备注")]),
        ]),
      ]),
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 576,
                  expression: "576",
                },
              ],
              attrs: { label: "基本信息", name: "first" },
            },
            [
              _c("div", { staticClass: "description_word" }, [
                _vm._v("基本信息"),
              ]),
              _vm.detailData.clientGenderAndType == "单位"
                ? _c(
                    "el-descriptions",
                    {
                      attrs: {
                        column: 3,
                        border: "",
                        labelClassName: "table-label",
                        contentStyle: { verticalAlign: "middle" },
                        labelStyle: { "text-align": "left !important" },
                      },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "公司全称" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.companyFullName) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "法人信息" } },
                        [_vm._v(" " + _vm._s(_vm.detailData.legalPerson) + " ")]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "成立时间" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.establishedTime) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "股东机构" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.detailData.shareholderStructure) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "公司性质" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.companyNature) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发车模式" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.departureMode) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "车辆来源" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.vehicleSource) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "车队管理系统" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.detailData.fleetManageSystem) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "社会信用代码" } },
                        [
                          [
                            _c(
                              "span",
                              { staticStyle: { "word-break": "break-word" } },
                              [_vm._v(" " + _vm._s(_vm.detailData.idNum) + " ")]
                            ),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "专属客户经理" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailData.exclusiveCustomerManagerName
                              ) +
                              " " +
                              _vm._s(
                                _vm.detailData.exclusiveCustomerManagerPhone
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "办公地址", span: 3 } },
                        [_vm._v(" " + _vm._s(_vm.detailData.address) + " ")]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "客户图片", span: 3 } },
                        [
                          _vm.detailData.picClientLists.length != 0
                            ? _c(
                                "div",
                                { staticClass: "flex" },
                                _vm._l(
                                  _vm.detailData.picClientLists,
                                  function (item, index) {
                                    return _c("upload-file", {
                                      key: index,
                                      attrs: {
                                        disabled: "",
                                        title: item.picTypeName,
                                        value: item.picUrlName,
                                        imagesForPreview:
                                          _vm.detailData.picClientLists.map(
                                            (img) => img.picUrlName
                                          ),
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _c("div", { staticClass: "color999" }, [
                                _vm._v("无附件图片"),
                              ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-descriptions",
                    {
                      attrs: {
                        column: 3,
                        border: "",
                        labelClassName: "table-label",
                        contentStyle: { verticalAlign: "middle" },
                        labelStyle: { "text-align": "left !important" },
                      },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "身份证号" } },
                        [
                          [
                            _c(
                              "span",
                              { staticStyle: { "word-break": "break-word" } },
                              [_vm._v(" " + _vm._s(_vm.detailData.idNum) + " ")]
                            ),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "其他证件" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailData.otherCertificateMsg == "null"
                                  ? ""
                                  : _vm.detailData.otherCertificateMsg
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "客户生日" } },
                        [_vm._v(" " + _vm._s(_vm.detailData.birthday) + " ")]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "联系地址" } },
                        [_vm._v(" " + _vm._s(_vm.detailData.address) + " ")]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "所在单位" } },
                        [_vm._v(" " + _vm._s(_vm.detailData.clientUnit) + " ")]
                      ),
                      _c("el-descriptions-item"),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "客户图片", span: 3 } },
                        [
                          _vm.detailData.picClientLists.length != 0
                            ? _c(
                                "div",
                                { staticClass: "flex" },
                                _vm._l(
                                  _vm.detailData.picClientLists,
                                  function (item, index) {
                                    return _c("upload-file", {
                                      key: index,
                                      attrs: {
                                        disabled: "",
                                        title: item.picTypeName,
                                        value: item.picUrlName,
                                        imagesForPreview:
                                          _vm.detailData.picClientLists.map(
                                            (img) => img.picUrlName
                                          ),
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _c("div", { staticClass: "color999" }, [
                                _vm._v("无附件图片"),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
              this.otherContactsLength > 0
                ? _c("div", { staticClass: "description_word" }, [
                    _vm._v(" 其他联系人 "),
                  ])
                : _vm._e(),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    column: 3,
                    border: "",
                    labelClassName: "table-label",
                    contentStyle: { verticalAlign: "middle", width: "22.5%" },
                  },
                },
                [
                  _vm._l(_vm.detailData.otherContacts, function (item, index) {
                    return _c(
                      "el-descriptions-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm._f("dict")(item.position, "position"),
                        },
                      },
                      [
                        _c("p", { staticStyle: { color: "#303133" } }, [
                          _vm._v(
                            " " +
                              _vm._s(item.contactsName) +
                              _vm._s(item.contactsPhone) +
                              " "
                          ),
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#999",
                              "word-break": "break-word",
                            },
                          },
                          [_vm._v(_vm._s(item.remark))]
                        ),
                      ]
                    )
                  }),
                  _vm.redundant == 1
                    ? _c("el-descriptions-item", { attrs: { label: "" } })
                    : _vm._e(),
                  _vm.redundant == 2
                    ? _c("el-descriptions-item", { attrs: { label: "" } })
                    : _vm._e(),
                  _vm.redundant == 3
                    ? _c("el-descriptions-item", { attrs: { label: "" } })
                    : _vm._e(),
                  _vm.redundant == 3
                    ? _c("el-descriptions-item", { attrs: { label: "" } })
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "description_word" }, [
                _vm._v(" 客户协议 "),
                ![0, undefined].includes(_vm.detailData.hasDueCount)
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: 474,
                            expression: "474",
                          },
                        ],
                        staticClass: "red text_operation",
                        staticStyle: { "font-size": "12px" },
                        on: { click: _vm.goAgreementList },
                      },
                      [
                        _vm._v(
                          "过期协议：" + _vm._s(_vm.detailData.hasDueCount)
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.agreementTable, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "协议编号", "mix-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "pointer text_operation blue",
                                on: {
                                  click: function ($event) {
                                    return _vm.goAgreementDetail(
                                      scope.row.clientAgreementId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.contractNumber))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "包干协议", "mix-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " + _vm._s(row.beContract ? "是" : "否") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "startDate",
                      label: "生效时间",
                      "mix-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "endDate",
                      label: "有效期至",
                      "mix-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "协议状态", "mix-width": "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.agreementState == "未生效"
                                    ? "待生效"
                                    : row.agreementState
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 577,
                  expression: "577",
                },
              ],
              attrs: { label: "车辆情况", name: "second" },
            },
            [
              _c("div", { staticClass: "description_word" }, [
                _vm._v("车辆情况"),
              ]),
              _c("div", { staticClass: "flex flex-x-between" }, [
                _c("div", { staticClass: "chart-style" }, [
                  _c("div", { attrs: { id: "vehicle-brand-chart" } }),
                ]),
                _c(
                  "div",
                  { staticStyle: { width: "80%", "overflow-x": "auto" } },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.carScale, border: "" },
                      },
                      [
                        _c(
                          "el-table-column",
                          {
                            attrs: { prop: "vehicleScale", "mix-width": "100" },
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 车辆规模 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content: "该客户拥有的车辆数",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "cooperationNumber",
                              "mix-width": "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.cooperationNumber) +
                                        " （" +
                                        _vm._s(row.cooperationRate) +
                                        "） "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 合作台数 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content:
                                          "该客户目前在系统绑定的车辆数 占比计算为【合作台数/车辆规模】",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "actualVehicle",
                              "mix-width": "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.actualVehicle) +
                                        " （" +
                                        _vm._s(row.operatingRate) +
                                        "） "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 实际运营 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content:
                                          "该客户实际运营的车辆数 占比计算为【实际运营/合作台数】",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: { prop: "actualShop", "mix-width": "100" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.actualShop) +
                                        " （" +
                                        _vm._s(row.admissionRate) +
                                        "） "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" 实际进店 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content:
                                          "该客户名下车辆从今日起前三个月有存在消费记录的车辆统计 占比计算为（实际进店/合作台数）",
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont grow-icon_doubt",
                                        staticStyle: { color: "#9a9a9a" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", "margin-top": "44px" },
                        attrs: {
                          data: _vm.clientCarMileageSectionVo,
                          border: "",
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          { attrs: { label: "里程区间", "mix-width": "120" } },
                          [[_c("span", [_vm._v("台数")])]],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "0-10万",
                            "mix-width": "100",
                            prop: "oneSectionCarNum",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "10-20万",
                            "mix-width": "100",
                            prop: "twoSectionCarNum",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "20-30万",
                            "mix-width": "100",
                            prop: "threeSectionCarNum",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "30-40万",
                            "mix-width": "100",
                            prop: "fourSectionCarNum",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "40-50万",
                            "mix-width": "100",
                            prop: "fiveSectionCarNum",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "50-60万",
                            "mix-width": "100",
                            prop: "sixSectionCarNum",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "60万以上",
                            "mix-width": "130",
                            prop: "sevenSectionCarNum",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "未知",
                            "mix-width": "100",
                            prop: "unknownSectionCarNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", "margin-top": "44px" },
                        attrs: {
                          data: _vm.clientCarOperatingLifeSectionVo,
                          border: "",
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          { attrs: { label: "运营年限", "mix-width": "120" } },
                          [[_c("span", [_vm._v("台数")])]],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            prop: "oneSectionCarNum",
                            label: "1年内",
                            "mix-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "twoSectionCarNum",
                            label: "1-2年内",
                            "mix-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "threeSectionCarNum",
                            label: "2-3年内",
                            "mix-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "fourSectionCarNum",
                            label: "3-4年内",
                            "mix-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "fiveSectionCarNum",
                            label: "4-5年内",
                            "mix-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "sixSectionCarNum",
                            label: "5-6年内",
                            "mix-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "sevenSectionCarNum",
                            label: "6年以上",
                            "mix-width": "130",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "unknownSectionCarNum",
                            label: "未知",
                            "mix-width": "100",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 578,
                  expression: "578",
                },
              ],
              attrs: { label: "业务信息", name: "third" },
            },
            [
              _c("div", { staticClass: "description_word" }, [
                _vm._v("业务信息"),
              ]),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    column: 3,
                    border: "",
                    labelClassName: "table-label",
                    contentStyle: { verticalAlign: "middle" },
                    labelStyle: { "text-align": "left !important" },
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "合作模式" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.cooperationModel) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "合作时间" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.cooperationTime) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "客户来源" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dict")(
                            _vm.clientBusinessMessageDto.customerSource,
                            "customerSource"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "自费维保" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.clientBusinessMessageDto.selfPayingMaintenance
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "事故维修" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.clientBusinessMessageDto.accidentMaintenance
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "退还车/整备" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.clientBusinessMessageDto.returnCar) +
                          " "
                      ),
                    ]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "车辆保险" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.carInsurance) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "强收车" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.forcedRetracting) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "停车场" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.parkingLot) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "车务代办" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.vehicleAgent) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "保费分期" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.premiumInstalment) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "车辆代发" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.carDispatch) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "车管报表" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.clientBusinessMessageDto.carReport) + " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "金融服务" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.financialServices) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "新车采购" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.newCarPurchase) +
                        " "
                    ),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "二手车销售" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.clientBusinessMessageDto.secondHandCarSales
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "电池回收" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientBusinessMessageDto.batteryRecycling) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item"),
                ],
                1
              ),
              _c("div", { staticClass: "description_word" }, [
                _vm._v("事故信息"),
              ]),
              _c("div", { staticClass: "descriptions-title" }, [
                _c("p", [_vm._v("出险推送方式")]),
              ]),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-bottom": "16px" },
                  attrs: {
                    column: 3,
                    border: "",
                    labelClassName: "table-label",
                    contentStyle: { verticalAlign: "middle" },
                    labelStyle: { "text-align": "left !important" },
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { effect: "light", placement: "bottom" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "rows-tip",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(" 事故信息保险公司推送接收人号码 ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { color: "#9a9a9a" },
                              }),
                            ]
                          ),
                          _vm._v(" 信息接收人 "),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.clientBusinessMessageDto.messageReceivingNumber
                          ) +
                          " "
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { effect: "light", placement: "bottom" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "rows-tip",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(" 事故消息保险公司推送格悦的方式 ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { color: "#9a9a9a" },
                              }),
                            ]
                          ),
                          _vm._v(" 保司推送方式 "),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.clientBusinessMessageDto.insuranceCompanyPush
                          ) +
                          " "
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { effect: "light", placement: "bottom" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "rows-tip",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(" 事故信息车队推送格悦的方式 ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { color: "#9a9a9a" },
                              }),
                            ]
                          ),
                          _vm._v(" 车队推送方式 "),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.clientBusinessMessageDto.fleetPush) +
                          " "
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "descriptions-title" }, [
                _c("p", [_vm._v("车队与司机的合同约定")]),
              ]),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    column: 2,
                    border: "",
                    labelClassName: "table-label",
                    contentStyle: { verticalAlign: "middle" },
                    labelStyle: { "text-align": "left !important" },
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        labelStyle: {
                          width: "11.5%",
                          "text-align": "left !important",
                        },
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { effect: "light", placement: "bottom" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "rows-tip",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(" 与司机合同约定有责事故联系方式 ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { color: "#9a9a9a" },
                              }),
                            ]
                          ),
                          _vm._v(" 有责事故约定 "),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.clientBusinessMessageDto
                              .responsibleAccidentAgreement
                          ) +
                          " "
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        labelStyle: {
                          width: "11.5%",
                          "text-align": "left !important",
                        },
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { effect: "light", placement: "bottom" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "rows-tip",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [_vm._v(" 与司机合同约定无责事故联系方式 ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont grow-icon_doubt",
                                staticStyle: { color: "#9a9a9a" },
                              }),
                            ]
                          ),
                          _vm._v(" 无责事故约定 "),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.clientBusinessMessageDto
                              .noResponsibleAccidentAgreement
                          ) +
                          " "
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: 337,
                  expression: "337",
                },
              ],
              attrs: { label: "消费记录", name: "four" },
            },
            [
              _c("customer-record", {
                attrs: { clientId: this.$route.query.id },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex-x-end foot-btn" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 62,
                    expression: "62",
                  },
                ],
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.showAddCusterDialog = true
                  },
                },
              },
              [_vm._v("编辑客户")]
            ),
            _vm.agreementTable.length == 0
              ? _c(
                  "el-dropdown",
                  {
                    directives: [
                      {
                        name: "auth",
                        rawName: "v-auth",
                        value: 335,
                        expression: "335",
                      },
                    ],
                    attrs: {
                      size: "small",
                      trigger: "click",
                      placement: "top",
                    },
                  },
                  [
                    _c("el-button", [
                      _vm._v(" 更多操作"),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticStyle: { width: "110px" },
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "btn-dropdown",
                            attrs: { icon: "el-icon-delete" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.del.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.showAddCusterDialog
        ? _c("add-customer-dialog", {
            attrs: {
              showAddCusterDialog: _vm.showAddCusterDialog,
              id: _vm.$route.query.id,
            },
            on: {
              "update:showAddCusterDialog": function ($event) {
                _vm.showAddCusterDialog = $event
              },
              "update:show-add-custer-dialog": function ($event) {
                _vm.showAddCusterDialog = $event
              },
              update: _vm.addUpdate,
            },
          })
        : _vm._e(),
      _vm.clientCommandDrawerVisibility
        ? _c("client-command-drawer", {
            attrs: {
              visibility: _vm.clientCommandDrawerVisibility,
              clientId: Number(_vm.$route.query.id),
              defaultActiveName: _vm.selfPaying,
            },
            on: {
              "update:visibility": function ($event) {
                _vm.clientCommandDrawerVisibility = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }