import http from '@/service/http';

// 获取数据
export function billCarBillStatistic(data) {
  return http.post('/admin/car/bill/pageBillStatistic', data, {
    noShowWaiting: true,
  });
}

// 获取报价数据
export function carLossDeterminationBill(data) {
  return http.post('/admin/carLossDeterminationBill/statistic', data, {
    noShowWaiting: true,
  });
}
// 获取临时协议数据
export function agreementClient(data) {
  return http.post('/admin/agreementClient/statistic', data, {
    noShowWaiting: true,
  });
}
// 获取今日在场
export function carStatistics(data) {
  return http.post('/admin/carStranded/carStatistics', data, {
    noShowWaiting: true,
  });
}

//获取待领料、待采购、待入库数据
export function carBillOtherCount(data) {
  return http.post('/admin/car/bill/otherCount', data, {
    noShowWaiting: true,
  });
}

//获取施工流程中的数据
export function dispatchPageStatistics(data) {
  return http.post('/admin/dispatch/pageStatistics', data, {
    noShowWaiting: true,
  });
}
//新增需求定损单接口
export function pageStatistics(data) {
  return http.post('/admin/carLossDeterminationBill/pageStatistics', data, {
    noShowWaiting: true,
  });
}
