import http from '@/service/http';

//查询问诊车型
export function getInquiryCarType(appearanceName) {
  return http.post('/admin/wzModelMidd/getInquiryCarType', { appearanceName });
}
//查询故障现象分页列表
export function getAppearancePage(data, config = {}) {
  return http.post(
    '/admin/wzModelMidd/getAppearancePageByModelIds',
    data,
    config
  );
}
//查询故障原因分页列表
export function getCausePage(data) {
  return http.post('/admin/wzModelMidd/getCausePageByModelIds', data);
}
//查询维修方案分页列表
export function getWzServicePage(data) {
  return http.post('/admin/wzModelMidd/getWzServicePageByModelIds', data);
}
//新增、问诊模型
export function addWzModel(data) {
  return http.post('/admin/wzModelMidd/addWzModel', data, {
    onlyReturnData: false,
  });
}
//编辑问诊模型
export function editWzModel(data) {
  return http.post('/admin/wzModelMidd/editWzModel', data, {
    onlyReturnData: false,
  });
}
//删除问诊模型
export function deleteWzModel(modelId) {
  return http.post(
    '/admin/wzModelMidd/deleteWzModel',
    { modelId },
    {
      onlyReturnData: false,
    }
  );
}
//查询故障现象列表
export function getAppearanceByPage(data, config = {}) {
  return http.post('/admin/wzModelMidd/getAppearanceByPage', data, config);
}
//删除故障现象
export function deleteAppearance(id) {
  return http.post(
    '/admin/wzModelMidd/deleteAppearance',
    { id },
    {
      onlyReturnData: false,
    }
  );
}
//编辑故障现象
export function editAppearance(data) {
  return http.post('/admin/wzModelMidd/editAppearance', data, {
    onlyReturnData: false,
  });
}
//查询故障原因列表
export function getCauseByPage(data, config = {}) {
  return http.post('/admin/wzModelMidd/getCauseByPage', data, config);
}
//删除故障原因
export function deleteCause(id) {
  return http.post(
    '/admin/wzModelMidd/deleteCause',
    { id },
    {
      onlyReturnData: false,
    }
  );
}
//编辑故障原因
export function editCause(data) {
  return http.post('/admin/wzModelMidd/editCause', data, {
    onlyReturnData: false,
  });
}
//问诊模型页面
//根据模型ID查询故障现象分页列表
export function getAppearancePageByModelIds(data, config = {}) {
  return http.post(
    '/admin/wzModelMidd/getAppearancePageByModelIds',
    data,
    config
  );
}
//根据模型ID查询故障原因分页列表
export function getCausePageByModelIds(data, config = {}) {
  return http.post('/admin/wzModelMidd/getCausePageByModelIds', data, config);
}
//根据模型ID查询维修方案分页列表
export function getWzServicePageByModelId(data, config = {}) {
  return http.post(
    '/admin/wzModelMidd/getWzServicePageByModelIds',
    data,
    config
  );
}
//查询疑难杂症列表分页
export function getWzDifficultByPage(data) {
  return http.post('/admin/wzModelMidd/getWzDifficultByPage', data);
}
//编辑疑难杂症状态为已处理
export function editWzDifficultState(ids) {
  return http.post(
    '/admin/wzModelMidd/editWzDifficultState',
    { ids },
    {
      onlyReturnData: false,
    }
  );
}
//疑难杂症导出
export const exportWzDifficultUrl = '/admin/wzModelMidd/wzDifficult/export';

//查询问诊模型详情
export function getWzModelDetail(modelId) {
  return http.post('/admin/wzModelMidd/getWzModelDetail', { modelId });
}
