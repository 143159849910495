import http from '@/service/http';
import store from '@/store';

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 商品管控列表
 */
export function getGoodsControlList(data, config = {}) {
  return http.post('/admin/good/control/list', data, config);
}

/**
 * 商品管控详情
 */
export function getGoodsControlDetail(data) {
  return http.post('/admin/good/control/detail', data);
}

/**
 * 商品管控库存列表模块列表
 */
export function getInventoryGoodsControlModuleList(data) {
  return http.post('/admin/good/control/getInventoryGoodsControlModule', data);
}

/**
 * 商品管控修改
 */
export function updateInventoryGoodsControl(data) {
  return http.post('/admin/good/control/update', data);
}

// 导出
export const inventoryGoodsControlExportUrl = '/admin/good/control/list/export';
