<template>
  <el-drawer
    title="检车单"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="content">
      <div v-if="list.length > 0">
        <p
          style="
            margin-bottom: 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #606266;
            line-height: 17px;
          "
          v-if="showButton"
        >
          <i
            class="iconfont grow-icon_warn_32 orange"
            style="margin-right: 5px"
          ></i
          >有检车项目暂未添加至报价单，请确认
        </p>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="flex-x-between listbox"
        >
          <div
            class="icon-sty pointer"
            v-if="billState == 0 && item.state != 0"
          >
            <i
              class="icon newicon grow-icon-close"
              @click="deleteEvent(index)"
            ></i>
          </div>
          <div>
            <p>
              {{ item.serviceItemName
              }}<span v-if="item.state == 0" class="primary">(已报价)</span>
              <span v-if="item.state == 1" class="red">(未报价)</span>
            </p>
            <el-tooltip :content="item.faultName">
              <p>故障:{{ item.faultName }}</p>
            </el-tooltip>
            <p>检车人:{{ item.operatorName }} {{ item.lastUpdated }}</p>
          </div>
          <div class="flex-y-center" style="padding: 11px 39px 5px 0px">
            <span
              v-if="!$lodash.get(item, 'faultPicList.length', 0)"
              style="width: 32px; text-align: center; color: #606266"
              >无</span
            >
            <el-popover v-else placement="top" trigger="hover">
              <upload-file-list
                disabled
                :value="item.preImages"
              ></upload-file-list>
              <div class="video-list">
                <video
                  class="thumbnail-video"
                  preload="auto"
                  v-for="(src, index) in item.preVideos"
                  :key="index"
                  :src="src"
                  @click="handleClickVideo($event, item.preVideos, index)"
                  :poster="playImg"
                >
                  <source :src="src" type="video/mp4" />
                  您的设备不支持 video 标签。
                </video>
              </div>
              <template slot="reference">
                <el-badge
                  style="margin-right: 8px"
                  :value="item.faultPicList.length"
                >
                  <img class="thumbnail-img" :src="item.prePoster" />
                </el-badge>
              </template>
            </el-popover>
          </div>
        </div>
        <div class="footer" v-if="billState == 0 && showButton">
          <el-button v-auth="4" size="small" type="primary" @click="editCarBill"
            >确定报价</el-button
          >
        </div>
      </div>
      <div v-else>
        <p class="flex-x-center">
          <img src="@/assets/img/noCarInspection.png" />
        </p>
        <p
          class="flex-x-center"
          style="
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #84878c;
            line-height: 20px;
            letter-spacing: 2px;
          "
        >
          暂无检车项～
        </p>
      </div>
    </div>
    <!-- 删除提示 -->
    <el-dialog
      title="提示"
      :visible.sync="deleteDialog"
      width="25%"
      top="15%"
      :before-close="handleCloseDelete"
      :close-on-click-modal="false"
      :modal="false"
    >
      <div class="prompt-sty">
        <p>确认删除此项</p>
      </div>
      <template slot="footer">
        <div style="text-align: center">
          <el-button @click="deleteDialog = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small" @click="determine"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>

    <videoPlayerDialog
      v-if="showVideoPlayerDialog"
      :visibility.sync="showVideoPlayerDialog"
      :videos="previewVideos"
      :index="previewVideoIndex"
      :modal="false"
    ></videoPlayerDialog>
  </el-drawer>
</template>
<script>
import uploadFileList from '@/components/uploadFileList';
import videoPlayerDialog from '@/components/videoPlayerDialog.vue';
import playImg from '@/assets/img/play.jpeg';
import { getCarDetectionServiceByBillId } from '@/api/carBill';
//方法
import { previewImage, isImage, isVideo } from '@/utils/fileApi';
// 方法
import verifyBeforeEditCarBill from '@/views/carBill/lock';
export default {
  name: 'inspectCarDrawer',
  components: {
    uploadFileList,
    videoPlayerDialog,
  },
  props: {
    visibility: { type: Boolean, default: false },
    billId: {
      type: [Number, String],
      default: '',
    },
    billState: {
      type: [Number, String],
      default: '',
    },
    billType: {
      type: [Number, String],
      default: '',
    },
    typeStatus: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      list: [],
      deleteDialog: false,
      previewVideos: [],
      previewVideoIndex: 0,
      showVideoPlayerDialog: false,
      playImg,
      //是否现在报价按钮
      showButton: false,
      //删除项目角标
      index: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getCarDetectionServiceByBillId(this.billId).then((res) => {
        this.list = res;
        this.list.forEach((item) => {
          if (item.faultPicList) {
            let pre = [...item.faultPicList];
            let preImages = [];
            let preVideos = [];
            pre.forEach((url) => {
              // 图片
              if (isImage(url)) {
                preImages.push(url);
              } else if (isVideo(url)) {
                // 视频
                preVideos.push(url);
              }
            });
            item.preImages = preImages;
            item.preVideos = preVideos;
            // 封面
            if (preImages.length) {
              item.prePoster = preImages[0];
            } else {
              item.prePoster = playImg;
            }
          }
        });
        var state = this.list.every((item) => item.state == 0);
        if (state) {
          this.showButton = false;
        } else {
          this.showButton = true;
        }
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    handleCloseDelete() {
      this.deleteDialog = false;
    },
    deleteEvent(index) {
      this.index = index;
      this.deleteDialog = true;
    },
    determine() {
      this.list.splice(this.index, 1);
      this.deleteDialog = false;
      this.$message.success('操作成功');
    },
    handleClickVideo(e, list, index) {
      e.preventDefault();
      this.previewVideos = list;
      this.previewVideoIndex = index;
      this.showVideoPlayerDialog = true;
    },
    async editCarBill() {
      var arr = [];
      var cloneData = this.$lodash.cloneDeep(this.list);
      cloneData.find((item) => {
        item.state == 1;
      });
      cloneData.forEach((item) => {
        arr.push({
          name: item.serviceItemName,
          price: item.price,
          serviceItemCode: item.serviceItemCode,
          serviceItemId: item.billServiceId,
        });
      });
      let result = await verifyBeforeEditCarBill(this.billId);
      if (!result) return;
      let route = {
        name: 'editCarBill',
        path: '/carBill/editCarBill',
        fullPath: `/carBill/editCarBill?id=${this.billId}`,
        query: {
          type: this.billType,
          id: this.billId,
          typeStatus: this.typeStatus, //多加一个变更业务的判断字段
          addService: arr,
        },
      };
      this.$store.dispatch('REPLACE_TAG', route);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__header {
  margin-bottom: 12px !important;
}
.content {
  height: calc(100% - 52px);
  position: relative;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: auto;
}
.footer {
  width: 60%;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
  text-align: right;
  background: #fff;
  z-index: 1;
}
.listbox {
  border-radius: 8px;
  background: #f5f7fa;
  padding: 12px 16px;
  margin-bottom: 12px;
  position: relative;
  p:first-child {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 550;
    color: #242526;
    line-height: 20px;
    margin-bottom: 8px;
    & + p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3c3d40;
      line-height: 20px;
      margin-bottom: 4px;
      max-width: 360px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & + p + p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
      line-height: 17px;
    }
  }
}
.icon-sty {
  position: absolute;
  right: 10px;
}
.prompt-sty {
  text-align: center;
  margin: 24px 0px 60px 0;
}
.thumbnail-img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
}
.video-list {
  display: inline-flex;
  .thumbnail-video {
    margin-left: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
}
</style>
