<template>
  <div class="upload-list_box">
    <upload-file
      v-for="(item, index) in fileList"
      :key="index"
      v-model="item.value"
      :index="index"
      :disabled="disabled"
      :customDelImg="handleDelImg"
      @upload="handleUpload"
      :imagesForPreview="imagesForPreview.length ? imagesForPreview : imgs"
    ></upload-file>
    <!-- <div
      class="upload-file-box"
      v-show="fileList.length < max"
      @click="addFile"
    >
      <div class="avatar-uploader">
        <div class="el-upload">
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import uploadFile from './uploadFile.vue';
export default {
  name: 'uploadFileList',
  components: { uploadFile },
  props: {
    // 图片路径 用逗号分隔
    value: [String, Array],
    // 最大数量
    max: {
      type: Number,
      default: 10,
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 父组件传递的预览图片数组
    imagesForPreview: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        val = val ?? '';
        if (typeof val === 'string') {
          this.fileList = val.split(',').map((item) => ({ value: item }));
        }
        if (Array.isArray(val)) {
          this.fileList = val.map((item) => ({ value: item }));
        }
        if (
          this.fileList.at(-1)?.value &&
          this.fileList.length < this.max &&
          !this.disabled
        ) {
          this.fileList.push({ value: '' });
        }
      },
    },
  },
  computed: {
    imgs() {
      return this.fileList.filter((img) => img.value).map((item) => item.value);
    },
  },
  mounted() {},
  methods: {
    // 上传事件
    handleUpload(index) {
      // 如果最后一张上传
      this.uploadModel();
      if (
        index === this.fileList.length - 1 &&
        this.fileList.length < this.max
      ) {
        // this.$nextTick().then(() => {
        this.fileList.push({ value: '' });
        // });
      }
    },
    // 删除一项
    handleDelImg(i) {
      const needUpdate = !!this.fileList[i].value;
      this.fileList.splice(i, 1);
      if (needUpdate) this.uploadModel();
      // 不触发watch更新 原因待排查 临时解决方案
      if (
        this.fileList.at(-1)?.value &&
        this.fileList.length < this.max &&
        !this.disabled
      ) {
        this.fileList.push({ value: '' });
      }
    },
    // 同步model
    uploadModel() {
      const val = this.fileList
        .filter((item) => !!item.value)
        .map((item) => item.value)
        .join(',');
      this.$emit('input', val);
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-list_box {
  display: inline-flex;
  flex-wrap: wrap;
  // margin-left: 10px;
}
</style>
