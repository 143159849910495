<template>
  <el-dialog
    title="配置详情"
    top="5vh"
    width="50%"
    :visible="showCarConfigDialog"
    :before-close="handleClose"
    append-to-body
  >
    <div style="height: 70vh; overflow: auto">
      <div class="title">车架号：{{ uniqueId }}</div>
      <div class="content-box">
        <div class="list">
          <div
            v-for="(item, index) in vehicleList"
            :key="index"
            @click="
              selectedIndex = index;
              selectedVehicle = item;
            "
            class="item pointer"
            :class="{ active: index == selectedIndex }"
          >
            <p>
              {{ item.brandName }} {{ item.yearPattern }}
              {{ item.pl }}
            </p>
            <p>{{ item.gearboxName }} {{ item.gearNum }}</p>
            <p>{{ item.vehicleName }}</p>
          </div>
        </div>
        <div class="detail">
          <table class="border-table">
            <tr>
              <td colspan="2" class="td-title">基本信息</td>
            </tr>
            <tr>
              <td class="table-label">车型名称</td>
              <td>{{ selectedVehicle.vehicleName }}</td>
            </tr>
            <tr>
              <td class="table-label">车型类别</td>
              <td>{{ selectedVehicle.vehicleType }}</td>
            </tr>
            <tr>
              <td class="table-label">品牌</td>
              <td>{{ selectedVehicle.brandName }}</td>
            </tr>
            <tr>
              <td class="table-label">车系</td>
              <td>{{ selectedVehicle.familyName }}</td>
            </tr>
            <tr>
              <td class="table-label">排量（L）</td>
              <td>{{ selectedVehicle.pl }}</td>
            </tr>
            <tr>
              <td class="table-label">燃料类型</td>
              <td>{{ selectedVehicle.fuelJetType }}</td>
            </tr>
            <tr>
              <td class="table-label">变速箱描述</td>
              <td>{{ selectedVehicle.gearboxName }}</td>
            </tr>
            <tr>
              <td class="table-label">变速器档数</td>
              <td>{{ selectedVehicle.gearNum }}</td>
            </tr>
            <tr>
              <td class="table-label">年款</td>
              <td>{{ selectedVehicle.yearPattern }}</td>
            </tr>
            <tr>
              <td class="table-label">配置级别</td>
              <td>{{ selectedVehicle.cfgLevel }}</td>
            </tr>
            <tr>
              <td class="table-label">指导价</td>
              <td>{{ selectedVehicle.guidingPrice | toThousands(3) }}万</td>
            </tr>
            <tr class="split-tr"></tr>
            <tr>
              <td colspan="2" class="td-title">更多参数</td>
            </tr>
            <tr>
              <td class="table-label">厂家</td>
              <td>{{ selectedVehicle.manufacturers }}</td>
            </tr>
            <tr>
              <td class="table-label">发动机型号</td>
              <td>{{ selectedVehicle.engineModel }}</td>
            </tr>
            <tr>
              <td class="table-label">发动机描述</td>
              <td>{{ selectedVehicle.engineDesc }}</td>
            </tr>
            <tr>
              <td class="table-label">底盘号</td>
              <td>{{ selectedVehicle.chassisCode }}</td>
            </tr>
            <tr>
              <td class="table-label">底气缸容积</td>
              <td>{{ selectedVehicle.cylinderVolume }}</td>
            </tr>
            <tr>
              <td class="table-label">进气形式</td>
              <td>{{ selectedVehicle.inairform }}</td>
            </tr>
            <tr>
              <td class="table-label">供油方式</td>
              <td>{{ selectedVehicle.supplyOil }}</td>
            </tr>
            <tr>
              <td class="table-label">气缸排列形式</td>
              <td>{{ selectedVehicle.arrayType }}</td>
            </tr>
            <tr>
              <td class="table-label">气缸数</td>
              <td>{{ selectedVehicle.cylinders }}</td>
            </tr>
            <tr>
              <td class="table-label">每气缸门数</td>
              <td>{{ selectedVehicle.valveNum }}</td>
            </tr>
            <tr>
              <td class="table-label">最大功率（KW）</td>
              <td>{{ selectedVehicle.powerKw }}</td>
            </tr>
            <tr>
              <td class="table-label">排放标准</td>
              <td>{{ selectedVehicle.emissionStandard }}</td>
            </tr>
            <tr>
              <td class="table-label">前制动器类型</td>
              <td>{{ selectedVehicle.frontBrake }}</td>
            </tr>
            <tr>
              <td class="table-label">后制动器类型</td>
              <td>{{ selectedVehicle.rearBrake }}</td>
            </tr>
            <tr>
              <td class="table-label">驱动形式</td>
              <td>{{ selectedVehicle.drivenType }}</td>
            </tr>
            <tr>
              <td class="table-label">轴距（mm）</td>
              <td>{{ selectedVehicle.wheelbase }}</td>
            </tr>
            <tr>
              <td class="table-label">车门数（个）</td>
              <td>{{ selectedVehicle.doorNum }}</td>
            </tr>
            <tr>
              <td class="table-label">座位数</td>
              <td>{{ selectedVehicle.seats }}</td>
            </tr>
            <tr>
              <td class="table-label">前轮胎规格</td>
              <td>{{ selectedVehicle.frontTyre }}</td>
            </tr>
            <tr>
              <td class="table-label">后轮胎规格</td>
              <td>{{ selectedVehicle.rearTyre }}</td>
            </tr>
            <tr>
              <td class="table-label">备胎规格</td>
              <td>{{ selectedVehicle.spareWheel }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <template slot="footer">
      <div>
        <el-button size="small" type="primary" @click="handleClose"
          >确 定</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { listCarModelDetailByVIN } from '@/api/customer/car';
export default {
  props: {
    showCarConfigDialog: {
      type: Boolean,
      default: false,
    },
    // vehicleList: {
    //   type: Array,
    //   default: () => [],
    // },
    uniqueId: {
      type: String,
      default: '',
    },
    levelId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedIndex: 0,
      selectedVehicle: {},
      vehicleList: [],
    };
  },
  created() {
    this.getVehicleList();
  },
  methods: {
    // 搜索配置详情
    getVehicleList() {
      listCarModelDetailByVIN(this.uniqueId).then((res) => {
        this.vehicleList = res.vehicleList || [];

        if (this.levelId) {
          //findIndex寻找对应的角标然后选中
          this.selectedIndex = this.vehicleList.findIndex(
            (item) => item.levelId == this.levelId
          );
          this.selectedVehicle = this.vehicleList[this.selectedIndex];
        } else {
          this.selectedVehicle = this.vehicleList[0];
        }
      });
    },
    handleClose() {
      this.$emit('update:showCarConfigDialog', false);
      this.$emit('selectedVehicle', this.selectedVehicle); //把选中的数据传过去
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #333;
  font-weight: 600;
  padding-bottom: 15px;
  // border-bottom: 1px solid #e6e6e6;
}
.content-box {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  .list,
  .detail {
    overflow: auto;
    height: calc(70vh - 69px);
  }
  .list {
    display: flex;
    flex-direction: column;
    flex-basis: 35%;

    .item {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 12px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #e8e8e8;
      & + .item {
        margin-top: 15px;
      }
      &.active {
        color: $primary;
        background: $bg;
        border: 1px solid $primary;
      }
      > p {
        font-size: 14px;
        & + p {
          margin-top: 10px;
        }
      }
    }
  }
  .detail {
    flex-basis: 65%;
    display: flex;
    justify-content: center;
    .border-table,
    .border-table > tr,
    .border-table > tr > td {
      border: 1px solid #e8e8e8;
    }
    .border-table {
      width: 80%;
      border: 1px solid #e8e8e8;
      .td-title {
        background: $bg;
        text-align: center;
      }
      > tr {
        height: 44px;
        > td {
          vertical-align: middle;
        }
      }
      .table-label {
        width: 160px;
        box-sizing: border-box;
        padding-right: 30px;
        text-align: right;
      }
      td:last-child {
        padding-left: 30px;
      }
      .split-tr {
        height: 30px;
      }
    }
  }
}
</style>
