import http from '@/service/http';

//接车单-定损单列表数据
export function getCarLossDeterminationBillList(data) {
  return http.post('/admin/carLossDeterminationBill/list', data);
}
//接车单-定损单-定损单详情
export function getCarLossDeterminationBillInfo(data) {
  return http.post('/admin/carLossDeterminationBill/info', data);
}
//接车单-定损单-定损状态与统计
export function getCarLossDeterminationBillStatistic(data) {
  return http.post('/admin/carLossDeterminationBill/statistic', data);
}
//接车单-车单检验锁单
export function getBillLockState(id = '') {
  return http.post(
    '/admin/car/bill/billLockState',
    { id: id },
    { onlyReturnData: false, noHandleError: true }
  );
}
//接车单-定损单-车单加锁
export function getCreateBillLocked(id = '') {
  return http.post(
    '/admin/car/bill/createBillLocked',
    { id: id },
    { onlyReturnData: false, noShowWaiting: true, noHandleError: true }
  );
}
//接车单-车单解锁操作
export function getBillLocakRelease(id = '') {
  return http.post(
    '/admin/car/bill/billLocakRelease',
    { id: id },
    { onlyReturnData: false }
  );
}

//接车单-车单详情记录-定损报备
export function getCarLossDeterminationBillOperateLossRepor(data) {
  return http.post('/admin/carLossDeterminationBill/operateLossReport', data, {
    onlyReturnData: false,
  });
}
//接车单-车单详情记录-出损确认
export function getCarLossDeterminationConfirmLossDetermination(data) {
  return http.post(
    '/admin/carLossDeterminationBill/confirmLossDetermination',
    data,
    {
      onlyReturnData: false,
    }
  );
}

//接车单-车单详情记录-撤销定损单
export function getCarLossDeterminationBillCancelOperate(data) {
  return http.post('/admin/carLossDeterminationBill/cancelOperate', data, {
    onlyReturnData: false,
  });
}

//接车单-车单详情记录-定损单回款认领
export function getBillLocakConfirmPaymentCollection(id = '') {
  return http.post(
    '/admin/carLossDeterminationBill/confirmPaymentCollection',
    { id: id },
    { onlyReturnData: false }
  );
}

//接车单-车单详情记录-定损单编辑理赔信息
export function getCarLossDeterminationBillUpdate(data) {
  return http.post('/admin/carLossDeterminationBill/update', data, {
    onlyReturnData: false,
  });
}

//接车单-接车单列表
export function getCarBillList(
  data,
  option = {
    onlyReturnData: false,
  }
) {
  return http.post('/admin/car/bill/list', data, option);
}
/**
 * 根据车牌搜索车单列表
 * @param {Object} data 车牌号和分页参数
 * @param {Object} option xhr控制参数
 * @returns 响应数据
 */
export function searchByCarNumber(
  data,
  option = {
    onlyReturnData: false,
  }
) {
  return http.post('/admin/car/bill/searchByCarNumber', data, option);
}

//导出接车单列表
export const exportCarBillListUrl = '/admin/carBill/export';
export const exportCarLossDeterminationBillListUrl =
  '/admin/carLossDeterminationBill/export';

//接车单-状态与统计
export function getCarBillStatistic(data) {
  return http.post('/admin/car/bill/carBillStatistic', data, {
    noShowWaiting: true,
  });
}
//接车单-增加查询结果数据的单据金额合计
export function getCarBillPaidStatistic(data) {
  return http.post('/admin/car/bill/carBillPaidStatistic', data, {
    noShowWaiting: true,
  });
}

//车单记录-车单反向记录列表
export function getListReverseProcessRecord(data) {
  return http.post('/admin/car/bill/listReverseProcessRecord', data);
}

//车单记录-状态与统计
export function getReverseProcessRecordStatistics(data) {
  return http.post('/admin/car/bill/reverseProcessRecordStatistics', data);
}

//新版改版之后的定损单详情里的相关接口
//下一步流程接口
export function carLossDeterminationBillNext(data) {
  return http.post('/admin/carLossDeterminationBill/next', data, {
    onlyReturnData: false,
  });
}
//撤销流程接口
export function carLossDeterminationBillBack(data) {
  return http.post('/admin/carLossDeterminationBill/back', data, {
    onlyReturnData: false,
  });
}

// 定损单检验锁单状态
export function getCarLossDeterminationBillLockState(id = '') {
  return http.post(
    '/admin/carLossDeterminationBill/lockState',
    { id: id },
    { onlyReturnData: false, noHandleError: true }
  );
}

// 定损单加锁
export function setCarLossDeterminationBillLock(id = '') {
  return http.post(
    '/admin/carLossDeterminationBill/createLocked',
    { id: id },
    { onlyReturnData: false, noShowWaiting: true, noHandleError: true }
  );
}
// 定损单解锁
export function removeCarLossDeterminationBillLock(
  id = '',
  config = { onlyReturnData: false }
) {
  return http.post(
    '/admin/carLossDeterminationBill/lockRelease',
    { id: id },
    config
  );
}
