<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div
        class="flex h100"
        style="flex: 1; overflow: auto; background: #f1f7fa"
      >
        <div class="left_side">
          <div class="brand_num">
            <h2 style="font-weight: 600">品牌车型</h2>
            <p>已上架问诊库车型({{ totalCount }})</p>
          </div>
          <ul style="margin-top: 16px" class="sidebar-menu-second_ul">
            <li class="sidebar-menu-second_li">
              <div
                class="sidebar-menu-second_btn"
                :class="{
                  'has-children': false,
                  active: activeId == 0,
                }"
                @click="
                  handleThirdMenuClick(
                    carModeList[0].inquiryCarSeriesList[0],
                    1
                  )
                "
              >
                <i
                  style="
                    margin-right: 5px;
                    scale: 1.2;
                    font-size: 24px;
                    color: #666;
                  "
                  class="newicon grow-icon_ok_shiwu"
                ></i>
                <span class="sidebar-menu-second_text"
                  >通用问诊库
                  <el-tooltip effect="light" placement="bottom-start">
                    <div
                      slot="content"
                      style="
                        color: #666666;
                        font-size: 13px;
                        line-height: 16px;
                        text-indent: 15px;
                      "
                    >
                      未在上架车型列表的车辆进店将使用通用问诊库数据
                    </div>
                    <i
                      style="margin-left: 14px"
                      class="iconfont grow-icon_doubt"
                    ></i>
                  </el-tooltip>
                </span>
              </div>
            </li>
            <li
              class="sidebar-menu-second_li"
              v-for="(secondMenu, index) in carModeList.slice(1)"
              :key="index"
            >
              <div
                class="sidebar-menu-second_btn"
                :class="{
                  'has-children': secondMenu.inquiryCarSeriesList,
                }"
                @click="handleSecondMenuClick(secondMenu)"
              >
                <img
                  style="margin-right: 5px"
                  :src="secondMenu.logoImagePath"
                />
                <span class="sidebar-menu-second_text">
                  {{ secondMenu.logoName }}
                  ({{ secondMenu.carSeriesKindNum }})
                </span>

                <i
                  v-show="secondMenu.inquiryCarSeriesList"
                  class="sidebar-menu-expand_icon newicon grow-icon-arrow-right"
                  :class="{ active: secondMenu.showChild }"
                ></i>
              </div>
              <ul class="sidebar-menu-third_ul" v-show="secondMenu.showChild">
                <li
                  v-for="(thirdMenu, index) in secondMenu.inquiryCarSeriesList"
                  :key="index"
                  :class="{
                    'sidebar-menu-third_li': true,
                    active: activeId == thirdMenu.modelDetailSeriesId,
                  }"
                  @click="handleThirdMenuClick(thirdMenu)"
                >
                  {{ thirdMenu.seriesName }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="right_side">
          <div class="flex" style="margin: 20px 0px 29px 16px">
            <el-input
              v-model="form.appearanceName"
              placeholder="搜索故障现象"
              clearable
              style="width: 150px; margin-right: 20px"
              @keyup.enter.native="doSeach"
            ></el-input>
            <el-button type="primary" size="small" @click="doSeach"
              >查询</el-button
            >
          </div>
          <div class="flex h100">
            <!-- 故障现象表 -->
            <div class="h100" style="width: 34%; margin-left: 16px">
              <div class="flex-x-between table_top">
                <span>故障现象</span>
                <p v-auth="557" @click="addDate(1)">新增现象</p>
              </div>
              <el-table
                ref="singleTable"
                class="customer-table"
                height="83%"
                :data="faultPhenomenonData"
                border
                @row-click="clickPhenomenonRow"
                highlight-current-row
              >
                <el-table-column
                  type="index"
                  width="80"
                  label="序号"
                ></el-table-column>
                <el-table-column property="appearanceName" label="故障现象">
                </el-table-column
              ></el-table>
              <template class="footer">
                <pagination
                  @pageChange="changePagePhenomenon"
                  :total="totalPhenomenon"
                  type="mini"
                  :typeStyle="typeStyle"
                ></pagination>
              </template>
            </div>

            <!-- 故障原因表 -->
            <div style="width: 34%; margin: 0px 16px">
              <div class="flex-x-between table_top">
                <span>故障原因</span>
                <p v-auth="557" @click="addDate(2)">新增原因</p>
              </div>
              <el-table
                class="customer-table"
                ref="singleTable"
                height="83%"
                :data="faultCauseData"
                border
                @row-click="clickCauseRow"
                highlight-current-row
                empty-text="请先选择故障现象"
              >
                <el-table-column
                  type="index"
                  width="80"
                  label="序号"
                ></el-table-column>
                <el-table-column property="sysName" label="系统" width="120">
                </el-table-column>
                <el-table-column property="causeName" label="故障原因">
                </el-table-column
              ></el-table>
              <template class="footer">
                <pagination
                  @pageChange="changePageCause"
                  :total="totalCause"
                  type="mini"
                  :typeStyle="typeStyle"
                ></pagination>
              </template>
            </div>
            <!-- 维修方案表 -->
            <div style="width: 30%; margin-right: 16px">
              <div class="flex-x-between table_top">
                <span>维修方案</span>
                <p v-auth="557" @click="addDate(3)">新增方案</p>
              </div>
              <el-table
                ref="singleTable"
                height="83%"
                :data="WzServiceData"
                border
                empty-text="请先选择故障原因"
              >
                <el-table-column
                  type="index"
                  width="80"
                  label="序号"
                ></el-table-column>
                <el-table-column label="操作" width="100">
                  <template #default="{ row }">
                    <p
                      v-auth="558"
                      class="blue pointer"
                      @click="editEvent(row)"
                    >
                      编辑
                    </p>
                  </template>
                </el-table-column>
                <el-table-column property="serviceName" label="维修方案">
                </el-table-column
              ></el-table>
              <template class="footer">
                <pagination
                  @pageChange="changePageSolution"
                  :total="totalSolution"
                  type="mini"
                  :typeStyle="typeStyle"
                ></pagination>
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- 弹窗 -->
      <consultation-dialog
        v-if="editDialog"
        :visibility.sync="editDialog"
        :title="title"
        :modelId="modelId"
        :showContent="showContent"
        :clickAppearanceName="clickAppearanceName"
        :clickCauseName="clickCauseName"
        @updateData="deteleUpdate"
      ></consultation-dialog>
    </template>
    <car-models-dialog
      v-if="showCarModelsDialog"
      :showCarModelsDialog.sync="showCarModelsDialog"
      @carModel="selectCarModel"
    ></car-models-dialog>
  </base-container>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
// 分页组件
import pagination from '@/components/Pagination.vue';

import carModelsDialog from '@/views/customerManagement/car/addCar/carModelsDialog.vue';
// 接口
import {
  getInquiryCarType,
  getAppearancePageByModelIds,
  getCausePageByModelIds,
  getWzServicePageByModelId,
} from '@/api/system/inquiryModel';
//弹窗
import consultationDialog from '@/components/businessComponent/consultationDialog.vue';

export default {
  name: 'consultationLibrary',
  components: { pagination, carModelsDialog, consultationDialog },
  data() {
    return {
      // 已上架问诊库车型数量
      totalCount: '',
      // 当前激活id
      activeId: 0,
      //点击之后的高亮Id
      afterActiveId: '',
      afterMenu: {},
      hasChange: false,

      showCarModelsDialog: false,
      showSecondMenus: false,
      selectedFirstMenu: {},
      carModeList: [],
      timer: null,
      isShow: true,

      form: {},
      //故障现象分页组件
      totalPhenomenon: 0,
      pageOptionPhenomenon: {
        size: 10,
        current: 1,
      },
      //故障原因分页组件
      totalCause: 0,
      pageOptionCause: {
        size: 10,
        current: 1,
      },
      //维修方案分页组件
      totalSolution: 0,
      pageOptionSolution: {
        causeId: '',
        size: 10,
        current: 1,
      },
      faultPhenomenonData: [],
      faultCauseData: [],
      WzServiceData: [],
      //弹窗可见性
      editDialog: false,
      title: '',
      showContent: false,

      general: 1,
      phenomenonData: {},
      causeData: {},
      solutionRequestData: {},
      modelId: '',
      clickAppearanceName: '',
      clickCauseName: '',
      clickData: {},
      typeStyle: true,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      companyId: (state) => state.user.companyId,
    }),
    ...mapGetters(['isHeadquarters']),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  mounted() {
    this.getModelCar();
  },
  methods: {
    /**
     * 点击一级菜单
     */
    handleSecondMenuClick(menu) {
      this.afterMenu = menu;
      if (menu.inquiryCarSeriesList?.length && !menu.showChild) {
        this.$set(menu, 'showChild', true);
      } else {
        this.$set(menu, 'showChild', false);
      }
    },
    /**
     * 点击二级菜单
     */
    handleThirdMenuClick(menu, general = 0) {
      this.general = general;
      this.phenomenonData = menu;
      this.getAppearance();
      this.faultCauseData = [];
      this.WzServiceData = [];
      this.totalPhenomenon = 0;
      this.totalCause = 0;
      this.totalSolution = 0;

      // 当前激活id
      this.activeId = this.phenomenonData.modelDetailSeriesId || 0;
      this.afterActiveId = this.phenomenonData.modelDetailSeriesId || 0;
    },

    //更改故障现象页码
    changePagePhenomenon(e) {
      this.pageOptionPhenomenon = e;
      this.totalCause = 0;
      this.totalSolution = 0;
      this.faultCauseData = [];
      this.WzServiceData = [];
      this.getAppearance(false);
    },
    //更改故障原因页码
    changePageCause(e) {
      this.pageOptionCause = e;
      this.totalSolution = 0;
      this.WzServiceData = [];
      this.getCause(false);
    },
    //更改维修方案原因页码
    changePageSolution(e) {
      this.pageOptionSolution = e;
      this.getSolution(false);
    },
    //查询问诊车型
    getModelCar() {
      getInquiryCarType().then((res) => {
        if (this.hasChange) {
          this.carModeList = this.$lodash.cloneDeep(res).map((item) => {
            if (item.logoName == this.afterMenu.logoName) {
              this.$set(item, 'showChild', true);
            }
            return item;
          });
        } else {
          this.carModeList = res;
        }

        // 默认选中 通用问诊库
        this.phenomenonData = res[0].inquiryCarSeriesList[0];
        // 计算已上架问诊库车型总数
        this.totalCount = res.slice(1).reduce((pre, cur) => {
          return pre + cur.carSeriesKindNum;
        }, 0);

        this.getAppearance();
      });
    },
    //查询故障现象
    getAppearance(reset = true) {
      if (reset) {
        this.pageOptionPhenomenon.current = 1;
        this.totalPhenomenon = 0;
      }
      var otherData = {
        general: this.general,
        modelIds: this.phenomenonData?.modelIds,
        appearanceName: this.form.appearanceName,
      };

      var data = Object.assign({}, this.pageOptionPhenomenon, otherData);
      getAppearancePageByModelIds(data).then((res) => {
        this.faultPhenomenonData = res.records;
        this.totalPhenomenon = res.total;
      });
    },
    clickPhenomenonRow(event) {
      this.causeData = event;
      this.clickAppearanceName = event.appearanceName;
      this.getCause();
      this.WzServiceData = [];
      this.totalSolution = 0;
    },
    //查询故障原因
    getCause(reset = true) {
      if (reset) {
        this.pageOptionCause.current = 1;
        this.totalCause = 0;
      }
      var otherData = {
        modelIds: this.phenomenonData.modelIds,
        appearanceId: this.causeData.appearanceId,
      };
      var data = Object.assign(this.pageOptionCause, otherData);
      getCausePageByModelIds(data).then((res) => {
        this.faultCauseData = res.records;
        this.totalCause = res.total;
      });
    },
    //查询维修方案
    getSolution(reset = true) {
      if (reset) {
        this.pageOptionSolution.current = 1;
        this.totalSolution = 0;
      }
      var otherData = {
        modelIds: this.solutionRequestData.modelIds,
        appearanceId: this.solutionRequestData.appearanceId,
        causeId: this.solutionRequestData.causeId,
      };
      var data = Object.assign(this.pageOptionSolution, otherData);
      getWzServicePageByModelId(data).then((res) => {
        this.WzServiceData = res.records;
        this.totalSolution = res.total;
      });
    },
    clickCauseRow(event) {
      this.clickData = event;
      this.getWzService(event);
    },
    //查询维修方案
    getWzService(event) {
      var data = {
        modelIds: this.phenomenonData.modelIds,
        appearanceId: this.causeData.appearanceId,
        causeId: event.causeId,
      };
      this.solutionRequestData.modelIds = this.phenomenonData.modelIds;
      this.solutionRequestData.appearanceId = this.causeData.appearanceId;
      this.solutionRequestData.causeId = event.causeId;
      getWzServicePageByModelId(data).then((res) => {
        this.WzServiceData = res.records;
        this.totalSolution = res.total;
      });
    },
    addDate(val) {
      this.editDialog = true;
      switch (val) {
        case 1:
          this.title = '新增问诊模型';
          this.showContent = false;
          this.modelId = '';
          this.clickAppearanceName = '';
          this.clickCauseName = '';
          break;
        case 2:
          this.title = '新增故障原因';
          this.showContent = false;
          this.modelId = '';
          this.clickAppearanceName = this.causeData.appearanceName;
          this.clickCauseName = '';
          break;
        case 3:
          this.title = '新增维修方案';
          this.showContent = false;
          this.modelId = '';
          this.clickAppearanceName = this.causeData.appearanceName;
          this.clickCauseName = this.clickData.causeName;
          break;
        case 4:
          this.title = '编辑问诊模型';
          this.showContent = true;
          this.clickAppearanceName = '';
          this.clickCauseName = '';
          break;
      }
    },
    editEvent(row) {
      this.modelId = row.modelId;
      this.addDate(4);
    },
    deteleUpdate() {
      this.hasChange = true;
      this.getModelCar();
      this.activeId = this.afterActiveId;
      this.faultCauseData = [];
      this.WzServiceData = [];
      this.totalSolution = 0;
      this.totalCause = 0;
    },
    doSeach() {
      this.getAppearance();
      this.faultCauseData = [];
      this.WzServiceData = [];
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .main {
  padding: 0 !important;
}
.footer {
  position: fixed;
  bottom: 20px;
  left: 30%;
}
.left_side {
  width: 284px;
  background: #fff;
  margin-right: 20px;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: auto;
  .brand_num {
    height: 69px;
    margin: 16px;
    background: #f5f7fa;
    border-radius: 4px;
    h2 {
      text-indent: 1.2em;
      padding-top: 12px;
    }
    p {
      color: #606266;
      font-size: 12px;
      text-indent: 1.5em;
      margin: 12px 0 12px 0;
    }
  }
}
.right_side {
  flex: 1;
  overflow: auto;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
}
.sidebar-menu-second_ul {
  margin-top: 16px;
  flex: 1;
  overflow: auto;
  .sidebar-menu-second_li {
    position: relative;
    .sidebar-menu-second_btn {
      height: 37px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      border-radius: 2px;
      box-sizing: border-box;
      cursor: pointer;
      color: #242526;
      &.active .sidebar-menu-second_text {
        color: $primary;
      }
      &:hover {
        &:not(.has-children) {
          background: rgba(51, 171, 121, 0.1);
        }
        .sidebar-menu-second_text,
        .sidebar-menu-expand_icon {
          color: $primary;
        }
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
    & + .sidebar-menu-second_li {
      margin-top: 2px;
    }

    .sidebar-menu-second_text {
      color: #242526;
      font-size: 14px;
      font-weight: 600;
    }
    .sidebar-menu-expand_icon {
      position: absolute;
      right: 8px;
      font-size: 14px;
      color: #606266;
      transition: 0.2s;
      transform: rotate(0deg);
      &.active {
        transform: rotate(90deg);
      }
    }

    .sidebar-menu-third_ul {
      padding-bottom: 8px;
      // position: absolute;
      .sidebar-menu-third_li {
        height: 32px;
        display: flex;
        align-items: center;
        padding-left: 35px;
        border-radius: 2px;
        box-sizing: border-box;
        cursor: pointer;
        color: #3c3d40;
        font-size: 14px;
        font-weight: 400;
        & + .sidebar-menu-third_li {
          margin-top: 2px;
        }
        &.active,
        &:hover {
          color: $primary;
        }
        &:hover {
          background: rgba(51, 171, 121, 0.1);
        }
      }
    }
  }
}
.table_top {
  height: 50px;
  background: #f5f7fa;
  span {
    line-height: 50px;
    text-indent: 1em;
    font-size: 14px;
    color: #242526;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
  }
  p {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    border: 1px solid #33ab79;
    color: #33ab79;
    font-size: 12px;
    padding: 0px 15px;
    margin: 10px 2em 0 0;
    cursor: pointer;
  }
}

.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
.customer-table ::v-deep .el-table__body-wrapper {
  overflow: auto;
}
::v-deep.el-table__body tr.current-row > td {
  background: #d0d3d9 !important;
}
</style>
