<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="900px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="editForm"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="110px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <!--左边商品信息-->
        <el-col :span="12" :offset="0">
          <el-form-item label="录入门店:">
            {{ editForm.company.companyName }}
          </el-form-item>
          <el-form-item label="商品名称:">
            {{ editForm.goods.name }}
          </el-form-item>
          <el-form-item label="商品标准名称:">
            {{ editForm.goods.standardName }}
          </el-form-item>
          <el-form-item label="商品品牌:">
            {{ editForm.goods.brandName }}
          </el-form-item>
          <el-form-item label="商品编号:">
            {{ editForm.goods.goodsCode }}
          </el-form-item>
          <el-form-item label="单位:">
            {{ editForm.goods.unit }}
          </el-form-item>
          <el-form-item label="售价:">
            {{ editForm.goods.price }}
          </el-form-item>
          <el-form-item label="是否启用" prop="isActive">
            <el-switch
              v-model="editForm.isActive"
              :active-text="editForm.isActive ? '是' : '否'"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item label="是否共享" prop="isShare">
            <el-switch
              v-model="editForm.isShare"
              :active-text="editForm.isShare ? '是' : '否'"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <!--右上  库存模块-->
          <div>
            <el-checkbox
              size="large"
              value="0"
              :indeterminate="isIndeterminate"
              v-model="editForm.disableAllModule"
              @change="handleSelectDisableAllModule"
              >全部禁用</el-checkbox
            >
            <div style="height: 10px"></div>
            <el-checkbox-group
              v-model="editForm.disableModule"
              @change="handleSelectDisableModule"
            >
              <el-checkbox
                style="margin: 10px 10px 10px 0px; width: 100px"
                v-for="config in inventoryGoodsControlModuleArray"
                :key="config.value"
                :label="config.value"
                size="large"
                >{{ config.desc }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <!--右下 门店模块-->
          <div style="margin: 40px 0px 20px">
            <div>
              <el-radio v-model="editForm.disableAllCompany" :label="1"
                ><span style="color: #333">全部门店</span></el-radio
              >
              <el-radio
                style="margin-left: 30px"
                v-model="editForm.disableAllCompany"
                :label="0"
                ><span style="color: #333">部分门店</span></el-radio
              >
              <el-button
                v-if="editForm.disableAllCompany == 0"
                size="small"
                class="btn_insert"
                @click="addStore('disablePartCompanyIds')"
                >选择门店</el-button
              >
            </div>
            <div class="table-box">
              <el-table
                border
                v-if="editForm.disableAllCompany == 0"
                :data="editForm.disablePartCompanyIds"
              >
                <el-table-column label="操作" width="80">
                  <template #default="{ $index }">
                    <el-button
                      @click="deleteRow(editForm.disablePartCompanyIds, $index)"
                      type="text"
                      size="small"
                    >
                      <i
                        class="iconfont grow-icon_delete3"
                        style="color: #ff9200; font-size: 22px"
                      ></i>
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  type="index"
                  label="序号"
                  width="60"
                ></el-table-column>
                <el-table-column
                  width="120"
                  prop="companyId"
                  label="车店编号"
                ></el-table-column>
                <el-table-column
                  prop="companyName"
                  label="车店名称"
                ></el-table-column>
                <el-table-column width="100" prop="type" label="连锁类型">
                  <template slot-scope="scope">{{
                    scope.row.chainCompanyType | dict('chainCompanyType')
                  }}</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <select-store
      v-if="storeDrawerVisibility"
      :storeDrawerVisibility.sync="storeDrawerVisibility"
      :stores="addStoreData"
      @select="handleSelectStore"
    ></select-store>
    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';

import selectStore from '@/views/systemManagement/selectStore';

import {
  getGoodsControlList,
  getInventoryGoodsControlModuleList,
  getGoodsControlDetail,
  updateInventoryGoodsControl,
} from '@/api/stock/goodsControl';

export default {
  name: 'editGoodsControlDialog',
  components: {
    selectStore,
  },
  props: {
    visibility: Boolean,
    id: Number,
    detail: {
      type: Object,
      default: () => ({}),
    },
    inventoryGoodsControlModuleList: {
      type: Array,
      default: () => [],
    },
    sharedStatusEnum: {
      type: Array,
      default: () => [],
    },
    availableStatusEnum: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {
        id: null,
        goodsId: null,
        disableAllModule: false,
        disableAllCompany: '',
        disableModule: [],
        disablePartCompanyIds: [],
        isActive: 0,
        isShare: 0,
        company: {
          companyName: '',
        },
        goods: {},
      },
      isIndeterminate: false,
      inventoryGoodsControlModuleArray: [],
      sharedStatusEnumArray: [],
      availableStatusEnumArray: [],
      // 选择门店抽屉可见性
      storeDrawerVisibility: false,
      // 当前选择门店字段
      addStoreProp: '',
      // 当前选择门店数据
      addStoreData: [],
      // 表单规则
      rules: {
        isActive: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择是否共享',
          },
        ],
        isShare: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择是否共享',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return '编辑商品管控信息';
    },
  },
  created() {
    if (Object.values(this.detail).length) {
      this.editForm = this.$lodash.cloneDeep(this.detail);
      this.$set(this.editForm, 'isActive', this.detail.goods.isActive ? 1 : 0);
      this.$set(this.editForm, 'isShare', this.detail.goods.isShare ? 1 : 0);

      this.$set(this.editForm, 'disableModule', []);
      console.log(this.editForm);
    }
    if (Object.values(this.inventoryGoodsControlModuleList).length) {
      const list = JSON.parse(
        JSON.stringify(this.inventoryGoodsControlModuleList)
      );
      for (let i = 0; i < this.inventoryGoodsControlModuleList.length; i++) {
        if (this.inventoryGoodsControlModuleList[i].value == 0) {
          // eslint-disable-next-line vue/no-mutating-props
          this.inventoryGoodsControlModuleList.splice(i, 1);
          break;
        }
      }
      console.log(this.inventoryGoodsControlModuleList);
      for (let i = 0; i < this.inventoryGoodsControlModuleList.length; i++) {
        this.inventoryGoodsControlModuleArray.push(
          this.inventoryGoodsControlModuleList[i]
        );
      }
      // this.$set(
      //   this.inventoryGoodsControlModuleArray,
      //   this.inventoryGoodsControlModuleList
      // );
    }
    this.init();
  },
  methods: {
    init() {
      getGoodsControlDetail(this.id).then((res) => {
        // 打钩
        if (
          res.goodsControlSetting &&
          res.goodsControlSetting.disableModule &&
          res.goodsControlSetting.disableModule.length > 0
        ) {
          res.goodsControlSetting.disableModule.forEach((st) => {
            this.editForm.disableModule.push(st);
          });
          if (
            this.editForm.disableModule.length ==
            this.inventoryGoodsControlModuleArray.length
          ) {
            this.isIndeterminate = false;
          } else {
            this.isIndeterminate = true;
          }
        }
        if (
          res.goodsControlSetting &&
          res.goodsControlSetting.disableAllCompany != undefined
        ) {
          this.$set(
            this.editForm,
            'disableAllCompany',
            res.goodsControlSetting.disableAllCompany ? 1 : 0
          );
        }
        if (
          res.disablePartCompanyList &&
          res.disablePartCompanyList.length > 0
        ) {
          this.$set(
            this.editForm,
            'disablePartCompanyIds',
            res.disablePartCompanyList
          );
        }
        console.log('res', res);
        console.log('edit', this.editForm);
      });
      // 如果选择了全部，则其他的取消掉
      // 如果选择了部分门店，初始化门店列表
    },
    handleSelectDisableAllModule(val) {
      if (val) {
        for (let i = 0; i < this.inventoryGoodsControlModuleArray.length; i++) {
          this.editForm.disableModule.push(
            this.inventoryGoodsControlModuleArray[i].value
          );
        }
      } else {
        this.editForm.disableModule = [];
      }
      this.isIndeterminate = false;
    },
    handleSelectDisableModule(values) {
      const checkedCount = values.length;
      this.editForm.disableAllModule =
        checkedCount === this.inventoryGoodsControlModuleArray.length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.inventoryGoodsControlModuleArray.length;
      console.log(this.editForm.disableModule);
    },
    /**
     * 处理选择门店回调
     */
    handleSelectStore(stores) {
      this.editForm[this.addStoreProp] = stores;
    },
    /**
     * 删除适用门店表格行
     */
    deleteRow(tableData, index) {
      tableData.splice(index, 1);
    },
    /**
     * 选择禁用门店
     */
    handleSelectDisableCompany(val) {
      if (val === 1) {
        this.editForm.disablePartCompanyIds = [];
      }
      this.$forceUpdate();
    },
    /**
     * 添加门店
     */
    addStore(prop) {
      // 设置标识
      this.addStoreProp = prop;
      this.addStoreData = this.editForm[prop] || [];
      this.storeDrawerVisibility = true;
    },
    /**
     * 保存
     */
    save() {
      // 如果选择了 全部模块，则

      // 如果选择了部分门店，则必须选择门店
      const formData = {
        goodsId: this.editForm.goods.goodsId,
        isShare: this.editForm.isShare,
        isActive: this.editForm.isActive,
        disableAllCompany: this.editForm.disableAllCompany,
      };
      if (this.editForm.id) {
        formData.id = this.editForm.id;
      }
      var body = Object.assign({}, formData, {
        disableModule: this.editForm.disableModule,
        disableAllCompany: this.editForm.disableAllCompany,
        disablePartCompanyIds: this.editForm.disablePartCompanyIds,
      });
      body.disableModule = [];
      if (this.editForm.disableModule) {
        for (let i = 0; i < this.editForm.disableModule.length; i++) {
          body.disableModule.push(this.editForm.disableModule[i]);
        }
      }
      if (this.editForm.disableAllModule) {
        body.disableModule.push(0);
      }
      body.disablePartCompanyIds = [];
      if (body.disableAllCompany == 0) {
        if (this.editForm.disablePartCompanyIds) {
          for (let i = 0; i < this.editForm.disablePartCompanyIds.length; i++) {
            body.disablePartCompanyIds.push(
              this.editForm.disablePartCompanyIds[i].companyId
            );
          }
        }
        if (body.disablePartCompanyIds.length == 0) {
          this.$message({
            type: 'warning',
            message: '请选择门店或者选择全部门店',
          });
          return;
        }
      }
      console.log('save', this.editForm);
      console.log('save', formData, body);
      updateInventoryGoodsControl(body).then((res) => {
        this.$message.success('保存成功！');
        this.$emit('save');
        this.handleClose();
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
