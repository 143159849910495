<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNumber"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                  :operateCompanyUrl="form.operateCompanyUrl"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="接车进店日期">
                <el-date-picker
                  class="w100"
                  v-model="form.intoStoreTimes"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  @input="dateRangeChange()"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div>
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="评价意见">
              <el-select
                v-model="form.appraiseLevel"
                @change="selectAdminJobListUser"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in appraiseList"
                  :key="item.level"
                  :label="item.appraise"
                  :value="item.level"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入接车单号"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="评价日期">
              <el-date-picker
                class="w100"
                v-model="form.appraiseTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="评价标签">
              <el-select
                v-model="form.appraiseTag"
                filterable
                clearable
                remote
                placeholder="请选择"
                :remote-method="getAppraiseTags"
                :loading="searchAppraiseTagsLoading"
              >
                <el-option
                  v-for="(item, index) in appraiseTagList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <template v-if="showMore">
              <el-form-item label="接车员">
                <el-select
                  v-model="form.pickUpUserId"
                  @change="selectAdminJobListUser"
                  filterable
                  clearable
                  remote
                  placeholder="请选择"
                  :remote-method="searchAdminJobListUser"
                  :loading="searchAdminJobListUserLoading"
                >
                  <el-option
                    v-for="item in adminJobListUserList"
                    :key="item.userId"
                    :label="item.realName"
                    :value="item.userId"
                  >
                    <span
                      style="float: left; color: #8492a6; font-size: 13px"
                      >{{ item.realName }}</span
                    >
                    <span style="float: right">{{ item.phone }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="签约客户">
                <el-select
                  v-model="form.clientId"
                  @change="selectClient"
                  filterable
                  clearable
                  remote
                  placeholder="请输入姓名"
                  :remote-method="searchClient"
                  :loading="searchClientLoading"
                >
                  <el-option
                    v-for="item in customList"
                    :key="item.id"
                    :label="item.clientName"
                    :value="item.id"
                  >
                    <span
                      style="float: left; color: #8492a6; font-size: 13px"
                      >{{ item.clientName }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车单完工日期">
                <el-date-picker
                  class="w100"
                  v-model="form.finishTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="车架号">
                <el-input
                  v-model="form.uniqueId"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
            </template>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="display: flex; justify-content: end">
        <el-button
          v-auth="494"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto">
        <div style="flex: 1; overflow: auto">
          <el-table
            class="custom-table"
            :data="appraisList"
            border
            @row-dblclick="goDetail($event.billId)"
            v-fixedScrollBar="$refs.baseContainer"
          >
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column
              width="120"
              label="操作门店"
              prop="operateCompanyName"
            >
              <template #default="{ row }">
                <el-tooltip :content="row.company.companyName">
                  <div class="text-ellipsis-2-row">
                    {{ row.company.companyName }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              label="车牌号"
              prop="carBillDetailsVo.carNumber"
            >
            </el-table-column>
            <el-table-column width="190" label="车单号/车单标签">
              <template #default="{ row }">
                <div class="blue pointer" @click="goDetail(row.billId)">
                  {{ row.carBillDetailsVo.billCode }}
                </div>
                <div class="tags">
                  <el-tag
                    v-if="row.carBillDetailsVo.returnCarOption.value"
                    size="small"
                    >{{ row.carBillDetailsVo.returnCarOption.desc }}</el-tag
                  >
                  <el-tag
                    v-if="row.carBillDetailsVo.repairOption.value"
                    size="small"
                    >{{ row.carBillDetailsVo.repairOption.desc }}</el-tag
                  >
                  <el-tag
                    v-if="row.carBillDetailsVo.supplementaryOrderOption.value"
                    size="small"
                    >{{
                      row.carBillDetailsVo.supplementaryOrderOption.desc
                    }}</el-tag
                  >
                  <el-tag
                    v-if="row.carBillDetailsVo.claimUnderWarrantyOption.desc"
                    size="small"
                    >{{
                      row.carBillDetailsVo.claimUnderWarrantyOption.desc
                    }}</el-tag
                  >
                  <el-tag
                    v-if="row.carBillDetailsVo.claimActivityOption.desc"
                    size="small"
                    >{{ row.carBillDetailsVo.claimActivityOption.desc }}</el-tag
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              label="接车员"
              prop="carBillDetailsVo.pickUpUserName"
            >
            </el-table-column>
            <el-table-column
              width="100"
              label="送修人"
              prop="carBillDetailsVo.contactName"
            >
            </el-table-column>
            <el-table-column
              width="120"
              label="送修手机号码"
              prop="carBillDetailsVo.contactPhone"
            >
            </el-table-column>
            <el-table-column
              width="160"
              label="进店时间"
              prop="carBillDetailsVo.dateCreated"
            >
            </el-table-column>
            <el-table-column width="160" label="完工时间" prop="finishTime">
            </el-table-column>
            <el-table-column width="160" label="评价时间" prop="dateCreated">
            </el-table-column>
            <el-table-column width="120" label="评价人手机号" prop="phone">
            </el-table-column>
            <el-table-column
              width="100"
              label="评价意见"
              prop="appraiseLevelStr"
            >
            </el-table-column>
            <el-table-column
              min-width="150"
              label="评价标签"
              prop="appraiseTags"
            >
            </el-table-column>
            <el-table-column
              min-width="100"
              label="其他"
              prop="appraiseSuggestion"
            >
            </el-table-column>
            <el-table-column width="120" label="消费客户" show-overflow-tooltip>
              <template #default="{ row }">
                <div v-if="row.consumer">
                  <div class="text-ellipsis">
                    {{ row.consumer.clientName }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120" label="签约客户" show-overflow-tooltip>
              <template #default="{ row }">
                <div v-if="row.owner">
                  <div class="text-ellipsis">
                    {{ row.owner.clientName }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template slot="footer">
        <pagination @pageChange="changePage" :total="total"></pagination>
      </template>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';

//接口
import {
  getAppraisList,
  exportAppraiseUrl,
  searchAppraiseTags,
} from '@/api/vehicleEvaluationRecord';

import { searchClient } from '@/api/customer/customer';
import { getUserList } from '@/api/employee';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';

// 工具
import Big from 'big.js';
import { mapState } from 'vuex';

export default {
  name: 'vehicleEvaluationRecord',
  components: {
    ExportDialog,
    operateCompanyCascader,
    pagination,
  },
  data() {
    return {
      form: {
        operateCompanyId: '',
      },
      appraisList: [],
      // 展示更多筛选
      showMore: false,
      //导出的页面设置
      startPage: 1,
      endPage: 1,
      exportUrl: exportAppraiseUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      searchAppraiseTagsLoading: false,
      searchAdminJobListUserLoading: false,
      searchClientLoading: false,
      adminJobListUserList: '',
      appraiseTagList: [],
      customList: [],
      appraiseList: [
        {
          level: 1,
          appraise: '不满意',
        },
        {
          level: 2,
          appraise: '还可以',
        },
        {
          level: 3,
          appraise: '很满意',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  methods: {
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        intoStoreStartTime: this.form.intoStoreTimes?.[0]
          ? this.form.intoStoreTimes?.[0]
          : '',
        intoStoreEndTime: this.form.intoStoreTimes?.[1]
          ? this.form.intoStoreTimes?.[1]
          : '',
        finishStartTime: this.form.finishTime?.[0]
          ? this.form.finishTime?.[0]
          : '',
        finishEndTime: this.form.finishTime?.[1]
          ? this.form.finishTime?.[1]
          : '',
        appraiseStartTime: this.form.appraiseTime?.[0]
          ? this.form.appraiseTime?.[0]
          : '',
        appraiseEndTime: this.form.appraiseTime?.[1]
          ? this.form.appraiseTime?.[1]
          : '',
      };
      var data = Object.assign(this.form, times, this.pageOption);
      getAppraisList(this.form).then((res) => {
        this.appraisList = res.records;
        this.total = res.total;
      });
    },
    getAppraiseTags(keyWork = '') {
      // console.log(11);
      // this.searchAppraiseTagsLoading = true;
      searchAppraiseTags(keyWork)
        .then((res) => {
          this.appraiseTagList = res || [];
        })
        .finally(() => {
          this.searchAppraiseTagsLoading = false;
        });
    },
    setCurrentKey(key) {},
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    // 置空输入框数据
    empty() {
      this.form = { paid: 2, intoStoreTimes: [], consumerMsg: '' };
      this.checkedLabels = [];
      this.checkedLabels = [];
      this.billGoods = [];
      this.billServices = [];
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },

    //远程搜索接车员列表
    adminJobUsrKeyWordChange(keyWord) {
      if (keyWord) {
        this.searchAdminJobListUser(keyWord);
      }
    },
    searchAdminJobListUser(keyWord = '') {
      this.searchAdminJobListUserLoading = true;
      getUserList(keyWord)
        .then((res) => {
          this.adminJobListUserList = res.records || [];
        })
        .finally(() => {
          this.searchAdminJobListUserLoading = false;
        });
    },
    //选中接车员
    selectAdminJobListUser() {},
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchClient(keyWord)
        .then((res) => {
          this.customList = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    selectClient(clientId) {},
    /**
     * 点击查询
     */
    async doSearch() {
      try {
        this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
  },
  created() {
    console.log(111111);
    // this.getAppraiseTags();
    console.log(this.$route.params.forms, 'this.$route.params.forms');
    // if (!this.$route.params.forms) {
    this.form.operateCompanyId = [this.companyId];
    this.getList();
    // }
  },
};
</script>
<style lang="scss" scoped>
//取消内容部分的内边距30px
// ::v-deep.base-container .main {
//   padding: 0px !important;
// }
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}

.choice-sty {
  width: 80px !important;
  height: 31px;
  background: rgba(66, 187, 184, 0.1);
  border-radius: 4px;
  font-size: 12px;
  color: #42bbb8;
  text-align: center;
}
.goods {
  background: rgba(85, 183, 142, 0.11);
  color: #55b78e;
}
.service {
  background: rgba(255, 146, 0, 0.1);
  color: #ff9200;
}

.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 10px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    text-align: center;
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
    p:nth-child(2) {
      font-size: 14px;
      margin-top: 3px;
    }
  }
  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }
}
.bill-dialog {
  color: #333;
  font-size: 20px span {
    font-size: 14px;
    color: #999;
  }
}
.billLabel {
  height: 440px;
  overflow-y: scroll;
}
.dialog-footer {
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  height: 109px;
  margin-top: 40px;
  line-height: 109px;
  button {
    height: 30px;
  }
}
.artisan-list {
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  .label {
    margin-top: 10px;
    margin-right: 15px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #eee;
    cursor: pointer;
    &.active {
      background: $bg;
      color: $primary;
    }
  }
}
.icon-sty {
  position: absolute;
  top: -8px;
  right: 0px;
}
//状态与统计的样式
.class-box {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .title {
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    background: #fafafa;
    color: #888;
    font-size: 14px;
  }
}
.tree-node {
  color: #888;
  font-size: 14px;
}
.tags {
  .el-tag + .el-tag {
    margin-left: 5px;
  }
}
</style>
