<template>
  <base-container
    ref="baseContainer"
    customStyle="background: #f1f7fa;"
    customMainStyle="margin-bottom: 16px;"
    :defaultPadding="false"
  >
    <template>
      <el-form
        style="background: #fff"
        class="filter-form"
        label-width="100px"
        size="small"
        @submit.native.prevent
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="项目名称">
              <el-input
                v-model="serviceName"
                placeholder="请输入项目名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div class="container">
        <div class="left">
          <ul style="margin-top: 16px" class="car-brand-tree_ul">
            <div class="title">品牌车型</div>
            <li
              class="car-brand-item_li"
              v-for="(lv1, index) in treeData"
              :key="index"
            >
              <div
                class="car-brand-name_div"
                :class="{
                  'has-children': lv1.series.length,
                }"
                @click="handleLv1Click(lv1)"
              >
                <img style="margin-right: 5px" :src="lv1.logoImage" />
                <span class="car-brand-name_text">
                  {{ lv1.logoName }}
                  ({{ lv1.series.length }})
                </span>

                <i
                  v-show="lv1.series"
                  class="item-expand_icon newicon grow-icon-arrow-right"
                  :class="{ active: lv1.showChild }"
                ></i>
              </div>
              <ul class="tree-item_ul" v-show="lv1.showChild">
                <li
                  v-for="(lv2, index) in lv1.series"
                  :key="index"
                  class="tree-item_li"
                >
                  <div
                    :class="{
                      'tree-item-name_div': true,
                      active: lv2ActiveId == lv2.seriesId,
                    }"
                    @click="handleLv2Click(lv2)"
                  >
                    <span>
                      {{ lv2.seriesName }}
                    </span>
                    <i
                      v-show="$lodash.get(lv2.modelNumbers, 'length', 0)"
                      class="item-expand_icon newicon grow-icon-arrow-right"
                      :class="{ active: lv2.showChild }"
                    ></i>
                  </div>
                  <ul class="tree-item_ul" v-show="lv2.showChild">
                    <li
                      v-for="(lv3, index) in lv2.modelNumbers"
                      :key="index"
                      class="tree-item_li"
                    >
                      <div
                        :class="{
                          'tree-item-name_div': true,
                          active: lv3ActiveId == lv3.modelNumberId,
                        }"
                        @click="handleLv3Click(lv3)"
                      >
                        <span>
                          {{ lv3.modelNumberName }}
                        </span>
                        <i
                          v-show="$lodash.get(lv3.modelYears, 'length', 0)"
                          class="item-expand_icon newicon grow-icon-arrow-right"
                          :class="{ active: lv3.showChild }"
                        ></i>
                      </div>
                      <ul class="tree-item_ul" v-show="lv3.showChild">
                        <li
                          v-for="(lv4, index) in lv3.modelYears"
                          :key="index"
                          class="tree-item_li"
                        >
                          <div
                            :class="{
                              'tree-item-name_div': true,
                              'last-level': true,
                              active: lv4ActiveId == lv4.modelYearId,
                            }"
                            @click="handleLv4Click(lv4)"
                          >
                            {{ lv4.modelYearName }}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="right">
          <div class="right-content">
            <div style="margin-bottom: 16px" class="flex-x-between">
              <div>
                <el-button
                  type="primary"
                  size="small"
                  @click="importDialogVisibility = true"
                  v-auth="581"
                  >导入</el-button
                >
              </div>
              <!--
              <el-button
                v-auth="283"
                size="small"
                type="text"
                class="blue"
                @click="showExportDialog = true"
                >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
              ></el-button> -->
            </div>

            <el-table :data="listData" border>
              <el-table-column type="index" label="序号" width="60">
              </el-table-column>
              <!-- <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                  <span
                    class="text_operation blue"
                    @click="handleEdit(scope.row)"
                    >编辑</span
                  >
                </template>
              </el-table-column> -->
              <el-table-column
                min-width="125"
                label="服务项目"
                prop="serviceName"
              ></el-table-column>
              <el-table-column
                width="120"
                label="服务时效（H）"
                prop="serviceTime"
              ></el-table-column>
              <el-table-column
                width="100"
                label="工时报价"
                prop="servicePrice"
              ></el-table-column>
              <el-table-column
                min-width="170"
                label="商品标准名"
                prop="standardName"
              ></el-table-column>
              <el-table-column
                min-width="100"
                label="原厂编码"
                prop="goodsCode"
              ></el-table-column>
              <el-table-column
                width="80"
                label="数量"
                prop="num"
                align="center"
              ></el-table-column>
              <el-table-column width="200" label="商品成本价" align="center">
                <el-table-column
                  width="100"
                  label="品牌"
                  prop="brandCost"
                  align="center"
                ></el-table-column>
                <el-table-column
                  width="100"
                  label="原厂"
                  prop="originalCost"
                  align="center"
                ></el-table-column>
                <el-table-column
                  v-if="showOfficialCost"
                  width="100"
                  label="正厂"
                  prop="officialCost"
                  align="center"
                ></el-table-column>
              </el-table-column>
            </el-table>
          </div>
          <pagination
            class="pagination-box"
            @pageChange="changePage"
            :total="total"
          ></pagination>
        </div>
      </div>

      <!-- <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog> -->
    </template>

    <!-- <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template> -->
    <!-- 导入弹窗 -->
    <import-dialog
      v-if="importDialogVisibility"
      :visibility.sync="importDialogVisibility"
      title="常用报价模板导入"
      templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20231204/%E5%B8%B8%E7%94%A8%E6%8A%A5%E4%BB%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
      :uploadFileApi="usualQuoteImportApi"
      :getLastResultApi="usualQuoteImportResultApi"
      :importFailDetail="usualQuoteImportMessageExportUrlApi"
      :disabled="false"
    ></import-dialog>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
// import ExportDialog from '@/components/ExportDialog';
// import editGoodsAliasDialog from './components/editGoodsAliasDialog';
// import selectGoodsClassCascader from '@/components/businessComponent/selectGoodsClassCascader.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';

// 接口
import { getCarModelTree, getUsualQuoteList } from '@/api/customer/usualQuote';
import {
  usualQuoteImport,
  usualQuoteImportResult,
  usualQuoteImportMessageExportUrl,
} from '@/api/system/dataImport';

// 字典数据
// import { enableState } from '@/service/dict/dictData';

import { mapState } from 'vuex';

export default {
  name: 'usualQuote',
  components: {
    // editGoodsAliasDialog,
    // selectGoodsClassCascader,
    pagination,
    importDialog,
    // ExportDialog
  },
  data() {
    return {
      // 导入弹窗可见性
      importDialogVisibility: false,

      usualQuoteImportApi: usualQuoteImport,
      usualQuoteImportMessageExportUrlApi: usualQuoteImportMessageExportUrl,
      usualQuoteImportResultApi: usualQuoteImportResult,

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // showEditDialog: false,
      // showExportDialog: false,
      // startPage: 1,
      // endPage: 1,
      // exportUrl: 'exportListUrl',

      serviceName: '',

      // 列表数据
      listData: [],

      treeData: [
        // {
        //   logoId: 250,
        //   logoName: '埃安',
        //   image:
        //     'https://growcny-pic.oss-cn-shenzhen.aliyuncs.com/car/aian.png',
        //   series: [
        //     {
        //       seriesId: 5548,
        //       seriesName: '广汽乘用车 Aion S',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 1,
        //           modelNumberName: '炫 580',
        //           modelYears: [
        //             {
        //               modelYearId: 2323,
        //               modelYearName: '2023',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 251,
        //   logoName: '星驰',
        //   image: 'http://www.realscloud.com/carlogo/88110.png',
        //   series: [
        //     {
        //       seriesId: 5549,
        //       seriesName: '星驰SUV',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 2,
        //           modelNumberName: '风翼',
        //           modelYears: [
        //             {
        //               modelYearId: 2324,
        //               modelYearName: '2024',
        //             },
        //             {
        //               modelYearId: 2325,
        //               modelYearName: '2025',
        //             },
        //           ],
        //         },
        //         {
        //           modelNumberId: 3,
        //           modelNumberName: '星光',
        //           modelYears: [
        //             {
        //               modelYearId: 2324,
        //               modelYearName: '2024',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 252,
        //   logoName: '优尼柯',
        //   image: 'http://www.realscloud.com/carlogo/chuanqi.png',
        //   series: [
        //     {
        //       seriesId: 5550,
        //       seriesName: '优尼柯轿车',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 4,
        //           modelNumberName: '雅致',
        //           modelYears: [
        //             {
        //               modelYearId: 2323,
        //               modelYearName: '2023',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 253,
        //   logoName: '极星',
        //   image: 'http://www.realscloud.com/carlogo/fengshen.png',
        //   series: [
        //     {
        //       seriesId: 5551,
        //       seriesName: '极星电动',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 5,
        //           modelNumberName: '星动',
        //           modelYears: [
        //             {
        //               modelYearId: 2325,
        //               modelYearName: '2025',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   logoId: 254,
        //   logoName: '北汽新能源',
        //   image:
        //     'https://growcny-pic.oss-cn-shenzhen.aliyuncs.com/car/%E5%8C%97%E6%B1%BD%E6%96%B0%E8%83%BD%E6%BA%90.png',
        //   series: [
        //     {
        //       seriesId: 5552,
        //       seriesName: '北汽新能源E系列',
        //       modelNumbers: [
        //         {
        //           modelNumberId: 6,
        //           modelNumberName: 'E150',
        //           modelYears: [
        //             {
        //               modelYearId: 2324,
        //               modelYearName: '2024',
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      lv1ActiveId: '',
      lv2ActiveId: '',
      lv3ActiveId: '',
      lv4ActiveId: '',

      showOfficialCost: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  activated() {},
  created() {
    this.getData();
    this.getTreeData();
  },
  methods: {
    getTreeData() {
      getCarModelTree().then((res) => {
        console.log(res);
        res.forEach((logo) => {
          logo.series?.forEach((series) => {
            series.logoId = logo.logoId;

            series.modelNumbers?.forEach((model) => {
              model.logoId = logo.logoId;
              model.seriesId = series.seriesId;

              model.modelYears?.forEach((year) => {
                year.logoId = logo.logoId;
                year.seriesId = series.seriesId;
                year.modelNumberId = model.modelNumberId;
              });
            });
          });
        });
        this.treeData = res || [];
      });
    },
    /**
     * 点击一级
     */
    handleLv1Click(item) {
      if (item.series?.length && !item.showChild) {
        this.$set(item, 'showChild', true);
      } else {
        this.$set(item, 'showChild', false);
      }
    },
    /**
     * 点击二级
     */
    handleLv2Click(item) {
      if (item.modelNumbers?.length && !item.showChild) {
        this.$set(item, 'showChild', true);
        this.lv1ActiveId = item.logoId;
        this.lv2ActiveId = item.seriesId;
      } else {
        this.$set(item, 'showChild', false);
        this.lv2ActiveId = '';
      }
      this.lv3ActiveId = '';
      this.lv4ActiveId = '';
    },
    /**
     * 点击三级
     */
    handleLv3Click(item) {
      if (item.modelYears?.length && !item.showChild) {
        this.$set(item, 'showChild', true);
        this.lv1ActiveId = item.logoId;
        this.lv2ActiveId = item.seriesId;
        this.lv3ActiveId = item.modelNumberId;
      } else {
        this.$set(item, 'showChild', false);
        this.lv3ActiveId = '';
      }
      this.lv4ActiveId = '';
    },
    /**
     * 点击四级
     */
    handleLv4Click(item) {
      this.lv1ActiveId = item.logoId;
      this.lv2ActiveId = item.seriesId;
      this.lv3ActiveId = item.modelNumberId;
      this.lv4ActiveId = item.modelYearId;
      this.getData();
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign({}, this.form, this.pageOption);
      if (this.serviceName) {
        data.serviceName = this.serviceName;
      }
      if (this.lv4ActiveId) {
        data.modelYearId = this.lv4ActiveId;
      }
      getUsualQuoteList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;

        this.showOfficialCost = res.records.some((el) => el.officialCost);
      });
    },

    /**
     * 新增
     */
    // add() {
    //   this.editId = '';
    //   this.$nextTick().then(() => {
    //     this.showEditDialog = true;
    //   });
    // },

    /**
     * 编辑
     */
    handleEdit(row) {
      this.editId = row.id;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.serviceName = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}
.container {
  width: 100%;
  height: calc(100% - 89px);
  display: flex;
  padding-top: 16px;
  // > div {

  // }
  .left {
    width: 259px;
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    overflow: auto;
    .car-brand-tree_ul {
      margin-top: 16px;
      flex: 1;
      overflow: auto;
      .title {
        height: 52px;
        background: #f5f7fa;
        border-radius: 4px;
        margin-bottom: 8px;
        padding: 16px;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #242526;
        line-height: 20px;
        user-select: none;
      }
      .car-brand-item_li {
        position: relative;
        .car-brand-name_div {
          height: 37px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          color: #242526;
          &.active .car-brand-name_text {
            color: $primary;
          }
          &:hover {
            &:not(.has-children) {
              background: rgba(51, 171, 121, 0.1);
            }
            .car-brand-name_text,
            .item-expand_icon {
              color: $primary;
            }
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        & + .car-brand-item_li {
          margin-top: 2px;
        }

        .car-brand-name_text {
          color: #242526;
          font-size: 14px;
          font-weight: 600;
        }
        .item-expand_icon {
          position: absolute;
          right: 8px;
          font-size: 14px;
          color: #606266;
          transition: 0.2s;
          transform: rotate(0deg);
          &.active {
            transform: rotate(90deg);
          }
        }

        .tree-item_ul {
          margin-left: 15px;
          padding-top: 6px;
          // position: absolute;
          .tree-item_li {
            // height: 32px;
            // display: flex;
            // align-items: center;
            // flex-direction: column;

            box-sizing: border-box;
            cursor: pointer;
            color: #3c3d40;
            font-size: 14px;
            font-weight: 400;
            & + .tree-item_li {
              margin-top: 2px;
            }
            .tree-item-name_div {
              position: relative;
              padding: 8px 8px 8px 20px;
              border-radius: 2px;
              &.active,
              &:hover {
                color: $primary;
              }
              &:hover,
              &.active.last-level {
                background: rgba(51, 171, 121, 0.1);
              }
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    background: #fff;
    margin-left: 16px;
    border-radius: 4px;
    position: relative;
    .right-content {
      flex: 1;
      // height: 100%;
      // margin-left: 16px;
      // padding: 8px;
      // background: #fff;
      // min-width: 0;
      padding-bottom: calc(4vh + 32px);
      overflow: auto;
    }
    .pagination-box {
      width: calc(100% - 48px);
      background: #fff;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      text-align: center;
      z-index: 2;
    }
  }
}
</style>
