var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c(
        "div",
        { staticClass: "sidebar-component" },
        [
          _c(
            "div",
            { staticClass: "sidebar-container", class: { lock: _vm.locked } },
            [
              _c("img", {
                staticClass: "sidebar-logo_img",
                attrs: {
                  src: require("@/assets/img/logo.svg"),
                  alt: "格悦新能源",
                },
              }),
              _c(
                "ul",
                { staticClass: "sidebar-menu-first_ul" },
                _vm._l(_vm.menus, function (firstMenu, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: {
                            code: firstMenu.permission,
                            additionalConditions:
                              !firstMenu.onlyHeadquarters || _vm.isHeadquarters,
                          },
                          expression:
                            "{\n          code: firstMenu.permission,\n          additionalConditions: !firstMenu.onlyHeadquarters || isHeadquarters,\n        }",
                        },
                      ],
                      key: index,
                      class: {
                        "sidebar-menu-first_li": true,
                        active: firstMenu.title === _vm.selectedFirstMenu.title,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleFirstMenuClick(firstMenu)
                        },
                        mouseenter: function ($event) {
                          return _vm.handleFirstMenuMouseenter(firstMenu)
                        },
                        mouseleave: function ($event) {
                          return _vm.handleFirstMenuMouseleave(firstMenu)
                        },
                      },
                    },
                    [
                      _c("i", {
                        class: [
                          "sidebar-menu-first_icon",
                          "newicon",
                          firstMenu.icon,
                        ],
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.locked,
                              expression: "!locked",
                            },
                          ],
                          staticClass: "sidebar-menu-first_text",
                        },
                        [_vm._v(_vm._s(firstMenu.title))]
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.locked && firstMenu.children,
                            expression: "!locked && firstMenu.children",
                          },
                        ],
                        staticClass:
                          "sidebar-menu-expand_icon newicon grow-icon-arrow-right",
                      }),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "footer" }, [
                _c(
                  "div",
                  { staticClass: "untreated", on: { click: _vm.goUntreated } },
                  [
                    _c("img", {
                      staticClass: "untreated-icon",
                      attrs: { src: require("@/assets/img/untreated.png") },
                    }),
                    _vm.num > 0
                      ? _c(
                          "el-badge",
                          {
                            staticClass: "untreated-item",
                            attrs: { value: _vm.num, max: 99 },
                          },
                          [_c("div", [_vm._v("待办")])]
                        )
                      : _vm._e(),
                    _vm.num == 0
                      ? _c("div", { staticClass: "untreated-item" }, [
                          _vm._v("待办"),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "store-name" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticStyle: {
                          flex: "1",
                          height: "100%",
                          display: "flex",
                          "align-item": "center",
                        },
                        attrs: { trigger: "click", placement: "top-end" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              flex: "1",
                              height: "100%",
                              display: "flex",
                              "align-item": "center",
                            },
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.locked,
                                  expression: "locked",
                                },
                              ],
                              staticClass: "store-icon newicon grow-icon-store",
                            }),
                            _c("div", { staticClass: "flex-y-center" }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.locked,
                                      expression: "!locked",
                                    },
                                  ],
                                  staticClass: "store-name_text",
                                  attrs: { title: _vm.companyName },
                                },
                                [_vm._v(_vm._s(_vm.companyName))]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                clearable: "",
                                placeholder: "请输入车店名称",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.getCompanyList(true, false, false)
                                },
                              },
                              model: {
                                value: _vm.keyName,
                                callback: function ($$v) {
                                  _vm.keyName = $$v
                                },
                                expression: "keyName",
                              },
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading",
                                  },
                                ],
                                staticClass: "company-list",
                              },
                              [
                                _vm._l(_vm.companyList, function (item, index) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: index,
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.switchCompany(item, true)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$lodash.get(
                                            item,
                                            "company[0].companyName",
                                            ""
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                }),
                                _c(
                                  "div",
                                  { staticClass: "flex-center" },
                                  [
                                    _vm.hasMore
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getCompanyList(
                                                  false,
                                                  true,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("加载更多")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.locked,
                          expression: "!locked",
                        },
                      ],
                      staticClass:
                        "store-name-expand_icon newicon grow-icon-arrow-right",
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "user-info" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticStyle: { flex: "1" },
                        attrs: { trigger: "click" },
                        on: { command: _vm.handleCommand },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-y-center",
                            attrs: { title: _vm.userInfo.userName },
                          },
                          [
                            _c("img", {
                              staticClass: "user-avatar",
                              attrs: {
                                src: require("@/assets/img/default-avatar.png"),
                              },
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.locked,
                                    expression: "!locked",
                                  },
                                ],
                                staticClass: "user-name_text",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.userInfo.realName) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "editUser" } },
                              [_vm._v("个人信息")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "changePassword" } },
                              [_vm._v("修改密码")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "logout" } },
                              [_vm._v("退出登录")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.locked,
                          expression: "!locked",
                        },
                      ],
                      staticClass:
                        "user-name-expand_icon newicon grow-icon-arrow-right",
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "lock" },
                  [
                    _vm.locked
                      ? _c("i", {
                          staticClass: "newicon grow-icon-locked",
                          staticStyle: { "padding-bottom": "10px" },
                          on: {
                            click: function ($event) {
                              _vm.locked = false
                            },
                          },
                        })
                      : _c("el-divider", [
                          _c("i", {
                            staticClass: "newicon grow-icon-unlock",
                            on: {
                              click: function ($event) {
                                _vm.locked = true
                              },
                            },
                          }),
                        ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSecondMenus,
                  expression: "showSecondMenus",
                },
              ],
              staticClass: "sidebar-menu-second_container",
              class: { lock: _vm.locked },
              on: {
                mouseenter: _vm.handleSecondMenuMouseenter,
                mouseleave: _vm.handleFirstMenuMouseleave,
              },
            },
            [
              _c("div", { staticClass: "top-icon" }, [
                _c("i", { class: ["newicon", _vm.selectedFirstMenu.icon] }),
              ]),
              _c(
                "ul",
                { staticClass: "sidebar-menu-second_ul" },
                _vm._l(
                  _vm.selectedFirstMenu.children,
                  function (secondMenu, index) {
                    return _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "auth",
                            rawName: "v-auth",
                            value: {
                              code: secondMenu.permission,
                              additionalConditions:
                                !secondMenu.onlyHeadquarters ||
                                _vm.isHeadquarters,
                            },
                            expression:
                              "{\n          code: secondMenu.permission,\n          additionalConditions:\n            !secondMenu.onlyHeadquarters || isHeadquarters,\n        }",
                          },
                        ],
                        key: index,
                        staticClass: "sidebar-menu-second_li",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-menu-second_btn",
                            class: {
                              "has-children": secondMenu.children,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSecondMenuClick(secondMenu)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "sidebar-menu-second_text" },
                              [_vm._v(_vm._s(secondMenu.title))]
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: secondMenu.children,
                                  expression: "secondMenu.children",
                                },
                              ],
                              staticClass:
                                "sidebar-menu-expand_icon newicon grow-icon-arrow-right",
                              class: { active: secondMenu.showChild },
                            }),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: secondMenu.showChild,
                                expression: "secondMenu.showChild",
                              },
                            ],
                            staticClass: "sidebar-menu-third_ul",
                          },
                          _vm._l(
                            secondMenu.children,
                            function (thirdMenu, index) {
                              return _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth",
                                      value: {
                                        code: thirdMenu.permission,
                                        additionalConditions:
                                          !thirdMenu.onlyHeadquarters ||
                                          _vm.isHeadquarters,
                                      },
                                      expression:
                                        "{\n              code: thirdMenu.permission,\n              additionalConditions:\n                !thirdMenu.onlyHeadquarters || isHeadquarters,\n            }",
                                    },
                                  ],
                                  key: index,
                                  staticClass: "sidebar-menu-third_li",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleThirdMenuClick(thirdMenu)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(thirdMenu.title) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ]
          ),
          _vm.dueSoonPassword
            ? _c("div", { staticClass: "due-soon-password-warning-dialog" }, [
                _c("p", { staticClass: "color626" }, [
                  _vm._v("为了你的信息安全，请尽快修改密码，并注意保管"),
                ]),
                _c("p", { staticClass: "color626" }, [
                  _vm._v(
                    "如不修改，系统将于" +
                      _vm._s(_vm.passwordDueTime) +
                      "不允许登录"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "btns" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.dueSoonPassword = false
                          },
                        },
                      },
                      [_vm._v("暂不提示")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleChangePassword },
                      },
                      [_vm._v("修改密码")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.changePasswordDialogVisibility
            ? _c("change-password-dialog", {
                attrs: {
                  visibility: _vm.changePasswordDialogVisibility,
                  needOldPassword: true,
                  phoneNumber: _vm.userInfo.phone,
                },
                on: {
                  "update:visibility": function ($event) {
                    _vm.changePasswordDialogVisibility = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }