export const yesno = [
  { label: '是', value: 1, sort: 0 },
  { label: '否', value: 0, sort: 1 },
];

export const gender = [
  { label: '男', value: 0, sort: 0 },
  { label: '女', value: 1, sort: 1 },
];

// 开放权限
export const openPermiss = [
  { label: '自费/包干', value: 0, sort: 0 },
  { label: '他人自费', value: 1, sort: 1 },
  { label: '保险理赔', value: 2, sort: 2 },
  // { label: '三包索赔', value: 3, sort: 3 },
];

// 年审车辆类型
export const annualSurveyCarType = [
  { label: '非营运', value: 0, sort: 0 },
  { label: '货运', value: 1, sort: 1 },
  { label: '营运', value: 2, sort: 2 },
  // { label: '摩托车', value: 3, sort: 3 },
  // { label: '拖拉机和其他机动车', value: 4, sort: 4 },
];

// 机动车种类
export const carType = [
  { label: '轿车', value: 0, sort: 0 },
  { label: 'MPV', value: 1, sort: 1 },
  { label: 'SUV', value: 2, sort: 2 },
  { label: '轿跑车', value: 3, sort: 3 },
  { label: '跑车', value: 4, sort: 4 },
  { label: '越野车', value: 5, sort: 5 },
  { label: '敞篷SUV', value: 6, sort: 6 },
  { label: '敞篷轿车', value: 7, sort: 7 },
  { label: '敞篷轿跑车', value: 8, sort: 8 },
  { label: '敞篷跑车', value: 9, sort: 9 },
  { label: '掀背轿车', value: 10, sort: 10 },
  { label: '掀背轿跑车', value: 11, sort: 11 },
  { label: '掀背跑车', value: 12, sort: 12 },
  { label: '皮卡', value: 13, sort: 13 },
  { label: '面包车', value: 14, sort: 14 },
  { label: '客车', value: 15, sort: 15 },
  { label: '旅行车', value: 16, sort: 16 },
  { label: '房车', value: 17, sort: 17 },
  { label: '货车', value: 18, sort: 18 },
  { label: '其他车种', value: 19, sort: 19 },
];

// 燃料类型
export const fuelType = [
  { label: '汽油', value: 0, sort: 0 },
  { label: '油电混合', value: 1, sort: 1 },
  { label: '柴油', value: 2, sort: 2 },
  { label: '电力', value: 3, sort: 3 },
  { label: '增程式电动', value: 4, sort: 4 },
  { label: '插电式混动', value: 5, sort: 5 },
  { label: '柴油油电混合', value: 6, sort: 6 },
  { label: '汽油/液化石油气(LPG)', value: 7, sort: 7 },
  { label: '汽油/压缩天然气(CNG)', value: 8, sort: 8 },
  { label: '液化石油气(LPG)', value: 9, sort: 9 },
  { label: '压缩天然气(CNG)', value: 10, sort: 10 },
  { label: '汽油/乙醇', value: 11, sort: 11 },
  { label: '汽油/甲醇', value: 12, sort: 12 },
];

// 连锁类型
export const chainCompanyType = [
  { label: '直营店', value: 0, sort: 0 },
  { label: '加盟店', value: 1, sort: 1 },
];

// 服务类型
export const serviceType = [
  { label: '商品', value: 0, sort: 0 },
  { label: '服务', value: 1, sort: 1 },
];

// 接车单 开单 施工类型
export const difficulty = [
  { label: '快修快保', value: 0, sort: 0 },
  { label: '一般故障', value: 1, sort: 1 },
  { label: '疑难故障', value: 2, sort: 2 },
  { label: '退车整备', value: 3, sort: 1 },
  { label: '其他', value: 4, sort: 2 },
];

// 接车单 开单 理赔 事故责任(0: 全责，1: 无责，2: 主责，3: 次责，4: 同责)
export const dutyType = [
  { label: '全责', value: 0, sort: 0 },
  { label: '无责', value: 1, sort: 1 },
  { label: '主责', value: 2, sort: 2 },
  { label: '次责', value: 3, sort: 3 },
  { label: '同责', value: 4, sort: 4 },
];

// 接车单 开单 剩余能源占比 电表(油表)
export const energyPercentage = [
  { label: '0%', value: 0, sort: 0 },
  { label: '20%', value: 1, sort: 1 },
  { label: '40%', value: 2, sort: 2 },
  { label: '60%', value: 3, sort: 3 },
  { label: '80%', value: 4, sort: 4 },
  { label: '100%', value: 5, sort: 5 },
];

// 商品 品质
export const goodsType = [
  { label: '原厂', value: 0, sort: 0 },
  { label: '品牌', value: 1, sort: 1 },
];

export const goodsManufactoryType = [
  { label: '品牌', value: 0, sort: 0 },
  { label: '原厂', value: 1, sort: 0 },
  { label: '正厂', value: 2, sort: 0 },
  { label: '修复', value: 3, sort: 0 },
  { label: '拆车', value: 4, sort: 0 },
  { label: '旧件', value: 5, sort: 0 },
];

// 应收账款-包干分摊批次状态
export const contractState = [
  { label: '待处理', value: 0, sort: 0 },
  { label: '成功', value: 1, sort: 1 },
  { label: '失败', value: 2, sort: 2 },
];

//应收账款-销账收款记录中的客户类型
export const clientType = [
  { label: '普通', value: 0, sort: 0 },
  { label: '包干', value: 1, sort: 1 },
  { label: '保险', value: 2, sort: 2 },
  { label: '三包', value: 3, sort: 3 },
];

// 应收账款-应收账款汇总中的客户类型
export const consumerType = [
  { label: '自费', value: 0, sort: 0 },
  { label: '包干', value: 1, sort: 1 },
  { label: '保险公司', value: 2, sort: 2 },
  { label: '三包公司', value: 3, sort: 3 },
];

// 车单类型
export const carBillType = [
  { label: '自费', value: 0, sort: 0 },
  { label: '包干', value: 1, sort: 1 },
  { label: '理赔', value: 2, sort: 2 },
  { label: '索赔', value: 3, sort: 3 },
];

// 车单状态
export const carBillState = [
  { label: '服务中', value: 0, sort: 0 },
  { label: '已结账', value: 1, sort: 1 },
  { label: '待结账', value: 2, sort: 2 },
  { label: '已取消', value: 3, sort: 3 },
  { label: '挂单中', value: 4, sort: 4 },
  { label: '待结账', value: 5, sort: 5 },
];

// 车单状态 - 对应图标
export const carBillStateIcon = [
  { label: 'grow-state_serve', value: 0, sort: 0, color: '#45B4E2' },
  { label: 'grow-a-state_settleaccounts', value: 1, sort: 1, color: '#45B4E2' },
  {
    label: 'grow-a-state_settleaccounts2',
    value: 2,
    sort: 2,
    color: ' #ff9200',
  },
  { label: 'grow-state_cancel', value: 3, sort: 3, color: '#D5D5D5' },
  { label: 'grow-a-state_pendingorder', value: 4, sort: 4, color: '#90D7D6' },
  { label: 'grow-a-state_pendingorder', value: 5, sort: 5, color: '#45B4E2' },
  // { label: 'grow-a-state_settleaccounts', value: 6, sort: 6 ,color:'#5DBB93'},
];

// 自费类型
export const isOthersSelfPayState = [
  { label: '车主自费', value: 0, sort: 0 },
  { label: '他人自费', value: 1, sort: 1 },
];

// 接车单-定损单表格列表-定损状态
export const lossDeterminationState = [
  { label: '待报损', value: 0, sort: 0 },
  { label: '待出定损', value: 1, sort: 1 },
  { label: '已出定损', value: 2, sort: 2 },
  { label: '已回款', value: 3, sort: 3 },
  { label: '待拍照对件', value: 4, sort: 4 },
  { label: '已提资料', value: 5, sort: 5 },
  { label: '已取消', value: 6, sort: 6 },
  { label: '待调度', value: 7, sort: 7 },
  { label: '待提定损', value: 8, sort: 8 },
  { label: '待复勘', value: 30, sort: 9 },
  { label: '待收旧', value: 31, sort: 10 },
  { label: '部分回款', value: 3100, sort: 9 },
  { label: '全部回款', value: 3101, sort: 10 },
];

// 接车单-定损单表格列表-检车状态
export const detectionState = [
  { label: '待派检', value: 0, sort: 0 },
  { label: '派检中', value: 1, sort: 1 },
  { label: '已检车', value: 2, sort: 2 },
];

// 搜索商品抽屉 - 库存状态
export const inventoryState = [
  { label: '全部库存', value: 0, sort: 0 },
  { label: '库存大于0', value: 1, sort: 1 },
  { label: '库存小于等于0', value: 2, sort: 2 },
];

// 车单 - 材料单 - 领料状态
export const materialGoodsState = [
  { label: '待领料', value: 0, sort: 0 },
  { label: '待入库', value: 1, sort: 1 },
  { label: '已领料', value: 2, sort: 2 },
];

// 车单施工状态
export const dispatchState =
  // [
  //   { label: '施工中', value: 0, sort: 0 },
  //   { label: '车间完工', value: 1, sort: 1 },
  //   { label: '已取消', value: 2, sort: 2 },
  //   // { label: '未施工', value: 3, sort: 3 },
  //   { label: '待派工', value: 4, sort: 4 },
  //   { label: '质检中', value: 5, sort: 5 },
  //   { label: '免施工', value: 6, sort: 6 },
  //   { label: '未施工', value: 7, sort: 7 },
  //   { label: '终检中', value: 8, sort: 8 },
  // ];
  [
    { label: '待施工', value: 0, sort: 0 },
    { label: '已完工', value: 1, sort: 1 },
    { label: '已取消', value: 2, sort: 2 },
    { label: '待施工', value: 3, sort: 3 },
    { label: '待派工', value: 4, sort: 4 },
    { label: '施工中', value: 5, sort: 5 },
    { label: '免施工', value: 6, sort: 6 },
    { label: '待派工', value: 7, sort: 7 },
  ];

// 服务项施工状态
export const dispatchTaskState =
  // [
  //   { label: '待派工', value: 0, sort: 0 },
  //   { label: '施工中', value: 1, sort: 1 },
  //   { label: '施工中', value: 2, sort: 2 },
  //   { label: '已完工', value: 3, sort: 3 },
  //   { label: '已完工', value: 4, sort: 4 },
  //   { label: '未施工', value: 5, sort: 5 },
  //   { label: '免施工', value: 6, sort: 6 },
  // ];

  [
    { label: '待派工', value: 0, sort: 0 },
    { label: '待施工', value: 1, sort: 1 },
    { label: '待施工', value: 2, sort: 2 },
    { label: '施工中', value: 3, sort: 3 },
    { label: '已完工', value: 4, sort: 4 },
    { label: '待派工', value: 5, sort: 5 },
    { label: '免施工', value: 6, sort: 6 },
  ];

// 车辆状态
export const carState = [
  { label: '包干中', value: 0, sort: 0 },
  { label: '未包干', value: 1, sort: 1 },
  { label: '白名单', value: 2, sort: 2 },
];

// 投保公司
export const insuranceCompany = [
  { label: '商业险', value: 1, sort: 1 },
  { label: '交强险', value: 2, sort: 2 },
  { label: '承运险', value: 3, sort: 3 },
];

// 收旧情况
export const recoveryState = [
  // { label: '无', value: 0, sort: 0 },
  { label: '不用回收', value: 1, sort: 1 },
  { label: '待回收', value: 2, sort: 2 },
  { label: '已回收', value: 3, sort: 3 },
];

// 复勘情况
export const investigationState = [
  // { label: '无', value: 0, sort: 0 },
  { label: '不用复勘', value: 1, sort: 1 },
  { label: '待复勘', value: 2, sort: 2 },
  { label: '已复勘', value: 3, sort: 3 },
];

// 提醒
export const remind = [
  { label: '无', value: 0, sort: 0 },
  { label: '提报超期', value: 1, sort: 1 },
  { label: '对件超期', value: 2, sort: 2 },
  { label: '出损超期', value: 3, sort: 3 },
  { label: '回款超期', value: 4, sort: 4 },
  { label: '调度超期', value: 5, sort: 5 },
  { label: '定损超期', value: 6, sort: 6 },
  { label: '复勘超期', value: 7, sort: 7 },
  { label: '收旧超期', value: 8, sort: 8 },
];

// 客户等级
export const clientLevel = [
  { label: 'A', value: 0, sort: 0 },
  { label: 'B', value: 1, sort: 1 },
  { label: 'C', value: 2, sort: 2 },
  { label: '无等级', value: 3, sort: 3 },
];

// 车辆档案 - 在保状态
export const underWarrantyState = [
  { label: '未知（未能判断状态）', value: 0, sort: 0 },
  { label: '过保', value: 1, sort: 1 },
  { label: '易损件质保', value: 2, sort: 2 },
  { label: '整车质保', value: 3, sort: 3 },
  // { label: '核心件质保', value: 4, sort: 4 },
  { label: '三电质保', value: 4, sort: 4 },
  { label: '动力电池质保', value: 5, sort: 5 },
  { label: '电芯质保', value: 6, sort: 6 },
];

// 车辆档案 - 在租状态
export const underLeaseState = [
  { label: '在租', value: 0, sort: 0 },
  { label: '停运', value: 1, sort: 1 },
];

// 车单状态（筛选条件中不存在5值）
export const carLossBillState = [
  { label: '服务中', value: 0, sort: 0 },
  { label: '挂单中', value: 4, sort: 1 },
  { label: '待结账', value: 2, sort: 2 },
  { label: '已结账', value: 1, sort: 3 },
  { label: '已取消', value: 3, sort: 4 },
];

// 岗位
export const position = [
  { label: '车管对接', value: 0, sort: 0 },
  { label: '维保对接', value: 1, sort: 1 },
  { label: '事故对接', value: 2, sort: 2 },
  { label: '整备对接', value: 3, sort: 3 },
  { label: '财务对接', value: 4, sort: 4 },
  { label: '承租司机', value: 5, sort: 5 },
  { label: '总经理', value: 6, sort: 6 },
  { label: '副总经理', value: 7, sort: 7 },
  { label: '区总', value: 8, sort: 8 },
];

// 其他证件类型
export const otherCertificateType = [
  { label: '驾驶证', value: 0, sort: 0 },
  { label: '军官证', value: 1, sort: 1 },
  { label: '警官证', value: 2, sort: 2 },
  { label: '士兵证', value: 3, sort: 3 },
  { label: '护照', value: 4, sort: 4 },
  { label: '台胞证', value: 5, sort: 5 },
  { label: '回乡证', value: 6, sort: 6 },
];

//客户来源
export const customerSource = [
  { label: '门店股东', value: 0, sort: 0 },
  { label: '客户转介绍', value: 1, sort: 1 },
  { label: '陌生客户', value: 2, sort: 2 },
];

const style = {
  display: 'inline-flex',
  'justify-content': 'center',
  'align-items': 'center',
  width: '20px',
  height: '20px',
  // 'border-radius': '50%',
  'border-radius': '2px',
  color: '#fff',
  'font-size': '14px',
  'line-height': '20px',
};
// 定损单 跟进进展
export const followUpStateList = [
  {
    label: '急需处理',
    iconText: 'A',
    tooltipText: '急需处理定损单：请立即处理',
    value: 0,
    sort: 0,
    iconStyle: Object.assign({}, style, { background: '#E84C4C' }),
  },
  {
    label: '次要紧急',
    iconText: 'B',
    tooltipText: '次要紧急定损单，请及时处理',
    value: 1,
    sort: 1,
    iconStyle: Object.assign({}, style, { background: '#EE8F33' }),
  },
  {
    label: '当前待跟进',
    iconText: 'C',
    tooltipText: '当前待跟进定损单，请跟进',
    value: 2,
    sort: 2,
    iconStyle: Object.assign({}, style, { background: '#1E86EB' }),
  },
  {
    label: '待保险公司跟进',
    iconText: 'D',
    tooltipText: '待保险公司跟进定损单，请联系保险公司',
    value: 3,
    sort: 3,
    iconStyle: Object.assign({}, style, { background: '#33AB79' }),
  },
];

// 客户指令的类型
export const defaultActiveName = [
  { label: 'selfPaying', value: 0, sort: 0 },
  { label: 'contractFor', value: 1, sort: 1 },
  { label: 'claimSettlement', value: 2, sort: 2 },
  { label: 'selfPaying', value: 3, sort: 3 },
];

// 接待类型
export const receptionType = [
  { label: '故障', value: 1, sort: 1 },
  { label: '保养', value: 0, sort: 0 },
  { label: '退车', value: 2, sort: 2 },
  { label: '其他', value: 3, sort: 3 },
];

// 车单流程-操作类型
export const operationType = [
  { label: '接待', value: 11, sort: 11 },
  { label: '检车', value: 12, sort: 12 },
  { label: '保存报价', value: 13, sort: 13 },
  { label: '提交结账', value: 14, sort: 14 },
  { label: '派工', value: 15, sort: 15 },
  { label: '完工', value: 16, sort: 16 },
  { label: '取消接待', value: 17, sort: 17 },
  { label: '重新服务', value: 18, sort: 18 },
  { label: '结账', value: 19, sort: 19 },
  { label: '反结算', value: 20, sort: 20 },
  { label: '提交施工', value: 22, sort: 22 },
  { label: '挂单', value: 23, sort: 23 },
  { label: '撤销挂单', value: 24, sort: 24 },
  { label: '提前提车', value: 25, sort: 25 },
  { label: '领工', value: 26, sort: 26 },
  { label: '提交改派', value: 27, sort: 27 },
  { label: '改派', value: 28, sort: 28 },
  { label: '质检', value: 29, sort: 29 },
  { label: '编辑接待', value: 30, sort: 30 },
  { label: '审核报价', value: 31, sort: 31 },
  { label: '滞留说明', value: 32, sort: 32 },
  { label: '提车', value: 33, sort: 33 },
  { label: '撤销提车', value: 34, sort: 34 },
  { label: '确认故障原因', value: 35, sort: 35 },
  { label: '开始施工', value: 36, sort: 36 },
  { label: '打印结算单', value: 37, sort: 36 },
  { label: '打印派工单', value: 38, sort: 36 },
  { label: '打印材料单', value: 39, sort: 36 },
  { label: '打印接待单', value: 40, sort: 36 },
];

// 车单成本类型
export const costType = [
  { label: '合同折扣', value: 0, sort: 0 },
  { label: '财务折扣', value: 1, sort: 1 },
  { label: '坏账', value: 2, sort: 2 },
  { label: '保费成本', value: 3, sort: 3 },
  { label: '代定损费用', value: 4, sort: 4 },
  { label: '业务人员费用', value: 5, sort: 5 },
];

// 商品管理 索赔属性
export const claimType = [
  { label: '一般件', value: 0, sort: 0 },
  { label: '核心件', value: 1, sort: 1 },
  { label: '基础件', value: 2, sort: 2 },
];

// 商品管理/服务管理 启用状态
// export const enableState = [
//   { label: '启用', value: 1, sort: 0 },
//   { label: '未启用', value: 0, sort: 1 },
// ];

// 商品管理 品质
export const manufactoryType = [
  { label: '品牌', value: 0, sort: 0 },
  { label: '原厂', value: 1, sort: 1 },
  { label: '正厂', value: 2, sort: 2 },
  { label: '修复', value: 3, sort: 3 },
  { label: '拆车', value: 4, sort: 4 },
  { label: '旧件', value: 5, sort: 5 },
];

// 定损单 流程详情 操作类型
export const carLossDeterminationBillOperationType = [
  { label: '完成报损', value: 0, sort: 0 },
  { label: '撤销报损', value: 1, sort: 1 },
  { label: '完成调度', value: 2, sort: 2 },
  { label: '撤销调度', value: 3, sort: 3 },
  { label: '完成对件', value: 4, sort: 4 },
  { label: '撤销对件', value: 5, sort: 5 },
  { label: '提交定损', value: 6, sort: 6 },
  { label: '撤销提损', value: 7, sort: 7 },
  { label: '已出定损', value: 8, sort: 8 },
  { label: '撤销出损', value: 9, sort: 9 },
  { label: '完成复勘', value: 10, sort: 10 },
  { label: '完成收旧', value: 11, sort: 11 },
  { label: '已提资料', value: 12, sort: 12 },
  { label: '撤销提交', value: 13, sort: 13 },
  { label: '回款认领', value: 14, sort: 14 },
  { label: '撤销回款', value: 15, sort: 15 },
  { label: '留言', value: 16, sort: 16 },
  { label: '生成定损单', value: 17, sort: 17 },
  { label: '认领回款', value: 18, sort: 18 },
  { label: '审核处理', value: 19, sort: 19 },
  { label: '调整免赔', value: 20, sort: 20 },
];
