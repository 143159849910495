<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="素材标题">
                <el-input
                  v-model="form.titleAndCodeMsg"
                  placeholder="搜索标题/编号"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item label="素材状态">
                <el-select v-model="form.state" placeholder="请选择" clearable>
                  <el-option label="已下架" :value="4"> </el-option>
                  <el-option label="已上架" :value="3"> </el-option>
                  <el-option label="待审核" :value="1"> </el-option>
                  <el-option label="不通过" :value="2"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作时间">
                <el-date-picker
                  class="w100"
                  v-model="form.operateTimes"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  @input="dateRangeChange()"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="供稿门店">
              <operateCompanyCascader
                v-model="form.contributeCompanyId"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="审稿人">
              <el-select
                v-model="form.verifierId"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAdminJobListUser"
                :loading="searchAdminJobListUserLoading"
              >
                <el-option
                  v-for="(item, index) in adminJobListUserList"
                  :key="index"
                  :label="item.realName"
                  :value="item.userIds"
                  @click.native="selectVerifierId(item)"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.realName
                  }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供稿时间">
              <el-date-picker
                class="w100"
                v-model="form.contributeTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="供稿人">
              <el-select
                v-model="form.creatorId"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAdminJobListUser"
                :loading="searchAdminJobListUserLoading"
                :disabled="disabledCreatorId"
                @clear="delValue"
              >
                <el-option
                  v-for="(item, index) in adminJobListUserList"
                  :key="index"
                  :label="item.realName"
                  :value="item.userIds"
                  @click.native="selectCreatorId(item)"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.realName
                  }}</span>
                  <span style="float: right">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 内容部分 -->
    <template>
      <div class="btns flex-x-between">
        <div>
          <el-button
            class="btn_search"
            type="primary"
            size="small"
            @click="materialEdit"
            >投稿</el-button
          >
          <el-button class="btn_search" size="small" @click="draftBox"
            >草稿箱</el-button
          >
        </div>
      </div>
      <div class="flex h100" style="flex: 1; overflow: auto; margin-top: 20px">
        <el-table
          class="custom-table"
          :data="materialList"
          border
          v-fixedScrollBar="$refs.baseContainer"
        >
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column min-width="120" label="素材标题" prop="title">
            <template #default="{ row }">
              <p
                v-if="row.state == 3"
                class="blue pointer"
                @click="openInnerDrawer(2, row.guideId)"
              >
                {{ row.title }}
              </p>
              <p
                v-if="row.state == 4"
                class="blue pointer"
                @click="openInnerDrawer(3, row.guideId)"
              >
                {{ row.title }}
              </p>
              <p
                v-if="row.state == 1"
                class="blue pointer"
                @click="openInnerDrawer(4, row.guideId)"
              >
                {{ row.title }}
              </p>
              <p
                v-if="row.state == 2"
                class="blue pointer"
                @click="openInnerDrawer(5, row.guideId)"
              >
                {{ row.title }}
              </p>
            </template>
          </el-table-column>
          <el-table-column min-width="120" label="编号" prop="guideCode">
          </el-table-column>
          <el-table-column min-width="100" label="状态" prop="state">
            <template #default="{ row }">
              <div v-if="row.state == 3" class="flex">
                <p class="dot" style="background: #33ab79"></p>
                <p>已上架</p>
              </div>
              <div v-if="row.state == 4" class="flex">
                <p class="dot" style="background: #d0d3d9"></p>
                <p style="color: #84878c">已下架</p>
              </div>
              <div v-if="row.state == 2" class="flex">
                <p class="dot" style="background: #e84c4c"></p>
                <p>不通过</p>
              </div>
              <div v-if="row.state == 1" class="flex">
                <p class="dot" style="background: #ee8f33"></p>
                <p>待审核</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="素材类型" prop="type">
            <template #default="{ row }">
              {{ row.type == 0 ? '技术案例' : '' }}
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="供稿时间" prop="offerTime">
          </el-table-column>
          <el-table-column min-width="100" label="供稿人" prop="creatorName">
          </el-table-column>
          <el-table-column min-width="160" label="供稿门店" prop="companyName">
          </el-table-column>
          <el-table-column min-width="100" label="操作时间" prop="lastUpdated">
          </el-table-column>
          <el-table-column min-width="100" label="审稿人" prop="verifierName">
          </el-table-column>
          <el-table-column min-width="120" label="原因" prop="noPassReason">
            <template #default="{ row }">
              <el-tooltip :content="row.noPassReason" v-if="row.state == 2">
                <div class="text-ellipsis-2-row">
                  {{ row.noPassReason }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
    <!-- 草稿箱抽屉 -->
    <el-drawer
      title="草稿箱"
      :visible.sync="draftFirstDrawer"
      size="55%"
      :before-close="handleClose"
      modal
      wrapperClosable
      close-on-press-escape
      ><div style="margin: 0 8px 0 24px; overflow: scroll">
        <el-table
          :data="draftList"
          border
          v-fixedScrollBar="$refs.baseContainer"
        >
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column min-width="120" label="素材标题" prop="title">
            <template #default="{ row }">
              <p class="blue pointer" @click="openInnerDrawer(1, row.guideId)">
                {{ row.title }}
              </p></template
            >
          </el-table-column>
          <el-table-column min-width="100" label="更新时间" prop="lastUpdated">
          </el-table-column>
        </el-table>
      </div>
      <div class="drawer-footer">
        <pagination
          @pageChange="changeDrawerPage"
          :total="totalDrawer"
        ></pagination>
      </div>
    </el-drawer>
    <draft-drawer
      v-if="innerDrawer"
      :innerDrawer.sync="innerDrawer"
      :detailType="detailType"
      :guideId="guideId"
      @updateData="doSearch"
      @upDraft="getDrafts"
    ></draft-drawer>
  </base-container>
</template>
<script>
// 分页组件
import pagination from '@/components/Pagination.vue';
//组件
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
//草稿箱抽屉
import draftDrawer from '@/components/businessComponent/draftDrawer.vue';
//接口
import { getAllUserByCompanySystemPage } from '@/api/employee';
import { wzGuideInfoDrafts, getMaterialPage } from '@/api/technologyCenter';
// 工具
import { mapState } from 'vuex';

import { hasAuth } from '@/utils/permissions';
export default {
  name: 'materialPhaseI',
  components: { pagination, draftDrawer, operateCompanyCascader },
  data() {
    return {
      form: {
        creatorId: [],
        verifierId: [],
      },
      materialList: [],
      draftList: [],
      //列表分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      //草稿箱抽屉分页组件
      totalDrawer: 0,
      drawerPageOption: {
        size: 10,
        current: 1,
      },
      //草稿箱抽屉可见性
      draftFirstDrawer: false,
      innerDrawer: false,
      //详情类型
      detailType: '',
      //搜索审稿人、供稿人列表字段
      searchAdminJobListUserLoading: false,
      adminJobListUserList: [],
      //指南id
      guideId: '',
      disabledCreatorId: false,
      currentUserIds: [],
      changeCreate: false,
      result: '',
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo?.userId,
      userName: (state) => state.user.userInfo?.realName,
      userPhone: (state) => state.user.userInfo?.phone,
    }),
  },
  created() {
    this.adminJobUsrKeyWordChange();
    this.authority();
    //   this.getList();
  },
  activated() {
    if (this.$route.params?.refresh) {
      this.getList();
    }
  },
  methods: {
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    //更改页码
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    changeDrawerPage(e) {
      this.drawerPageOption = e;
      this.getDrafts(false);
    },
    // 置空输入框数据
    empty() {
      this.form = {};
      this.changeCreate = true;
    },
    //远程搜索审稿人、供稿人列表
    adminJobUsrKeyWordChange(keyWord) {
      this.searchAdminJobListUser(keyWord);
    },
    searchAdminJobListUser(keyWord = '') {
      this.searchAdminJobListUserLoading = true;
      getAllUserByCompanySystemPage(keyWord)
        .then((res) => {
          this.adminJobListUserList = res.records || [];
        })
        .finally(() => {
          this.searchAdminJobListUserLoading = false;
        });
    },
    //选中供稿人
    selectCreatorId(value) {
      this.changeCreate = true;
    },
    //选中审稿人
    selectVerifierId(val) {},
    //审稿人中点击清空按钮时
    delValue() {
      this.changeCreate = true;
    },
    //权限处理
    authority() {
      //供稿人是否有上架权限权限
      this.result = hasAuth(550);
      if (!this.result) {
        this.disabledCreatorId = true;
        getAllUserByCompanySystemPage(this.userName).then((res) => {
          this.currentUserIds = res.records[0].userIds;
          this.form.creatorId = res.records[0].realName;
          this.getList();
        });
      } else {
        this.disabledCreatorId = false;
        this.getList();
      }
    },
    doSearch() {
      this.getList();
    },
    handleClose() {
      this.draftFirstDrawer = false;
    },
    //打开详情抽屉
    openInnerDrawer(value, id) {
      this.detailType = value;
      this.innerDrawer = true;
      this.guideId = id;
    },
    draftBox() {
      this.draftFirstDrawer = true;
      this.getDrafts();
    },
    //列表数据
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.form.creatorId =
        typeof this.form?.creatorId === 'string'
          ? this.form.creatorId === this.userName
            ? this.currentUserIds
            : []
          : this.form.creatorId;
      this.form.verifierId =
        this.form?.verifierId == '' ? [] : this.form.verifierId;
      var times = {
        operateStartTime: this.form.operateTimes?.[0]
          ? this.form.operateTimes?.[0]
          : '',
        operateEndTime: this.form.operateTimes?.[1]
          ? this.form.operateTimes?.[1]
          : '',
        contributeStartTime: this.form.contributeTimes?.[0]
          ? this.form.contributeTimes?.[0]
          : '',
        contributeEndTime: this.form.contributeTimes?.[1]
          ? this.form.contributeTimes?.[1]
          : '',
      };
      var data = Object.assign(this.form, this.pageOption, times);
      getMaterialPage(data).then((res) => {
        this.materialList = res.records;
        this.total = res.total;
        if (!this.changeCreate && !this.result) {
          this.form.creatorId = this.userName;
        }
      });
    },
    //指南草稿箱请求数据
    getDrafts(reset = true) {
      if (reset) {
        this.drawerPageOption.current = 1;
        this.totalDrawer = 0;
      }
      var data = this.drawerPageOption;
      wzGuideInfoDrafts(data).then((res) => {
        this.draftList = res.records;
        this.totalDrawer = res.total;
      });
    },
    // 素材编辑
    materialEdit() {
      this.$router.push({
        name: 'materialEdit',
        query: {
          id: '',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer-footer {
  position: fixed;
  bottom: 20px;
  left: 23%;
  width: 100%;
}
.dot {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-top: 8px;
  margin-right: 5px;
}
</style>
