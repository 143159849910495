<template>
  <base-container>
    <template slot="header"> </template>
    <template>
      <div class="header-box">
        <div class="row1">
          <div class="flex-y-center h100">
            <div class="bill-type flex-center">
              {{ billType | dict('carBillType') }}
            </div>
            <div class="select-car flex-y-center" v-if="id == null">
              <span class="select-car-label">选择已有车辆</span>
              <select-car-for-create-car-bill-popover
                ref="selectCar"
                :searchKeyWord="carInfo.carNumber"
                :billType="billType"
                @clear="deleteCar"
                @select="selectCar"
              ></select-car-for-create-car-bill-popover>
              <span v-auth="54" class="or">或</span>
              <el-button
                v-auth="54"
                size="small"
                type="text"
                class="orange-btn"
                @click="addCarDialogVisibility = true"
                >新增</el-button
              >
            </div>
            <!-- 如果是编辑车单的时候直接显示数据 -->
            <div class="select-car flex-y-center" v-else>
              <span class="select-car-label"
                >接车单号：{{ form.billCode }}</span
              >
              <i
                :style="{
                  color: $lodash.get(
                    carBillStateIconDict[form.billState],
                    'color',
                    ''
                  ),
                }"
                :class="[
                  'bill-state',
                  'iconfont',
                  'blue',
                  $lodash.get(
                    carBillStateIconDict[form.billState],
                    'label',
                    ''
                  ),
                ]"
              ></i>
            </div>
          </div>
          <div class="bill-process">
            <div class="state-name">
              <span>接待开单</span>
              <span class="time">{{ form.dateCreated }}</span>
            </div>
            <div class="split-string">
              <div class="top-text">
                <span v-show="form.planFinishTime">
                  预交车: {{ form.planFinishTime }}
                </span>
                <span v-show="form.waitInStore">在店等</span>
                <span v-show="form.undetermined">待定</span>
              </div>
              <div class="line"></div>
              <div class="bottom-text orange">
                <span v-if="form.planFinishTime">{{
                  distancePlannedTime
                }}</span>
              </div>
            </div>
            <div class="state-name">
              <span>质检交车</span>
              <span class="time"></span>
            </div>
            <div class="split-string">
              <div class="top-text plan-time"></div>
              <div class="line"></div>
              <div class="bottom-text orange"></div>
            </div>
            <div class="state-name">
              <span>提交结账</span>
              <span class="time"></span>
            </div>
            <div class="split-string">
              <div class="top-text orange"></div>
              <div class="line"></div>
              <div class="bottom-text orange"></div>
            </div>
            <div class="state-name">
              <span>车单结账</span>
              <span class="time"></span>
            </div>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="row2" v-if="carInfo.carId">
          <div class="car-and-client-info-box">
            <div class="car-logo">
              <img v-if="carInfo.carLogoIcon" :src="carInfo.carLogoIcon" />
              <img v-else src="@/assets/img/carLogo.png" />
            </div>
            <div class="client-info">
              <div class="car-info">
                <div class="flex">
                  <span class="car-number pointer" @click="goCarDetail">{{
                    carInfo.carNumber
                  }}</span>

                  <div class="car-info-tag" style="display: inline-block">
                    <span class="tag history pointer" @click="viewHistory"
                      >历史进店 {{ carInfo.intoStoresCount }} 次</span
                    >
                    <!-- <span
                      v-if="carInfo.vehicleType"
                      class="tag car-vehicle-type"
                      >{{ carInfo.vehicleType }}</span
                    > -->
                    <!-- <span v-if="carInfo.uniqueId" class="tag car-uniqueId"
                    >VIN: {{ carInfo.uniqueId }}</span
                  > -->
                    <span
                      v-if="carInfo.brandName"
                      class="tag car-brand"
                      style="margin-left: 0px"
                      >{{ carInfo.brandName }}</span
                    >
                    <span
                      class="tag riskControlCar"
                      v-if="carInfo.isRiskControlCar === 1"
                      >风控车</span
                    >
                    <div style="clear: both"></div>
                    <span
                      v-if="
                        carInfo.billClientMemberMessageVo != undefined &&
                        carInfo.billClientMemberMessageVo.isAgreementClient !=
                          undefined &&
                        carInfo.billClientMemberMessageVo.state != undefined &&
                        carInfo.billClientMemberMessageVo.isAgreementClient ==
                          1 &&
                        carInfo.billClientMemberMessageVo.state == 1
                      "
                      class="tag car-Contract"
                      >包干车辆</span
                    >
                  </div>
                  <p
                    class="color626 text-ellipsis"
                    style="
                      display: inline-block;
                      width: 100px;
                      vertical-align: middle;
                      flex-grow: 1;
                      margin-top: 4px;
                    "
                  >
                    {{ carInfo.carRemark }}
                  </p>
                </div>
                <div class="client-base-info flex-x-between">
                  <div class="flex">
                    <div v-if="carInfo.clientId" style="margin-top: 5px">
                      <!-- <i class="iconfont grow-icon_person icon-person"></i> -->
                      <i
                        v-if="[0, 1, 2].includes(carInfo.clientLevel)"
                        class="iconfont blue"
                        :class="levelIcons[carInfo.clientLevel]"
                        :title="levelIconsWord[carInfo.clientLevel]"
                      ></i>
                      <span
                        class="client-name pointer"
                        @click="goClientDetail"
                        >{{ carInfo.clientName }}</span
                      >

                      <!-- 注释性别的展示 -->
                      <!-- <i
                    class="iconfont icon_sex"
                    :class="{
                      'grow-icon_man': carInfo.clientGender == 0,
                      'grow-icon_girl': carInfo.clientGender == 1,
                    }"
                  ></i> -->
                      <!-- bug764隐藏 -->
                      <!-- <i
                  class="iconfont grow-icon_VX_16"
                  :class="{
                    icon_wechat: carInfo.isBindingWx == 0,
                    icon_wechat_active: carInfo.isBindingWx == 1,
                  }"
                ></i> -->
                      <!-- 注释定金、优惠券的展示 -->
                      <!-- <span
                    style="margin-left: 15px"
                    class="color666"
                    v-show="carInfo.clientDepositAmountNums"
                  >
                    定金（{{ carInfo.clientDepositAmountNums }}笔，¥{{
                      carInfo.clientDepositAmount | toThousands
                    }}）
                  </span>
                  <span
                    style="margin-left: 15px"
                    class="color666"
                    v-show="carInfo.clientCouponNums"
                  >
                    优惠券（{{ carInfo.clientCouponNums }}张，¥{{
                      carInfo.clientCouponSum | toThousands
                    }}）
                  </span> -->
                      <!-- <span
                        class="blue pointer"
                        style="margin-left: 10px"
                        v-if="carInfo.clientRemark"
                        @click="remarksDrawerVisibilityEvent(1)"
                        >注意事项</span
                      > -->

                      <span
                        v-if="carOwnerSettlementWay"
                        :style="{
                          'margin-left': '5px',
                          color:
                            carOwnerSettlementWay === '现结'
                              ? '#33ab79'
                              : 'red',
                        }"
                        >{{ carOwnerSettlementWay }}</span
                      >
                      <span
                        class="primary pointer"
                        style="margin-left: 10px"
                        v-auth="546"
                        @click="clientCommandDrawerVisibility = true"
                        >客户指令</span
                      >
                    </div>
                    <div
                      v-if="!carInfo.clientId"
                      style="font-size: 14px; display: inline; margin-top: 5px"
                    >
                      <!-- <i class="el-icon-warning orange"></i> -->
                      <span class="color999">暂未绑定客户</span>
                      <span
                        v-auth="63"
                        style="margin-left: 5px"
                        class="blue pointer"
                        @click="showBindingCustomerDialog"
                        v-if="!id"
                        >绑定客户</span
                      >
                    </div>
                    <el-popover
                      width="300"
                      placement="bottom"
                      trigger="hover"
                      v-if="underWarrantyState == 0"
                    >
                      <template slot="reference">
                        <el-button type="primary" plain class="warranty-info"
                          >未知<i
                            class="iconfont grow-icon_doubt"
                            style="font-size: 12px; margin-left: 3px"
                          ></i
                        ></el-button>
                      </template>
                      <div
                        v-if="
                          vehicleVo.length == 0 &&
                          corePartsVo.length == 0 &&
                          wearingPartsVo.length == 0
                        "
                      >
                        <span class="warranty-title">未知</span>
                        <span class="tips">（暂无该车型质保规则）</span>
                      </div>
                      <div v-else>
                        <div v-if="vehicleVo.length != 0">
                          <span class="warranty-title">未知</span>
                          <span class="tips"
                            >（注册时间为空或暂无进店里程）</span
                          >
                          <p>
                            <span class="warranty-title">1.整车质保</span>
                            <span class="tips"
                              >({{ vehicleVo[0].month }}月/{{
                                vehicleVo[0].km
                              }}公里)</span
                            >
                          </p>
                          <p class="tips">{{ vehicleVo[0].content }}</p>
                          <div v-if="vehicleVo.length > 1">
                            <div
                              v-for="(item, index) in vehicleVoList"
                              :key="index"
                              class="tips"
                            >
                              <span>({{ item.month }}月/{{ item.km }}公里)</span
                              ><span>{{ item.content }}</span>
                            </div>
                          </div>
                        </div>
                        <div v-if="corePartsVo.length != 0">
                          <p>
                            <span class="warranty-title">2.核心件质保</span>
                            <span class="tips"
                              >({{ corePartsVo[0].month }}月/{{
                                corePartsVo[0].km
                              }}公里)</span
                            >
                          </p>
                          <p class="tips">{{ corePartsVo[0].content }}</p>
                          <div v-if="corePartsVo.length > 1">
                            <div
                              v-for="(item, index) in corePartsVoList"
                              :key="index"
                              class="tips"
                            >
                              <span>({{ item.month }}月/{{ item.km }}公里)</span
                              ><span>{{ item.content }}</span>
                            </div>
                          </div>
                        </div>
                        <div v-if="wearingPartsVo.length != 0">
                          <p>
                            <span class="warranty-title">3.易损件质保</span>
                            <span class="tips"
                              >({{ wearingPartsVo[0].month }}月/{{
                                wearingPartsVo[0].km
                              }}公里)</span
                            >
                          </p>
                          <p class="tips">{{ wearingPartsVo[0].content }}</p>
                          <div v-if="wearingPartsVo.length > 1">
                            <div
                              v-for="(item, index) in wearingPartsVoList"
                              :key="index"
                              class="tips"
                            >
                              <span>({{ item.month }}月/{{ item.km }}公里)</span
                              ><span>{{ item.content }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-popover>
                    <el-popover
                      width="300"
                      placement="bottom"
                      trigger="hover"
                      v-if="underWarrantyState == 1"
                    >
                      <template slot="reference">
                        <el-button type="primary" plain class="warranty-info"
                          >过保<i
                            class="iconfont grow-icon_doubt"
                            style="font-size: 12px; margin-left: 3px"
                          ></i
                        ></el-button>
                      </template>
                      <div v-if="vehicleVo.length != 0">
                        <p>过保</p>
                        <p>
                          <span class="warranty-title">1.整车质保</span>
                          <span class="tips"
                            >({{ vehicleVo[0].month }}月/{{
                              vehicleVo[0].km
                            }}公里)</span
                          >
                        </p>
                        <p class="tips">{{ vehicleVo[0].content }}</p>
                        <div v-if="vehicleVo.length > 1">
                          <div
                            v-for="(item, index) in vehicleVoList"
                            :key="index"
                            class="tips"
                          >
                            <span>({{ item.month }}月/{{ item.km }}公里)</span
                            ><span>{{ item.content }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="corePartsVo.length != 0">
                        <p>
                          <span class="warranty-title">2.核心件质保</span>
                          <span class="tips"
                            >({{ corePartsVo[0].month }}月/{{
                              corePartsVo[0].km
                            }}公里)</span
                          >
                        </p>
                        <p class="tips">{{ corePartsVo[0].content }}</p>
                        <div v-if="corePartsVo.length > 1">
                          <div
                            v-for="(item, index) in corePartsVoList"
                            :key="index"
                            class="tips"
                          >
                            <span>({{ item.month }}月/{{ item.km }}公里)</span
                            ><span>{{ item.content }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="wearingPartsVo.length != 0">
                        <p>
                          <span class="warranty-title">3.易损件质保</span>
                          <span class="tips"
                            >({{ wearingPartsVo[0].month }}月/{{
                              wearingPartsVo[0].km
                            }}公里)</span
                          >
                        </p>
                        <p class="tips">{{ wearingPartsVo[0].content }}</p>
                        <div v-if="wearingPartsVo.length > 1">
                          <div
                            v-for="(item, index) in wearingPartsVoList"
                            :key="index"
                            class="tips"
                          >
                            <span>({{ item.month }}月/{{ item.km }}公里)</span
                            ><span>{{ item.content }}</span>
                          </div>
                        </div>
                      </div>
                    </el-popover>
                    <el-popover
                      width="300"
                      placement="bottom"
                      trigger="hover"
                      v-if="underWarrantyState == 2 && vehicleVo.length != 0"
                    >
                      <template slot="reference">
                        <el-button type="primary" plain class="warranty-info"
                          >整车质保<i
                            class="iconfont grow-icon_doubt"
                            style="font-size: 12px; margin-left: 3px"
                          ></i
                        ></el-button>
                      </template>
                      <div v-if="vehicleVo.length != 0">
                        <p>
                          <span class="warranty-title">整车质保</span>
                          <span class="tips"
                            >({{ vehicleVo[0].month }}月/{{
                              vehicleVo[0].km
                            }}公里)</span
                          >
                        </p>
                        <p class="tips">{{ vehicleVo[0].content }}</p>
                      </div>
                    </el-popover>
                    <el-popover
                      width="300"
                      placement="bottom"
                      trigger="hover"
                      v-if="underWarrantyState == 2 && corePartsVo.length != 0"
                    >
                      <template slot="reference">
                        <el-button type="primary" plain class="warranty-info"
                          >核心件质保<i
                            class="iconfont grow-icon_doubt"
                            style="font-size: 12px; margin-left: 3px"
                          ></i
                        ></el-button>
                      </template>
                      <div v-if="corePartsVo.length != 0">
                        <p>
                          <span class="warranty-title"> 核心件质保</span>
                          <span class="tips"
                            >({{ corePartsVo[0].month }}月/{{
                              corePartsVo[0].km
                            }}公里)</span
                          >
                        </p>
                        <p class="tips">{{ corePartsVo[0].content }}</p>
                      </div>
                    </el-popover>
                    <el-popover
                      width="300"
                      placement="bottom"
                      trigger="hover"
                      v-if="
                        underWarrantyState == 2 && wearingPartsVo.length != 0
                      "
                    >
                      <template slot="reference">
                        <el-button type="primary" plain class="warranty-info"
                          >易损件质保<i
                            class="iconfont grow-icon_doubt"
                            style="font-size: 12px; margin-left: 3px"
                          ></i
                        ></el-button>
                      </template>
                      <div v-if="wearingPartsVo.length != 0">
                        <p>
                          <span class="warranty-title">易损件质保</span>
                          <span class="tips"
                            >({{ wearingPartsVo[0].month }}月/{{
                              wearingPartsVo[0].km
                            }}公里)</span
                          >
                        </p>
                        <p class="tips">{{ wearingPartsVo[0].content }}</p>
                      </div>
                    </el-popover>
                  </div>

                  <!-- 他人自费 -->
                  <div v-if="billType === 0" style="margin-right: 60px">
                    <span
                      style="margin-right: 10px; font-size: 14px"
                      class="color666"
                      >他人自费</span
                    >
                    <el-switch
                      v-model="isOthersSelfPayState"
                      @change="handleOthersSelfPayChange"
                    ></el-switch>
                  </div>
                  <!-- 他人推修 -->
                  <div v-if="billType === 2" style="margin-right: 60px">
                    <span style="font-size: 14px" class="color666"
                      >他人推修</span
                    >
                    <el-tooltip
                      content="车单默认推修人为签约客户，如需调整请打开开关设置"
                    >
                      <i
                        style="margin-right: 10px"
                        class="iconfont grow-icon_doubt color626"
                      ></i>
                    </el-tooltip>
                    <el-switch
                      v-model="isOtherClientPush"
                      @change="handleOtherClientPushChange"
                      :inactive-value="0"
                      :active-value="1"
                    ></el-switch>
                  </div>
                </div>
              </div>
              <!-- <div class="car-info-tag">
                <span v-if="carInfo.vehicleType" class="tag car-vehicle-type">{{
                  carInfo.vehicleType
                }}</span>
                <span v-if="carInfo.uniqueId" class="tag car-uniqueId"
                  >VIN: {{ carInfo.uniqueId }}</span
                >
                <span v-if="carInfo.brandName" class="tag car-brand">{{
                  carInfo.brandName
                }}</span>
              </div>
              <p class="color626">
                {{ carInfo.carRemark }}
              </p> -->
              <!-- 注释掉更换会员折扣功能 -->
              <!-- <div v-if="carInfo.clientId" class="client-vip-info">
                <i
                  class="iconfont grow-icon_vip"
                  :class="{
                    orange: carInfo.isVip,
                    color999: !carInfo.isVip,
                  }"
                ></i>
                <span class="vip-text" v-if="carInfo.isVip">
                  <span v-if="false">
                    会员客户 ( 会员卡:
                    {{
                      carInfo.billClientMemberMessageVo.memberCardNums
                    }}张，余额: ¥{{
                      carInfo.billClientMemberMessageVo.memberBalance
                    }}，余次:
                    {{ carInfo.billClientMemberMessageVo.memberResidueDegree }}
                    {{
                      carInfo.billClientMemberMessageVo.agreementClientId
                        ? carInfo.billClientMemberMessageVo.isAgreementClient
                          ? '；协议客户 - 包干'
                          : '；协议客户'
                        : ''
                    }}
                    )
                  </span>
                  <span style="margin-left: 10px" class="blue pointer"
                    >更换会员折扣</span
                  >
                </span>
                <span v-else style="margin-left: 10px; font-size: 14px">
                  <span>非会员</span>
                  <span style="margin-left: 10px" class="blue pointer"
                    >开卡</span
                  >
                </span>
              </div>
              <div v-else style="height: 30px"></div> -->
            </div>
          </div>
          <!-- <div class="row2-right">
            <div class="status"> -->
          <!-- 1.0 隐藏 -->
          <!-- <el-badge :value="5">
                <i class="iconfont grow-icon_order"></i>
              </el-badge>
              <el-badge :value="5">
                <i class="iconfont grow-icon_warn1"></i>
              </el-badge>
              <el-badge :value="5">
                <i class="iconfont grow-icon_chedan"></i>
              </el-badge> -->
          <!-- </div>
            <div class="flex-x-between">
              <div v-if="billType === 0" style="margin-right: 60px">
                <span style="margin-right: 10px" class="color666"
                  >他人自费</span
                >
                <el-switch
                  v-model="isOthersSelfPayState"
                  @change="isOthersEvent()"
                ></el-switch>
              </div> -->
          <!-- <div class="color666 pointer">
                <i class="iconfont grow-icon_jilu"></i>
                操作记录
              </div> -->
          <!-- </div>
          </div> -->
        </div>
        <!-- <div class="empty-tip" v-else>请先选择或新增接待车辆</div>
        <div v-if="isOthersSelfPayState" class="split-line"></div> -->
        <div
          v-if="isOthersSelfPayState && payerInfo.clientId"
          class="other-people-payment-box"
        >
          <div class="label">
            <span>他人自费客户：</span>
          </div>
          <div class="select-client-box">
            <div class="select-client">
              <span style="margin-right: 27px">{{ payerInfo.clientName }}</span>
              <span
                v-if="payerSettlementWay"
                :style="{
                  'margin-right': '27px',
                  color: payerSettlementWay === '现结' ? '#33ab79' : 'red',
                }"
                >{{ payerSettlementWay }}</span
              >
              <el-button
                size="small"
                type="text"
                class="primary orange-btn"
                style="margin-left: 10px"
                @click="selectOtherPayClientDialogVisibility = true"
                >重选</el-button
              >
            </div>
            <span
              class="blue pointer"
              style="margin-left: 10px"
              v-if="payerInfo.clientRemark"
              @click="remarksDrawerVisibilityEvent(2)"
              >注意事项</span
            >
          </div>
        </div>
        <div
          v-if="isOtherClientPush && pushClientId"
          class="other-people-payment-box"
        >
          <div class="label">
            <span>推修对象：</span>
          </div>
          <div class="select-client-box">
            <div class="select-client">
              <span style="margin-right: 27px">{{ pushClientName }}</span>

              <el-button
                size="small"
                type="text"
                class="primary orange-btn"
                style="margin-left: 10px"
                @click="selectOtherClientPushDialogVisibility = true"
                >重选</el-button
              >
            </div>
          </div>
        </div>

        <div
          v-if="carInfo.carId && [2, 3].includes(billType)"
          class="split-line"
        ></div>
        <div
          v-if="carInfo.carId && billType === 2"
          class="insurance-type-bill-box"
        >
          <div>
            <div style="margin-bottom: 20px">
              <span>理赔信息</span>
              <el-tooltip content="可上传8张图片每张图片建议不超过2M">
                <i class="iconfont grow-icon_doubt color626"></i>
              </el-tooltip>
            </div>
            <div>
              <span>交商不同</span>
              <el-tooltip
                content="交强险与商业险保险公司不一致时，请打开开关设置"
              >
                <i class="iconfont grow-icon_doubt color626"></i>
              </el-tooltip>
            </div>
            <div style="text-align: center; margin-top: 5px">
              <el-switch
                v-model="isJqInfo"
                :inactive-value="0"
                :active-value="1"
              ></el-switch>
            </div>
          </div>
          <el-form :model="billInsuranceDto" label-width="110px" size="small">
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  <template slot="label">
                    <span style="color: #f00">*</span>
                    <span>保险公司</span>
                    <el-tooltip content="商业险保险公司">
                      <i class="iconfont grow-icon_doubt color626"></i>
                    </el-tooltip>
                  </template>
                  <select-insurance-company-popover
                    :searchKeyWord="billInsuranceDto.claimsInsuranceCompanyName"
                    @select="handleSelectInsuranceCompany"
                    @clear="handleClearInsuranceCompany"
                  ></select-insurance-company-popover>
                </el-form-item>
              </el-col>
              <el-col :span="10" :offset="1">
                <el-form-item>
                  <template slot="label">
                    <span style="color: #f00">*</span>
                    <span>报案号</span>
                  </template>
                  <el-input
                    size="small"
                    v-model="billInsuranceDto.caseNum"
                    placeholder="请输入报案号"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="isJqInfo">
              <el-col :span="10">
                <el-form-item>
                  <template slot="label">
                    <span style="color: #f00">*</span>
                    <span>交强保险公司</span>
                  </template>
                  <select-insurance-company-popover
                    :searchKeyWord="jqInsuranceName"
                    @select="handleSelectJqInsuranceCompany"
                    @clear="handleClearJqInsuranceCompany"
                  ></select-insurance-company-popover>
                </el-form-item>
              </el-col>
              <el-col :span="10" :offset="1">
                <el-form-item>
                  <template slot="label">
                    <span style="color: #f00">*</span>
                    <span>交强报案号</span>
                  </template>
                  <el-input
                    size="small"
                    v-model="jqCaseNum"
                    placeholder="请输入报案号"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  <template slot="label">
                    <span class="red">*</span>
                    <span>开票主体</span>
                  </template>
                  <!-- <span style="font-size: 14px; color: #666">{{
                    billInsuranceDto.insuranceCompanyName
                  }}</span> -->
                  <el-select
                    size="small"
                    v-model.number="billInsuranceDto.insuranceCompanyId"
                    filterable
                    clearable
                    remote
                    placeholder="搜索开票主体名称"
                    :remote-method="searchStore"
                    :loading="searchStoreLoading"
                  >
                    <el-option
                      v-for="item in storeList"
                      :key="item.companyId"
                      :label="item.invoicingEntity"
                      :value="item.companyId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10" :offset="1">
                <el-form-item>
                  <template slot="label">
                    <span style="color: #f00">*</span>
                    <span>事故责任</span>
                  </template>
                  <el-select size="small" v-model="billInsuranceDto.dutyType">
                    <el-option
                      v-for="item in dutyTypeDicts"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="其他信息">
                  <el-radio-group v-model="isInsuranceTurnSelf">
                    <el-radio
                      :label="0"
                      @click.native.prevent="handleClickInsuranceTurnSelf(0)"
                      >免赔2000</el-radio
                    >
                    <el-radio
                      :label="1"
                      @click.native.prevent="handleClickInsuranceTurnSelf(1)"
                      >转自费</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="10" :offset="1">
                <el-form-item v-if="[0, 1].includes(isInsuranceTurnSelf)">
                  <template slot="label">
                    <span style="color: #f00">*</span>
                    <span>消费客户</span>
                    <el-tooltip content="当前理赔单（免赔或转自费）费用承担方">
                      <i class="iconfont grow-icon_doubt color626"></i>
                    </el-tooltip>
                  </template>
                  {{ beTurnConsumerName }}
                  <span
                    v-if="beTurnConsumerSettlementWay"
                    :style="{
                      'margin-right': '5px',
                      color:
                        beTurnConsumerSettlementWay === '现结'
                          ? '#33ab79'
                          : 'red',
                    }"
                    >{{ beTurnConsumerSettlementWay }}</span
                  >
                  <el-button
                    size="small"
                    type="text"
                    class="primary orange-btn"
                    style="margin-left: 10px"
                    @click="selectInsuranceCustomerDialogVisibility = true"
                    >重选</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            class="color666"
            style="display: flex; flex-wrap: wrap; font-size: 14px"
          >
            <!-- <upload-file
              title="行驶证"
              v-model="billInsuranceDto.runningLicenceImg"
            ></upload-file> -->
            <upload-file
              title="驾驶证"
              v-model="billInsuranceDto.picDrivingLicence"
            ></upload-file>
            <upload-file
              title="定损单1"
              v-model="billInsuranceDto.feeListPicA"
            ></upload-file>
            <upload-file
              title="定损单2"
              v-model="billInsuranceDto.feeListPicB"
            ></upload-file>
            <upload-file
              title="定损单3"
              v-model="billInsuranceDto.feeListPicC"
            ></upload-file>
            <upload-file-list
              v-model="billInsuranceDto.feeListPicOthers"
              :max="4"
            ></upload-file-list>
          </div>
        </div>
        <div
          v-if="carInfo.carId && billType === 3"
          class="undertake-type-bill-box"
        >
          <div class="title">
            <span>索赔信息</span>
            <span>(可上传5张图片每张图片建议不超过2M)</span>
          </div>
          <el-form :model="billUndertakeDto" label-width="100px" size="small">
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  <template slot="label">
                    <span style="color: #f00">*</span>
                    <span>三包厂家</span>
                  </template>
                  <select-undertake-company-popover
                    :searchKeyWord="
                      billUndertakeDto.undertakeInsuranceCompanyName
                    "
                    @select="handleSelectUndertakeCompany"
                    @clear="handleClearUndertakeCompany"
                  ></select-undertake-company-popover>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="索赔单号">
                  <div
                    class="undertake-num"
                    v-for="(item, index) in billUndertakeDto.claimsNumArr"
                    :key="index"
                  >
                    <i
                      class="iconfont grow-icon_delete3 pointer orange"
                      style="margin-right: 12px"
                      v-if="index !== 0"
                      @click="delClaimsNum(index)"
                    ></i>
                    <el-input
                      v-model="item.value"
                      clearable
                      placeholder="请输入"
                      size="small"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <div
                  style="margin: 11px 12px"
                  class="blue pointer"
                  @click="addClaimsNum"
                >
                  +添加更多
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item>
                  <template slot="label">
                    <span>开票主体</span>
                  </template>
                  <span style="font-size: 14px; color: #666">{{
                    billUndertakeDto.undertakeCompanyName
                  }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            class="color666"
            style="display: flex; flex-wrap: wrap; font-size: 14px"
          >
            <upload-file-list
              v-model="billUndertakeDto.undertakePics"
              :max="5"
            ></upload-file-list>
          </div>
        </div>
      </div>

      <div v-if="![0, 1, 2, 3].includes(this.typeStatus)">
        <div v-if="carInfo.carId">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="100px"
            size="small"
            style="margin: 30px 0"
          >
            <el-row>
              <el-col :span="16">
                <el-row>
                  <el-col :span="7">
                    <el-form-item prop="mileage">
                      <template slot="label">
                        <div class="flex-col">
                          <span
                            ><span style="color: #f00">*</span>进店里程</span
                          >
                        </div>
                      </template>
                      <number-input
                        v-model="form.mileage"
                        :max="999999"
                        :enabledZero="false"
                        :enabledDecimals="false"
                        clearable
                      >
                        <template slot="append">KM</template>
                      </number-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item prop="customerName">
                      <template slot="label">
                        <div class="flex-col">
                          <span
                            ><span style="color: #f00">*</span>送修人姓名</span
                          >
                        </div>
                      </template>
                      <el-input
                        placeholder="请输入送修人姓名"
                        v-model="form.customerName"
                        maxlength="10"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item>
                      <template slot="label">
                        <div class="flex-col">
                          <span
                            ><span style="color: #f00">*</span>送修人电话</span
                          >
                        </div>
                      </template>
                      <number-input
                        placeholder="请输入送修人电话"
                        v-model="form.customerPhone"
                        :maxlength="11"
                        :enabledDecimals="false"
                        :negativeNumber="false"
                      ></number-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="7">
                    <el-form-item label="业务来源" prop="billSourceType">
                      <selectBillSourcePopover
                        v-model="form.billSourceTypeId"
                        :searchKeyWord="form.billSourceTypeName"
                        :isCarBill="true"
                      ></selectBillSourcePopover>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item label="进店油(电)表">
                      <el-select
                        v-model="form.oilMeter"
                        placeholder="请选择进店油(电)表"
                        clearable
                      >
                        <el-option
                          v-for="item in energyPercentage"
                          :label="item.label"
                          :value="item.value"
                          :key="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item>
                      <template slot="label">
                        <div class="flex-col">
                          <span
                            ><span style="color: #f00">*</span>施工类型</span
                          >
                        </div>
                      </template>
                      <el-select
                        v-model="form.difficulty"
                        placeholder="请选择施工类型"
                        clearable
                      >
                        <el-option
                          v-for="item in difficultyDicts"
                          :label="item.label"
                          :value="item.value"
                          :key="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="7">
                    <div v-if="![0, 1, 2, 3].includes(this.typeStatus)">
                      <div v-if="carInfo.carId" class="plan-finish-time-box">
                        <div class="flex-y">
                          <el-form-item>
                            <template slot="label">
                              <div class="flex-col">
                                <span
                                  ><span style="color: #f00">*</span
                                  >预交车时间</span
                                >
                              </div>
                            </template>
                            <el-date-picker
                              type="datetime"
                              v-model="form.planFinishTime"
                              placeholder="请选择预交车时间"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              :disabled="form.undetermined"
                              :picker-options="{ disabledDate }"
                            >
                            </el-date-picker>
                          </el-form-item>
                          <div style="margin-left: 102px; margin-top: -12px">
                            <el-checkbox
                              v-model="form.undetermined"
                              @change="changeUndetermined"
                              >待定</el-checkbox
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item label="真实续航">
                      <number-input
                        placeholder="请输入真实续航"
                        v-model="form.trueEndurance"
                        :enabledDecimals="false"
                        :negativeNumber="false"
                      ></number-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7" :offset="1">
                    <el-form-item label="接车员">
                      <el-select
                        v-model="form.pickUpUserId"
                        placeholder="请选择接车员"
                        filterable
                        clearable
                        remote
                        @clear="handleClearPickUpUser"
                        :remote-method="getEmployeeList"
                        :loading="loadingEmployee"
                      >
                        <el-option
                          v-for="(item, index) in employeeList"
                          :key="index"
                          :label="item.realName"
                          :value="item.userId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="23" style="margin-top: 20px">
                    <el-form-item label="环检图片">
                      <template slot="label">
                        <div class="bao-btn">
                          <el-tooltip effect="light" placement="bottom-start">
                            <div
                              slot="content"
                              style="
                                color: #666;
                                font-size: 13px;
                                line-height: 20px;
                              "
                            >
                              <div class="flex">
                                <span
                                  >(可上传20张图片,建议每张图片大小不超过1M)</span
                                >
                              </div>
                            </div>
                            <i class="iconfont grow-icon_doubt"></i>
                          </el-tooltip>
                          环检图片
                        </div>
                      </template>
                      <div class="color666">
                        <upload-file
                          v-model="form.carBodyPositivePic"
                          title="车身正面"
                        ></upload-file>
                        <upload-file
                          v-model="form.carBodyLeftPic"
                          title="车身左侧"
                        ></upload-file>
                        <upload-file
                          v-model="form.carBodyBehindPic"
                          title="车身后面"
                        ></upload-file>
                        <upload-file
                          v-model="form.carBodyRightPic"
                          title="车身右侧"
                        ></upload-file>
                        <upload-file
                          v-model="form.runningLicenceImg"
                          title="行驶证"
                        ></upload-file>
                        <upload-file
                          v-model="form.carInstrumentPanelPic"
                          title="仪表盘"
                        ></upload-file>
                        <upload-file
                          v-model="form.vehicleNameplateImg"
                          title="车辆铭牌"
                        ></upload-file>
                        <upload-file
                          v-model="form.batteryNameplateImg"
                          title="电池铭牌"
                        ></upload-file>
                        <upload-file-list
                          :max="12"
                          v-model="form.carOtherPics"
                        ></upload-file-list>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-if="isSpecificClient()">
                  <el-col :span="7">
                    <el-form-item label="录单情况" prop="doubleSysInputState">
                      <div class="flex">
                        <el-select
                          v-model="form.doubleSysInputState"
                          placeholder="双系统录单情况"
                        >
                          <el-option
                            label="双系统待录单"
                            :value="1"
                          ></el-option>
                          <el-option
                            label="双系统已录单"
                            :value="2"
                          ></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10" :offset="1">
                    <el-form-item label="双系统单号">
                      <div
                        class="double-num-item"
                        v-for="(item, index) in doubleSysNumArr"
                        :key="index"
                      >
                        <i
                          class="iconfont grow-icon_delete3 pointer orange"
                          style="margin-right: 12px"
                          v-if="index !== 0"
                          @click="delDoubleSysNum(index)"
                        ></i>
                        <el-input
                          v-model="item.value"
                          clearable
                          placeholder="请输入双系统单号"
                          size="small"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" :offset="1">
                    <div
                      style="margin: 11px 12px"
                      class="blue pointer"
                      @click="addDoubleSysNum"
                    >
                      +添加更多
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-col :span="23" :offset="1">
                  <el-form-item prop="remark">
                    <template slot="label">
                      <div class="flex-col">
                        <span>车主要求</span>
                        <span
                          style="
                            color: #999;
                            font-size: 12px;
                            line-height: 14px;
                          "
                          >(客户可见)</span
                        >
                      </div>
                    </template>
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="form.remark"
                      placeholder="请输入车主要求"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="23" :offset="1">
                  <el-form-item prop="firstVisitRemark">
                    <template slot="label">
                      <div class="flex-col">
                        <span
                          ><span style="color: #f00">*</span>初诊与备注</span
                        >
                        <span
                          style="
                            color: #999;
                            font-size: 12px;
                            line-height: 14px;
                          "
                          >(客户可见)</span
                        >
                      </div>
                    </template>
                    <el-input
                      type="textarea"
                      :rows="3"
                      placeholder="请输入接待初诊、备注（必填）"
                      v-model="form.firstVisitRemark"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="23" :offset="1">
                  <el-form-item>
                    <template slot="label">
                      <div style="line-height: 20px; font-size: 14px">
                        车单备注
                        <div style="color: #999; font-size: 12px">
                          (客户不可见)
                        </div>
                      </div>
                    </template>
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入门店内部备注"
                      v-model="form.companyRemark"
                    >
                    </el-input>
                    <div class="flex">
                      <div>
                        <el-checkbox
                          v-model="form.isReturnCar"
                          :true-label="1"
                          :false-label="0"
                          >退车整备</el-checkbox
                        >
                        <el-tooltip content="退车整备车单">
                          <i class="color626 iconfont grow-icon_doubt"></i>
                        </el-tooltip>
                      </div>
                      <div style="margin-left: 8px">
                        <el-checkbox
                          v-model="form.isRepair"
                          :true-label="1"
                          :false-label="0"
                          >返修</el-checkbox
                        >
                        <el-tooltip content="车辆返修车单">
                          <i class="color626 iconfont grow-icon_doubt"></i>
                        </el-tooltip>
                      </div>
                      <div style="margin-left: 8px">
                        <el-checkbox
                          v-model="form.isSupplementaryOrder"
                          :true-label="1"
                          :false-label="0"
                          >补单</el-checkbox
                        >
                        <el-tooltip content="非真实进店，车单补录。">
                          <i class="color626 iconfont grow-icon_doubt"></i>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="flex">
                      <div>
                        <el-checkbox
                          v-model="form.isClaimSettlToSelfPayment"
                          :true-label="1"
                          :false-label="0"
                          >理赔转自费</el-checkbox
                        >
                        <el-tooltip
                          content="用于统计没有买车损险转自费的车辆，业务来源还是建客户自费，但是算事故车进场台次里"
                        >
                          <i class="color626 iconfont grow-icon_doubt"></i>
                        </el-tooltip>
                      </div>
                      <div style="margin-left: 8px">
                        <el-checkbox
                          v-model="form.isClaimSettlFullLoss"
                          :true-label="1"
                          :false-label="0"
                          >全损</el-checkbox
                        >
                        <el-tooltip content="理赔的全损报废车辆">
                          <i class="color626 iconfont grow-icon_doubt"></i>
                        </el-tooltip>
                      </div>
                      <div style="margin-left: 8px">
                        <el-checkbox
                          v-model="form.isClaimExternalService"
                          :true-label="1"
                          :false-label="0"
                          >外服</el-checkbox
                        >
                        <el-tooltip content="索赔的电池外服">
                          <i class="color626 iconfont grow-icon_doubt"></i>
                        </el-tooltip>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="23" :offset="1">
                  <el-form-item label="索赔情况">
                    <div class="flex">
                      <el-select
                        v-model="form.isClaimUnderWarranty"
                        placeholder="在保情况"
                      >
                        <el-option label="在保" :value="1"></el-option>
                        <el-option label="不在保" :value="0"> </el-option>
                      </el-select>
                      <el-select
                        style="margin-left: 5%"
                        v-model="form.isClaimActivity"
                        placeholder="活动情况"
                      >
                        <el-option label="有活动" :value="1"></el-option>
                        <el-option label="无活动" :value="0"> </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="23" :offset="1">
                  <el-form-item>
                    <template slot="label">
                      <div style="line-height: 20px; font-size: 14px">
                        返修追溯备注
                        <div style="color: #999; font-size: 12px">
                          (客户不可见)
                        </div>
                      </div>
                    </template>
                    <el-input
                      type="textarea"
                      :rows="2"
                      readonly
                      placeholder="请输入返修追溯备注"
                      v-model="form.repairRemark"
                    >
                    </el-input>
                    <div class="flex">
                      <div style="margin-left: 8px; margin-top: 10px">
                        <el-button
                          type="primary"
                          size="small"
                          @click="openRepairPopover"
                          >返修追溯</el-button
                        >
                        <span
                          v-if="form.tracingBillId != undefined"
                          style="margin-left: 10px"
                          >已选择{{ form.tracingBillCode }}</span
                        >
                        <i
                          v-if="form.tracingBillId != undefined"
                          class="pointer iconfont grow-a-icon_tabbar_close icon-sty"
                          style="color: #ff9200"
                          title="清除返修追溯车单"
                          @click="clearRepairTracingBill"
                        ></i>
                        <!--
                        <el-button
                          class="btn_search"
                          type="primary"
                          size="small"
                          v-if="form.tracingBillId"
                          @click="clearRepairTracingBill"
                          >清除返修追溯</el-button
                        >
                        -->
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>
          </el-form>
          <div class="btns flex-x-between">
            <div class="flex">
              <div class="bao-btn">
                报价项目
                <el-tooltip effect="light" placement="bottom-start">
                  <div
                    slot="content"
                    style="color: #666; font-size: 13px; line-height: 20px"
                  >
                    <div class="flex">
                      <span>报价说明:</span>
                      <p>
                        1、报价项目,先添加服务项目,再在服务项目下添加商品(“商品及库存”列,操作【添加】或【批量添加】搜索选择报价商品),<br />
                        2、会员卡内服务项目请从【批量选择服务】入口进入进行选择报价;会员卡内商品项目请从“商品及库存”列的【批量添加】入口进入进行选择报价;<br />
                        3、报价的商品,将在接待开单或保存接车单后自动保存到接车单的“领用材料”清单里,可在“领用材料”入口进入进行领料出库
                      </p>
                    </div>
                  </div>
                  <i class="iconfont grow-icon_doubt"></i>
                </el-tooltip>
              </div>
              <div class="flex-y-center" v-if="[0, 1].includes(billType)">
                <el-tag type="info" size="mini" class="el-icon--left"
                  >协议</el-tag
                >
                <p style="font-size: 12px" class="color626">
                  存在该标识时该项目与客户约定的协议价，不允许修改报价
                </p>
              </div>
            </div>
          </div>

          <el-table
            style="width: 100%"
            class="service-table"
            border
            :data="serviceTableData"
            default-expand-all
            row-key="id"
            :tree-props="{ children: 'billGoods', hasChildren: 'hasChildren' }"
            :span-method="spanMethod"
          >
            <el-table-column width="60" label="操作" align="center">
              <template slot-scope="scope">
                <span v-if="!scope.row.isChild">
                  <i
                    style="color: #ff9200; font-size: 22px"
                    class="el-icon-delete pointer table-del-icon"
                    @click="deleteRow(scope.row, scope.$index)"
                  ></i>
                </span>
                <span v-else>
                  <i
                    style="color: #ff9200; font-size: 22px"
                    class="iconfont grow-icon_delete3 pointer table-del-icon"
                    @click="deleteRow(scope.row, scope.$index)"
                  ></i>
                </span>
              </template>
            </el-table-column>
            <el-table-column width="60" label="序号" prop="title">
            </el-table-column>
            <el-table-column label="项目名称" min-width="160">
              <template #header>
                项目名称
                <el-tooltip content="批量添加">
                  <i
                    class="iconfont grow-icon_add primary pointer"
                    @click="multipleSelectServiceDrawerVisibility = true"
                  ></i>
                </el-tooltip>
              </template>
              <template #default="{ row, $index }">
                <span v-if="row.name">
                  <el-tag
                    v-if="row.isAgreePrice"
                    type="info"
                    size="mini"
                    class="el-icon--left"
                    >协议</el-tag
                  >
                  <span>{{ row.name }}</span>
                  <p class="red" v-if="row.lastUseDate">
                    最近服务: {{ row.lastUseDate }}
                  </p>
                </span>
                <div v-else>
                  <select-goods-popover
                    ref="selectGoodsPopover"
                    :carId="carInfo.carId"
                    :code="row.keywordOfCode"
                    :isAgreePrice="editRow.isAgreePrice"
                    :serviceItemId="editRow.serviceItemId"
                    :billType="billType"
                    :clientAgreeId="
                      customerAgreementId ||
                      (isOthersSelfPayState ? '' : carAgreementId)
                    "
                    :serviceProducts="serviceProducts"
                    :carModel.sync="carModelKeyword"
                    :isMatchThisModel="
                      carModelKeyword === oldCarModelName ? 1 : 0
                    "
                    :billCompanyId="createCompanyId"
                    @select="onSelectGoodsItem($event, row, $index)"
                  ></select-goods-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="项目编码" min-width="150">
              <template #default="{ row }">
                <span v-if="row.name">{{ row.code }}</span>
                <div v-else>
                  <el-input
                    id="codeInput"
                    v-model="row.keywordOfCode"
                    clearable
                    @focus="showSelectGoodsPopover"
                    placeholder="搜索自编码"
                    size="small"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品及库存" min-width="110">
              <template #default="{ row, $index }">
                <div v-if="row.name">
                  <div v-if="!row.isChild">
                    <el-dropdown
                      size="mini"
                      split-button
                      @click="addGoodsRow(row)"
                    >
                      添加
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          @click.native="
                            showMultipleSelectGoodsDrawer(row, $index)
                          "
                          >批量添加</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div v-else>
                    <span>{{ row.inventoryTotalNum }}</span>
                    <span style="margin-left: 5px">{{ row.unit }}</span>
                  </div>
                </div>
                <div v-else>
                  <el-button
                    v-auth="19"
                    class="orange-btn"
                    type="text"
                    size="small"
                    @click="addGoodsDialogVisibility = true"
                    >新增商品</el-button
                  >
                </div>
              </template>
            </el-table-column>
            <!-- 1.0隐藏 -->
            <!-- <el-table-column label="项目标签" width="220">
            <template #default="{ row }">
              <el-select
                size="small"
                v-model="row.externalBillLabelIds"
                multiple
                placeholder="请选择(可多选)"
              >
                <el-option
                  v-for="item in labelList"
                  :key="item.billLabelId"
                  :label="item.name"
                  :value="item.billLabelId"
                >
                </el-option>
                <li v-if="labelHasMore" class="el-select-dropdown__item">
                  <el-button type="text" @click="getLabelList">
                    加载更多
                  </el-button>
                </li>
                <span
                  v-else
                  class="w100 color666 flex-x-center"
                  style="padding: 5px 0; background: #f8f8f8; font-size: 14px"
                  >已加载全部</span
                >
              </el-select>
            </template>
          </el-table-column> -->
            <el-table-column prop="price" label="单价" min-width="90">
              <template #default="{ row }">
                <template v-if="row.isAgreePrice">
                  <span @click="showDisabledReason">{{ row.price }}</span>
                </template>
                <template v-else>
                  <number-input
                    v-if="row.name"
                    :key="tableKey + 1"
                    v-model="row.price"
                    size="small"
                    :nullable="false"
                    :decimalDigits="2"
                    :fixedLength="2"
                    :clearable="false"
                    @blur="changePrice(row)"
                  ></number-input>
                </template>
              </template>
            </el-table-column>
            <!-- 协议价赠送配置 -->
            <el-table-column label="赠送" min-width="90">
              <template #header>
                <el-tooltip effect="light" placement="bottom-start">
                  <div
                    slot="content"
                    style="color: #666; font-size: 13px; line-height: 20px"
                  >
                    <div class="flex">
                      满足条件，附赠的服务(可以是项目或者商品配件)
                    </div>
                  </div>
                  <i class="iconfont grow-icon_doubt"></i>
                </el-tooltip>
                赠送
              </template>
              <template #default="{ row }">
                <template v-if="row.isAgreePrice">
                  <el-checkbox
                    v-model="row.agreementFree"
                    :true-label="true"
                    :false-label="false"
                    @change="(newValue) => changeAgreementFree(newValue, row)"
                    >赠送</el-checkbox
                  >
                  <el-input
                    type="textarea"
                    :rows="3"
                    :disabled="!row.agreementFree"
                    placeholder="请输入赠送说明"
                    v-model="row.agreementFreeRemark"
                  >
                  </el-input>
                </template>
              </template>
            </el-table-column>
            <!-- 折旧费配置 -->
            <el-table-column label="折旧费" min-width="200">
              <template #header>
                <el-tooltip effect="light" placement="bottom-start">
                  <div
                    slot="content"
                    style="color: #666; font-size: 13px; line-height: 20px"
                  >
                    <div class="flex">配件换新等抵扣</div>
                  </div>
                  <i class="iconfont grow-icon_doubt"></i>
                </el-tooltip>
                折旧费
              </template>
              <template #default="{ row }">
                <!-- 配件的 赠送的不能配置 -->
                <template v-if="row.goodsId && !row.agreementFree">
                  <el-row :gutter="10">
                    <el-col :span="8" :offset="0">
                      <el-checkbox
                        style="margin-top: 7px"
                        v-model="row.isDepreciation"
                        :true-label="true"
                        :false-label="false"
                        @change="
                          (newValue) =>
                            changeDepreciationChargeCheckBox(newValue, row)
                        "
                        >折旧</el-checkbox
                      >
                    </el-col>
                    <el-col :span="16" :offset="0">
                      <number-input
                        v-model="row.depreciationCharge"
                        decimalDigits="2"
                        :min="0.0"
                        :max="999999.99"
                        placeholder="请输入折旧费"
                        clearable
                        @change="
                          (newValue) => changeDepreciationCharge(newValue, row)
                        "
                      >
                      </number-input>
                    </el-col>
                  </el-row>
                  <el-input
                    style="margin-top: 4px"
                    type="textarea"
                    :rows="3"
                    :maxlength="20"
                    :disabled="!row.isDepreciation"
                    placeholder="折旧备注(字数限制在20个字内)"
                    v-model="row.depreciationRemark"
                  >
                  </el-input>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="discount" width="110">
              <template #header>
                <div v-show="editDiscountBatch">
                  <number-input
                    style="width: 50px"
                    v-model="batchDiscountNumber"
                    size="mini"
                    :max="9.9"
                    :decimalDigits="1"
                    :enabledZero="false"
                    placeholder="请输入"
                  ></number-input>
                  <el-button
                    style="padding: 7px 2px; margin-left: 4px"
                    size="mini"
                    @click="handleBatchSetDiscount"
                    >确定</el-button
                  >
                </div>
                <div v-show="!editDiscountBatch">
                  <span>折扣</span>
                  <el-tooltip content="批量设置折扣(协议项目不支持打折)">
                    <!-- <template #content>
                      <p
                        style="color: #666; font-size: 13px; line-height: 20px"
                      >

                      </p>
                    </template> -->
                    <!-- <i class="iconfont grow-icon_doubt"></i> -->
                    <i
                      style="font-size: 12px"
                      class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                      @click="editDiscountBatch = true"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <template #default="{ row }">
                <number-input
                  v-if="row.name"
                  :key="tableKey + 2"
                  v-model="row.discount"
                  size="small"
                  :disabled="row.isAgreePrice"
                  :max="9.9"
                  :decimalDigits="1"
                  :enabledZero="false"
                  placeholder="无"
                  @blur="changeDiscount(row)"
                  @clear="changeDiscount(row)"
                ></number-input>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="数量" min-width="80">
              <template #default="{ row }">
                <number-input
                  v-if="row.name"
                  :key="tableKey + 3"
                  v-model="row.num"
                  size="small"
                  :clearable="false"
                  :min="0.01"
                  :decimalDigits="2"
                  @blur="changeNumber(row)"
                ></number-input>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="小计" min-width="80">
              <template #default="{ row }">
                <div v-if="row.name">
                  <!-- <number-input
                    :key="tableKey + 4"
                    v-model="row.total"
                    size="small"
                    :decimalDigits="2"
                    :fixedLength="2"
                    :nullable="false"
                    :clearable="false"
                    @blur="changeTotal(row)"
                  ></number-input> -->
                  <span :key="tableKey + 4">{{ row.total }}</span>
                  <div v-if="billDiscountTotalPrice != billTotalPrice">
                    <span>整单折扣后: </span>
                    <span>¥{{ row.billDiscount | toThousands }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="100" label="项目合计">
              <template #default="{ row }">
                <div v-if="billDiscountTotalPrice == billTotalPrice">
                  <div>{{ row.itemTotalBefore | toThousands }}</div>
                </div>
                <div v-else>
                  <div>{{ row.itemTotalAfter | toThousands }}</div>
                  <del>{{ row.itemTotalBefore | toThousands }}</del>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100">
              <template #header>
                <span>施工技师</span>
                <i
                  v-auth="95"
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                  @click="showSelectTechniciansDialogForAllItem"
                ></i>
              </template>
              <template #default="{ row, $index }">
                <div :key="tableKey + 5">
                  <!-- 不可编辑 -->

                  <div
                    v-if="
                      !id ||
                      !checkCurrentCompanyHasEnableDispatch ||
                      row.dispatchTaskState === undefined ||
                      [5, 6].includes(row.dispatchTaskState)
                    "
                  >
                    <div v-if="$lodash.get(row, 'technicianIds.length', false)">
                      <div>
                        {{
                          row.technicianIds
                            .map((item) => item.realName)
                            .join('、')
                        }}
                        <i
                          style="font-size: 14px"
                          class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                          @click="showSelectTechniciansDialog(row, $index)"
                        ></i>
                      </div>
                    </div>
                    <!-- <div v-else-if="row.isConstructionFree">
                      免施工
                      <i
                        style="font-size: 14px"
                        class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                        @click="showSelectTechniciansDialog(row, $index)"
                      ></i>
                    </div> -->
                    <div v-else>
                      <span
                        class="blue pointer"
                        v-auth="95"
                        @click="showSelectTechniciansDialog(row, $index)"
                        >点击设置</span
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="$lodash.get(row, 'technicianIds.length', false)">
                      <div>
                        {{
                          row.technicianIds
                            .map((item) => item.realName)
                            .join('、')
                        }}
                      </div>
                    </div>
                    <!-- <div v-else-if="row.isConstructionFree">免施工</div> -->
                  </div>
                  <div class="orange">
                    {{ row.dispatchTaskState | dict('dispatchTaskState') }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="业务员" width="100">
              <template slot="header">
                <span>业务员</span>
                <i
                  style="font-size: 14px"
                  class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                  @click="showSelectSalesmanDialogForAllItem"
                ></i>
              </template>
              <template #default="{ row, $index }">
                <div :key="tableKey + 8">
                  <div v-if="row.sellerId">
                    <div>
                      {{ row.sellerName }}
                      <i
                        style="font-size: 14px"
                        class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
                        @click="showSelectSalesmanDialog(row, $index)"
                      ></i>
                    </div>
                  </div>
                  <div v-else>
                    <el-button
                      class="blue"
                      type="text"
                      size="small"
                      @click="showSelectSalesmanDialog(row, $index)"
                      >点击设置</el-button
                    >
                  </div>
                </div>
              </template>
            </el-table-column> -->
            <!-- cgd 上传图片操作由app处理 -->
            <!-- <el-table-column prop="address" label="施工前图片" width="120">
            <template #default="{ row }">
              <div :key="tableKey + 6">
                <div>
                  <el-button
                    class="blue"
                    v-if="
                      $lodash.get(row, 'preConstructionPics', '').split(',')
                        .length < 10
                    "
                    size="small"
                    type="text"
                    @click="chooseFile(row, 'preConstructionPics')"
                    >添加图片</el-button
                  >
                </div>
                <el-popover placement="top" width="450" trigger="click">
                  <upload-file-list
                    v-model="row.preConstructionPics"
                    @input="tableKey = Math.random()"
                  ></upload-file-list>
                  <span
                    style="margin: 0 16px"
                    class="pointer blue"
                    slot="reference"
                    >图片({{
                      $lodash
                        .get(row, 'preConstructionPics', '')
                        .split(',')
                        .filter((item) => item).length
                    }})</span
                  >
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="施工后图片" width="120">
            <template #default="{ row }">
              <div :key="tableKey + 7">
                <div>
                  <el-button
                    class="blue"
                    v-if="
                      $lodash.get(row, 'afterConstructionPics', '').split(',')
                        .length < 10
                    "
                    size="small"
                    type="text"
                    @click="chooseFile(row, 'afterConstructionPics')"
                    >添加图片</el-button
                  >
                </div>
                <el-popover placement="top" width="450" trigger="click">
                  <upload-file-list
                    v-model="row.afterConstructionPics"
                    @input="tableKey = Math.random()"
                  ></upload-file-list>
                  <span
                    style="margin: 0 16px"
                    class="pointer blue"
                    slot="reference"
                    >图片({{
                      $lodash
                        .get(row, 'afterConstructionPics', '')
                        .split(',')
                        .filter((item) => item).length
                    }})</span
                  >
                </el-popover>
              </div>
            </template>
          </el-table-column> -->
            <!-- <el-table-column
          prop="date"
          label="项目外部标签与备注（客户可见）"
          width="180"
        >
        </el-table-column> -->
            <template slot="empty">
              <div style="border-bottom: 1px solid #ebeef5">未添加服务</div>
            </template>
            <!-- 表格尾部 新增行 -->
            <template slot="append">
              <div class="flex add-service-item">
                <div style="width: 160px">
                  <select-service-popover
                    :billType="billType"
                    :clientAgreeId="
                      customerAgreementId ||
                      (isOthersSelfPayState ? '' : carAgreementId)
                    "
                    :carId="carInfo.carId"
                    :billId="form.billId"
                    :billCompanyId="createCompanyId"
                    @select="onSelectServiceItem"
                  ></select-service-popover>
                </div>
                <el-button
                  v-auth="25"
                  style="margin-left: 20px"
                  class="orange-btn"
                  type="text"
                  size="small"
                  @click="addServiceDialogVisibility = true"
                  >新增服务</el-button
                >
              </div>
            </template>
          </el-table>
        </div>
      </div>
      <el-dialog
        title="修改单据金额"
        :visible.sync="changeTotalPriceDialogVisible"
        width="400px"
        :close-on-click-modal="false"
      >
        <template slot="title">
          <span>修改单据金额</span>
          <el-tooltip effect="light" placement="bottom-start">
            <template slot="content">
              <p class="color666" style="line-height: 20px">
                整个折扣分摊到单个项目规则,非最后一单项按计算整单折扣分摊金额:<br />
                单折后小计=单项折后小计-单项折后金额/整单斤1折前金额合计X整单<br />
                的折扣优惠(仅显示两个小数位,四舍五入)金最后一单项分摊金额=该<br />
                单据剩余未分摊金额;
              </p>
            </template>
            <i
              class="iconfont grow-icon_doubt color666 pointer el-icon--right"
            ></i>
          </el-tooltip>
        </template>
        <div style="color: #333">
          <el-form label-width="100px">
            <el-form-item label="折前金额">
              ¥{{ billTotalPrice | toThousands }}
            </el-form-item>
            <el-form-item label="折扣">
              <number-input
                v-model="billDiscountTemp"
                size="small"
                :max="9.9"
                :decimalDigits="1"
                placeholder="无"
                @input="changeBillDiscountTemp"
                @blur="changeBillDiscountTemp"
                @clear="changeBillDiscountTemp"
              ></number-input>
            </el-form-item>
            <el-form-item label="折后金额">
              <number-input
                v-model="billDiscountPriceTemp"
                size="small"
                :decimalDigits="2"
                :clearable="false"
                placeholder="无"
                @blur="changeBillDiscountPriceTemp"
              ></number-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer">
          <el-button @click="changeTotalPriceDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmBillDiscount"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 新增车辆弹窗 -->
      <add-car-dialog
        :showAddCarDialog.sync="addCarDialogVisibility"
        @save="onAddCar"
      ></add-car-dialog>
      <!-- 批量添加服务 -->
      <select-services-for-car-bill
        v-if="multipleSelectServiceDrawerVisibility"
        :visibility.sync="multipleSelectServiceDrawerVisibility"
        :oldData="serviceTableData"
        :carId="carInfo.carId"
        :billId="form.billId"
        :clientAgreeId="
          customerAgreementId || (isOthersSelfPayState ? '' : carAgreementId)
        "
        :billCompanyId="createCompanyId"
        @select="multipleSelectService"
      ></select-services-for-car-bill>
      <!-- 添加返修追溯车单 -->
      <selectRepairTracingBillDrawer
        v-if="selectRepairTracingBillDrawerVisibility"
        :visibility.sync="selectRepairTracingBillDrawerVisibility"
        :carId="carInfo.carId"
        @select="onSelectRepairTracingBill"
      ></selectRepairTracingBillDrawer>
      <!-- 批量添加商品 -->
      <select-goods-for-car-bill
        v-if="multipleSelectGoodsDrawerVisibility"
        :visibility.sync="multipleSelectGoodsDrawerVisibility"
        :oldData="editRow.billGoods || []"
        :serviceProducts="serviceProducts"
        :isAgreePrice="editRow.isAgreePrice"
        :serviceItemId="editRow.serviceItemId"
        :carId="carInfo.carId"
        :carModel.sync="carModelKeyword"
        :isMatchThisModel="carModelKeyword === oldCarModelName ? 1 : 0"
        :billType="billType"
        :clientAgreeId="
          customerAgreementId || (isOthersSelfPayState ? '' : carAgreementId)
        "
        :billCompanyId="createCompanyId"
        @select="multipleSelectGoods"
      ></select-goods-for-car-bill>
      <!-- 选择技师 -->
      <select-technicians-dialog
        v-if="selectTechniciansDialogVisibility"
        :visibility.sync="selectTechniciansDialogVisibility"
        @select="handleSelectTechnicians"
      ></select-technicians-dialog>
      <!-- 选择业务员 -->
      <select-salesman-dialog
        v-if="selectSalesmanDialogVisibility"
        :visibility.sync="selectSalesmanDialogVisibility"
        @select="handleSelectSalesman"
      ></select-salesman-dialog>

      <!-- 保养方案 -->
      <maintenance-plan-dialog
        v-if="maintenancePlanDialogVisibility"
        :maintenancePlanDialogVisibility.sync="maintenancePlanDialogVisibility"
        :carInfo="carInfo"
        :mileage="form.mileage"
      >
      </maintenance-plan-dialog>

      <!-- 客户备注抽屉 -->
      <remarks-drawer
        v-if="remarksDrawerVisibility"
        :remarksDrawerVisibility.sync="remarksDrawerVisibility"
        :clientRemark="clientRemark"
      ></remarks-drawer>

      <!-- 车辆绑定客户 -->
      <car-binding-customer
        v-if="carBindingCustomerDialogVisibility"
        :visibility.sync="carBindingCustomerDialogVisibility"
        :carId="carInfo.carId"
        @binding="handleBindingClient"
      ></car-binding-customer>
      <!-- 选择他人自费客户 -->
      <select-other-pay-client-dialog
        v-if="selectOtherPayClientDialogVisibility"
        :visibility.sync="selectOtherPayClientDialogVisibility"
        @select="handleSelectOtherPayClient"
        @clear="handleClearSelectOtherPayClient"
      ></select-other-pay-client-dialog>
      <!-- 选择 理赔 他人推修客户 -->
      <select-other-pay-client-dialog
        v-if="selectOtherClientPushDialogVisibility"
        :visibility.sync="selectOtherClientPushDialogVisibility"
        @select="handleSelectOtherClientPush"
        @clear="handleClearSelectOtherClientPush"
      ></select-other-pay-client-dialog>
      <!-- 选择 理赔 消费客户 -->
      <select-other-pay-client-dialog
        v-if="selectInsuranceCustomerDialogVisibility"
        :visibility.sync="selectInsuranceCustomerDialogVisibility"
        @select="handleSelectInsuranceCustomer"
        @clear="handleClearSelectInsuranceCustomer"
      ></select-other-pay-client-dialog>
      <!-- 车单商品检测 -->
      <edit-car-bill-goods-drawer
        v-if="editCarBillGoodsDrawerVisibility"
        :visibility.sync="editCarBillGoodsDrawerVisibility"
        :carId="carInfo.carId"
        :list="exceptionList"
        :treeData="goodsTree"
        @save="mergeGoods"
      ></edit-car-bill-goods-drawer>
      <!-- 客户指令 -->
      <client-command-drawer
        v-if="clientCommandDrawerVisibility"
        :visibility.sync="clientCommandDrawerVisibility"
        :clientId="carInfo.clientId"
        :defaultActiveName="billType | dict('defaultActiveName')"
      >
      </client-command-drawer>
      <!-- 新增服务 -->
      <add-service-dialog
        v-if="addServiceDialogVisibility"
        :showServiceManagementDialog.sync="addServiceDialogVisibility"
      ></add-service-dialog>
      <!-- 新增商品 -->
      <add-goods-dialog
        v-if="addGoodsDialogVisibility"
        :visibility.sync="addGoodsDialogVisibility"
      ></add-goods-dialog>
      <!-- 索赔活动弹出框 -->
      <carFactoryCompensationActivityDialog
        v-if="carFactoryCompensationActivityDialogVisibility"
        :visibility.sync="carFactoryCompensationActivityDialogVisibility"
        :data="carFactoryCompensationActivityData"
        @serviceItemClick="onAddCarFactoryCompensationActivity"
      >
      </carFactoryCompensationActivityDialog>
    </template>
    <template slot="footer">
      <div class="footer-box" v-if="![0, 1, 2, 3].includes(this.typeStatus)">
        <div>
          <div class="bill-price-box">
            <span class="color333"> 单据总额：</span>
            <span class="rmb">¥</span>
            <!-- <span v-if="[0, '', undefined].includes(billDiscount)">
              <span class="price-number" style="margin-right: 4px">{{
                billTotalPrice | toThousands
              }}</span>
            </span>
            <span v-else> -->
            <span>
              <span class="price-number" style="margin-right: 4px">{{
                billDiscountTotalPrice | toThousands
              }}</span>
              <del
                v-if="billDiscountTotalPrice != billTotalPrice"
                class="original-price color999"
                >¥{{ billTotalPrice | toThousands }}</del
              >
            </span>
            <i
              class="iconfont grow-a-icon_fillout color666 el-icon--right pointer"
              @click="showBillDiscountEditDialog"
            ></i>
          </div>
          <p class="price-detail">
            项目报价总额：¥{{ billTotalPrice | toThousands
            }}<span v-if="billDiscountTotalPrice != billTotalPrice">（</span
            >服务项目：¥{{ serviceTotalPrice | toThousands }} + 商品项目：¥{{
              goodsTotalPrice | toThousands
            }}<span v-if="billDiscountTotalPrice != billTotalPrice"
              >）- 整单折扣优惠：¥{{
                (billTotalPrice - billDiscountTotalPrice) | toThousands
              }}</span
            >
          </p>
        </div>
        <div>
          <el-button size="small" type="primary" @click="saveBill">{{
            id ? '保 存' : '接待开单'
          }}</el-button>
          <el-button v-if="id" size="small" @click="verifyGoodsQuantity"
            >商品检测</el-button
          >
          <el-button size="small" @click="close">取 消</el-button>
        </div>
      </div>
      <div v-else class="footer-box" style="justify-content: flex-end">
        <div>
          <el-button size="small" type="primary" @click="saveBill"
            >变更</el-button
          >
          <el-button size="small" @click="close">取 消</el-button>
        </div>
      </div>
    </template>
  </base-container>
</template>

<script>
// 接口
import {
  insertCarBill,
  checkMakeCarBill,
  checkMakeCarBill30Day,
  getCarBillDetail,
  updateCarBillDetail,
  billGoodsEditCheck,
  searchCarForCreateCarBill,
  searchClientForCreateCarBill,
  checkCarExistenceServiceTakeByCarId,
  getCarQueryWarranty,
  getClientSettlementWay as getClientSettlementWayApi,
  getAgreementPriceByConsumer,
  checkQuotationApi,
  findCarFactoryCompensationActivityByCarId,
} from '@/api/carBill';
import { searchStore as searchStoreApi } from '@/api/customer/store';
import { searchInvoice } from '@/api/storeManagement/index';
import { searchClient } from '@/api/customer/customer';
import { getBillLocakRelease } from '@/api/financial/pickSingle';
import { getLabelList as getLabelListApi } from '@/api/label';
import { getUserList } from '@/api/employee';
import { getAgreementDiscount } from '@/api/customer/agreement';
import verifyBeforeEditCarBill from '@/views/carBill/lock';
// 字典
import {
  carBillState,
  carBillStateIcon,
  serviceType,
} from '@/service/dict/dictData';

// 组件
import uploadFile from '@/components/uploadFile.vue';
import uploadFileList from '@/components/uploadFileList.vue';

import selectCarForCreateCarBillPopover from '@/components/businessComponent/selectCarForCreateCarBillPopover';
import selectInsuranceCompanyPopover from '@/components/businessComponent/selectInsuranceCompanyPopover.vue';
import selectUndertakeCompanyPopover from '@/components/businessComponent/selectUndertakeCompanyPopover';
import clientCommandDrawer from '@/components/businessComponent/clientCommandDrawer';

import selectServicePopover from '@/components/businessComponent/selectServicePopover.vue';
import selectRepairTracingBillDrawer from '@/components/businessComponent/selectRepairTracingBillDrawer.vue';
import selectServicesForCarBill from '@/components/businessComponent/selectServicesForCarBill';
import selectGoodsPopover from '@/components/businessComponent/selectGoodsPopover.vue';
import selectGoodsForCarBill from '@/components/businessComponent/selectGoodsForCarBill';

import selectBillSourcePopover from '@/components/businessComponent/selectBillSourcePopover.vue';
import selectTechniciansDialog from '@/components/businessComponent/selectTechniciansDialog.vue';
import selectSalesmanDialog from '@/components/businessComponent/selectSalesmanDialog';

import maintenancePlanDialog from '@/components/businessComponent/maintenancePlanDialog';
import remarksDrawer from '@/views/carBill/editCarBill/remarksDrawer.vue';

import addCarDialog from '@/views/customerManagement/car/addCar/addCarDialog';
import carBindingCustomer from '@/components/businessComponent/carBindingCustomer';

import editCarBillGoodsDrawer from '@/views/carBill/editCarBill/editCarBillGoodsDrawer';

import EditCarBillGoodsDrawer from './editCarBillGoodsDrawer.vue';
import selectOtherPayClientDialog from '@/components/businessComponent/selectOtherPayClientDialog.vue';

import editGoodsDialog from '@/views/stock/setting/goodsManage/components/editGoodsDialog.vue';
import serviceManagementDialog from '@/views/systemManagement/carOrderSettings/serviceManagement/serviceManagementDialog.vue';

import carFactoryCompensationActivityDialog from './components/carFactoryCompensationActivityDialog.vue';

// 字典
import {
  difficulty,
  energyPercentage,
  dutyType,
} from '@/service/dict/dictData';
import { levelIcons, levelIconsWord } from '@/const/client';

// 权限
import { verifyAuth } from '@/utils/permissions';

// 工具
import {
  chooseFile as chooseFileMethod,
  chooseAndUploadFiles,
} from '@/utils/fileApi';
import { getDHMS } from '@/utils/tools/date';
import Big from 'big.js';
import { mapState } from 'vuex';
// import {
//   admin1PagePrefix,
//   admin2PagePrefix,
// } from '@/config/compatibilityOlderSystems';

export default {
  name: 'editCarBill',
  components: {
    uploadFile,
    uploadFileList,
    selectCarForCreateCarBillPopover,
    selectInsuranceCompanyPopover,
    selectUndertakeCompanyPopover,
    selectServicePopover,
    selectRepairTracingBillDrawer,
    selectServicesForCarBill,
    selectGoodsPopover,
    selectGoodsForCarBill,
    selectBillSourcePopover,
    selectTechniciansDialog,
    selectSalesmanDialog,
    maintenancePlanDialog,
    remarksDrawer,
    addCarDialog,
    carBindingCustomer,
    editCarBillGoodsDrawer,
    EditCarBillGoodsDrawer,
    selectOtherPayClientDialog,
    clientCommandDrawer,
    addGoodsDialog: editGoodsDialog,
    addServiceDialog: serviceManagementDialog,
    carFactoryCompensationActivityDialog,
  },
  data() {
    return {
      id: null,
      // 车单类型（0：自费，1：包干，2：理赔，3：索赔）
      billType: 0,

      // 车辆信息(客户信息)
      carInfo: {},

      carBillStateIconDict: carBillStateIcon,
      // 客户等级对应图标
      levelIcons,
      levelIconsWord,

      // 主表单
      form: {
        planFinishTime: '',
        customerName: '',
        customerPhone: '',
        pickUpUserId: undefined,
        tracingBillId: undefined,
        tracingBillCode: '',
        repairRemark: '',
      },

      // 理赔信息
      billInsuranceDto: {},
      // 索赔信息
      billUndertakeDto: {
        claimsNumArr: [{ value: '' }],
      },

      // 是否他人自费
      isOthersSelfPayState: false,

      // 选择他人自费客户弹窗可见性
      selectOtherPayClientDialogVisibility: false,

      // 消费客户
      payerInfo: {},

      // 接车员
      employeeList: [],
      loadingEmployee: false,

      difficultyDicts: difficulty,
      energyPercentage: energyPercentage,
      dutyTypeDicts: dutyType,
      // 服务列表表格
      serviceTableData: [],

      // 标签列表
      labelList: [],
      labelPageSize: 10,
      labelPageCurrent: 1,
      labelHasMore: true,

      tableKey: 123,

      // 单据总额
      billTotalPrice: 0,
      // 单据折扣后总额
      billDiscountTotalPrice: 0,
      // 单据折扣
      billDiscount: '',
      // 服务项目总额
      serviceTotalPrice: 0,
      // 商品总额
      goodsTotalPrice: 0,

      // 总价打折弹窗可见性
      changeTotalPriceDialogVisible: false,
      // 修改总价折扣弹窗中 折扣 临时变量
      billDiscountTemp: '',
      // 修改总价折扣弹窗中 折后总价 临时变量
      billDiscountPriceTemp: '',

      // 服务项，正在编辑 下标
      editIndex: 0,
      // 服务项，正在编辑 对象
      editRow: null,

      // 车辆绑定客户弹窗可见性
      carBindingCustomerDialogVisibility: false,
      // 新增车辆弹窗可见性
      addCarDialogVisibility: false,
      // 选择技师弹窗可见性
      selectTechniciansDialogVisibility: false,
      // 选择业务员弹窗可见性
      selectSalesmanDialogVisibility: false,
      // 保养方案弹窗可见性
      maintenancePlanDialogVisibility: false,
      // 批量添加服务项抽屉可见性
      multipleSelectServiceDrawerVisibility: false,
      // 批量添加商品抽屉可见性
      multipleSelectGoodsDrawerVisibility: false,
      // 车单商品检测抽屉可见性
      editCarBillGoodsDrawerVisibility: false,
      // 车单商品检测抽屉可见性
      carFactoryCompensationActivityDialogVisibility: false,
      carFactoryCompensationActivityData: [],
      // 商品检测 异常列表
      exceptionList: [],
      //商品检测，分类树租
      goodsTree: [],
      // 客户备注抽屉可见性
      remarksDrawerVisibility: false,
      clientRemark: '',

      // 是否使用协议折扣
      // useAgreementDiscount: false,

      // // 协议折扣
      // // 服务一级分类
      // firstStageService: [],
      // // 服务二级分类
      // secondStageService: [],
      // // 商品二级分类
      // firstStageGoods: [],
      // // 商品二级分类
      // secondStageGoods: [],
      // // 特殊折扣
      // specialDiscount: [],

      // 理赔开票主体 列表及是否正在加载变量
      storeList: [],
      searchStoreLoading: false,

      // 是否开启施工单
      checkCurrentCompanyHasEnableDispatch: true,
      //是否是变更业务类型
      typeStatus: '',

      //质保状态
      underWarrantyState: '',
      vehicleVo: [], //整车质保
      corePartsVo: [], //核心件质保
      wearingPartsVo: [], //易损件质保
      vehicleVoList: [], //整车质保
      corePartsVoList: [], //核心件质保
      wearingPartsVoList: [], //易损件质保

      //客户指令抽屉可见性
      clientCommandDrawerVisibility: false,

      // 添加商品 - 适用车型关键字
      carModelKeyword: '',
      // 车辆车型原始值
      oldCarModelName: '',
      // 返修追溯
      selectRepairTracingBillDrawerVisibility: false,
      // 添加服务-弹窗
      addServiceDialogVisibility: false,
      // 添加商品-弹窗
      addGoodsDialogVisibility: false,

      // 创建门店id
      createCompanyId: '',

      // 车主 现结/月结
      carOwnerSettlementWay: '',
      // 消费客户 现结/月结
      payerSettlementWay: '',

      // 批量设置折扣显示
      editDiscountBatch: false,
      // 批量折扣输入框
      batchDiscountNumber: '',

      // ======================== 理赔新增推修对象 交商不同 start ===============================
      // 是否他人推修
      isOtherClientPush: 0,
      // 他人推修
      pushClientId: '',
      pushClientName: '',
      // 选择他人推修弹窗
      selectOtherClientPushDialogVisibility: false,
      // 其他信息
      isInsuranceTurnSelf: '',
      // 交商不同
      isJqInfo: 0,
      // 交强险保险公司名称
      jqInsuranceName: '',
      // 交强险保险公司ID
      jqInsuranceId: '',
      // 交强险报案号
      jqCaseNum: '',
      // 其他信息消费客户
      beTurnConsumerName: '',
      beTurnConsumerId: '',
      // 选择理赔消费客户
      selectInsuranceCustomerDialogVisibility: false,
      // 免赔2000 或 转自费 消费客户 结算方式
      beTurnConsumerSettlementWay: '',
      // ======================== 理赔 end ===============================
      // ======================== 预约开单 begin ===============================
      // 预约开单来源业务类型
      fromBusinessType: null,
      // 来源业务ID
      fromBusinessTypeId: null,
      // 来源车牌号码
      fromCarNumber: null,
      // 来源车辆ID
      fromCarId: null,
      // 来源送修人
      fromContactUserName: null,
      // 来源送修人电话
      fromContactPhone: null,
      // 来源送修类型
      fromRepairType: null,
      // 来源初诊与备注
      fromFirstVisitRemark: null,
      // ======================== 预约开单 end ===============================
      doubleSysNumArr: [{ value: '' }],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    distancePlannedTime() {
      if (this.form.planFinishTime) {
        let now = new Date();
        let plan = new Date(this.form.planFinishTime);
        let difference = plan - now;
        let word = difference > 0 ? '剩余' : '已超时';
        difference = Math.abs(difference);
        let res = getDHMS(difference);
        return `${word}${res[0] ? res[0] + '天' : ''}${res[1]}时${res[2]}分`;
      } else {
        return '';
      }
    },
    carAgreementId() {
      return this.$lodash.get(
        this.carInfo,
        'billClientMemberMessageVo.agreementClientId',
        0
      );
    },
    customerAgreementId() {
      return (
        this.$lodash.get(
          this.payerInfo,
          'billClientMemberMessageVo.agreementClientId',
          0
        ) ||
        this.payerInfo.agreementClientId ||
        0
      );
    },
    serviceProducts() {
      return this.editRow?.serviceProducts || [];
    },
    rules() {
      const part1 = {
        planFinishTime: [{ required: true, message: '请选择预交车时间' }],
        billSourceType: [{ required: true, message: '请选择业务来源' }],
      };
      const part2 = {};
      if (this.isSpecificClient()) {
        part2.doubleSysInputState = [
          { required: true, message: '请选择系统录单情况' },
        ];
      }
      return Object.assign({}, part1, part2);
    },
  },
  created() {
    this.id = this.$route.query.id || null;
    this.typeStatus = this.$route.query.typeStatus;
    this.initGetCarInfoFromOtherPage();
    if (['0', '1', '2', '3'].includes(this.typeStatus)) {
      this.typeStatus = +this.typeStatus;
    }
    // this.$route.meta.title = this.id ? '编辑车单' : '接待开单';
    if (this.id) {
      this.getData();
      this.checkCarQueryWarranty(this.id, 1);
    } else {
      this.billType = Number(this.$route.query.type) ?? 0;
      // 重置表单
      this.initForm();
      let obj = {
        userId: this.userInfo.userId,
        realName: this.userInfo.realName,
      };
      this.employeeList.unshift(obj);
      this.createCompanyId = this.userInfo.companyId;
    }
    // 获取商品标签
    this.getLabelList();
    // 获取接车员列表
    // this.getEmployeeList();
    //获取开票主体
    this.searchStore();
    this.initSearchCarInfoFromOtherPage();
  },
  activated() {
    this.$store.commit('UPDATE_TAG_TITLE', {
      title: this.id
        ? [0, 1, 2, 3].includes(this.typeStatus)
          ? '变更业务'
          : '编辑车单'
        : '接待开单',
    });
  },
  beforeDestroy() {
    if (this.id) getBillLocakRelease(this.id);
  },
  methods: {
    initGetCarInfoFromOtherPage() {
      this.fromBusinessType = this.$route.query.businessType || null;
      this.fromBusinessTypeId = this.$route.query.businessTypeId || null;
      this.fromCarNumber = this.$route.query.carNumber || null;
      this.fromCarId = this.$route.query.carId || null;

      this.fromContactUserName = this.$route.query.contact || null;
      this.fromContactPhone = this.$route.query.contactPhone || null;
      this.fromRepairType = this.$route.query.repairType;
      this.fromFirstVisitRemark = this.$route.query.firstVisitRemark || '';
    },
    initSearchCarInfoFromOtherPage() {
      const _this = this;
      if (_this.fromCarId) {
        this.$set(this.carInfo, 'carNumber', _this.fromCarNumber);
        _this.onAddCar(_this.fromCarId);
      } else if (_this.fromCarNumber) {
        this.$set(this.carInfo, 'carNumber', _this.fromCarNumber);
        if (!_this.fromCarId) {
          _this.$message.warning(
            _this.fromCarNumber + ',车牌号码不在系统中，请添加车辆后再开接待单',
            6e3
          );
        }
        //  触发查询
        let clearSelectCarKeyWord = () => {
          _this.$refs?.selectCar?.getData(true, false);
        };
        clearSelectCarKeyWord();
      }
    },
    /**
     * 双系统录单情况，只有特定客户才展示
     */
    isSpecificClient() {
      // 环球、芯享智慧、小桔、亚滴、喜滴经租、舒适橙、橙电
      // 过期的也放进去了
      const _clientId = this.carInfo.clientId || 0;
      return [
        1701149, 2430786, 2644703, 2662917, 2817977, 2817984, 2818460, 3488578,
        4019765, 4093652, 4427814, 4494515, 4866950, 4868852, 4901353, 4919403,
        4928895, 4928928, 4937689, 4952039, 5072013, 5844812, 5846879, 5908015,
        6654376, 6844802, 6845302, 7150651, 7390929, 7503534, 7503709, 7503800,
        7503865, 7503895, 7503902, 7503915, 7503982, 7504203, 7504206, 7504271,
        7504299, 7504315, 7504326, 7504355, 7504391, 7504407, 7504429, 7504601,
        7504676, 7504677, 7504687, 7504754, 7504792, 7504798, 7506711, 7506766,
        7506841, 7506896, 7507152, 7507166, 7507191, 7507355, 7507439, 7507519,
        7507552, 7507581, 7507585, 7507586, 7507608, 7507723, 7507791, 7507875,
        7508005, 7508046, 7508292, 7508412, 7508515, 7508522, 7508523, 7508732,
        7508733, 7508797, 7508798, 7508799, 7508800, 7508801, 1368261796438016,
        1380220722585600, 1390115893075968, 7507158, 7507161, 7508036, 7508346,
        7508348, 7508392, 7508396, 7508404, 7508410, 7508417, 7508441, 7508455,
        7508468, 7508475, 7508476, 7508478, 7508531, 7508534, 7508541, 7508558,
        1373005794451457, 1373005810900992, 1373005818699776, 1373006065426432,
        1384609048027136, 1384609048035328, 1384609048076288, 1386100995768320,
        1404928895303680,
      ].includes(_clientId);
    },
    openRepairPopover() {
      this.selectRepairTracingBillDrawerVisibility = true;
    },
    /**
     * 重置表单为默认数据
     */
    initForm() {
      this.form = {
        planFinishTime: '',
        customerName: '',
        customerPhone: '',
        pickUpUserId: '',
      };
    },
    /**
     * 远程搜索门店列表
     */
    searchStore(keyWord = '') {
      this.searchStoreLoading = true;
      searchInvoice(keyWord, this.userInfo.companyId)
        .then((res) => {
          this.storeList = res;
        })
        .finally(() => {
          this.searchStoreLoading = false;
        });
    },
    clearRepairTracingBill() {
      console.log('edit_form be', this.form);
      this.form.repairRemark = '';
      this.form.tracingBillId = undefined;
      this.form.tracingBillCode = '';
      console.log('edit_form', this.form);
      this.$forceUpdate();
    },
    /**
     * 选择返修追溯关联车单
     * @param {Object} selBill
     */
    onSelectRepairTracingBill(selBill) {
      this.form.repairRemark =
        '门店：' +
        selBill.companyName +
        ',车单号：' +
        selBill.billCode +
        ',检车人:' +
        (selBill.detectionUserNames == undefined
          ? ''
          : selBill.detectionUserNames) +
        ', 施工人:' +
        (selBill.technicianNames == undefined ? '' : selBill.technicianNames);
      this.form.tracingBillId = selBill.billId;
      this.form.tracingBillCode = selBill.billCode;

      console.log('edit_form sel', this.form);
    },
    /**
     * 新增车辆 查询选中车辆信息
     */
    onAddCar(id) {
      searchCarForCreateCarBill({
        type: this.billType,
        companyId: this.userInfo.companyId,
        carId: id,
      }).then((res) => {
        this.selectCar(res.records[0]);
      });
    },
    /**
     * 预交车时间禁用日期
     */
    disabledDate(date) {
      return new Date(date).getTime() < new Date().setHours(0, 0, 0, 0);
    },
    /**
     * 获取接车员列表
     */
    getEmployeeList(keyWord) {
      this.loadingEmployee = true;
      getUserList(keyWord)
        .then((res) => {
          this.employeeList = res.records || [];
          // this.employeeList = this.employeeList.concat(res.records || []);

          // this.employeeList = this.$lodash.uniqBy(
          //   this.employeeList.concat(...(res.records || [])),
          //   'userId'
          // );
        })
        .finally(() => {
          this.loadingEmployee = false;
        });
    },
    /**
     * 清空接车员
     */
    handleClearPickUpUser() {
      this.getEmployeeList('');
    },

    /**
     * 选择车辆
     */
    async selectCar(car) {
      const _this = this;
      if (car === undefined || car === null) {
        return;
      }
      let clearSelectCarKeyWord = () => {
        this.$refs.selectCar.clearKeyword();
      };
      if (!this.id) {
        let verifyResult30 = await checkMakeCarBill30Day(
          this.billType,
          car.carId
        );
        if (verifyResult30.type === 3) {
          let res = await this.$confirm(verifyResult30.message, {
            title: '提示',
            cancelButtonText: '查看30天内记录',
            confirmButtonText: '是',
            distinguishCancelAndClose: true,
          }).catch((err) => {
            if (err === 'cancel') {
              this.$router.push({
                name: 'carBillList',
                params: {
                  forms: {
                    intoStoreTimes: [
                      verifyResult30.data.beginDateTime,
                      verifyResult30.data.endDateTime,
                    ],
                    billState: 45,
                    carNumber: verifyResult30.data.carNumber,
                  },
                },
              });
            }
          });
          if (!res) {
            clearSelectCarKeyWord();
            return;
          }
        }
        // 判断车辆是否允许被接待
        let verifyResult = await checkMakeCarBill(this.billType, car.carId);
        // type 0-成功;1-车单挂单中校验;2-车单类型重复中校验;
        if (verifyResult.type === 1) {
          let res = await this.$confirm(verifyResult.message, {
            title: '提示',
            cancelButtonText: '查看单据',
            confirmButtonText: '是',
            distinguishCancelAndClose: true,
          }).catch((err) => {
            if (err === 'cancel') {
              this.$router.push({
                name: 'carBillDetail',
                query: {
                  id: verifyResult.billId,
                },
              });
            }
          });
          if (!res) {
            clearSelectCarKeyWord();
            return;
          }
        }
        if (verifyResult.type === 2) {
          let res = await this.$confirm(verifyResult.message, {
            title: '提示',
            cancelButtonText: '查看单据',
            confirmButtonText: '是',
            distinguishCancelAndClose: true,
          }).catch((err) => {
            if (err === 'cancel') {
              this.$router.push({
                name: 'carBillDetail',
                query: {
                  id: verifyResult.billId,
                },
              });
            }
          });
          if (!res) {
            clearSelectCarKeyWord();
            return;
          }
        }
      }
      // 判断车辆是否存在 挂单 单据
      // let existenceService = await checkCarExistenceServiceTakeByCarId({
      //   id: car.carId,
      // });
      // if (existenceService) {
      //   this.$confirm(
      //     '车辆' +
      //       car.carNumber +
      //       '存在"挂单"状态的车单,不能操作新开单,请选择"查看单据"打开"挂单"的车单,或选择"取消接待"本车辆重新选择其他车辆接待',
      //     '温馨提示',
      //     {
      //       cancelButtonText: '取消接待',
      //       confirmButtonText: '查看单据',
      //     }
      //   )
      //     .then(() => {
      //       this.$router.push({
      //         name: 'carBillDetail',
      //         query: {
      //           id: existenceService,
      //         },
      //       });
      //     })
      //     .catch(() => {});
      //   clearSelectCarKeyWord();
      //   return;
      // }
      /**
       2.2、包干业务
            包干协议客户的车辆，且操作门店为协议包干业务的适用门店，可直接选择接待开单；
            包干协议客户的车辆，但操作门店非协议包干业务的适用门店，点击选择则直接弹窗温馨提示“包干协议客户的车辆，但本门店非协议包干业务的适用门店，车辆不能在本门店进行包干业务消费”，点击无效；
            非包干协议客户的车辆，不能被选择，点击选择则直接弹窗温馨提示“非包干协议客户的车辆，车辆不能进行包干业务消费”；
            2.3、理赔业务
            非协议客户的车辆，可直接选择接待开单；
            协议客户的车辆，且操作门店为协议理赔业务的适用门店，可直接选择接待开单；
            协议客户的车辆，但操作门店非协议理赔业务的适用门店，点击选择则直接弹窗温馨提示“协议客户的车辆，但本门店非协议理赔业务的适用门店，车辆不能在本门店进行理赔业务消费”，点击无效；
            2.4、索赔业务
            非协议客户的车辆，可直接选择接待开单；
            协议客户的车辆，且操作门店为协议索赔业务的适用门店，可直接选择接待开单；
            协议客户的车辆，但操作门店非协议索赔业务的适用门店，点击选择则直接弹窗温馨提示“协议客户的车辆，但本门店非协议索赔业务的适用门店，车辆不能在本门店进行索赔业务消费”，点击无效；
       */
      // 自费业务
      if (this.billType === 0) {
        // 是否为协议客户
        if (car?.billClientMemberMessageVo?.agreementClientId) {
          // 协议是否适用于当前门店
          if (car?.billClientMemberMessageVo?.isAgreementApplyStores) {
            let id = car?.billClientMemberMessageVo?.agreementClientId;
            // this.handleAgreement(id);
          }
          // 协议用户 在 非协议适用门店开单 时
          else if (!this.id) {
            let res = await this.$confirm(
              '该协议客户非本门店适用,无法使用自费折扣,是否继续开单?',
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
            ).catch(() => {});
            if (res !== 'confirm') {
              clearSelectCarKeyWord();
              return;
            }
          }
        }
      }

      if (this.id) {
        this.$set(this, 'carInfo', this.$lodash.cloneDeep(car));
        return;
      }

      // 重置表单
      this.initForm();

      if (this.billType === 1) {
        if (!car.billClientMemberMessageVo?.isAgreementClient) {
          this.$alert('非包干协议客户的车辆，车辆不能进行包干业务消费');
          clearSelectCarKeyWord();
          return;
        }
        if (!car.billClientMemberMessageVo?.isAgreementApplyStores) {
          this.$alert(
            '包干协议客户的车辆，但本门店非协议包干业务的适用门店，车辆不能在本门店进行包干业务消费'
          );
          clearSelectCarKeyWord();
          return;
        }
        if (car.billClientMemberMessageVo?.state !== 1) {
          this.$alert('该车辆不在该客户包干范围，不允许进行包干业务消费');
          clearSelectCarKeyWord();
          return;
        }
      }
      if (this.billType === 2) {
        if (
          this.$lodash.get(
            car,
            'billClientMemberMessageVo.isAgreementClient',
            0
          ) &&
          !this.$lodash.get(
            car,
            'billClientMemberMessageVo.isAgreementApplyStores',
            0
          )
        ) {
          this.$alert(
            '协议客户的车辆，但本门店非协议理赔业务的适用门店，车辆不能在本门店进行理赔业务消费'
          );
          clearSelectCarKeyWord();
          return;
        }
        this.billInsuranceDto.picDrivingLicence = car.picDrivingLicence;
        // this.billInsuranceDto.runningLicenceImg = car.runningLicenceImg;
        this.form.runningLicenceImg = car.runningLicenceImg;
      }
      if (this.billType === 3) {
        if (
          this.$lodash.get(
            car,
            'billClientMemberMessageVo.isAgreementClient',
            0
          ) &&
          !this.$lodash.get(
            car,
            'billClientMemberMessageVo.isAgreementApplyStores',
            0
          )
        ) {
          this.$alert(
            '协议客户的车辆，但本门店非协议索赔业务的适用门店，车辆不能在本门店进行索赔业务消费'
          );
          clearSelectCarKeyWord();
          return;
        }
      }

      // 同步车辆铭牌、电池铭牌照片 真实续航
      this.form.vehicleNameplateImg = car.vehicleNameplateImg || '';
      this.form.batteryNameplateImg = car.batteryNameplateImg || '';
      this.form.trueEndurance = car.trueEndurance || '';

      this.$set(this, 'carInfo', this.$lodash.cloneDeep(car));
      this.$set(this.form, 'customerName', car.customerName);
      this.$set(this.form, 'customerPhone', car.customerPhone);
      if (this.fromContactUserName) {
        this.$set(this.form, 'customerName', this.fromContactUserName);
      }
      if (this.fromContactPhone) {
        this.$set(this.form, 'customerPhone', this.fromContactPhone);
      }
      if (this.fromRepairType !== null) {
        this.$set(this.form, 'difficulty', this.fromRepairType);
      }
      if (this.fromFirstVisitRemark !== '') {
        this.$set(this.form, 'firstVisitRemark', this.fromFirstVisitRemark);
      }

      // 接车员默认当前操作员工
      this.form.pickUpUserId = this.userInfo.userId;
      this.form.pickUpUserName = this.userInfo.realName;
      // 预交车时间 默认当时时间+1小时
      // this.form.planFinishTime = new Date(
      //   new Date().getTime() + 1000 * 60 * 60
      // ).Format('yyyy-MM-dd hh:mm:ss');
      //默认待定选中
      this.form.undetermined = true;
      // 清空已选择的其他信息
      this.payerInfo = {};
      //确认之后请求车辆质保情况接口
      this.checkCarQueryWarranty(car.carId, 2);

      this.carModelKeyword = car.brandName;
      this.oldCarModelName = car.brandName;
      if (this.billType === 0 && this.carInfo.clientId) {
        this.getClientSettlementWay(
          'carOwnerSettlementWay',
          this.carInfo.clientId
        );
      }
      if (!this.id) {
        // 建单， 弹出是否有索赔业务活动
        const carIdReqdata = {
          carId: car.carId,
        };
        let _carFactoryCompensationActivityData =
          await findCarFactoryCompensationActivityByCarId(carIdReqdata);
        if (_carFactoryCompensationActivityData.length > 0) {
          console.log('有索赔活动');
          this.carFactoryCompensationActivityDialogVisibility = true;
          this.carFactoryCompensationActivityData =
            _carFactoryCompensationActivityData;
        }
      }
    },
    /**
     * 处理协议折扣
     * @param id{number} 协议id
     */
    // handleAgreement(id) {
    //   getAgreementDiscount(this.userInfo.companyId, id).then((res) => {
    //     // 服务
    //     //    二级分类
    //     var secondStageService = [];
    //     //    一级分类
    //     var firstStageService = res.agreementServiceCategory.map((item) => {
    //       let { secondStage = [], ...other } = item;
    //       secondStageService.push(...secondStage);
    //       return other;
    //     });

    //     // 商品
    //     //    二级分类
    //     var secondStageGoods = [];
    //     //    一级分类
    //     var firstStageGoods = res.agreementGoodCategory.map((item) => {
    //       let { secondStage = [], ...other } = item;
    //       secondStageGoods.push(...secondStage);
    //       return other;
    //     });

    //     // 特殊商品折扣
    //     var specialDiscount = res.agreementSpecialCategory || [];

    //     this.firstStageService = firstStageService;
    //     this.secondStageService = secondStageService;

    //     this.firstStageGoods = firstStageGoods;
    //     this.secondStageGoods = secondStageGoods;

    //     this.specialDiscount = specialDiscount;

    //     // 使用协议折扣
    //     this.useAgreementDiscount = true;
    //   });
    // },
    /**
     * 删除车辆
     */
    deleteCar() {
      if (this.carInfo.carId) this.carInfo.carId = '';
      this.carInfo = {};
      this.isOthersSelfPayState = false;
    },

    /**
     * 选择保险公司 - 理赔单
     */
    handleSelectInsuranceCompany(val) {
      this.$set(this.billInsuranceDto, 'claimsInsuranceCompanyId', val.id);
      this.$set(
        this.billInsuranceDto,
        'claimsInsuranceCompanyName',
        val.invoiceCompanyName
      );
      // this.$set(
      //   this.billInsuranceDto,
      //   'insuranceCompanyId',
      //   val.insuranceCompanyId
      // );
      // this.$set(
      //   this.billInsuranceDto,
      //   'insuranceCompanyName',
      //   val.invoiceCompany
      // );
    },
    /**
     * 清空保险公司 - 理赔单
     */
    handleClearInsuranceCompany() {
      this.billInsuranceDto.claimsInsuranceCompanyId = '';
      this.billInsuranceDto.claimsInsuranceCompanyName = '';
      // this.billInsuranceDto.insuranceCompanyId = '';
      // this.billInsuranceDto.insuranceCompanyName = '';
    },

    /**
     * 选择保险公司 - 索赔单
     * @param val 保险公司
     */
    handleSelectUndertakeCompany(val) {
      this.$set(this.billUndertakeDto, 'undertakeInsuranceCompanyId', val.id);
      this.$set(
        this.billUndertakeDto,
        'undertakeInsuranceCompanyName',
        val.undertakeName
      );
      this.$set(
        this.billUndertakeDto,
        'undertakeCompanyId',
        val.undertakeCompanyId
      );
      this.$set(this.billUndertakeDto, 'undertakeCompanyName', val.invoiceName);
    },
    /**
     * 清空保险公司 - 索赔单
     */
    handleClearUndertakeCompany() {
      this.billUndertakeDto.undertakeInsuranceCompanyId = '';
      this.billUndertakeDto.undertakeInsuranceCompanyName = '';
      this.billUndertakeDto.undertakeCompanyId = '';
      this.billUndertakeDto.undertakeCompanyName = '';
    },

    /**
     * 添加索赔单号
     */
    addClaimsNum() {
      this.billUndertakeDto?.claimsNumArr?.push({ value: '' });
    },
    /**
     * 删除索赔单号
     */
    delClaimsNum(index) {
      this.billUndertakeDto?.claimsNumArr?.splice(index, 1);
    },
    /**
     * 添加双系统单号
     */
    addDoubleSysNum() {
      this.doubleSysNumArr?.push({ value: '' });
    },
    /**
     * 删除双系统单号
     */
    delDoubleSysNum(index) {
      this.doubleSysNumArr?.splice(index, 1);
    },

    // /**
    //  * 选择业务来源
    //  */
    // onSelectBillSource(val) {
    //   this.form.billSourceTypeId = val.billSourceTypeId;
    // },
    // /**
    //  * 清空业务来源
    //  */
    // onClearBillSource() {
    //   this.form.billSourceTypeId = '';
    // },

    /**
     * 在店等
     */
    changeWaitInStore(val) {
      if (val) {
        this.$set(this.form, 'planFinishTime', '');
        this.$set(this.form, 'undetermined', false);
        this.rules.planFinishTime[0].required = false;
      } else {
        this.rules.planFinishTime[0].required = true;
      }
    },
    /**
     * 待定
     */
    changeUndetermined(val) {
      if (val) {
        this.$set(this.form, 'planFinishTime', '');
        this.$set(this.form, 'waitInStore', false);
        this.rules.planFinishTime[0].required = false;
      } else {
        this.rules.planFinishTime[0].required = true;
        // 预交车时间 默认当时时间+1小时
        this.$set(
          this.form,
          'planFinishTime',
          new Date(new Date().getTime() + 1000 * 60 * 60).Format(
            'yyyy-MM-dd hh:mm:ss'
          )
        );
      }
    },
    /**
     * 获取标签列表
     */
    getLabelList() {
      getLabelListApi({
        size: this.labelPageSize,
        current: this.labelPageCurrent,
        labelType: 1, //标签类型(0-车单标签;1-项目标签)
        // isShare: true, //总店用，是否共享到全部分店
        isActive: true, //是否启用
      }).then((res) => {
        this.labelList.push(...res.records);
        this.labelHasMore = this.labelPageCurrent < res.pages;
      });
    },
    /**
     * 选择服务项事件
     */
    onSelectServiceItem(row, setIndex = true) {
      row = this.$lodash.cloneDeep(row);
      // 自费单并且开启使用协议折扣
      row.num = '1';
      // if (this.billType === 0 && this.useAgreementDiscount) {
      //   row = this.matchDiscount(row, 1);
      // } else {
      //   row.discount = '';
      // }
      row.discount = '';
      if (row.discount) {
        row.total = Big(row.price)
          .times(row.num)
          .times(row.discount)
          .div(10)
          .toFixed(2);
      } else {
        row.total = Big(row.price).times(row.num).toFixed(2);
      }
      row.id = row.serviceItemId;
      row.code = row.serviceItemCode;
      row.preConstructionPics = '';
      row.afterConstructionPics = '';
      // 是否免施工
      row.isConstructionFree = 0;
      // 施工状态
      row.dispatchTaskState = 5;

      if (
        !this.serviceTableData.find(
          (item) => item.serviceItemId == row.serviceItemId
        )
      ) {
        this.serviceTableData.push(row);
      } else {
        this.$message.warning('该服务已存在！');
      }

      if (setIndex) {
        // 设置标题
        this.setIndex(this.serviceTableData);
        this.calculateTotalAmount();
      }
    },
    /**
     * 添加索赔活动业务服务
     */
    onAddCarFactoryCompensationActivity(item) {
      let items = [item];
      items = this.$lodash.cloneDeep(items);
      let addItems = [];
      let repeated = [];
      items.forEach((row) => {
        if (
          this.serviceTableData.find(
            (s) => s.serviceItemId === row.serviceItemId
          )
        ) {
          repeated.push(row);
        } else {
          addItems.push(row);
        }
      });
      let list = addItems.map((row) => {
        row.num = '1';
        // // 自费单并且开启使用协议折扣
        // if (this.billType === 0 && this.useAgreementDiscount) {
        //   row = this.matchDiscount(row, 1);
        // } else {
        //   row.discount = '';
        // }
        row.discount = '';
        if (row.discount) {
          row.total = Big(row.price)
            .times(row.num)
            .times(row.discount)
            .div(10)
            .toFixed(2);
        } else {
          row.total = Big(row.price).times(row.num).toFixed(2);
        }
        row.id = row.serviceItemId;
        row.code = row.serviceItemCode;
        row.preConstructionPics = '';
        row.afterConstructionPics = '';
        // 是否免施工
        row.isConstructionFree = 0;
        // 施工状态
        row.dispatchTaskState = 5;
        return row;
      });
      if (repeated.length) {
        this.$message.warning('本次添加项目有重复项，仅新增未存在的项目');
      }
      this.serviceTableData.push(...list);
      this.setIndex(this.serviceTableData);
      this.$set(this, 'serviceTableData', this.serviceTableData);
      this.tableKey = Math.random();
      this.calculateTotalAmount();
    },
    /**
     * 批量添加服务项
     */
    multipleSelectService(items) {
      items = this.$lodash.cloneDeep(items);
      let addItems = [];
      let repeated = [];
      items.forEach((row) => {
        if (
          this.serviceTableData.find(
            (s) => s.serviceItemId === row.serviceItemId
          )
        ) {
          repeated.push(row);
        } else {
          addItems.push(row);
        }
      });
      let list = addItems.map((row) => {
        row.num = '1';
        // // 自费单并且开启使用协议折扣
        // if (this.billType === 0 && this.useAgreementDiscount) {
        //   row = this.matchDiscount(row, 1);
        // } else {
        //   row.discount = '';
        // }
        row.discount = '';
        if (row.discount) {
          row.total = Big(row.price)
            .times(row.num)
            .times(row.discount)
            .div(10)
            .toFixed(2);
        } else {
          row.total = Big(row.price).times(row.num).toFixed(2);
        }
        row.id = row.serviceItemId;
        row.code = row.serviceItemCode;
        row.preConstructionPics = '';
        row.afterConstructionPics = '';
        // 是否免施工
        row.isConstructionFree = 0;
        // 施工状态
        row.dispatchTaskState = 5;
        return row;
      });

      // repeated.forEach((row) => {
      //   let index = this.serviceTableData.findIndex(
      //     (s) => s.serviceItemId === row.serviceItemId
      //   );
      //   this.serviceTableData[index].num++;
      //   this.changeNumber(this.serviceTableData[index]);
      // });
      if (repeated.length) {
        this.$message.warning('本次添加项目有重复项，仅新增未存在的项目');
      }
      this.serviceTableData.push(...list);
      this.setIndex(this.serviceTableData);
      this.$set(this, 'serviceTableData', this.serviceTableData);
      this.tableKey = Math.random();
      this.calculateTotalAmount();
    },
    /**
     * 删除服务项或商品
     */
    deleteRow(row, index) {
      if (row.parentId) {
        var indexes = row.title.split('.');
        this.serviceTableData[indexes[0] - 1].billGoods.splice(
          indexes[1] - 1,
          1
        );
      } else {
        let url = row.title.split('.');
        if (url.length === 1) {
          this.serviceTableData.splice(url[0] - 1, 1);
        } else if (url.length === 2) {
          this.serviceTableData[url[0]].billGoods.splice(url[1] - 1, 1);
        }
      }
      this.setIndex(this.serviceTableData);
      this.calculateTotalAmount();
    },

    /**
     * 添加商品 空行
     */
    addGoodsRow(row) {
      this.serviceTableData.forEach((item) => {
        if (item.billGoods?.length) {
          item.billGoods = item.billGoods.filter((el) => el.goodsId);
        }
      });
      if (Array.isArray(row?.billGoods)) {
        this.$set(row.billGoods, row.billGoods.length, {
          isChild: true,
          id: row.serviceItemId + Math.random(),
          parentId: row.serviceItemId,
        });
        this.editRow = row;
      } else {
        this.$set(row, 'billGoods', [
          {
            isChild: true,
            id: row.serviceItemId + Math.random(),
            parentId: row.serviceItemId,
          },
        ]);

        this.editRow = row;
      }
      // 设置标题
      this.setIndex(this.serviceTableData);
    },
    /**
     * 选择商品
     */
    onSelectGoodsItem(item, row) {
      item = this.$lodash.cloneDeep(item);
      // 如果添加相同商品
      let sameIndex = this.editRow.billGoods?.findIndex(
        (goods) => goods.goodsId === item.goodsId
      );
      if (sameIndex >= 0) {
        // 则数量+1
        // this.editRow.billGoods[sameIndex].num =
        //   +this.editRow.billGoods[sameIndex].num + 1;
        // this.changeNumber(this.editRow.billGoods[sameIndex]);

        // change 220825 提示 不允许重复选择
        this.$message.warning('该商品已存在！');
        return;
      }
      // 2022/9/10 产品 重复材料单临时解决方案
      // let isInOtherServiceGoods = this.serviceTableData
      //   .reduce((prev, s) => {
      //     if (
      //       s.billGoods?.length &&
      //       this.editRow.serviceItemId !== s.serviceItemId
      //     ) {
      //       return prev.concat(s.billGoods);
      //     } else return prev;
      //   }, [])
      //   .find((goods) => goods.goodsId === item.goodsId);
      // if (isInOtherServiceGoods) {
      //   this.$alert(
      //     `当前商品已在【${
      //       this.serviceTableData[isInOtherServiceGoods.title.split('.')[0] - 1]
      //         ?.name
      //     }】下存在，暂不能添加，如需如此操作请先前往旧系统添加`,
      //     '提示'
      //   );
      //   return;
      // }

      item.name = item.goodsName;
      item.code = item.goodsCode;

      row.discount = '';
      row.num = '1';
      row.total = Big(item.price).times(row.num).toFixed(2);
      row.id = item.goodsId + Math.random();

      row = Object.assign(row, item);

      // // 自费单并且开启使用协议折扣
      // if (this.billType === 0 && this.useAgreementDiscount) {
      //   row = this.matchDiscount(row, 0);
      //   if (row.discount) {
      //     row.total = Big(row.price)
      //       .times(row.num)
      //       .times(row.discount)
      //       .div(10)
      //       .toFixed(2);
      //   } else {
      //     row.total = Big(row.price).times(row.num).toFixed(2);
      //   }
      // }

      // 设置标题
      this.setIndex(this.serviceTableData);
      this.calculateTotalAmount();
      this.addGoodsRow(this.serviceTableData[row.title.split('.')[0] - 1]);
    },
    /**
     * 批量添加商品
     */
    multipleSelectGoods(items) {
      items = this.$lodash.cloneDeep(items);
      let addItems = [];
      let repeated = [];

      // 判断其他服务中是否存在此商品
      let otherServiceGoods = [];
      this.serviceTableData.forEach((s) => {
        let { billGoods = [], ...other } = s;
        if (billGoods?.length) {
          otherServiceGoods.push(
            ...billGoods.map((el) =>
              Object.assign({}, el, { serviceName: s.name })
            )
          );
        }
      });

      // let errorMessage = [];

      items.forEach((row) => {
        // 当前服务中存在
        if (this.editRow?.billGoods?.find((g) => g.goodsId === row.goodsId)) {
          repeated.push(row);
        } else {
          // // 是否在其他服务中存在
          // let isInOtherServiceGoods = otherServiceGoods.find(
          //   (otherGoods) => otherGoods.goodsId === row.goodsId
          // );
          // if (isInOtherServiceGoods) {
          //   errorMessage.push(
          //     `<p>商品【${row.goodsName}】已在【${isInOtherServiceGoods.serviceName}】下存在，暂不能添加，如需如此操作请先前往旧系统添加<p>`
          //   );
          // } else {
          addItems.push(row);
          // }
        }
      });

      // if (errorMessage.length) {
      //   this.$alert(errorMessage.join(''), '提示', {
      //     dangerouslyUseHTMLString: true,
      //   });
      // }

      var goods = addItems.map((item) => {
        var row = {
          isChild: true,
          id: this.editRow.serviceItemId + Math.random(),
          parentId: this.editRow.serviceItemId,
        };
        row.name = item.goodsName;
        row.code = item.goodsCode;
        row.discount = '';
        row.num = '1';
        row.total = Big(item.price).times(row.num).toFixed(2);
        row.id = item.goodsId + Math.random();

        row = Object.assign(row, item);
        // // 自费单并且开启使用协议折扣
        // if (this.billType === 0 && this.useAgreementDiscount) {
        //   row = this.matchDiscount(row, 0);
        //   if (row.discount) {
        //     row.total = Big(row.price)
        //       .times(row.num)
        //       .times(row.discount)
        //       .div(10)
        //       .toFixed(2);
        //   } else {
        //     row.total = Big(row.price).times(row.num).toFixed(2);
        //   }
        // }
        return row;
      });
      if (Array.isArray(this.editRow?.billGoods)) {
        goods.forEach((item) => {
          this.$set(
            this.editRow.billGoods,
            this.editRow.billGoods.length,
            item
          );
        });
      } else {
        this.$set(this.editRow, 'billGoods', goods);
      }
      // 去除空行
      this.editRow.billGoods = this.editRow.billGoods.filter(
        (item) => item.goodsId
      );

      // 220825 重复项不加数量
      // repeated.forEach((row) => {
      //   let index = this.editRow.billGoods.findIndex(
      //     (s) => s.goodsId === row.goodsId
      //   );
      //   this.editRow.billGoods[index].num++;
      //   this.changeNumber(this.editRow.billGoods[index]);
      // });
      if (repeated.length) {
        this.$message.warning('本次添加项目有重复项，仅新增未存在的项目');
      }

      this.setIndex(this.serviceTableData);
      this.$set(this, 'serviceTableData', this.serviceTableData);
      this.tableKey = Math.random();
      this.calculateTotalAmount();
    },
    /**
     * 显示批量选择商品抽屉
     */
    showMultipleSelectGoodsDrawer(row, index) {
      this.editRow = row;
      this.editIndex = +row.title - 1;
      this.multipleSelectGoodsDrawerVisibility = true;
    },

    /**
     * 给服务/商品匹配协议折扣价
     * @param row{object} 服务/商品 对象
     * @param type{number} 类型: 1 服务 2 商品
     * @return {object}
     */
    // matchDiscount(row, type) {
    //   let isSpecial;
    //   let matchedItem;

    //   let special;
    //   let second;
    //   let first;
    //   // 服务
    //   if (type === 1) {
    //     // 该服务/商品是否存在特殊协议价
    //     special = this.specialDiscount.find(
    //       (item) => item.serviceType === 1 && +item.itemId === row.serviceItemId
    //     );

    //     // 该服务/商品的二级分类是否存在协议折扣
    //     second = this.secondStageService.find(
    //       (item) => item.categoryId === row.secondCategory?.categoryId
    //     );

    //     // 该服务/商品的一级分类是否存在协议折扣
    //     first = this.firstStageService.find(
    //       (item) => item.categoryId === row.firstCategory?.categoryId
    //     );
    //   }
    //   // 商品
    //   if (type === 0) {
    //     // 该服务/商品是否存在特殊协议价
    //     special = this.specialDiscount.find(
    //       (item) => item.serviceType === 0 && +item.itemId === row.goodsId
    //     );

    //     // 该服务/商品的二级分类是否存在协议折扣
    //     second = this.secondStageGoods.find(
    //       (item) => item.categoryId === row.secondCategoryId
    //     );

    //     // 该服务/商品的一级分类是否存在协议折扣
    //     first = this.firstStageGoods.find(
    //       (item) => item.categoryId === row.firstCategoryId
    //     );
    //   }
    //   if (special) {
    //     isSpecial = true;
    //     matchedItem = special;
    //     console.log('匹配到特殊折扣', row, special);
    //   } else if (second?.discount) {
    //     matchedItem = second;
    //     console.log('匹配到二级分类折扣', row, second);
    //   } else if (first?.discount) {
    //     matchedItem = first;
    //     console.log('匹配到一级分类折扣', row, first);
    //   }
    //   // 设置默认折扣价格
    //   if (matchedItem) {
    //     // 特殊价格
    //     if (isSpecial) {
    //       row.price = matchedItem.negotiatedPrice || 0;
    //     } else {
    //       // 类别折扣
    //       row.discount = matchedItem.discount || '';
    //     }
    //   }
    //   return row;
    // },

    /**
     * 修改单价
     * 重新计算 小计
     */
    changePrice(row) {
      if (['', undefined].includes(row.price)) row.price = 0;
      row.total = this.calcRowTotalPrice(row);
      this.calculateTotalAmount();
      this.tableKey = Math.random();
    },
    /**
     * 选择赠送
     * 重新计算小计
     */
    changeAgreementFree(newValue, row) {
      if (newValue === false) {
        row.agreementFreeRemark = '';
        row.isDepreciation = false;
        row.depreciationCharge = 0;
        row.depreciationRemark = '';
      }
      row.total = this.calcRowTotalPrice(row);
      this.calculateTotalAmount();
      this.tableKey = Math.random();
    },
    /**
     * 选择折旧
     * 重新计算小计
     */
    changeDepreciationChargeCheckBox(newValue, row) {
      if (newValue === false) {
        row.depreciationCharge = 0;
        row.depreciationRemark = '';
      }
      row.total = this.calcRowTotalPrice(row);
      this.calculateTotalAmount();
      this.tableKey = Math.random();
    },
    /**
     * 输入折旧
     * 重新计算小计
     */
    changeDepreciationCharge(newValue, row) {
      row.total = this.calcRowTotalPrice(row);
      this.calculateTotalAmount();
      this.tableKey = Math.random();
    },
    /**
     * 修改折扣
     * 重新计算 小计
     */
    changeDiscount(row) {
      row.discount = row.discount ?? '';
      row.total = this.calcRowTotalPrice(row);
      this.calculateTotalAmount();
      this.tableKey = Math.random();
    },
    /**
     * 修改数量
     * 重新计算 小计
     */
    changeNumber(row) {
      if (!row.num >= 1) row.num = 1;
      row.total = this.calcRowTotalPrice(row);
      this.calculateTotalAmount();
      this.tableKey = Math.random();
    },
    /**
     * 计算行的小计
     * @param row 行数据
     */
    calcRowTotalPrice(row) {
      if (row.agreementFree || row.agreementFree === 'true') {
        // 赠送的
        return Number(0);
      }
      if (['', undefined].includes(row.price)) {
        row.price = 0;
      }
      if (['', undefined].includes(row.depreciationCharge)) {
        row.depreciationCharge = 0;
      }
      if (!row.num >= 1) {
        row.num = 1;
      }
      row.discount = row.discount ?? '';
      const discount =
        row.discount > 0 ? Big(row.discount).div(10).toFixed(2) : 1;
      // 单价 * 数量 * 折扣 - 折旧费
      const total = Big(row.price)
        .times(row.num)
        .times(discount)
        .minus(row.depreciationCharge);
      // 不能是负数
      if (total < 0) {
        return Number(0);
      }
      return total.toFixed(2);
    },
    /**
     * 修改小计
     * 重新计算 折扣
     */
    // changeTotal(row) {
    //   if (+row.total > Big(row.price).times(row.num)) {
    //     this.$alert('折扣大于商品单价×数量，请确认单价与小计金额', '提示');
    //     row.total = Big(row.price).times(row.num).toFixed(2);
    //     row.discount = '';
    //     this.calculateTotalAmount();
    //     this.tableKey = Math.random();
    //     return;
    //   }
    //   row.discount = Big(row.total)
    //     .div(Big(row.num))
    //     .div(Big(row.price))
    //     .times(10)
    //     .toFixed(1);
    //   if (row.discount >= 10) row.discount = '';
    //   this.calculateTotalAmount();
    //   this.tableKey = Math.random();
    // },

    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 9) {
        if (!row.parentId) {
          var length = row.billGoods?.length || 0;
          if (length) {
            return {
              rowspan: length + 1,
              colspan: 1,
            };
          } else {
            return [1, 1];
          }
        } else {
          return [0, 0];
        }
      }
    },
    /**
     * 计算总金额
     */
    calculateTotalAmount(resetDiscount = true) {
      // 正常价格
      var normalPrice = 0;
      // 服务项目报价
      var servicePrice = 0;
      // 商品报价
      var goodsPrice = 0;

      // 整单折扣后价格
      var discountPrice = 0;
      // 是否存在整单折扣
      // var hasBillDiscount = !['', undefined, NaN].includes(this.billDiscount);

      this.serviceTableData.forEach((service) => {
        // service.billDiscount = '';
        // 服务项目折扣后合计金额
        service.itemTotalBefore = 0;
        if (+service.total) {
          service.itemTotalBefore += +service.total;
          if (resetDiscount) service.itemTotalAfter = '';
          servicePrice += +service.total;
          if (resetDiscount) service.billDiscount = 0;
          // service.billDiscount = +service.total;
          // if (hasBillDiscount) {
          //   service.billDiscount = Big(service.total)
          //     .times(this.billDiscount)
          //     .div(10)
          //     .toFixed(2);
          //   discountPrice = Big(service.total)
          //     .times(this.billDiscount)
          //     .div(10)
          //     .toFixed(2);
          // }
        }
        if (service.billGoods?.length) {
          service.itemTotalBefore = service.billGoods.reduce((prev, item) => {
            if (resetDiscount) item.billDiscount = 0;
            if (+item.total) {
              goodsPrice += +item.total;
              // if (hasBillDiscount) {
              //   item.billDiscount = Big(item.total)
              //     .times(this.billDiscount)
              //     .div(10)
              //     .toFixed(2);
              //   discountPrice = Big(item.itemTotalBefore)
              //     .times(this.billDiscount)
              //     .div(10)
              //     .toFixed(2);
              // }
              return prev + +item.total;
            } else {
              return prev;
            }
          }, service.itemTotalBefore);
        }
        // 整单金额
        normalPrice += service.itemTotalBefore;
      });

      this.billTotalPrice = Big(normalPrice).toFixed(2);
      // if (hasBillDiscount) {
      //   // 整单折扣后金额
      //   this.billDiscountTotalPrice = discountPrice;
      //   // this.billDiscountTotalPrice = Big(this.billTotalPrice)
      //   //   .times(Big(this.billDiscount))
      //   //   .div(10)
      //   //   .toFixed(2);
      // } else {
      //   this.billDiscountTotalPrice = this.billTotalPrice;
      // }
      this.serviceTotalPrice = servicePrice;
      this.goodsTotalPrice = goodsPrice;
      if (resetDiscount) this.billDiscount = '';
      if (resetDiscount) this.billDiscountTotalPrice = this.billTotalPrice;
    },
    /**
     * 处理协议价
     */
    handleAgreementPrice(map) {
      // 修改报价为客户协议价
      console.log('协议价不一致：', map);
      this.serviceTableData.forEach((service) => {
        if (map[service.serviceItemId] !== undefined) {
          service.isAgreePrice = true;
          service.price = map[service.serviceItemId];
          service.discount = '';
          service.total = this.calcRowTotalPrice(service);
        }
        if (service.billGoods?.length) {
          service.billGoods.forEach((goods) => {
            if (map[goods.goodsId] !== undefined) {
              goods.isAgreePrice = true;
              goods.price = map[goods.goodsId];
              goods.discount = '';
              goods.total = this.calcRowTotalPrice(goods);
            }
          });
        }
      });
      console.log('table', this.serviceTableData);
      this.tableKey = Math.random();
      this.calculateTotalAmount(!!Object.keys(map).length);
      this.$message.warning('当前车单协议价已更新');
    },

    /**
     * 显示选择技师弹窗
     */
    showSelectTechniciansDialogForAllItem() {
      this.editRow = undefined;
      this.editIndex = undefined;
      this.selectTechniciansDialogVisibility = true;
    },
    /**
     * 显示选择技师弹窗
     */
    showSelectTechniciansDialog(row, index) {
      this.editRow = row;
      this.editIndex = +row.title - 1;
      this.selectTechniciansDialogVisibility = true;
    },

    /**
     * 选择技师回调
     */
    handleSelectTechnicians(list) {
      // 设置全部行
      if (this.editRow === undefined) {
        this.serviceTableData.forEach((item) => {
          if (
            !this.id ||
            !this.checkCurrentCompanyHasEnableDispatch ||
            [5, 6, undefined].includes(item.dispatchTaskState)
          ) {
            if (list) {
              item.isConstructionFree = 0;
              item.technicianIds = list;
              // 施工状态
              item.dispatchTaskState = 5;
            } else {
              item.isConstructionFree = 1;
              item.technicianIds = [];
              // 施工状态
              item.dispatchTaskState = 6;
            }
          }
        });
      } else {
        if (list) {
          this.editRow.isConstructionFree = 0;
          this.editRow.technicianIds = list;
          // 施工状态
          this.editRow.dispatchTaskState = 5;
        } else {
          this.editRow.isConstructionFree = 1;
          this.editRow.technicianIds = [];
          // 施工状态
          this.editRow.dispatchTaskState = 6;
        }
        this.$set(this.serviceTableData, this.editIndex, this.editRow);
      }
      this.tableKey = Math.random();
    },

    /**
     * 显示选择业务员弹窗
     */
    showSelectSalesmanDialogForAllItem() {
      this.editRow = undefined;
      this.editIndex = undefined;
      this.selectSalesmanDialogVisibility = true;
    },
    /**
     * 显示选择业务员弹窗
     */
    showSelectSalesmanDialog(row, index) {
      this.editRow = row;
      this.editIndex = +row.title - 1;
      this.selectSalesmanDialogVisibility = true;
    },

    /**
     * 选择业务员回调
     */
    handleSelectSalesman(item) {
      if (this.editRow === undefined) {
        this.serviceTableData.forEach((el) => {
          el.sellerId = item.userId;
          el.sellerName = item.realName;
        });
      } else {
        this.editRow.sellerId = item.userId;
        this.editRow.sellerName = item.realName;
        this.$set(this.serviceTableData, this.editIndex, this.editRow);
      }

      this.tableKey = Math.random();
    },

    /**
     * 设置序号
     */
    setIndex(arr, indexArr = [], children = 'billGoods') {
      arr.forEach((item, index) => {
        let cloneArr = [];
        if (indexArr.length) {
          cloneArr = indexArr.concat(index + 1);
        } else {
          cloneArr.push(index + 1);
        }
        item.title = cloneArr.join('.');
        if (item[children]?.length) {
          this.setIndex(item[children], cloneArr);
        }
      });
    },

    /**
     * 点击编辑总价折扣按钮
     */
    showBillDiscountEditDialog() {
      this.changeTotalPriceDialogVisible = true;
      this.billDiscountTemp = this.billDiscount;
      this.billDiscountPriceTemp = this.billDiscountTotalPrice;
    },
    /**
     * 修改整单折扣后总价
     */
    changeBillDiscountPriceTemp() {
      if (+this.billDiscountPriceTemp > +this.billTotalPrice) {
        this.billDiscountPriceTemp = this.billTotalPrice;
        this.billDiscountTemp = '';
        this.$alert('折后金额不能大于折前金额', '提示');
        return;
      }
      // if (this.billDiscountPriceTemp / this.billTotalPrice < 0.01) {
      //   this.billDiscountPriceTemp = Big(this.billTotalPrice)
      //     .times(0.01)
      //     .toFixed(2);
      //   this.billDiscountTemp = 0.1;
      //   this.$alert('最小折扣不能小于0.1折', '提示');
      // }
      if (!this.billDiscountPriceTemp) {
        this.billDiscountTemp = 0;
        this.billDiscountPriceTemp = 0;
      } else {
        var discount = Big(this.billDiscountPriceTemp)
          .div(this.billTotalPrice)
          .times(10)
          .toFixed(1);
        this.billDiscountTemp = discount == '10.0' ? '' : discount;
      }
    },
    /**
     * 修改整单折扣
     */
    changeBillDiscountTemp() {
      if (+this.billDiscountTemp) {
        this.billDiscountPriceTemp = Big(this.billDiscountTemp || 0)
          .times(this.billTotalPrice)
          .div(10)
          .toFixed(2);
      } else {
        this.billDiscountPriceTemp = this.billTotalPrice;
      }
    },
    /**
     * 确认整单折扣
     */
    confirmBillDiscount() {
      // if (!Number(this.billDiscountTemp)) {
      //   this.billDiscountPriceTemp = this.billTotalPrice;
      //   this.billDiscountTemp = '';
      // }
      this.billDiscountTotalPrice = +this.billDiscountPriceTemp;
      this.billDiscount = +this.billDiscountTemp;
      this.changeTotalPriceDialogVisible = false;
      /**
       * 整个折扣分摊到单个项目规则
       * 非最后一单项按计算整单折扣分摊金额:整单折后小计=单项折后小计-单项折后金额/整单折前金额合计X整单的折扣优惠(仅显示两个小数位,四舍五入)金最后一单项分摊金额=该单据剩余未分摊金额;
       */

      if (this.billDiscountTotalPrice != this.billTotalPrice) {
        var items = [];
        this.serviceTableData.forEach((service) => {
          items.push(service);
          if (service.billGoods?.length) {
            items.push(...service.billGoods);
          }
        });

        items.forEach((item) => {
          item.billDiscount = Big(item.total || 0)
            .times(this.billDiscount)
            .div(10)
            .toFixed(2);
        });

        // 最后一项，填平误差
        var lastIndex = items.length - 1;
        items.at(-1).billDiscount =
          this.billDiscountTotalPrice -
          items.reduce((prev, item, index) => {
            if (index != lastIndex) {
              return prev + +item.billDiscount;
            } else {
              return prev;
            }
          }, 0);

        this.serviceTableData.forEach((service) => {
          var itemTotalAfter = Big(service.billDiscount);
          if (service.billGoods?.length) {
            service.billGoods.forEach((item) => {
              itemTotalAfter = itemTotalAfter.plus(item.billDiscount);
            });
          }

          service.itemTotalAfter = +itemTotalAfter.toFixed(2);
        });

        this.tableKey = Math.random();
      }
      // 否则清空折扣
      else {
        this.calculateTotalAmount();
      }
    },

    /**
     * 显示保养方案弹窗
     */
    showMaintenancePlanDialog() {
      if (!this.form.mileage) {
        this.$alert('无进店里程数，请先输入');
        return;
      }
      if (!this.carInfo.uniqueId) {
        this.$alert('车辆无车架号，请先编辑车辆档案，输入车架号确认车型配置');
        return;
      }
      this.maintenancePlanDialogVisibility = true;
    },

    /**
     * 查询
     */
    getData() {
      getCarBillDetail(this.id).then(async (res) => {
        console.log(res);
        this.billType = [0, 1, 2, 3].includes(this.typeStatus)
          ? this.typeStatus
          : res.billMessage.billType;
        this.isOthersSelfPayState = res.isOthersSelfPayState;
        // 消费客户
        this.payerInfo = res.carBillConsumerMessage;
        this.payerInfo.clientName = res.carBillConsumerMessage.consumerName;
        this.payerInfo.clientPhone = res.carBillConsumerMessage.consumerPhone;
        this.payerInfo.clientId = this.payerInfo.consumerId;
        if (this.isOthersSelfPayState) {
          this.getClientSettlementWay(
            'payerSettlementWay',
            this.payerInfo.clientId
          );
        }
        this.createCompanyId = res.companyId;
        this.carInfo = res.carBillClientMessage;
        this.carModelKeyword = this.carInfo.brandName || '';
        this.oldCarModelName = this.carModelKeyword;

        if (this.billType === 0 && this.carInfo.clientId) {
          this.getClientSettlementWay(
            'carOwnerSettlementWay',
            this.carInfo.clientId
          );
        }

        if (this.billType === 2 && res.beTurnConsumerId) {
          this.getClientSettlementWay(
            'beTurnConsumerSettlementWay',
            res.beTurnConsumerId
          );
        }

        this.checkCurrentCompanyHasEnableDispatch =
          res.checkCurrentCompanyHasEnableDispatch;
        this.form = res.billMessage;
        if ([0, 1].includes(res.isClaimUnderWarranty)) {
          this.$set(
            this.form,
            'isClaimUnderWarranty',
            res.isClaimUnderWarranty
          );
        }
        if ([1, 2].includes(res.doubleSysInputState)) {
          this.$set(this.form, 'doubleSysInputState', res.doubleSysInputState);
        } else {
          this.$set(this.form, 'doubleSysInputState', null);
        }
        if (
          res?.billMessage?.doubleSysNums &&
          res?.billMessage?.doubleSysNums?.length > 0
        ) {
          this.doubleSysNumArr.length = 0;
          res.billMessage.doubleSysNums.forEach((item) => {
            this.doubleSysNumArr.push({ value: item });
          });
        }
        if (!this.doubleSysNumArr?.length) {
          this.$set(this, 'doubleSysNumArr', [{ value: '' }]);
        }
        if ([0, 1].includes(res.isClaimActivity)) {
          this.$set(this.form, 'isClaimActivity', res.isClaimActivity);
        }
        // 接车员回显
        if (this.form.pickUpUserId) {
          let obj = {
            userId: this.form.pickUpUserId,
            realName: this.form.pickUpUserName,
          };
          this.employeeList.unshift(obj);
          this.employeeList = this.$lodash.uniqBy(this.employeeList, 'userId');
        }

        // 返修追溯
        if (res.billRepairTracingRecord) {
          this.form.tracingBillId = res.billRepairTracingRecord.tracingBillId;
          this.form.tracingBillCode =
            res.billRepairTracingRecord.tracingBillCode;
          this.form.repairRemark = res.billRepairTracingRecord.repairRemark;
        }

        this.form.carBodyPositivePic =
          res.billMessage?.carBodyInfoVo?.carBodyPositivePic || '';
        this.form.carBodyBehindPic =
          res.billMessage?.carBodyInfoVo?.carBodyBehindPic || '';
        this.form.carBodyLeftPic =
          res.billMessage?.carBodyInfoVo?.carBodyLeftPic || '';
        this.form.carBodyRightPic =
          res.billMessage?.carBodyInfoVo?.carBodyRightPic || '';
        // this.form.carRoofPic = res.billMessage?.carBodyInfoVo?.carRoofPic || '';
        this.form.runningLicenceImg =
          res.billMessage?.carBodyInfoVo?.runningLicenceImg || '';
        this.form.carInstrumentPanelPic =
          res.billMessage?.carBodyInfoVo?.carInstrumentPanelPic || '';
        this.form.vehicleNameplateImg =
          res.billMessage?.carBodyInfoVo?.vehicleNameplateImg || '';
        this.form.batteryNameplateImg =
          res.billMessage?.carBodyInfoVo?.batteryNameplateImg || '';
        this.form.carOtherPics =
          res.billMessage?.carBodyInfoVo?.carOtherPics?.join() || '';

        this.billInsuranceDto = res.carBillInsuranceInfo || {};
        this.billInsuranceDto.feeListPicOthers =
          this.billInsuranceDto?.feeListPicOthers?.join();

        if (![0, 1, 2, 3].includes(this.typeStatus)) {
          this.billUndertakeDto = res.carBillUndertakeInfo;
          if (Array.isArray(this.billUndertakeDto?.claimsNum)) {
            let arr = this.billUndertakeDto.claimsNum.map((item) => ({
              value: item,
            }));
            this.$set(this.billUndertakeDto, 'claimsNumArr', arr);
            if (!this.billUndertakeDto.claimsNumArr?.length) {
              this.$set(this.billUndertakeDto, 'claimsNumArr', [{ value: '' }]);
            }
          }
        }

        this.billDiscount = res.discount;
        this.billDiscountTotalPrice = res.paid;

        this.billTotalPrice = res.total;

        // 协议价时清除折扣
        let clearDiscount = false;
        var serviceTableData = res.serviceItems
          .filter((item) => item.serviceItemId)
          .map((service) => {
            service.id = service.billServiceId;
            if (!service.id) service.id = Math.random();
            service.name = service.serviceItemName;
            if (!service.name) service.name = ' ';
            service.code = service.serviceItemCode;
            service.afterConstructionPics =
              service.afterConstructionPics?.join();
            service.preConstructionPics = service.preConstructionPics?.join();
            service.technicianIds = service.technicianInfoVos?.map((item) => ({
              realName: item.technicianName,
              userId: item.technicianId,
            }));

            // 协议价时清除折扣
            if (service.isAgreePrice && service.discount > 0) {
              service.discount = 0;
              service.total = Big(service.price).times(service.num).toFixed(2);
              clearDiscount = true;
            }

            service.billGoods = service.goodsInfoVos;
            if (service?.billGoods?.length) {
              service.billGoods?.forEach((item) => {
                item.id = item.billGoodsId;
                if (!item.id) item.id = Math.random();
                item.name = item.goodsName;
                item.code = item.goodsCode;
                item.parentId = service.id;
                item.isChild = true;

                // 协议价时清除折扣
                if (item.isAgreePrice && item.discount > 0) {
                  item.discount = 0;
                  item.total = Big(item.price).times(item.num).toFixed(2);
                  clearDiscount = true;
                }
              });
            }

            return service;
          });
        this.setIndex(serviceTableData);
        if (clearDiscount) {
          this.calculateTotalAmount();
          this.$message.warning('协议价项目不允许打折，已清除折扣');
        }
        this.serviceTableData = serviceTableData;

        // 查询协议价

        // 变更业务
        // if ([0, 1, 2, 3].includes(this.typeStatus)) {
        //   this.handleConsumerChange();
        // }
        //  else {
        /**
         * 需求#124 车单-统一报价体系方案： 进入编辑时清空原有项目单项折扣及整单折扣  同步协议价
         * 需求#135  报价体系-恢复车单单项折扣与整单折扣需求功能
         */
        const map = this.$route.params?.agreementPriceMap || {};
        // 携带了参数
        if (Object.values(map).length) {
          this.handleAgreementPrice(map);
        } else {
          // 校验协议价
          if ([0, 1].includes(this.billType)) {
            const res = await checkQuotationApi(this.id);
            if (!res.isConsistent) {
              var agreementPriceMap = {};
              res.service.forEach((service, i) => {
                if (!service.isConsistent) {
                  agreementPriceMap[service.serviceItemId] = service.agreePrice;
                }
                service.goods?.forEach((goods, j) => {
                  if (!goods.isConsistent) {
                    agreementPriceMap[goods.goodsId] = goods.agreePrice;
                  }
                });
              });
              this.handleAgreementPrice(agreementPriceMap);
            }
          }
        }
        // }

        // 自费业务
        // if (this.billType === 0) {
        //   // 是否为协议客户
        //   if (this.carInfo?.billClientMemberMessageVo?.agreementClientId) {
        //     // 协议是否适用于当前门店
        //     if (
        //       this.carInfo?.billClientMemberMessageVo?.isAgreementApplyStores
        //     ) {
        //       let id =
        //         this.carInfo?.billClientMemberMessageVo?.agreementClientId;
        //       this.handleAgreement(id);
        //     }
        //   }
        // }
        // 理赔业务
        if (this.billType === 2) {
          this.storeList.push({
            companyId: this.billInsuranceDto.insuranceCompanyId,
            invoicingEntity: this.billInsuranceDto.insuranceCompanyName,
          });
          this.storeList = this.$lodash.unionBy(this.storeList, 'companyId');

          // 是否他人推修
          this.isOtherClientPush = res.isOtherClientPush;

          if (this.isOtherClientPush) {
            // 他人推修客户
            this.pushClientId = res.pushClientId;
            this.pushClientName = res.pushClient;
          }

          // 是否交商不同
          this.isJqInfo = res.isJqInfo;
          // 交强险保险公司
          this.jqInsuranceId = res.jqInsuranceId;
          this.jqInsuranceName = res.jqInsurance;
          // 交强险报案号
          this.jqCaseNum = res.jqCaseNum;
          // 其他信息
          this.isInsuranceTurnSelf = res.isInsuranceTurnSelf;
          // 理赔消费客户
          this.beTurnConsumerName = res.beTurnConsumer;
          this.beTurnConsumerId = res.beTurnConsumerId;
        }
        //从车单详情中检车单抽屉-确认报价跳转条件判断
        if (this.$route.query.addService) {
          this.multipleSelectService(this.$route.query.addService);
        }
      });
    },

    /**
     * 保存前校验
     */
    verifyBeforeSave() {
      let _this = this;
      return new Promise((resolve, reject) => {
        // carId
        if (!this.carInfo.carId) {
          this.$alert('请选择车辆！', '提示');
          reject(false);
          return;
        }
        // contactId 取消此字段 220706
        // if (!this.carInfo.clientId) {
        //   this.$alert('请绑定客户！', '提示');
        //   reject();
        //   return;
        // }

        // discount 有默认值

        //进店里程
        if (!this.form.mileage) {
          this.$alert('请填写进店里程！', '提示');
          reject(false);
          return;
        }
        //送修人姓名
        if (!this.form.customerName) {
          this.$alert('请填写送修人姓名！', '提示');
          reject(false);
          return;
        }
        //送修人电话
        if (!this.form.customerPhone) {
          this.$alert('请填写送修人电话！', '提示');
          reject(false);
          return;
        }
        //送修人电话
        if (this.form.customerPhone?.length !== 11) {
          this.$alert('送修人电话格式有误，请检查！', '提示');
          reject(false);
          return;
        }
        if (
          _this.form.billSourceTypeId === undefined ||
          !_this.form.billSourceTypeId
        ) {
          this.$alert('请选择业务来源！', '提示');
          reject(false);
          return;
        }
        //施工类型
        if (
          _this.form.difficulty === undefined ||
          _this.form.difficulty === ''
        ) {
          this.$alert('请选择施工类型！', '提示');
          reject(false);
          return;
        }

        // 预交车时间
        if (!this.form.planFinishTime && !this.form.undetermined) {
          this.$alert('请选择预交车时间！', '提示');
          reject(false);
          return;
        }

        // firstVisitRemark
        if (
          !this.form.firstVisitRemark &&
          ![0, 1, 2, 3].includes(this.typeStatus)
        ) {
          this.$alert('请填写初诊与备注！', '提示');
          reject(false);
          return;
        }
        if (
          this.isSpecificClient() &&
          ![1, 2].includes(this.form.doubleSysInputState)
        ) {
          this.$alert('请选择双系统录单情况', '提示');
          reject(false);
          return;
        }

        // // 自费   需求49改版之后的开单去掉他人自费必填
        // if (this.billType == 0) {
        //   // 如果开启他人自费
        //   if (this.isOthersSelfPayState && !this.payerInfo.clientId) {
        //     this.$alert('请选择他人自费客户！', '提示');
        //     reject(false);
        //     return;
        //   }
        // }
        // 理赔
        if (this.billType == 2) {
          if (!this.billInsuranceDto.claimsInsuranceCompanyId) {
            this.$alert('请选择保险公司！', '提示');
            reject(false);
            return;
          }
          if (!this.billInsuranceDto.caseNum) {
            this.$alert('请填写报案号！', '提示');
            reject(false);
            return;
          }
          if (!this.billInsuranceDto.insuranceCompanyId) {
            this.$alert('请选择开票主体！', '提示');
            reject(false);
            return;
          }
          if (this.billInsuranceDto.dutyType === undefined) {
            this.$alert('请选择事故责任！', '提示');
            reject(false);
            return;
          }

          // 交商不同
          if (this.isJqInfo) {
            if (!this.jqInsuranceId) {
              this.$alert('请选择交强保险公司！', '提示');
              reject(false);
            }
            if (!this.jqCaseNum) {
              this.$alert('请输入交强报案号！', '提示');
              reject(false);
            }
          }
          // 其他信息 校验消费客户
          if (
            [0, 1].includes(this.isInsuranceTurnSelf) &&
            !this.beTurnConsumerId
          ) {
            this.$alert('请选择保险消费客户！', '提示');
            reject(false);
          }
        }
        // 索赔
        if (this.billType == 3) {
          if (!this.billUndertakeDto.undertakeCompanyId) {
            this.$alert('请选择三包厂家！', '提示');
            reject(false);
            return;
          }
        }

        //  业务员默认接车员
        if (this.serviceTableData?.length) {
          this.serviceTableData.forEach((service) => {
            if (!service.sellerId) {
              service.sellerId = this.userInfo.userId;
              service.sellerName = this.userInfo.realName;
            }
          });
        }

        let errorArr = [];
        this.serviceTableData.forEach((s, index) => {
          // 校验商品服务是否大于0
          if (s.num <= 0) {
            errorArr.push(
              `<p>第 ${s.title} 行服务「${s.serviceItemName}」数量不能为0</p>`
            );
          }
          if (s.billGoods?.length) {
            s.billGoods.forEach((g) => {
              if (g.num <= 0) {
                errorArr.push(
                  `<p>第 ${g.title} 行商品「${g.goodsName}」数量不能为0</p>`
                );
              }
            });
          }
        });
        if (errorArr.length) {
          this.$alert(errorArr.join(''), '提示', {
            dangerouslyUseHTMLString: true,
          });
          reject(false);
          return;
        }

        resolve(true);
      });
    },

    /**
     * 保存
     */
    async saveBill() {
      const _this = this;
      let res = await this.verifyBeforeSave().catch((err) => {
        console.log('保存前检验结果: ', err);
      });
      if (!res) return;
      var cloneForm = this.$lodash.cloneDeep(this.form);
      // 环检图片只保留文件名
      cloneForm.carBodyPositivePic = cloneForm.carBodyPositivePic
        ?.split('/')
        ?.at(-1);
      cloneForm.carBodyBehindPic = cloneForm.carBodyBehindPic
        ?.split('/')
        ?.at(-1);
      cloneForm.carBodyLeftPic = cloneForm.carBodyLeftPic?.split('/')?.at(-1);
      cloneForm.carBodyRightPic = cloneForm.carBodyRightPic?.split('/')?.at(-1);
      // cloneForm.carRoofPic = cloneForm.carRoofPic?.split('/')?.at(-1);
      cloneForm.runningLicenceImg = cloneForm.runningLicenceImg
        ?.split('/')
        ?.at(-1);
      cloneForm.carInstrumentPanelPic = cloneForm.carInstrumentPanelPic
        ?.split('/')
        ?.at(-1);
      cloneForm.vehicleNameplateImg = cloneForm.vehicleNameplateImg
        ?.split('/')
        ?.at(-1);
      cloneForm.batteryNameplateImg = cloneForm.batteryNameplateImg
        ?.split('/')
        ?.at(-1);

      cloneForm.carOtherPics = cloneForm.carOtherPics
        ?.split(',')
        .map((pic) => pic?.split('/')?.at(-1))
        .join();

      // 金额和折扣转数字 技师ids 施工前后图片
      let cloneServiceData = this.$lodash.cloneDeep(this.serviceTableData);
      cloneServiceData.forEach((service) => {
        service.billDiscount = Number(service.billDiscount) || 0;
        service.price = Number(service.price) || 0;
        service.discount = Number(service.discount) || 0;
        service.num = Number(service.num) || 0;
        service.total = Number(service.total) || 0;

        service.afterConstructionPics = service.afterConstructionPics
          ?.split(',')
          .map((item) => item?.split('/')?.at(-1))
          .join();
        service.preConstructionPics = service.preConstructionPics
          ?.split(',')
          .map((item) => item?.split('/')?.at(-1))
          .join();

        if (Array.isArray(service.technicianIds)) {
          service.technicianIds = service.technicianIds.map(
            (item) => item.userId
          );
        }
        if (service.billGoods?.length) {
          service.billGoods = service.billGoods.filter((item) => item.goodsId);
          service.billGoods.forEach((goods) => {
            goods.price = Number(goods.price) || 0;
            goods.discount = Number(goods.discount) || 0;
            goods.num = Number(goods.num) || 0;
            goods.total = Number(goods.total) || 0;
            goods.serviceItemId = service.serviceItemId;
          });
        }
      });
      // cloneForm.total = +cloneForm.total || 0;
      cloneForm.mileage = +cloneForm.mileage || undefined;

      // 需要合并的内容
      let merge = {};

      // 自费
      if (this.billType == 0) {
        // 消费客户ID
        cloneForm.isOthersSelfPayState = this.isOthersSelfPayState;
        cloneForm.consumerId = this.payerInfo.clientId || this.carInfo.clientId;
      }

      // 包干
      if (this.billType == 1) {
        // 消费客户ID
        cloneForm.consumerId = this.carInfo.clientId;
      }

      // 理赔单
      if (this.billType == 2) {
        let cloneBillInsuranceDto = this.$lodash.cloneDeep(
          this.billInsuranceDto
        );
        // 消费客户ID
        cloneForm.consumerId = cloneBillInsuranceDto.claimsInsuranceCompanyId;

        // cloneBillInsuranceDto.runningLicenceImg =
        //   cloneBillInsuranceDto.runningLicenceImg?.split('/').at(-1);
        cloneBillInsuranceDto.picDrivingLicence =
          cloneBillInsuranceDto.picDrivingLicence?.split('/').at(-1);
        cloneBillInsuranceDto.feeListPicA = cloneBillInsuranceDto.feeListPicA
          ?.split('/')
          .at(-1);
        cloneBillInsuranceDto.feeListPicB = cloneBillInsuranceDto.feeListPicB
          ?.split('/')
          .at(-1);
        cloneBillInsuranceDto.feeListPicC = cloneBillInsuranceDto.feeListPicC
          ?.split('/')
          .at(-1);
        cloneBillInsuranceDto.feeListPicOthers =
          cloneBillInsuranceDto.feeListPicOthers
            ?.split(',')
            .map((item) => item.split('/').at(-1))
            .join(',');
        merge.billInsuranceDto = cloneBillInsuranceDto;

        cloneForm.isOtherClientPush = this.isOtherClientPush;
        cloneForm.pushClientId = this.pushClientId || this.carInfo.clientId;
        cloneForm.isJqInfo = this.isJqInfo;
        cloneForm.jqInsuranceId = this.jqInsuranceId;
        cloneForm.jqCaseNum = this.jqCaseNum;
        cloneForm.isInsuranceTurnSelf = this.isInsuranceTurnSelf;
        cloneForm.beTurnConsumerId = this.beTurnConsumerId;
      }

      // 索赔单
      if (this.billType == 3) {
        let cloneBillUndertakeDto = this.$lodash.cloneDeep(
          this.billUndertakeDto
        );
        // 索赔单号
        cloneBillUndertakeDto.claimsNum =
          cloneBillUndertakeDto.claimsNumArr
            ?.filter((item) => !!item.value)
            ?.map((item) => item.value)
            .join() || '';
        // 照片
        if (Array.isArray(cloneBillUndertakeDto.undertakePics)) {
          cloneBillUndertakeDto.undertakePics =
            cloneBillUndertakeDto.undertakePics
              .map((item) => item.split('/').at(-1))
              .join();
        } else {
          cloneBillUndertakeDto.undertakePics =
            cloneBillUndertakeDto.undertakePics
              ?.split(',')
              .map((item) => item.split('/').at(-1))
              .join();
        }
        cloneBillUndertakeDto.claimsNum = cloneBillUndertakeDto.claimsNumArr
          .map((item) => item.value)
          .join();
        // 消费客户ID
        cloneForm.consumerId =
          cloneBillUndertakeDto.undertakeInsuranceCompanyId;
        merge.billUndertakeDto = cloneBillUndertakeDto;
      }

      var body = {
        billType: this.billType,
        billServices: cloneServiceData,
        ...this.carInfo,
        ...this.payerInfo,
        // 客户ID
        // contactId: this.carInfo.clientId,

        // 总价
        total: +this.billTotalPrice || 0,
        // 折扣金额
        discountAmount: +Big(this.billTotalPrice).minus(
          this.billDiscountTotalPrice
        ),
        //  Number(this.billDiscount)
        //   ? +this.billDiscountTotalPrice || 0
        //   : +this.billTotalPrice || 0,
        // 实收金额
        paid: +this.billDiscountTotalPrice || 0,
        // 整单折扣
        discount: Number(this.billDiscount) || 0,
        // 协议ID
        clientAgreeId:
          this.carInfo?.billClientMemberMessageVo?.agreementClientId,

        companyId: this.userInfo?.companyId,
      };
      let doubleSysData = {};
      if (
        _this.isSpecificClient() &&
        [1, 2].includes(_this.form.doubleSysInputState)
      ) {
        doubleSysData.doubleSysInputState = _this.form.doubleSysInputState;
        doubleSysData.doubleSysNum = _this.doubleSysNumArr
          .map((item) => item.value)
          .filter((item) => item !== null && item !== undefined)
          .filter((item) => item !== '')
          .join();
      }
      console.log('body', body);
      console.log('merge', merge);
      console.log('cloneForm', cloneForm);
      console.log('doubleSysData', doubleSysData);
      // 合并表单
      let data = Object.assign({}, body, merge, cloneForm, doubleSysData, {
        billType: this.billType,
      });

      if (this.id) {
        let result = await verifyBeforeEditCarBill(this.id);
        if (!result) return;
        updateCarBillDetail(data).then((res) => {
          this.$message.success('保存成功！');
          let route = {
            name: 'carBillDetail',
            path: '/carBill/carBillDetail',
            fullPath: `/carBill/carBillDetail?id=${this.id}`,
            query: {
              type: this.billType,
              id: this.id,
            },
          };
          this.$store.dispatch('REPLACE_TAG', route);
        });
      } else {
        // 加上预约、现场排号参数
        const mergeData = Object.assign({}, data, {
          fromBusinessType: this.fromBusinessType,
          fromBusinessTypeId: this.fromBusinessTypeId,
        });
        insertCarBill(mergeData).then((res) => {
          this.$route.query.id = res;
          this.id = res;
          this.$message.success('保存成功！');
          let route = {
            name: 'carBillDetail',
            path: '/carBill/carBillDetail',
            fullPath: `/carBill/carBillDetail?id=${this.id}`,
            query: {
              type: this.billType,
              id: this.id,
            },
          };
          this.$store.dispatch('REPLACE_TAG', route);
        });
      }
    },
    /**
     * 选择文件
     */
    chooseFile(row, key) {
      chooseAndUploadFiles('image/*').then((res) => {
        if (row[key]) {
          let arr = row[key]?.split(',');
          row[key] = arr.concat(res.map((item) => item.imageUrl)).join(',');
        } else {
          row[key] = res.map((item) => item.imageUrl).join(',');
        }
        this.tableKey = Math.random();
      });
    },
    /**
     * 关闭
     */
    close() {
      this.$confirm('如果离开页面，已填写的数据将丢失，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.id) {
            let route = {
              name: 'carBillDetail',
              path: '/carBill/carBillDetail',
              fullPath: `/carBill/carBillDetail?id=${this.id}`,
              query: {
                id: this.id,
              },
            };
            this.$store.dispatch('REPLACE_TAG', route);
          } else {
            this.$store.commit('DEL_TAG', {
              index: this.$store.getters.activeTagIndex,
            });
          }
        })
        .catch((action) => {});
    },
    /**
     * 查看车辆详情
     */
    async goCarDetail() {
      await verifyAuth(52);
      this.$router.push({
        name: 'carDetail',
        query: {
          id: this.carInfo.carId,
        },
      });
    },
    /**
     * 查看车辆历史消费记录
     */
    async viewHistory() {
      await verifyAuth(52);
      const id = this.carInfo.carId;
      this.$router.push({
        name: 'carDetail',
        query: {
          id,
        },
        params: {
          activeTabName: 'second',
        },
      });
    },
    /**
     * 查看客户详情
     */
    async goClientDetail() {
      await verifyAuth(107);
      this.$router.push({
        name: 'customerDetails',
        query: {
          id: this.carInfo.clientId,
        },
      });
    },
    //客户备注触发事件
    remarksDrawerVisibilityEvent(value) {
      if (value == 1) {
        this.clientRemark = this.carInfo.clientRemark;
      } else {
        this.clientRemark = this.payerInfo.clientRemark;
      }
      this.remarksDrawerVisibility = true;
    },

    /**
     * 商品编码输入框聚焦事件
     */
    showSelectGoodsPopover() {
      this.$refs.selectGoodsPopover.show();
    },
    /**
     * 显示车辆绑定客户弹窗
     */
    showBindingCustomerDialog() {
      this.carBindingCustomerDialogVisibility = true;
    },
    /**
     * 处理绑定客户事件
     */
    handleBindingClient() {
      // 重新查询车辆信息
      this.onAddCar(this.carInfo.carId);
    },
    /**
     * 商品检测按钮
     */
    verifyGoodsQuantity() {
      const goodsEditCheckDetails = this.serviceTableData.map((service) => {
        const goodsDetails =
          service.billGoods?.map((goods) => ({
            goodsId: goods.goodsId,
            num: goods.num,
            materialGoodsId: goods.materialGoodsId,
            materialGoodsState: goods.materialGoodsState,
            billServiceId: service.billServiceId,
            serviceItemId: service.serviceItemId,
            serviceSequence: service.sequence,
            goodsSequence: goods.sequence,
          })) || [];
        return {
          billServiceId: service.billServiceId,
          serviceItemId: service.serviceItemId,
          goodsDetails,
        };
      });
      const body = {
        billId: this.id,
        goodsEditCheckDetails,
      };
      billGoodsEditCheck(body).then((res) => {
        if (!res?.materialService?.length) {
          this.$message.success('当前车单报价商品与材料单数量一致');
        } else {
          this.exceptionList = res.materialService;
          this.goodsTree = res.goodsFirstCategory;
          this.editCarBillGoodsDrawerVisibility = true;
        }
      });
    },
    /**
     * 商品检测保存合并商品
     */
    mergeGoods(list) {
      // 服务是否存在于报价单
      let inOldServices = (serviceId) => {
        return this.serviceTableData.find(
          (item) => item.serviceItemId === serviceId
        );
      };

      /**
       * 新增商品
       * @param goods 商品对象
       * @param service 服务对象
       */
      let addNewGoods = (goods, service) => {
        // 去除原来空商品行
        this.serviceTableData.forEach((item) => {
          if (item.billGoods?.length) {
            item.billGoods = item.billGoods.filter((el) => el.goodsId);
          }
        });

        // 组合商品
        let addGoods = {
          isChild: true,
          id: service.serviceItemId + Math.random(),
          parentId: service.serviceItemId,

          name: goods.goodsName,
          code: goods.goodsCode,
          discount: '',

          goodsId: goods.goodsId,
          goodsName: goods.goodsName,
          goodsCode: goods.goodsCode,
          inventoryTotalNum: goods.inventoryTotalNum,
          num: goods.newNum,
          price: goods.price,
          unit: goods.unit,

          // 物料单标记
          materialGoodsId: goods.materialGoodsId,
          materialGoodsState: true,
        };

        addGoods.total = Big(addGoods.price).times(addGoods.num).toFixed(2);
        addGoods.id = addGoods.goodsId + Math.random();

        // // 自费单并且开启使用协议折扣
        // if (this.billType === 0 && this.useAgreementDiscount) {
        //   addGoods = this.matchDiscount(addGoods, 0);
        //   if (addGoods.discount) {
        //     addGoods.total = Big(addGoods.price)
        //       .times(addGoods.num)
        //       .times(addGoods.discount)
        //       .div(10)
        //       .toFixed(2);
        //   } else {
        //     addGoods.total = Big(addGoods.price).times(addGoods.num).toFixed(2);
        //   }
        // }

        if (Array.isArray(service?.billGoods)) {
          this.$set(service.billGoods, service.billGoods.length, addGoods);
        } else {
          this.$set(service, 'billGoods', [addGoods]);
        }
      };

      /**
       * 判断商品所属服务是否存在于报价单中及添加操作
       * @param goods 商品对象
       */
      let handleServiceIsInOldService = (goods) => {
        let isInOldServices = inOldServices(goods.serviceItemId);
        // 商品所属服务是否存在于报价单中
        if (isInOldServices) {
          // 商品是否存在于服务中
          let oldGoods = isInOldServices?.billGoods?.find(
            (g) => g.goodsId === goods.goodsId
          );
          if (oldGoods) {
            goods.newNum = +goods.newNum + +oldGoods.num;
            changeGoodsNum(goods, isInOldServices);
          } else {
            // 新增商品
            addNewGoods(goods, isInOldServices);
          }
        } else {
          // 新增服务
          this.onSelectServiceItem(goods.service, false);
          addNewGoods(goods, this.serviceTableData.at(-1));
        }
      };

      /**
       * 修改服务中某个商品的数量
       * @param goods 商品对象
       * @param service 服务对象
       */
      let changeGoodsNum = (goods, service) => {
        // 找到服务中的商品
        let currGoods = service.billGoods.find(
          (g) => g.goodsId === goods.goodsId
        );
        // 修改数量
        currGoods.num = goods.newNum;
        currGoods.total = Big(currGoods.price)
          .times(currGoods.num)
          .times(
            currGoods.discount > 0
              ? Big(currGoods.discount).div(10).toFixed(2)
              : 1
          )
          .toFixed(2);
      };

      // 遍历处理
      list.forEach((goods) => {
        // 商品是否为新增商品
        // let isAddGoods = !goods.originServiceId &&
        if (!goods.originServiceId) {
          // 商品所属服务是否存在于报价单中
          handleServiceIsInOldService(goods);
        } else {
          // 找到原服务
          let service = inOldServices(goods.originServiceId);
          // 商品是否修改过所属服务
          if (goods.originServiceId === goods.serviceItemId) {
            changeGoodsNum(goods, service);
          } else {
            handleServiceIsInOldService(goods);
            // 处理原服务
            if (goods.originServiceId) {
              // 在原服务中删除此商品
              let index = service.billGoods.findIndex(
                (s) => s.goodsId === goods.goodsId
              );
              service.billGoods.splice(index, 1);
              // 原服务中是否存在其他商品
              if (!service.billGoods.length) {
                let serviceIndex = this.serviceTableData.findIndex(
                  (s) => s.serviceItemId === service.serviceItemId
                );
                this.serviceTableData.splice(serviceIndex, 1);
              }
            }
          }
        }
      });
      this.setIndex(this.serviceTableData);
      this.calculateTotalAmount();
    },

    /**
     * 他人自费状态变更
     */
    handleOthersSelfPayChange(val) {
      if (val) {
        this.selectOtherPayClientDialogVisibility = true;
      } else {
        this.deletePayer();
      }
    },

    /**
     * 选择他人自费客户
     */
    handleSelectOtherPayClient(value) {
      if (value.id) {
        this.payerInfo = value;
        this.payerInfo.clientId = value.id;
        this.getClientSettlementWay(
          'payerSettlementWay',
          this.payerInfo.clientId
        );

        this.handleConsumerChange();
      } else {
        this.isOthersSelfPayState = false;
        this.deletePayer();
      }
    },

    /**
     * 清空他人自费客户
     */
    handleClearSelectOtherPayClient() {
      this.isOthersSelfPayState = false;
      this.handleConsumerChange();
    },

    /**
     * 删除他人自费客户
     */
    deletePayer() {
      if (this.payerInfo.clientId) this.payerInfo.clientId = '';

      console.log(this.carInfo);
      console.log(this.payerInfo);
      // 车主没有协议  && 他人自费有协议 清除车单保存的协议id
      if (
        !this.carInfo.billClientMemberMessageVo?.agreementClientId &&
        this.payerInfo?.billClientMemberMessageVo?.agreementClientId
      ) {
        delete this.form.clientAgreeId;
        // 清除协议和赠送
        this.serviceTableData.forEach((row) => {
          this.$set(row, 'agreementFree', false);
          this.$set(row, 'agreementFreeRemark', '');
        });
      }
      this.payerInfo = {};
      this.handleConsumerChange();
    },

    //查询车辆质保情况
    checkCarQueryWarranty(id, number) {
      if (number == 1) {
        var data = {
          billId: id,
        };
      } else if (number == 2) {
        data = {
          carId: id,
        };
      }
      getCarQueryWarranty(data).then((res) => {
        this.underWarrantyState = res.underWarrantyState;
        let { vehicleVo, corePartsVo, wearingPartsVo } = res;
        this.vehicleVo = vehicleVo || []; //整车质保
        this.corePartsVo = corePartsVo || []; //核心件质保
        this.wearingPartsVo = wearingPartsVo || []; //易损件质保
        this.vehicleVoList = this.$lodash.cloneDeep(res.vehicleVo).slice(1);
        this.corePartsVoList = this.$lodash.cloneDeep(res.corePartsVo).slice(1); //核心件质保
        this.wearingPartsVoList = this.$lodash
          .cloneDeep(res.wearingPartsVo)
          .slice(1); //易损件质保
      });
    },

    /**
     * 存在协议价时，提示不允许修改
     */
    showDisabledReason() {
      this.$message.warning('该项目存在约定协议价，不允许修改');
    },

    /**
     * 查询消费客户现结/月结状态
     * @param {string} type 类型 carOwnerSettlementWay -车主 payerSettlementWay -消费客户
     * @param {number} id 客户ID
     */
    getClientSettlementWay(type, id) {
      getClientSettlementWayApi({
        clientId: id,
        billType: this.billType,
        companyId: this.userInfo.companyId,
      }).then((res) => {
        console.log(res, 'res.data');
        this[type] = res;
      });
    },

    /**
     * 批量设置折扣
     */
    handleBatchSetDiscount() {
      console.log(this.batchDiscountNumber);
      var batchDiscountNumber = this.batchDiscountNumber;
      function setItemDiscount(row) {
        if (row.isAgreePrice) return;
        row.discount = +batchDiscountNumber || '';
        row.total = Big(row.price).times(row.num);
        if (+row.discount > 0) {
          row.total = row.total.times(Big(row.discount).div(10));
        }
        row.total = row.total.toFixed(2);
      }

      this.serviceTableData.forEach((service) => {
        setItemDiscount(service);
        if (service.billGoods?.length) {
          service.billGoods.forEach((goods) => {
            if (goods.goodsId) setItemDiscount(goods);
          });
        }
      });

      this.editDiscountBatch = false;
      this.calculateTotalAmount();
      this.tableKey = Math.random();
    },

    /**
     * 消费客户变更 - 查询协议价
     */
    handleConsumerChange() {
      var quotationServiceDto = this.serviceTableData.map((service) => {
        var quotationGoodsDto = (
          service.billGoods?.map((goods) => ({
            goodsId: goods.goodsId,
          })) || []
        ).filter((item) => item.goodsId);
        return {
          serviceItemId: service.serviceItemId,
          quotationGoodsDto,
        };
      });

      var body = {
        billType: this.billType,
        companyId: this.userInfo.companyId,
        carId: this.carInfo.carId,
        clientAgreeId:
          (this.isOthersSelfPayState
            ? this.customerAgreementId || ''
            : this.carAgreementId || '') || this.customerAgreementId,
        quotationServiceDto,
      };
      getAgreementPriceByConsumer(body).then((res) => {
        console.log(res);
        var change = false;
        res.forEach((service, i) => {
          var current = this.serviceTableData[i];
          if (service.isAgreePrice) {
            //协议价 清空折扣
            current.discount = '';
            if (current.price !== service.price) {
              current.price = service.price;
            }
            change = true;
          } else {
            this.$set(current, 'agreementFree', false);
            this.$set(current, 'agreementFreeRemark', '');
          }
          this.changePrice(current);
          current.isAgreePrice = service.isAgreePrice;

          service.quotationGoodsVo?.forEach((goods, j) => {
            if (goods.isAgreePrice) {
              //协议价 清空折扣
              current.billGoods[j].discount = '';
              if (current.billGoods[j].price !== goods.price) {
                current.billGoods[j].price = goods.price;
              }
              change = true;
            } else {
              this.$set(goods, 'agreementFree', false);
              this.$set(goods, 'agreementFreeRemark', '');
            }
            this.changePrice(current.billGoods[j]);
            current.billGoods[j].isAgreePrice = goods.isAgreePrice;
          });
        });
        this.calculateTotalAmount();
        if (change) {
          this.$message.warning('当前车单协议价已更新');
        }
      });
    },

    // ======================== 理赔新增推修对象 交商不同 start ===============================
    /**
     * 他人推修开关
     */
    handleOtherClientPushChange(val) {
      if (val) {
        this.selectOtherClientPushDialogVisibility = true;
      } else {
        this.handleClearSelectOtherClientPush();
      }
    },
    /**
     * 选择他人推修客户
     */
    handleSelectOtherClientPush(client) {
      if (client.id) {
        this.pushClientId = client.id;
        this.pushClientName = client.clientName;
      } else {
        this.handleClearSelectOtherClientPush();
      }
    },
    /**
     * 清除他人推修客户
     */
    handleClearSelectOtherClientPush() {
      this.isOtherClientPush = 0;
      this.pushClientId = '';
      this.pushClientName = '';
    },

    /**
     * 点击其他信息中选项
     */
    handleClickInsuranceTurnSelf(val) {
      if (val === this.isInsuranceTurnSelf) {
        this.isInsuranceTurnSelf = '';
        this.handleClearSelectInsuranceCustomer();
      } else {
        this.isInsuranceTurnSelf = val;
        // 保险消费客户 默认车主
        this.beTurnConsumerName = this.carInfo.clientName;
        this.beTurnConsumerId = this.carInfo.clientId;
        this.getClientSettlementWay(
          'beTurnConsumerSettlementWay',
          this.beTurnConsumerId
        );
      }
    },

    /**
     * 选择交强险保险公司 - 理赔单
     */
    handleSelectJqInsuranceCompany(val) {
      this.jqInsuranceId = val.id;
      this.jqInsuranceName = val.invoiceCompanyName;
    },
    /**
     * 清空交强险保险公司 - 理赔单
     */
    handleClearJqInsuranceCompany() {
      this.jqInsuranceId = '';
      this.jqInsuranceName = '';
    },

    /**
     * 选择理赔消费客户
     */
    handleSelectInsuranceCustomer(client) {
      if (client.id) {
        this.beTurnConsumerName = client.clientName;
        this.beTurnConsumerId = client.id;
        this.getClientSettlementWay(
          'beTurnConsumerSettlementWay',
          this.beTurnConsumerId
        );
      } else {
        this.handleClearSelectInsuranceCustomer();
      }
    },
    /**
     * 清除理赔消费客户
     */
    handleClearSelectInsuranceCustomer() {
      this.beTurnConsumerName = '';
      this.beTurnConsumerId = '';
      this.beTurnConsumerSettlementWay = '';
    },
    // ======================== 理赔 end ===============================
  },
};
</script>
<style lang="scss" scoped>
// header
.header-box {
  overflow: hidden;
  margin-top: 10px;
  border-radius: 4px;
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05),
  //   0px -2px 4px 0px rgba(0, 0, 0, 0.05);
  .row1 {
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 16px;
    padding-right: 30px;
    .bill-type {
      width: 54px;
      height: 100%;
      writing-mode: vertical-lr; //vertical-rl;从右往左排 vertical-lr是从左往右排
      letter-spacing: 7px; //设置间距
      background: $primary;
      color: #fff;
      font-size: 16px;
    }
    .select-car {
      margin-left: 32px;
      .select-car-label {
        margin-right: 12px;
        color: #333;
      }
      .el-select {
        width: 300px;
      }
      .or {
        margin: 0 18px;
        font-size: 14px;
        color: #666;
      }
      .bill-state {
        font-size: 60px;
        margin-left: 10px;
      }
    }
    .bill-process {
      display: flex;
      font-size: 12px;
      color: #999;
      .state-name {
        display: flex;
        flex-direction: column;
        text-align: center;
        span:first-child {
          font-size: 14px;
        }
        .time {
          margin-top: 5px;
        }
      }
      .split-string {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -8px 10px 0;
        .top-text {
          height: 17px;
          box-sizing: border-box;
          // position: absolute;
          // bottom: 100%;
          padding-bottom: 5px;
          white-space: nowrap;
          &.plan-time {
            // color: #333;
          }
        }
        .line {
          height: 1px;
          width: 100%;
          background: #d8d8d8;
          min-width: 50px;
        }
        .bottom-text {
          margin-top: 10px;
        }
      }
    }
  }
  .split-line {
    border-bottom: 1px solid #e6e6e6;
  }
  .row2 {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    border-bottom: 1px solid #e6e6e6;
    .car-and-client-info-box {
      display: flex;
      align-items: center;
      width: 100%;
      > .car-logo {
        width: 69px;
        height: 69px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .client-info {
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-grow: 1;
        > div + div {
          margin-top: 15px;
        }
        .car-info {
          .car-number {
            color: #333;
            font-weight: 600;
            font-size: 30px;
          }

          .history {
            color: #e84931;
          }
          .riskControlCar {
            color: #e84931;
            font-weight: bold;
            font-size: 15px !important;
          }
          .car-Contract {
            color: #e84931;
            font-weight: bold;
            font-size: 15px !important;
          }
          .tag {
            display: inline-block;
            margin-left: 15px;
            padding: 4px 6px;
            border-radius: 4px;
            border: 1px solid rgba(66, 187, 184, 0.1);
            font-size: 12px;
          }
        }
        .car-info-tag {
          margin-bottom: 10px;
          .car-vehicle-type {
            color: #42bbb8;
          }
          .car-uniqueId {
            color: #3eb1e0;
          }
          .car-brand {
            color: #55b78e;
          }
          .tag {
            display: inline-block;
            margin-right: 15px;
            padding: 4px 6px;
            border-radius: 4px;
            border: 1px solid rgba(66, 187, 184, 0.1);
            font-size: 12px;
          }
        }
      }
    }

    .row2-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .status {
        display: flex;
        justify-content: flex-end;
        i {
          font-size: 50px;

          margin-left: 20px;
        }
        .grow-icon_order {
          color: $blue;
        }
        .grow-icon_warn1 {
          color: #4ebebc;
        }
        .grow-icon_chedan {
          color: $orange;
        }
      }
    }
  }
  // 用户信息 车辆信息和他人自费共用
  .client-base-info {
    margin-top: 16px;
    .icon-person {
      color: #44bf86;
      margin-left: 0;
    }
    .client-name {
      color: #333;
    }
    .client-phone {
      margin-left: 15px;
      color: #333;
    }
    .icon_sex {
      color: $blue;
    }
    .icon_grade {
      color: $blue;
    }
    .grow-icon_girl {
      color: #f48080;
    }
    .icon_wechat {
      color: #999;
    }
    .icon_wechat_active {
      color: #00c800;
    }
    // i {
    //   margin-left: 15px;
    // }
  }
  .client-vip-info {
    .vip-text {
      margin-left: 10px;
      color: #666;
      font-size: 14px;
    }
  }
  .empty-tip {
    padding: 20px;
    text-align: center;
    color: #999;
  }
  // 他人自费信息
  .other-people-payment-box {
    display: flex;
    align-items: center;
    padding: 20px;
    .label {
      display: flex;
      align-items: center;
      color: #333;
    }
    .select-client-box {
      > div + div {
        margin-top: 15px;
      }
      .select-client {
        .select-client-label {
          margin-right: 12px;
          color: #333;
        }
        .el-select {
          width: 300px;
        }
        .or {
          margin: 0 18px;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
  .insurance-type-bill-box,
  .undertake-type-bill-box {
    display: flex;
    align-items: center;
    padding: 20px;
    .title {
      width: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 20px;
      > span:first-child {
        color: #333;
        font-size: 16px;
      }
      > span:nth-child(2) {
        font-size: 12px;
        color: #999;
      }
    }
  }
  // 理赔信息
  .insurance-type-bill-box {
    .el-form {
      width: 800px;
      margin-bottom: -22px;
    }
  }
  // 索赔信息
  .undertake-type-bill-box {
    .el-form {
      width: 800px;
      margin-bottom: -22px;
    }
    .undertake-num {
      display: flex;
      position: relative;

      & + .undertake-num {
        margin-top: 12px;
      }
      > i {
        position: absolute;
        left: -25px;
      }
    }
  }
  // 预交车时间
  .plan-finish-time-box {
    display: flex;
    align-items: center;
    padding: 20px;
    .title {
      width: 110px;
      color: #333;
    }
    .el-form {
      width: 240px;
      margin-bottom: -22px;
    }
  }
}
.double-num-item {
  display: flex;
  margin: 6px 0px;
}
.orange-btn {
  // background: $orange;
  //  color: #fff;
  border: 1px solid #33ab79;
  color: #33ab79;
  border-radius: 4px;
}

.el-form-item__content > div {
  width: 100%;
}

.btns {
  border-bottom: 2px solid #fafafa;
  .bao-btn {
    display: inline-block;
    width: 138px;
    height: 46px;
    background: $bg;
    color: #55b78e;
    text-align: center;
    line-height: 48px;
    margin-right: 30px;
    border-radius: 4px 4px 0px 0px;
  }
  .maintain-btn {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    border: 1px solid $blue;
    color: $blue;
  }
}
.com-services {
  height: 60px;
  border-left: 2px solid #fafafa;
  line-height: 60px;
  padding-left: 38px;
  font-size: 14px;
  .service-item {
    color: $primary;
    & + .service-item {
      margin-left: 20px;
    }
  }
}

.service-table {
  margin-bottom: 400px;
  .table-del-icon {
    // margin-left: 24px;
  }
  .el-table__expand-icon + .table-del-icon {
    margin-left: 0;
  }
}
.add-service-item {
  width: 100%;
  padding: 10px 0;
  padding-left: 138px;
}
.service-table ::v-deep .el-table__empty-text {
  width: 100% !important;
}

// footer
.footer-box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  .bill-price-box {
    .title {
      color: #333;
      font-size: 14px;
    }
    .rmb {
      font-size: 12px;
      color: $orange;
    }
    .price-number {
      color: $orange;
      font-size: 18px;
    }
    .original-price {
      margin-left: 5px;
      color: #666;
      font-size: 12px;
    }
  }
  .price-detail {
    margin-top: 8px;
    font-size: 12px;
    color: #666;
  }
}
.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}
// 不显示收起图标
.service-table.el-table ::v-deep .el-table__expand-icon {
  display: none;
}
.service-table.el-table ::v-deep .el-table__indent {
  padding: 0 !important;
}
.service-table.el-table ::v-deep .el-table__placeholder {
  width: 0 !important;
}
.service-table.el-table ::v-deep input {
  padding: 0 3px;
}
//质保样式
.warranty-info {
  display: inline-block;
  margin-left: 16px;
  padding: 4px 6px;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  font-size: 12px;
  color: #606266;
  background: #f5f7fa;
}
.warranty-title {
  color: #3c3d40;
  margin-right: 5px;
}
.tips {
  color: #909399;
  font-size: 12px;
}
</style>
