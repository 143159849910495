var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-container",
    { ref: "baseContainer", attrs: { paddingTop: "" } },
    [
      [
        _c(
          "el-form",
          {
            staticClass: "filter-form",
            attrs: { model: _vm.form, "label-width": "100px", size: "small" },
          },
          [
            _c("div", { staticClass: "flex-x-between" }, [
              _c(
                "div",
                { staticClass: "flex flex-shrink0" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户信息" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入姓名/手机/车牌",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getDataList.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.clientMsg,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "clientMsg", $$v)
                          },
                          expression: "form.clientMsg",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.form.vipType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vipType", $$v)
                            },
                            expression: "form.vipType",
                          },
                        },
                        _vm._l(_vm.vipType, function (item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-x-center" },
                          [
                            _vm._v(" 建档门店 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "light",
                                  content:
                                    "客户档案的建档门店,可查连锁范围内全部门店",
                                  placement: "bottom-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont grow-icon_doubt",
                                  staticStyle: { color: "#9a9a9a" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "span6_input",
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            placeholder: "搜索车店名称、编号",
                            "remote-method": _vm.searchStore,
                            loading: _vm.searchStoreLoading,
                          },
                          model: {
                            value: _vm.form.filingStoreCompanyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "filingStoreCompanyId", $$v)
                            },
                            expression: "form.filingStoreCompanyId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "left",
                                    color: "#8492a6",
                                    "font-size": "13px",
                                  },
                                },
                                [_vm._v(_vm._s(item.companyId))]
                              ),
                              _c("span", { staticStyle: { float: "right" } }, [
                                _vm._v(_vm._s(item.companyName)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "el-form-item--small" }, [
                _c(
                  "div",
                  {
                    staticClass: "el-form-item__label pointer",
                    on: {
                      click: function ($event) {
                        _vm.showMore = !_vm.showMore
                      },
                    },
                  },
                  [
                    _vm._v(" 更多筛选 "),
                    _c("i", {
                      class: {
                        primary: true,
                        "el-icon-arrow-right": !_vm.showMore,
                        "el-icon-arrow-down": _vm.showMore,
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.getDataList()
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_search",
                        attrs: { size: "small" },
                        on: { click: _vm.empty },
                      },
                      [_vm._v("置空")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm.showMore
              ? [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户等级" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.clientLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "clientLevel", $$v)
                                },
                                expression: "form.clientLevel",
                              },
                            },
                            _vm._l(_vm.clientLevel, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "流失客户" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.clientState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "clientState", $$v)
                                },
                                expression: "form.clientState",
                              },
                            },
                            _vm._l(_vm.clientState, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ],
      [
        _c(
          "div",
          {
            staticClass: "btns flex-x-between",
            staticStyle: { "margin-bottom": "15px" },
          },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 61,
                    expression: "61",
                  },
                ],
                staticClass: "btn_insert",
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addCuster },
              },
              [_vm._v("新增客户")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "auth",
                    rawName: "v-auth",
                    value: 282,
                    expression: "282",
                  },
                ],
                staticClass: "blue",
                attrs: { size: "small", type: "text" },
                on: {
                  click: function ($event) {
                    _vm.showExportDialog = true
                  },
                },
              },
              [
                _vm._v("导出列表"),
                _c("i", {
                  staticClass: "iconfont grow-icon_daochu el-icon--right",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "fixedScrollBar",
                rawName: "v-fixedScrollBar",
                value: _vm.$refs.baseContainer,
                expression: "$refs.baseContainer",
              },
            ],
            staticClass: "custom-table",
            attrs: { data: _vm.tableData, border: "" },
            on: { "row-dblclick": _vm.goDetail },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", width: "60", label: "序号" },
            }),
            _c("el-table-column", {
              attrs: { prop: "date", label: "操作", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "auth",
                              rawName: "v-auth",
                              value: 62,
                              expression: "62",
                            },
                          ],
                          staticClass: "pointer text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.getUpdate(scope.$index, scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "clientId", label: "客户ID", width: "100" },
            }),
            _c("el-table-column", {
              attrs: { prop: "name", label: "客户名称", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "pointer text_operation blue",
                          on: {
                            click: function ($event) {
                              return _vm.goDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.name))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "vipType", label: "会员类型", width: "120" },
            }),
            _c("el-table-column", {
              attrs: { label: "客户等级", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.clientLevel == "无" ? "无等级" : row.clientLevel
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "clientLicensePlateNum",
                label: "名下车辆",
                width: "180",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "pointer text_operation primary",
                          on: {
                            click: function ($event) {
                              return _vm.goCarList(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.clientLicensePlateNum))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "clientNotIntoTime",
                label: "未进店天数",
                "min-width": "140",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "remark",
                label: "备注",
                "min-width": "150",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "pointer text_operation" }, [
                        _vm._v(_vm._s(scope.row.remark)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "clientCreateTime",
                label: "建档日期",
                width: "180",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "clientCreatePerpon",
                label: "建档人",
                width: "140",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "companyName", label: "建档门店" },
            }),
          ],
          1
        ),
        _vm.showAddCusterDialog
          ? _c("add-customer-dialog", {
              attrs: {
                showAddCusterDialog: _vm.showAddCusterDialog,
                id: _vm.editId,
              },
              on: {
                "update:showAddCusterDialog": function ($event) {
                  _vm.showAddCusterDialog = $event
                },
                "update:show-add-custer-dialog": function ($event) {
                  _vm.showAddCusterDialog = $event
                },
                update: _vm.getDataList,
              },
            })
          : _vm._e(),
        _c("export-dialog", {
          attrs: {
            visibility: _vm.showExportDialog,
            total: _vm.total,
            pageOption: _vm.pageOption,
            filterForm: _vm.form,
            url: _vm.exportUrl,
          },
          on: {
            "update:visibility": function ($event) {
              _vm.showExportDialog = $event
            },
          },
        }),
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("pagination", {
            attrs: { total: _vm.total },
            on: { pageChange: _vm.changePage },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }