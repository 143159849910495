<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="供应商">
              <el-select
                v-model="form.supplierId"
                filterable
                clearable
                remote
                :remote-method="searchSupplierList"
                :loading="searchSupplierListLoading"
                placeholder="请选择供应商"
              >
                <el-option
                  v-for="(item, index) in supplierList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品标准名">
              <el-input
                v-model="form.goodsStandardName"
                placeholder="请输入商品标准名"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodsName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="648"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="原厂编码">
              <el-input
                v-model="form.goodsCode"
                placeholder="请输入原厂编码"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button
            size="small"
            class="btn_insert"
            style="height: 35px"
            v-auth="711"
            @click.native="batchOperation(2, '批量删除')"
            >批量删除</el-button
          >
          <el-button
            size="small"
            v-auth="710"
            style="margin-left: 10px"
            @click="openImportDialog()"
          >
            批量导入<i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
        <div>
          <el-button
            v-auth="709"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="60"
          prop="selection"
          align="center"
        >
        </el-table-column>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="200" label="作用门店" prop="applyCompanyName">
        </el-table-column>
        <el-table-column width="200" label="供应商ID" prop="supplierId">
        </el-table-column>
        <el-table-column width="200" label="供应商名称" prop="supplierName">
        </el-table-column>
        <el-table-column width="200" label="商品ID" prop="goodsId">
        </el-table-column>
        <el-table-column
          width="200"
          label="商品标准名"
          prop="goodsStandardName"
        >
        </el-table-column>
        <el-table-column width="200" label="商品名" prop="goodsName">
        </el-table-column>
        <el-table-column width="162" label="品质" prop="manufactureTypeDesc">
        </el-table-column>
        <el-table-column width="200" label="原厂编码" prop="goodsCode">
        </el-table-column>
        <el-table-column width="200" label="单位" prop="unit">
        </el-table-column>
        <el-table-column width="140" label="上限价" prop="purchaseCeilingPrice">
        </el-table-column>
        <el-table-column width="140" label="强制锁定" prop="forceLockStr">
        </el-table-column>
        <el-table-column width="200" label="备注" prop="remark">
        </el-table-column>
      </el-table>
      <!-- 导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :visibility.sync="importDialogVisibility"
        :showTip="false"
        title="供应商商品配件采购上限价导入"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240802/%E9%87%87%E8%B4%AD%E4%BB%B7%E7%AE%A1%E6%8E%A7%E6%A8%A1%E7%89%88.xlsx"
        :uploadFileApi="importApi"
        :getLastResultApi="importResultApi"
        :importFailDetail="importMessageExportUrlApi"
      ></import-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';
// 接口
import { downloadFile } from '@/utils/fileApi';
import { supplierSearch } from '@/api/stock/inventoryReplenishment/index';
import {
  purchasePriceList,
  purchasePriceListExportUrl,
  purchasePricedeleteBatch,
  purchasePriceImport,
  purchasePriceImportResult,
  purchasePriceImportResultExportUrl,
} from '@/api/stock/inventory';
import { hasAuth } from '@/utils/permissions';
import { mapState } from 'vuex';

export default {
  name: 'purchasePriceControlList',
  components: {
    pagination,
    importDialog,
  },
  data() {
    return {
      importApi: purchasePriceImport,
      importResultApi: purchasePriceImportResult,
      importMessageExportUrlApi: purchasePriceImportResultExportUrl,

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      exportUrl: purchasePriceListExportUrl,
      supplierList: [],
      searchSupplierListLoading: false,
      searchSupplierTimer: undefined,
      form: {},
      selectData: [],
      // 展示更多筛选
      showMore: true,
      listData: [],
      // 导入弹窗可见性
      importDialogVisibility: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.getData();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    searchSupplierList(keyWord) {
      const _this = this;
      _this.searchSupplierTimer != undefined &&
        window.clearTimeout(_this.searchSupplierTimer);

      _this.searchSupplierTimer = window.setTimeout(function () {
        _this.searchSupplierListLoading = true;
        supplierSearch({ key: keyWord || '' }, { noShowWaiting: true })
          .then((res) => {
            _this.supplierList = res || [];
          })
          .finally(() => {
            _this.searchSupplierListLoading = false;
          });
      }, 800);
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    handleSelectionChange(items, row) {
      this.selectData = [];
      items.forEach((item) => {
        this.selectData.push(item.id);
      });
    },
    openImportDialog() {
      this.importDialogVisibility = true;
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(708);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {};
      var data = Object.assign({}, this.form, this.pageOption, times);

      purchasePriceList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, this.form, {}),
      });
    },
    /**
     * 批量操作
     * @param {Integer} status
     * @param {String} str
     */
    batchOperation(status, str) {
      if (this.selectData.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请选择数据后操作',
        });
      }
      let txt = `确定要${str}选中的记录吗？`;
      this.$confirm(txt, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const body = {
          ids: this.selectData,
        };
        purchasePricedeleteBatch(body).then((res) => {
          this.$message({
            type: 'success',
            message: '批量删除成功',
          });
          this.getData(false);
        });
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        operateCompanyId: [this.companyId],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
