<template>
  <div class="map-box">
    <div class="shade"></div>
    <div id="AMapContainer" tabindex="0"></div>
  </div>
</template>

<script>
import $ from 'jquery';
import AMapLoader from '@amap/amap-jsapi-loader';

import { mapState } from 'vuex';
import {
  getProvinceCompanyNum,
  getCityCompanyNum,
  getCompanyCityDistribution,
  getDistrictCompanyInfo,
} from '@/api/commandCenter';

import { toThousands } from '@/utils/filters/number';

export default {
  data: function () {
    return {
      // 地图实例
      map: null,
      // 行政区实例
      districtExplorer: null,

      // 当前聚焦的区域
      currentAreaNode: null,

      // 鼠标hover提示内容
      $tipMarkerContent: null,
      // 鼠标hover提示窗体
      tipMarker: null,

      // 行政区域中心点字典
      centerMap: new Map(),

      // 地图级别
      level: 'country',

      // 标记点
      markerList: [],

      // 各省份分布数据
      provinceData: [],
      // 各城市分布数据
      cityData: [],
      // 各区县分布数据
      // districtData: [],

      // 门店数据
      storeData: [],

      // 级别 默认进入广州市
      districtArr: [
        {
          name: '中国',
          code: 100000,
          level: 'country',
        },
        {
          name: '广东省',
          code: 440000,
          level: 'province',
        },
        {
          name: '广州市',
          code: 440100,
          level: 'city',
        },
        {},
      ],

      // firstLoad: true,
      lastZIndex: 13,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    needLightArea() {
      let data = [];
      switch (this.level) {
        case 'country':
          data = this.provinceData.map((item) => item.name);
          break;
        case 'province':
          data = this.cityData.map((item) => item.name);
          break;
        case 'city':
          data = this.storeData.map((item) => item.districtName);
          break;
        // case 'district':
        //   data = this.provinceData.map((item) => item.name);
        //   break;
      }
      return data;
    },
  },
  mounted() {
    this.initAMap();
    // this.loadScript(
    //   '//webapi.amap.com/maps?v=2.0&key=764ce9a391af17174b3b0dc493b1f48b'
    // ).then(() => {
    //   this.loadScript('//webapi.amap.com/ui/1.1/main.js?v=1.1.1').then(() => {
    //   });
    // });
  },
  methods: {
    /**
     * 加载script
     * @param {string} url
     */
    // loadScript(url) {
    //   return new Promise((resolve, reject) => {
    //     var script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     if (script.readyState) {
    //       script.onreadystatechange = function () {
    //         if (
    //           script.readyState == 'loaded' ||
    //           script.readyState == 'complete'
    //         ) {
    //           script.onreadystatechange = null;
    //           resolve();
    //         }
    //       };
    //     } else {
    //       script.onload = function () {
    //         resolve();
    //       };
    //     }
    //     script.src = url;
    //     document.body.appendChild(script);
    //   });
    // },
    /**
     * 初始化地图
     */
    initAMap() {
      AMapLoader.load({
        key: '764ce9a391af17174b3b0dc493b1f48b',
        version: '2.0',
        plugins: [
          // 'AMap.ToolBar', 'AMap.Driving'
          'AMap.DistrictSearch',
        ],
        AMapUI: {
          version: '1.1',
          plugins: [],
        },
        // Loca: {
        //   version: '2.0',
        // },
      })
        .then(async (AMap) => {
          // this.map = new AMap.Map("container", {
          //     viewMode: "3D",
          //     zoom: 5,
          //     zooms: [2, 22],
          //     center: [105.602725, 37.076636],
          // });
          //创建地图
          this.map = new AMap.Map('AMapContainer', {
            mapStyle: 'amap://styles/983482ae79b218d543793f3e36fa7e85',
            zoom: 4,
          });

          // var satellite = new AMap.TileLayer.Satellite();
          // this.map.addLayer(satellite);

          // 获取省份列表中心点数据
          await this.districtSearch('中国', 'country', 3).then((result) => {
            var centerMap = new Map();
            function getCenter(area) {
              centerMap.set(area.adcode, [area.center.lng, area.center.lat]);
              if (area.districtList) {
                area.districtList.forEach(getCenter);
              }
            }
            if (result.info === 'OK') {
              result.districtList.forEach(getCenter);
              // console.log('centerMap:', centerMap);
            }
            this.centerMap = centerMap;
          });
          window.AMapUI.load(
            // 'lib/$'
            ['ui/geo/DistrictExplorer'],
            this.AMapUILoadCallback
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    /**
     * AMapUI load callback
     */
    AMapUILoadCallback(DistrictExplorer) {
      //创建一个实例
      // window.districtExplorer =
      this.districtExplorer = new DistrictExplorer({
        eventSupport: true, //打开事件支持
        map: this.map,
      });

      // this.createTipMarker();

      //监听feature的hover事件
      this.districtExplorer.on(
        'featureMouseout featureMouseover',
        (e, feature) => {
          // this.toggleHoverFeature(
          //   feature,
          //   e.type === 'featureMouseover',
          //   e.originalEvent ? e.originalEvent.lnglat : null
          // );

          if (this.level === 'district') return;

          //更新相关多边形的样式
          let { name, adcode } = feature.properties;
          var polys = this.districtExplorer.findFeaturePolygonsByAdcode(adcode);
          let isLight = this.needLightArea.includes(name);
          let isHover = e.type === 'featureMouseover';
          for (var i = 0, len = polys.length; i < len; i++) {
            polys[i].setOptions({
              fillOpacity: isHover ? 0.6 : isLight ? 0.4 : 0.1,
              fillColor: isHover || isLight ? '#0770FF' : '#31C1EA',
              strokeColor: isHover ? '#7AF6FF' : '#31C1EA',
              strokeOpacity: isHover ? 1 : 0.8,
              strokeWeight: isHover ? 2 : 1,
            });
          }
        }
      );

      // 监听鼠标在feature上滑动
      // this.districtExplorer.on('featureMousemove', (e, feature) => {
      //   //更新提示位置
      //   this.tipMarker.setPosition(e.originalEvent.lnglat);
      // });

      //feature被点击
      this.districtExplorer.on('featureClick', (e, feature) => {
        var props = feature.properties;
        console.log(props);
        // props.level "country" "province" "city" "district"
        this.levelChange(props.name, props.adcode, props.level);

        //如果存在子节点
        // if (props.childrenNum > 0) {
        //切换聚焦区域
        // this.switch2AreaNode(props.adcode);
        // }
      });

      //外部区域被点击
      this.districtExplorer.on('outsideClick', (e) => {
        this.districtExplorer.locatePosition(
          e.originalEvent.lnglat,
          (error, routeFeatures) => {
            // if (routeFeatures && routeFeatures.length > 1) {
            //   let number = 0;
            //   switch (this.level) {
            //     case 'country':
            //       number = 1;
            //       break;
            //     case 'province':
            //       number = 2;
            //       break;
            //     case 'city':
            //       number = 3;
            //       break;
            //     case 'district':
            //       number = 4;
            //       break;
            //   }
            //   let targetIndex;
            //   if (routeFeatures.length < number) {
            //     targetIndex = routeFeatures.length - 1;
            //   } else {
            //     targetIndex = number - 1;
            //   }
            //   this.levelChange(
            //     routeFeatures[targetIndex].properties.name,
            //     routeFeatures[targetIndex].properties.adcode,
            //     routeFeatures[targetIndex].properties.level
            //   );
            //   //切换到省级区域
            //   // this.switch2AreaNode(routeFeatures[1].properties.adcode);
            // } else {
            //   this.levelChange('中国', 100000, 'country');
            //   //切换到全国
            //   // this.switch2AreaNode(100000);
            // }

            var i;
            switch (this.level) {
              case 'country':
                i = -1;
                break;
              case 'province':
                i = 0;
                break;
              case 'city':
                i = 1;
                break;
              case 'district':
                i = 2;
                break;
            }
            if (i < 0) return;
            let obj = this.districtArr[i];
            this.levelChange(obj.name, obj.code, obj.level);
          },
          {
            levelLimit: 4,
          }
        );
      });

      // this.levelChange('中国', 100000, 'country');
      // // 默认切换到广东省
      // this.levelChange('广东省', 440000, 'province');
      // 默认切换到广东省
      this.levelChange('广州市', 440100, 'city');
      //全国
      // this.switch2AreaNode(100000);
    },

    /**
     * 创建标记
     */
    createTipMarker() {
      //鼠标hover提示内容
      this.$tipMarkerContent = $('<div class="tipMarker top"></div>');

      this.tipMarker = new window.AMap.Marker({
        content: this.$tipMarkerContent.get(0),
        offset: new window.AMap.Pixel(0, 0),
        bubble: true,
      });
    },
    /**
     * 根据Hover状态设置相关样式
     * @param {*} feature
     * @param {*} isHover
     * @param {*} position
     */
    toggleHoverFeature(feature, isHover, position) {
      this.tipMarker.setMap(isHover ? this.map : null);

      if (!feature) {
        return;
      }

      var props = feature.properties;

      if (isHover) {
        //更新提示内容
        // props.adcode + ': ' +
        this.$tipMarkerContent.html(props.name);
        //更新位置
        this.tipMarker.setPosition(position || props.center);
      }

      // //更新相关多边形的样式
      // var polys = this.districtExplorer.findFeaturePolygonsByAdcode(
      //   props.adcode
      // );
      // for (var i = 0, len = polys.length; i < len; i++) {
      //   polys[i].setOptions({
      //     fillOpacity: isHover ? 0.5 : 0.1,
      //     fillColor: '#0770FF',
      //   });
      // }
    },

    /**
     * 绘制某个区域的边界
     */
    renderAreaPolygons(areaNode) {
      //更新地图视野
      this.map.setBounds(areaNode.getBounds(), null, null, true);
      // if (this.firstLoad) {
      //   this.firstLoad = false;
      //   this.map.setZoom(9.7);
      // }
      // 进入广州地图时设置默认缩放级别
      if (this.level === 'city' && this.districtArr[2].code === 440100) {
        this.map.setZoom(9.7);
      }

      //清除已有的绘制内容
      this.districtExplorer.clearFeaturePolygons();

      //绘制子区域
      this.districtExplorer.renderSubFeatures(areaNode, (feature, i) => {
        // console.log(feature, i);
        var fillColor = '#31C1EA';
        var fillOpacity = 0.1;

        var name = feature.properties.name;
        if (
          // ['广东省', '广州市', '珠海市']
          this.needLightArea.includes(name)
        ) {
          fillColor = '#0770FF';
          fillOpacity = 0.4;
        }
        return {
          cursor: 'default',
          bubble: true,
          strokeColor: '#7AF6FF', //线颜色 31c1ea
          strokeOpacity: 0.8, //线透明度
          strokeWeight: 1, //线宽
          fillColor, //填充色 4bcbff
          fillOpacity, //填充透明度
        };
      });

      //绘制父区域
      this.districtExplorer.renderParentFeature(areaNode, {
        cursor: 'default',
        bubble: true,
        strokeColor: '#7AF6FF', //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 2, //线宽
        fillColor: areaNode.getSubFeatures().length ? null : '#0770FF', //填充色
        fillOpacity: areaNode.getSubFeatures().length ? 0.1 : 0.4, //填充透明度
      });
    },

    /**
     * 切换区域后刷新显示内容
     */
    refreshAreaNode(areaNode) {
      this.districtExplorer.setHoverFeature(null);

      this.renderAreaPolygons(areaNode);
    },

    /**
     * 切换区域
     */
    switch2AreaNode(adcode, callback) {
      if (
        this.currentAreaNode &&
        '' + this.currentAreaNode.getAdcode() === '' + adcode
      ) {
        return;
      }

      this.loadAreaNode(adcode, (error, areaNode) => {
        if (error) {
          if (callback) {
            callback(error);
          }
          return;
        }

        this.currentAreaNode = areaNode;

        //设置当前使用的定位用节点
        this.districtExplorer.setAreaNodesForLocating([this.currentAreaNode]);

        this.refreshAreaNode(areaNode);

        if (callback) {
          callback(null, areaNode);
        }
      });
    },

    /**
     * 加载区域
     */
    loadAreaNode(adcode, callback) {
      this.districtExplorer.loadAreaNode(adcode, (error, areaNode) => {
        // console.log('areaNode:', areaNode);
        if (error) {
          if (callback) {
            callback(error);
          }
          console.error(error);
          return;
        }
        if (callback) {
          callback(null, areaNode);
        }
      });
    },
    /**
     * 搜索行政区
     * @param {String} name    行政区名称
     * @param {String} level   行政区级别 "country" "province" "city" "district"
     */
    districtSearch(name, level, subdistrict) {
      return new Promise((resolve, reject) => {
        var districtSearch = new window.AMap.DistrictSearch({
          level, // 关键字对应的行政区级别，country表示国家
          subdistrict, // 显示下级行政区级数，1表示返回下一级行政区
        });

        // 搜索所有省/直辖市信息
        districtSearch.search(name, (status, result) => {
          // 查询成功时，result即为对应的行政区信息
          if (result.info === 'OK') {
            resolve(result);
          } else {
            reject(result);
          }
        });
      });
    },

    // ====================== 业务逻辑开始 =======================

    /**
     * 地图选中级别变更
     * @param {String} name  区域名称
     * @param {String} code  区域编码
     * @param {String} level 区域级别 "country" "province" "city" "district"
     */
    levelChange(name, code, level) {
      // 移除原有marker
      this.markerList.forEach((item) => {
        item.remove();
      });
      this.markerList = [];

      // 保存当前地图级别
      this.level = level;
      this.districtArr[
        ['country', 'province', 'city', 'district'].findIndex(
          (el) => el === level
        )
      ] = {
        name,
        code,
        level,
      };

      switch (level) {
        case 'country':
          this.handleCountry(name, code);
          break;
        case 'province':
          this.handleProvince(name, code);
          break;
        case 'city':
          this.handleCity(name, code);
          break;
        case 'district':
          this.handleDistrict(name, code);
          break;
      }
    },
    /**
     * 处理国家级别
     * @param {String} name  区域名称
     * @param {String} code  区域编码
     */
    handleCountry(name, code) {
      // 获取门店省份分布数据
      this.getProvinceData().then((res) => {
        res.forEach((el) => {
          el.number = el.companyNum;
        });
        // console.log(res);
        this.provinceData = res;
        this.switch2AreaNode(code, () => {
          // this.map.setCenter([113.486779, 22.890161]);
          // this.map.setZoom(7.5);
        });

        this.renderMarker(res);
      });
    },
    getProvinceData() {
      return getProvinceCompanyNum({ id: this.companyId });
    },
    /**
     * 处理省级别
     * @param {String} name  区域名称
     * @param {String} code  区域编码
     */
    handleProvince(name, code) {
      // 获取门店城市分布数据
      this.getCityData(code).then((res) => {
        res.forEach((el) => {
          el.number = el.companyNum;
        });
        // console.log(res);
        this.cityData = res;

        this.switch2AreaNode(code);

        this.renderMarker(res);
        // setTimeout(() => {
        //   var a = this.map.getZoom();
        //   console.log(a);
        //   // var a = this.map.getCenter();
        //   // console.log(a);
        //   // var tmapBounds = this.map.getBounds();
        //   // console.log(tmapBounds);
        // }, 3e3);
      });
    },
    getCityData(code) {
      return getCityCompanyNum({
        companyId: this.companyId,
        // code
      });
    },
    /**
     * 处理城市级别
     * @param {String} name  区域名称
     * @param {String} code  区域编码
     */
    handleCity(name, code) {
      // 获取当前城市门店数据
      this.getStoreDataByCityName(name).then((res) => {
        // console.log(res);
        this.convertFrom(res || [], code);
      });
    },
    /**
     * 获取城市门店数据
     */
    getStoreDataByCityName(name) {
      return getCompanyCityDistribution({
        companyId: this.companyId,
        statisticsPeriod: new Date().Format('yyyy/MM'),
        // areaName: name,
      });
    },
    /**
     * 处理区县级别
     * @param {String} name  区域名称
     * @param {String} code  区域编码
     */
    handleDistrict(name, code) {
      getDistrictCompanyInfo({
        //  code,
        companyId: this.companyId,
      }).then((res) => {
        this.convertFrom(res || [], code);
      });
    },
    /**
     * 坐标转换
     */
    convertFrom(res, code) {
      // 坐标转换
      let path = res.map((item) => [
        item.positionLongitude,
        item.positionLatitude,
      ]);
      window.AMap.convertFrom(path, 'baidu', (status, result) => {
        if (result.info === 'ok') {
          var path2 = result.locations;
          // console.log(path2);
          // debugger;
          res.forEach((item, index) => {
            item.positionLongitude = path2[index].lng;
            item.positionLatitude = path2[index].lat;
          });

          this.storeData = res;

          this.renderStoreMarker(res);
        }
        this.switch2AreaNode(code);
      });
    },
    // 渲染省市marker
    renderMarker(list) {
      var markerList = [];
      list.forEach((item) => {
        // console.log('item:', item);
        // console.log('this.centerMap:', this.centerMap);
        // console.log(this.centerMap.get(item.code));
        // background: rgba(0,0,0,0.2);
        var dom = $(`<div>
                        <img src="${require('./images/AMap/icon_marker.png')}" width="24px" height="24px" style="transform: translate(-50%,-50%);" />
                        <p style="
                                  width: max-content;
                                  padding: 4px 6px;
                                  border-radius: 5px;

                                  transform: translate(-50%,-60%);
                                  color:#FFDD3D;
                                  line-height: 14px;
                                  letter-spacing: 1px;
                                  font-size: 14px;
                                  font-family: PangMenZhengDao;">
                          <span>${item.name}</span>
                          <span>${item.number}</span>
                        </p>
                     </div>`);
        var marker = new window.AMap.Marker({
          position: this.centerMap.get(item.code),
          map: this.map,
          content: dom[0],
          bubble: true,
          // position: new window.AMap.LngLat(lng: this.centerMap.get(item.code)[0], lat: this.centerMap.get(item.code)[1], ),
          // title: item.name,
          // offset: ['-50%', '-50%'],
          // offset: [-25, -24],
          // icon: require('./images/AMap/icon_marker.png'),
          // label: {
          //   content: item.number,
          //   offset: [0, 20],
          //   direction: 'bottom',
          // },
        });
        markerList.push(marker);
      });
      // 全局存储
      this.markerList = markerList;
    },
    // 渲染门店marker
    renderStoreMarker(list) {
      var markerList = [];
      list.forEach((item) => {
        let target = item.totalTarget;
        if (target === 'NaN') {
          target = '';
        }

        // 添加千分符
        target = toThousands(target);
        var totalTurnover = toThousands(item.totalTurnover);

        // background: rgba(0,0,0,0.2);
        var dom = $(`<div class="store-marker">
                      <img class="store-marker-icon" src="${
                        item.totalTurnoverWarning
                          ? require('./images/AMap/icon_marker_error.png')
                          : require('./images/AMap/icon_marker.png')
                      }" width="24px" height="24px" style="transform: translate(-50%,-50%);" />
                      <div class="hidden-box">
                        <div class="tip">当前营业额：${totalTurnover}（进度${
          item.totalPercentageComplete
        }%） <br>目标营业额：${target} <br>毛利率：${
          item.totalGrossProfitRate
        }%</div>
                        <p class="store-marker-name">
                          <span>${item.companyName}</span>
                        </p>
                      </div>
                    </div>`);
        var marker = new window.AMap.Marker({
          position: [item.positionLongitude, item.positionLatitude],
          map: this.map,
          content: dom[0],
          bubble: true,
        });

        marker.on('mouseover', () => {
          marker.setzIndex(this.lastZIndex++);
        });
        // marker.on('mouseout', () => {
        //   marker.setzIndex(12);
        // });

        markerList.push(marker);
      });
      // 全局存储
      this.markerList = markerList;
    },

    // 刷新数据
    refresh() {
      console.log('刷新数据');
      var i;
      switch (this.level) {
        case 'country':
          i = 0;
          break;
        case 'province':
          i = 1;
          break;
        case 'city':
          i = 2;
          break;
        case 'district':
          i = 3;
          break;
      }
      let obj = this.districtArr[i];
      this.levelChange(obj.name, obj.code, obj.level);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-box {
  width: 100%;
  height: 100%;
  padding: 8px;
  // box-shadow: 0px 0px 8px 8px rgba(0, 10, 56, 0.9);
  position: relative;
  .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* -webkit-filter: blur(5px); */
    /* filter: blur(4px); */
    /* background: #000a39; */
    // background-color: hsl(189.96deg 100% 48.43% / 62%);
    box-shadow: inset 0px 0px 5px 5px #071a4a;
  }
}

#AMapContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  box-shadow: 0px 0px 4px 8px rgb(11, 23, 58, 0.8);
}
</style>

<style>
.tipMarker {
  color: #555;
  background-color: rgba(255, 254, 239, 0.8);
  border: 1px solid #7e7e7e;
  padding: 2px 6px;
  font-size: 12px;
  white-space: nowrap;
  display: inline-block;
}

.tipMarker:before,
.tipMarker:after {
  content: '';
  display: block;
  position: absolute;
  margin: auto;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 5px 5px;
}

.tipMarker.top {
  transform: translate(-50%, -110%);
}

.tipMarker.top:before,
.tipMarker.top:after {
  bottom: -9px;
  left: 0;
  right: 0;
  border-top-color: rgba(255, 254, 239, 0.8);
}

.tipMarker.top:before {
  bottom: -10px;
  border-top-color: #7e7e7e;
}

.store-marker {
  width: 0;
}
.store-marker .hidden-box {
  position: relative;
  display: none;
  z-index: 999;
}
.store-marker .tip {
  position: absolute;
  top: -96px;
  width: max-content;
  padding: 4px 8px;
  border: 1px solid #7af6ff;
  border-radius: 4px;
  transform: translate(-50%, -20px);
  box-sizing: border-box;
  color: #7af6ff;
  font-size: 14px;
  background: url(./images/img_side.png) no-repeat;
  line-height: 20px;
  /* #F22727  */
  /* #7AF6FF */
}
.store-marker-name {
  width: max-content;
  padding: 4px 6px;
  border-radius: 5px;
  transform: translate(-50%, -60%);
  color: #ffdd3d;
  line-height: 14px;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: PangMenZhengDao;
}
.store-marker-icon:hover + .hidden-box {
  display: block;
}
.amap-logo {
  display: none !important;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
</style>
