<template>
  <div>
    <el-dialog
      title="完工质检"
      width="60%"
      :visible="visibility"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="quality-testing_box">
        <div class="bill-info">
          <div class="row flex-x-between">
            <div class="col flex">
              <div class="label">接车单：</div>
              <div class="value">
                <span
                  class="blue pointer"
                  @click="goCarBillDetail(detailData.billId)"
                  >{{ detailData.billCode }}</span
                >
                (<span
                  v-if="detailData.isOthersSelfPayState != undefined"
                  class="color999"
                >
                  {{
                    detailData.isOthersSelfPayState
                      | dict('isOthersSelfPayState')
                  }}</span
                >)
              </div>
            </div>
            <div class="col flex">
              <div class="label">预交车时间：</div>
              <div class="value">
                <div v-if="detailData.undetermined">待定</div>
                <div v-else class="flex">
                  <p>{{ detailData.planFinishTime }}</p>
                  <p class="color999" v-if="detailData.waitInStore">
                    ({{ detailData.waitInStore ? '在店等' : '' }})
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="row flex-x-between">
            <div class="col flex">
              <div class="label">车牌号：</div>
              <div class="value">
                <span>{{ detailData.carNumber }}</span>
                <span v-if="detailData.carType !== undefined">
                  ({{ detailData.vehicleType }})
                </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">车型/车架号：</div>
              <div class="value">
                <span>{{ detailData.carModel }}</span>
                <p style="margin-top: 5px" v-if="detailData.uniqueId">
                  VIN: {{ detailData.uniqueId }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="title">本次检车问题及所报项目</div>
        <div class="list">
          <div class="item">
            <div class="flex-y-center">
              <div class="cell" style="width: 120px; text-align: center">
                <span>1.前保险杠</span>
                <br />
                <span class="blue pointer">检车图片(3)</span>
              </div>
              <div style="width: 260px" class="cell">
                <p class="p">
                  问题详情说明xxxxxxxxxxxxxxxxxxxx， 最多可三行显示，超长 ...
                  标识，鼠标悬触冒泡全显
                </p>
              </div>
              <div class="cell">
                <div class="flex-col flex-y-center">
                  <el-switch :value="true"></el-switch>
                  <br />
                  <span>已修复</span>
                </div>
                <el-input type="textarea" :rows="4" placeholder="请输入内容">
                </el-input>
                <upload-file-list></upload-file-list>
              </div>
            </div>
            <el-table border :data="qualityList" style="width: 100%">
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column label="报价项目" width="180">
                <template #default="{ row }">
                  <span>{{ row.itemName }}</span>
                  <span>编码：{{ row.itemCode }}</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="tags" label="内部标签">
              </el-table-column>
              <el-table-column width="110" label="施工技师">
                <template #default="{ row }">
                  <div>{{ row.uname }}</div>
                  <span>({{ row.dept }})</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="rework" label="返工次数">
              </el-table-column>
              <el-table-column width="110" prop="finishDate" label="完工时间">
              </el-table-column>
              <el-table-column header-align="center" label="历史返工详情">
                <el-table-column width="110" label="质检时间" prop="date">
                </el-table-column>
                <el-table-column width="110" label="质检员" prop="employee">
                </el-table-column>
                <el-table-column label="返工原因" prop="reason">
                </el-table-column>
              </el-table-column>
            </el-table>
            <div class="split-row"></div>
          </div>
          <div class="item">
            <div class="flex-y-center">
              <div style="width: 120px" class="cell">2.空调不制冷</div>
              <div style="width: 260px" class="cell">
                <p class="p">
                  问题详情说明xxxxxxxxxxxxxxxxxxxx， 最多可三行显示，超长 ...
                  标识，鼠标悬触冒泡全显
                </p>
                <span class="flex w100 blue" style="margin-left: 5px"
                  >检车图片</span
                >
              </div>
              <div style="width: 260px" class="cell">
                <p class="p">
                  问题详情说明xxxxxxxxxxxxxxxxxxxx， 最多可三行显示，超长 ...
                  标识，鼠标悬触冒泡全显
                </p>
                <span class="flex w100 blue" style="margin-left: 5px"
                  >质检图片</span
                >
              </div>
              <div class="cell" style="text-align: center">
                <el-switch :value="true"></el-switch>
                <br />
                <span>已修复</span>
              </div>
            </div>
            <el-table border :data="qualityList" style="width: 100%">
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column label="报价项目" width="180">
                <template #default="{ row }">
                  <span>{{ row.itemName }}</span>
                  <span>编码：{{ row.itemCode }}</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="tags" label="内部标签">
              </el-table-column>
              <el-table-column width="110" label="施工技师">
                <template #default="{ row }">
                  <div>{{ row.uname }}</div>
                  <span>({{ row.dept }})</span>
                </template>
              </el-table-column>
              <el-table-column width="110" prop="rework" label="返工次数">
              </el-table-column>
              <el-table-column width="110" prop="finishDate" label="完工时间">
              </el-table-column>
              <el-table-column header-align="center" label="历史返工详情">
                <el-table-column width="110" label="质检时间" prop="date">
                </el-table-column>
                <el-table-column width="110" label="质检员" prop="employee">
                </el-table-column>
                <el-table-column label="返工原因" prop="reason">
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div> -->
        <!-- bug764隐藏 -->
        <!-- <div class="title">非检车问题所报项目</div> -->
        <el-table
          border
          :data="taskList"
          style="width: 100%"
          :span-method="spanMethod"
        >
          <el-table-column label="序号" width="80" prop="index">
          </el-table-column>
          <el-table-column label="报价项目" width="180">
            <template #default="{ row }">
              <p>{{ row.serviceItemName }}</p>
              <span v-if="row.serviceItemCode"
                >编码：{{ row.serviceItemCode }}</span
              >
            </template>
          </el-table-column>
          <el-table-column width="110" prop="tags" label="内部标签">
          </el-table-column>
          <el-table-column width="110" label="施工技师">
            <template #default="{ row }">
              <div>{{ row.technicianNames }}</div>
              <!-- <span>({{ row.dept }})</span> -->
            </template>
          </el-table-column>
          <el-table-column width="110" prop="reworkTimes" label="返工次数">
          </el-table-column>
          <el-table-column width="160" prop="updateTime" label="完工时间">
          </el-table-column>
          <el-table-column width="160">
            <template slot="header" slot-scope="scope">
              <!-- 不使用scope将无法改变el-switch的value -->
              <div :insignificance="scope.isRework">
                返工<el-switch
                  class="el-icon--right"
                  v-model="isRework"
                  @change="changeIsRework"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </div>
            </template>
            <template #default="{ row }">
              {{ row.reworkOrNot ? '返工' : '完工' }}
              <el-switch
                :active-value="1"
                :inactive-value="0"
                v-model="row.reworkOrNot"
                @change="changeItemReworkStatus($event, row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column width="300" label="返工原因(可上传5张图片)">
            <template #default="{ row }">
              <div :key="key">
                <div v-if="row.reworkOrNot">
                  <el-input
                    type="textarea"
                    rows="2"
                    v-model="row.reworkReason"
                    placeholder="请输入"
                  ></el-input>
                  <div style="margin: 10px 0 0 -10px">
                    <upload-file-list
                      v-model="row.reworkPics"
                      :max="5"
                    ></upload-file-list>
                  </div>
                </div>
                <div v-else class="flex">
                  <upload-file-list
                    disabled
                    :value="row.reworkPics"
                    v-show="row.reworkPics"
                  ></upload-file-list>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="历史返工详情">
            <el-table-column
              width="110"
              label="质检时间"
              prop="qualityInspectionTime"
            >
            </el-table-column>
            <el-table-column
              width="110"
              label="质检员"
              prop="qualityInspectionUserName"
            >
            </el-table-column>
            <el-table-column label="返工原因" prop="reworkReason">
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <template slot="footer">
        <div class="flex-x-end">
          <el-button size="small" @click="handleClose">取 消</el-button>
          <el-button size="small">保 存</el-button>
          <div style="margin-left: 10px">
            <el-button
              v-if="buttonName === '车间完工'"
              v-auth="456"
              size="small"
              type="primary"
              @click="submit"
              >{{ buttonName }}</el-button
            >
            <el-button
              v-else
              v-auth="455"
              size="small"
              type="primary"
              @click="submit"
              >{{ buttonName }}</el-button
            >
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  getForDispatchDetailsById,
  // submitForDispatch,
  // constructionReassignment,
  completionQualityInspection,
} from '@/api/carBill';
import uploadFileList from '@/components/uploadFileList';
export default {
  name: 'qualityTestingDialog',
  components: { uploadFileList },
  props: {
    visibility: { type: Boolean, default: false },
    id: {
      type: [Number, String],
    },
    dispatchType: {
      type: [Number, String],
    },
  },
  data() {
    return {
      detailData: {},

      // 列表
      taskList: [],

      // 正在编辑下标
      editIndex: 0,

      // 选择技师弹窗可见性
      selectTechniciansDialogVisibility: false,

      cellKey: 0,

      // 质检表格数据
      qualityList: [],

      // 是否返工
      isRework: false,

      key: 0,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
      userId: (state) => state.user.userInfo?.userId,
    }),
    buttonName() {
      return this.taskList.every((item) => !item.reworkOrNot)
        ? '车间完工'
        : '提交返工';
    },
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * getData()
     */
    async getData() {
      let res = await getForDispatchDetailsById({
        id: this.id,
        dispatchType: this.dispatchType,
      });

      let { taskBillServiceItems = [], ...data } = res;
      this.detailData = data;

      let rowIndex = 0;

      //给返回的数据重新组合
      taskBillServiceItems.forEach((item, index) => {
        if (item.taskBillReworkDetails?.length) {
          item.rowspan = item.taskBillReworkDetails.length;
          item.taskBillReworkDetails.forEach((el, index) => {
            if (index === 0) {
              el.index = ++rowIndex;
              el.isParent = true;
              el.rowspan = item.taskBillReworkDetails.length;
            } else {
              el.isChild = true;
            }
            this.taskList.push(
              Object.assign(this.$lodash.cloneDeep(el), {
                billServiceId: item.billServiceId,
                reworkTimes: item.reworkTimes,
                serviceItemName: item.serviceItemName,
                taskBillId: item.taskBillId,
                taskBillState: item.taskBillState,
                technicianNames: item.technicianNames,
                updateTime: item.updateTime,
                qualityInspectionTime: el.qualityInspectionTime,
                qualityInspectionUserId: el.qualityInspectionUserId,
                qualityInspectionUserName: el.qualityInspectionUserName,
                reworkReason: el.reworkReason,
                reworkPics: el.reworkPics,
              })
            );
          });
        } else {
          item.index = ++rowIndex;
          this.taskList.push(item);
        }
      });
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < 7) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },
    /**
     * 返工
     */
    changeIsRework(val) {
      this.taskList.forEach((item, index) => {
        item.reworkOrNot = val;
        item.reworkReason = '';
        item.reworkPics = '';
      });
    },
    /**
     * 修改项目返工状态
     */
    changeItemReworkStatus(val, row) {
      if (!val) {
        this.isRework = 0;
        row.reworkReason = '';
        row.reworkPics = '';
        if (row.isParent) {
          for (var i = 1; i < row.rowspan; i++) {
            this.taskList[i].reworkOrNot = 0;
            this.taskList[i].reworkReason = '';
            this.taskList[i].reworkPics = '';
          }
        }
      } else {
        if (row.isParent) {
          for (var j = 1; j < row.rowspan; j++) {
            this.taskList[j].reworkOrNot = 1;
            this.taskList[j].reworkReason = '';
            this.taskList[j].reworkPics = '';
          }
        }
      }
      this.key = Math.random();
    },

    /**
     * 提交
     */
    submit() {
      this.$confirm('是否确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let list = this.$lodash.cloneDeep(this.taskList);
          list.forEach((item) => {
            item.reworkPics = item.reworkPics
              ?.split(',')
              ?.map((pic) => pic?.split('/')?.at(-1))
              .join();
          });
          completionQualityInspection({
            dispatchBillId: this.id,
            dispatchCompletionQualityInspectionService: list,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success('操作成功！');
              this.$emit('finish');
              this.handleClose();
            }
          });
        })
        .catch(() => {});
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.handleClose();
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.quality-testing_box {
  height: 60vh;
  overflow: auto;
  // padding: 30px 0 60px;
  .bill-info {
    padding-bottom: 60px;
    .row {
      display: flex;
      .col {
        width: 50%;
        .label {
          margin-right: 10px;
        }
        // &:first-child {
        // }
      }
      & + .row {
        margin-top: 14px;
      }
    }
  }
  .title {
    margin: 14px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  .list {
    .item {
      border-left: 1px solid #eee;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-radius: 4px 4px 0 0;
      .cell {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        .p {
          line-height: 18px;
          // 三行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          word-break: break-all;
          word-wrap: break-word;
          text-overflow: ellipsis;
        }
        & + .cell {
          border-left: 1px solid #eee;
        }
      }
    }
    .split-row {
      height: 30px;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
    }
  }
}
</style>
