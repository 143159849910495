<template>
  <div>
    <el-drawer
      title="施工项目"
      :visible="visibility"
      size="50%"
      append-to-body
      :before-close="handleClose"
    >
      <div class="update-fix-items_box">
        <div class="bill-info">
          <div class="row flex-x-between">
            <div class="col flex">
              <div class="label">接车单：</div>
              <div class="value">
                <span
                  class="blue pointer"
                  @click="goCarBillDetail(detailData.billId)"
                  >{{ detailData.billCode }}</span
                >
                <span v-if="detailData.isOthersSelfPayState"> (他人自费) </span>
              </div>
            </div>
            <div class="col flex">
              <div class="label">预交车时间：</div>
              <div class="value">
                <span v-if="detailData.planFinishTime">{{
                  detailData.planFinishTime
                }}</span>
                <span v-if="detailData.waitInStore"> （在店等） </span>
                <span v-if="detailData.undetermined"> 待定 </span>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" class="row flex-x-between">
            <div class="col flex">
              <div class="label">车牌号：</div>
              <div class="value">
                <span>{{ detailData.carNumber }}</span>
                <span v-if="detailData.carType !== undefined">
                  ({{ detailData.vehicleType }})
                </span>
              </div>
            </div>
            <div class="col">
              <div class="label">
                车型/车架号：<span>{{ detailData.carModel }}</span>
              </div>
              s
              <div class="value" style="margin-top: 10px">
                <span> VIN: {{ detailData.uniqueId }} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <span>勾选施工中项目，操作：</span>
          <span
            v-auth="454"
            class="pointer orange"
            @click="verifyNotEmpty('workFinishedDialogVisible')"
            >项目完工</span
          >
          <span
            v-auth="453"
            class="pointer blue"
            @click="verifyNotEmpty('submitChangeDialogVisible')"
            >提交改派</span
          >
          <span
            v-auth="96"
            class="pointer second"
            @click="verifyNotEmpty('dispatchingDialogVisible')"
            >施工改派</span
          >
        </div>
        <el-table
          style="width: 100%; margin: 10px 0"
          border
          :data="taskList"
          :span-method="spanMethod"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column label="项目名称" width="200" prop="serviceItemName">
          </el-table-column>
          <el-table-column label="项目编码" width="120" prop="serviceItemCode">
          </el-table-column>
          <el-table-column label="内部标签" width="140" prop="tags">
          </el-table-column>
          <el-table-column label="施工技师">
            <template #default="{ row }">
              {{ row.technicianNames }}
            </template>
          </el-table-column>
          <el-table-column prop="reworkTimes" label="返工次数">
          </el-table-column>
          <el-table-column label="历史返工详情" header-align="center">
            <el-table-column
              width="140"
              label="质检时间"
              prop="qualityInspectionTime"
            ></el-table-column>
            <el-table-column
              width="120"
              label="质检员"
              prop="qualityInspectionUserName"
            ></el-table-column>
            <el-table-column
              width="180"
              label="返工原因"
              prop="reworkReason"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <p>{{ row.reworkReason }}</p>
                <el-popover placement="top" width="450" trigger="click">
                  <upload-file-list
                    v-model="row.reworkPics"
                    disabled
                  ></upload-file-list>
                  <span class="pointer blue" slot="reference"
                    >质检图片({{
                      $lodash
                        .get(row, 'reworkPics', '')
                        .split(',')
                        .filter((item) => item).length
                    }})</span
                  >
                </el-popover>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>

        <p class="color333">已完工或免施工项目</p>

        <el-table
          style="width: 100%; margin: 10px 0"
          border
          :data="finishedList"
          :span-method="spanMethod"
        >
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column label="项目名称" width="200" prop="serviceItemName">
          </el-table-column>
          <el-table-column label="项目编码" width="120" prop="serviceItemCode">
          </el-table-column>
          <el-table-column label="内部标签" width="140" prop="tags">
          </el-table-column>
          <el-table-column label="施工技师">
            <template #default="{ row }">
              {{ row.technicianNames }}
            </template>
          </el-table-column>
          <el-table-column prop="reworkTimes" label="返工次数">
          </el-table-column>
          <el-table-column label="历史返工详情" header-align="center">
            <el-table-column
              width="140"
              label="质检时间"
              prop="qualityInspectionTime"
            ></el-table-column>
            <el-table-column
              width="120"
              label="质检员"
              prop="qualityInspectionUserName"
            ></el-table-column>
            <el-table-column
              width="180"
              label="返工原因"
              prop="reworkReason"
              show-overflow-tooltip
            ></el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <!-- 施工改派 选择技师弹窗 -->
    <!-- <select-technicians-dialog
      v-if="selectTechniciansDialogVisibility"
      :visibility.sync="selectTechniciansDialogVisibility"
      @select="handleSelect"
    ></select-technicians-dialog> -->

    <!-- 确认完工弹窗 -->
    <el-dialog
      title="项目完工"
      :visible="workFinishedDialogVisible"
      width="35%"
      :close-on-click-modal="false"
      :before-close="
        () => {
          workFinishedDialogVisible = false;
        }
      "
      center
    >
      <div class="work-finished-dialog">
        <p>
          确定已选择的施工项目（数量 {{ selectedList.length }}）已完成施工？
        </p>
        <p>
          <i class="el-icon-warning orange"></i>
          注：如本车单需施工的项目已全部完工，“确定”成功后系统将自动提交质检，<br />且有【完工质检】通知推送给有接收通知权限的终检员
        </p>
      </div>
      <div slot="footer">
        <el-button size="small" @click="workFinishedDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmFinished"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 提交改派弹窗 -->
    <el-dialog
      title="提交改派"
      width="35%"
      :visible="submitChangeDialogVisible"
      :close-on-click-modal="false"
      :before-close="
        () => {
          submitChangeDialogVisible = false;
        }
      "
      center
    >
      <div class="work-finished-dialog">
        <p>
          确定要将已选择的施工项目（数量
          {{ selectedList.length }}）提交给车间主管，改派其他班组施工？
        </p>
        <p>
          <i class="el-icon-warning orange"></i>
          注：操作成功，有【提交施工】通知推送给有接收权限的员工
        </p>
      </div>
      <div slot="footer">
        <el-button size="small" @click="submitChangeDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmChange"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 施工改派弹窗 -->
    <dispatching-dialog
      v-if="dispatchingDialogVisible"
      :visibility.sync="dispatchingDialogVisible"
      :id="id"
      :dispatchType="dispatchType"
      :billData="detailData"
      :list="selectedList"
      @finish="handleClose"
    ></dispatching-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  getForDispatchDetailsById,
  submitForDispatch,
  submitCompletion,
  submitReassignment,
  constructionReassignment,
} from '@/api/carBill';
import DispatchingDialog from '@/components/businessComponent/dispatchingDialog.vue';
import uploadFileList from '@/components/uploadFileList';
export default {
  name: 'updateFixItemDrawer',
  components: {
    DispatchingDialog,
    uploadFileList,
  },
  props: {
    visibility: { type: Boolean, default: false },
    id: {
      type: [Number, String],
    },
    dispatchType: {
      type: [Number, String],
    },
  },
  data() {
    return {
      detailData: {},

      // 可操作列表
      taskList: [],

      // 已完工列表
      finishedList: [],

      // 选中列表
      selectedList: [],

      // 项目完工提示内容
      workFinishedDialogVisible: false,

      // 提交改派提示内容
      submitChangeDialogVisible: false,

      // 施工改派弹窗
      dispatchingDialogVisible: false,

      cellKey: 0,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.companyId,
      userId: (state) => state.user.userInfo?.userId,
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * getData()
     */
    async getData() {
      let res = await getForDispatchDetailsById({
        id: this.id,
        dispatchType: this.dispatchType,
      });
      let { taskBillServiceItems = [], ...data } = res;

      let rowIndex = 0;

      //给返回的数据重新组合
      var arr = [];
      taskBillServiceItems.forEach((item, index) => {
        if (item.taskBillReworkDetails?.length) {
          item.rowspan = item.taskBillReworkDetails.length;
          item.taskBillReworkDetails.forEach((el, index) => {
            if (index === 0) {
              el.index = ++rowIndex;
              el.isParent = true;
              el.rowspan = item.taskBillReworkDetails.length;
            } else {
              el.isChild = true;
            }
            arr.push(
              Object.assign(this.$lodash.cloneDeep(el), {
                billServiceId: item.billServiceId,
                reworkTimes: item.reworkTimes,
                serviceItemName: item.serviceItemName,
                taskBillId: item.taskBillId,
                taskBillState: item.taskBillState,
                technicianNames: item.technicianNames,
                updateTime: item.updateTime,
                qualityInspectionTime: el.qualityInspectionTime,
                qualityInspectionUserId: el.qualityInspectionUserId,
                qualityInspectionUserName: el.qualityInspectionUserName,
                reworkReason: el.reworkReason,
                reworkPics: el.reworkPics,
              })
            );
          });
        } else {
          item.index = ++rowIndex;
          arr.push(item);
        }
      });
      this.taskList = arr.filter((item) => [1, 2].includes(item.taskBillState));
      this.finishedList = arr.filter((item) =>
        [3, 4].includes(item.taskBillState)
      );
      this.detailData = data;
    },
    /**
     * 选择事件
     */
    selectionChange(list) {
      this.selectedList = list;
    },
    /**
     * 验证是否选择施工项目,展示对应弹窗
     */
    verifyNotEmpty(key) {
      if (!this.selectedList.length) {
        this.$message.warning('请先选择施工项目！');
      } else {
        this[key] = true;
      }
    },
    /**
     * 确认完工
     */
    confirmFinished() {
      let body = {
        dispatchBillId: this.id,
        taskBillId: this.selectedList.map((item) => item.taskBillId),
      };
      submitCompletion(body).then((res) => {
        if (res.code == 200) {
          this.$message.success('操作成功！');
          this.$emit('finish');
          this.handleClose();
        }
      });
    },
    /**
     * 确认改派
     */
    confirmChange() {
      let body = {
        dispatchBillId: this.id,
        taskBillId: this.selectedList.map((item) => item.taskBillId),
      };
      submitReassignment(body).then((res) => {
        if (res.code == 200) {
          this.$message.success('操作成功！');
          this.$emit('finish');
          this.handleClose();
        }
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.handleClose();
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 2, 3, 4, 5, 6].includes(columnIndex)) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.update-fix-items_box {
  padding: 0 30px 0 30px;
  .bill-info {
    .row {
      color: #333;
      .col {
        width: 50%;
        .label {
          flex-shrink: 0;
        }
        // .value {
        // }
      }
    }
  }
}
.btns {
  margin: 60px 0 0 0;
  font-size: 14px;
  color: #333;
  .pointer {
    &:nth-child(2) {
      margin-left: 10px;
    }
    margin-left: 30px;
  }
}
.work-finished-dialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  > p:first-child {
    margin: 14px 0;
    font-size: 16px;
    color: #333;
  }
  > p:nth-child(2) {
    margin-bottom: 30px;
    line-height: 20px;
    font-size: 14px;
    color: #999;
  }
}
</style>
