<template>
  <el-dialog
    :visible="visibility"
    width="45%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <template slot="title">
      <div class="flex-y-center custom-dialog-title">
        <h2>选择技师</h2>
        <span>（可双击直接单选，或单击批量选择再确定选择）</span>
      </div>
    </template>
    <div class="appoint-dialog">
      <slot name="header">
        <!-- <div style="margin: 15px 0 30px">
          <el-switch
            v-model="isConstructionFree"
            @change="handleChange"
          ></el-switch>
          外包项目非本店施工，或其他免施工项目，可设置“免施工”
        </div> -->
        <p>
          可选在职岗位含“技师”的员工，施工技师只能选择(可多选)同一个班组内的技师
        </p>
        <!-- <p>
          <i class="el-icon-warning orange"></i>
          注：操作成功，有【检车派检】通知推送给项目的施工技师
        </p> -->
      </slot>

      <div class="content-box flex">
        <div class="artisan-box">
          <!-- <div class="title">
            <span class="label">已选择班组：</span>
            <span class="value">机修部门 / 机修一组</span>
          </div> -->
          <div class="artisan-list">
            <div
              v-for="artisan in technicianList"
              :key="artisan.userId"
              :class="{
                artisan: true,
                active: artisan.checked,
                disabled: isConstructionFree,
              }"
              @click="handleArtisanChecked(artisan)"
              @dblclick="confirmArtisan(artisan)"
            >
              {{ artisan.realName }}
            </div>
          </div>
        </div>
        <div class="dept-tree">
          <div class="class-box">
            <p class="title">部门</p>
            <el-tree
              class="custom-tree"
              :data="treeData"
              :props="treeProp"
              highlight-current
              node-key="categoryId"
              @node-click="onNodeClick"
            >
            </el-tree>
          </div>
        </div>
      </div>

      <div>
        <span>已选择：</span>
        <span class="primary">{{
          checkedArtisans.map((item) => item.realName).join('、')
        }}</span>
        <!-- <span v-if="isConstructionFree">免施工</span> -->
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="selectArtisan"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  getTechniciansList,
  getTechniciansDepartmentTree,
} from '@/api/employee';
import { mapState } from 'vuex';
export default {
  name: 'selectTechniciansDialog',
  props: {
    visibility: { type: Boolean, default: false },
  },
  data() {
    return {
      // 是否外包 或 免施工
      isConstructionFree: false,

      // 技师列表
      technicianList: [],

      // 选中技工
      checkedArtisans: [],
      //部门
      treeData: [],
      treeProp: {
        children: 'subDepartments',
        label: 'name',
      },
    };
  },
  created() {
    getTechniciansList({ companyId: this.companyId, departmentId: '' }).then(
      (res) => {
        this.technicianList = res;
      }
    );
    getTechniciansDepartmentTree().then((res) => {
      // 添加全部选项
      res.unshift({
        categoryId: 0,
        name: '全部',
      });
      this.treeData = res;
    });
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  methods: {
    /**
     * 派检选择操作
     */
    handleArtisanChecked(artisan) {
      if (this.isConstructionFree) return;
      this.$set(artisan, 'checked', !artisan.checked);
      if (artisan.checked) {
        this.checkedArtisans.push(artisan);
      } else {
        const index = this.checkedArtisans.findIndex(
          (item) => item.userId == artisan.userId
        );
        if (index !== undefined) {
          this.checkedArtisans.splice(index, 1);
        }
      }
    },
    /**
     * 确定派检
     */
    confirmArtisan(artisan) {
      if (this.isConstructionFree) return;
      if (artisan) {
        this.$set(artisan, 'checked', true);
        if (
          !this.checkedArtisans.find((item) => item.userId == artisan.userId)
        ) {
          this.checkedArtisans.push(artisan);
        }
      }
      this.selectArtisan();
    },
    /**
     * 选择技师弹窗确认事件
     */
    selectArtisan() {
      if (this.isConstructionFree) {
        this.$emit('select', false);
      } else if (this.technicianList.length) {
        this.$emit('select', this.checkedArtisans);
      }
      this.handleClose();
    },
    /**
     * 免施工change事件
     */
    handleChange(val) {
      if (val) {
        this.technicianList.forEach((item) => {
          item.checked = false;
        });
        this.checkedArtisans = [];
      }
    },
    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },

    /**
     * 点击节点
     */
    onNodeClick(obj, node, origin) {
      if (obj.categoryId) {
        if (obj.parentId) {
          this.firstCategoryId = obj.parentId;
          this.secondCategoryId = obj.categoryId;
        } else {
          this.firstCategoryId = obj.categoryId;
          this.secondCategoryId = 0;
        }
      } else {
        this.firstCategoryId = '';
        this.secondCategoryId = 0;
      }

      getTechniciansList({
        companyId: this.companyId,
        departmentId: obj.departmentId,
      }).then((res) => {
        res.forEach((item) => {
          if (this.checkedArtisans.find((el) => el.userId === item.userId)) {
            item.checked = true;
          }
        });
        this.technicianList = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog-title {
  h2 {
    line-height: 28px;
    color: #333;
    font-size: 20px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
    color: #999;
  }
}
.appoint-dialog {
  padding-top: 10px;
  > p {
    color: #999;
    & + p {
      margin-top: 10px;
    }
  }
  .content-box {
    min-height: 300px;
  }
  .artisan-box {
    width: 65%;
    margin: 15px 0;
    padding: 30px;
    border: 1px solid #eee;
    border-radius: 4px;
    .title {
      .label {
        color: #333;
        font-size: 16px;
      }
      .value {
        font-size: 14px;
        color: #666;
      }
    }
    .lv {
      margin: 10px 0;
      .lv-title {
        flex-shrink: 0;
        width: 80px;
        text-align: right;
        font-size: 16px;
        color: #333;
      }
    }
    .artisan-list {
      margin-left: 5px;
      display: flex;
      flex-wrap: wrap;
      .artisan {
        margin-top: 10px;
        margin-right: 15px;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid #eee;
        cursor: pointer;
        user-select: none;
        &.active {
          background: $bg;
          color: $primary;
        }
      }
      .disabled {
        cursor: no-drop;
        background: #f5f5f5;
      }
    }
  }
  .dept-tree {
    padding: 15px;
    width: 30%;
  }
}
//部门tree样式
.class-box {
  width: 100%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}

.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
}
</style>
