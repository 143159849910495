<template>
  <base-container :defaultPadding="false">
    <el-table
      :data="tableData"
      border
      class="custom-table"
      style="height: calc(63vh)"
    >
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column width="180" label="修改时间" prop="lastUpdated">
      </el-table-column>
      <el-table-column width="180" label="修改人" prop="updater">
      </el-table-column>
      <el-table-column width="180" label="车牌" prop="carNumber">
      </el-table-column>
      <el-table-column width="180" label="车架号" prop="uniqueId">
      </el-table-column>
      <el-table-column width="180" label="电机号/发动机号" prop="engineNumber">
      </el-table-column>
      <el-table-column width="180" label="车型" prop="carModel">
      </el-table-column>
      <el-table-column width="180" label="车辆所有人" prop="ownerName">
      </el-table-column>
      <el-table-column width="180" label="动力类型" prop="fuelType">
      </el-table-column>
      <el-table-column
        width="180"
        label="使用性质"
        prop="annualSurveyCarTypeStr"
      >
      </el-table-column>
      <el-table-column width="180" label="注册时间" prop="registerDate">
      </el-table-column>
      <el-table-column width="180" label="真实续航" prop="trueEndurance">
      </el-table-column>
      <el-table-column width="180" label="新车里程" prop="newVehicleMileage">
      </el-table-column>
      <el-table-column width="180" label="在租状态" prop="underLeaseStateStr">
      </el-table-column>
      <el-table-column width="180" label="备注" prop="remark">
      </el-table-column>
      <el-table-column width="180" label="变更备注" prop="changeRemark">
      </el-table-column>
      <el-table-column width="180" label="客户" prop="clientName">
      </el-table-column>
      <el-table-column width="180" label="车辆来源" prop="vehicleSource">
      </el-table-column>
      <el-table-column width="180" label="发车模式" prop="departureMode">
      </el-table-column>
    </el-table>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
//接口
import { getChangeLogList } from '@/api/customer/car';
import { hasAuth } from '@/utils/permissions';
export default {
  name: 'carChangeLogRecords',
  components: { pagination },
  props: {
    carId: { type: [Number, String], default: '' },
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      tableData: [],
    };
  },
  computed: {},
  created() {
    this.getDataList();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getDataList();
    },
    /**
     * 加载数据
     */
    getDataList() {
      var res = hasAuth(667);
      if (!res) {
        return;
      }
      var carId = {
        carId: this.carId,
      };
      var data = Object.assign(carId, this.pageOption);
      getChangeLogList(data).then((res) => {
        this.total = res.total || 0;
        this.tableData = res.records || [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  width: 100%;
  display: inline-flex;
}
.custom-tabs {
  ::v-deep .el-tabs__header {
    margin: 0px 0px 0px !important;
    border-bottom: none;
    .el-tabs__nav {
      // border-bottom: 1px solid #e4e7ed;
    }
    .el-tabs__item {
      height: auto;
      border-bottom: 1px solid #e4e7ed;
      &.is-active {
        background: rgba(51, 171, 121, 0.1);
      }
    }
  }
}
</style>
