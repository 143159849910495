import http from '@/service/http';
import store from '@/store';
const defaultListOption = {
  current: 1,
  size: 10,
};
// 获取客户档案列表
export function getCustomerList(data = defaultListOption) {
  return http.post('/admin/client/list', data);
}

// 查询客户档案详情
export function getCustomerDetail(id) {
  return http.post('/admin/client/info', { id });
}

const addList = {};
// 新增客户信息
export function insertCustomer(data = addList) {
  return http.post('/admin/client/save', data, { onlyReturnData: false });
}

const updateList = {};
// 修改客户信息
export function updateCustomer(data = updateList) {
  return http.post('/admin/client/update', data, { onlyReturnData: false });
}

// 导出客户列表
export const exportCusterListUrl = '/admin/client/export';

// 删除客户
export function delCuster(data) {
  return http.post('/admin/client/deleteById', data, { onlyReturnData: false });
}

// 检索用户列表
export function searchClient(
  keyWord = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/client/getClientBySearch',
    {
      clientMsg: keyWord,
      size,
      current,
      companyId: store?.state?.user?.userInfo?.companyId,
    },
    config
  );
}

// 搜索来源客户
export function searchSourceClient(
  keyWord = '',
  size = 10,
  current = 1,
  config = { noShowWaiting: true }
) {
  return http.post(
    '/admin/client/getSourceClientBySearch',
    {
      clientSourceMsg: keyWord,
      size,
      current,
      companyId: store?.state?.user?.userInfo?.companyId,
    },
    config
  );
}

//客户消费记录
export function clientGetClientConsumptionRecord(data) {
  return http.post('/admin/client/getClientConsumptionRecord', data);
}
//客户消费记录名下车辆
export function clientGetClientCarsByClientId(data) {
  return http.post('/admin/client/getClientCarsByClientId', data);
}

//客户档案-消费记录-接车单详情抽屉-车单账款情况
export function clientPersonalAccounts(id) {
  return http.post(
    '/admin/client/personalAccounts',
    { id },
    {
      onlyReturnData: false,
    }
  );
}

/**
 * 查询客户指令
 * @param {number} id 客户id
 */
export function getClientCommand(id) {
  return http.post('/admin/client/queryClientInstruct', { id });
}

/**
 * 更新客户指令
 * @param {object} data
 */
export function updateClientCommand(data) {
  return http.post('/admin/client/updateClientInstruct', data);
}

//查询客户业务信息
export function getClientBusinessMessage(id) {
  return http.post('/admin/client/getClientBusinessMessage', { id });
}
//查询客户车辆区间信息
export function getClientCarMessageBySection(id) {
  return http.post('/admin/client/getClientCarMessageBySection', { id });
}
//查询客户车辆品牌信息
export function getCarBrandByClientId(id) {
  return http.post('/admin/client/getCarBrandByClientId', { id });
}
//开单、客户协议选择客户中的弹窗要有翻页
export function getClientBySearch(data) {
  return http.post('/admin/client/getClientBySearch', data, {
    noShowWaiting: true,
  });
}
