<template>
  <el-dialog
    :visible="visibility"
    width="800px"
    title="门店范围管理"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div class="container">
      <p class="tip-word">
        <i class="el-icon-warning orange"></i>
        设置门店运营指挥中心中需要管控的门店清单，仅管控统计列表的门店清单
      </p>
      <el-table :data="tableData" border>
        <el-table-column label="操作" width="60" align="center">
          <template #default="{ $index }">
            <i
              class="iconfont grow-icon_delete3 pointer red"
              @click="del($index)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column label="序号" width="60" align="center">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="门店编号" prop="companyId"></el-table-column>
        <el-table-column label="门店名称" prop="companyName"></el-table-column>
        <el-table-column prop="showState">
          <template #header>
            <el-tooltip
              placement="top-start"
              content="控制指挥中心地图是否显示该门店位置"
            >
              <span
                >是否显示门店位置
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </span>
            </el-tooltip>
          </template>
          <template #default="{ row }">
            <el-switch
              v-model="row.showState"
              active-text="显示"
              inactive-text="不显示"
              :active-value="0"
              :inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <template slot="append">
          <div class="append">
            <el-select
              v-model="selectedCompany"
              size="small"
              filterable
              remote
              placeholder="搜索车店名称、编号"
              :remote-method="searchNotControlCompanyList"
              :loading="searchNotControlCompanyListLoading"
              @change="handleSelect"
            >
              <el-option
                v-for="item in notControlCompanyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item"
              >
                <span style="float: left">{{ item.companyId }}</span>
                <span style="float: right; color: #8492a6">{{
                  item.companyName
                }}</span>
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table>
    </div>

    <div slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSave"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';

import {
  getControlCompany,
  getNotControlCompanyPage,
  setControlCompany,
} from '@/api/commandCenter';

export default {
  name: 'setStoreScopeDialog',
  components: {},
  props: {
    visibility: { type: Boolean, default: false },
  },
  data() {
    return {
      // 搜索门店
      notControlCompanyList: [],
      searchNotControlCompanyListLoading: false,
      selectedCompany: '',
      id: '',
      tableData: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {},
  mounted() {
    this.getList();
    this.searchNotControlCompanyList();
  },
  methods: {
    /**
     * 获取当前门店管理范围
     */
    getList() {
      getControlCompany({ id: this.companyId }).then((res) => {
        res.forEach((item) => {
          if (!item.showState) item.showState = 0;
        });
        this.tableData = res;
      });
    },

    /**
     * 处理选择门店事件
     */
    handleSelect(company) {
      console.log(company);
      var temp = this.$lodash.clone(company);
      temp.showState = 0;
      this.tableData.push(temp);
      this.notControlCompanyList.splice(
        this.notControlCompanyList.findIndex(
          (item) => item.companyId === company.companyId
        ),
        1
      );
      this.selectedCompany = '';
    },

    /**
     * 删除指定门店
     */
    del(index) {
      this.tableData.splice(index, 1);
    },

    /**
     * 非管控门店
     */
    searchNotControlCompanyList(keyWord) {
      this.searchNotControlCompanyListLoading = true;
      getNotControlCompanyPage({
        companyId: this.companyId,
        msg: keyWord,
        size: 99,
        current: 1,
      })
        .then((res) => {
          this.notControlCompanyList = res.records || [];
        })
        .finally(() => {
          this.searchNotControlCompanyListLoading = false;
        });
    },
    /**
     * 保存
     */
    handleSave() {
      setControlCompany({
        companyId: this.companyId,
        companyControlAndShowList: this.tableData.map((item) => ({
          controlCompanyId: item.companyId,
          showState: item.showState,
        })),
      }).then((res) => {
        this.$message.success('设置成功!');
        this.handleClose();
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-word {
  margin: 15px 0;
  font-size: 13px;
  color: #666;
}
.append {
  padding: 4px 120px;
  .el-cascader {
    width: 220px !important;
  }
}
</style>
