// 打印类属性、方法定义
/* eslint-disable */
export const Print = function (dom, options) {
  if (!(this instanceof Print)) return new Print(dom, options);
  console.log(dom,'dom5555')
  this.options = this.extend(
    {
      noPrint: '.no-print'
    },
    options
  );
    console.log('options',options)
  if (typeof dom === 'string') {
    this.dom = document.querySelector(dom);
  } else {
    this.isDOM(dom);
    this.dom = this.isDOM(dom) ? dom : dom.$el;
  }
  this.dom.style.color = '#000';

  this.init();
};
Print.prototype = {
  init: function () {
    var content = this.getStyle() + this.getHtml();
    this.writeIframe(content);
  },
  extend: function (obj, obj2) {
    for (var k in obj2) {
      obj[k] = obj2[k];
    }
    return obj;
  },

  getStyle: function () {
    var str = '',
      styles = document.querySelectorAll('style,link');
      if(this.options.title){
        str = `<div style="display:flex; justify-content: center;;font-size:30px;font-weight:bold;margin-bottom:30px">${this.options.title}</div>`
      }
    for (var i = 0; i < styles.length; i++) {
      str += styles[i].outerHTML;
    }
    str += '<style>' + (this.options.noPrint ? this.options.noPrint : '.no-print') + '{display:none;}</style>';
        // 添加水印样式
        str += `
        <style type="text/css">
        @media print {
          .watermark {
            position: fixed;
            top: 0;
            left: 0;
            height:100vh;
            width:100vw;
            right: 0;
            bottom: 0;
            opacity: 0.2;
            z-index: 9999;
            font-size: 20px;
            transform: rotate(-45deg);
            color: lightgrey;
            display:flex;
            // position: absolute;
            // transform: rotate(325deg);
            // font-size: 24px;
            // color: rgba(0, 0, 0, 0.2); /* 水印文字颜色和透明度 */
            // pointer-events: none; /* 使水印不干扰用户交互 */
            // z-index: 9999; /* 确保水印在最前面 */
            // white-space: nowrap;
          }
          .title{
            span{
              margin-left:10px;
            }
          }
          .info{
            padding-top:12px;
          }
          @page {
            size: auto;
            margin: 30px;
            // size: A4 portrait;
          }
        }
        
      </style>
      `;
    return str;
  },

  getHtml: function () {
    var inputs = document.querySelectorAll('input');
    var textareas = document.querySelectorAll('textarea');
    var selects = document.querySelectorAll('select');
    console.log(this.dom,'domdomdom')
    if(this.options.isWatermark){
      // let screenWidth = window.innerWidth;
      // let screenHeight = window.innerHeight;
      // let watermarkWidth = 300; // 调整水印的宽度
      // let watermarkHeight = 140; // 调整水印的高度
      // let rows = Math.ceil(screenHeight / watermarkHeight); 
      // let cols = Math.ceil (screenWidth / watermarkWidth );
      let date = new Date()
      // var totalPages = window.frames.length;
      let years = date.getFullYear()
      let moth = date.getMonth()+1
      let day  = date.getDate()
      let watermarkText = `${this.options.companyName}  ${this.options.userName} ${years + "-" +moth + "-" + day}`
      var pageWidth = document.body.clientWidth; // 获取页面的宽度，单位像素
      var pageHeight = document.body.clientHeight; // 获取页面的高度，单位像素

      var watermarkWidth = 50; // 水印宽度，单位毫米
      var watermarkHeight = 20; // 水印高度，单位毫米

      var watermarkCountX = 5; // 水印横向数量
      var watermarkCountY = 5; // 水印纵向数量

      var gapX = (100 - (watermarkWidth * watermarkCountX)) / (watermarkCountX - 1); // 计算水印横向间隔百分比
      var gapY = (100 - (watermarkHeight * watermarkCountY)) / (watermarkCountY - 1); // 计算水印纵向间隔百分比

      for (var i = 0; i < watermarkCountX; i++) {
        for (var j = 0; j < watermarkCountY; j++) {
          var watermark = document.createElement('div');
          watermark.className = 'watermark';
          watermark.innerText = watermarkText; // 更改为你的水印内容
          watermark.style.left = (i * (watermarkWidth + gapX+22)) + '%';
          watermark.style.top = (j * (watermarkHeight + gapY)) + '%';
          watermark.style.width = watermarkWidth + 'mm';
          watermark.style.height = watermarkHeight + 'mm';
          // document.body.appendChild(watermark);
          this.dom.appendChild(watermark);
        }
      }
      // for (let i = 0; i < rows; i++) {
      //   for (let j = 0; j < cols; j++) {
      //     var watermark = document.createElement('div');
      //     watermark.className = 'watermark';
      //     watermark.innerText = watermarkText;
      //     // watermark.style.position = 'fixed';
      //     watermark.style.top = i * watermarkHeight  +'px'; // 增加间距  + 
      //     watermark.style.left = j  * watermarkWidth  + 'px'; // 增加间距 
      //     watermark.style.width =  watermarkWidth+ 'px'; // 设置水印宽度
      //     watermark.style.height = watermarkHeight + 'px'; // 设置水印高度
      //     // watermark.style.margin = '20px'
      //     watermark.style.fontSize = '20px'
      //     watermark.style.overflow = 'hidden'; // 隐藏超出部分
      //     watermark.style.color = 'lightgrey'
      //     this.dom.appendChild(watermark);
      //   }
      // }
    }

    for (var k = 0; k < inputs.length; k++) {
      if (inputs[k].type == 'checkbox' || inputs[k].type == 'radio') {
        if (inputs[k].checked == true) {
          inputs[k].setAttribute('checked', 'checked');
        } else {
          inputs[k].removeAttribute('checked');
        }
      } else if (inputs[k].type == 'text') {
        inputs[k].setAttribute('value', inputs[k].value);
      } else {
        inputs[k].setAttribute('value', inputs[k].value);
      }
    }

    for (var k2 = 0; k2 < textareas.length; k2++) {
      if (textareas[k2].type == 'textarea') {
        textareas[k2].innerHTML = textareas[k2].value;
      }
    }

    for (var k3 = 0; k3 < selects.length; k3++) {
      if (selects[k3].type == 'select-one') {
        var child = selects[k3].children;
        for (var i in child) {
          if (child[i].tagName == 'OPTION') {
            if (child[i].selected == true) {
              child[i].setAttribute('selected', 'selected');
            } else {
              child[i].removeAttribute('selected');
            }
          }
        }
      }
    }
    
    return this.dom.outerHTML;
  },

  writeIframe: function (content) {
    var w,
      doc,
      iframe = document.createElement('iframe'),
      f = document.body.appendChild(iframe);
    iframe.id = 'myIframe';
    //iframe.style = "position:absolute;width:0;height:0;top:-10px;left:-10px;";
    iframe.setAttribute('style', 'position:absolute;width:0;height:0;top:-10px;left:-10px;');
    w = f.contentWindow || f.contentDocument;
    doc = f.contentDocument || f.contentWindow.document;
    doc.open();
    doc.write(content);
    doc.close();
    if(this.options.materialTitle && this.options.dateCreated.split(' ')){
    let oldDocumentTitle =  document.title;
    let newDocumentTitle  = this.options.materialTitle;
    let dateCreated = this.options.dateCreated.split(' ')
    document.title = newDocumentTitle + dateCreated[0]
    var _this = this;
    iframe.onload = function () {
      _this.toPrint(w);
      setTimeout(function () {
        document.title = oldDocumentTitle
        document.body.removeChild(iframe);
      }, 100);
    };
    }else{
    var _this = this;
    iframe.onload = function () {
      _this.toPrint(w);
      setTimeout(function () {
        document.body.removeChild(iframe);
      }, 100);
    };
    }
    
  },

  toPrint: function (frameWindow) {
    // var _this = this;

    // 在打印前移除水印元素
    var watermarks = document.querySelectorAll('.watermark');
    watermarks.forEach(function (watermark) {
      watermark.parentNode.removeChild(watermark);
    });
    try {
      setTimeout(function () {
        frameWindow.focus();
        try {
          if (!frameWindow.document.execCommand('print', false, null)) {
            setTimeout(() => {
              frameWindow.print();     
            }, 2000);
          }
        } catch (e) {
          frameWindow.print();
        }
        frameWindow.close();
      }, 10);
    } catch (err) {
      console.log('err', err);
    }

  },
  isDOM:
    typeof HTMLElement === 'object'
      ? function (obj) {
        return obj instanceof HTMLElement;
      }
      : function (obj) {
        return obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string';
      }
};
