<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <div style="margin-bottom: 30px; margin-left: 40px" class="flex">
        <i class="el-icon-warning orange el-icon--left"></i>
        <p style="font-size: 14px">点击确定{{ title }}</p>
      </div>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="跟进人" prop="followUpPeople">
            <el-select
              v-model="editForm.followUpPeople"
              filterable
              clearable
              remote
              placeholder="搜索姓名、手机号"
              :remote-method="searchAllUser"
              :loading="searchAllUserLoading"
            >
              <el-option
                v-for="item in allUserList"
                :key="item.userId"
                :label="item.realName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="title === '完成复勘'" label="复勘情况" required>
            <el-select
              v-model="editForm.investigationState"
              placeholder="请选择"
            >
              <el-option :value="3" label="已复勘"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="title === '完成收旧'" label="收旧情况" required>
            <el-select v-model="editForm.recoveryState" placeholder="请选择">
              <el-option :value="3" label="已回收"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="跟进进展" prop="followUpState">
            <el-radio-group
              style="line-height: 1.4rem"
              v-model="editForm.followUpState"
            >
              <el-radio
                v-for="option in followUpStateList"
                :key="option.value"
                :label="option.value"
                @click.native.prevent="handleClickFollowUpState(option.value)"
              >
                <div :style="option.iconStyle">{{ option.iconText }}</div>
                <span style="margin-left: 5px">{{ option.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="当次留言" prop="message">
            <el-input
              type="textarea"
              size="small"
              v-model="editForm.message"
              :rows="2"
              maxlength="300"
              show-word-limit
              placeholder="请输入当次留言，最多300个字符"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="附件" prop="attachment">
            <span style="color: #909399; font-size: 12px"
              >只支持上传zip文件，最多5个</span
            >
            <div class="flex">
              <ul style="max-width: 300px">
                <li v-for="(item, i) in attachmentList" :key="i">
                  <i
                    class="iconfont grow-icon_delete3 orange el-icon--left pointer"
                    @click="handleDelete(i)"
                  ></i>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
              <div style="margin-left: 20px" v-if="attachmentList.length < 5">
                <el-button size="small" @click="chooseFile">选择文件</el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 字典数据
import { followUpStateList } from '@/service/dict/dictData';

import { getAllUser } from '@/api/employee';
import {
  finishInvestigationRecovery,
  uploadZipFile,
} from '@/api/carBill/carLossDeterminationBill';

import { chooseFile } from '@/utils/fileApi';
export default {
  name: 'secondEditDialog',
  props: {
    visibility: Boolean,
    id: Number,
    title: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      followUpStateList,
      // 表单信息
      editForm: {},
      // 表单规则
      rules: {
        followUpPeople: [
          { required: true, message: '请选择跟进人', trigger: 'blur' },
        ],
      },
      attachmentList: [],

      searchAllUserLoading: false,
      allUserList: [],
    };
  },
  created() {
    this.editForm = this.$lodash.cloneDeep(this.form);

    if (this.title === '完成复勘') {
      this.editForm.investigationState = 3;
    } else {
      this.editForm.recoveryState = 3;
    }

    if (this.editForm.attachmentUrl) {
      const url = this.editForm.attachmentUrl.split(',').filter((el) => el);
      const name = this.editForm.attachmentName.split(',').filter((el) => el);
      this.attachmentList = url.map((item, index) => {
        return {
          url: item,
          name: name[index],
        };
      });
    }
    // this.searchAllUser({
    //   msg: this.editForm.followUpPeopleName,
    //   userId: this.editForm.followUpPeople,
    // });
    if (this.editForm.followUpPeople) {
      this.allUserList = [
        {
          userId: this.editForm.followUpPeople,
          realName: this.editForm.followUpPeopleName,
        },
      ];
    }
  },
  methods: {
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      this.searchAllUserLoading = true;
      let params = {};
      if (typeof msg === 'object') {
        params = msg;
      } else {
        params = { msg };
      }
      getAllUser(params)
        .then((res) => {
          this.allUserList = res.records || [];
        })
        .finally(() => {
          this.searchAllUserLoading = false;
        });
    },
    /**
     * 跟进进展单选框选中时，再次点击取消选中
     */
    handleClickFollowUpState(value) {
      if (this.editForm.followUpState === value) {
        this.editForm.followUpState = '';
      } else {
        this.$set(this.editForm, 'followUpState', value);
      }
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          const body = Object.assign(this.editForm, {
            id: this.id,
            type: this.title === '完成复勘' ? 10 : 11,
            attachmentName: this.attachmentList
              .map((item) => item.name)
              .join(','),
            attachmentUrl: this.attachmentList
              .map((item) => item.url.split('/').at(-1))
              .join(','),
          });
          finishInvestigationRecovery(body).then((res) => {
            this.$message.success(res.data || '操作成功!');

            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },

    /**
     * 选择文件
     */
    chooseFile() {
      chooseFile({ accept: '.zip', multiple: false }).then((file) => {
        console.log(file[0]);
        // 大于100M不允许上传
        if (file[0].size > 1024 * 1024 * 100) {
          this.$message.error('上传的附件大小不能超过100MB!');
          return;
        }
        uploadZipFile(file[0]).then((res) => {
          this.attachmentList.push({
            url: res.imageUrl,
            // name: res.name.replace(/,/g, ''),
            name: file[0].name.replace(/,/g, ''),
          });
        });
      });
    },

    /**
     * 删除
     */
    handleDelete(index) {
      this.attachmentList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
