<template>
  <el-dialog
    :visible="visibility"
    width="45%"
    :close-on-click-modal="false"
    :before-close="handleClose"
    title="选择客户"
  >
    <div class="binding-customer-dialog">
      <span style="margin-right: 10px">选择已有客户</span>
      <el-popover
        placement="bottom"
        trigger="manual"
        v-model="visible"
        popper-class="popper-class"
      >
        <el-table
          class="car-table"
          :max-height="300"
          border
          :data="listData"
          @row-click="handleClick"
        >
          <el-table-column width="90 " label="客户名称" prop="clientName">
          </el-table-column>
          <el-table-column min-width="160" label="协议客户">
            <template #default="{ row }">
              <p v-if="row.isAgreementClient == 1">
                协议客户<span v-if="row.beContract">（包干）</span>
              </p>
              <p v-if="row.isAgreementClient == 0"></p>
            </template>
          </el-table-column>
          <el-table-column width="90 " label="等级" prop="clientLevel">
          </el-table-column>
        </el-table>
        <template slot="reference">
          <el-input
            style="width: 300px"
            ref="keyWordInput"
            v-model="keyWord"
            size="small"
            placeholder="搜索客户名称"
            @input="getData(keyWord)"
            @focus="visible = true"
            @blur="visible = false"
            @clear="handleClear"
            clearable
          ></el-input>
          <span v-auth="61" style="margin: 0 15px">或</span>
          <span
            v-auth="61"
            class="primary text_operation"
            @click="showAddCusterDialog = true"
            >新增</span
          >
        </template>
      </el-popover>
      <div class="client" v-show="clientInfo.id">
        <i
          v-auth="64"
          class="iconfont grow-icon_delete3 icon_delete pointer"
          @click="deleteClient"
        ></i>
        <div class="client-info">
          <div>
            <i class="iconfont grow-icon_person icon_person"></i>
            <span>{{ clientInfo.clientName }}</span>
            <!-- <i
              class="iconfont icon_sex"
              :class="{
                'grow-icon_man': clientInfo.clientGenderValue == 0,
                'grow-icon_girl': clientInfo.clientGenderValue == 1,
              }"
              :title="['男士', '女士'][clientInfo.clientGenderValue] || ''"
            ></i> -->
            <i
              class="iconfont icon_grade"
              :class="levelIconsOther[clientInfo.clientLevel]"
              :title="[clientInfo.clientLevel] || ''"
            ></i>
          </div>
          <!-- <div class="flex">
            <p v-if="clientInfo.vipType == 4">
              <i class="iconfont grow-icon_vip icon_vip"></i>
              会员卡/协议客户（包干）
            </p>
            <p v-if="['', undefined].includes(clientInfo.vipType)">
              <i class="iconfont grow-icon_vip_nonactivated"></i>
              非会员卡
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <add-customer-dialog
      v-if="showAddCusterDialog"
      :showAddCusterDialog.sync="showAddCusterDialog"
      @custerInforma="handleAddCustomer"
    ></add-customer-dialog>

    <template slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { searchClient } from '@/api/customer/customer';
import { levelIconsOther } from '@/const/client';
import addCustomerDialog from '@/views/customerManagement/addCustomer';
import { bindingClientByCarId } from '@/api/customer/car';
export default {
  name: 'carBindingCustomer',
  components: {
    addCustomerDialog,
  },
  props: {
    visibility: { type: Boolean, default: false },
    carId: { type: Number, default: 0 },
  },
  data() {
    return {
      clientId: undefined,
      clientInfo: {},
      clientList: [],
      searchClientLoading: false,
      showAddCusterDialog: false,
      levelIconsOther,
      // 列表数据
      listData: [],
      // 搜索关键字
      keyWord: '',
      // 可见性
      visible: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  methods: {
    /**
     * 获取数据
     */
    getData(keyword = '') {
      this.searchClientLoading = true;
      searchClient(keyword)
        .then((res) => {
          this.clientList = res.records || [];
          this.listData = res.records || [];
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择客户
     */
    selectClient(clientId) {
      this.visible = false;
      if (clientId) {
        const client = this.clientList.find((item) => item.id === clientId);
        this.clientInfo = client;
      }
    },
    /**
     * 删除选中
     */
    deleteClient() {
      this.clientInfo = {};
    },
    /**
     * 处理新增客户
     */
    handleAddCustomer(val) {
      this.visible = false;
      this.clientInfo = val;
      this.clientId = val.id;
      this.clientList = [this.clientInfo]; //处理回显
    },
    /**
     * 确认事件
     */
    confirm() {
      bindingClientByCarId(this.clientId, [this.carId]).then((res) => {
        this.$emit('binding', this.clientInfo);
        this.handleClose();
      });
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * 表格中选中
     */
    handleClick(val) {
      this.keyWord = val.clientName;
      this.visible = false;
      this.clientInfo = val;
      this.clientId = val.id;
    },
    /**
     * 输入框清空
     */
    handleClear() {
      this.visible = true;
      this.clientInfo = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.binding-customer-dialog {
  height: 120px;
  padding: 10px 0;
}
.client {
  margin: 20px 0 0 55px;
  display: flex;
  align-items: center;
  > .icon_delete {
    font-size: 18px;
    color: #ff9200;
    margin-right: 15px;
  }
  .client-info {
    > div + div {
      margin-top: 10px;
    }
    i {
      margin: 0 5px;
    }
    .icon_person {
      color: $blue;
    }
    .icon_sex {
      color: $blue;
    }
    .icon_grade {
      color: $blue;
    }
    .grow-icon_girl {
      color: #f48080;
    }
    .icon_phone {
      color: #42bbb8;
    }
    .icon_sex,
    .icon_grade,
    .icon_wechat,
    .icon_wechat_active,
    .icon_vip {
      font-size: 14px;
    }
  }
}
</style>
