<template>
  <el-drawer
    title="选择服务"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div style="height: 100%; padding: 0 30px">
      <el-tabs class="page-tabs" v-model="activeTabName">
        <el-tab-pane label="全部" name="first">
          <base-container :default-padding="false">
            <template slot="header">
              <el-col :span="7" style="margin-right: 20px">
                <el-input
                  size="small"
                  v-model="keyWord"
                  placeholder="请输入服务名称、编码"
                  clearable
                  @keyup.enter.native="getListData"
                ></el-input>
              </el-col>
              <el-button size="small" type="primary" @click="getListData"
                >查询</el-button
              >
            </template>
            <div class="content-box">
              <el-table
                ref="multipleTable"
                :data="tableData"
                border
                class="data-table"
                @select="handleSelectionChange"
                @select-all="handleSelectAll"
              >
                <el-table-column type="selection" width="40"> </el-table-column>
                <el-table-column label="序号" type="index" width="60">
                </el-table-column>
                <el-table-column prop="name" label="服务名称" width="120">
                  <template #default="{ row }">
                    <el-tag
                      v-if="row.isAgreePrice"
                      type="info"
                      size="mini"
                      class="el-icon--left"
                      >协议</el-tag
                    >
                    <span>{{ row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="110"
                  prop="serviceTime"
                  label="服务时效(H)"
                ></el-table-column>
                <el-table-column label="售价" prop="price" width="100">
                </el-table-column>
                <el-table-column
                  label="最近服务"
                  prop="lastUseDate"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  min-width="120"
                  prop="description"
                  label="服务备注"
                ></el-table-column>
                <!-- <el-table-column
                  prop="serviceItemCode"
                  label="服务编码"
                  width="110"
                >
                </el-table-column>
                <el-table-column
                  prop="fsPrice"
                  label="4S参考价"
                  min-width="110"
                >
                </el-table-column>
                <el-table-column label="所属分类">
                  <template slot-scope="scope">
                    {{ scope.row.categoryName }}
                  </template>
                </el-table-column> -->
              </el-table>
              <div class="class-box">
                <p class="title">服务分类</p>
                <el-tree
                  class="custom-tree"
                  :data="treeData"
                  :props="treeProp"
                  highlight-current
                  node-key="categoryId"
                  @node-click="onNodeClick"
                >
                </el-tree>
              </div>
            </div>
            <template slot="footer">
              <pagination @pageChange="changePage" :total="total"></pagination>
            </template>
          </base-container>
        </el-tab-pane>
        <!-- <el-tab-pane label="卡内服务项目" name="second"></el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="footer">
      <p>
        <i class="iconfont grow-icon_warn_32 orange"></i>
        <span>已选择服务项目 {{ selectedItems.length }}</span>
        <!-- ，本次新增选择 {{ addLength }} -->
      </p>
      <el-button type="primary" size="small" @click="confirmSelect"
        >确 定</el-button
      >
      <el-button size="small" @click="handleClose">取 消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import {
  searchServiceForCreateCarBill,
  searchServiceWithAgreementPriceForCreateCarBill,
  getServicesClasses,
} from '@/api/carBill';
import pagination from '@/components/Pagination.vue';
import { mapState } from 'vuex';
export default {
  name: 'selectServicesForCarBill',
  props: {
    visibility: { type: Boolean, default: false },
    billType: [String, Number],
    clientAgreeId: [String, Number],
    carId: {
      type: [String, Number],
      default: 0,
    },
    billId: {
      type: [String, Number],
      default: 0,
    },
    billCompanyId: {
      type: Number,
      default: 0,
    },
    oldData: {
      type: Array,
      default: () => [],
    },
  },
  components: { pagination },
  data() {
    return {
      activeTabName: 'first',
      // 列表数据
      tableData: [],
      selectedItems: [],
      treeData: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      keyWord: '',
      treeProp: {
        children: 'subCategories',
        label: 'name',
      },
      firstCategoryId: '',
      secondCategoryId: '',
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    addLength() {
      return this.totalLength - this.oldData.length;
    },
    totalSelected() {
      return this.$lodash.uniqBy(
        this.selectedItems.concat(this.oldData),
        'serviceItemId'
      );
    },
    totalLength() {
      return this.totalSelected.length;
    },
    addItems() {
      return this.$lodash.differenceBy(
        this.selectedItems,
        this.oldData,
        'serviceItemId'
      );
    },
  },
  created() {
    this.getStatistic();
    this.getListData();
    // this.selectedItems = this.$lodash.cloneDeep(this.oldData || []);
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getListData();
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * table选中事件
     */
    handleSelectionChange(val, item) {
      console.log(arguments);
      // 如果此次点击的项目不是勾选状态，则说明是取消勾选此项
      let clickItem = val.find((s) => s.serviceItemId === item.serviceItemId);
      if (!clickItem) {
        // 在选中列表中删除此项
        this.selectedItems = this.selectedItems.filter(
          (s) => s.serviceItemId != item.serviceItemId
        );
      }
      this.selectedItems = this.$lodash.uniqBy(
        this.selectedItems.concat(val),
        'serviceItemId'
      );
    },
    /**
     * 勾选全部
     */
    handleSelectAll(val) {
      let bool = this.selectedItems.every((item) =>
        val.find((el) => el.title === item.title)
      );
      this.selectedItems.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row, bool);
      });
      this.selectedItems = bool
        ? this.$lodash.uniqBy(this.selectedItems.concat(val), 'serviceItemId')
        : [];
    },
    /**
     * 同步选中状态
     */
    syncSelectedStatus() {
      this.$nextTick().then(() => {
        if (this.selectedItems?.length) {
          var serviceItemId = this.selectedItems.map(
            (item) => item.serviceItemId
          );
          var shouldBeSelected = this.tableData.filter((item) =>
            serviceItemId.includes(item.serviceItemId)
          );
          shouldBeSelected.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    /**
     * 确认选择
     */
    confirmSelect() {
      // 调用自定义事件，传入参数
      this.$emit('select', this.selectedItems);
      this.$emit('update:visibility', false);
    },

    /**
     * 获取数据
     */
    getListData() {
      var form = {
        companyId: this.companyId,
        serviceMessage: this.keyWord,
      };
      if (this.firstCategoryId) {
        form.firstCategoryId = this.firstCategoryId;
      }
      if (this.secondCategoryId) {
        form.secondCategoryId = this.secondCategoryId;
      }
      if (this.billType !== undefined) {
        form.billType = this.billType;
      }
      if (this.clientAgreeId) {
        form.clientAgreeId = this.clientAgreeId;
      }
      if (this.billCompanyId) {
        form.billCompanyId = this.billCompanyId;
      }

      let data = Object.assign({}, form, this.pageOption, {
        carId: this.carId,
        billId: this.billId,
      });
      searchServiceWithAgreementPriceForCreateCarBill(data, {
        noShowWaiting: false,
      }).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
        this.syncSelectedStatus();
      });
    },
    /**
     * 获取分类数据
     */
    getStatistic() {
      getServicesClasses(this.companyId).then((res) => {
        // 添加全部选项
        res.unshift({
          categoryId: 0,
          name: '全部',
        });
        this.treeData = res;
      });
    },
    /**
     * 点击节点
     */
    onNodeClick(obj, node, origin) {
      if (obj.categoryId) {
        if (obj.parentId) {
          this.firstCategoryId = obj.parentId;
          this.secondCategoryId = obj.categoryId;
        } else {
          this.firstCategoryId = obj.categoryId;
          this.secondCategoryId = 0;
        }
      } else {
        this.firstCategoryId = '';
        this.secondCategoryId = 0;
      }

      this.getListData();
    },
  },
};
</script>
<style lang="scss" scoped>
.page-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  &::v-deep .el-tabs__content {
    flex: 1;
    .el-tab-pane {
      height: calc(100% - 109px);
    }
  }
}

.content-box {
  height: 100%;
  display: flex;
  padding-top: 20px;
  box-sizing: border-box;
  .data-table {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::v-deep .el-table__header-wrapper {
      flex-shrink: 0;
    }
    ::v-deep .el-table__body-wrapper {
      flex: 1;
      overflow: auto;
    }
  }
}

.class-box {
  width: 30%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}

.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 109px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 60px;
  // border-top: 1px solid #e4e4e4;
  // border-bottom: 1px solid #e4e4e4;
  box-shadow: -4px 0px 30px 0px rgb(0 0 0 / 10%);
  background: #fff;
  z-index: 99;
}
.footer > p {
  margin-right: 30px;
}
.footer span {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
}
</style>
