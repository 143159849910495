<template>
  <el-dialog
    title="滞留说明"
    :visible="visibility"
    width="200"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      class="form"
      :model="editInfo"
      :rules="rules"
      label-width="120px"
      size="small"
    >
      <el-form-item label="滞留原因" prop="strandedReason">
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请填写车辆滞留在场原因或维修中断原因（必填）"
          v-model="editInfo.strandedReason"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item label="卡点解决时间" prop="planCompletionTime">
        <el-date-picker
          v-model="editInfo.planCompletionTime"
          type="date"
          placeholder="请选择日期"
          size="small"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <p style="color: #909399; margin: -2px 0 10px 120px">
        设置当前阶段预计完成时间
      </p>
      <el-form-item label="预交车时间" prop="planFinishTime">
        <el-date-picker
          v-model="editInfo.planFinishTime"
          type="datetime"
          :placeholder="finishPlaceholder"
          size="small"
          :disabled="planFinishDisabled"
          @focus="verifyPermission"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  getCarBillStrandedInfo,
  editDetentionInstructions,
} from '@/api/carBill/strandedCar';

import { verifyAuth, hasAuth, getAuthUrl } from '@/utils/permissions';

import { mapState } from 'vuex';
export default {
  name: 'strandedNoteDialog',
  props: {
    visibility: Boolean,
    billId: [Number, String],
  },
  data() {
    return {
      // 表单信息
      editInfo: {},
      planFinishDisabled: false,
      // 表单规则
      rules: {
        strandedReason: [
          {
            required: true,
            message: '请填写滞留原因',
            trigger: 'blur',
          },
        ],
        planCompletionTime: [
          { required: true, message: '请选择卡点解决时间', trigger: 'blur' },
        ],
        planFinishTime: [
          { required: true, message: '请选择预交车时间', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    finishPlaceholder() {
      return this.editInfo.undetermined ? '待定' : '请选择预交车时间';
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getCarBillStrandedInfo({ billId: this.billId }).then((res) => {
        this.editInfo = res || {
          billId: this.billId,
          strandedReason: '',
          planCompletionTime: '',
          planFinishTime: '',
        };
      });
    },
    /**
     * 校验修改预交车时间权限
     */
    verifyPermission() {
      var res = hasAuth(524);
      if (!res) {
        this.planFinishDisabled = true;

        let url = getAuthUrl(524);
        let urlString = url.reduce((prev, item, index) => {
          if (index === url.length - 1) {
            item = `【${item}】`;
          }
          if (index > 0) {
            item = ` > ${item}`;
          }
          return prev + item;
        }, '');
        let msg = `无“${urlString}”的权限，请联系超级用户设置角色权限`;
        this.$alert(msg, '温馨提示');
      }
    },

    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          if (result) {
            var body = Object.assign({}, this.editInfo, {
              planCompletionTime: new Date(
                this.editInfo.planCompletionTime
              ).Format('yyyy-MM-dd hh:mm:ss'),
            });
            editDetentionInstructions(body)
              .then((res) => {
                this.$message.success('操作成功！');
                this.handleClose();
              })
              .catch((err) => {
                console.log(err);
                this.$message.error('操作失败！');
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.warning('请填写必填项！');
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
// .form ::v-deep .el-form-item__label {
//   text-align: left;
// }
</style>
