<template>
  <div>
    <div class="flex note">
      <p>适用门店说明：</p>
      <div>
        <p>
          客户协议设置适用门店后，协议信息将在适用门店内共享。对于加盟店，协议客户的客户、车辆等信息也将一并共享给加盟店。
        </p>
      </div>
    </div>

    <div class="table-title">
      <span>自费业务： </span>
      <span v-if="$lodash.get(detail, 'hasSelfPaying')">
        有限制，部分门店适用 ({{
          $lodash.get(detailData, 'selfPaying.length')
        }})
        {{
          $lodash
            .get(detailData, 'selfPaying', [])
            .find((item) => item.companyId == companyId)
            ? '，含本门店'
            : '，不包含本店'
        }}
      </span>
      <span v-else>无限制，所有门店适用</span>
    </div>
    <el-table
      class="table"
      v-if="$lodash.get(detailData, 'selfPaying.length')"
      :data="detailData.selfPaying"
      border
    >
      <el-table-column type="index" label="序号" width="60"> </el-table-column>
      <el-table-column prop="companyId" label="车店编号" width="160">
      </el-table-column>
      <el-table-column prop="companyName" label="车店名称"> </el-table-column>
      <el-table-column label="连锁类型" width="160">
        <template #default="{ row }">
          {{ row.chainCompanyType | dict('chainCompanyType') }}
        </template>
      </el-table-column>
    </el-table>
    <div class="table-title">
      <span>包干业务： </span>
      <span v-if="$lodash.get(detail, 'hasContractFor')">
        有限制，部分门店适用 ({{
          $lodash.get(detailData, 'contractFor.length')
        }})
        {{
          $lodash
            .get(detailData, 'contractFor', [])
            .find((item) => item.companyId == companyId)
            ? '，含本门店'
            : '，不包含本店'
        }}</span
      >
      <span v-else>无限制，所有门店适用</span>
    </div>
    <el-table
      class="table"
      v-if="$lodash.get(detailData, 'contractFor.length')"
      :data="detailData.contractFor"
      border
    >
      <el-table-column type="index" label="序号" width="60"> </el-table-column>
      <el-table-column prop="companyId" label="车店编号" width="160">
      </el-table-column>
      <el-table-column prop="companyName" label="车店名称"> </el-table-column>
      <el-table-column label="连锁类型" width="160">
        <template #default="{ row }">
          {{ row.chainCompanyType | dict('chainCompanyType') }}
        </template>
      </el-table-column>
    </el-table>
    <div class="table-title">
      <span>理赔业务： </span>
      <span v-if="$lodash.get(detail, 'hasClaimSettlement')">
        有限制，部分门店适用 ({{
          $lodash.get(detailData, 'claimSettlement.length')
        }})
        {{
          $lodash
            .get(detailData, 'claimSettlement', [])
            .find((item) => item.companyId == companyId)
            ? '，含本门店'
            : '，不包含本店'
        }}
      </span>
      <span v-else>无限制，所有门店适用</span>
    </div>
    <el-table
      class="table"
      v-if="$lodash.get(detailData, 'claimSettlement.length')"
      :data="detailData.claimSettlement"
      border
    >
      <el-table-column type="index" label="序号" width="60"> </el-table-column>
      <el-table-column prop="companyId" label="车店编号" width="160">
      </el-table-column>
      <el-table-column prop="companyName" label="车店名称"> </el-table-column>
      <el-table-column label="连锁类型" width="160">
        <template #default="{ row }">
          {{ row.chainCompanyType | dict('chainCompanyType') }}
        </template>
      </el-table-column>
    </el-table>
    <div class="table-title">
      <span>索赔业务： </span>
      <span v-if="$lodash.get(detail, 'hasClaimForCompensation')">
        有限制，部分门店适用 ({{
          $lodash.get(detailData, 'claimForCompensation.length')
        }})
        {{
          $lodash
            .get(detailData, 'claimForCompensation', [])
            .find((item) => item.companyId == companyId)
            ? '，含本门店'
            : '，不包含本店'
        }}
      </span>
      <span v-else>无限制，所有门店适用</span>
    </div>
    <el-table
      class="table"
      v-if="$lodash.get(detailData, 'claimForCompensation.length')"
      :data="detailData.claimForCompensation"
      border
    >
      <el-table-column type="index" label="序号" width="60"> </el-table-column>
      <el-table-column prop="companyId" label="车店编号" width="160">
      </el-table-column>
      <el-table-column prop="companyName" label="车店名称"> </el-table-column>
      <el-table-column label="连锁类型" width="160">
        <template #default="{ row }">
          {{ row.chainCompanyType | dict('chainCompanyType') }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getAgreementStore } from '@/api/customer/agreement';
export default {
  props: {
    id: {
      type: [String, Number],
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      detailData: {},
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    getAgreementStore(this.id).then((res) => {
      this.detailData = res;
    });
  },
};
</script>

<style lang="scss" scoped>
.note {
  margin-top: 30px;
  color: #999;
  font-size: 14px;
  > p {
    flex-shrink: 0;
  }
}

.table-title {
  margin: 60px 0 20px;
  color: #333;
  font-weight: 500;
  > span:first-child {
    font-weight: 600;
  }
}
.table {
  width: 580px;
  margin-left: 5%;
}
</style>
