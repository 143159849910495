<template>
  <el-input
    v-model="realValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :size="size"
    :prefix-icon="prefixIcon"
    :suffix-icon="suffixIcon"
    :clearable="clearable"
    @blur="onBlur"
    @focus="onFocus"
    @change="onChange"
    @input="onInput"
    @clear="onClear"
  >
    <template slot="prefix">
      <slot name="prefix"></slot>
    </template>
    <template slot="suffix">
      <slot name="suffix"></slot>
    </template>
    <template slot="prepend">
      <slot name="prepend"></slot>
    </template>
    <template slot="append">
      <slot name="append"></slot>
    </template>
  </el-input>
</template>

<script>
export default {
  name: 'numberInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    // 占位符
    placeholder: {
      type: String,
      default: '',
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 前 图标
    prefixIcon: {
      type: String,
      default: '',
    },
    // 前 图标
    size: {
      type: String,
      default: 'small',
    },
    // 后 图标
    suffixIcon: {
      type: String,
      default: '',
    },
    // 是否启用清空图标
    clearable: {
      type: Boolean,
      default: true,
    },
    // 正则
    // regexp: {
    //   type: RegExp,
    //   default: /[^\d.]/g,
    // },
    // 是否可以为负数
    negativeNumber: {
      type: Boolean,
      default: false,
    },
    // 是否可以为0
    enabledZero: {
      type: Boolean,
      default: true,
    },
    // 是否可以为小数
    enabledDecimals: {
      type: Boolean,
      default: true,
    },
    // 小数位最长位数
    decimalDigits: {
      type: [Number, String],
    },
    // 保留几位小数
    fixedLength: {
      type: [Number, String],
      default: 0,
    },
    // 最大值
    max: {
      type: Number,
    },
    // 最小值
    min: {
      type: Number,
    },
    // 是否允许空字符串
    nullable: {
      type: Boolean,
      default: true,
    },
    //最大长度
    maxlength: {
      type: Number,
    },
    //最小长度
    minlength: {
      type: Number,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.realValue = val;
      },
    },
  },
  data() {
    return {
      realValue: '',
    };
  },
  mounted() {
    this.realValue = this.verify(this.value ?? '');
  },
  methods: {
    /**
     * 输入事件
     */
    onInput(value) {
      this.realValue = this.verify(value);
      this.$emit('input', this.realValue);
    },
    /**
     * change事件
     */
    onChange(value) {
      this.realValue = this.verify(value);
      this.$emit('change', this.realValue);
    },
    /**
     * blur事件
     */
    onBlur() {
      // 如果最后一位是点
      if ((this.realValue + '').at(-1) === '.') {
        this.realValue = this.realValue.replace('.', '');
      }

      // 如果不允许空值
      if (!this.nullable && this.realValue === '') {
        this.realValue = this.min ?? 0;
      }
      // 如果小于最小值
      if (!this.min !== undefined && this.realValue < this.min) {
        this.realValue = this.min;
      }

      // 如果保留小数位
      if (this.fixedLength) {
        this.realValue = (+this.realValue).toFixed(this.fixedLength);
      }
      this.$emit('input', this.realValue);
      this.$emit('blur', this.realValue);
    },
    /**
     * focus事件
     */
    onFocus() {
      this.$emit('focus', this.realValue);
    },
    /**
     * onClear事件
     */
    onClear() {
      this.$emit('clear', this.realValue);
    },
    /**
     * 校验
     */
    verify(v) {
      let val;
      if (typeof v === 'string') {
        val = v;
      } else if (typeof v === 'number') {
        val = '' + v;
      }
      // 1.只保留数字和小数点
      val = val.replace(/[^\d.]/g, '');
      // 2.第一位必须为数字而不是.
      val = val.replace(/^\.+/g, '');

      // 如果不允许小数
      if (!this.enabledDecimals) {
        val = val.replace(/\./g, '');
        if (val.startsWith('0') && String(val).length > 1) {
          val = val.substring(1);
        }
      } else {
        // 3. 0开头后必须是.
        if (
          val.startsWith('0') &&
          String(val).length > 1 &&
          String(val)[1] != '.'
        ) {
          val = String(val).split('');
          val.splice(1, 0, '.');
          val = val.join('');
          // safari 不兼容此正则
          // val = val.replace(/(?<=(^0|^-0))[^.].*/g, '');
        }
        // 限制小数位数
        if (
          this.decimalDigits &&
          val.includes('.') &&
          (val + '').split('.')[1]?.length > this.decimalDigits
        ) {
          val = [
            (val + '').split('.')[0],
            (val + '').split('.')[1].slice(0, this.decimalDigits),
          ].join('.');
        }
      }

      // 4.去掉除第一位的小数点
      var firstDot = val.indexOf('.');
      if (firstDot > -1) {
        var arr = val.split('.');
        val = arr[0] + '.' + arr.slice(1).join('');
      }

      // 最大最小值
      if (this.max && val > this.max) {
        val = this.max;
      }

      // 如果不允许为0
      if (!this.enabledZero && +this.realValue === 0) {
        val = '';
      }

      //限制最大输入长度
      if (this.maxlength && val.length > this.maxlength) {
        val = val.slice(0, this.maxlength);
      }
      //限制最小输入长度
      if (this.minlength && val.length > this.minlength) {
        val = val.slice(0, this.minlength);
      }
      return val;
    },
  },
};
</script>
