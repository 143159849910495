<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="注册门店">
              <operateCompanyCascader
                v-model="form.operateCompanyIds"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="车队">
              <el-input
                v-model="form.clientName"
                placeholder="请输入车队名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input
                v-model="form.userName"
                placeholder="请输入姓名"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="el-form-item__label pointer"></div>
            <div class="flex">
              <el-button
                v-auth="648"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="手机号">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="721"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="200" label="注册门店" prop="companyName">
        </el-table-column>
        <el-table-column width="200" label="姓名" prop="userName">
        </el-table-column>
        <el-table-column width="200" label="手机号" prop="phone">
        </el-table-column>
        <el-table-column width="200" label="车牌号" prop="carNumber">
        </el-table-column>
        <el-table-column width="162" label="车队" prop="clientName">
        </el-table-column>
        <el-table-column width="200" label="注册时间" prop="dateCreated">
        </el-table-column>
      </el-table>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
// 接口
import { downloadFile } from '@/utils/fileApi';
import { mapState } from 'vuex';
import {
  miniSenderOfRepairList,
  miniSenderOfRepairListExportUrl,
} from '@/api/carBill/miniProgramBooking';
import { hasAuth } from '@/utils/permissions';

export default {
  name: 'miniProgramBookingRegisterList',
  components: {
    pagination,
    operateCompanyCascader,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      exportUrl: miniSenderOfRepairListExportUrl,

      form: {},
      // 展示更多筛选
      showMore: true,
      listData: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.getData();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(720);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {};

      var data = Object.assign({}, this.form, this.pageOption, times);
      miniSenderOfRepairList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, this.form, {}),
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        operateCompanyIds: [this.companyId],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
