import http from '@/service/http';
import store from '@/store';

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 品名列表
 */
export function getProductNameList(data, config = {}) {
  return http.post('/admin/productName/list', data, config);
}

/**
 * 品名新增
 */
export function addProductName(data) {
  return http.post('/admin/productName/add', data);
}

/**
 * 品名修改
 */
export function updateProductName(data) {
  return http.post('/admin/productName/update', data);
}

/**
 * 商品列表
 */
export function getGoodsList(data) {
  return http.post('/admin/good/findByPage', data);
}
/**
 * 根据关键字查询商品信息
 */
export function searchGoodsByKeyword(data) {
  return http.post('/admin/good/searchGoodsByKeyword', data);
}

// 商品导出
export const goodsExportUrl = '/admin/good/findByPage/export';

/**
 * 商品新增
 */
export function addGoods(data) {
  return http.post('/admin/good/add', data);
}

/**
 * 商品编辑
 */
export function updateGoods(data) {
  return http.post('/admin/good/update', data);
}

/**
 * 商品详情
 */
export function getGoodsDetail(data) {
  return http.post('/admin/good/details', data);
}

/**
 * 共享商品分类
 */
export function getShareGoodsClasses() {
  return http.post('/admin/good/share/getCategory', {});
}
