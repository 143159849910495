<template>
  <div style="height: 600px">
    <p>预览图</p>
    <!-- 表格 -->
    <div
      ref="printDom"
      id="tableId"
      style="width: 800px; padding: 12px; background: white"
    >
      <!-- 设置循环出来的数据 -->
      <div
        ref="print"
        style="page-break-after: always; position: relative"
        v-for="(item, index) in tableList"
        :key="index"
        class="settlement-page"
      >
        <div style="display: flex">
          <div>
            <img
              v-if="config.leftImagePath"
              :src="config.leftImagePath"
              style="min-width: 75px; max-width: 200px; height: 75px"
            />
            <img
              v-if="config.rightImagePath"
              :src="config.rightImagePath"
              style="min-width: 75px; max-width: 200px; height: 75px"
            />
          </div>
          <div style="margin-left: 10%; height: 75px">
            <h1 style="font-size: 26px; text-align: center">
              {{ item.operateCompanyName }}
            </h1>
            <p style="font-size: 18px; text-align: center">
              {{ config.title }}
            </p>
          </div>
        </div>
        <div
          style="
            display: flex;
            font-size: 12px;
            justify-content: flex-end;
            height: 10px;
          "
        >
          打印时间：{{ printTime }}
        </div>
        <div style="margin-top: -10px">
          <div style="display: flex; justify-content: space-between">
            <p style="font-size: 15px">
              客户：{{ item.carBillClientMessage.clientName }}
            </p>
            <p style="font-size: 15px">
              接车单号：{{ item.billMessage.billCode }}
            </p>
          </div>
          <div style="margin-top: -14px">
            <table style="font-size: 15px">
              <tbody>
                <tr>
                  <td
                    style="
                      width: 85px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    送修司机
                  </td>
                  <td
                    style="
                      width: 165px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.billMessage.customerName }}
                  </td>
                  <td
                    style="
                      width: 100px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    送修司机电话
                  </td>
                  <td
                    style="
                      width: 179px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.billMessage.customerPhone }}
                  </td>
                  <td
                    style="
                      width: 85px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    车牌号
                  </td>
                  <td
                    style="
                      width: 162px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.carBillClientMessage.carNumber }}
                  </td>
                </tr>

                <tr>
                  <td
                    style="
                      width: 85px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    车型
                  </td>
                  <td
                    style="
                      width: 165px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.carBillClientMessage.brandName }}
                  </td>
                  <td
                    style="
                      width: 90px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    车架号
                  </td>
                  <td
                    style="
                      width: 179px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.carBillClientMessage.uniqueId }}
                  </td>
                  <td
                    style="
                      width: 85px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    发动机号
                  </td>
                  <td
                    style="
                      width: 162px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.carBillClientMessage.engineNumber }}
                  </td>
                </tr>

                <tr>
                  <td
                    style="
                      width: 85px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    接车员
                  </td>
                  <td
                    style="
                      width: 165px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.billMessage.pickUpUserName }}
                  </td>
                  <td
                    style="
                      width: 90px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    进店时间
                  </td>
                  <td
                    style="
                      width: 179px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.billMessage.dateCreated }}
                  </td>
                  <td
                    style="
                      width: 85px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    进店里程
                  </td>
                  <td
                    style="
                      width: 162px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{
                      item.billMessage.mileage
                        ? item.billMessage.mileage + 'km'
                        : ''
                    }}
                  </td>
                </tr>

                <tr>
                  <td
                    style="
                      width: 85px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    结账收银员
                  </td>
                  <td
                    style="
                      width: 165px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ item.clientOtherIncomeStyle.cashier }}
                  </td>
                  <td
                    style="
                      width: 90px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    结账时间
                  </td>
                  <td
                    style="text-align: left; border: 1px solid #000 !important"
                  >
                    {{ item.clientOtherIncomeStyle.checkDate }}
                  </td>
                  <td
                    style="
                      width: 90px;
                      text-align: left;
                      border: 1px solid #000 !important;
                    "
                  >
                    提车时间
                  </td>
                  <td
                    style="text-align: left; border: 1px solid #000 !important"
                  >
                    {{
                      item.billMessage.takeTime || item.billMessage.checkDate
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style="width: 100%; margin-top: 10px"
            v-if="item.serviceList && item.serviceList.length"
          >
            <h4 style="width: 100%; text-align: center; font-weight: 600">
              服务明细
            </h4>
            <table style="width: 100%; margin-top: -20px; font-size: 15px">
              <thead>
                <tr>
                  <th
                    style="
                      width: 40px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    序号
                  </th>
                  <th
                    style="
                      min-width: 130px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    服务名称
                  </th>
                  <th
                    style="
                      width: 65px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    工时
                  </th>
                  <th
                    style="
                      width: 70px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    单价
                  </th>
                  <th
                    style="
                      width: 40px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    折扣
                  </th>
                  <th
                    style="
                      width: 80px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    小计
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in item.serviceList" :key="index">
                  <td
                    style="
                      text-align: center;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    style="text-align: left; border: 1px solid #000 !important"
                  >
                    {{ item.serviceItemName }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.num }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.price | toThousands }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.discount ? item.discount : '无' }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.total | toThousands }}
                  </td>
                </tr>
                <tr
                  style="text-align: right; border: 1px solid #000 !important"
                >
                  <td :colspan="6" style="padding: 4px; text-align: right">
                    <b
                      style="
                        font-weight: 600;
                        text-right: right;
                        font-size: 15px;
                      "
                      >折前服务费合计：{{
                        item.beforeTotalServicePrice | toThousands
                      }}&nbsp;</b
                    >
                    <b
                      style="
                        font-weight: 600;
                        text-right: right;
                        font-size: 15px;
                      "
                      >折后服务费合计：{{
                        item.afterTotalServicePrice | toThousands
                      }}</b
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="item.goodsList && item.goodsList.length">
            <h4
              ref="materialTitle"
              style="width: 100%; text-align: center; font-weight: 600"
            >
              材料明细
            </h4>
            <table ref="materialTable" style="width: 100%; margin-top: -20px">
              <thead>
                <tr ref="goodTr">
                  <th
                    style="
                      width: 40px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    序号
                  </th>
                  <th
                    style="
                      min-width: 130px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    商品名称
                  </th>
                  <th
                    style="
                      width: 65px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    数量
                  </th>
                  <th
                    style="
                      width: 70px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    单价
                  </th>
                  <th
                    style="
                      width: 40px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    折扣
                  </th>
                  <th
                    style="
                      width: 80px;
                      text-align: center;
                      border: 1px solid #000 !important;
                      font-size: 15px;
                      font-weight: 0;
                    "
                  >
                    小计
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in item.goodsList" :key="index">
                  <td
                    style="
                      text-align: center;
                      border: 1px solid #000 !important;
                    "
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    style="text-align: left; border: 1px solid #000 !important"
                  >
                    {{ item.name }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.num }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.price | toThousands }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.discount ? item.discount : '无' }}
                  </td>
                  <td
                    style="text-align: right; border: 1px solid #000 !important"
                  >
                    {{ item.total | toThousands }}
                  </td>
                </tr>
                <tr
                  style="text-align: right; border: 1px solid #000 !important"
                >
                  <td :colspan="6" style="padding: 4px; text-align: right">
                    <b
                      style="
                        font-weight: 600;
                        text-right: right;
                        font-size: 15px;
                      "
                      >折前材料费合计：{{
                        item.beforeTotalGoodsPrice | toThousands
                      }}&nbsp;</b
                    >
                    <b
                      style="
                        font-weight: 600;
                        text-right: right;
                        font-size: 15px;
                      "
                      >折后材料费合计：{{
                        item.afterTotalGoodsPrice | toThousands
                      }}</b
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style="display: flex; justify-content: space-between">
            <div style="width: 45%">
              <h4
                ref="receivableTitle"
                style="
                  display: flex;
                  justify-content: center;
                  margin: 7px 0 1px;
                  font-weight: 600;
                "
              >
                应收明细
              </h4>
              <table ref="receivableTable" style="font-size: 15px">
                <thead>
                  <tr>
                    <th
                      style="
                        width: 40px;
                        text-align: center;
                        border: 1px solid #000 !important;
                        font-size: 15px;
                        font-weight: 0;
                      "
                    >
                      序号
                    </th>
                    <th
                      style="
                        min-width: 180px;
                        text-align: center;
                        border: 1px solid #000 !important;
                        font-size: 15px;
                        font-weight: 0;
                      "
                    >
                      名称
                    </th>
                    <th
                      style="
                        width: 74px;
                        text-align: center;
                        border: 1px solid #000 !important;
                        font-size: 15px;
                        font-weight: 0;
                      "
                    >
                      金额
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        border: 1px solid #000 !important;
                      "
                    >
                      1
                    </td>
                    <td
                      style="
                        text-align: left;
                        border: 1px solid #000 !important;
                      "
                    >
                      工时费
                    </td>
                    <td
                      style="
                        text-align: right;
                        border: 1px solid #000 !important;
                      "
                    >
                      {{ item.serviceTotalPrice | toThousands }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        border: 1px solid #000 !important;
                      "
                    >
                      2
                    </td>
                    <td
                      style="
                        text-align: left;
                        border: 1px solid #000 !important;
                      "
                    >
                      材料费
                    </td>
                    <td
                      style="
                        text-align: right;
                        border: 1px solid #000 !important;
                      "
                    >
                      {{ item.goodsTotalPrice | toThousands }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        border: 1px solid #000 !important;
                      "
                    >
                      3
                    </td>
                    <td
                      style="
                        text-align: left;
                        border: 1px solid #000 !important;
                      "
                    >
                      优惠金额
                    </td>
                    <td
                      style="
                        text-align: right;
                        border: 1px solid #000 !important;
                      "
                    >
                      -{{ item.discountAmount | toThousands }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      :colspan="3"
                      style="
                        text-align: right;
                        font-weight: 600;
                        border: 1px solid #000 !important;
                        font-size: 15px;
                      "
                    >
                      应收合计(大写): {{ item.needPay | toThousands }} ({{
                        item.needPay | amountToChinese
                      }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="border-bottom: none; width: 45%">
              <h4
                ref="chargeTitle"
                style="
                  display: flex;
                  justify-content: center;
                  margin: 7px 0 1px;
                  font-weight: 600;
                "
              >
                收费结算
              </h4>

              <table ref="chargeTable" style="font-size: 15px">
                <tbody>
                  <tr>
                    <th
                      style="
                        width: 40px;
                        text-align: center;
                        border: 1px solid #000 !important;
                        font-size: 15px;
                        font-weight: 0;
                      "
                    >
                      序号
                    </th>
                    <th
                      style="
                        min-width: 180px;
                        text-align: center;
                        border: 1px solid #000 !important;
                        font-size: 15px;
                        font-weight: 0;
                      "
                    >
                      支付方式
                    </th>
                    <th
                      style="
                        text-align: center;
                        width: 110px;
                        border: 1px solid #000 !important;
                        font-size: 15px;
                        font-weight: 0;
                      "
                    >
                      金额
                    </th>
                  </tr>
                  <tr v-for="(item, index) in item.paidList" :key="index">
                    <td
                      style="
                        text-align: center;
                        border: 1px solid #000 !important;
                      "
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      style="
                        text-align: left;
                        border: 1px solid #000 !important;
                      "
                    >
                      {{ item.payType }}
                    </td>
                    <td
                      style="
                        text-align: right;
                        border: 1px solid #000 !important;
                      "
                    >
                      {{ item.money | toThousands }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      :colspan="3"
                      style="
                        text-align: right;
                        font-weight: 600;
                        border: 1px solid rgb(0, 0, 0) !important;
                        font-size: 15px;
                      "
                    >
                      实收合计(大写): {{ item.paidTotal | toThousands }} ({{
                        item.paidTotal | amountToChinese
                      }})
                    </td>
                  </tr>
                  <tr>
                    <td
                      :colspan="3"
                      style="
                        text-align: right;
                        font-weight: 600;
                        border: 1px solid rgb(0, 0, 0) !important;
                        font-size: 15px;
                      "
                    >
                      欠收合计(大写):
                      {{ item.needPaidTotal | toThousands }} ({{
                        item.needPaidTotal | amountToChinese
                      }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            ref="signature"
            style="
              display: flex;
              padding: 20px 0;
              border-bottom: 1px solid #000 !important;
            "
          >
            <b style="width: 60%">服务顾问签名：</b>
            <b style="width: 40%">客户签名：</b>
          </div>
        </div>
        <div
          class="address"
          style="
            margin-top: 10px;
            flex-wrap: wrap;
            display: flex;
            position: relative;
          "
        >
          <div style="width: 60%; font-size: 14px">
            联系地址：{{ item.companyAddress }}
          </div>
          <div style="font-size: 14px">
            联系电话：{{ item.telephone }}
            <span style="margin-left: 30px">{{
              item.alternativeTelephone
            }}</span>
          </div>
          <!-- <div style="position: absolute; left: 30px; top: -140px">
              <img
                v-if="item.base64"
                style="width: 300px; height: 300px; z-index: -1"
                :src="item.base64"
              />
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getLodop from '@/utils/plugins/LodopFuncs';

export default {
  name: 'printTemplate',
  props: {
    businessSeal: {
      type: [Number, String],
      default: '',
    },
    invoiceName: {
      type: String,
      default: '',
    },
    carBillData: {
      type: Array,
      default: () => [],
    },
    printPageCurr: Number, // 当前页码
    printPageSize: Number, // 一页条数
    count: Number, // 打印总条数
  },
  data() {
    return {
      //表数据
      tableList: [
        // {
        //   carBillClientMessage: {
        //     clientName: '李四', //客户姓名
        //     carNumber: '粤A.14562', //车牌号
        //     brandName: '奔驰', //车型
        //     uniqueId: 'UI5145244', //车架号
        //     engineNumber: '2456', //发动机号
        //   },
        //   billMessage: {
        //     billCode: 'JS51265778232212', //车单号
        //     customerName: '张三', //送修人姓名
        //     customerPhone: '52415122', //送修人电话
        //     pickUpUserName: '李勇', //接车员姓名
        //     dateCreated: '2022-05-22', //接待开单时间-进店时间
        //     mileage: '130', //提车时间
        //     checkDate: '2022-10-20', //提车时间
        //     invoice: 'xxx有限公司', //开票主体
        //   },
        //   //结账信息
        //   clientOtherIncomeStyle: {
        //     cashier: '小明', //结账收款人
        //     checkDate: '2022-10-01', //结账时间
        //   },
        //   //服务
        //   serviceList: [
        //     {
        //       serviceItemName: '保养',
        //       num: 10,
        //       price: 100,
        //       discount: 0.9,
        //       total: 1000,
        //     },
        //     {
        //       serviceItemName: '洗车',
        //       num: 20,
        //       price: 90,
        //       discount: 0.9,
        //       total: 600,
        //     },
        //   ],
        //   // 折前服务合计金额
        //   beforeTotalServicePrice: 20000,
        //   // 折后服务合计金额
        //   afterTotalServicePrice: 15000,
        //   //商品
        //   goodsList: [
        //     {
        //       name: '刹车',
        //       num: 60,
        //       price: 300,
        //       discount: 0.8,
        //       total: 18000,
        //     },
        //     {
        //       name: '罗盘',
        //       num: 30,
        //       price: 900,
        //       discount: 0.5,
        //       total: 2001,
        //     },
        //   ],
        //   cashier: '收款人',
        //   serviceTotalPrice: 800,
        //   goodsTotalPrice: 600,
        //   discountAmount: 900,
        //   needPay: 0.8,
        //   paidList: [
        //     {
        //       payType: '现金',
        //       money: 10000,
        //       paidTotal: 10000,
        //     },
        //     {
        //       payType: '微信',
        //       money: 220,
        //       paidTotal: 350,
        //     },
        //   ],
        //   paidTotal: 10000,
        //   needPaidTotal: 20000,
        //   beforeTotalGoodsPrice: 8000,
        //   afterTotalGoodsPrice: 5000,
        // },
      ],
      printTime: new Date().Format('yyyy-MM-dd hh:mm'),
      config: {
        title: '出厂结算单',
        leftImagePath: require('@/assets/slogan.png'),
      },
    };
  },
  created() {
    this.printTime = new Date().Format('yyyy-MM-dd hh:mm');
  },
  watch: {
    carBillData() {
      //处理数据
      var list = this.$lodash.cloneDeep(this.carBillData).map((item) => ({
        companyAddress: item.companyAddress,
        telephone: item.telephone,
        alternativeTelephone: item.alternativeTelephone,
        operateCompanyName: item.operateCompanyName,
        //车单与车辆信息
        carBillClientMessage: {
          clientName: item.carBillClientMessage.clientName || '', //客户姓名
          carNumber: item.carBillClientMessage.carNumber || '', //车牌号
          brandName: item.carBillClientMessage.brandName || '', //车型
          uniqueId: item.carBillClientMessage.uniqueId || '', //车架号
          engineNumber: item.carBillClientMessage.engineNumber || '', //发动机号
        },
        //车单信息
        billMessage: {
          billCode: item.billMessage.billCode || '', //车单号
          customerName: item.billMessage.customerName || '', //送修人姓名
          customerPhone: item.billMessage.customerPhone || '', //送修人电话
          pickUpUserName: item.billMessage.pickUpUserName || '', //接车员姓名
          dateCreated: item.billMessage.dateCreated || '', //接待开单时间-进店时间
          mileage: item.billMessage.mileage || '', //进店里程
          takeTime: item.billMessage.takeTime || '', //提车时间
          checkDate: item.billMessage.checkDate || '', //提车时间
          invoice: item.billMessage.invoice || '', //开票主体
        },
        //结账信息
        clientOtherIncomeStyle: {
          cashier: item?.clientOtherIncomeStyle?.cashier || '', //结账收款人
          checkDate: item?.clientOtherIncomeStyle?.checkDate || '', //结账时间
        },
        //收费结算-支付方式
        paidList: item?.clientOtherIncomeStyle?.paid || [],
        //服务
        serviceList: (item.serviceItems || []).filter((s) => s.serviceItemId),
        // 折前服务合计金额
        beforeTotalServicePrice: item.serviceItems.reduce(
          (prev, item) => prev + (item.num * item.price || 0),
          0
        ),
        // 折后服务合计金额
        afterTotalServicePrice: item.serviceItems.reduce(
          (prev, item) => prev + (+item.total || 0),

          0
        ),
        //商品
        goodsList: (item.serviceItems || [])
          .filter((s) => s.serviceItemId)
          .reduce((prev, s) => {
            if (s.goodsInfoVos?.length) {
              return prev.concat(
                s.goodsInfoVos.map((g) => ({
                  name: g.goodsName,
                  code: g.goodsCode,
                  num: g.num,
                  price: g.price,
                  discount: g.discount,
                  total: g.total,
                }))
              );
            } else {
              return prev;
            }
          }, []),
        //应收明细-材料费
        goodsTotalPrice: item.serviceItems.forEach((service) => {
          if (service.goodsInfoVos?.length) {
            service.goodsInfoVos.forEach((item) => {
              (prev, item) => prev + (+item.total || 0), 0;
            });
          }
        }),
        //应收明细-工时费
        serviceTotalPrice: item.serviceItems.reduce(
          (prev, item) => prev + (item.total || 0),
          0
        ),
        //应收明细-优惠金额
        discountAmount: item.total - item.paid,
        //应收明细-应收合计
        needPay: [0, '', undefined].includes(item.discount)
          ? item.total
          : item.paid,

        //收费结算中实收合计
        paidTotal: '',
        needPaidTotal: '',
        paidTotalList:
          item?.clientOtherIncomeStyle?.paid?.filter(
            (item) => item.payType !== '挂账'
          ) || [],
        needPaidTotalList:
          item?.clientOtherIncomeStyle?.paid?.filter(
            (item) => item.payType === '挂账'
          ) || [],
        //折前材料合计
        beforeTotalGoodsPrice: '',
        // 折后商品合计金额
        afterTotalGoodsPrice: '',
      }));

      this.tableList = list.map((item) => ({
        companyAddress: item.companyAddress,
        telephone: item.telephone,
        alternativeTelephone: item.alternativeTelephone,
        operateCompanyName: item.operateCompanyName,
        //车单与车辆信息
        carBillClientMessage: item.carBillClientMessage,
        //车单信息
        billMessage: item.billMessage,
        //结账信息
        clientOtherIncomeStyle: item.clientOtherIncomeStyle,
        //收费结算-支付方式
        paidList: item.paidList,
        //服务
        serviceList: item.serviceList,
        // 折前服务合计金额
        beforeTotalServicePrice: item.beforeTotalServicePrice,
        // 折后服务合计金额
        afterTotalServicePrice: item.afterTotalServicePrice,
        //商品
        goodsList: item.goodsList,
        //应收明细-材料费
        goodsTotalPrice: item.goodsList.reduce(
          (prev, item) => prev + (item.total || 0),
          0
        ),
        //应收明细-工时费
        serviceTotalPrice: item.serviceTotalPrice,
        //应收明细-优惠金额
        discountAmount: item.discountAmount,
        //应收明细-应收合计
        needPay: item.needPay,

        //折前材料合计
        beforeTotalGoodsPrice: item.goodsList.reduce(
          (prev, item) => prev + (item.num * item.price || 0),
          0
        ),
        // 折后商品合计金额
        afterTotalGoodsPrice: item.goodsList.reduce(
          (prev, item) => prev + (+item.total || 0),
          0
        ),
        //收费结算中实收合计
        paidTotal: item.paidTotalList.reduce(
          (prev, item) => prev + (item.money || 0),
          0
        ),
        //收费结算中欠收合计
        needPaidTotal: item.needPaidTotalList.reduce(
          (prev, item) => prev + (item.money || 0),
          0
        ),
      }));
      this.changeBusinessSeal(this.businessSeal, this.invoiceName);
      this.$nextTick().then(() => {
        this.printReport();
      });
    },
    // businessSeal() {
    //   this.changeBusinessSeal(this.businessSeal, this.invoiceName);
    // },
  },
  mounted() {},
  methods: {
    // 打印报表
    printReport() {
      const LODOP = getLodop();
      if (LODOP) {
        var strBodyStyle = '<style>';
        strBodyStyle +=
          'table { border-top: 1 solid #000000; border-left: 1 solid #000000; border-collapse:collapse; border-spacing:0;}';
        strBodyStyle += 'caption { line-height:2em; }';
        strBodyStyle +=
          'td { border-right: 1 solid #000000; border-bottom: 1 solid #000000; text-align:center; padding:2px 3px; font-size:11px;}';
        strBodyStyle += '</style>'; //设置打印样式
        // LODOP.SET_LICENSES(
        //   '',
        //   'EE0887D00FCC7D29375A695F728489A6',
        //   'C94CEE276DB2187AE6B65D56B3FC2848',
        //   ''
        // ); //此语句能去掉打印时试用版的水印
        // LODOP.SET_SHOW_MODE('LANGUAGE', 0); //上面注册号还是没办法去掉水印再加上的一句

        LODOP.SET_LICENSES(
          '长飞光纤光缆股份有限公司',
          '1D94781BFAE17DEDC50B3A807E83CA12',
          '長飛光纖光纜股份有限公司',
          'A09A4B2ED4CE7F620802B3963C36E7CA'
        );
        LODOP.SET_LICENSES(
          'THIRD LICENSE',
          '',
          'YOFC Optical Fibre and Cable Joint Stock Limited Company',
          '6155EB005BA4614653D149BDC4E2E48E'
        );

        // 打印任务名称添加当前打印单据序号 01-10 091-100
        let start = (this.printPageCurr - 1) * this.printPageSize + 1 + '';
        let end = this.printPageCurr * this.printPageSize;
        if (end > this.count) end = this.count;
        end += '';

        if (end.length > start.length) {
          let difference = end.length - start.length;
          for (var c = 0; c < difference; c++) {
            start = '0' + start;
          }
        }
        LODOP.PRINT_INIT(`结算单${start}-${end}`); //初始化  给打印结算单命名
        LODOP.SET_PRINT_PAGESIZE(4, 0, 0, 'A4'); //设置纵向
        LODOP.SET_PRINT_STYLE('Stretch', 2); // 图片截取缩放模式 0--截取图片 1--扩展（可变形）缩放 2--按原图长和宽比例（不变形）缩放。缺省值是0。

        var printDomList = document.getElementsByClassName('settlement-page');
        for (var i = 0; i < this.tableList.length; i++) {
          var dom = printDomList[i];
          console.log(dom.clientHeight);
          var height = dom.clientHeight;
          if (height > 435) height += 70;
          var ele = strBodyStyle + dom.innerHTML; //获取打印内容
          // var ele = strBodyStyle + '<body>' + dom.innerHTML + '</body>'; //获取打印内容
          LODOP.NewPageA();
          LODOP.ADD_PRINT_IMAGE(
            height,
            '25%',
            '5cm',
            '5cm',
            `<img style="z-index:-1" transcolor="#FFFFFF" src="${this.tableList[i].base64}" />`
          );

          LODOP.ADD_PRINT_HTM('1%', '1%', '98%', '100%', ele);
        }
        // LODOP.PREVIEW();
        LODOP.PRINT(); // 不弹出预览，直接打印

        //获取是否打印成功
        // var self = this;
        // LODOP.On_Return = function (TaskID, Value) {
        //   console.log('打印xians:' + self.printStatus); //+ self.printStatus不成功的时候会是undefined，成功就有数字
        //   console.log('TaskID:' + TaskID);
        //   console.log('打印成功状态:' + Value); //打印成功状态 1
        //   self.printStatus = Value;
        //   if (+Value != 0) {
        //     var data = {
        //       printCode: this.printCode,
        //       billIds: this.billIds,
        //     };
        //   }
        // };
      }
    },
    /**
     * 更改盖章类型
     */
    changeBusinessSeal(type, invoiceName = '') {
      if (['0', ''].includes(type)) {
        for (var i = 0; i < this.tableList.length; i++) {
          this.$set(this.tableList[i], 'base64', '');
        }
      } else {
        if (type == 1) {
          for (var j = 0; j < this.tableList.length; j++) {
            this.$set(
              this.tableList[j],
              'base64',
              this.createSeal(this.tableList[j].billMessage.invoice)
            );
          }
        } else if (type == 2) {
          let base64 = this.createSeal(invoiceName);
          for (var k = 0; k < this.tableList.length; k++) {
            this.$set(this.tableList[k], 'base64', base64);
          }
        }
      }
    },
    createSeal(name) {
      console.log('印章内容:', name);
      var canvas = document.createElement('canvas');
      canvas.width = 800;
      canvas.height = 800;
      var context = canvas.getContext('2d');
      var text = '业务专用章';
      var companyName = name;
      // 绘制印章边框
      var width = canvas.width / 2;
      var height = canvas.height / 2;
      // var width = canvas.width;
      // var height = canvas.height ;
      context.lineWidth = 12;
      context.strokeStyle = '#f00';
      context.beginPath();
      context.arc(width, height, 300, 0, Math.PI * 2); //宽、高、半径
      context.stroke();
      //画五角星
      create5star(context, width, height, 92, 'rgba(255,0,0,0.7)', 0); //控制五角星大小
      // 绘制印章名称
      context.font = '600 68px 宋体';
      context.textBaseline = 'middle'; //设置文本的垂直对齐方式
      context.textAlign = 'center'; //设置文本的水平对对齐方式
      // context.lineWidth = 1;
      // context.strokeStyle = '#f00';
      // context.strokeText(text, width, height + 200);
      context.fillStyle = '#f00';
      context.fillText(text, width, height + 200);
      // 绘制印章单位
      context.translate(width, height); // 平移到此位置,
      context.font = '600 80px 宋体';
      var count = companyName.length; // 字数
      var angle = (4 * Math.PI) / (3 * (count - 1)); // 字间角度
      var chars = companyName.split('');
      var c;
      for (var i = 0; i < count; i++) {
        c = chars[i]; // 需要绘制的字符
        if (i == 0) {
          context.rotate((5 * Math.PI) / 6);
        } else {
          context.rotate(angle);
        }
        context.save();
        context.translate(240, 0); // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2); // 旋转90度,让字平行于x轴
        // context.strokeText(c, 0, 0); // 此点为字的中心点
        context.fillText(c, 0, 0); // 此点为字的中心点
        context.restore();
      }
      //绘制五角星
      function create5star(context, sx, sy, radius, color, rotato) {
        context.save();
        context.lineWidth = 1;
        context.fillStyle = color;
        context.translate(sx, sy); //移动坐标原点
        context.rotate(Math.PI + rotato); //旋转
        context.beginPath(); //创建路径
        var x = Math.sin(0);
        var y = Math.cos(0);
        var dig = (Math.PI / 5) * 4;
        for (var i = 0; i < 5; i++) {
          //画五角星的五条边
          x = Math.sin(i * dig);
          y = Math.cos(i * dig);
          context.lineTo(x * radius, y * radius);
        }
        context.closePath();
        // context.stroke();
        context.fill();
        context.restore();
      }
      return canvas.toDataURL('image/png', 1);
    },
  },
};
</script>
