<template>
  <!-- 挂单弹窗 -->
  <el-dialog
    :visible="visibility"
    width="40%"
    top="15%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <template slot="title">
      <p>
        <span>挂单</span>
        <i
          class="iconfont grow-icon_warn_32 orange"
          style="margin: 0px 4px"
        ></i>
        <span style="font-size: 14px; color: #999999"
          >确定挂单，接车单转为“挂单中”状态，后续可撤销挂单重新服务</span
        >
      </p>
    </template>
    <el-form size="small">
      <el-form-item label="挂单原因" required>
        <el-input
          v-model="reason"
          type="textarea"
          :rows="4"
          placeholder="请输入挂单原因（必填）"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="完工状态" required>
        <el-radio-group v-model="finishedTaskState" @change="takeStateChange">
          <el-radio :label="1">已完工</el-radio>
          <el-radio :label="0">未完工</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="提车状态" required>
        <el-radio-group v-model="takeState" :disabled="finishedTaskState === 0">
          <el-radio :label="1">已提车</el-radio>
          <el-radio :label="0">未提车</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save" size="small">确 定</el-button>
      <el-button @click="handleClose" size="small">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { getCarBillNoServiceTake } from '@/api/carBill';

export default {
  name: 'carBillSuspendDialog',

  props: {
    visibility: Boolean,
    billId: [Number, String],
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  data() {
    return {
      // 挂单原因
      reason: '',
      // 提车状态
      takeState: '',
      // 完工状态
      finishedTaskState: '',
    };
  },
  created() {},
  methods: {
    /**
     * 修改完工状态
     */
    takeStateChange(val) {
      if (!val) {
        this.takeState = 1;
      }
    },
    /**
     * 保存
     */
    save() {
      if (!this.reason) {
        return this.$message.warning('请填写挂单原因！');
      } else if (this.takeState === '') {
        return this.$message.warning('请选择提车状态！');
      } else if (this.finishedTaskState === '') {
        return this.$message.warning('请选择完工状态！');
      }

      var data = {
        billId: this.billId,
        companyId: this.companyId,
        takeState: this.takeState,
        finishedTaskState: this.finishedTaskState,
        remark: this.reason,
      };
      getCarBillNoServiceTake(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '挂单成功!',
          });
          this.$emit('save');
          this.handleClose();
        } else {
          console.error(res);
          this.$message.error('保存失败！');
        }
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
