<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  结算门店
                  <el-tooltip
                    effect="light"
                    content="门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="车牌">
              <el-input
                v-model="form.carNumber"
                placeholder="请输入车牌"
                clearable
                @input="dateRangeChange()"
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入车单号"
                @input="dateRangeChange()"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="659"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="回款主体" prop="invoicingEntity">
              <el-select
                v-model="form.invoicingEntity"
                clearable
                filterable
                placeholder="请选择回款主体"
              >
                <el-option
                  v-for="item in invoicingEntities"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="认领时间">
              <el-date-picker
                class="w100"
                v-model="form.claimDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结账时间">
              <el-date-picker
                class="w100"
                v-model="form.checkDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="提单状态">
              <el-select
                v-model="form.submitStatus"
                placeholder="请选择提单状态"
              >
                <el-option
                  v-for="item in submitStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="提单时间">
              <el-date-picker
                class="w100"
                v-model="form.submitDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="流程单号">
              <el-input
                v-model="form.trackingSeq"
                placeholder="请输入内部提单流程单号"
                @input="dateRangeChange()"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="撤销提单">
              <el-select
                v-model="form.revokeStatus"
                placeholder="请选择提单状态"
              >
                <el-option
                  v-for="item in revokeStatusEnum"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button
            size="small"
            class="btn_insert"
            style="height: 35px"
            v-auth="661"
            @click.native="batchOperation(2, '批量提单')"
            >批量提单</el-button
          >
          <span
            style="margin-left: 15px; color: red"
            v-if="showSelecedClaimAmount"
          >
            {{ selecedClaimAmount | toThousands }}</span
          >
        </div>
        <div>
          <el-button
            v-auth="660"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="60"
          prop="selection"
          align="center"
          label="选择"
        >
        </el-table-column>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <span
              v-auth="681"
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleUpdateRemark(scope.row)"
              >更新备注</span
            >
            <span
              v-auth="661"
              v-if="scope.row.hasSubmitTask === '未提单'"
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleSubmit(scope.row)"
              >提单</span
            >
          </template>
        </el-table-column>
        <el-table-column width="100" label="提单状态" prop="hasSubmitTask">
        </el-table-column>
        <el-table-column width="200" label="认领人" prop="claimOperator">
        </el-table-column>
        <el-table-column width="200" label="认领时间" prop="claimDateTime">
        </el-table-column>
        <el-table-column width="200" label="结算门店" prop="settlementCompany">
        </el-table-column>
        <el-table-column width="200" label="车单号" prop="billCode">
          <template #default="{ row }">
            <div class="blue pointer" @click="goBillDetail(row.billId)">
              {{ row.billCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="140" label="车牌" prop="carNumber">
        </el-table-column>
        <el-table-column width="140" label="业务来源" prop="billSourceTypeName">
        </el-table-column>
        <el-table-column
          width="140"
          label="已认领金额（元）"
          prop="claimAmount"
        >
        </el-table-column>
        <el-table-column width="140" label="车单金额(元)" prop="billAmount">
        </el-table-column>
        <el-table-column
          width="200"
          label="系统流水编号"
          prop="sysStatementSeqCode"
        >
        </el-table-column>
        <el-table-column width="200" label="回款主体" prop="invoicingEntity">
        </el-table-column>
        <el-table-column width="200" label="银行账号" prop="bankAccount">
        </el-table-column>
        <el-table-column width="200" label="开户行" prop="depositBank">
        </el-table-column>
        <el-table-column width="200" label="回款时间" prop="receivingDateTime">
        </el-table-column>
        <el-table-column
          width="200"
          label="付款主体"
          prop="insuranceCompanyName"
        >
        </el-table-column>
        <el-table-column width="200" label="付款账号" prop="paymentAccount">
        </el-table-column>
        <el-table-column
          min-width="200"
          label="银行摘要"
          prop="bankSummary"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column width="140" label="金额(元)" prop="amount">
        </el-table-column>
        <el-table-column width="140" label="资金流水号" prop="transactionSeq">
        </el-table-column>
        <el-table-column width="200" label="提单时间" prop="submitWorkDateTime">
        </el-table-column>
        <el-table-column
          width="200"
          label="完成确认提单时间"
          prop="confirmCompleteDateTime"
        >
        </el-table-column>
        <el-table-column
          width="140"
          label="内部结算流程单号"
          prop="internalSettTrackingSeq"
        >
        </el-table-column>
        <el-table-column
          width="140"
          label="提单备注"
          prop="remark"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <editSubmitWorkRemarkDialog
        v-if="showEditRemarkDialog"
        :visibility.sync="showEditRemarkDialog"
        :id="submitEditId"
        :edit-type="1"
        :submitRemark="submitRemark"
        @save="getData(false)"
      ></editSubmitWorkRemarkDialog>
      <editSubmitWorkDialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :ids="submitEditIds"
        :submitInternalSettTrackingSeq="submitInternalSettTrackingSeq"
        :submitRemark="submitRemark"
        @save="getData(false)"
      ></editSubmitWorkDialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
import editSubmitWorkDialog from './components/editSubmitWorkDialog.vue';
import editSubmitWorkRemarkDialog from './components/editSubmitWorkRemarkDialog.vue';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  transactionSubmitTaskList,
  transactionSubmitTaskListExportUrl,
  transactionBatchRevokeSubmitTask,
  getPreLoadSaveOrUpdateDropListData,
} from '@/api/financial/financialOver';
import { hasAuth } from '@/utils/permissions';

import { mapState } from 'vuex';

export default {
  name: 'transaction_submitwork',
  components: {
    pagination,
    operateCompanyCascader,
    editSubmitWorkDialog,
    editSubmitWorkRemarkDialog,
  },
  data() {
    return {
      showSelecedClaimAmount: false,
      selecedClaimAmount: 0,
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      startPage: 1,
      endPage: 1,
      exportUrl: transactionSubmitTaskListExportUrl,
      showEditDialog: false,
      showEditCompleteDialog: false,
      submitEditIds: [],
      showEditRemarkDialog: false,
      submitEditId: 0,
      submitInternalSettTrackingSeq: '',
      submitRemark: '',
      form: {
        submitStatus: '',
        revokeStatus: '',
        trackingSeq: '',
      },
      submitStatusEnum: [
        {
          value: '',
          desc: '全部',
        },
        {
          value: 0,
          desc: '未提单',
        },
        {
          value: 1,
          desc: '已提单',
        },
        {
          value: 2,
          desc: '完成提单',
        },
      ],
      revokeStatusEnum: [
        {
          value: '',
          desc: '全部',
        },
        {
          value: false,
          desc: '无撤销',
        },
        {
          value: true,
          desc: '有撤销',
        },
      ],
      invoicingEntities: [],
      // 展示更多筛选
      showMore: true,
      listData: [],
      selectData: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    /**
     * 查询体系内的门店列表
     */
    getInsuranceCompanyListAndInvoicingEntities() {
      const _this = this;
      getPreLoadSaveOrUpdateDropListData().then((res) => {
        const { insuranceCompanyList, invoicingEntities } = res;
        _this.insuranceCompanyList = insuranceCompanyList;
        _this.invoicingEntities = invoicingEntities;
      });
    },
    init() {
      this.getInsuranceCompanyListAndInvoicingEntities();
      this.form.operateCompanyId = [this.companyId];
      // 如果是跳转过来的，拿到门店、车单号、车牌号，直接定位到车单中，以便进行认领——如定损单页面、接车单页面
      const queryCompanyId = this.$route.query.companyId;
      if (queryCompanyId) {
        this.form.operateCompanyId = [Number(queryCompanyId)];
      }
      const queryCarNumber = this.$route.query.carNumber;
      if (queryCarNumber) {
        this.form.carNumber = queryCarNumber;
      }
      const queryBillCode = this.$route.query.billCode;
      if (queryBillCode) {
        this.form.billCode = queryBillCode;
      }
      this.getData();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    handleSelectionChange(items, row) {
      this.selectData = [];
      let _selecedClaimAmount = 0;
      items.forEach((item) => {
        this.selectData.push(item.id);
        _selecedClaimAmount += item.claimAmount;
      });
      this.showSelecedClaimAmount = items.length > 0;
      this.selecedClaimAmount = _selecedClaimAmount;
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(659);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        claimBeginDateTime: this.form.claimDateTimes?.[0]
          ? this.form.claimDateTimes?.[0] + ' 00:00:00'
          : '',
        claimEndDateTime: this.form.claimDateTimes?.[1]
          ? this.form.claimDateTimes?.[1] + ' 23:59:59'
          : '',
        checkBeginDateTime: this.form.checkDateTimes?.[0]
          ? this.form.checkDateTimes?.[0] + ' 00:00:00'
          : '',
        checkEndDateTime: this.form.checkDateTimes?.[1]
          ? this.form.checkDateTimes?.[1] + ' 23:59:59'
          : '',
        submitDateTime: this.form.submitDateTimes?.[0]
          ? this.form.submitDateTimes?.[0] + ' 00:00:00'
          : '',
        submitEndDateTime: this.form.submitDateTimes?.[1]
          ? this.form.submitDateTimes?.[1] + ' 23:59:59'
          : '',
      };
      var data = Object.assign({}, this.form, this.pageOption, times);
      delete data.claimDateTimes;
      delete data.checkDateTimes;
      transactionSubmitTaskList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
      this.showSelecedClaimAmount = false;
      this.selecedClaimAmount = 0;
    },
    /**
     * 跳转到车单详情页面
     * @param billId 车单ID
     */
    goBillDetail(billId) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: billId,
          t: new Date().getTime(),
        },
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign(this.form, {}),
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        operateCompanyId: [this.companyId],
      };
    },
    /**
     * 撤销提单
     * @param row 行记录
     */
    handleRevokeSubmit(row) {
      this.$confirm('确定要撤销提单吗？将变为未提单状态', {
        title: '提示',
        cancelButtonText: '确定',
        confirmButtonText: '取消',
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        type: 'warning',
      }).catch((err) => {
        if (err === 'cancel') {
          const data = {
            ids: [row.id],
          };
          transactionBatchRevokeSubmitTask(data).then((res) => {
            this.getData(false);
          });
        }
      });
    },
    /**
     * 编辑提单备注
     * @param row 提单数据
     */
    handleUpdateRemark(row) {
      this.submitEditId = row.id;
      this.submitRemark = row.remark;
      this.showEditRemarkDialog = true;
    },
    /**
     * 提单
     * @param row 需要提单的记录
     */
    handleSubmit(row) {
      this.submitEditIds = [row.id];
      this.submitInternalSettTrackingSeq = row.internalSettTrackingSeq || '';
      this.submitRemark = row.remark;
      this.showEditDialog = true;
    },
    handleCompleteSubmit(row) {
      this.submitEditIds = [row.id];
      this.submitInternalSettTrackingSeq = row.internalSettTrackingSeq || '';
      this.submitRemark = row.remark || '';
      this.showEditCompleteDialog = true;
    },
    /**
     * 批量操作
     * @param {Integer} status
     * @param {String} str
     */
    batchOperation(status, str) {
      if (this.selectData.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请选择数据后操作',
        });
      }
      this.submitRemark = '';
      if (status === 2) {
        this.submitEditIds = this.selectData;
        this.showEditDialog = true;
      } else if (status === 3) {
        this.submitEditIds = this.selectData;
        this.showEditCompleteDialog = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
