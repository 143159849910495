<template>
  <!-- 内嵌抽屉 -->
  <el-drawer
    size="40%"
    :append-to-body="true"
    :visible="innerDrawer"
    :before-close="handleClose"
    wrapperClosable
    modal-append-to-body
    modal
  >
    <template slot="title">
      <div class="flex-y-center custom-dialog-title">
        <h2>{{ title }}</h2>
      </div>
    </template>
    <div class="content">
      <div ref="printDom">
        <div class="material-information">
          <div class="flex title">
            <p>技术案例</p>
            <span>{{ detailData.title }}</span>
          </div>
          <p class="info">
            供稿：{{ detailData.companyName }} {{ detailData.creatorName }}
          </p>
          <p class="info">
            审稿：{{ detailData.verifierCompany }} {{ detailData.verifierName }}
          </p>
          <p class="info" style="padding-bottom: 20px">
            供稿时间：{{ detailData.dateCreated }}
          </p>
        </div>
        <div
          class="preview-html-box not-apply-reset"
          v-html="detailData.html"
        ></div>
      </div>
    </div>
    <div class="footer">
      <!-- v-auth="590" -->
      <el-button
        v-auth="591"
        size="small"
        type="primary"
        @click="doPrint"
        style="margin-right: 10px"
        v-if="detailType != 1"
        >导出</el-button
      >
      <!-- 草稿详情 -->
      <div v-if="detailType == 1">
        <el-button size="small" type="primary" @click="materialEdit"
          >编辑</el-button
        >
        <el-button size="small" @click="promptDialog = true">删除</el-button>
      </div>
      <!--  已上架详情-->
      <div v-if="detailType == 2">
        <el-button
          size="small"
          v-auth="551"
          type="primary"
          @click="promptDialog = true"
          >下架</el-button
        >
      </div>
      <!-- 已下架详情 -->
      <div v-if="detailType == 3">
        <el-button size="small" v-auth="550" type="primary" @click="shelfEvent"
          >上架</el-button
        >
      </div>
      <!-- 不通过详情 -->
      <div v-if="detailType == 5">
        <el-button
          size="small"
          type="primary"
          v-if="showEdit"
          @click="materialEdit"
          >编辑</el-button
        >
      </div>
      <!-- 待审核详情 -->
      <div v-if="detailType == 4">
        <el-button size="small" v-auth="550" type="primary" @click="shelfEvent"
          >通过</el-button
        >
        <el-button size="small" v-auth="550" @click="failPassDialog = true"
          >不通过</el-button
        >
      </div>
    </div>
    <!-- 提示框 -->
    <el-dialog
      title="提示"
      :visible.sync="promptDialog"
      width="15%"
      top="15%"
      :before-close="handleClosePrompt"
      :close-on-click-modal="false"
      :modal="false"
    >
      <div class="prompt-sty">
        <p v-if="detailType == 1">确定删除？</p>
        <p v-if="detailType == 2">确定下架？</p>
        <p v-if="detailType == 3">确定上架？</p>
        <p v-if="detailType == 4">确定操作</p>
      </div>
      <template slot="footer">
        <div style="text-align: center">
          <el-button @click="promptDialog = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" size="small" @click="determine"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 不满足上架提示框 -->
    <el-dialog
      title="通过"
      :visible.sync="unshelfDialog"
      width="30%"
      top="15%"
      :before-close="handleCloseUnshelf"
      :close-on-click-modal="false"
      :modal="false"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="车型信息" prop="carModel">
            <el-select
              v-model="ruleForm.carModel"
              filterable
              remote
              placeholder="搜索车型"
              :remote-method="searchModel"
              :loading="searchModelLoading"
              clearable
            >
              <el-option
                v-for="item in models"
                :key="$lodash.get(item, 'series.id')"
                :value="
                  $lodash.get(
                    item,
                    'series.name',
                    $lodash.get(
                      item,
                      'brand.name',
                      $lodash.get(item, 'logo.name')
                    )
                  )
                "
                @click.native="selectCarModel(item)"
              >
                <span v-html="item.markedRedName"></span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属系统" prop="guideSysId">
            <el-select v-model="ruleForm.guideSysId" placeholder="请选择">
              <el-option
                v-for="type in owningSystem"
                :key="type.value"
                :value="type.sysId"
                :label="type.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <div>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')"
            >上架</el-button
          >
          <el-button @click="materialEdit" size="small">编辑素材</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 不通过 -->
    <el-dialog
      title="不通过原因"
      :visible.sync="failPassDialog"
      width="30%"
      top="15%"
      :before-close="handleCloseFail"
      :close-on-click-modal="false"
      :modal="false"
    >
      <el-input
        type="textarea"
        placeholder="请输入不通过原因"
        v-model="failTextarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        maxlength="50"
        show-word-limit
      >
      </el-input>
      <template slot="footer">
        <div>
          <el-button size="small" @click="failPassDialog = false"
            >取消</el-button
          >
          <el-button size="small" type="primary" @click="submitFail"
            >确定</el-button
          >
        </div>
      </template>
    </el-dialog>
  </el-drawer>
</template>
<script>
// 接口
import {
  getMaterialInfo,
  getWzGuideSystemList,
  deleteMaterial,
  addAndEditMaterial,
  newSearchCarModel,
} from '@/api/technologyCenter';
// 工具
import { mapState } from 'vuex';
import { Print } from '@/utils/print';
export default {
  name: 'draftDrawer',
  props: {
    //弹窗可见性
    innerDrawer: { type: Boolean, default: false },
    // 详情提示
    detailType: {
      type: [Number, String],
      default: '',
    },
    // 指南ID
    guideId: {
      type: [Number, String],
      default: '',
    },
  },
  components: {},
  data() {
    return {
      title: '',
      //详情数据
      detailData: {},
      //搜索车型信息列表字段
      searchModelLoading: false,
      models: [],
      //所属系统
      owningSystem: [],
      //提示弹窗可见性
      promptDialog: false,
      //不满足上架弹窗可见性
      unshelfDialog: false,
      ruleForm: {
        carModel: '',
        guideSysId: '',
      },
      rules: {
        carModel: [
          { required: true, message: '请选择车型', trigger: 'change' },
        ],
        guideSysId: [
          { required: true, message: '请选择所属系统', trigger: 'change' },
        ],
      },
      modelDetailLogoId: '',
      modelDetailBrandId: '',
      modelDetailSeriesId: '',
      failPassDialog: false,
      failTextarea: '',
      showEdit: false,
    };
  },
  computed: {
    ...mapState({
      userName: (state) => state.user.userInfo?.realName,
      companyName: (state) => state.user.companyName,
    }),
  },
  mounted() {},
  created() {
    this.getInfo();
    if (this.detailType == 1) {
      this.title = '素材详情-草稿';
    } else if (this.detailType == 2) {
      this.title = '素材详情-已上架';
    } else if (this.detailType == 3) {
      this.title = '素材详情-已下架';
    } else if (this.detailType == 4) {
      this.title = '素材详情-待审核';
    } else if (this.detailType == 5) {
      this.title = '素材详情-不通过';
    }
  },
  methods: {
    //打印
    doPrint() {
      let option = {
        userName: this.userName,
        companyName: this.companyName,
        title: this.detailData.title,
        materialTitle: this.detailData.title,
        isWatermark: true,
        dateCreated: this.detailData.dateCreated,
      };
      this.$nextTick().then(() => {
        Print(this.$refs.printDom, option);
      });
    },
    //获取详情
    getInfo() {
      getMaterialInfo(this.guideId).then((res) => {
        this.detailData = res;
        this.ruleForm.carModel = res.carSeriesName;
        this.ruleForm.guideSysId = res.guideSysId;
        this.modelDetailLogoId = res.modelDetailLogoId;
        this.modelDetailBrandId = res.modelDetailBrandId;
        this.modelDetailSeriesId = res.modelDetailSeriesId;
        if (this.detailData.creatorName == this.userName) {
          this.showEdit = true;
        }
      });
    },
    //问诊系统列表
    getSysList() {
      getWzGuideSystemList().then((res) => {
        this.owningSystem = res;
      });
    },
    // 关闭抽屉
    handleClose() {
      this.$emit('update:innerDrawer', false);
    },
    handleClosePrompt() {
      this.promptDialog = false;
    },
    handleCloseUnshelf() {
      this.unshelfDialog = false;
    },
    handleCloseFail() {
      this.failPassDialog = false;
    },
    //选中车辆
    selectCarModel(val) {
      this.modelDetailLogoId = val.logo.id;
      this.modelDetailBrandId = val.brand.id;
      this.modelDetailSeriesId = val.series.id;
    },
    //远程搜索车型信息列表
    searchModel(key = '') {
      this.searchModelLoading = true;
      newSearchCarModel(key)
        .then((res) => {
          this.models = res?.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.promptDialog = true;
        } else {
          this.$message.warning('请填写必填项!');
          return false;
        }
      });
    },
    //二次弹窗确认事件
    determine() {
      if (this.detailType == 1) {
        //删除
        deleteMaterial(this.guideId).then((res) => {
          if (res.code === 200) {
            this.$message.success('操作成功!');
            this.promptDialog = false;
            this.$emit('update:innerDrawer', false);
            this.$emit('updateData');
            this.$emit('upDraft');
          }
        });
      } else if (this.detailType == 2) {
        //下架
        var data = {
          state: 4,
          guideId: this.guideId,
        };
        addAndEditMaterial(data, { onlyReturnData: false }).then((res) => {
          if (res.code === 200) {
            this.$message.success('操作成功!');
            this.promptDialog = false;
            this.$emit('update:innerDrawer', false);
            this.$emit('updateData');
          }
        });
      } else if ([3, 4].includes(this.detailType)) {
        this.unshelfDialog = true;
        // //上架
        var shelfData = {
          state: 3,
          guideId: this.guideId,
          modelDetailLogoId: this.modelDetailLogoId,
          modelDetailBrandId: this.modelDetailBrandId,
          modelDetailSeriesId: this.modelDetailSeriesId,
          guideSysId: this.ruleForm.guideSysId,
        };
        addAndEditMaterial(shelfData, { onlyReturnData: false }).then((res) => {
          if (res.code === 200) {
            this.$message.success('操作成功!');
            this.promptDialog = false;
            this.unshelfDialog = false;
            this.$emit('update:innerDrawer', false);
            this.$emit('updateData');
          }
        });
      }
    },
    shelfEvent() {
      this.getSysList();
      this.unshelfDialog = true;
    },
    // 素材编辑
    materialEdit() {
      this.$router.push({
        name: 'materialEdit',
        query: {
          id: this.guideId,
        },
      });
    },
    //不通过事件
    submitFail() {
      if (!this.failTextarea) {
        return this.$message.warning('请填写不通过原因');
      } else {
        var data = {
          state: 2,
          guideId: this.guideId,
          noPassReason: this.failTextarea,
        };
        addAndEditMaterial(data, { onlyReturnData: false }).then((res) => {});
        this.promptDialog = false;
        this.failPassDialog = false;
        this.$emit('update:innerDrawer', false);
        this.$emit('updateData');
        this.$message.success('操作成功');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
}
.prompt-sty {
  text-align: center;
  margin: 24px 0px 60px 0;
}

.watermark {
  display: none; /* 默认情况下隐藏水印 */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  font-size: 24px;
  color: lightgrey;
  z-index: -1;
  overflow: hidden;
  transform: rotate(-45deg); /* 倾斜水印 */
  transform-origin: 0 0; /* 设置倾斜的原点为左上角 */
}

.content {
  height: 90%;
  margin: 0px 25px 20px 24px;
  user-select: none;
  overflow-y: scroll;
  .material-information {
    width: 100%;
    background: #f5f7fa;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3c3d40;
    .title {
      margin: 0px 0px 12px 16px;
      padding-top: 12px;
      p {
        width: 70px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #dcdfe6;
      }
      span {
        margin: 10px 0px 0px 5px;
        color: #242526;
        font-size: 14px;
      }
    }
    .info {
      margin: 0px 0px 12px 17px;
    }
  }
}
</style>
