<template>
  <div>
    <el-dialog
      :visible="visibility"
      width="600px"
      :title="title"
      :close-on-click-modal="false"
      :before-close="handleClose"
      append-to-body
    >
      <div class="container">
        <p class="info">
          月份：{{ pageYear }}年{{ pageMonth }}月； 门店：{{ pageCompanyName }}
        </p>
        <el-table :data="tableData" border>
          <el-table-column
            label="业务类型"
            prop="label"
            width="120"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column label="责任人" width="200">
            <template #default="{ row }">
              <div v-if="edit">
                <el-select
                  v-model="row.principal"
                  size="small"
                  filterable
                  value-key="userId"
                  clearable
                  remote
                  :remote-method="searchEmployee"
                  :loading="searchEmployeeLoading"
                  @focus="searchEmployee()"
                  placeholder="请选择责任人"
                >
                  <el-option
                    v-for="item in mergeEmployeeList"
                    :key="item.userId"
                    :label="item.realName + ' / ' + item.phone"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else>
                <span>{{ row.principalObj.realName }}</span>
                <span v-if="row.principalObj.phone"> / </span>
                <span v-if="row.principalObj.phone">{{
                  row.principalObj.phone
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="营业额目标" min-width="220">
            <template #default="{ row }">
              <div v-if="row.label != '合计'">
                <div v-if="edit">
                  <number-input
                    v-model="row.target"
                    :enabledDecimals="false"
                    size="small"
                    @blur="changeTarget(row)"
                    placeholder="请输入目标值"
                  ></number-input>
                </div>
                <div v-else>{{ row.target | toThousands(2, false) }}</div>
              </div>
              <div v-else-if="totalTarget !== ''">
                {{ totalTarget | toThousands(2, false) }}
              </div>
              <div v-else>请输入各业务类型目标值</div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div slot="footer">
        <template v-if="edit">
          <el-button size="small" @click="handleClose">取 消</el-button>
          <el-button size="small" type="primary" @click="handleSave"
            >确 定</el-button
          >
        </template>
        <template v-else>
          <el-button size="small" @click="handleClose">取 消</el-button>
          <el-button
            v-auth="539"
            size="small"
            type="primary"
            @click="changeCurrentMonth"
            >修改当月指标</el-button
          >
          <el-button
            v-auth="538"
            size="small"
            type="primary"
            @click="setNextMonth"
            >设置下月目标</el-button
          >
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口
import { findUserByPage } from '@/api/commandCenter';
import {
  selectOneCompanyTurnoverTarget,
  setCompanyTurnoverTarget,
} from '@/api/commandCenter';

export default {
  name: 'setTurnoverTargetDialog',
  props: {
    visibility: { type: Boolean, default: false },
    year: { type: [String, Number], default: new Date().getFullYear() },
    month: { type: [String, Number], default: new Date().getMonth() + 1 },
    companyId: {
      type: Number,
      default: 0,
    },
    companyName: {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    lastMonth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,

      pageYear: '',
      pageMonth: '',
      pageCompanyName: '',

      tableData: [
        {
          label: '自费',
          principal: '',
          principalObj: {},
          target: '',
        },
        {
          label: '包干',
          principal: '',
          principalObj: {},
          target: '',
        },
        {
          label: '理赔',
          principal: '',
          principalObj: {},
          target: '',
        },
        {
          label: '索赔',
          principal: '',
          principalObj: {},
          target: '',
        },
        {
          label: '合计',
          principal: '',
          principalObj: {},
          target: '',
        },
      ],
      totalTarget: '',

      // 搜索员工
      employeeList: [],
      searchEmployeeLoading: false,

      // 已保存的员工列表
      savedEmployeeList: [],
    };
  },
  computed: {
    title() {
      return `${this.edit ? '设置' : '查看'}营业额目标`;
    },
    // ...mapState({
    //   companyId: (state) => state.user.userInfo.companyId,
    // }),

    mergeEmployeeList() {
      return this.$lodash.unionBy(
        [...this.employeeList, ...this.savedEmployeeList],
        'userId'
      );
    },
  },
  created() {},
  mounted() {
    this.edit = this.isEdit;
    this.pageYear = this.year;
    this.pageMonth = this.month;
    this.pageCompanyName =
      this.companyName || this.$store.state.user?.companyName;

    this.getData(this.edit, this.lastMonth);

    this.searchEmployee();
  },
  methods: {
    /**
     * 获取数据
     */
    getData(edit, lastMonth) {
      selectOneCompanyTurnoverTarget({
        companyId: this.companyId,
        statisticsPeriod: new Date(
          this.pageYear,
          this.pageMonth - (lastMonth ? 2 : 1)
        ).Format('yyyy/MM'),
      }).then((res) => {
        console.log(res);
        // 设置过
        if (![null, undefined].includes(res.totalTarget)) {
          let userArr = [];
          // 自费
          this.$set(this.tableData[0], 'target', res.selfPayTarget ?? '');
          this.$set(
            this.tableData[0],
            'principal',
            res.selfPayResponsibleId ?? ''
          );
          let principalObj1 = {
            userId: res.selfPayResponsibleId,
            realName: res.selfPayResponsibleName,
            phone: res.selfPayResponsiblePhone,
          };
          this.$set(this.tableData[0], 'principalObj', principalObj1);
          userArr.push(principalObj1);

          // 包干
          this.$set(this.tableData[1], 'target', res.contractForTarget ?? '');
          this.$set(
            this.tableData[1],
            'principal',
            res.contractForResponsibleId ?? ''
          );
          let principalObj2 = {
            userId: res.contractForResponsibleId,
            realName: res.contractForResponsibleName,
            phone: res.contractForResponsiblePhone,
          };
          this.$set(this.tableData[1], 'principalObj', principalObj2);
          userArr.push(principalObj2);

          // 理赔
          this.$set(
            this.tableData[2],
            'target',
            res.claimSettlementTarget ?? ''
          );
          this.$set(
            this.tableData[2],
            'principal',
            res.claimSettlementResponsibleId ?? ''
          );

          let principalObj3 = {
            userId: res.claimSettlementResponsibleId,
            realName: res.claimSettlementResponsibleName,
            phone: res.claimSettlementResponsiblePhone,
          };
          this.$set(this.tableData[2], 'principalObj', principalObj3);
          userArr.push(principalObj3);

          // 索赔
          this.$set(
            this.tableData[3],
            'target',
            res.claimForCompensationTarget ?? ''
          );
          this.$set(
            this.tableData[3],
            'principal',
            res.claimForCompensationResponsibleId ?? ''
          );
          let principalObj4 = {
            userId: res.claimForCompensationResponsibleId,
            realName: res.claimForCompensationResponsibleName,
            phone: res.claimForCompensationResponsiblePhone,
          };
          this.$set(this.tableData[3], 'principalObj', principalObj4);
          userArr.push(principalObj4);

          // 合计
          this.totalTarget = res.totalTarget;
          this.$set(
            this.tableData[4],
            'principal',
            res.totalResponsibleId ?? ''
          );
          let principalObj5 = {
            userId: res.totalResponsibleId,
            realName: res.totalResponsibleName,
            phone: res.totalResponsiblePhone,
          };
          this.$set(this.tableData[4], 'principalObj', principalObj5);
          userArr.push(principalObj5);

          this.savedEmployeeList = userArr.filter((item) => item.userId);
        }
        if (edit) this.edit = true;
      });
    },
    /**
     * 远程搜索门店列表
     */
    searchEmployee(keyWord) {
      console.log(keyWord);
      this.searchEmployeeLoading = true;
      findUserByPage({
        msg: keyWord,
        companyId: this.companyId,
      })
        .then((res) => {
          this.employeeList = res.records || [];
        })
        .finally(() => {
          this.searchEmployeeLoading = false;
        });
    },

    /**
     * 修改营业额目标
     */
    changeTarget() {
      this.totalTarget = this.tableData
        .slice(0, -1)
        .reduce((prev, item) => prev + Number(item.target) || 0, 0);
    },

    /**
     * 修改当前月目标
     */
    changeCurrentMonth() {
      this.edit = true;
    },

    /**
     * 设置下月目标
     */
    setNextMonth() {
      let date = new Date(this.pageYear, this.pageMonth);
      this.pageYear = date.getFullYear();
      this.pageMonth = date.getMonth() + 1;
      this.getData(true);
    },

    /**
     * 保存
     */
    handleSave() {
      let map = {
        selfPayResponsibleId: {
          title: '请选择自费责任人',
          value: this.tableData[0].principal,
        },
        selfPayTarget: {
          title: '请输入自费类型营业额目标',
          value: this.tableData[0].target,
        },
        contractForResponsibleId: {
          title: '请选择包干责任人',
          value: this.tableData[1].principal,
        },
        contractForTarget: {
          title: '请输入包干类型营业额目标',
          value: this.tableData[1].target,
        },
        claimSettlementResponsibleId: {
          title: '请选择理赔责任人',
          value: this.tableData[2].principal,
        },
        claimSettlementTarget: {
          title: '请输入理赔类型营业额目标',
          value: this.tableData[2].target,
        },
        claimForCompensationResponsibleId: {
          title: '请选择索赔责任人',
          value: this.tableData[3].principal,
        },
        claimForCompensationTarget: {
          title: '请输入索赔类型营业额目标',
          value: this.tableData[3].target,
        },
        totalResponsibleId: {
          title: '请选择总责任人',
          value: this.tableData[4].principal,
        },
      };

      // 校验
      let emptyField = Object.keys(map).find((item) =>
        ['', undefined, null].includes(map[item].value)
      );
      if (emptyField) {
        this.$message.warning(map[emptyField].title);
        return;
      }

      let part = {};
      Object.keys(map).forEach((key) => {
        part[key] = +map[key].value;
      });

      // 合并参数
      let body = Object.assign(
        {},
        {
          // 门店id
          companyId: this.companyId,
          // 统计周期(yyyy/MM)
          statisticsPeriod: new Date(this.pageYear, this.pageMonth - 1).Format(
            'yyyy/MM'
          ),
          // 总目标
          totalTarget: this.totalTarget,
        },
        part
      );
      setCompanyTurnoverTarget(body).then((res) => {
        // this.edit = false;
        this.$message.success('设置成功！');
        this.$emit('change');
        this.handleClose();
      });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  margin: 15px 0;
}
::v-deep td.el-table__cell {
  height: 57px;
}
</style>
