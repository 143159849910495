<template>
  <base-container>
    <template>
      <div class="flex">
        <div class="flex" style="width: 50%">
          <div class="customer-name">协议客户</div>
          <div class="customer-contents">
            客户名称：{{
              $lodash.get(collectionData[0], 'client.clientName', '')
            }}
          </div>
        </div>
        <div class="flex" style="width: 50%">
          <div class="customer-name">开票主体</div>
          <div class="customer-contents">
            {{ $lodash.get(collectionData[0], 'invoiceName[0]', '') }}
          </div>
        </div>
      </div>
      <div class="flex tip-word">
        <div>销账单据清单</div>
        <p
          class="blue pointer text-underline"
          style="margin-right: 60px"
          @click="selectDocumentsDrawer = true"
        >
          选择单据
        </p>
      </div>
      <el-table
        style="width: 100%"
        class="service-table"
        border
        :data="collectionData"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column width="80" label="操作">
          <template slot-scope="scope">
            <i
              style="color: #ff9200; font-size: 22px"
              class="iconfont grow-icon_delete3 pointer"
              @click="deleteRow(scope.row, scope.$index)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column width="60" label="序号" prop="title">
        </el-table-column>
        <el-table-column label="接车单号" width="200">
          <template slot-scope="scope">
            <p class="blue">{{ scope.row.billCode }}</p>
            <p>{{ scope.row.billType | dict('carBillType') }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="checkDate"
          label="结账(挂账）时间"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          prop="sourceOfBusiness"
          label="业务来源"
          min-width="100"
        >
        </el-table-column>
        <el-table-column prop="carNumber" label="车牌号" min-widthh="180">
        </el-table-column>
        <el-table-column prop="paid" label="单据金额" min-widthh="180">
        </el-table-column>
        <el-table-column prop="contractTotal" min-width="180">
          <template slot="header">
            <div class="flex">
              挂账(包干）总额
              <el-tooltip effect="light" placement="bottom-start">
                <div
                  slot="content"
                  style="
                    color: #666666;
                    font-size: 13px;
                    line-height: 16px;
                    text-indent: 15px;
                  "
                >
                  包干业务的挂账金额为包干分摊金额，<br />
                  结账当月显示为查询时点的预分摊金额，<br />
                  结账次月显示为实际分摊金额
                </div>
                <i
                  class="iconfont grow-icon_doubt"
                  style="margin-left: 5px"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="alreadyPaid" label="已收金额" min-widthh="180">
        </el-table-column>
        <el-table-column label="本次收款" min-widthh="180">
          <template #default="{ row }">
            <number-input
              v-model="row.itemCodes"
              size="small"
              :clearable="false"
              :min="1"
              decimalDigits="2"
            ></number-input>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceNum" label="开票单据号" min-width="180">
        </el-table-column>
        <el-table-column label="事故责任" min-width="180">
          <template #default="{ row }">
            {{ row.dutyType | dict('dutyType') }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="180">
        </el-table-column>
      </el-table>

      <div class="remark">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入本次收款销账备注"
          v-model="remarks"
        >
        </el-input>
      </div>
    </template>
    <template slot="footer">
      <div class="flex foot-btn">
        <div class="foot-btn-item">
          账单时间：
          <el-date-picker
            type="datetime"
            v-model="dateTime"
            placeholder="选择日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </div>
        <div class="foot-btn-item">
          支付方式：
          <el-select
            v-model="payType"
            filterable
            placeholder="请选择支付方式"
            size="small"
            value-key="payType"
          >
            <el-option
              v-for="item in payTypeList"
              :key="item.payType"
              :label="item.payTypeName"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-button class="blue pay-btn" size="small" @click="combinationPay"
            >组合支付</el-button
          >
        </div>
        <p>
          本次收款销账：<span class="orange">{{
            collectMoney | toThousands
          }}</span>
        </p>
        <div class="btn-item">
          <el-button size="small" @click="collectionDialogEvent"
            >收款销账</el-button
          >
          <el-button size="small" @click="cancel">取消</el-button>
        </div>
      </div>
    </template>
    <!-- 点击收款销账按钮的弹窗 -->
    <el-dialog
      title="温馨提示"
      :visible.sync="collectionDialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="text-align: center; font-size: 16px; color: #333">
        已确认信息无误，确定收款销账？
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="text-align: center; margin-top: 30px"
      >
        <el-button @click="collectionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCheck">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 组合支付弹窗 -->
    <el-dialog
      title="组合支付"
      :visible.sync="payDialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="other-chioce-pay">
        <div class="flex color333" style="margin-bottom: 16px">
          <div style="margin-right: 30px">
            账单时间：
            <el-date-picker
              style="width: 200px"
              size="small"
              v-model="checkTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div style="margin-top: 8px">
            应收金额：<span class="orange"
              >￥{{ receivableMoney | toThousands }}</span
            >
          </div>
        </div>
        <div class="flex color333" style="position: relative">
          <el-form :model="form" label-width="200px" size="small">
            <el-row
              v-for="(item, index) in billCheckDetails"
              v-bind:key="index"
            >
              <div class="flex" style="margin-bottom: 20px; width: 80%">
                <i
                  class="iconfont grow-icon_delete3"
                  style="
                    color: #ff9200;
                    font-size: 22px;
                    margin: 5px 15px 5px 36px;
                  "
                  @click="deletePayType(index)"
                  v-if="index > 0"
                ></i>
                <span
                  v-if="index == 0"
                  style="margin-right: 14px; margin-top: 10px"
                  >收款方式:</span
                >
                <el-col :span="8">
                  <el-select
                    v-model="item.payType"
                    filterable
                    placeholder="请选择支付方式"
                    size="small"
                    value-key="payType"
                  >
                    <el-option
                      v-for="item in payTypeOptions"
                      :key="item.payType"
                      :label="item.payTypeName"
                      :value="item"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10" :offset="1">
                  <el-input
                    placeholder=" 请输入收款金额"
                    v-model="item.price"
                    size="small"
                    :disabled="item.payType === undefined"
                    @input="changePrice(item.price, index)"
                  />
                </el-col>
              </div>
            </el-row>
            <p style="position: absolute; right: 5%; bottom: 30px">
              剩余应收：<span class="orange"
                >￥{{ remainingMoney | toThousands }}</span
              >
            </p>
          </el-form>
        </div>
        <p @click="addOtherPay()" class="pointer blue addOtherPay">
          +添加其他收款方式
        </p>
      </div>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="输入本次结账收款备注"
        v-model="billCheckRemark"
      >
      </el-input>
      <span
        slot="footer"
        class="dialog-footer btn-item"
        style="text-align: center; margin-top: 30px"
      >
        <el-button size="small" @click="conTest">收款销账</el-button>
        <el-button size="small" @click="payDialogVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择单据抽屉弹窗 -->
    <select-documents-drawer
      v-if="selectDocumentsDrawer"
      :selectDocumentsDrawer.sync="selectDocumentsDrawer"
      :times="times"
      :selectOldList="collectionData"
      @selectTableList="selectList"
    ></select-documents-drawer>
  </base-container>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import bus from '@/service/bus';

import Big from 'big.js';

//组件
import selectDocumentsDrawer from '@/views/financialManagement/accountsReceivable/selectDocumentsDrawer.vue';

//接口
import { getCarBillListPayType } from '@/api/carBill/index';
import { getFinanceRepaymentOnAccount } from '@/api/financial/financialOver';
export default {
  name: 'collectionChargeOffs',
  components: { selectDocumentsDrawer },
  data() {
    return {
      collectionData: {},
      remarks: '',
      dateTime: '',
      checkTime: '',
      payType: '',
      payTypeList: [],
      //收款销账弹窗可见性
      collectionDialogVisible: false,
      billIds: {},
      //组合支付弹窗可见性
      payDialogVisible: false,
      billCheckDetails: [],
      form: {},
      billCheckRemark: '',
      //选择单据抽屉可见性
      selectDocumentsDrawer: false,
      times: [],
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    ...mapGetters(['activeTagIndex']),
    // 所有本次要核销的的金额
    collectMoney() {
      return this.collectionData.reduce((prev, item) => {
        return prev + +item.itemCodes || 0;
      }, 0);
    },
    // 所有未结清的金额
    receivableMoney() {
      return this.collectionData.reduce((prev, item) => {
        return prev + +item.unclearedDebt || 0;
      }, 0);
    },
    // 每个支付方式累加的金额
    otherMoney() {
      return this.billCheckDetails.reduce((prev, item) => {
        return prev + +item.price || 0;
      }, 0);
    },
    //判断如果已经存在的付款方式就不能再选择了
    payTypeOptions() {
      const _this = this;
      let list = _this.payTypeList.map((item) => {
        if (_this.billCheckDetails.find((el) => el.payType === item)) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        return item;
      });
      return list;
    },
    //计算剩余应收
    remainingMoney: function () {
      return Big(this.receivableMoney).minus(
        this.billCheckDetails.reduce((prev, item) => {
          return prev + (+item.price || 0);
        }, 0)
      );
    },
  },
  activated() {
    this.collectionData = this.$route.params.tableData || this.collectionData;
    this.times = this.$route.params.times;
  },
  // beforeCreate() {
  //   this.collectionData = this.$route.params.tableData;

  // },
  created() {
    this.collectionData = this.$route.params.tableData;
    this.times = this.$route.params.times;
    this.getPayType();
    this.billCheckDetails.push({
      payType: 0,
      price: this.receivableMoney,
    });
    this.dateTime = new Date().Format('yyyy-MM-dd hh:mm:ss');
    this.checkTime = new Date().Format('yyyy-MM-dd hh:mm:ss');
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
    /**
     * 删除单据
     */
    deleteRow(row, index) {
      this.collectionData.splice(index, 1);
    },
    //获取支付方式列表
    getPayType() {
      const _this = this;
      getCarBillListPayType().then((res) => {
        _this.payTypeList = res.filter((item) => item.payType != 3);
        if (_this.payTypeList.length > 0) {
          _this.billCheckDetails = [];
          _this.billCheckDetails.push({
            payType: _this.payTypeList[0],
            price: _this.receivableMoney,
          });
        }
        return;
      });
    },
    handleClose() {
      this.collectionDialogVisible = false;
      this.payDialogVisible = false;
    },
    //收款销账按钮事件
    collectionDialogEvent() {
      this.collectionDialogVisible = true;
      // if (this.collectMoney != 0) {
      //   this.collectionDialogVisible = true;
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     message: '请先填写收款金额',
      //   });
      // }
    },
    confirmCheck() {
      if (this.payType === '') {
        return this.$message.warning('请选择支付方式');
      }
      //本次应收账款
      var data = this.collectionData.map((item) => {
        return {
          billId: item.billId,
          billCheckRemark: this.remarks,
          checkDate: this.dateTime,
          billCheckDetails: [
            {
              payType: this.payType.payType,
              customPayTypeId: this.payType?.customPayTypeId || '',
              remark: this.remarks || '',
              price: item.itemCodes || 0,
            },
          ],
        };
      });
      getFinanceRepaymentOnAccount(data).then((res) => {
        if (res.code == 200) {
          //关闭当前页面
          this.$store.commit('DEL_TAG', {
            index: this.$store.getters.activeTagIndex,
            needJump: false,
          });
          this.$router.push({
            path: '/financialManagement/receivableDetailsSecond',
          });
          bus.$emit('refreshData');
        } else {
          this.$message({
            type: 'warning',
            message: res.message,
          });
        }
      });
    },
    //组合支付按钮事件
    combinationPay() {
      //判断表格中每一项的本次收款是否不是空的
      let isHasMoney = this.collectionData.every(
        (item) => item.itemCodes != null
      );
      if (isHasMoney) {
        this.payDialogVisible = true;
      } else {
        this.$message.warning('表格中的本次收款不能为空');
      }
    },
    conTest() {
      const _this = this;
      var data = this.collectionData.map((item) => {
        item.remainingMoney = Number(item.itemCodes);
        return {
          billId: item.billId,
          billCheckRemark: _this.billCheckRemark,
          checkDate: _this.checkTime,
          billCheckDetails: _this.billCheckDetails.map((items) => {
            console.log(
              'xxxx',
              item.itemCodes,
              _this.collectMoney,
              _this.otherMoney
            );
            // 如果销账金额 小于金额， 最后的核销为0
            // 比如我要核销 5块， 但是有 转账3块， 拉卡拉 3块，有一个是核销2块的
            let tempAmount = +(
              (item.itemCodes / _this.collectMoney) *
              items.price
            ).toFixed(2);
            if (item.remainingMoney >= tempAmount) {
              item.remainingMoney = item.remainingMoney - tempAmount;
            } else {
              tempAmount = item.remainingMoney;
              item.remainingMoney = Number(0);
            }
            return {
              price: tempAmount,
              payType: items.payType.payType,
              customPayTypeId: items.payType?.customPayTypeId || '',
              remark: _this.billCheckRemark,
            };
          }),
        };
      });
      getFinanceRepaymentOnAccount(data).then((res) => {
        if (res.code == 200) {
          //关闭当前页面
          this.$store.commit('DEL_TAG', {
            index: this.$store.getters.activeTagIndex,
            needJump: false,
          });
          this.$router.push({
            path: '/financialManagement/receivableDetailsSecond',
          });
          bus.$emit('refreshData');
        }
      });
    },
    //添加其他收款方式
    addOtherPay() {
      this.billCheckDetails.push({});
    },
    //下面是支付方式必须要存在一个
    deletePayType(index) {
      if (index > 0) {
        this.billCheckDetails.splice(index, 1);
      } else {
        this.billCheckDetails[0].payType = '';
      }
    },
    //选择单据抽屉传过来的数据
    selectList(value) {
      this.collectionData = value;
    },
    //收款销账的取消按钮事件，关闭当前页面
    cancel() {
      this.closeTag({ index: this.activeTagIndex });
    },
    /**
     * 修改金额
     */
    changePrice(price, index) {
      if (this.remainingMoney < 0) {
        this.billCheckDetails[index].price = +price + +this.remainingMoney;
      }
    },
    //表格尾部自定义表格合计
    getSummaries(param) {
      //columns是每列的信息，data是每行的信息
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (['field_7', 'alreadyPaid', 'itemCodes'].includes(column.property)) {
          sums[index] = data
            .reduce((prev, curr) => {
              const value = Number(this.$lodash.get(curr, column.property, 0));
              return prev + value;
            }, 0)
            .toFixed(2);
          sums[index] += ' 元';
        }
      });

      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-name {
  width: 96px;
  height: 70px;
  background: #55b78e;
  border-radius: 4px 0px 0px 4px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 70px;
  text-align: center;
}
.customer-contents {
  width: 94%;
  height: 70px;
  background: rgba(85, 183, 142, 0.1);
  border-radius: 4px;
  line-height: 70px;
  color: #333;
  font-size: 14px;
  padding-left: 11px;
}
.tip-word {
  margin-top: 30px;
  justify-content: space-between;
  div {
    width: 156px;
    height: 46px;
    background: rgba(85, 183, 142, 0.1);
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    line-height: 46px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #55b78e;
  }
}
//设置表格中合计行颜色
::v-deep .el-table__footer-wrapper tbody td.el-table__cell {
  color: $orange !important;
}
.remark {
  border-radius: 4px;
  margin-top: 30px;
}
.foot-btn {
  justify-content: flex-end;
  padding: 10px 20px 0;
  height: 72px;
  line-height: 72px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  color: #333;
}
.foot-btn-item {
  margin-right: 30px;
}
.pay-btn {
  border-radius: 4px;
  border: 1px solid #3eb1e0;
  margin-left: 30px;
}
.btn-item {
  margin-left: 30px;
  button {
    background: $orange;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    & + button {
      background: #f0f2f5;
      border: 1px solid #d7dbe0;
      color: #333333;
    }
  }
}
.other-pay {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 10px;
}
.other-chioce-pay {
  width: 96%;
  height: 250px;
  padding: 15px 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  overflow-y: scroll;
  color: #333;
}
.addOtherPay {
  margin-left: 65px;
}
</style>
