<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div class="form-top">
        <el-form :model="form" label-width="100px" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                ></operateCompanyCascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    开票主体
                    <el-tooltip effect="light" placement="bottom-start">
                      <div
                        slot="content"
                        style="
                          color: #666666;
                          font-size: 13px;
                          line-height: 20px;
                          text-indent: 15px;
                        "
                      >
                        开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                        的回款管理门店(保险公司的开票主体也是定损主体)。保险公司<br />管理的开票主体，可查已成为保险公司开票
                        主体的全部门店。
                      </div>
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>

                <el-select
                  v-model="form.invoiceCompanyId"
                  @change="selectInvoice"
                  @clear="deleteInvoice"
                  filterable
                  clearable
                  remote
                  placeholder="搜索开票主体名称"
                  :remote-method="searchInvoice"
                  :loading="searchinvoiceLoading"
                >
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="挂账日期">
                <el-date-picker
                  class="w100"
                  v-model="form.times"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div class="el-form-item--small" style="margin-left: 20%">
                <el-button
                  class="btn_search"
                  type="primary"
                  size="small"
                  @click="query"
                  >查询</el-button
                >
                <el-button class="btn_search" size="small" @click="empty"
                  >置空</el-button
                >
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <el-form-item label="消费客户">
                <div class="last-type-sty">
                  <el-select
                    v-model="form.consumerType"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in customerList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    v-model="form.clientName"
                    placeholder="请输入客户名称"
                    clearable
                    @keyup.enter.native="query"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结余金额">
                <el-select
                  v-model="form.accountType"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in accountType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="content-container">
        <div class="btns flex-x-between" v-if="isHeadquarters">
          <div>
            <div class="custom-tabs">
              <div
                @click="status = 'status0'"
                :class="{
                  'custom-tab-item': true,
                  active: status == 'status0',
                }"
              >
                按开票主体统计
              </div>
              <div
                @click="status = 'status1'"
                :class="{
                  'custom-tab-item': true,
                  active: status == 'status1',
                }"
              >
                按操作门店统计
              </div>
              <p>
                <i
                  class="iconfont grow-icon_warn_32 orange"
                  style="vertical-align: text-top; margin-right: 4px"
                ></i
                >注：包干业务的应收账款，结账当月为查询时点的预分摊金额，结账次月为实际分摊金额。
              </p>
            </div>
          </div>
          <div>
            <el-button
              v-auth="496"
              size="small"
              type="text"
              class="blue"
              @click="showExportDialog = true"
              >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
            ></el-button>
          </div>
        </div>
        <div class="flex" style="flex: 1; overflow: auto">
          <div style="width: 85%; flex: 1; overflow: auto">
            <el-table
              class="custom-table"
              :data="tableData"
              height="100%"
              border
              :span-method="spanMethod"
              @selection-change="handleSelectionChange"
              :summary-method="getSummaries"
              show-summary
            >
              <el-table-column prop="index" label="序号" width="60">
              </el-table-column>
              <el-table-column label="消费客户" min-width="180">
                <template #default="{ row }">
                  <div v-if="row.consumer">
                    <p>
                      {{
                        [
                          '消费客户名称',
                          '消费客户名称',
                          '保险公司名称',
                          '三包厂家名称',
                        ][row.consumerType]
                      }}:{{ row.consumer.name }}
                    </p>
                    <p class="color999">{{ row.consumer.phone }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="客户类型" min-width="180">
                <template slot-scope="scope">{{
                  scope.row.consumerType | dict('consumerType')
                }}</template>
              </el-table-column>
              <el-table-column label="联系人、联系电话" min-width="180">
                <template #default="{ row }">
                  <div v-if="row.contact">
                    <p>{{ row.contact.name }}</p>
                    <p class="color999">{{ row.contact.phone }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="180">
                <template #header>
                  <div v-if="status == 'status0'">
                    <div class="flex flex-x-center">
                      开票主体
                      <el-tooltip effect="light" placement="bottom-start">
                        <div
                          slot="content"
                          style="
                            color: #666666;
                            font-size: 13px;
                            line-height: 20px;
                            text-indent: 15px;
                          "
                        >
                          开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                          的回款管理门店(保险公司的开票主体也是定损主体)。
                        </div>
                        <i
                          class="iconfont grow-icon_doubt"
                          style="margin-left: 5px"
                        ></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div v-if="status == 'status1'">
                    <div class="flex flex-x-center">操作门店</div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <p
                    v-if="status == 'status0'"
                    :class="{
                      orange: scope.row.invoices === '消费客户小计',
                    }"
                  >
                    {{ scope.row.invoices }}
                  </p>
                  <p
                    v-if="status == 'status1'"
                    :class="{
                      orange: scope.row.companyName === '消费客户小计',
                    }"
                  >
                    {{ scope.row.companyName }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="operation" label="操作" min-width="150">
                <template slot-scope="scope">
                  <span
                    class="pointer text_operation second"
                    @click="goReceivableDetails(scope.$index, scope.row)"
                    >本期应收明细</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="operationStore"
                min-width="150"
                v-if="isHeadquarters"
              >
                <template #header>
                  <div v-if="status == 'status1'">
                    <div class="flex flex-x-center">
                      开票主体
                      <el-tooltip effect="light" placement="bottom-start">
                        <div
                          slot="content"
                          style="
                            color: #666666;
                            font-size: 13px;
                            line-height: 20px;
                            text-indent: 15px;
                          "
                        >
                          开票主体，即为协议客户、保险公司和三包厂家的应收账款<br />
                          的回款管理门店(保险公司的开票主体也是定损主体)。
                        </div>
                        <i
                          class="iconfont grow-icon_doubt"
                          style="margin-left: 5px"
                        ></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div v-if="status == 'status0'">
                    <div class="flex flex-x-center">操作门店</div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <!-- <span
                    v-if="status == 'status0'"
                    class="pointer text_operation blue"
                    @click="getUpdate(scope.$index, scope.row)"
                    >统计门店（{{ scope.row.invoices.length }}）</span
                  >  因为没有统计门店返回的字段暂时注释掉 -->
                  <span
                    v-if="status == 'status0'"
                    class="pointer text_operation blue"
                    >统计门店（）</span
                  >
                  <span
                    v-if="status == 'status1'"
                    class="pointer text_operation blue"
                    >开票主体统计（{{ scope.row.invoices.length }}）</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="期初结余" min-width="100">
                <template #default="{ row }">
                  <p
                    :class="{
                      orange:
                        row.invoices === '消费客户小计' ||
                        row.companyName === '消费客户小计',
                    }"
                  >
                    {{ row.openingBalance }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="期初结余本期已收" min-width="140">
                <template #default="{ row }">
                  <p
                    :class="{
                      orange:
                        row.invoices === '消费客户小计' ||
                        row.companyName === '消费客户小计',
                    }"
                  >
                    {{ row.openingBalanceRec }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="本期应收总额" min-width="140">
                <template #default="{ row }">
                  <p
                    :class="{
                      orange:
                        row.invoices === '消费客户小计' ||
                        row.companyName === '消费客户小计',
                    }"
                  >
                    {{ row.currentAccountsReceivable }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="本期应收已收" min-width="140">
                <template #default="{ row }">
                  <p
                    :class="{
                      orange:
                        row.invoices === '消费客户小计' ||
                        row.companyName === '消费客户小计',
                    }"
                  >
                    {{ row.currentAccountsReceivableRec }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="本期结余" min-width="200">
                <template #default="{ row }">
                  <p
                    :class="{
                      orange:
                        row.invoices === '消费客户小计' ||
                        row.companyName === '消费客户小计',
                    }"
                  >
                    {{ row.currentBalance }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="期末结余" min-width="100">
                <template #default="{ row }">
                  <p
                    :class="{
                      orange:
                        row.invoices === '消费客户小计' ||
                        row.companyName === '消费客户小计',
                    }"
                  >
                    {{ row.endBalance }}
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 15%; padding-left: 30px; box-sizing: border-box">
            <el-table border :data="tableData2" style="width: 100%">
              <el-table-column prop="filder14">
                <template slot="header">
                  <div>消费客户与统计<br />（客户数）</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <el-dialog title="导出提示" :visible.sync="showExportDialog" center>
        <div class="export-dialog__body">
          <div class="export-dialog-page__input">
            <span>导出范围：第</span>
            <el-input
              type="number"
              size="small"
              v-model="startPage"
              :max="Math.ceil(total / pageOption.size)"
              :min="1"
            ></el-input>
            <span>至</span>
            <el-input
              type="number"
              size="small"
              v-model="endPage"
              :max="Math.ceil(total / pageOption.size)"
              :min="1"
            ></el-input>
          </div>
          <p class="export-dialog__tip">
            共{{
              Math.ceil(total / pageOption.size)
            }}页，一次最多导出10000条数据
          </p>
        </div>
        <div slot="footer">
          <el-button @click="showExportDialog = false">取 消</el-button>
          <el-button type="primary" @click="doExport">确 定</el-button>
        </div>
      </el-dialog>
      <template slot="footer">
        <pagination @pageChange="changePage" :total="total"></pagination>
      </template>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import { exportCusterListUrl } from '@/api/customer/customer';
import { downloadFile } from '@/utils/fileApi';
//接口
import { searchInvoice } from '@/api/storeManagement/index';
import {
  getFundsOnAccountList,
  getCompanyOnAccountList,
  exportFundsOnAccountListUrl,
  exportCompanyOnAccountListUrl,
} from '@/api/financial/financialOver';
import { mapState, mapGetters } from 'vuex';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';
// 工具
import Big from 'big.js';
export default {
  name: 'receivableSummaryFirst',
  components: { pagination, operateCompanyCascader },
  data() {
    return {
      form: {
        times: [],
      },
      //导出的页面设置
      startPage: 1,
      endPage: 1,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      status: 'status0',
      tableData: [],
      showMore: false,
      tableData2: [],
      //搜索开票主体门店字段
      searchinvoiceLoading: false,
      invoiceList: [],
      //消费客户字段
      customerList: [
        {
          value: '0',
          label: '自费',
        },
        {
          value: '1',
          label: '包干',
        },
        {
          value: '2',
          label: '保险公司',
        },
        {
          value: '3',
          label: '三包公司',
        },
      ],
      //结余金额字段
      accountType: [
        {
          value: ' 0',
          label: '本期结余大于0',
        },
        {
          value: '1',
          label: '期末结余大于0',
        },
      ],
      selectKey: 1,
    };
  },
  watch: {
    status() {
      if (this.status == 'status0') {
        this.getList();
      } else {
        this.getStoreList();
      }
    },
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    ...mapGetters(['isHeadquarters']),
  },
  created() {
    this.form.operateCompanyId = [this.companyId];
    this.pickTimes();
    this.searchInvoice();
    this.getList();
  },
  methods: {
    // 重置筛选表单
    empty() {
      this.form = {};
      this.form.operateCompanyId = [];
    },
    // 更改页码
    changePage(e) {
      this.pageOption = e;
      if (this.status == 'status0') {
        this.getList(false);
      } else {
        this.getStoreList();
      }
    },
    // 导出客户列表
    doExport() {
      var url;
      if (this.status == 'status0') {
        url = exportFundsOnAccountListUrl;
      } else {
        url = exportCompanyOnAccountListUrl;
      }
      downloadFile({
        url: url,
        data: Object.assign(this.form, {
          exportStartNum: Big(this.startPage - 1).times(this.pageOption.size),
          exportEndNum: Big(this.endPage).times(this.pageOption.size),
        }),
      });
      this.showExportDialog = false;
    },
    //表格选中项
    handleSelectionChange(val) {},
    doHandleZero(zero) {
      var date = zero;
      if (zero.toString().length == 1) {
        date = '0' + zero;
      }
      return date;
    },
    //设置查询日期的默认时间
    pickTimes() {
      var tYear = new Date().getFullYear();
      var tMonth = new Date().getMonth();
      tMonth = tYear + '-' + this.doHandleZero(tMonth + 1) + '-' + '01';
      this.form.times = [tMonth, new Date().Format('yyyy-MM-dd')];
    },
    //远程搜索开票主体门店列表
    searchInvoice(keyWord = '') {
      this.searchinvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then((res) => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchinvoiceLoading = false;
        });
    },
    // 选择开票主体门店
    selectInvoice(invoiceId) {},
    //删除开票主体门店
    deleteInvoice() {},
    //获取数据-开票方式
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.tableData = [];
      var allTimes = {
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
        operateCompanyId: this.form.operateCompanyId,
      };
      var data = Object.assign(this.form, allTimes, this.pageOption);
      getFundsOnAccountList(data).then((res) => {
        this.total = res.total;
        this.tableData = res.records;
        this.handleList();
      });
    },
    handleList() {
      var arr = [];
      var cloneData = this.$lodash.cloneDeep(this.tableData);

      let rowIndex = 0;

      //判断是按开票主体还是门店统计
      if (this.status == 'status0') {
        cloneData.forEach((item, index) => {
          if (item.invoices?.length) {
            item.rowspan = item.invoices.length;
            item.invoices.forEach((el, index) => {
              if (index === 0) {
                el.index = ++rowIndex;
                el.isParent = true;
                el.rowspan = item.invoices.length;
              } else {
                el.isChild = true;
              }
              arr.push(
                Object.assign(this.$lodash.cloneDeep(el), {
                  id: item.id,
                  consumer: item.consumer,
                  billId: item.billId,
                  consumerType: item.consumerType,
                  contact: item.contact,
                  invoices: el.name,
                  invoiceCompanyId: el.id,
                  openingBalance: el.openingBalance,
                  openingBalanceRec: el.openingBalanceRec,
                  currentAccountsReceivable: el.currentAccountsReceivable,
                  currentAccountsReceivableRec: el.currentAccountsReceivableRec,
                  currentBalance: el.currentBalance,
                  endBalance: el.endBalance,
                })
              );
            });
          } else {
            item.index = ++rowIndex;
            arr.push(item);
          }
        });
      } else {
        cloneData.forEach((item) => {
          if (item.companies?.length) {
            item.rowspan = item.companies.length;
            item.companies.forEach((el, index) => {
              if (index === 0) {
                el.index = ++rowIndex;
                el.isParent = true;
                el.rowspan = item.companies.length;
              } else {
                el.isChild = true;
              }
              arr.push(
                Object.assign(this.$lodash.cloneDeep(el), {
                  id: item.id,
                  consumer: item.consumer,
                  billId: item.billId,
                  consumerType: item.consumerType,
                  contact: item.contact,
                  companyName: el.companyName,
                  companyId: el.companyId,
                  invoices: el.invoices,
                  openingBalance: el.statistics.openingBalance,
                  openingBalanceRec: el.statistics.openingBalanceRec,
                  currentAccountsReceivable:
                    el.statistics.currentAccountsReceivable,
                  currentAccountsReceivableRec:
                    el.statistics.currentAccountsReceivableRec,
                  currentBalance: el.statistics.currentBalance,
                  endBalance: el.statistics.endBalance,
                })
              );
            });
          } else {
            item.index = ++rowIndex;
            arr.push(item);
          }
        });
      }

      this.tableData = arr;
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 2, 3].includes(columnIndex)) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },
    //获取数据-门店方式
    getStoreList() {
      this.tableData = [];
      var alltimes = {
        startDate: this.form.times?.[0]
          ? this.form.times?.[0] + ' 00:00:00'
          : '',
        endDate: this.form.times?.[1] ? this.form.times?.[1] + ' 23:59:59' : '',
        operateCompanyId: this.form.operateCompanyId,
      };
      var data = Object.assign(this.form, alltimes, this.pageOption);
      getCompanyOnAccountList(data).then((res) => {
        this.total = res.total;
        this.tableData = res.records;
        this.handleList();
      });
    },
    //查询按钮事件
    query() {
      if (this.status == 'status0') {
        this.getList();
      } else {
        this.getStoreList();
      }
    },
    goReceivableDetails(index, row) {
      // 子组件向父组件传值，修改tabs的activeTabOrderRecordType,从而达到组件切换的效果,后面传值过去，是查询到具体数据列表
      // this.$emit('updateActiveName', 'second', row, this.form.times);
      //2022-11-17 需求把页面都去掉三级菜单，直接跳转到对应的页面-应收账款明细
      this.$router.push({
        name: 'receivableDetailsSecond',
        params: {
          row: row,
          times: this.form.times,
        },
      });
    },
    //表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (
          [
            'endBalance',
            'currentBalance',
            'currentAccountsReceivableRec',
            'currentAccountsReceivable',
            'openingBalanceRec',
            'openingBalance',
          ].includes(column.property)
        ) {
          sums[index] = data
            .reduce((prev, curr) => {
              const value = Number(this.$lodash.get(curr, column.property, 0));
              return prev + value;
            }, 0)
            .toFixed(2);
          sums[index] += ' ';
        }
      });

      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 30px 30px 0;
  box-sizing: border-box;
}
//修改选择时间框长度
.el-form {
  width: 100%;
  .el-form-item__content > div {
    width: 100%;
  }
}
//设置表格中合计行颜色
::v-deep .el-table__footer-wrapper tbody td.el-table__cell {
  color: $orange !important;
}
.custom-tabs {
  display: flex;
  .custom-tab-item {
    padding: 12px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    border-bottom: none;
    cursor: pointer;
    color: #999999;
    & + .custom-tab-item {
      margin-left: 30px;
    }
    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
  }
  &.nopadding .custom-tab-item {
    margin-left: 0;
  }
  p {
    font-size: 14px;
    color: #999999;
    margin: 8px 20px;
  }
}
.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}
//导出列表样式
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
.content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}
.custom-table {
  display: flex;
  flex-direction: column;
  overflow: auto;
  ::v-deep .el-table__header-wrapper {
    flex-shrink: 0;
  }
  ::v-deep .el-table__body-wrapper {
    flex: 1;
    overflow: auto;
  }
  ::v-deep .el-table__footer-wrapper {
    flex-shrink: 0;
  }
}
</style>
