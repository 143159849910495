import http from '@/service/http';
import store from '@/store';

const defaultListOption = {
  current: 1,
  size: 15,
};

// 业务来源列表
export function getBillSourceTypeList(
  data = defaultListOption,
  config = { noShowWaiting: true }
) {
  data = Object.assign(
    {
      companyId: store?.state?.user?.userInfo?.companyId,
      isActive: true,
      isShare: true,
    },
    defaultListOption,
    data || {}
  );
  return http.post('/admin/car/billSourceType/list', data, config);
}

// 开单-业务来源列表
export function getListForBill(
  data = defaultListOption,
  config = { noShowWaiting: true }
) {
  data = Object.assign(
    {
      companyId: store?.state?.user?.userInfo?.companyId,
      isActive: true,
      // isShare: true,
    },
    defaultListOption,
    data || {}
  );
  return http.post('/admin/car/billSourceType/listForBill', data, config);
}
