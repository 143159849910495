<template>
  <el-drawer
    title="销账收款详情"
    :visible="chargeDrawerVisibility"
    size="50%"
    :append-to-body="true"
    :before-close="handleClose"
    modal
    close-on-press-escape
    wrapperClosable
  >
    <div class="infor">
      <p>单据号:{{ list.billCode }}</p>
    </div>
    <div class="infor">
      <p>协议客户：{{ list.clientName }}</p>
      <p>
        单据状态:<span class="primary">{{
          list.status == '0' ? '正常' : '作废'
        }}</span>
      </p>
    </div>
    <el-table :data="tableData" border="" style="width: 95%; margin-left: 2.5%">
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="billCode" label="单据号" min-width="180">
      </el-table-column>
      <el-table-column prop="billCode" label="车牌号" min-width="180">
      </el-table-column>
      <el-table-column prop="checkTime" label="结账(挂账）时间" min-width="180">
      </el-table-column>
      <el-table-column prop="billTotal" label="单据金额" min-width="90">
      </el-table-column>
      <el-table-column prop="chargeTotal" label="挂账金额" min-width="90">
      </el-table-column>
      <el-table-column prop="receivedTotal" label="本次收款" min-width="90">
      </el-table-column>
      <el-table-column prop="receivableTotal" label="应收结余" min-width="90">
      </el-table-column>
    </el-table>
    <div class="footer-table">
      <p>金额大写：{{ moneyTotal | amountToChinese }}</p>
      <p>
        收款合计：<span class="orange">{{ moneyTotal }}</span>
      </p>
    </div>
    <div class="footer-infor">
      <div>
        <p>收款方式:</p>
        <p
          v-for="(item, index) in list.payTypeDetails"
          :key="index"
          style="width: 17%; text-align: inherit"
        >
          <span>{{ item.name }}({{ item.paid }})</span>
        </p>
      </div>
      <div>
        <p>收款时间:</p>
        {{ list.checkDate }}
      </div>
    </div>
    <div class="footer-infor">
      <div>
        <p>收款人:</p>
        {{ list.creatorName }}
      </div>
      <div>
        <p>备注:</p>
        {{ list.remark }}
      </div>
    </div>
    <div style="width: 95%; margin-left: 2.5%">
      <el-divider></el-divider>
    </div>
    <div v-if="list.status == 1">
      <div class="footer-infor">
        <div>
          <p>作废人:</p>
          {{ list.creatorName }}
        </div>
        <div>
          <p>作废时间:</p>
          {{ list.cancelTime }}
        </div>
      </div>
      <div class="invalid">
        <p>作废原因:</p>
        {{ list.cancelReason }}
      </div>
    </div>

    <div class="footer">
      <el-button
        v-auth="217"
        size="small"
        @click="invalidDialogVisible = true"
        v-if="list.status == 1"
        >作废</el-button
      >
      <el-button size="small">打印</el-button>
    </div>
    <el-dialog
      title="作废"
      :visible.sync="invalidDialogVisible"
      width="30%"
      :before-close="handleCloseinvalid"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div style="margin: 11px 20px 5px 20px">请输入作废原因，并确认作废</div>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请输入作废原因（必填）"
        v-model="textarea"
        style="margin: 10px 20px; width: 95%"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="invalidDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cancellation()">确 定</el-button>
      </span>
    </el-dialog>
  </el-drawer>
</template>
<script>
import {
  getRecordDetailOnAccountList,
  getRecordDetailOnAccountCancellation,
} from '@/api/financial/financialOver';

import { mapState } from 'vuex';
export default {
  props: {
    chargeDrawerVisibility: { type: Boolean, default: false },
    billRepaymentId: { type: Number },
  },
  components: {},
  data() {
    return {
      list: [],
      moneyTotal: 0,
      tableData: [],
      invalidDialogVisible: false,
      textarea: '',
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.getList();
  },
  methods: {
    //关闭抽屉
    handleClose() {
      this.$emit('update:chargeDrawerVisibility', false);
    },
    handleCloseinvalid() {
      this.invalidDialogVisible = false;
    },
    //获取信息
    getList() {
      var data = {
        billRepaymentId: this.billRepaymentId,
        companyId: this.companyId,
      };
      getRecordDetailOnAccountList(data).then((res) => {
        this.list = res;
        this.tableData = res.details;
        this.moneyTotal = res.details.reduce((prev, item) => {
          return prev + +item.receivableTotal || 0;
        }, 0);
      });
    },
    //作废小弹窗事件
    cancellation() {
      if (this.textarea != '') {
        var data = {
          billRepaymentId: this.billRepaymentId,
        };
        getRecordDetailOnAccountCancellation(data).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '操作成功!',
            });
            this.invalidDialogVisible = false;
            this.$emit('update:chargeDrawerVisibility', false);
            this.$emit('update');
          }
        });
      } else {
        this.$message({
          type: 'warning',
          message: '作废原因必须!',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.infor {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 10px 6%;
  p {
    width: 50%;
  }
}
.footer-table {
  width: 95%;
  margin-left: 2.5%;
  padding: 0px 39px 0px 13px;
  display: flex;
  justify-content: space-between;
  line-height: 47px;
  border: 1px solid #e8e8e8;
  border-top: 0px;
}
.footer-infor {
  width: 95%;
  margin-left: 2.5%;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #333333;
  margin-top: 30px;
  div {
    width: 50%;
    display: flex;
    p {
      width: 20%;
      text-align: right;
      margin-right: 10px;
    }
  }
  & + .footer-infor {
    margin-top: 12px;
    margin-bottom: 30px;
  }
}
.invalid {
  width: 50%;
  margin-left: 2%;
  display: flex;
  font-size: 14px;
  color: #333333;
  margin-top: 10px;
  p {
    width: 20%;
    text-align: right;
    margin-right: 10px;
  }
}
.footer {
  position: fixed;
  bottom: 40px;
  right: 5%;
}
</style>
