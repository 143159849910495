<template>
  <div
    class="base-container"
    :class="{ 'default-padding': defaultPadding, pt: paddingTop }"
    :style="customStyle"
  >
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="main" :style="customMainStyle">
      <slot></slot>
    </div>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseContainer',
  props: {
    defaultPadding: {
      type: Boolean,
      default: true,
    },
    paddingTop: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: '',
    },
    customMainStyle: {
      type: [Object, String],
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.base-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &.default-padding {
    // padding: 30px 0 0;
    > .header,
    > .main {
      padding: 0 30px;
    }
  }
  &.pt .main {
    padding-top: 30px;
  }
  .main {
    flex: 1;
    overflow: auto;
  }
  .footer {
    z-index: 1;
  }
}
</style>
