import * as dicts from './dictData';

// 获取字典选项数组
export function getDictData(key) {
  let data = dicts[key];
  if (!data) {
    console.warn(`未找到该字典选项 ${key}`);
    return [];
  }
  return data.sort((a, b) => a.sort - b.sort);
}

// 根据字典labe获取value
export function getDictValueByLabel(dictName, label) {
  let data = dicts[dictName];
  if (!data) {
    console.warn(`未找到该字典选项 ${dictName}`);
    return '';
  }
  var res = data.find((item) => item.label === label);
  return res?.value ?? '';
}
