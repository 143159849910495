<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="510px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-table
      class="custom-table"
      :data="tableData"
      @row-dblclick="confirmSelect"
    >
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column width="200" label="活动名称" prop="name">
      </el-table-column>
      <el-table-column width="200" label="说明">
        <template #default=""> 双击直接添加项目 </template>
      </el-table-column>
    </el-table>
    <template slot="footer">
      <div style="display: block; text-align: center">
        <el-button size="small" @click="handleClose"> 关 闭 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'carFactoryCompensationActivityDialog',
  components: {},
  props: {
    visibility: Boolean,
    data: [Object],
  },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {
    title() {
      return '检测到此车存在厂家活动';
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.tableData = this.$lodash.cloneDeep(this.data);
    },
    /**
     * 确认选择
     */
    confirmSelect(row, column, event) {
      this.$emit('serviceItemClick', row);
      // 没有数据了，自动关闭
      let newList = [];
      this.tableData.forEach((item) => {
        if (item.serviceItemId !== row.serviceItemId) {
          newList.push(item);
        }
      });
      this.tableData = newList;
      const hasMoreData = newList.length !== 0;
      if (!hasMoreData) {
        this.$emit('update:visibility', false);
      }
    },
    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    margin-left: 100px;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
