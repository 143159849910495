<template>
  <el-drawer
    title="选择商品"
    :visible="visibility"
    size="60%"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div style="height: 100%; padding: 0 30px">
      <el-tabs class="page-tabs" v-model="activeTabName">
        <el-tab-pane label="全部" name="first">
          <base-container :default-padding="false">
            <template slot="header">
              <el-form label-width="100px" size="small">
                <el-row :gutter="20">
                  <el-col :span="3" :offset="0">
                    <el-form-item label="库存大于0">
                      <el-checkbox
                        style="margin-left: 15px"
                        v-model="pageInStock"
                        @change="changeInStock"
                      ></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" :offset="0">
                    <el-form-item label="商品名称">
                      <el-input
                        v-model="goodsName"
                        placeholder="请输入商品名称"
                        clearable
                        @keyup.enter.native="getData(true)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" :offset="0">
                    <el-form-item label="适用车型">
                      <el-input
                        v-model="modelName"
                        clearable
                        @keyup.enter.native="getData(true)"
                        @input="handleModelInput"
                        size="small"
                        placeholder="请输入适用车型"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" :offset="0">
                    <el-form-item label="原厂编码">
                      <el-input
                        v-model="goodsCode"
                        placeholder="请输入原厂编码"
                        clearable
                        @keyup.enter.native="getData(true)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" :offset="0">
                    <el-button
                      size="small"
                      type="primary"
                      @click.prevent="getData"
                      >查询</el-button
                    >
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="6">
                    <el-form-item label="商品品名">
                      <el-select
                        v-model="productIds"
                        multiple
                        filterable
                        remote
                        placeholder="请搜索，支持多选"
                        :remote-method="searchProductList"
                        :loading="searchProductListLoading"
                        value-key="id"
                      >
                        <el-option
                          v-for="item in productList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select> </el-form-item
                  ></el-col>
                  <el-col :span="6">
                    <el-form-item label="入库批次号">
                      <el-input
                        v-model="inventoryInAndOutBillCode"
                        placeholder="请输入入库批次号"
                        clearable
                        @keyup.enter.native="getData(true)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </template>
            <div class="content-box">
              <el-table
                ref="multipleTable"
                :data="tableData"
                border
                class="data-table"
                @select="handleSelectionChange"
                @select-all="handleSelectAll"
              >
                <el-table-column type="selection" width="40"> </el-table-column>
                <el-table-column label="序号" type="index" width="60">
                </el-table-column>
                <el-table-column
                  width="180"
                  prop="matchCarType"
                  label="适用车型"
                >
                  <template #default="{ row }">
                    <el-tag
                      v-if="row.isAgreePrice"
                      type="info"
                      size="mini"
                      class="el-icon--left"
                      >协议</el-tag
                    >
                    <span>{{ row.matchCarType }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="180"
                  label="商品标准名"
                  prop="standardName"
                >
                </el-table-column>
                <el-table-column width="100" label="品质">
                  <template #default="{ row }">
                    <span>{{ row.goodsType | dict('manufactoryType') }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="140"
                  label="自编码"
                  prop="ownCode"
                ></el-table-column>
                <el-table-column
                  width="140"
                  label="原厂编码"
                  prop="goodsCode"
                ></el-table-column>

                <!-- <el-table-column
        width="180"
        prop="goodsName"
        label="商品项目"
      ></el-table-column>
      <el-table-column
        width="100"
        label="编码"
        prop="goodsCode"
      ></el-table-column> -->
                <el-table-column width="120" label="本店库存/单位">
                  <template #default="{ row }">
                    <span>{{ row.inventoryTotalNum }}</span>
                    <span v-if="row.unit">({{ row.unit }})</span>
                  </template>
                </el-table-column>
                <el-table-column width="120" label="他店库存/单位">
                  <template #default="{ row }">
                    <span>{{ row.otherInventoryTotalNum }}</span>
                    <span v-if="row.unit && row.otherInventoryTotalNum"
                      >({{ row.unit }})</span
                    >
                  </template>
                </el-table-column>
                <el-table-column width="120" label="售价">
                  <template #default="{ row }">
                    <span>{{ row.price | toThousands }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120"
                  label="商品备注"
                  prop="remark"
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table>
              <div class="class-box">
                <p class="title">商品分类</p>
                <el-tree
                  class="custom-tree"
                  :data="treeData"
                  :props="treeProp"
                  highlight-current
                  node-key="categoryId"
                  @node-click="onNodeClick"
                >
                </el-tree>
              </div>
            </div>
            <template slot="footer">
              <pagination @pageChange="changePage" :total="total"></pagination>
            </template>
          </base-container>
        </el-tab-pane>
        <!-- <el-tab-pane label="卡内商品项目" name="second"></el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="footer">
      <p>
        <i class="iconfont grow-icon_warn_32 orange"></i>
        <span>已选择商品 {{ selectedItems.length }}</span>
        <!-- <span>共选择商品{{ totalLength }}，本次新增选择{{ addLength }}</span> -->
      </p>
      <el-button type="primary" size="small" @click="confirmSelect"
        >确 定</el-button
      >
      <el-button size="small" @click="handleClose">取 消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import {
  searchGoodsForCreateCarBill,
  searchGoodsWithAgreementPriceForCreateCarBill,
  getGoodsClasses,
} from '@/api/carBill';
import { getProductNameList } from '@/api/stock/goodsSetting';
import pagination from '@/components/Pagination.vue';
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'selectGoodsForCarBill',
  props: {
    visibility: { type: Boolean, default: false },
    oldData: {
      type: Array,
      default: () => [],
    },
    // 车辆ID
    carId: {
      type: [Number, String],
      default: '',
    },
    carModel: {
      type: String,
      default: '',
    },
    billType: [String, Number],
    clientAgreeId: [String, Number],
    /**
     * 服务对应品名
     */
    serviceProducts: {
      type: Array,
      default: () => [],
    },
    /**
     * 商品对应的服务ID
     */
    serviceItemId: {
      type: [String, Number],
      default: 0,
    },
    /**
     * 是否协议服务
     */
    isAgreePrice: {
      type: Boolean,
      default: false,
    },
    billCompanyId: {
      type: Number,
      default: 0,
    },
    isMatchThisModel: Number,
  },
  components: { pagination },
  data() {
    return {
      activeTabName: 'first',
      // 列表数据
      tableData: [],
      selectedItems: [],
      treeData: [],
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      treeProp: {
        children: 'subCategories',
        label: 'name',
      },

      // 商品编码
      goodsCode: '',
      // 商品名称
      goodsName: '',
      // 车型名称名称
      modelName: '',
      // 库存大于0
      pageInStock: true,
      inventoryInAndOutBillCode: '',

      firstCategoryId: '',
      secondCategoryId: '',

      productIds: [],

      productList: [],
      searchProductListLoading: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
      // 库存大于0
      inStock: (state) => state.user.inStock,
    }),

    addLength() {
      return this.totalLength - this.oldData.length;
    },
    totalSelected() {
      return this.$lodash.uniqBy(
        this.selectedItems.concat(this.oldData),
        'goodsId'
      );
    },
    totalLength() {
      return this.totalSelected.length;
    },
    addItems() {
      return this.$lodash.differenceBy(
        this.selectedItems,
        this.oldData,
        'goodsId'
      );
    },
  },
  created() {
    this.pageInStock = this.inStock;
    this.modelName = this.carModel;
    this.getStatistic();
    // this.selectedItems = this.$lodash.cloneDeep(this.oldData || []);
    this.searchProductList();
    this.productIds = this.serviceProducts.map((el) => el.productId);
    this.getData();
  },
  methods: {
    ...mapMutations({
      globalChangeInStock: 'changeInStock',
    }),
    /**
     * 更改 库存大于0
     */
    changeInStock(value) {
      this.globalChangeInStock({ value });
      this.getData();
    },
    /**
     * 搜索品名列表
     */
    searchProductList(keyWord) {
      this.searchProductListLoading = true;
      getProductNameList(
        { name: keyWord || '', current: 1, size: 50 },
        { noShowWaiting: true }
      )
        .then((res) => {
          this.productList = this.$lodash.unionBy(
            this.serviceProducts
              .map((el) => ({
                id: el.productId,
                name: el.productName,
              }))
              .concat(res.records || []),
            'id'
          );
        })
        .finally(() => {
          this.searchProductListLoading = false;
        });
    },
    /**
     * 输入适用车型
     */
    handleModelInput(val) {
      this.$emit('update:carModel', val);
      this.getData(true);
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData();
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
    /**
     * table选中事件
     */
    handleSelectionChange(val, item) {
      // 如果此次点击的项目不是勾选状态，则说明是取消勾选此项
      let clickItem = val.find((s) => s.serviceItemId === item.goodsId);
      if (!clickItem) {
        // 在选中列表中删除此项
        this.selectedItems = this.selectedItems.filter(
          (s) => s.goodsId != item.goodsId
        );
      }
      this.selectedItems = this.$lodash.uniqBy(
        this.selectedItems.concat(val),
        'goodsId'
      );
    },
    /**
     * 勾选全部
     */
    handleSelectAll(val) {
      let bool = this.selectedItems.every((item) =>
        val.find((el) => el.title === item.title)
      );
      this.selectedItems.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row, bool);
      });
      this.selectedItems = bool
        ? this.$lodash.uniqBy(this.selectedItems.concat(val), 'goodsId')
        : [];
    },
    /**
     * 同步选中状态
     */
    syncSelectedStatus() {
      this.$nextTick().then(() => {
        if (this.selectedItems?.length) {
          var serviceItemId = this.selectedItems.map((item) => item.goodsId);
          var shouldBeSelected = this.tableData.filter((item) =>
            serviceItemId.includes(item.goodsId)
          );
          shouldBeSelected.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    /**
     * 确认选择
     */
    confirmSelect() {
      // 调用自定义事件，传入参数
      this.$emit('select', this.selectedItems);
      this.$emit('update:visibility', false);
    },

    /**
     * 获取数据
     */
    getData() {
      let body = {
        ...this.pageOption,
        goodsName: this.goodsName,
        goodsCode: this.goodsCode,
        // ownCode: this.ownCode,
        carId: this.carId,
        // isMatchThisModel: 0, //是否匹配本车型（0/1 否/是)
        companyId: this.companyId,
        // 库存状态
        inventoryState: this.pageInStock ? 1 : 0,
        inventoryInAndOutBillCode: this.inventoryInAndOutBillCode,
        // inventoryState: this.inventoryState,
        // 适用车型
        modelName: this.modelName,
        // 品名
        productIds: this.productIds,
        serviceItemId: this.serviceItemId,
        isMatchThisModel: this.isMatchThisModel,
      };
      if (this.firstCategoryId) {
        body.firstCategoryId = this.firstCategoryId;
      }
      if (this.secondCategoryId) {
        body.secondCategoryId = this.secondCategoryId;
      }

      if (this.billType !== undefined) {
        body.billType = this.billType;
      }
      if (this.clientAgreeId) {
        body.clientAgreeId = this.clientAgreeId;
      }
      if (this.billCompanyId) {
        body.billCompanyId = this.billCompanyId;
      }

      searchGoodsWithAgreementPriceForCreateCarBill(body, {
        noShowWaiting: false,
      }).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
        this.syncSelectedStatus();
      });
    },
    /**
     * 获取分类数据
     */
    getStatistic() {
      getGoodsClasses(this.companyId).then((res) => {
        // 添加全部选项
        res.unshift({
          categoryId: 0,
          name: '全部',
        });
        this.treeData = res;
      });
    },
    /**
     * 点击节点
     */
    onNodeClick(obj, node, origin) {
      if (obj.categoryId) {
        if (obj.parentId) {
          this.firstCategoryId = obj.parentId;
          this.secondCategoryId = obj.categoryId;
        } else {
          this.firstCategoryId = obj.categoryId;
          this.secondCategoryId = 0;
        }
      } else {
        this.firstCategoryId = '';
        this.secondCategoryId = 0;
      }

      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.page-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  &::v-deep .el-tabs__content {
    flex: 1;
    .el-tab-pane {
      height: calc(100% - 109px);
    }
  }
}

.content-box {
  height: 100%;
  display: flex;
  padding-top: 20px;
  box-sizing: border-box;
  .data-table {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ::v-deep .el-table__header-wrapper {
      flex-shrink: 0;
    }
    ::v-deep .el-table__body-wrapper {
      flex: 1;
      overflow: auto;
    }
  }
}

.class-box {
  width: 30%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  .el-tree {
    overflow: auto;
  }
}

.class-box .title {
  flex-shrink: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  background: #fafafa;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 109px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 60px;
  // border-top: 1px solid #e4e4e4;
  // border-bottom: 1px solid #e4e4e4;
  box-shadow: -4px 0px 30px 0px rgb(0 0 0 / 10%);
  background: #fff;
  z-index: 99;
}
.footer > p {
  margin-right: 30px;
}
.footer span {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
}
</style>
