<template>
  <div>
    <div
      class="pagination"
      v-if="type == 'mini'"
      :class="{ otherSty: typeStyle }"
    >
      <span>
        <span class="total" style="margin-right: 24px"
          >共 &nbsp;{{ total }}&nbsp;条</span
        >
        <span>
          每页
          <el-select
            size="mini"
            class="pagination-short-input"
            v-model="size"
            @change="changePageSize"
          >
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          条
        </span>
        <i
          class="store-icon newicon grow-icon-arrow-left"
          @click="changeCurrentPage('previous')"
        ></i>
        <strong class="page-num" style="min-width: 40px">
          <span class="blue">{{ current }}</span>
          <span>/{{ totalPage || 0 }}</span>
        </strong>
        <i
          class="store-icon newicon grow-icon-arrow-right"
          @click="changeCurrentPage('next')"
        ></i>
      </span>
    </div>
    <div class="pagination" v-if="type != 'mini'">
      <span>
        每页
        <el-select
          size="mini"
          class="pagination-short-input"
          v-model="size"
          @change="changePageSize"
        >
          <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        条
      </span>
      <div class="page">
        <button class="first-page" @click="changeCurrentPage('first')">
          首页
        </button>
        <button class="previous-page" @click="changeCurrentPage('previous')">
          上一页
        </button>
        <strong class="page-num">
          <span class="blue">{{ current }}</span>
          <span>/{{ totalPage || 0 }}</span>
        </strong>
        <button class="next-page" @click="changeCurrentPage('next')">
          下一页
        </button>
        <button class="last-page" @click="changeCurrentPage('last')">
          末页
        </button>
      </div>
      <span>
        前往第
        <el-input
          class="pagination-short-input"
          size="mini"
          v-model="jumper"
          @input="regCode"
          @keyup.enter.native="jumperPage"
        >
        </el-input>
        <!-- <input
          type="number"
          class="regular-input"
          v-model="jumper"
          @input="regCode"
          @keypress="jumperPage($event)"
        /> -->
        页
      </span>
      <span class="total">共 &nbsp;{{ total }}&nbsp;条记录</span>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'pagination',
  props: {
    // 每页大小
    pageSizePror: {
      type: [String, Number],
      default: 10,
    },
    // 总页数
    total: {
      type: [String, Number],
      required: true,
    },
    // 每页数量选项
    options: {
      type: Array,
      default: () => [10, 20, 50, 100, 200],
    },
    type: [String],
    typeStyle: { type: Boolean, default: false },
  },
  data() {
    return {
      size: this.pageSizePror,
      current: 1,
      jumper: null,
    };
  },
  watch: {
    totalPage: function () {
      this.current = 1;
    },
  },
  computed: {
    totalPage: function () {
      return Math.ceil(this.total / this.size);
    },
  },
  methods: {
    changeCurrentPage(p) {
      if (p == 'first') {
        if (this.current == 1) return;
        this.current = 1;
        this.emitChange();
      }
      if (p == 'previous') {
        if (this.current == 1) return;
        this.current -= 1;
        this.emitChange();
      }
      if (p == 'next') {
        if (this.current >= this.totalPage) return;
        this.current += 1;
        this.emitChange();
      }
      if (p == 'last') {
        if (this.current == this.totalPage) return;
        this.current = this.totalPage;
        this.emitChange();
      }
      this.jumper = null;
    },
    regCode() {
      this.jumper = this.jumper.replace(/[^0-9]/g, '');
    },
    jumperPage() {
      if (this.jumper <= this.totalPage) {
        this.current = isNaN(Number(this.jumper)) ? 1 : Number(this.jumper);
      } else if (this.jumper > this.totalPage) {
        this.jumper = this.totalPage;
        this.current = this.jumper;
        this.$message.warning('请不要输入可查询页数范围之外的页数');
      }
      this.emitChange();
    },
    changePageSize() {
      this.current = 1;
      this.emitChange();
    },
    initPage() {
      this.page.current = 1;
    },
    emitChange() {
      this.$emit('pageChange', {
        size: this.size,
        current: this.current,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin: 2vh;
  color: #3a3a3a;
  text-align: center;
}
.page {
  display: inline-block;
  margin: 0 20px;
}
.regular-input {
  width: 60px;
  padding: 0 5px;
  text-align: center;
}
button {
  font-size: 12px;
  line-height: 26px;
  outline: 0;
  padding: 0 15px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  margin: 2px;
}
.page-num {
  margin: 0 5px;
  min-width: 60px;
  display: inline-block;
}
.total {
  display: inline-block;
  margin-left: 20px;
}
.pagination-short-input {
  width: 70px;
}
.otherSty {
  margin: 2vh 0;
}
</style>
