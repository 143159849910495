<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="100px"
      style="margin-top: 18px"
    >
      <div style="margin-bottom: 30px; margin-left: 40px" class="flex">
        <i class="el-icon-warning orange el-icon--left"></i>
        <p style="font-size: 14px">
          点击确定{{ title }}，提交到“{{ nextState[title] }}”状态
        </p>
      </div>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="跟进人" prop="followUpPeople">
            <el-select
              v-model="editForm.followUpPeople"
              filterable
              clearable
              remote
              placeholder="搜索姓名、手机号"
              :remote-method="searchAllUser"
              :loading="searchAllUserLoading"
              @select="doVerify('followUpPeople')"
            >
              <el-option
                v-for="item in allUserList"
                :key="item.userId"
                :label="item.realName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="拖车费" prop="towingCharge">
            <number-input
              v-model="editForm.towingCharge"
              decimalDigits="2"
              :max="999999.99"
              placeholder="请输入"
              clearable
              @blur="doVerify('towingCharge')"
            >
            </number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="定损员" prop="lossDeterminationPersonName">
            <el-input
              v-model="editForm.lossDeterminationPersonName"
              placeholder="请输入2-10字符"
              clearable
              maxlength="10"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="lossDeterminationPersonPhone">
            <number-input
              placeholder="请输入"
              v-model="editForm.lossDeterminationPersonPhone"
              :maxlength="11"
              :enabledDecimals="false"
              :negativeNumber="false"
              @blur="doVerify('lossDeterminationPersonPhone')"
            ></number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="拍照要求" prop="photoRequest">
            <el-input
              type="textarea"
              size="small"
              v-model="editForm.photoRequest"
              :rows="1"
              maxlength="50"
              placeholder="请输入拍照要求，最多50个字符"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="报案时间" prop="reportingTime">
            <el-date-picker
              v-model="editForm.reportingTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车损金额" prop="carDamageAmount">
            <number-input
              v-model="editForm.carDamageAmount"
              :max="999999.99"
              decimalDigits="2"
              placeholder="请输入"
              clearable
              @blur="doVerify('carDamageAmount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="风险评估" prop="riskAssessment">
            <el-radio-group v-model="editForm.riskAssessment">
              <el-radio :label="false">无风险</el-radio>
              <el-radio :label="true">有风险</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预估金额" prop="estimatedAmount">
            <number-input
              v-model="editForm.estimatedAmount"
              :max="999999.99"
              decimalDigits="2"
              placeholder="请输入"
              clearable
              @blur="doVerify('estimatedAmount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="核损金额" prop="lossAssessmentAmount">
            <number-input
              v-model="editForm.lossAssessmentAmount"
              :max="999999.99"
              decimalDigits="2"
              placeholder="请输入"
              clearable
              @blur="doVerify('lossAssessmentAmount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="定损金额" prop="lossAmount">
            <number-input
              v-model="editForm.lossAmount"
              :max="999999.99"
              decimalDigits="2"
              placeholder="请输入"
              clearable
              @blur="doVerify('lossAmount')"
            >
            </number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="收旧情况" prop="recoveryState">
            <el-select v-model="editForm.recoveryState" placeholder="请选择">
              <el-option
                v-for="type in recoveryState"
                :key="type.value"
                :value="type.value"
                :label="type.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="复勘情况" prop="investigationState">
            <el-select
              v-model="editForm.investigationState"
              placeholder="请选择"
            >
              <el-option
                v-for="type in investigationState"
                :key="type.value"
                :value="type.value"
                :label="type.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="是否有免赔" prop="isExemptionPay2000">
            <el-radio-group
              v-model="editForm.isExemptionPay2000"
              :disabled="!localAllowExemptionFill"
            >
              <el-radio
                :label="0"
                @click.native.prevent="handleClickChangeIsExemptionPay2000(0)"
                >无</el-radio
              >
              <el-radio
                :label="1"
                @click.native.prevent="handleClickChangeIsExemptionPay2000(1)"
                >有</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="免赔金额" prop="exemptionPayMoney">
            <number-input
              v-model="editForm.exemptionPayMoney"
              :max="999999.99"
              decimalDigits="2"
              placeholder="请输入免赔金额"
              :disabled="!localIsExemptionPay2000"
              clearable
              @blur="doVerify('exemptionPayMoney')"
            >
            </number-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="免赔支付方" prop="settlementMethod">
            <el-radio-group
              v-model="editForm.settlementMethod"
              :disabled="!localIsExemptionPay2000"
            >
              <el-radio
                :label="0"
                @click.native.prevent="handleClickChangeSettlementMethod(0)"
                >挂账车队({{ editForm.settlementClientName }})</el-radio
              >
              <el-radio
                :label="1"
                @click.native.prevent="handleClickChangeSettlementMethod(1)"
                >司机现结</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="跟进进展" prop="followUpState">
            <el-radio-group
              style="line-height: 1.4rem"
              v-model="editForm.followUpState"
            >
              <el-radio
                v-for="option in followUpStateList"
                :key="option.value"
                :label="option.value"
                @click.native.prevent="handleClickFollowUpState(option.value)"
              >
                <div :style="option.iconStyle">{{ option.iconText }}</div>
                <span style="margin-left: 5px">{{ option.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              placeholder="请输入备注，最多300个字符"
              v-model="editForm.remark"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="当次留言" prop="remark">
            <el-input
              type="textarea"
              v-model="editForm.message"
              maxlength="300"
              show-word-limit
              placeholder="请输入当次留言，最多300个字符"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="图片" prop="feeListPicA">
            <span style="color: #909399; font-size: 12px"
              >支持上传 10 张图片，建议每张图片大小不超过 2 MB</span
            >

            <div>
              <upload-file
                v-model="editForm.runningLicenceImg"
                title="行驶证"
              ></upload-file>
              <upload-file
                v-model="editForm.picDrivingLicence"
                title="驾驶证"
              ></upload-file>
              <upload-file
                v-model="editForm.feeListPicA"
                title="定损单1"
                :requiredIcon="type >= 8"
              >
              </upload-file>
              <upload-file
                v-model="editForm.feeListPicB"
                title="定损单2"
              ></upload-file>
              <upload-file
                v-model="editForm.feeListPicC"
                title="定损单3"
              ></upload-file>
              <upload-file-list
                :max="5"
                v-model="editForm.feeListPicOthersList"
              ></upload-file-list>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="附件" prop="attachment">
            <span style="color: #909399; font-size: 12px"
              >只支持上传zip文件，最多5个</span
            >
            <div class="flex">
              <ul style="max-width: 300px">
                <li v-for="(item, i) in attachmentList" :key="i">
                  <i
                    class="iconfont grow-icon_delete3 orange el-icon--left pointer"
                    @click="handleDelete(i)"
                  ></i>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
              <div style="margin-left: 20px" v-if="attachmentList.length < 5">
                <el-button size="small" @click="chooseFile">选择文件</el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 字典数据
import {
  followUpStateList,
  recoveryState,
  investigationState,
} from '@/service/dict/dictData';

import { getAllUser } from '@/api/employee';
import {
  updateCarLossDeterminationBill,
  uploadZipFile,
} from '@/api/carBill/carLossDeterminationBill';

import UploadFile from '@/components/uploadFile.vue';
import uploadFileList from '@/components/uploadFileList';
import { chooseFile } from '@/utils/fileApi';

export default {
  name: 'editDialog',
  components: { UploadFile, uploadFileList },
  props: {
    visibility: Boolean,
    id: Number,
    type: Number,
    title: {
      type: String,
      default: '编辑',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      followUpStateList,
      recoveryState,
      investigationState,
      // 表单信息
      editForm: {},
      attachmentList: [],
      settlementPayWay: '',
      localIsExemptionPay2000: false,
      localAllowExemptionFill: false,
      searchAllUserLoading: false,
      allUserList: [],

      nextState: {
        完成报损: '待调度',
        完成调度: '待拍照对件',
        完成对件: '待提定损',
        提交定损: '待出定损',
        已出定损: '已出定损',
      },
    };
  },
  computed: {
    // 表单规则
    rules() {
      const part1 = {
        followUpPeople: [
          { required: true, message: '请选择跟进人', trigger: 'blur' },
        ],
        towingCharge: [
          { required: true, message: '请输入拖车费', trigger: 'blur' },
        ],
        photoRequest: [
          { required: true, message: '请输入拍照要求', trigger: 'blur' },
        ],
        reportingTime: [
          { required: true, message: '请选择报案时间', trigger: 'blur' },
        ],
        carDamageAmount: [
          { required: true, message: '请输入车损金额', trigger: 'blur' },
        ],
      };
      const part2 = {};
      // 完成调度
      if (this.type >= 2) {
        part2.riskAssessment = [
          { required: true, message: '请选择评估风险', trigger: 'blur' },
        ];
      }
      // 完成对件
      if (this.type >= 4) {
        part2.estimatedAmount = [
          { required: true, message: '请输入预估金额', trigger: 'blur' },
        ];
      }
      // 提交定损
      if (this.type >= 6) {
        part2.lossDeterminationPersonName = [
          { required: true, message: '请输入定损员', trigger: 'blur' },
        ];
        part2.lossDeterminationPersonPhone = [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ];
        part2.lossAssessmentAmount = [
          { required: true, message: '请输入核损金额', trigger: 'blur' },
        ];
      }
      // 已出定损
      if (this.type >= 8) {
        part2.lossAmount = [
          { required: true, message: '请输入定损金额', trigger: 'blur' },
        ];
        part2.recoveryState = [
          { required: true, message: '请选择收旧情况', trigger: 'blur' },
        ];
        part2.investigationState = [
          { required: true, message: '请选择复勘情况', trigger: 'blur' },
        ];
        part2.feeListPicA = [
          {
            required: true,
            message: '请上传定损单',
            // validator: (rule, value, callback) => {
            //   if (!value) {
            //     callback(new Error('请上传定损单'));
            //   } else {
            //     callback();
            //   }
            // },
          },
        ];
        // 已出定损， 必须填写 是否免赔、如果有，则需填写免赔金额和支付方
      }
      if (this.type == 8) {
        part2.isExemptionPay2000 = [
          { required: true, message: '请选择是否有免赔' },
        ];
        if (this.editForm.isExemptionPay2000 == 1) {
          part2.exemptionPayMoney = [
            { required: true, message: '请输入免赔金额', trigger: 'blur' },
          ];
          part2.settlementMethod = [
            { required: true, message: '请选择免赔支付方', trigger: 'blur' },
          ];
        }
      }
      // switch (this.title) {
      //   case '完成调度':
      //     part2 = {
      //       riskAssessment: [
      //         { required: true, message: '请选择评估风险', trigger: 'blur' },
      //       ],
      //     };
      //     break;
      //   case '完成对件':
      //     break;
      //   case '提前定损':
      //     break;
      //   case '已出定损':
      //     break;
      // }

      return Object.assign({}, part1, part2);
    },
  },
  created() {
    this.editForm = this.$lodash.cloneDeep(this.form);

    this.editForm.recoveryState = this.editForm.recoveryState || '';
    this.editForm.investigationState = this.editForm.investigationState || '';

    this.editForm.feeListPicOthersList =
      this.editForm.feeListPicOthersList.join(',');

    if (this.editForm.attachmentUrl) {
      const url = this.editForm.attachmentUrl.split(',').filter((el) => el);
      const name = this.editForm.attachmentName.split(',').filter((el) => el);
      this.attachmentList = url.map((item, index) => {
        return {
          url: item,
          name: name[index],
        };
      });
    }
    if (this.editForm.followUpPeople) {
      this.allUserList = [
        {
          userId: this.editForm.followUpPeople,
          realName: this.editForm.followUpPeopleName,
        },
      ];
    }
    if (this.editForm.settlementMethod == 0) {
      this.settlementPayWay = 0;
    }
    if (
      this.editForm.isExemptionPay2000 == 0 ||
      this.editForm.isExemptionPay2000 == undefined ||
      this.editForm.isExemptionPay2000 == null
    ) {
      this.localIsExemptionPay2000 = false;
    } else {
      this.localIsExemptionPay2000 = true;
    }
    // 已出定损状态下才允许编辑
    if (this.editForm.lossDeterminationState === 1) {
      this.localAllowExemptionFill = true;
    } else {
      this.localAllowExemptionFill = false;
      if (this.localIsExemptionPay2000) {
        this.localIsExemptionPay2000 = false;
      }
    }
    // this.searchAllUser({
    //   msg: this.editForm.followUpPeopleName,
    //   userId: this.editForm.followUpPeople,
    // });
  },
  methods: {
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      this.searchAllUserLoading = true;
      let params = {};
      if (typeof msg === 'object') {
        params = msg;
      } else {
        params = { msg };
      }
      getAllUser(params)
        .then((res) => {
          this.allUserList = res.records || [];
        })
        .finally(() => {
          this.searchAllUserLoading = false;
        });
    },
    /**
     * 跟进进展单选框选中时，再次点击取消选中
     */
    handleClickFollowUpState(value) {
      if (this.editForm.followUpState === value) {
        this.editForm.followUpState = '';
      } else {
        this.$set(this.editForm, 'followUpState', value);
      }
    },
    /**
     * 处理变更是否有免赔2000
     * @param {Integer} val 0 无 1 有
     */
    handleClickChangeIsExemptionPay2000(val) {
      if (!this.localAllowExemptionFill) {
        return false;
      }
      this.$set(this.editForm, 'isExemptionPay2000', val);
      if (val === this.localIsExemptionPay2000) {
        return true;
      } else {
        this.localIsExemptionPay2000 = val;
        if (val == 1) {
          // 增加校验规则
          this.rules.exemptionPayMoney = [
            { required: true, message: '请输入免赔金额', trigger: 'blur' },
          ];
          this.rules.settlementMethod = [
            { required: true, message: '请选择免赔支付方', trigger: 'blur' },
          ];
        } else if (val == 0) {
          // 去除校验规则
          this.rules.exemptionPayMoney = [];
          this.rules.settlementMethod = [];
          // 去除默认值，让理赔员填写
        }
      }
      return true;
    },
    /**
     * 处理变更 免赔支付方
     * @param {Integer} val 0 出行公司 1 司机现结
     */
    handleClickChangeSettlementMethod(val) {
      if (!this.localAllowExemptionFill) {
        return false;
      }
      this.$set(this.editForm, 'settlementMethod', val);
      if (val === this.settlementPayWay) {
        return true;
      } else {
        this.settlementPayWay = val;
      }
      return true;
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form.clearValidate();
      this.$refs.form
        .validate()
        .then((result, object) => {
          // 手工处理下 已出定损， 必须填写 是否免赔、如果有，则需填写免赔金额和支付方
          if (this.editForm.isExemptionPay2000 === '1') {
            if (this.editForm.exemptionPayMoney === undefined) {
              this.$message.error('免赔金额必须填写!');
              return;
            }
            if (this.editForm.settlementMethod === undefined) {
              this.$message.error('免赔支付方必须选择!');
              return;
            }
          }
          //处理图片
          this.editForm.runningLicenceImg = this.editForm.runningLicenceImg
            ?.split('/')
            .at(-1);
          this.editForm.picDrivingLicence = this.editForm.picDrivingLicence
            ?.split('/')
            .at(-1);
          this.editForm.feeListPicA = this.editForm.feeListPicA
            ?.split('/')
            .at(-1);
          this.editForm.feeListPicB = this.editForm.feeListPicB
            ?.split('/')
            .at(-1);
          this.editForm.feeListPicC = this.editForm.feeListPicC
            ?.split('/')
            .at(-1);
          // this.editForm.feeListPicOthers = this.editForm.feeListPicOthersList
          //   ?.split(',')
          //   .map((item) => item.split('/').at(-1))
          //   .join();
          // if (this.editForm.feeListPicOthersList.length > 0) {
          //   this.editForm.feeListPicOthers = this.editForm.feeListPicOthersList
          //     .map((item) => item.split('/').at(-1))
          //     .join();
          // } else if (this.editForm.feeListPicOthersList.length == 0) {
          //   this.editForm.feeListPicOthers = '';
          // } else {
          this.editForm.feeListPicOthers = this.editForm.feeListPicOthersList
            ?.split(',')
            .map((item) => item.split('/').at(-1))
            .join();
          // }

          const body = Object.assign(this.editForm, {
            id: this.id,
            type: this.type,
            attachmentName: this.attachmentList
              .map((item) => item.name)
              .join(','),
            attachmentUrl: this.attachmentList
              .map((item) => item.url.split('/').at(-1))
              .join(','),
          });
          updateCarLossDeterminationBill(body).then((res) => {
            this.$message.success(res.data || '操作成功!');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },

    /**
     * 校验某个字段
     */
    doVerify(prop) {
      this.$refs.form.validateField(prop);
    },

    /**
     * 选择文件
     */
    chooseFile() {
      chooseFile({ accept: '.zip', multiple: false }).then((file) => {
        console.log(file[0]);
        // 大于100M不允许上传
        if (file[0].size > 1024 * 1024 * 100) {
          this.$message.error('上传的附件大小不能超过100MB!');
          return;
        }
        uploadZipFile(file[0]).then((res) => {
          this.attachmentList.push({
            url: res.imageUrl,
            // name: res.name.replace(/,/g, ''),
            name: file[0].name.replace(/,/g, ''),
          });
        });
      });
    },

    /**
     * 删除
     */
    handleDelete(index) {
      this.attachmentList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
