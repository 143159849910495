import http from '@/service/http';
import store from '@/store';

const defaultListOption = {
  current: 1,
  size: 10,
};

/**
 * 毛利明细排行榜
 * @param {*} data
 */
export function grossProfitRankingList(data = {}) {
  return http.post('/admin/data/board/grossProfitRankingList', data);
}

/**
 * 月度毛利率趋势
 * @param {*} data
 */
export function monthlyGrossMarginTrend(data = {}) {
  return http.post('/admin/data/board/monthlyGrossMarginTrend', data);
}

/**
 * 月度综合毛利率
 * @param {*} data
 */
export function monthlyConsolidatedGrossMargin(data = {}) {
  return http.post('/admin/data/board/monthlyConsolidatedGrossMargin', data);
}

/**
 * 故障分诊明细
 * @param {*} data
 */
export function getFaultDiagnosisDetail(data = {}) {
  return http.post('/admin/data/board/faultDiagnosisDetail', data);
}

/**
 * 故障分诊明细导出
 */
export const faultDiagnosisDetailExport =
  '/admin/data/board/faultDiagnosisDetail/export';
