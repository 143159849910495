<template>
  <base-container paddingTop>
    <el-tabs class="tabs" v-model="activeName">
      <el-tab-pane label="预约信息" name="bookingList" v-auth="692">
        <miniProgramBookingRecords></miniProgramBookingRecords>
      </el-tab-pane>
      <el-tab-pane label="快保配置" name="quickConfig" v-auth="689">
        <miniProgramBookingServiceConfig
          :service-type="0"
        ></miniProgramBookingServiceConfig>
      </el-tab-pane>
      <el-tab-pane label="故障配置" name="faultRepairConfig" v-auth="689">
        <miniProgramBookingServiceConfig
          :service-type="1"
        ></miniProgramBookingServiceConfig>
      </el-tab-pane>
      <el-tab-pane label="事故配置" name="accidentConfig" v-auth="689">
        <miniProgramBookingServiceConfig
          :service-type="2"
        ></miniProgramBookingServiceConfig>
      </el-tab-pane>
      <el-tab-pane label="退车配置" name="returnCarConfig" v-auth="689">
        <miniProgramBookingServiceConfig
          :service-type="3"
        ></miniProgramBookingServiceConfig>
      </el-tab-pane>
    </el-tabs>
  </base-container>
</template>

<script>
import miniProgramBookingRecords from './miniProgramBookingRecords.vue';
import miniProgramBookingServiceConfig from './miniProgramBookingServiceConfig.vue';
import { mapGetters, mapMutations } from 'vuex';
import { levelIconsOther } from '@/const/client';
// 权限
import { verifyAuth } from '@/utils/permissions';
export default {
  name: 'miniProgramBookingRecordList',
  components: {
    miniProgramBookingRecords,
    miniProgramBookingServiceConfig,
  },
  data() {
    return {
      activeName: 'bookingList',
    };
  },
  created() {
    this.activeName = this.$route.params?.activeTabName || 'bookingList';
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
  },
};
</script>

<style lang="scss" scoped>
.el-descriptions ::v-deep .table-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: $descriptions-label-bg;
}
.rows-tip {
  color: #999;
  line-height: 18px;
}
.box {
  .title {
    margin: 24px 0 8px 0;
    color: #303133;
    font-size: 16px;
    font-weight: 600;
  }
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  .el-dropdown {
    margin-left: 24px;
  }
  .el-dropdown-item ::v-deep .el-dropdown-menu {
    padding: 0;
  }
}
.client-info {
  // > div + div {
  //   margin-top: 10px;
  // }
  i {
    margin: 0 5px;
  }
  // .icon_person {
  //   color: $blue;
  // }
  // .icon_sex {
  //   color: $blue;
  // }
  .icon_grade {
    color: $blue;
    position: absolute;
    left: -30px;
  }
  // .grow-icon_girl {
  //   color: #f48080;
  // }
  // .icon_wechat {
  //   color: #999;
  // }
  // .icon_wechat_active {
  //   color: #00c800;
  // }
  // .icon_phone {
  //   color: #42bbb8;
  // }
  // .icon_vip {
  //   color: $orange;
  // }
  // .icon_sex,
  // .icon_wechat,
  // .icon_wechat_active,
  // .icon_vip,
  .icon_grade {
    font-size: 22px;
  }
}
.btn-dropdown:focus,
.btn-dropdown:hover {
  color: #606266;
  background: #f5f7fa;
  border-color: #f5f7fa;
}
.cell-left {
  width: 20%;
  min-height: 46px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-right: 1px solid #ebeef5;
  text-align: center;
  span {
    width: 100%;
  }
}
.cell-right {
  height: 100%;
  flex: 1;
  padding: 0 12px;
}
.tabs {
  margin-bottom: 30px;
}
.el-descriptions ::v-deep .el-descriptions-item__content {
  height: 46px;
  min-height: 46px;
}
</style>
