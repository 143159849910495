<template>
  <el-dialog
    :visible="visibility"
    width="40%"
    :close-on-click-modal="false"
    :before-close="handleClose"
    title="选择客户"
    append-to-body
  >
    <el-popover
      placement="bottom"
      trigger="manual"
      v-model="visible"
      popper-class="popper-class"
    >
      <el-table
        class="car-table"
        :max-height="400"
        border
        :data="listData"
        @row-click="handleClick"
      >
        <el-table-column width="90 " label="客户名称" prop="clientName">
        </el-table-column>
        <el-table-column width="160" label="协议客户">
          <template #default="{ row }">
            <p v-if="row.isAgreementClient == 1">
              协议客户<span v-if="row.beContract">（包干）</span>
            </p>
            <p v-if="row.isAgreementClient == 0"></p>
          </template>
        </el-table-column>
        <el-table-column width="90 " label="等级" prop="clientLevel">
        </el-table-column>
        <template slot="append">
          <div
            v-if="hasMore"
            class="load-more-btn"
            @click="getData(false, true)"
          >
            加载更多数据
          </div>
        </template>
      </el-table>
      <template slot="reference">
        <el-input
          style="width: 300px"
          ref="keyWordInput"
          v-model="keyWord"
          size="small"
          placeholder="搜索客户名称"
          @input="getData(true)"
          @focus="visible = true"
          @clear="handleClear"
          clearable
        ></el-input>
        <span v-auth="61" style="margin: 0 15px">或</span>
        <span
          v-auth="61"
          class="primary text_operation"
          @click="showAddCusterDialog = true"
          >新增</span
        >
      </template>
    </el-popover>
    <div class="client" v-show="clientInfo.id">
      <i
        class="iconfont grow-icon_delete3 icon_delete pointer"
        @click="deleteClient"
      ></i>
      <div class="client-info">
        <i
          class="iconfont icon_grade"
          :class="levelIconsOther[clientInfo.clientLevel]"
          :title="[clientInfo.clientLevel] || ''"
        ></i>
        <span>{{ clientInfo.clientName }}</span>
        <span v-if="clientInfo.isAgreementClient == 1">协议客户（包干） </span>
      </div>
    </div>
    <add-customer-dialog
      v-if="showAddCusterDialog"
      :showAddCusterDialog.sync="showAddCusterDialog"
      @custerInforma="handleAddCustomer"
    ></add-customer-dialog>

    <template slot="footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { searchClient, getClientBySearch } from '@/api/customer/customer';
import { levelIconsOther } from '@/const/client';
import addCustomerDialog from '@/views/customerManagement/addCustomer';
import lodash from 'lodash';
export default {
  name: 'selectOtherPayClientDialog',
  components: {
    addCustomerDialog,
  },
  props: {
    visibility: { type: Boolean, default: false },
  },
  data() {
    return {
      clientId: undefined,
      clientInfo: {},
      clientList: [],
      searchClientLoading: false,
      showAddCusterDialog: false,
      levelIconsOther,
      // 列表数据
      listData: [],
      // 搜索关键字
      keyWord: '',
      // 可见性
      visible: false,
      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: false,

      // 是否正在加载
      isLoading: false,

      // 是否首次加载
      isFirstLoad: true,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  methods: {
    /**
     * 获取数据
     */
    getData(reset, loadMore) {
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      if (!this.isFirstLoad) this.isLoading = true;
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;
        //  滚动到头部
        // this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      getClientBySearch(
        {
          clientMsg: this.keyWord,
          companyId: this.$store.state.user.userInfo.companyId,
          ...this.pagination,
        },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (reset) this.listData = [];
          this.listData.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;
        })
        .finally(() => {
          this.isLoading = false;
          this.isFirstLoad = false;
        });
    }, 5e2),
    /**
     * 选择客户
     */
    selectClient(clientId) {
      if (clientId) {
        const client = this.clientList.find((item) => item.id === clientId);
        this.clientInfo = client;
      }
    },
    /**
     * 删除选中
     */
    deleteClient() {
      this.clientInfo = {};
      this.keyWord = '';
    },
    /**
     * 处理新增客户
     */
    handleAddCustomer(val) {
      this.visible = false;
      this.clientInfo = val;
      this.clientId = val.id;
      this.clientList = [this.clientInfo]; //处理回显
      this.keyWord = val.clientName;
    },
    /**
     * 确认事件
     */
    confirm() {
      //传递选中数据给父组件
      this.$emit('select', this.clientInfo);
      this.handleClose();
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      if (!this.clientInfo.id) this.$emit('clear');
      this.$emit('update:visibility', false);
    },
    /**
     * 表格中选中
     */
    handleClick(val) {
      console.log('val', val);
      this.keyWord = val.clientName;
      this.visible = false;
      this.clientInfo = val;
    },
    /**
     * 输入框清空
     */
    handleClear() {
      this.visible = false;
      this.clientInfo = {};
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//   height: 400px;
// }
.popper-class {
  padding: 0 !important;
}
.binding-customer-dialog {
  height: 120px;
  padding: 10px 0;
}
.client {
  margin: 20px 0 0 0px;
  display: flex;
  align-items: center;
  > .icon_delete {
    font-size: 18px;
    color: #ff9200;
    margin-right: 15px;
  }
  .client-info {
    .icon_grade {
      margin: 0 5px;
      color: $blue;
      font-size: 14px;
    }
  }
}
.car-table ::v-deep .el-table__body-wrapper {
  overflow: auto;
}
.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
</style>
