<template>
  <div
    :style="{ height: width + 'px', width: width + 'px' }"
    class="progress-box"
  >
    <svg viewBox="0 0 100 100">
      <path
        class="el-progress-circle__track"
        :d="trackPath"
        :stroke="defineBackColor"
        :stroke-width="relativeStrokeWidth"
        fill="none"
        :style="trailPathStyle"
      ></path>
      <path
        class="el-progress-circle__path"
        :d="trackPath"
        :stroke="stroke"
        fill="none"
        :stroke-linecap="strokeLinecap"
        :stroke-width="percentage ? relativeStrokeWidth : 0"
        :style="circlePathStyle"
      ></path>
    </svg>
    <div
      class="content"
      :style="{
        width: width - strokeWidth * 2 + 'px',
        height: width - strokeWidth * 2 + 'px',
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'circularProgressBar',
  props: {
    /**
     * 百分比（必填）
     * 可选 0-100
     * @type {Number}
     * @default 0
     */
    percentage: {
      type: Number,
      default: 0,
      required: true,
      // validator: (val) => val >= 0 && val <= 100,
    },

    /**
     * 进度条宽度 单位 px
     * @type {Number}
     * @default 6
     */
    strokeWidth: {
      type: Number,
      default: 6,
    },

    /**
     * 进度条容器宽度
     * @type {Number}
     * @default 126
     */
    width: {
      type: Number,
      default: 126,
    },

    /**
     * 指定进度条底色（支持 hex 格式）
     * @default '#ebeef5'
     */
    defineBackColor: {
      type: [String, Array, Function],
      default: '#ebeef5',
    },

    /**
     * 进度条颜色
     * @default '#409EFF'
     */
    color: {
      type: [String, Array, Function],
      default: '',
    },

    /**
     * 进度条当前状态
     */
    status: {
      type: String,
      validator: (val) => ['success', 'exception', 'warning'].indexOf(val) > -1,
    },
    /**
     * 类型路径两端的形状
     * 可选 butt/round/square
     * @default 'round'
     */
    strokeLinecap: {
      type: String,
      default: 'round',
    },
  },
  data() {
    return {};
  },
  computed: {
    perimeter() {
      return 2 * Math.PI * this.radius;
    },
    rate() {
      return 1;
    },

    relativeStrokeWidth() {
      return ((this.strokeWidth / this.width) * 100).toFixed(1);
    },
    radius() {
      return parseInt(50 - parseFloat(this.relativeStrokeWidth) / 2, 10);
    },
    trackPath() {
      const radius = this.radius;
      return `
          M 50 50
          m 0 -${radius}
          a ${radius} ${radius} 0 1 1 0 ${radius * 2}
          a ${radius} ${radius} 0 1 1 0 -${radius * 2}
          `;
    },
    strokeDashoffset() {
      const offset = (-1 * this.perimeter * (1 - this.rate)) / 2;
      return `${offset}px`;
    },
    trailPathStyle() {
      return {
        strokeDasharray: `${this.perimeter * this.rate}px, ${this.perimeter}px`,
        strokeDashoffset: this.strokeDashoffset,
      };
    },
    circlePathStyle() {
      return {
        strokeDasharray: `${
          this.perimeter * this.rate * (this.percentage / 100)
        }px, ${this.perimeter}px`,
        strokeDashoffset: this.strokeDashoffset,
        transition: 'stroke-dasharray 0.6s ease 0s, stroke 0.6s ease',
      };
    },
    stroke() {
      let ret;
      if (this.color) {
        ret = this.getCurrentColor(this.percentage);
      } else {
        switch (this.status) {
          case 'success':
            ret = '#13ce66';
            break;
          case 'exception':
            ret = '#ff4949';
            break;
          case 'warning':
            ret = '#e6a23c';
            break;
          default:
            ret = '#20a0ff';
        }
      }
      return ret;
    },
  },
  methods: {
    getCurrentColor(percentage) {
      if (typeof this.color === 'function') {
        return this.color(percentage);
      } else if (typeof this.color === 'string') {
        return this.color;
      } else {
        return this.getLevelColor(percentage);
      }
    },
    getLevelColor(percentage) {
      const colorArray = this.getColorArray().sort(
        (a, b) => a.percentage - b.percentage
      );

      for (let i = 0; i < colorArray.length; i++) {
        if (colorArray[i].percentage > percentage) {
          return colorArray[i].color;
        }
      }
      return colorArray[colorArray.length - 1].color;
    },
    getColorArray() {
      const color = this.color;
      const span = 100 / color.length;
      return color.map((seriesColor, index) => {
        if (typeof seriesColor === 'string') {
          return {
            color: seriesColor,
            percentage: (index + 1) * span,
          };
        }
        return seriesColor;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-box {
  position: relative;
  display: inline-block;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
}
</style>
